import React from 'react';
import { ListItem, makeStyles, Theme } from '@material-ui/core';
import { ECategoryGroupDisplayCode, ICategoryGroup } from 'components/menu/model/Menu';

interface IMenuCategoryGroupProps {
    onClick: (categoryGroup: ICategoryGroup) => void;
    categoryGroup: ICategoryGroup;
}

const useStyles = makeStyles((theme: Theme) => ({
    listItem: ({ categoryGroup }: IMenuCategoryGroupProps) => ({
        borderRadius: theme.shape.borderRadius,
        paddingTop:
            categoryGroup.displayCode === ECategoryGroupDisplayCode.LARGE ? `${200 / 3}%` : `${100 / 3}%`,
        paddingBottom: theme.spacing(0),
        backgroundImage: `url(${categoryGroup.imageUrl})`,
        backgroundSize: 'cover',
        marginTop: theme.spacing(1),
        boxShadow: !!categoryGroup.imageUrl ? 'none' : '0 0 40px 0 #c2c2c2'
    })
}));

export const MenuCategoryGroup: React.FC<IMenuCategoryGroupProps> = props => {
    const { onClick, categoryGroup } = props;
    const classes = useStyles(props);
    const handleClick = React.useCallback(() => onClick(categoryGroup), [categoryGroup, onClick]);

    return <ListItem className={classes.listItem} onClick={handleClick} disableGutters />;
};
