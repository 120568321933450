import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { useResponsive } from 'src/hooks/useResponsive';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { OrderScenario } from 'components/order/model/Order';
import { useTimeSlotHelpers } from 'components/timeslots/useTimeSlotHelpers';
import { CONTENT_MAX_WIDTH } from 'config/constants';
import { getFadeDownGradient } from 'lib/helpers';
import { SmoothNumbers } from 'lib/SmoothNumbers';
import { isDefined } from 'lib/typeInference';
import { useCurrencyString } from 'lib/useCurrencyString';
import { useLocalHistory } from 'lib/useLocalHistory';
import { LocationRouteParams, ROUTES } from 'pages/routes';
import { ApplicationState } from 'store/store';
import { EGAEventName, isHIBSV4, isSweetwaters, useGAHelpers } from 'lib/useGAHelpers';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: ({ isDesktop }: { isDesktop: boolean }) => ({
            position: 'fixed',
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            left: 0,
            right: 0,
            padding: theme.spacing(1),
            paddingTop: 0,
            paddingRight: theme.spacing(1),
            width: isDesktop ? 'calc(100% - 4px)' : '100%',
            background: getFadeDownGradient(theme.palette.background.default),
            zIndex: 1
        }),
        viewBasketButton: {
            width: '100%',
            padding: theme.spacing(1),
            marginTop: 0,
            maxWidth: `${CONTENT_MAX_WIDTH}px`
        },
        viewBasketIcon: {
            fontSize: '1rem'
        }
    })
);

export const MenuViewBasketButton: React.FC = () => {
    const { t } = useTranslation();
    const { isDesktop } = useResponsive();
    const classes = useStyles({ isDesktop });
    const { enqueueSnackbar } = useSnackbar();

    const { items, deliveryLocation } = useSelector((state: ApplicationState) => state.basket);
    const { menu } = useSelector((state: ApplicationState) => state.menu);

    const { search } = useLocation();
    const { push } = useLocalHistory();
    const getCurrencyString = useCurrencyString();
    const { isLocationOrderEnabled } = useLocationHelpers();
    const { isTimeSlotsAvailable, asapEnabled } = useTimeSlotHelpers();
    const [scenario, routeScenario] = useRouteScenario();
    const { merchantId, locationId } = useParams<LocationRouteParams>();
    const { logUserEvent, getBasketEventBody } = useGAHelpers();

    const handleViewBasket = React.useCallback(() => {
        if (isSweetwaters(merchantId)) {
            logUserEvent(EGAEventName.ViewBasketSweetwaters);
        }
        if (isHIBSV4(merchantId) && locationId) {
            logUserEvent(EGAEventName.ViewBasketDetailed, getBasketEventBody(items, locationId));
        }
        if (scenario === OrderScenario.ORDER_TO_TABLE && !isDefined(deliveryLocation)) {
            enqueueSnackbar(t('OTT_TABLE_NUMBER_NEEDED'), { variant: 'error' });
            return;
        }
        push(ROUTES.ORDER.BASKET, { scenario: routeScenario }, search);
    }, [
        merchantId,
        locationId,
        scenario,
        deliveryLocation,
        push,
        routeScenario,
        search,
        logUserEvent,
        getBasketEventBody,
        items,
        enqueueSnackbar,
        t
    ]);

    const price = React.useMemo(() => {
        let totalPrice = 0;
        for (const item of items) {
            totalPrice += item.price * item.quantity;
            if (Array.isArray(item.modifiers)) {
                item.modifiers.forEach(modifier => {
                    if (Array.isArray(modifier.options)) {
                        modifier.options.forEach(option => {
                            totalPrice += option.cost ?? 0;
                        });
                    }
                });
            }
        }
        return totalPrice;
    }, [items]);

    const handleQuantityAnimationFormat = React.useCallback((value: number) => value.toFixed(0), []);
    const handlePriceAnimationFormat = React.useCallback(
        (value: number) => getCurrencyString(value),
        [getCurrencyString]
    );
    const quantity = React.useMemo(() => {
        let totalQuantity = 0;
        for (const item of items) {
            if (!isDefined(item.parentReferenceId)) {
                totalQuantity += item.quantity;
            }
        }
        return totalQuantity;
    }, [items]);
    if (!menu || items.length === 0) {
        return null;
    }
    return (
        <Box>
            <div className={classes.buttonContainer}>
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.viewBasketButton}
                    onClick={handleViewBasket}
                    data-cy="menu-view-basket"
                    disabled={
                        !isLocationOrderEnabled ||
                        (scenario === OrderScenario.PREORDER && !asapEnabled && !isTimeSlotsAvailable)
                    }
                >
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        paddingLeft={1}
                        paddingRight={1}
                    >
                        <Box display="flex" alignItems="center">
                            <ShoppingBasketIcon className={classes.viewBasketIcon} />
                            <Box marginLeft={0.5}>
                                <SmoothNumbers
                                    stepDuration={1}
                                    value={quantity}
                                    formatValue={handleQuantityAnimationFormat}
                                />
                            </Box>
                        </Box>
                        <Typography>{t('PREORDER_VIEW_BASKET')}</Typography>
                        <SmoothNumbers
                            stepDuration={2}
                            value={price}
                            formatValue={handlePriceAnimationFormat}
                        />
                    </Box>
                </Button>
            </div>
        </Box>
    );
};
