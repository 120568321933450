import * as React from 'react';
import { Box, createStyles, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import PlusIcon from '@material-ui/icons/ControlPointRounded';
import MinusIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import { addOpacity } from 'lib/helpers';

interface IProps {
    value: number;
    onChange: (value: number) => void;
    max?: number;
    min: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        count: {
            color: addOpacity(theme.palette.text.primary, 0.3),
            margin: theme.spacing(0, 3)
        },
        button: {
            color: theme.palette.text.hint
        },
        colorDisabled: {
            color: addOpacity(theme.palette.primary.main, 0.4)
        }
    })
);

export const Counter: React.FC<IProps> = ({ value, onChange, min, max }) => {
    const classes = useStyles();
    const handleChange = React.useCallback(
        (mode: 'INCREASE' | 'DECREASE') => () => {
            if (mode === 'INCREASE') {
                if (max) {
                    if (value + 1 <= max) {
                        onChange(value + 1);
                    }
                } else {
                    onChange(value + 1);
                }
            } else if (value - 1 >= min) {
                onChange(value - 1);
            }
        },
        [max, min, onChange, value]
    );

    return (
        <Box display="flex" alignItems="center">
            <IconButton
                disabled={value === min}
                className={classes.button}
                classes={{ disabled: classes.colorDisabled }}
                onClick={handleChange('DECREASE')}
            >
                <MinusIcon fontSize="large" />
            </IconButton>
            <Typography className={classes.count} variant="h1">
                {value}
            </Typography>
            <IconButton
                classes={{ disabled: classes.colorDisabled }}
                className={classes.button}
                disabled={value === max}
                onClick={handleChange('INCREASE')}
            >
                <PlusIcon fontSize="large" />
            </IconButton>
        </Box>
    );
};
