import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { EventEmitter } from 'events';
import { getLocalSettings } from 'components/settings/localStore';
import { isString } from 'lib/typeInference';

export type IHttpClientConfig = AxiosRequestConfig & {
    version?: string;
};

interface IHttpRequestConfig {
    shouldIncludeAuthToken?: boolean;
    shouldIncludeXApplicationId?: boolean;
    shouldIncludeXClientPlatform?: boolean;
    nonce?: string;
    version?: string;
}

interface IHttpRequestArgs<T = Record<string, string>> {
    url: string;
    data?: T;
    headers?: any;
    configs?: AxiosRequestConfig;
    headerConfigs?: IHttpRequestConfig;
}

export class HttpClient {
    public static token = '';
    public static events = new EventEmitter();
    public static merchantId?: string = undefined;
    public defaultRequestHeaderConfigs: IHttpRequestConfig = {
        shouldIncludeAuthToken: true,
        shouldIncludeXApplicationId: true,
        shouldIncludeXClientPlatform: true
    };
    private api: AxiosInstance;
    private BASE_URL = '/';

    constructor({ baseURL, url, version }: IHttpClientConfig) {
        this.api = axios.create({ baseURL, url });
        this.api.interceptors.response.use(
            res => Promise.resolve(res.data),
            err => {
                if (err.response?.status === 401) {
                    HttpClient.events.emit('unauthorized', false);
                }
                return Promise.reject(err.response);
            }
        );
        this.BASE_URL = url || '';
        if (isString(version)) {
            this.defaultRequestHeaderConfigs.version = version;
        }
    }

    protected addResponseInterceptor = (
        onFulfilled?: (value: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>,
        onRejected?: (error: any) => any
    ) => {
        this.api.interceptors.response.use(onFulfilled, onRejected);
    };

    protected unauthorize() {
        HttpClient.events.emit('unauthorized', false);
    }

    protected getRequest = <Res>({ url, headers, configs, headerConfigs }: IHttpRequestArgs) => {
        const requestHeaders = { ...this.getRequestHeadersConfig(headerConfigs), ...headers };
        const params = {
            m: configs?.params?.tenantId ? undefined : HttpClient.merchantId,
            ...configs?.params
        };
        return this.api.get<Res, Res>(`${this.BASE_URL}${url}`, {
            headers: requestHeaders,
            ...configs,
            params
        });
    };

    protected postRequest = <Req, Res>({
        url,
        headers,
        configs,
        headerConfigs,
        data
    }: IHttpRequestArgs<Req>) => {
        const requestHeaders = { ...this.getRequestHeadersConfig(headerConfigs), ...headers };
        return this.api.post<Req, Res>(url, data, { headers: requestHeaders, ...configs });
    };

    protected putRequest = <Req, Res>({
        url,
        headers,
        configs,
        headerConfigs,
        data
    }: IHttpRequestArgs<Req>) => {
        const requestHeaders = { ...this.getRequestHeadersConfig(headerConfigs), ...headers };
        return this.api.put<Req, Res>(url, data, { headers: requestHeaders, ...configs });
    };
    protected deleteRequest = <Res>({ url, headers, configs, headerConfigs }: IHttpRequestArgs) => {
        const requestHeaders = { ...this.getRequestHeadersConfig(headerConfigs), ...headers };
        return this.api.delete<Res>(url, { headers: requestHeaders, ...configs });
    };

    private getRequestHeadersConfig = (requestHeaderConfigs?: IHttpRequestConfig) => {
        const headers: Record<string, string> = {};
        const _requestHeaderConfigs = {
            ...this.defaultRequestHeaderConfigs,
            ...requestHeaderConfigs
        };

        if (_requestHeaderConfigs.shouldIncludeAuthToken && !!HttpClient.token) {
            headers.Authorization = `Token ${HttpClient.token}`;
        }

        if (!!_requestHeaderConfigs.nonce) {
            headers['x-pepper-req-nonce'] = _requestHeaderConfigs.nonce;
        }

        if (isString(_requestHeaderConfigs.version)) {
            headers['x-api-version'] = _requestHeaderConfigs.version;
        }

        if (!!_requestHeaderConfigs.shouldIncludeXClientPlatform) {
            headers['x-client-platform'] = 'web';
        }

        if (_requestHeaderConfigs.shouldIncludeXApplicationId) {
            const localManifest = getLocalSettings();

            if (!localManifest) {
                throw new Error('x-application-id is missing');
            }

            headers['x-application-id'] = localManifest.applicationId;
        }

        return headers;
    };
}
