import * as React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { getCurrencyString } from './helpers';

export const useCurrencyString = () => {
    const region = useSelector((state: ApplicationState) => state.settings.settings?.region);
    const getCurrencyStringWrapper = React.useCallback(
        (value: number) => getCurrencyString(value, region?.currencyCode),
        [region?.currencyCode]
    );

    return getCurrencyStringWrapper;
};
