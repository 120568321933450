import React from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { addOpacity } from 'lib/helpers';
import { isDefined } from 'lib/typeInference';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: '50px',
        width: '100%'
    },
    box: {
        borderRadius: theme.shape.borderRadius,
        border: `1.5px solid ${addOpacity(theme.palette.text.primary, 0.1)}`,
        flex: 1,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    primary: {
        fontSize: '14px',
        lineHeight: '1.1rem',
        fontWeight: 700
    },
    secondary: {
        fontSize: '13px',
        fontWeight: 500,
        lineHeight: '1.1rem',
        color: addOpacity(theme.palette.text.primary, 0.4)
    },
    selected: {
        borderColor: theme.palette.text.primary
    },
    skeleton: {
        borderRadius: theme.shape.borderRadius,
        flex: 1
    }
}));

interface PayTipsItemBoxProps {
    primary: string;
    secondary?: string;
    selected?: boolean;
    onClick: () => void;
    loading?: boolean;
    gutterRight?: boolean;
    primaryClassName?: string;
}

export const PayTipsItemBox: React.FC<PayTipsItemBoxProps> = ({
    primary,
    secondary,
    selected,
    loading,
    gutterRight = true,
    primaryClassName,
    onClick
}) => {
    const classes = useStyles();
    if (loading) {
        return (
            <Box className={classes.root} mr={gutterRight ? 1 : 0}>
                <Skeleton className={classes.skeleton} variant="rect" height="50px" width="100%" />
            </Box>
        );
    }
    return (
        <Box className={classes.root} mr={gutterRight ? 1 : 0} onClick={onClick}>
            <Box
                className={clsx(classes.box, {
                    [classes.selected]: !!selected
                })}
            >
                {isDefined(secondary) && <Typography className={classes.secondary}>{secondary}</Typography>}
                <Typography className={clsx(classes.primary, primaryClassName)}>{primary}</Typography>
            </Box>
        </Box>
    );
};
