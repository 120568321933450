import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, ButtonBase, createStyles, makeStyles, Typography } from '@material-ui/core';
import { BaseRouteParams } from 'pages/routes';
import { ApplicationState } from 'store/store';
import { PayTableInfo } from '../header/PayTableInfo';
import { QuickPayButton } from '../ui/PayButton';
import { PayBaseModal } from './PayBaseModal';
import { Image } from 'lib/Image';

const useStyles = makeStyles(() =>
    createStyles({
        link: {
            textDecoration: 'none'
        },
        typography: {
            fontWeight: 700,
            fontSize: '15px'
        }
    })
);

interface PayActionsModalProps {
    open: boolean;
    handleChange: () => void;
    table?: string;
    checkId?: string;
    onClose: () => void;
    orderNotFound?: boolean;
    onBillSplit: () => void;
}

export const PayActionModal: React.FC<PayActionsModalProps> = ({
    open,
    handleChange,
    table,
    checkId,
    onClose,
    orderNotFound,
    onBillSplit
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { merchantId } = useParams<BaseRouteParams>();
    const [showLogo, setShowLogo] = React.useState<boolean>(true);
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const handleBillSplit = React.useCallback(() => {
        onClose();
        onBillSplit();
    }, [onBillSplit, onClose]);
    const handleLogoFallbackError = React.useCallback(() => {
        setShowLogo(false);
    }, []);
    return (
        <PayBaseModal open={open} onClose={onClose}>
            <Box px={1} py={1.5}>
                {!!showLogo && (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        paddingBottom={1}
                        paddingTop={5}
                    >
                        <Image
                            src={`${process.env.MEDIA_URL}/tenants/${merchantId}/app_media/pat_logo.svg`}
                            fallbackSrc={`${process.env.MEDIA_URL}/tenants/${merchantId}/app_media/pat_logo.png`}
                            onFallbackError={handleLogoFallbackError}
                            height="140px"
                        />
                    </Box>
                )}
                <Box display="flex" justifyContent="center" marginBottom={4}>
                    <ButtonBase onClick={handleChange}>
                        <PayTableInfo isLoading={false} table={table} checkId={checkId} />
                    </ButtonBase>
                </Box>
                {orderNotFound && (
                    <Typography className={classes.typography} align="center">
                        {t('PAT_QUICKPAY_ORDER_NOT_FOUND')}
                    </Typography>
                )}
                <Box>
                    {!!settings?.payAtTable?.noTableCheckFoundUrl && (
                        <Box paddingBottom={0.25}>
                            <a
                                href={settings?.payAtTable?.noTableCheckFoundUrl}
                                rel="noreferrer"
                                target="_blank"
                                className={classes.link}
                            >
                                <QuickPayButton secondary>{t('DIALOG_VIEW_MENU')}</QuickPayButton>
                            </a>
                        </Box>
                    )}
                    {!!settings?.billSplittingByPercentageEnabled && !orderNotFound && (
                        <Box paddingBottom={0.25}>
                            <QuickPayButton onClick={handleBillSplit} secondary>
                                {t('VIEW_BILL_SPLIT_BTN')}
                            </QuickPayButton>
                        </Box>
                    )}
                    <QuickPayButton disabled={orderNotFound} onClick={onClose}>
                        {t('PAT_QUICKPAY_PAY_THE_BILL')}
                    </QuickPayButton>
                </Box>
            </Box>
        </PayBaseModal>
    );
};
