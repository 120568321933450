import * as React from 'react';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { IPointAward } from 'components/awards/models';
import { useTokenizedString } from '../useTokenizedString';

interface IProps {
    award: IPointAward;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: `${theme.spacing(3.5)}px`,
            fontWeight: theme.typography.fontWeightBold
        },
        subtitle: {
            fontSize: `${theme.spacing(2.5)}px`,
            fontWeight: theme.typography.fontWeightBold
        },
        description: {
            fontSize: `${theme.spacing(1.75)}px`
        }
    })
);

export const AdHoc: React.FC<IProps> = ({ award }) => {
    const classes = useStyles();
    const { renderTokenizedString } = useTokenizedString(award);

    return (
        <Box width="100%">
            {award.visualisation.titleText && (
                <Typography display="block" className={classes.title}>
                    {award.visualisation.titleText}
                </Typography>
            )}
            {award.visualisation.subtitleText && (
                <Typography display="block" className={classes.subtitle}>
                    {renderTokenizedString(award.visualisation.subtitleText)}
                </Typography>
            )}
            {award.visualisation.subtitleTwoText && (
                <Typography display="block" className={classes.description}>
                    {renderTokenizedString(award.visualisation.subtitleTwoText)}
                </Typography>
            )}
        </Box>
    );
};
