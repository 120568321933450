import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { BasePage } from './base';
import { LandingPage } from './landing';
import { ROUTES } from './routes';
import { WorldpayMessanger } from './worldpay';

export const Root = () => (
    <Box height="100%" width="100%" className="hidden-scroll">
        <Switch>
            <Route component={WorldpayMessanger} path={ROUTES.WORLDPAY} exact />
            <Route component={BasePage} path={ROUTES.MENU} />
            <Route component={BasePage} path={ROUTES.BASE} />
            <Route path="/" exact component={LandingPage} />
        </Switch>
    </Box>
);
