import { ActionCreator } from 'redux';
import { layoutApi } from 'components/layout/layoutApi';
import { Layout } from 'components/layout/model/Layout';
import { getLocalSettings } from 'components/settings/localStore';
import logger from 'lib/logger';
import { LayoutActionTypes } from './layoutActionTypes';

interface LayoutLoadingAction {
    type: LayoutActionTypes.END_REQUEST | LayoutActionTypes.START_REQUEST;
}

interface LayoutGetSuccessAction {
    type: LayoutActionTypes.GET_LAYOUT_SUCCESS;
    layout: Layout;
    tenantId: string;
}

interface LayoutErrorAction {
    type: LayoutActionTypes.GET_LAYOUT_ERROR;
    error: string;
}

export type LayoutAction = LayoutLoadingAction | LayoutGetSuccessAction | LayoutErrorAction;

export const getLayout = () => async (dispatch: ActionCreator<LayoutAction>) => {
    dispatch({
        type: LayoutActionTypes.START_REQUEST
    });
    try {
        const localSettings = getLocalSettings();
        const data = await layoutApi.getActive();
        if (!data.anchor.properties) {
            throw new Error('Cannot read properties field of undefined');
        }
        dispatch({
            layout: data.anchor.properties,
            tenantId: localSettings?.merchantId,
            type: LayoutActionTypes.GET_LAYOUT_SUCCESS
        });
    } catch (e) {
        logger.error(e);
        if (e && e.message) {
            dispatch({
                type: LayoutActionTypes.GET_LAYOUT_ERROR,
                error: e.message
            });
        }
    }
    dispatch({
        type: LayoutActionTypes.END_REQUEST
    });
};
