import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import clsx from 'clsx';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { ViewBillHeader } from 'components/bill/ui/ViewBill/ViewBillHeader';
import { addGuestOrder } from 'components/order/localGuestOrders';
import { OperationStatus } from 'components/order/model/Operation';
import { IOrderCreateResourceV8, OrderErrorCodes, OrderScenario } from 'components/order/model/Order';
import { EOrderNonceKey, legacyOrderApi, orderApi } from 'components/order/orderApi';
import { OPERATION_POLLING_DELAY } from 'config/constants';
import { ELoadingSVG, RandomLoading } from 'lib/animations/RandomLoadingSVG';
import { EmptyState } from 'lib/EmptyState';
import { addOpacity } from 'lib/helpers';
import { InnerPageLayout } from 'lib/InnerPageLayout';
import logger from 'lib/logger';
import { isDefined } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { resetBasket } from 'store/basket/basketActions';
import { loadTimeslots } from 'store/settings/settingsActions';
import { ApplicationState } from 'store/store';
import { IPaymentProvider, paymentProviderFactory } from '../integrations/PaymentProvider/PaymentProvider';
import { ROUTES } from './routes';
import { userApi } from 'components/user/userApi';
import { getUserCard } from 'store/auth/authActions';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageContainer: {
            display: 'flex',
            height: '100%',
            paddingBottom: theme.spacing(7),
            overflowY: 'hidden',
            flexDirection: 'column'
        },
        pageCenter: {
            alignItems: 'center'
        },
        verticalCenter: {
            justifyContent: 'center'
        },
        basketError: {
            fontSize: theme.spacing(12),
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(-6)
        },
        paragraph: {
            marginTop: theme.spacing(0.5),
            color: addOpacity(theme.palette.text.primary, 0.5)
        }
    })
);

const SPECIAL_ERROR_CODES: string[] = [
    OrderErrorCodes.DUPLICATE_ORDER_NONCE,
    OrderErrorCodes.DUPLICATE_PENDING_ORDER,
    OrderErrorCodes.UPDATING_THE_BILL
];

enum ErrorType {
    GENERIC,
    NOT_FOUND,
    NONE,
    THREEDSECURE_AUTH_REQUIRED
}

export const OperationStatusPage: React.FC = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(ErrorType.NONE);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [showRetry, setShowRetry] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { operationId, locationId, merchantId } = useParams<{
        operationId: string;
        locationId: string;
        merchantId: string;
    }>();
    const { push } = useLocalHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory<{
        orderBodyToRecreate?: IOrderCreateResourceV8;
        orderBodyToRecreatePat?: IOrderCreateResourceV8 & { id: number };
    }>();
    const { timeslots, settings } = useSelector((state: ApplicationState) => state.settings);
    const [scenario, routeScenario] = useRouteScenario();
    const { lastRequest } = useSelector((state: ApplicationState) => state.request);
    const { isGuest, card, user } = useAuth();
    const title = React.useMemo(
        () => (scenario === OrderScenario.TABLE ? t('TAB_TITLE') : t('OPERATION_STATUS_TITLE')),
        [scenario, t]
    );
    const localOrderApi = React.useMemo(() => {
        if (scenario === OrderScenario.TABLE) {
            return legacyOrderApi;
        }
        return orderApi;
    }, [scenario]);

    const [provider, setProvider] = React.useState<IPaymentProvider>();

    React.useEffect(() => {
        if (settings && !provider) {
            paymentProviderFactory.create(settings).then(paymentProvider => {
                setProvider(paymentProvider);
            });
        }
        return () => {
            if (provider && provider.reset) {
                provider.reset();
            }
        };
    }, [provider, provider?.reset, settings]);

    const handleOnBack = React.useCallback(() => {
        if (error !== ErrorType.NONE) {
            switch (scenario) {
                case OrderScenario.TABLE:
                    push(ROUTES.JOURNEY.PAT.BILL, {
                        merchantId,
                        locationId,
                        scenario: routeScenario
                    });
                    break;
                default:
                    push(ROUTES.ORDER.BASKET, { merchantId, locationId });
                    break;
            }
        }
    }, [error, locationId, merchantId, push, routeScenario, scenario]);

    const handleOperationStatus = React.useCallback(async () => {
        if (!provider) {
            return;
        }
        setLoading(true);
        try {
            const operation = await localOrderApi.getOperation(operationId);
            if (!isDefined(operation?.status)) {
                throw new Error(t('DIALOG_ORDER_STATUS_UNKNOWN'));
            }
            localOrderApi.nonceHandlingByOperationStatus(operation.status, [
                EOrderNonceKey.CREATE_ORDER,
                EOrderNonceKey.UPDATE_ORDER
            ]);

            switch (operation.status) {
                case OperationStatus.PENDING:
                    // Recursive Poll for order completion
                    setTimeout(() => {
                        handleOperationStatus();
                    }, OPERATION_POLLING_DELAY);
                    break;
                case OperationStatus.ERROR:
                case OperationStatus.TIMEOUT:
                    if (
                        operation.errorCode === 'E-ORD-0354' &&
                        !!operation.errorData &&
                        (!!history.location.state?.orderBodyToRecreate ||
                            history.location.state?.orderBodyToRecreatePat) &&
                        !!settings
                    ) {
                        setError(ErrorType.THREEDSECURE_AUTH_REQUIRED);
                        setLoading(false);
                        const { orderBodyToRecreate } = history.location.state;
                        const { orderBodyToRecreatePat } = history.location.state;
                        try {
                            if (!!orderBodyToRecreate) {
                                const orderBody = await provider.threeDSecureAuthenticate(
                                    operation.errorData,
                                    orderBodyToRecreate
                                );
                                if (orderBody !== null) {
                                    const operationResponse = await localOrderApi.createOrder(orderBody);

                                    if (isDefined(operationResponse.id)) {
                                        push(ROUTES.ORDER.OPERATION, {
                                            operationId: operationResponse.id.toString()
                                        });
                                        // We do not want to show the previous operation's error
                                        return;
                                    }
                                } else {
                                    throw new Error('Error while submitting payment');
                                }
                            } else if (!!orderBodyToRecreatePat && isDefined(orderBodyToRecreatePat.id)) {
                                const { id, ...orderToRecreate } = orderBodyToRecreatePat;
                                const orderBody = await provider.threeDSecureAuthenticate(
                                    operation.errorData,
                                    orderToRecreate
                                );

                                if (orderBody !== null) {
                                    const operationResponse = await localOrderApi.updateOrder(id, orderBody);

                                    if (isDefined(operationResponse.id)) {
                                        push(ROUTES.ORDER.OPERATION, {
                                            operationId: operationResponse.id.toString()
                                        });
                                        // We do not want to show the previous operation's error
                                        return;
                                    }
                                } else {
                                    throw new Error('Error while submitting payment');
                                }
                            }
                        } catch (e) {
                            logger.error(e);
                            enqueueSnackbar(t('CHECKOUT_ERROR_PLACING_ORDER'), {
                                variant: 'error'
                            });
                        }
                    }
                    if (!operation.errorCode || !SPECIAL_ERROR_CODES.includes(operation.errorCode)) {
                        throw new Error(operation.errorMessage || 'Operation error or timeout');
                    }
                    if (operation.errorCode === OrderErrorCodes.UPDATING_THE_BILL) {
                        setShowRetry(true);
                        throw new Error(operation.errorMessage);
                    }
                // eslint-disable-next-line no-fallthrough
                case OperationStatus.DONE: {
                    const orderId = operation.resultId ?? null;
                    if (!orderId) {
                        throw new Error('No order id supplied in operation');
                    }
                    if (scenario === OrderScenario.PREORDER && !!timeslots) {
                        loadTimeslots(locationId, undefined)(dispatch);
                    }
                    resetBasket(dispatch);
                    if (isGuest) {
                        addGuestOrder(orderId.toString());
                        if (card) {
                            await userApi.removeCard(card._id);
                            if (user?.id) {
                                await getUserCard(user.id)(dispatch);
                            }
                        }
                    }
                    // Redirect
                    push(ROUTES.ORDER.SUMMARY, { orderId: orderId.toString() });
                }
            }
        } catch (orderStatusError) {
            if (isDefined(orderStatusError?.response) && orderStatusError.response?.status === 404) {
                setError(ErrorType.NOT_FOUND);
                setErrorMessage('Order not found');
            } else {
                setError(ErrorType.GENERIC);
                setErrorMessage(orderStatusError);
                if (!isDefined(orderStatusError)) {
                    setErrorMessage(t('DIALOG_ORDER_STATUS_UNKNOWN'));
                    setShowRetry(true);
                }
            }
            setLoading(false);
        }
    }, [
        card,
        dispatch,
        enqueueSnackbar,
        history.location.state,
        isGuest,
        localOrderApi,
        locationId,
        operationId,
        provider,
        push,
        scenario,
        settings,
        t,
        timeslots,
        user?.id
    ]);

    React.useEffect(() => {
        handleOperationStatus();
    }, [handleOperationStatus]);

    const handleRetry = React.useCallback(() => {
        if (lastRequest) {
            lastRequest();
            handleOperationStatus();
            setError(ErrorType.NONE);
            setErrorMessage('');
        }
    }, [handleOperationStatus, lastRequest]);
    const handleCancel = React.useCallback(() => {
        if (scenario === OrderScenario.ORDER_TO_TABLE || scenario === OrderScenario.PREORDER) {
            localOrderApi.resetNonceByKey(EOrderNonceKey.CREATE_ORDER);
        } else if (scenario === OrderScenario.TABLE) {
            localOrderApi.resetNonceByKey(EOrderNonceKey.UPDATE_ORDER);
        }

        if (scenario === OrderScenario.PREORDER) {
            push(ROUTES.BASE);
        } else {
            push(ROUTES.JOURNEY.LANDING);
        }

        resetBasket(dispatch);
    }, [dispatch, localOrderApi, push, scenario]);

    // Using a memo here to stop this component changing over 3DS2 Auth / re-order operation change
    // Much more seamless experience for the user
    const RandomLoadingComponent = React.useMemo(
        () => (
            <RandomLoading
                fallbackText={t('GENERAL_GENERIC_ERROR')}
                enabledImages={[
                    ELoadingSVG.COFFEE_CUP,
                    ELoadingSVG.COFFEE_MUG,
                    ELoadingSVG.CUPCAKE,
                    ELoadingSVG.DONUT,
                    ELoadingSVG.FRYING_PAN,
                    ELoadingSVG.POPSICLE
                ]}
            />
        ),
        [t]
    );

    return (
        <InnerPageLayout>
            <ViewBillHeader disableBack={!!(showRetry && lastRequest)} onBack={handleOnBack} title={title} />
            {(loading || error === ErrorType.THREEDSECURE_AUTH_REQUIRED) && (
                <Box className={clsx(classes.pageContainer, classes.pageCenter, classes.verticalCenter)}>
                    {RandomLoadingComponent}
                </Box>
            )}
            {!loading && error === ErrorType.GENERIC && (
                <Box className={clsx(classes.pageContainer, classes.pageCenter)}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        flex={1}
                        justifyContent="center"
                        alignItems="center"
                        padding={2}
                    >
                        <ErrorOutline className={classes.basketError} />
                        <Typography align="center" variant="h5">
                            {t('GENERAL_GENERIC_ERROR')}
                        </Typography>
                        <Typography
                            align="center"
                            variant="h6"
                            color="textSecondary"
                            gutterBottom
                            className={classes.paragraph}
                        >
                            {t('OPERATION_STATUS_ERROR_LOADING', { errorMessage })}
                        </Typography>
                        {showRetry && lastRequest && (
                            <>
                                <Button fullWidth onClick={handleRetry} color="primary" variant="contained">
                                    {t('RETRY')}
                                </Button>
                                <Box marginTop={1} width="100%">
                                    <Button
                                        fullWidth
                                        onClick={handleCancel}
                                        color="primary"
                                        variant="outlined"
                                    >
                                        {t('GENERAL_CANCEL')}
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Box>
                </Box>
            )}
            {!loading && error === ErrorType.NOT_FOUND && (
                <Box className={clsx(classes.pageContainer, classes.pageCenter)}>
                    <EmptyState
                        headerText={t('GENERAL_GENERIC_ERROR')}
                        paragraphText={t('ORDER_STATUS_NOT_FOUND')}
                    >
                        <ErrorOutline className={classes.basketError} />
                    </EmptyState>
                </Box>
            )}
        </InnerPageLayout>
    );
};
