/* eslint-disable max-len */
import * as React from 'react';

interface IProps extends Partial<React.SVGProps<SVGSVGElement>> {
    color?: string;
}

export const EmptyBillIcon: React.FC<IProps> = props => (
    <svg width={199} height={106} viewBox="0 0 199 106" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>{'EmptyBillIcon'}</title>
        <g id="Empty-plate-black-2" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
                d="M170,84 C170,81.5192145 169.849264,79.0708887 169.555776,76.6623835 C165.60231,44.2183197 135.745377,19 99.5,19 C63.3158173,19 33.4985246,44.1332379 29.4644695,76.4981167 C29.1576702,78.9595418 29,81.4627945 29,84"
                id="Path"
                stroke="inherit"
                strokeWidth="4"
            />
            <g
                id="plus-circle"
                transform="translate(86.000000, 41.000000)"
                fill="#86888B"
                fillRule="nonzero"
                stroke="inherit"
                strokeWidth="1.38278146"
            >
                <path
                    d="M7.49923477,13.3939716 L13.3939716,13.3939716 L13.3939716,7.49923477 L14.8676558,7.49923477 L14.8676558,13.3939716 L20.7623927,13.3939716 L20.7623927,14.8676558 L14.8676558,14.8676558 L14.8676558,20.7623927 L13.3939716,20.7623927 L13.3939716,14.8676558 L7.49923477,14.8676558 L7.49923477,13.3939716 Z M14,0 C21.7319789,0 28,6.26802105 28,14 C28,21.7319789 21.7319789,28 14,28 C6.26800632,28 0,21.7319789 0,14 C0,6.26802105 6.26800632,0 14,0 Z M14,1.47369895 C7.08190737,1.47369895 1.47368421,7.08192211 1.47368421,14 C1.47368421,20.9180632 7.08190737,26.5263158 14,26.5263158 C20.9180632,26.5263158 26.5263158,20.9180632 26.5263158,14 C26.5263158,7.08192211 20.9180632,1.47369895 14,1.47369895 Z"
                    id="Shape"
                />
            </g>
            <circle id="Oval" stroke="inherit" strokeWidth="3" cx="100" cy="13" r="5.5" />
            <g
                id="Group"
                transform="translate(16.000000, 84.000000)"
                fill="#010101"
                stroke="inherit"
                strokeWidth="6"
            >
                <rect id="Rectangle" x="3" y="3" width="162" height="1" rx="0.5" />
            </g>
            <rect
                id="Rectangle-Copy-2"
                stroke="inherit"
                strokeWidth="6"
                fill="#010101"
                x="9"
                y="97"
                width="182"
                height="1"
                rx="0.5"
            />
        </g>
    </svg>
);
