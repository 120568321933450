import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { UserCard } from 'components/user/model/User';
import { LoadingTypography } from 'lib/LoadingTypography';
import { PaymentMethodIcon } from 'lib/PaymentMethodIcon';

const useStyles = makeStyles(() =>
    createStyles({
        icon: {
            height: '27px',
            width: '42px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        content: {
            fontSize: '13px',
            fontWeight: 700
        }
    })
);

interface PaymentMethodItemProps {
    card?: UserCard;
    type: string;
    loading?: boolean;
}

export const PaymentMethodItem: React.FC<PaymentMethodItemProps> = ({ card, type, loading }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const content = React.useMemo(() => {
        switch (type) {
            case 'GOOGLEPAY':
                return t('PAYMENT_TYPE_GOOGLE_PAY');
            case 'APPLEPAY':
                return t('PAYMENT_TYPE_APPLE_PAY');
            default:
                return card ? `**** **** **** ${card.last4}` : null;
        }
    }, [card, t, type]);
    if (loading) {
        return (
            <Box display="flex" alignItems="center">
                <Box marginRight={1} display="flex" alignItems="center" className={classes.icon}>
                    <Skeleton variant="rect" width="100%" />
                </Box>
                <LoadingTypography loading width={100} className={classes.content} />
            </Box>
        );
    }
    return (
        <Box display="flex" alignItems="center">
            <Box marginRight={1} display="flex" alignItems="center" className={classes.icon}>
                <PaymentMethodIcon width="100%" type={type} />
            </Box>
            <Typography className={classes.content}>{content}</Typography>
        </Box>
    );
};
