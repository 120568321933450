import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Button, createStyles, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import { useAsyncData } from 'src/hooks/useAsyncData';
import { useQuery } from 'src/hooks/useQuery';
import { isError, isLoading, isSuccess } from 'src/utils/request';
import { getLocalBasket } from 'components/basket/localStore';
import { IAdjustmentReadResourceV10, OrderAdjustmentType } from 'components/basket/model/Basket';
import { ViewBill } from 'components/bill/ui/ViewBill';
import { ViewBillContentSection } from 'components/bill/ui/ViewBill/ViewBillContentSection';
import { ViewBillHeader } from 'components/bill/ui/ViewBill/ViewBillHeader';
import { OrderToTableLocationPicker } from 'components/location/OrderToTableLocationPicker';
import { IOperation, OperationStatus } from 'components/order/model/Operation';
import {
    IOrderCreatePaymentV8,
    IOrderReadResourceV12,
    OrderErrorCodes,
    OrderPaymentType
} from 'components/order/model/Order';
import { EOrderNonceKey, legacyOrderApi } from 'components/order/orderApi';
import { orderService } from 'components/order/orderService';
import { ETipScheme } from 'components/settings/enums';
import { getLocalMerchantId } from 'components/settings/localStore';
import { setOnCloseRedirectUrl } from 'components/user/localAuth';
import { OPERATION_POLLING_DELAY } from 'config/constants';
import { EmptyState } from 'lib/EmptyState';
import { addOpacity, roundToDecimal } from 'lib/helpers';
import { InnerPageLayout } from 'lib/InnerPageLayout';
import { InnerPageLayoutContent } from 'lib/InnerPageLayout/InnerPageLayoutContent';
import { Throbber } from 'lib/Throbber';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { setReturnUrl } from 'store/auth/authActions';
import { setBasket } from 'store/basket/basketActions';
import { ApplicationState } from 'store/store';
import { LocationRouteParams, ROUTES } from './routes';
import { isDefined } from 'lib/typeInference';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageContainer: {
            display: 'flex',
            height: '100%',
            paddingBottom: theme.spacing(7),
            alignItems: 'center',
            overflowY: 'hidden',
            flexDirection: 'column'
        },
        basketError: {
            fontSize: theme.spacing(12),
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(-6)
        },
        paragraph: {
            marginTop: theme.spacing(0.5),
            color: addOpacity(theme.palette.text.primary, 0.5)
        },
        pageCenter: {
            alignItems: 'center'
        }
    })
);

export const ViewBillPage: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const billSplittingPart = useQuery('splitPart');
    const { user, isGuest } = useAuth();
    const { push } = useLocalHistory();
    const { pathname } = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const isSplitBillItem = useQuery('isSplitBillItem');
    const { locationId } = useParams<LocationRouteParams>();
    const [claimedItemIds, setClaimedItemIds] = React.useState<number[]>([]);

    const [orderDetails, setOrderDetails, setOrderDetailsInitial] = useAsyncData<IOrderReadResourceV12>();
    const [joinOrderDetails, setJoinOrderDetails] = useAsyncData<IOperation>();
    const [operationError, setOperationError, setOperationErrorInitial] = useAsyncData<IOperation>();

    const { deliveryLocation, checkId } = useSelector((state: ApplicationState) => state.basket);
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const menuLink = React.useMemo(
        () => settings?.payAtTable?.noTableCheckFoundUrl,
        [settings?.payAtTable?.noTableCheckFoundUrl]
    );
    const [orderNotFound, setOrderNotFound] = React.useState(false);
    const [giftCard, setGiftCard] = React.useState<string | undefined>('');
    const [selectedReward, setSelectedReward] = React.useState<IAdjustmentReadResourceV10 | undefined>();
    const [isBillLoading, setIsBillLoading] = React.useState(!!deliveryLocation);
    const [userSelectedTipValue, setUserSelectedTipValue] = React.useState(user?.defaultTip?.amount);
    const [tip, setTip] = React.useState(0);

    const recalculateCheckoutDetails = React.useCallback(
        async ({
            newTip,
            giftCardNumber,
            newSelectedReward
        }: {
            newTip?: {
                value: number;
                isPercentage: boolean;
            };
            giftCardNumber?: string | null;
            newSelectedReward?: IAdjustmentReadResourceV10 | null;
        }) => {
            const adjustments: Partial<IAdjustmentReadResourceV10>[] = [];
            const payments: Partial<IOrderCreatePaymentV8[]> = [];

            if (newSelectedReward) {
                adjustments.push(newSelectedReward);
            } else if (newSelectedReward !== null && selectedReward) {
                adjustments.push(selectedReward);
            }

            if (giftCard || giftCardNumber) {
                if (newTip) {
                    adjustments.push({
                        type: OrderAdjustmentType.TIP,
                        value: newTip.value,
                        isPercentage: newTip.isPercentage
                    });
                } else if (tip) {
                    if (settings?.tipping.scheme === ETipScheme.PERCENTAGE && userSelectedTipValue) {
                        adjustments.push({
                            type: OrderAdjustmentType.TIP,
                            value: roundToDecimal(userSelectedTipValue / 100, 3),
                            isPercentage: true
                        });
                    } else {
                        adjustments.push({
                            type: OrderAdjustmentType.TIP,
                            value: tip
                        });
                    }
                }
            }
            if (settings && settings.giftCardPaymentEnabled && settings.giftCardPaymentProvider) {
                if (giftCardNumber) {
                    payments.push({
                        type: OrderPaymentType.GIFT_CARD,
                        provider: settings.giftCardPaymentProvider,
                        auth: {
                            giftCardNumber
                        }
                    });
                } else if (giftCardNumber !== null && giftCard) {
                    payments.push({
                        type: OrderPaymentType.GIFT_CARD,
                        provider: settings.giftCardPaymentProvider,
                        auth: {
                            giftCardNumber: giftCard
                        }
                    });
                }
            }
            setIsBillLoading(true);
            const orderDetailsRes = await legacyOrderApi
                .getCheckoutDetails(
                    String(orderDetails.data?.id),
                    adjustments,
                    Number(billSplittingPart),
                    claimedItemIds,
                    payments
                )
                .catch(err => {
                    if (err.data.code === 'E-ORD-6806') {
                        enqueueSnackbar(t('CHECKOUT_GIFTCARD_INVALID_FORMAT'), { variant: 'error' });
                    } else if (err.data.code === 'E-ORD-0358') {
                        enqueueSnackbar(err.data.message, { variant: 'error' });
                    }
                })
                .finally(() => setIsBillLoading(false));
            if (orderDetailsRes) {
                setOrderDetails(() => Promise.resolve(orderDetailsRes));
            }
        },
        [
            billSplittingPart,
            claimedItemIds,
            enqueueSnackbar,
            giftCard,
            orderDetails.data?.id,
            selectedReward,
            setOrderDetails,
            settings,
            t,
            tip,
            userSelectedTipValue
        ]
    );

    const handleApplyGiftCard = React.useCallback(
        async (giftCardNumber: string | undefined) => {
            setGiftCard(giftCardNumber);
            await recalculateCheckoutDetails({
                giftCardNumber: !isDefined(giftCardNumber) ? null : giftCardNumber
            });
        },
        [recalculateCheckoutDetails]
    );
    const resetOrderCustomizations = React.useCallback(() => {
        setGiftCard(undefined);
        setTip(0);
    }, []);
    const handleTipChange = React.useCallback(
        async (tipValue: number, selectorValue?: number) => {
            if (selectorValue) {
                setUserSelectedTipValue(selectorValue);
            } else {
                setUserSelectedTipValue(undefined);
            }
            setTip(tipValue);
            if (giftCard) {
                await recalculateCheckoutDetails({
                    newTip: {
                        isPercentage: !!(selectorValue && settings?.tipping.scheme === ETipScheme.PERCENTAGE),
                        value:
                            selectorValue && settings?.tipping.scheme === ETipScheme.PERCENTAGE
                                ? roundToDecimal(selectorValue / 100, 3)
                                : tipValue
                    }
                });
            }
        },
        [giftCard, recalculateCheckoutDetails, settings?.tipping?.scheme]
    );
    const handleRewardSelected = React.useCallback(
        (userSelectedReward: IAdjustmentReadResourceV10 | undefined) => {
            if (orderDetails.data) {
                recalculateCheckoutDetails({
                    newSelectedReward: !isDefined(userSelectedReward)
                        ? null
                        : (pick(userSelectedReward, ['awardId', 'quantity']) as IAdjustmentReadResourceV10)
                });
            }
            setSelectedReward(
                userSelectedReward
                    ? (pick(userSelectedReward, [
                          'awardId',
                          'quantity',
                          'type'
                      ]) as IAdjustmentReadResourceV10)
                    : undefined
            );
        },
        [orderDetails.data, recalculateCheckoutDetails]
    );

    React.useEffect(() => {
        const localBasket = getLocalBasket();
        if (localBasket) {
            setBasket(localBasket)(dispatch);
        }
    }, [dispatch]);

    React.useEffect(() => {
        if ((deliveryLocation || checkId) && locationId) {
            let joiningDetails: {
                deliveryLocation?: string | undefined;
                checkId?: string | undefined;
                locationId: string;
                createIfNotFound?: boolean;
            } = { locationId };
            if (settings) {
                const retrievalBy = settings.payAtTable?.retrievalBy;
                if (retrievalBy === 'CHECK_NUMBER' && checkId) {
                    joiningDetails = {
                        locationId,
                        checkId
                    };
                } else if (retrievalBy === 'TABLE_NUMBER' && deliveryLocation) {
                    joiningDetails = {
                        locationId,
                        deliveryLocation,
                        createIfNotFound: settings.orderToTable?.multiPartEnabled
                    };
                } else if (retrievalBy === 'BOTH' && (deliveryLocation || checkId)) {
                    joiningDetails = checkId
                        ? {
                              locationId,
                              checkId
                          }
                        : {
                              locationId,
                              deliveryLocation,
                              createIfNotFound: settings.orderToTable?.multiPartEnabled
                          };
                } else if (!retrievalBy) {
                    joiningDetails = {
                        locationId,
                        deliveryLocation,
                        createIfNotFound: settings.orderToTable?.multiPartEnabled
                    };
                }
            }
            if (joiningDetails.checkId || joiningDetails.deliveryLocation) {
                setSelectedReward(undefined);
                setOperationErrorInitial();
                setOrderDetailsInitial();
                setIsBillLoading(true);
                setOrderNotFound(false);
                setJoinOrderDetails(() => legacyOrderApi.joinOrder(joiningDetails)).then(response => {
                    legacyOrderApi.nonceHandlingByOperationStatus(response.status, EOrderNonceKey.JOIN_ORDER);
                    async function getOperationStatus() {
                        if (response.id) {
                            const operation: IOperation = await legacyOrderApi.getOperation(
                                response.id?.toString()
                            );
                            switch (operation.status) {
                                case OperationStatus.PENDING:
                                    // Recursive Poll for order completion
                                    setIsBillLoading(true);
                                    setTimeout(() => {
                                        getOperationStatus();
                                    }, OPERATION_POLLING_DELAY);

                                    legacyOrderApi.resetNonceByKey(EOrderNonceKey.JOIN_ORDER);
                                    break;
                                case OperationStatus.ERROR:
                                    if (
                                        operation.errorCode === OrderErrorCodes.ORDER_NOT_FOUND ||
                                        operation.httpCode === 404
                                    ) {
                                        setOrderNotFound(true);
                                        setIsBillLoading(false);
                                    } else {
                                        setOperationError(() => Promise.resolve(operation));
                                        setIsBillLoading(false);
                                    }
                                    break;
                                case OperationStatus.TIMEOUT:
                                    throw new Error(operation.errorMessage || 'Operation error or timeout');
                                case OperationStatus.DONE: {
                                    if (!!operation) {
                                        try {
                                            if (
                                                settings?.billSplittingByItemEnabled &&
                                                claimedItemIds &&
                                                isSplitBillItem
                                            ) {
                                                const orderResponse = await setOrderDetails(() =>
                                                    legacyOrderApi.getCheckoutDetails(
                                                        String(operation.resultId),
                                                        [],
                                                        undefined,
                                                        claimedItemIds
                                                    )
                                                );
                                                if (user) {
                                                    const mappedItems = orderService.getSplittableItemMap(
                                                        orderResponse,
                                                        user
                                                    );
                                                    const userClaimItemIds =
                                                        orderService.getUserClaimedItemIds(mappedItems, user);
                                                    if (
                                                        isEqual(
                                                            claimedItemIds.sort(),
                                                            userClaimItemIds.sort()
                                                        )
                                                    ) {
                                                        setIsBillLoading(false);
                                                    } else {
                                                        setClaimedItemIds(userClaimItemIds);
                                                    }
                                                }
                                            } else {
                                                await setOrderDetails(() =>
                                                    legacyOrderApi.getCheckoutDetails(
                                                        String(operation.resultId),
                                                        [],
                                                        Number(billSplittingPart)
                                                    )
                                                );
                                                setIsBillLoading(false);
                                            }
                                        } catch (err) {
                                            setOrderNotFound(true);
                                            setIsBillLoading(false);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (response.id) {
                        getOperationStatus();
                    }
                });
            } else {
                setIsBillLoading(false);
            }
        }
    }, [
        billSplittingPart,
        checkId,
        claimedItemIds,
        deliveryLocation,
        isSplitBillItem,
        locationId,
        setJoinOrderDetails,
        setOperationError,
        setOperationErrorInitial,
        setOrderDetails,
        setOrderDetailsInitial,
        settings,
        settings?.billSplittingByItemEnabled,
        user
    ]);

    const handleLeaveOrder = React.useCallback(() => {
        if (orderDetails.data && orderDetails.data.id) {
            return legacyOrderApi.leaveOrder(orderDetails.data.id);
        }

        return Promise.resolve();
    }, [orderDetails.data]);
    const shouldOpenLocationPicker = React.useMemo(() => {
        if (settings && settings.payAtTable) {
            switch (settings.payAtTable.retrievalBy) {
                case 'TABLE_NUMBER':
                    return !deliveryLocation;
                case 'CHECK_NUMBER':
                    return !checkId;
                case 'BOTH':
                    return !(checkId || deliveryLocation);
                default:
                    return !deliveryLocation;
            }
        }

        return false;
    }, [checkId, deliveryLocation, settings]);
    const isCheckSelectionEnabled = React.useMemo(
        () =>
            settings?.payAtTable?.retrievalBy === 'BOTH' ||
            settings?.payAtTable?.retrievalBy === 'CHECK_NUMBER',
        [settings?.payAtTable?.retrievalBy]
    );
    const renderTableAndCheckNumberPicker = React.useCallback(
        (onEdit: () => void) =>
            settings?.payAtTable?.retrievalBy === 'BOTH' ||
            settings?.payAtTable?.retrievalBy === 'CHECK_NUMBER' ? (
                <ViewBillContentSection bottomSpacing={4}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography>
                            {t('BILL_CHECK_ID', { checkNumber: checkId })}
                            {orderDetails.data?.checkId}
                        </Typography>
                        <Typography>
                            <Link onClick={onEdit}>{t('GENERAL_EDIT')}</Link>
                        </Typography>
                    </Box>
                </ViewBillContentSection>
            ) : null,
        [checkId, settings?.payAtTable?.retrievalBy, orderDetails.data?.checkId, t]
    );
    const handleViewMenu = React.useCallback(() => window.open(menuLink, '_blank'), [menuLink]);
    const operationErrorMessage = React.useMemo(() => {
        if (operationError && operationError.data) {
            switch (operationError.data.errorCode) {
                case 'E-ORD-0085':
                    return t('PAT_PAYMENT_CARD_REQUIRED');

                default:
                    return operationError.data.errorMessage || '';
            }
        }

        return operationError?.data?.errorMessage || '';
    }, [operationError, t]);
    const handleCardEnforcement = React.useCallback(() => {
        const merchantId = getLocalMerchantId();

        if (isGuest) {
            push(ROUTES.USER.LOGIN);
        } else {
            push(ROUTES.USER.PAYMENT_METHODS);
        }

        if (merchantId) {
            setOnCloseRedirectUrl(pathname, merchantId);
            setReturnUrl(pathname)(dispatch);
        }
    }, [dispatch, isGuest, pathname, push]);
    const errorContent = React.useMemo(() => {
        if (operationError && operationError.data) {
            switch (operationError.data.errorCode) {
                case 'E-ORD-0085':
                    return (
                        <Box
                            display="flex"
                            flexDirection="column"
                            flex={1}
                            justifyContent="center"
                            alignItems="center"
                            padding={2}
                        >
                            <ErrorOutline className={classes.basketError} />
                            <Typography align="center" variant="h5">
                                {t('GENERAL_GENERIC_ERROR')}
                            </Typography>
                            <Typography
                                align="center"
                                variant="h6"
                                color="textSecondary"
                                gutterBottom
                                className={classes.paragraph}
                            >
                                {operationErrorMessage}
                            </Typography>
                            {isGuest ? (
                                <Button color="primary" variant="contained" onClick={handleCardEnforcement}>
                                    {t('ONBOARDING_SIGN_IN')}
                                </Button>
                            ) : (
                                <Button color="primary" variant="contained" onClick={handleCardEnforcement}>
                                    {t('CHECKOUT_ADD_CARD')}
                                </Button>
                            )}
                        </Box>
                    );
                default:
                    return (
                        <EmptyState
                            headerText={t('GENERAL_GENERIC_ERROR')}
                            paragraphText={operationErrorMessage}
                        >
                            <ErrorOutline className={classes.basketError} />
                        </EmptyState>
                    );
            }
        }
    }, [
        classes.basketError,
        classes.paragraph,
        handleCardEnforcement,
        isGuest,
        operationError,
        operationErrorMessage,
        t
    ]);
    const hasError = React.useMemo(
        () =>
            isError(orderDetails) ||
            isError(joinOrderDetails) ||
            joinOrderDetails.data?.status === OperationStatus.ERROR ||
            joinOrderDetails.data?.status === OperationStatus.TIMEOUT,
        [joinOrderDetails, orderDetails]
    );
    const handleBack = React.useCallback(() => {
        handleLeaveOrder();
        push(ROUTES.JOURNEY.LANDING);
    }, [handleLeaveOrder, push]);
    return (
        <Box display="flex" height="100%" width="100%" className="hidden-scroll" flexDirection="column">
            {shouldOpenLocationPicker && (
                <InnerPageLayout>
                    <ViewBillHeader
                        onBack={handleBack}
                        onAuthActionClick={handleLeaveOrder}
                        title={t('VIEW_BILL_SCREEN_TITLE')}
                    />
                </InnerPageLayout>
            )}
            <OrderToTableLocationPicker
                isDialogOpenInitially={shouldOpenLocationPicker}
                pickerTitle="location"
                locationId={locationId}
                checkSelectionEnabled={isCheckSelectionEnabled}
                hidden
            />
            {(isLoading(joinOrderDetails) || isLoading(orderDetails) || isBillLoading) &&
                !orderDetails.data &&
                !hasError && (
                    <Box className={classes.pageContainer}>
                        <Throbber text={t('GENERAL_LOADING')} />
                    </Box>
                )}
            {!isBillLoading && !orderNotFound && operationError.data && operationError.data.errorMessage && (
                <Box className={classes.pageContainer}>
                    <ViewBillHeader
                        onBack={handleBack}
                        onAuthActionClick={handleLeaveOrder}
                        title={t('VIEW_BILL_SCREEN_TITLE')}
                    />
                    {errorContent}
                </Box>
            )}
            {hasError && (
                <>
                    <ViewBillHeader
                        onBack={handleBack}
                        onAuthActionClick={handleLeaveOrder}
                        title={t('VIEW_BILL_SCREEN_TITLE')}
                    />
                    <Box className={classes.pageContainer}>
                        <EmptyState
                            headerText={t('GENERAL_GENERIC_ERROR')}
                            paragraphText={
                                orderDetails.error?.message || joinOrderDetails.error?.message || ''
                            }
                        >
                            <ErrorOutline className={classes.basketError} />
                        </EmptyState>
                    </Box>
                </>
            )}
            {isSuccess(joinOrderDetails) && !hasError && orderNotFound && (
                <InnerPageLayout>
                    <ViewBillHeader
                        onBack={handleBack}
                        onAuthActionClick={handleLeaveOrder}
                        title={t('VIEW_BILL_SCREEN_TITLE')}
                    />
                    <InnerPageLayoutContent>
                        <OrderToTableLocationPicker
                            pickerTitle="location"
                            locationId={locationId}
                            renderPickerView={renderTableAndCheckNumberPicker}
                            checkSelectionEnabled={isCheckSelectionEnabled}
                        />
                        <Box
                            display="flex"
                            flexDirection="column"
                            flex={1}
                            justifyContent="center"
                            alignItems="center"
                            paddingY={2}
                        >
                            <Typography align="center" variant="h5">
                                {t('GENERAL_GENERIC_ERROR')}
                            </Typography>
                            <Typography
                                align="center"
                                variant="h6"
                                color="textSecondary"
                                gutterBottom
                                className={classes.paragraph}
                            >
                                {t('BILL_EMPTY_MESSAGE', {
                                    tableNumber: checkId
                                        ? t('BILL_CHECK_NUMBER', { checkId })
                                        : t('BILL_TABLE_NUMBER', { tableNumber: deliveryLocation })
                                })}
                            </Typography>
                            {!!menuLink && (
                                <Button
                                    onClick={handleViewMenu}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                >
                                    {t('VIEW_MENU_LINK')}
                                </Button>
                            )}
                        </Box>
                    </InnerPageLayoutContent>
                </InnerPageLayout>
            )}
            {!orderNotFound && !!orderDetails.data && !isError(orderDetails) && (
                <ViewBill
                    onRewardSelected={handleRewardSelected}
                    resetOrderCustomizations={resetOrderCustomizations}
                    selectedReward={selectedReward}
                    orderDetails={orderDetails.data}
                    onClaimItems={setClaimedItemIds}
                    userSelectedTipValue={userSelectedTipValue}
                    isLoading={isBillLoading || isLoading(orderDetails)}
                    onApplyGiftCard={handleApplyGiftCard}
                    giftCard={giftCard}
                    onTipChange={handleTipChange}
                    tipValue={tip}
                />
            )}
        </Box>
    );
};
