import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { ISettings } from 'components/settings/model/Settings';
import { ISquareCardData, PaymentProviderType } from 'components/user/model/User';
import { userApi } from 'components/user/userApi';
import logger from 'lib/logger';
import { isDefined } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import { LoadingButton } from 'ui/LoadingButton';
import { Card, TokenDetails } from './models';
import { SquareProvider } from './SquareProvider';
import { useSnackbar } from 'notistack';

interface IProps {
    settings: ISettings['square'];
    postCardAddition: () => void;
    squareInstance: SquareProvider;
}

export const SquareAddCard: React.FC<IProps> = ({ postCardAddition, squareInstance }) => {
    const { t } = useTranslation();
    const { isLoggedIn, hasSession } = useAuth();

    const [initialLoading, setInitialLoading] = React.useState(true);
    const [addCardInProgress, setAddCardInProgress] = React.useState(false);
    const [cardInstance, setCardInstance] = React.useState<Card | null>(null);
    const { enqueueSnackbar } = useSnackbar();

    const handleAddUserCard = React.useCallback(
        (cardDetails: ISquareCardData) => {
            setAddCardInProgress(true);
            userApi
                .createCard(cardDetails)
                .then(() => {
                    enqueueSnackbar(t('CHECKOUT_CARD_ADDED'), { variant: 'success' });
                    postCardAddition();
                })
                .catch(err => {
                    enqueueSnackbar(err.data.message, { variant: 'error' });
                })
                .finally(() => {
                    setAddCardInProgress(false);
                });
        },
        [enqueueSnackbar, postCardAddition, t]
    );

    const handleCardAdded = React.useCallback(
        (nonce?: string, details?: TokenDetails) => {
            const cardData = details?.card;
            if (!isDefined(nonce) || !isDefined(cardData)) {
                throw new Error('No nonce received');
            }
            if (!hasSession) {
                throw new Error('User is not authenticated');
            } else {
                squareInstance.threeDSecureAuthenticate({
                    square: {
                        cardId: nonce,
                        intent: 'STORE',
                        contact: { postalCode: details?.billing?.postalCode },
                        onVerificationEnd: (err, result) => {
                            if (err) {
                                enqueueSnackbar(err.message, { variant: 'error' });
                            } else {
                                setAddCardInProgress(true);
                                if (result?.token) {
                                    handleAddUserCard({
                                        cardType: PaymentProviderType.SQUARE,
                                        gatewayId: nonce,
                                        last4: cardData.last4,
                                        expires: `${cardData.expMonth}/${cardData.expYear}`,
                                        type: cardData.brand,
                                        token: result.token
                                    });
                                }
                            }
                        }
                    }
                });
            }
        },
        [enqueueSnackbar, handleAddUserCard, hasSession, squareInstance]
    );

    const tokenize = React.useCallback(async () => {
        if (cardInstance) {
            const result = await squareInstance.tokenize(cardInstance);
            handleCardAdded(result.token, result.details);
        }
    }, [cardInstance, handleCardAdded, squareInstance]);

    React.useEffect(() => {
        squareInstance
            .initCard()
            .then(card => {
                setCardInstance(card);
            })
            .catch(e => {
                logger.error('Initializing Card failed', e);
            })
            .finally(() => {
                setInitialLoading(false);
            });
    }, [squareInstance]);
    return (
        <Box height="100%" display="flex" flexDirection="column">
            <Box padding={2}>
                <div id="card-container" />
            </Box>
            <Box marginTop="auto" padding={2}>
                <LoadingButton
                    disabled={addCardInProgress || initialLoading}
                    loading={addCardInProgress}
                    onClick={tokenize}
                    color="primary"
                    variant="contained"
                    fullWidth
                >
                    {isLoggedIn ? t('CHECKOUT_ADD_CARD') : t('CHECKOUT_USE_CARD')}
                </LoadingButton>
            </Box>
        </Box>
    );
};
