import React from 'react';
import { IBasketResourceV10, IItemReadResourceV10 } from './model/Basket';
import { ViewBillOrderItem } from 'components/bill/ui/ViewBill/ViewBillOrderItem';
import { ViewBillContentSection } from 'components/bill/ui/ViewBill/ViewBillContentSection';
import { Box, Button, Theme, createStyles, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        clearBasketButton: {
            color: theme.palette.text.hint,
            fontSize: theme.spacing(2),
            textTransform: 'capitalize',
            fontWeight: 400
        }
    })
);

interface BasketItemsProps {
    calculatedBasket: IBasketResourceV10;
    onItemClick: (index: number, item?: IItemReadResourceV10) => void;
    onOpenClearBasketDialog: () => void;
}

export const BasketItems: React.FC<BasketItemsProps> = ({
    calculatedBasket,
    onItemClick,
    onOpenClearBasketDialog
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const basket = useSelector((state: ApplicationState) => state.basket);
    const renderOrderItem = React.useCallback(
        (item: IItemReadResourceV10, index: number) => (
            <ViewBillOrderItem
                onClick={onItemClick}
                index={index}
                orderItem={item}
                allItems={calculatedBasket?.items ?? []}
                key={item.id}
            />
        ),
        [calculatedBasket, onItemClick]
    );
    return (
        <ViewBillContentSection bottomSpacing={2}>
            {calculatedBasket.items.length > 0 && basket.items.length > 0 && (
                <>
                    {calculatedBasket.items.map(renderOrderItem)}
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Button onClick={onOpenClearBasketDialog} className={classes.clearBasketButton}>
                            {t('CHECKOUT_EMPTY_BASKET_TITLE')}
                        </Button>
                    </Box>
                </>
            )}
        </ViewBillContentSection>
    );
};
