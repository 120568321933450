/* eslint-disable max-len */
import * as React from 'react';
import { SVGIconProps } from './models';

interface IProps extends SVGIconProps {
    color?: string;
}

export const PickUpIcon: React.FC<IProps> = props => (
    <svg width="1em" height="1em" viewBox="0 0 59 72" xmlns="http://www.w3.org/2000/svg" {...props}>
        <title>{'PickUpIcon'}</title>
        <g fill="none" fillRule="evenodd">
            <path d="M5.393 1H55.42l-4.48 6.646 5.973 4.43H3.9l6.72-4.43z" />
            <g transform="translate(1 1.323)" stroke="inherit">
                <path
                    d="M.378 42.251V12.554c0-1.224 1.015-2.216 2.267-2.216h51.878c.816 0 1.477.662 1.477 1.477v53.908a2.953 2.953 0 01-2.953 2.954H3.33a2.953 2.953 0 01-2.953-2.954V51.692M.373 52.8V41.723"
                    strokeWidth={2.57}
                    strokeLinecap="round"
                />
                <path
                    d="M1.867 10.338V1.477C1.867.66 2.467 0 3.207 0h49.586c.74 0 1.34.661 1.34 1.477v8.861"
                    strokeWidth={2.483}
                />
                <ellipse strokeWidth={2.286} cx={15.68} cy={23.262} rx={2.613} ry={2.585} />
                <ellipse strokeWidth={2.286} cx={40.32} cy={23.262} rx={2.613} ry={2.585} />
                <path
                    d="M39.947 25.846c0 6.526-5.349 11.816-11.947 11.816-6.598 0-11.947-5.29-11.947-11.816"
                    strokeWidth={2.483}
                    strokeLinecap="round"
                />
                <path
                    d="M2.659.736l5.295 5.17a.746.746 0 01-.163 1.188l-5.924 3.244h0"
                    strokeWidth={2.286}
                    strokeLinecap="round"
                />
                <path
                    d="M53.468.79L48.4 5.902a.746.746 0 00.176 1.182l6.032 3.254h0"
                    strokeWidth={2.483}
                    strokeLinecap="round"
                />
            </g>
        </g>
    </svg>
);
