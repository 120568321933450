/* eslint-disable react/no-multi-comp */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Button, createStyles, Dialog, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { ApplicationState } from 'store/store';

export interface PrepTimeModalInfo {
    onSuccess: () => void;
    onCancel: () => void;
    timePadding: number;
    productTitle: string;
}

interface IProps {
    info?: PrepTimeModalInfo;
    onCancel: () => void;
    open: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            color: theme.palette.titleTextColour
        }
    })
);

const MenuProductInfoTimePadding: React.FC<PrepTimeModalInfo> = ({
    onCancel,
    onSuccess,
    timePadding,
    productTitle
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    if (!settings) {
        return null;
    }
    const {
        preOrder: { readyPeriodStart, readyPeriodEnd }
    } = settings;
    const from = String((readyPeriodStart || 0) + timePadding);
    const to = String((readyPeriodEnd || 0) + timePadding);
    return (
        <Box padding={2}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" color="primary" className={classes.title}>
                        {t('DIALOG_PREORDER_PREP_TIME_CONFIRMATION_TITLE')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1">
                        {t('DIALOG_PREORDER_PREP_TIME_CONFIRMATION_BODY', {
                            product: productTitle,
                            prepTimePadding: String(timePadding),
                            from,
                            to
                        })}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box justifyContent="flex-end" width="100%" display="flex">
                        <Box marginRight={2.25}>
                            <Button variant="outlined" color="primary" onClick={onCancel}>
                                {t('DIALOG_PREORDER_PREP_TIME_CONFIRMATION_CANCEL')}
                            </Button>
                        </Box>
                        <Button variant="contained" color="primary" onClick={onSuccess}>
                            {t('DIALOG_PREORDER_PREP_TIME_CONFIRMATION_OK')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export const MenuProductInfoTimePaddingDialog: React.FC<IProps> = ({ open, onCancel, info }) => (
    <Dialog open={open} onClose={onCancel}>
        {!!info && <MenuProductInfoTimePadding {...info} />}
    </Dialog>
);
