import React from 'react';
import { useTranslation } from 'react-i18next';
import InView from 'react-intersection-observer';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    createStyles,
    Divider,
    ListItem,
    ListItemText,
    makeStyles,
    Theme
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import throttle from 'lodash/throttle';
import { IOrderReadResourceV12 } from 'components/order/model/Order';
import { RecentOrder } from 'components/recentOrders/RecentOrder';
import { RecentOrderItemsSelection } from 'components/recentOrders/RecentOrderItemsSelection';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            padding: theme.spacing(0),
            flexDirection: 'column'
        },
        panelTitle: {
            fontWeight: 'bold',
            color: theme.palette.titleTextColour,
            marginLeft: theme.spacing(1.5)
        },
        panel: {
            width: '100%',
            '&::before': {
                height: 0
            },
            marginTop: '0'
        },
        expansionPanelSummary: {
            alignItems: 'flex-start',
            padding: theme.spacing(0, 1.5, 0, 0),
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        expansionPanelIcon: {
            color: theme.palette.text.primary,
            margin: theme.spacing(0, -3, 0, 0),
            '& svg': {
                fontSize: '32px'
            },
            alignSelf: 'center'
        },
        expansionPanelDetails: {
            flexDirection: 'column',
            paddingLeft: 0,
            paddingRight: 0
        },
        iconColor: {
            color: theme.palette.titleTextColour
        }
    })
);

interface IRecentOrdersListProps {
    orders: IOrderReadResourceV12[];
    isCollapsible?: boolean;
    setActiveCategory?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const RecentOrdersList: React.FC<IRecentOrdersListProps> = ({
    orders,
    isCollapsible = true,
    setActiveCategory
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [selectedRecentOrder, setSelectedRecentOrder] = React.useState<IOrderReadResourceV12 | null>();

    const handleRecentOrderClick = React.useCallback(
        (order: IOrderReadResourceV12) => {
            setSelectedRecentOrder(order);
        },
        [setSelectedRecentOrder]
    );

    const renderOrderItem = React.useCallback(
        (order: IOrderReadResourceV12) => (
            <RecentOrder key={order.id} order={order} onOrderClick={handleRecentOrderClick} />
        ),
        [handleRecentOrderClick]
    );

    const handleRecentOrderSelectionClose = React.useCallback(() => {
        setSelectedRecentOrder(null);
    }, []);

    const handleActiveCategory = React.useMemo(
        () =>
            throttle((inView: boolean) => {
                if (inView && setActiveCategory) {
                    setActiveCategory('recent-orders');
                }
            }, 800),
        [setActiveCategory]
    );

    if (!!orders.length) {
        return (
            <ListItem className={classes.listItem}>
                {isCollapsible ? (
                    <Accordion
                        elevation={0}
                        TransitionProps={{ unmountOnExit: true }}
                        className={classes.panel}
                    >
                        <AccordionSummary
                            className={classes.expansionPanelSummary}
                            classes={{
                                expandIcon: classes.expansionPanelIcon
                            }}
                            expandIcon={<ExpandMoreIcon className={classes.iconColor} />}
                        >
                            <Box width="100%">
                                <ListItemText
                                    primaryTypographyProps={{ className: classes.panelTitle, variant: 'h5' }}
                                    primary={t('PREORDER_PREVIOUS_ORDERS')}
                                />
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails classes={{ root: classes.expansionPanelDetails }}>
                            {orders.map(renderOrderItem)}
                        </AccordionDetails>
                    </Accordion>
                ) : (
                    <InView
                        rootMargin={'-110px 0px -80% 0px'}
                        onChange={handleActiveCategory}
                        style={{ width: '100%' }}
                        key="recent-orders"
                    >
                        <Box width="100%" marginTop={2} marginBottom={1}>
                            <ListItemText
                                primaryTypographyProps={{ className: classes.panelTitle, variant: 'h5' }}
                                primary={t('PREORDER_PREVIOUS_ORDERS')}
                            />
                        </Box>
                        <Divider />
                        <div> {orders.map(renderOrderItem)}</div>
                    </InView>
                )}
                {!!selectedRecentOrder && (
                    <RecentOrderItemsSelection
                        open={!!selectedRecentOrder}
                        onClose={handleRecentOrderSelectionClose}
                        order={selectedRecentOrder}
                    />
                )}
            </ListItem>
        );
    }

    return null;
};
