import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { useAsyncData } from 'src/hooks/useAsyncData';
import { IAdjustmentReadResourceV10 } from 'components/basket/model/Basket';
import { IOperation, OperationStatus } from 'components/order/model/Operation';
import { IOrderJoinDetails, IOrderReadResourceV18, OrderErrorCodes } from 'components/order/model/Order';
import { EOrderNonceKey, orderApi } from 'components/order/orderApi';
import { orderService } from 'components/order/orderService';
import { OPERATION_POLLING_DELAY } from 'config/constants';
import { isDefined } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import { LocationRouteParams } from 'pages/routes';
import { ApplicationState } from 'store/store';
import { usePayQueryParams } from './usePayDateCheckId';

export interface RecalculateOrderParams {
    newSelectedReward?: IAdjustmentReadResourceV10 | null;
}

export function usePayAtTable() {
    const { merchantId, locationId } = useParams<LocationRouteParams>();
    const { checkId, tableNumber, date } = usePayQueryParams();
    const [isLoading, setIsLoading] = React.useState(true);
    const [isOrderNotFound, setIsOrderNotFound] = React.useState(false);
    const [claimedItemIds, setClaimedItemIds] = React.useState<number[]>([]);

    const { user } = useAuth();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const isSplitBillItem = false;

    const [orderDetails, setOrderDetails, setOrderDetailsInitial] = useAsyncData<IOrderReadResourceV18>();
    const [joinOrderDetails, setJoinOrderDetails] = useAsyncData<IOperation>();
    const [operationError, setOperationError, setOperationErrorInitial] = useAsyncData<IOperation>();

    React.useEffect(() => {
        if ((isDefined(tableNumber) || isDefined(checkId)) && locationId && user) {
            const joiningDetails: IOrderJoinDetails = isDefined(checkId)
                ? {
                      locationId,
                      checkId
                  }
                : {
                      locationId,
                      deliveryLocation: tableNumber
                  };
            if (isDefined(date)) {
                joiningDetails.checkDate = date;
            }
            if (joiningDetails.checkId || joiningDetails.deliveryLocation) {
                setOperationErrorInitial();
                setOrderDetailsInitial();
                setIsLoading(true);
                setIsOrderNotFound(false);
                setJoinOrderDetails(() => orderApi.joinOrder(joiningDetails)).then(response => {
                    orderApi.nonceHandlingByOperationStatus(response.status, EOrderNonceKey.JOIN_ORDER);
                    async function getOperationStatus() {
                        if (response.id) {
                            const operation: IOperation = await orderApi.getOperation(
                                response.id?.toString()
                            );
                            switch (operation.status) {
                                case OperationStatus.PENDING:
                                    // Recursive Poll for order completion
                                    setIsLoading(true);
                                    setTimeout(() => {
                                        getOperationStatus();
                                    }, OPERATION_POLLING_DELAY);

                                    orderApi.resetNonceByKey(EOrderNonceKey.JOIN_ORDER);
                                    break;
                                case OperationStatus.ERROR:
                                    if (
                                        operation.errorCode === OrderErrorCodes.CHECK_NOT_FOUND ||
                                        operation.errorCode === OrderErrorCodes.ORDER_NOT_FOUND ||
                                        operation.httpCode === 404
                                    ) {
                                        setIsOrderNotFound(true);
                                        setIsLoading(false);
                                    } else {
                                        setOperationError(() => Promise.resolve(operation));
                                        setIsLoading(false);
                                    }
                                    break;
                                case OperationStatus.TIMEOUT:
                                    throw new Error(operation.errorMessage || 'Operation error or timeout');
                                case OperationStatus.DONE: {
                                    if (!!operation) {
                                        try {
                                            if (
                                                settings?.billSplittingByItemEnabled &&
                                                claimedItemIds &&
                                                isSplitBillItem
                                            ) {
                                                const orderResponse = await setOrderDetails(() =>
                                                    orderApi.getCheckoutDetails(
                                                        String(operation.resultId),
                                                        [],
                                                        undefined,
                                                        claimedItemIds
                                                    )
                                                );
                                                if (user) {
                                                    const mappedItems = orderService.getSplittableItemMap(
                                                        orderResponse,
                                                        user
                                                    );
                                                    const userClaimItemIds =
                                                        orderService.getUserClaimedItemIds(mappedItems, user);
                                                    if (
                                                        isEqual(
                                                            claimedItemIds.sort(),
                                                            userClaimItemIds.sort()
                                                        )
                                                    ) {
                                                        setIsLoading(false);
                                                    } else {
                                                        setClaimedItemIds(userClaimItemIds);
                                                    }
                                                }
                                            } else {
                                                await setOrderDetails(() =>
                                                    orderApi.getCheckoutDetails(
                                                        String(operation.resultId),
                                                        [],
                                                        undefined,
                                                        [],
                                                        []
                                                    )
                                                );
                                                setIsLoading(false);
                                            }
                                        } catch (err) {
                                            setIsOrderNotFound(true);
                                            setIsLoading(false);
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (response.id) {
                        getOperationStatus();
                    }
                });
            } else {
                setIsLoading(false);
            }
        }
    }, [
        checkId,
        claimedItemIds,
        date,
        isSplitBillItem,
        locationId,
        setJoinOrderDetails,
        setOperationError,
        setOperationErrorInitial,
        setOrderDetails,
        setOrderDetailsInitial,
        settings,
        settings?.billSplittingByItemEnabled,
        tableNumber,
        user,
        merchantId
    ]);

    return {
        isLoading,
        isOrderNotFound,
        orderDetails,
        operationError,
        joinOrderDetails,
        setOperationErrorInitial
    };
}
