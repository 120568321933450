import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'src/hooks/useQuery';
import { makeRouteScenario } from 'src/utils/route';
import { ErrorLayout } from 'app/ErrorLayout';
import { isOrderScenario, OrderScenario } from 'components/order/model/Order';
import { getLocalMerchantId } from 'components/settings/localStore';
import { Throbber } from 'lib/Throbber';
import { useLocalHistory } from 'lib/useLocalHistory';
import { resetReturnUrl } from 'store/auth/authActions';
import { resetBasket, setBasket, setDeliveryLocation } from 'store/basket/basketActions';
import { ROUTES } from './routes';
import { isQuickpayEnabled } from 'components/pay/model/isQuickpayEnabled';
import { ApplicationState } from 'store/store';

export const OrderEntryPage: React.FC = () => {
    const tableNumber = useQuery('tableNumber');
    const scenario = useQuery('scenario');
    const locationId = useQuery('locationId');
    const { t } = useTranslation();
    const { push, getParsedPath } = useLocalHistory();
    const dispatch = useDispatch();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const parsedScenario = React.useMemo(() => (!!scenario ? scenario : OrderScenario.PREORDER), [scenario]);
    const hasInvalidParam = React.useMemo(
        () => !locationId && parsedScenario === OrderScenario.ORDER_TO_TABLE,
        [locationId, parsedScenario]
    );
    const description = hasInvalidParam ? t('ORDER_ENTRY_INVALID_LINK_PARAM') : t('ORDER_ENTRY_INVALID_LINK');
    const validationError = React.useMemo(() => {
        if (!isOrderScenario(scenario)) {
            return `Error: ${scenario} is an invalid Scenario`;
        }
        if (hasInvalidParam) {
            return 'Invalid Link: Missing Location';
        }
        return '';
    }, [hasInvalidParam, scenario]);

    const isQuickpayAvailable = React.useMemo(() => isQuickpayEnabled(settings), [settings]);

    // This effect should take all the information from the URL, and set it in the correct places
    // after which it should redirect the user to the correct page, for it to handle the scenario further
    React.useEffect(() => {
        if (scenario && !!validationError) {
            return; // Something isn't valid. Ignore this request
        }

        // Reset previous states that might have been loaded from LocalStorage
        resetReturnUrl(dispatch);
        resetBasket(dispatch);
        let searchState = undefined;

        if (!!tableNumber) {
            setDeliveryLocation(tableNumber)(dispatch);
            searchState = `tableNumber=${tableNumber}`;
        }

        // If we have a location, redirect to the menu page
        if (!!locationId) {
            const merchantId = getLocalMerchantId();
            if (scenario) {
                setBasket({ locationId })(dispatch);
                if (merchantId) {
                    if (scenario === OrderScenario.TABLE) {
                        if (isQuickpayAvailable) {
                            push(
                                ROUTES.QUICKPAY.CHECKOUT,
                                {
                                    merchantId,
                                    locationId,
                                    scenario: makeRouteScenario(scenario)
                                },
                                searchState
                            );
                        } else {
                            push(ROUTES.JOURNEY.PAT.BILL, {
                                merchantId,
                                locationId,
                                scenario: makeRouteScenario(scenario)
                            });
                        }
                        return;
                    }
                }
            } else {
                setBasket({ locationId })(dispatch);
                if (merchantId) {
                    push(ROUTES.JOURNEY.LANDING, { merchantId, locationId });
                    return;
                }
            }
            push(ROUTES.MENU, { locationId, scenario: makeRouteScenario(scenario as OrderScenario) });
            return;
        }

        // Redirect to Location List page
        push(ROUTES.BASE, { scenario });
    }, [validationError, locationId, scenario, tableNumber, dispatch, push, isQuickpayAvailable]);

    const fallbackUrl = React.useMemo(() => getParsedPath(ROUTES.BASE), [getParsedPath]);

    return (
        <>
            {!!validationError && (
                <ErrorLayout
                    title={t('GENERAL_GENERIC_ERROR')}
                    description={description}
                    fallbackTitle="Home"
                    fallbackUrl={fallbackUrl}
                    errorMessage={validationError}
                />
            )}
            {!validationError && <Throbber text={t('ORDER_ENTRY_LOADING')} />}
        </>
    );
};
