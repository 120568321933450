import { ApplePayPaymentDetails } from 'src/integrations/PaymentProvider/ApplePayButton';
import {
    CurrencyCode,
    IAdjustmentReadResourceV10 as IBasketAdjustmentReadResourceV10,
    IBasketAdjustment,
    IBasketItemV10,
    IBasketTotal,
    IItemReadResourceV10,
    isBasketTotal,
    isItemReadResourceV10
} from 'components/basket/model/Basket';
import { Timeslot } from 'components/timeslots/model/Timeslot';
import { isArrayOf, isBoolean, isNumber, isOptional, isOptionalString, isString } from 'lib/typeInference';
import { GooglePayPaymentDetails } from 'src/integrations/GooglePay';

export enum OrderErrorCodes {
    UPDATING_THE_BILL = 'E-ORD-3028',
    DUPLICATE_PENDING_ORDER = 'E-ORD-0900',
    DUPLICATE_ORDER_NONCE = 'E-ORD-0900',
    ORDER_NOT_FOUND = 'E-ORD-0317',
    CHECK_NOT_FOUND = 'E-ORD-0307',
    TIMESLOT_NOT_AVAILABLE = 'E-ORD-0366'
}

export enum OrderScenario {
    TAB = 'TAB',
    TABLE = 'TABLE',
    PAYATPOS = 'PAYATPOS',
    PREORDER = 'PREORDER',
    ORDER_TO_TABLE = 'ORDER_TO_TABLE'
}

export enum GiftCardProvider {
    POWERCARD = 'POWERCARD'
}

export function isOrderScenario(input: any): input is OrderScenario {
    return input in OrderScenario;
}

export interface IUserReadResourceV10 {
    id: number;
    userId: string;
    firstName: string;
    lastName: string;
    orderId?: number;
    isPrimary: boolean;
    hasLeft: boolean;
    claimedItemIds: number[];
    claimedItemTotal?: IBasketTotal;
}

function isUserReadResource(input: any): input is IUserReadResourceV10 {
    return (
        isNumber(input.id) &&
        isString(input.userId) &&
        isOptionalString(input.firstName) &&
        isOptionalString(input.lastName) &&
        isOptional(isNumber, input.orderId) &&
        isBoolean(input.isPrimary) &&
        isBoolean(input.hasLeft) &&
        isArrayOf(isNumber, input.claimedItemIds) &&
        isOptional(isBasketTotal, input.claimedItemTotal)
    );
}

export enum OrderAdjustmentType {
    DISCOUNT = 'DISCOUNT',
    TIP = 'TIP',
    CHARGE = 'CHARGE',
    REFUND = 'REFUND'
}

function isOrderAdjustmentType(input: any): input is OrderAdjustmentType {
    return input in OrderAdjustmentType;
}

export interface IAdjustmentReadResourceV10 {
    id: number;
    userId: string;
    awardId?: string;
    parentId?: number;
    paymentId?: number;
    pointsRedeemed?: number;
    description?: string;
    isAllocation: boolean;
    quantity?: number;
    type: OrderAdjustmentType;
    value: number;
    deleted?: boolean;
    taxToAdd?: number;
    charges?: number;
}

function isAdjustmentReadResource(input: any): input is IAdjustmentReadResourceV10 {
    return (
        isNumber(input.id) &&
        isString(input.userId) &&
        isOptionalString(input.awardId) &&
        isOptional(isNumber, input.parentId) &&
        isOptional(isNumber, input.paymentId) &&
        isOptional(isNumber, input.pointsRedeemed) &&
        isOptionalString(input.description) &&
        isBoolean(input.isAllocation) &&
        isNumber(input.quantity) &&
        isOrderAdjustmentType(input.type) &&
        isNumber(input.value) &&
        isOptional(isBoolean, input.deleted)
    );
}

interface ISplitTotal {
    splitTotal: number;
    splittableTotal: number;
}

export interface IOrderTotal extends IBasketTotal, ISplitTotal {
    tips: number;
    balance: number;
}

function isOrderTotal(input: any): input is IOrderTotal {
    return isNumber(input.tips) && isNumber(input.balance) && isBasketTotal(input);
}

export enum OrderPaymentType {
    CASH_AT_POS = 'CASH_AT_POS',
    CARD_AT_POS = 'CARD_AT_POS',
    APPLEPAY = 'APPLEPAY',
    GOOGLEPAY = 'GOOGLEPAY',
    CARD_ON_FILE = 'CARD_ON_FILE',
    GIFT_CARD = 'GIFT_CARD',

    // deprecated values:
    CASH = 'CASH', // same as CASH_AT_POS
    CARD = 'CARD', // same as CARD_AT_POS
    OTHER = 'OTHER',
    APP = 'APP' // same as CARD_ON_FILE
}

function isOrderPaymentType(input: any): input is OrderPaymentType {
    return input in OrderPaymentType;
}

export interface IOrderPaymentReadResource {
    id?: number;
    userId?: string;
    type: OrderPaymentType;
    itemIds?: number[];
    currencyCode?: CurrencyCode;
    amount?: number;
    completedAt?: string;
    deleted?: boolean;
    remainingBalance?: number;
    balance?: number;
    auth?: {
        nonce?: string; // Token / ReceiptId returned from 3D Secure Payment Gateway SDKs
        clientDetails?: string; // AntiFraud payload from the Payment Gateway SDK
    };
}

function isOrderPaymentReadResource(input: any): input is IOrderPaymentReadResource {
    return (
        isOptional(isNumber, input.id) &&
        isString(input.userId) &&
        isOrderPaymentType(input.type) &&
        isArrayOf(isNumber, input.itemIds) &&
        isOptionalString(input.currencyCode) &&
        isOptional(isNumber, input.amount) &&
        isOptionalString(input.completedAt) &&
        isOptional(isBoolean, input.deleted)
    );
}

export interface IOrderReadResourceV10 {
    id: number;
    externalId?: string;
    primaryUserId: string;
    locationId: string;
    deliveryLocation?: string;
    checkId?: string;
    note?: string;
    currencyCode: CurrencyCode;
    timeSlot?: Timeslot;
    scenario: OrderScenario;
    pin: string;
    passphrase: string;
    isOpen: boolean;
    nonce: string;
    users: IUserReadResourceV10[];
    items: IItemReadResourceV10[];
    availableAdjustments: IBasketAdjustmentReadResourceV10[];
    adjustments: IBasketAdjustmentReadResourceV10[];
    payments: IOrderPaymentReadResource[];
    createdAt?: Date;
    total: IOrderTotal;
    splitTotal?: IOrderTotal;
    splitNumber?: number;
    prepTimeMins?: number;
}

export interface IUserReadResourceV12 {
    id: number;
    userId: string;
    firstName?: string;
    lastName?: string;
    orderId?: number;
    isPrimary: boolean;
    hasLeft: boolean;
    claimedItemIds: number[];
}

export interface ISplitOrderDetails {
    balanceExcClaimedItems?: number;
    splits?: number;
    splitsPaid?: number;
    splitsToPay?: number;
    total?: number;
}

export interface IOrderReadResourceV12 {
    id: number;
    externalId?: string;
    locationId: string;
    deliveryLocation?: string;
    note?: string;
    currencyCode: CurrencyCode;
    scenario: OrderScenario;
    pin: string;
    passphrase: string;
    isOpen: boolean;
    users: IUserReadResourceV12[];
    items: IItemReadResourceV10[];
    adjustments: IBasketAdjustmentReadResourceV10[];
    payments: IOrderPaymentReadResource[];
    createdAt?: Date;
    prepTimeMins?: number;
    isPending?: boolean;
    timeSlot?: Timeslot;
    primaryUserId?: string;
    checkId?: string;
    splitNumber?: number;
    total: IOrderTotal;
    splitTotal?: IOrderTotal;
    availableAdjustments: IAdjustmentReadResourceV10[];
}

export type IOrderReadResourceV18 = IOrderReadResourceV12 & {
    splitTotal?: ISplitOrderDetails;
};
export interface IUpdateOrderAdjustment {
    awardId?: string;
    quantity?: number;
    value?: number;
    type?: OrderAdjustmentType;
}

export interface IUpdateOrderDetails {
    id: number;
    isOpen: boolean;
    deliveryLocation: string | undefined;
    adjustments: IUpdateOrderAdjustment[];
    payments: IOrderReadResourceV10['payments'];
    total: IOrderReadResourceV10['total'];
    splitNumber?: number;
}

export function isOrderReadResource(input: any): input is IOrderReadResourceV10 {
    return (
        isNumber(input.id) &&
        isOptionalString(input.externalId) &&
        isString(input.primaryUserId) &&
        isString(input.locationId) &&
        isOptionalString(input.deliveryLocation) &&
        isOptionalString(input.note) &&
        isString(input.currencyCode) &&
        isOrderScenario(input.scenario) &&
        isString(input.pin) &&
        isString(input.passphrase) &&
        isBoolean(input.isOpen) &&
        isString(input.nonce) &&
        isArrayOf(isUserReadResource, input.users) &&
        isArrayOf(isItemReadResourceV10, input.items) &&
        isArrayOf(isAdjustmentReadResource, input.adjustments) &&
        isArrayOf(isOrderPaymentReadResource, input.payments) &&
        isOrderTotal(input.total)
    );
}

// ----------------------------------------------------
// ORDER CREATION SCHEMA
// ----------------------------------------------------

interface IOrderCreateModifierV8 {
    id: string;
    name?: string;
    options: {
        id: string;
        name?: string;
        cost?: number;
        taxToAdd?: number;
        taxAdded?: number;
    }[];
}

export interface IOrderCreateItemV8 {
    externalId?: string;
    categoryId: string;
    categoryName?: string;
    productId: string;
    productName?: string;
    cost?: number;
    taxToAdd?: number;
    taxAdded?: number;
    quantity?: number;
    referenceId?: string;
    parentReferenceId?: string;
    modifiers?: IOrderCreateModifierV8[];
    note?: string;
}
export interface IOrderCreatePaymentV8 {
    type: OrderPaymentType;
    amount?: number; // Total payment amount including tips, after rewards applied etc
    auth?: {
        nonce?: string; // Token / ReceiptId returned from 3D Secure Payment Gateway SDKs
        clientDetails?: string; // AntiFraud payload from the Payment Gateway SDK
        threeDSecureData?: {
            verificationToken?: string;
            deviceData?: {
                collectionReference: string;
                userAgentHeader: string;
            };
            challenge?: {
                returnUrl?: string;
                reference?: string;
            };
            transactionReference?: string;
        };
        giftCardNumber?: string;
    };
    wallet?:
        | GooglePayPaymentDetails
        | ApplePayPaymentDetails
        | { worldpay: GooglePayPaymentDetails | ApplePayPaymentDetails };
    itemIds?: number[];
    provider?: string;
    splits?: number;
}
export interface IOrderCreateResourceV8 {
    externalId?: string; // optional POS id of the order
    locationId: string;
    timeSlot?: Timeslot;
    scenario: OrderScenario;
    deliveryLocation?: string; // optional Either a table number or other delivery details
    isOpen?: boolean; // optional, default true, MUST BE FALSE FOR PREORDER SCENARIO
    items: IOrderCreateItemV8[];
    adjustments?: IBasketAdjustment[]; // Check type of this - awards, tips etc
    payments: IOrderCreatePaymentV8[];
    note?: string;
}

export interface IOrderJoinDetails {
    deliveryLocation?: string;
    checkId?: string;
    locationId: string;
    checkDate?: string;
}

// Order Create is very picky, no additional data must be present
// So this plucks the data we want from the calculated basket response
export const createOrderCreateItemFromBasketItem = ({
    categoryId,
    productId,
    quantity,
    referenceId,
    parentReferenceId,
    modifiers,
    note
}: IBasketItemV10): IOrderCreateItemV8 => {
    const orderCreateItem: IOrderCreateItemV8 = {
        categoryId,
        productId,
        quantity,
        modifiers: modifiers?.map(modifier => ({
            id: modifier.id,
            options: modifier.options.map(option => ({ id: option.id }))
        })),
        referenceId,
        parentReferenceId,
        note
    };
    return orderCreateItem;
};

export type SplittableItem = IItemReadResourceV10 & {
    isPaid: boolean;
    claimedBy?: IUserReadResourceV12;
    available: boolean;
    condiments: IItemReadResourceV10[];
};

interface IDeviceData {
    bin: string;
    jwt: string;
    url: string;
}

export interface IDeviceDataResponse {
    deviceData: IDeviceData;
    transactionReference: string;
}

export interface IGiftcardResource {
    balance: number;
    giftcardNum: string;
    provider: GiftCardProvider;
}

export interface IAdjustmentLocalResource extends IAdjustmentReadResourceV10 {
    totalValue: number;
    chargeDiscountPerUnit?: number;
}

export interface ExistingSharedOrderData {
    selectedReward?: IAdjustmentLocalResource | null;
    giftCard?: string | undefined;
    userSelectedTipValue?: number;
    tipValue?: number;
}
