import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Collapse, Divider, makeStyles, Theme } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { IOrderReadResourceV18, OrderPaymentType } from 'components/order/model/Order';
import { getFadeDownGradient } from 'lib/helpers';
import { PayItems } from '../order-item/PayItems';
import { PaymentBreakdown } from '../PaymentBreakdown';

const useStyles = makeStyles((theme: Theme) => ({
    itemsBox: {
        position: 'relative'
    },
    itemsButton: {
        textTransform: 'unset',
        backgroundColor: theme.palette.background.default,
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(1.25),
        borderRadius: 0,
        '&.MuiButton-root:hover': {
            backgroundColor: theme.palette.background.default
        }
    },
    itemsButtonWrapper: {
        position: 'absolute',
        bottom: 1,
        width: '100%'
    },
    itemsFade: {
        width: '100%',
        height: '34px',
        background: getFadeDownGradient(theme.palette.background.default, [0, 0.6, 1])
    }
}));

interface PaySummaryItems {
    order?: IOrderReadResourceV18;
    collapseItems?: boolean;
    loading?: boolean;
}

export const PaySummaryItems: React.FC<PaySummaryItems> = ({ order, collapseItems, loading }) => {
    const { items, total, payments } = order || {};
    const canCollapse = collapseItems && items && items.length > 6;
    const classes = useStyles();
    const { t } = useTranslation();
    const [collapse, setCollapse] = React.useState(canCollapse);
    const giftCardPayment = React.useMemo(() => {
        if (Array.isArray(payments)) {
            return payments.find(payment => payment.type === OrderPaymentType.GIFT_CARD && payment.amount);
        }
        return undefined;
    }, [payments]);
    const handleClick = React.useCallback(() => {
        setCollapse(isCollapsed => !isCollapsed);
    }, []);
    if (loading) {
        return (
            <Box className={classes.itemsBox}>
                <Box pt={1}>
                    <PayItems loading />
                    <Box pt={1}>
                        <PaymentBreakdown loading tip={0} />
                    </Box>
                </Box>
                <Box px={2}>
                    <Divider />
                </Box>
            </Box>
        );
    }
    return (
        <Box className={classes.itemsBox}>
            <Collapse in={!collapse} collapsedSize={112}>
                <Box mb={canCollapse ? 4 : 0} pt={1}>
                    <PayItems items={items} total={total} keepOpen />
                    <Box pt={1}>
                        <PaymentBreakdown
                            total={total}
                            tip={total?.tips || 0}
                            giftCardPayment={giftCardPayment}
                        />
                    </Box>
                </Box>
            </Collapse>
            {canCollapse && (
                <Box className={classes.itemsButtonWrapper}>
                    {collapse && <div className={classes.itemsFade} />}
                    <Button
                        fullWidth
                        onClick={handleClick}
                        className={classes.itemsButton}
                        disableTouchRipple
                    >
                        {!collapse ? t('PAT_QUICKPAY_SHOW_LESS') : t('PAT_QUICKPAY_SHOW_MORE')}
                        {!collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </Button>
                </Box>
            )}
            <Box px={2}>
                <Divider />
            </Box>
        </Box>
    );
};
