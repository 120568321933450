import { IEnrichedMenu, IEnrichedMenuWithModifierMaps } from 'components/menu/model/Menu';
import { MenuAction } from './menuActions';
import { MenuActionTypes } from './menuActionTypes';

export interface MenuState {
    menu?: IEnrichedMenuWithModifierMaps;
    categories: IEnrichedMenu['categories'];
    error: string;
    isLoading: boolean;
}

export const initialState: MenuState = {
    menu: undefined,
    categories: [],
    error: '',
    isLoading: true
};

export default function (state: MenuState = initialState, action: MenuAction): MenuState {
    switch (action.type) {
        case MenuActionTypes.END_REQUEST:
            return { ...state, isLoading: false };
        case MenuActionTypes.START_REQUEST:
            return { ...state, isLoading: true };
        case MenuActionTypes.GET_MENU_ERROR:
            return { ...state, menu: undefined, error: action.error, categories: [] };
        case MenuActionTypes.GET_MENU_SUCCESS:
            return {
                ...state,
                error: '',
                menu: action.menu,
                categories: action.categories
            };
        default:
            return state;
    }
}
