import { HttpClient } from 'components/http/HttpClient';
import { IAwardsResponse } from './models';
import { AxiosRequestConfig } from 'axios';

class AwardsApi extends HttpClient {
    constructor() {
        super({ baseURL: process.env.API_SERVER_URL, url: '/awards' });
    }

    public getAwards(forceIncludeLoyaltyBalance?: boolean): Promise<IAwardsResponse> {
        const params: AxiosRequestConfig['params'] = {};
        if (forceIncludeLoyaltyBalance) {
            params.forceIncludeLoyaltyBalance = forceIncludeLoyaltyBalance;
        }
        return this.getRequest<IAwardsResponse>({
            url: '',
            headers: { 'x-api-version': '7' },
            configs: {
                params
            }
        });
    }
}
export const awardsApi = new AwardsApi();
