import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrencyString } from 'lib/useCurrencyString';
import { PayGiftCardApply } from './PayGiftCardApply';

interface PayGiftCardSuccessProps {
    giftCardNumber?: string;
    onClose: () => void;
    onChange: () => void;
    giftCardPayment?: number;
    giftCardRemainingBalance?: number;
}

export const PayGiftCardSuccess: React.FC<PayGiftCardSuccessProps> = ({
    giftCardNumber,
    onClose,
    onChange,
    giftCardRemainingBalance,
    giftCardPayment
}) => {
    const { t } = useTranslation();
    const getCurrencyString = useCurrencyString();
    const localisationStrings = React.useMemo(
        () => ({
            title: t('PAT_QUICKPAY_GIFTCARD_APPLIED_TITLE'),
            subtitle: t('PAT_QUICKPAY_GIFTCARD_APPLIED_BODY', {
                applied: getCurrencyString(giftCardPayment ?? 0),
                remaining: getCurrencyString(giftCardRemainingBalance ?? 0)
            }),
            hint: t('PAT_QUICKPAY_GIFTCARD_APPLIED_HINT', {
                applied: getCurrencyString(giftCardPayment ?? 0),
                remaining: getCurrencyString(giftCardRemainingBalance ?? 0)
            }),
            primary: t('PAT_QUICKPAY_GIFTCARD_APPLIED_PRIMARY'),
            secondary: t('PAT_QUICKPAY_GIFTCARD_APPLIED_SECONDARY')
        }),
        [getCurrencyString, giftCardPayment, giftCardRemainingBalance, t]
    );
    return (
        <PayGiftCardApply
            localisation={localisationStrings}
            giftCardNumber={giftCardNumber}
            onPrimaryClick={onClose}
            onSecondaryClick={onChange}
        />
    );
};
