import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useResponsive } from 'src/hooks/useResponsive';
import { usePaymentProvider } from 'src/integrations/PaymentProvider/hooks/usePaymentProvider';
import { OrderPaymentType } from 'components/order/model/Order';
import { UserCard } from 'components/user/model/User';
import { CONTENT_MAX_WIDTH } from 'config/constants';
import { useAuth } from 'lib/useAuth';
import { getUserCard } from 'store/auth/authActions';
import { setLastRequest } from 'store/request/requestActions';
import { ApplicationState } from 'store/store';
import { PayBaseModal } from '../modals/PayBaseModal';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: (props: { isDesktop: boolean }) => ({
            display: 'flex',
            ...(props.isDesktop
                ? {
                      maxWidth: CONTENT_MAX_WIDTH,
                      width: CONTENT_MAX_WIDTH,
                      borderRadius: theme.shape.borderRadius
                  }
                : {})
        }),
        title: {
            fontWeight: 700,
            fontSize: '24px',
            marginBottom: theme.spacing(1)
        },
        button: {
            textTransform: 'none',
            padding: theme.spacing(1.5, 2),
            fontWeight: 700
        },
        buttonContainer: {
            padding: 0,
            marginTop: theme.spacing(3)
        }
    })
);

interface PayAddCardDialogProps {
    handleCreateOrder: (paymentType: OrderPaymentType, selectedCard: UserCard | null) => () => void;
    open: boolean;
    onClose: () => void;
}

export const PayAddCardDialog: React.FC<PayAddCardDialogProps> = ({ handleCreateOrder, open, onClose }) => {
    const { isDesktop } = useResponsive();
    const { user } = useAuth();
    const { t } = useTranslation();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const classes = useStyles({ isDesktop });
    const dispatch = useDispatch();

    const handleCardAdded = React.useCallback(() => {
        if (user && user.id) {
            getUserCard(user.id)(dispatch).then(userCard => {
                if (userCard) {
                    const requestFunction = handleCreateOrder(OrderPaymentType.CARD_ON_FILE, userCard);
                    setLastRequest(requestFunction)(dispatch);
                    requestFunction();
                    onClose();
                }
            });
        }
    }, [user, dispatch, handleCreateOrder, onClose]);
    const { isProviderLoading, addCardComponent } = usePaymentProvider(
        { type: 'cardOnFile' },
        {
            onCardAdded: handleCardAdded,
            classes: { button: classes.button, buttonContainer: classes.buttonContainer }
        }
    );
    if (!settings) {
        return null;
    }
    return (
        <PayBaseModal open={open} onClose={onClose} paperClassName={classes.dialog}>
            <Box px={1} py={1.5}>
                <Typography className={classes.title} align="center">
                    {t('PAT_QUICKPAY_ADD_CARD_DIALOG_TITLE')}
                </Typography>
                {!isProviderLoading && !!addCardComponent && open && addCardComponent}
            </Box>
        </PayBaseModal>
    );
};
