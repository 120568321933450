import React from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'src/hooks/useQuery';
import { CreatePassword } from 'components/user/forgotten_password/create';
import { ResetPassword } from 'components/user/forgotten_password/reset';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { ApplicationState } from 'store/store';
import { ROUTES } from './routes';

export const ForgotPasswordPage: React.FC = () => {
    const [identity, setIdentity] = React.useState<string | null>();
    const routeIdentity = useQuery('identity');
    const { push } = useLocalHistory();
    const { isLoading, returnUrl } = useSelector((state: ApplicationState) => state.auth);
    const { isLoggedIn } = useAuth();
    React.useEffect(() => {
        setIdentity(routeIdentity);
    }, [routeIdentity]);
    React.useEffect(() => {
        if (isLoggedIn) {
            if (returnUrl) {
                const [path, search] = returnUrl.split('?');
                push(path, {}, search);
            } else {
                push(ROUTES.BASE);
            }
        }
    }, [isLoggedIn, push, returnUrl]);
    const handleReset = React.useCallback(
        (newIdentity: string) => {
            push(`${ROUTES.USER.FORGOT_PASSWORD}`, {}, `identity=${encodeURIComponent(newIdentity)}`);
        },
        [push]
    );
    if (identity) {
        return <CreatePassword identity={identity} isLoading={isLoading} />;
    }
    return <ResetPassword onSubmit={handleReset} />;
};
