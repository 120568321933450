import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addBasketItem } from 'store/basket/basketActions';
import { ApplicationState } from 'store/store';
import { IProduct, ISuggestions } from '../model/Menu';
import { MenuSuggestionsBogof } from './MenuSuggestionsBogof';

export const useBogofModal = (onClose: () => void, quantity: number, modifiers?: boolean) => {
    const dispatch = useDispatch();
    const { menu } = useSelector((state: ApplicationState) => state.menu);
    const currency = useSelector(
        (state: ApplicationState) => state.settings.settings?.region?.currencyCode ?? 'GBP'
    );
    const [suggestionBogof, setBogofSuggestion] = React.useState<ISuggestions | null>(null);
    const [product, setProduct] = React.useState<IProduct | null>(null);
    const [newQuantity, setNewQuantity] = React.useState<number | null>(null);

    const handleSuggestionsClose = React.useCallback(() => {
        setBogofSuggestion(null);
    }, []);

    const handleChangeQuantity = React.useCallback(
        (changedQuantity: number) => {
            if (modifiers) {
                setNewQuantity(changedQuantity);
                return;
            }
            if (menu && product) {
                setBogofSuggestion(null);

                const categoryId = menu.productIdToCategoryId.get(product.id);
                addBasketItem(product, categoryId || '', changedQuantity, undefined, product.price)(dispatch);
                onClose();
            }
        },
        [dispatch, menu, modifiers, onClose, product]
    );

    const bogofModal = React.useCallback(() => {
        if (suggestionBogof) {
            return (
                <MenuSuggestionsBogof
                    open={!!suggestionBogof}
                    onClose={handleSuggestionsClose}
                    changeQuantity={handleChangeQuantity}
                    currency={currency}
                    suggestion={suggestionBogof}
                    quantity={quantity}
                />
            );
        }
        return null;
    }, [currency, handleChangeQuantity, handleSuggestionsClose, quantity, suggestionBogof]);

    return { bogofModal, setBogofSuggestion, setProduct, newQuantity, setNewQuantity };
};
