import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Theme } from '@material-ui/core';
import {
    IAdjustmentLocalResource,
    IOrderPaymentReadResource,
    IOrderTotal,
    OrderPaymentType
} from 'components/order/model/Order';
import { addOpacity } from 'lib/helpers';
import { LoadingTypography } from 'lib/LoadingTypography';
import { isNumber } from 'lib/typeInference';
import { useCurrencyString } from 'lib/useCurrencyString';

interface PaymentBreakdownProps {
    total?: IOrderTotal;
    loading?: boolean;
    tip: number;
    giftCardPayment?: IOrderPaymentReadResource;
    payments?: IOrderPaymentReadResource[];
    reward?: IAdjustmentLocalResource;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(1, 2),
        paddingTop: theme.spacing(0.5)
    },
    item: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    typography: {
        color: addOpacity(theme.palette.text.primary, 0.5),
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: 1.7
    }
}));

export const PaymentBreakdown: React.FC<PaymentBreakdownProps> = ({
    total,
    loading,
    tip,
    giftCardPayment,
    payments,
    reward
}) => {
    const { t } = useTranslation();
    const getCurrencyString = useCurrencyString();
    const classes = useStyles();
    const paymentsResult = React.useMemo(() => {
        if (Array.isArray(payments)) {
            return payments.reduce<{ card: number; cash: number }>(
                (init, { type, amount, auth }) => {
                    if (type === OrderPaymentType.CASH_AT_POS || type === OrderPaymentType.CASH) {
                        init.cash += amount || 0;
                    } else {
                        if (type === OrderPaymentType.GIFT_CARD) {
                            if (!!auth) {
                                return init;
                            }
                        }
                        init.card += amount || 0;
                    }

                    return init;
                },
                { cash: 0, card: 0 }
            );
        }
        return { cash: 0, card: 0 };
    }, [payments]);
    const paymentInfoStrings = React.useMemo<([string, string] | [])[]>(() => {
        if (loading) {
            return [[], [], []];
        }
        if (!total) {
            return [];
        }
        const strings: [string, string][] = [];

        strings.push([t('BILL_CHECKOUT_PAYMENT_INFO_ITEMS'), getCurrencyString(total.cost)]);

        if (total.charges !== 0) {
            strings.push([t('BILL_CHECKOUT_PAYMENT_INFO_CHARGES'), getCurrencyString(total.charges)]);
        }

        if (total.discounts !== 0 || reward) {
            strings.push([
                t('BILL_CHECKOUT_PAYMENT_INFO_DISCOUNTS'),
                getCurrencyString(total.discounts + (reward?.totalValue ?? 0))
            ]);
        }

        if (total.taxes?.length > 0) {
            total.taxes
                .filter(tax => !tax.inclusive)
                .forEach(tax => strings.push([tax.name, getCurrencyString(tax.amount)]));
        }

        if (giftCardPayment && isNumber(giftCardPayment.amount)) {
            strings.push([t('CHECKOUT_GIFTCARD_SUMMARY'), `-${getCurrencyString(giftCardPayment.amount)}`]);
        }

        if (paymentsResult.cash || paymentsResult.card) {
            strings.push([
                t('BILL_CHECKOUT_PAYMENT_INFO_PAYMENTS'),
                `-${getCurrencyString(paymentsResult.cash + paymentsResult.card)}`
            ]);
        }

        if (total.total > total.balance) {
            strings.push([t('BILL_CHECKOUT_PAYMENT_INFO_BALANCE'), getCurrencyString(total.balance)]);
        }

        if (tip > 0) {
            strings.push([t('BILL_CHECKOUT_PAYMENT_INFO_TIP'), getCurrencyString(tip)]);
        }

        return strings;
    }, [
        getCurrencyString,
        giftCardPayment,
        loading,
        paymentsResult.card,
        paymentsResult.cash,
        reward,
        t,
        tip,
        total
    ]);

    const renderPaymentItem = React.useCallback(
        ([key, value]: [] | [string, string], index: number) => (
            <Box key={`breakdown-${index}`} className={classes.item}>
                <LoadingTypography className={classes.typography} loading={loading} width={120}>
                    {key}
                </LoadingTypography>
                <LoadingTypography className={classes.typography} loading={loading} width={40}>
                    {value}
                </LoadingTypography>
            </Box>
        ),
        [classes.item, classes.typography, loading]
    );
    return <div className={classes.root}>{paymentInfoStrings.map(renderPaymentItem)}</div>;
};
