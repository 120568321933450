import React from 'react';
import { useSelector } from 'react-redux';
import { useSuggestionsContext } from 'src/hooks/useSuggestionsContext';
import { ApplicationState } from 'store/store';
import { IProduct, ProductGroup } from '../model/Menu';
import { MenuSuggestionsAdd } from './MenuSuggestionsAdd';

export const useAddModal = () => {
    const { menu } = useSelector((state: ApplicationState) => state.menu);
    const currency = useSelector(
        (state: ApplicationState) => state.settings.settings?.region?.currencyCode ?? 'GBP'
    );
    const context = useSuggestionsContext();

    const handleSuggestionsClose = React.useCallback(() => {
        context.setSuggestion(null);
        context.setSuggestedGroupProduct(undefined);
        context.setAddedProduct(null);
    }, [context]);

    const handleShowMoreProductInfo = React.useCallback(
        (product: IProduct | ProductGroup | undefined) => {
            if (!menu) {
                return;
            }
            handleSuggestionsClose();
            if (product) {
                context.setSuggestedGroupProduct(product);
                context.setShowMoreSuggestionInfo(true);
            }
        },
        [context, handleSuggestionsClose, menu]
    );

    const addModal = React.useCallback(() => {
        if (!(context.suggestion && context.addedProduct && context.suggestedProduct)) {
            return null;
        }
        return (
            <MenuSuggestionsAdd
                open={!!context.suggestion}
                onClose={handleSuggestionsClose}
                showMoreInfo={handleShowMoreProductInfo}
                currency={currency}
                suggestion={context.suggestion}
                addedItem={context.addedProduct}
                suggestedProduct={context.suggestedProduct}
            />
        );
    }, [
        context.addedProduct,
        context.suggestedProduct,
        context.suggestion,
        currency,
        handleShowMoreProductInfo,
        handleSuggestionsClose
    ]);

    return { addModal };
};
