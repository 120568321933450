export enum EActionType {
    CREDENTIAL_VERIFIED = 'CREDENTIAL_VERIFIED',
    ORDER_PAYMENT_REFUNDED = 'ORDER_PAYMENT_REFUNDED',
    ORDER_PAYMENT_RECEIVED = 'ORDER_PAYMENT_RECEIVED',
    VOUCHER_REDEEMED = 'VOUCHER_REDEEMED',
    ORDER_COMPLETED = 'ORDER_COMPLETED',
    TOPUP = 'TOPUP',
    TOPUP_REFUNDED = 'TOPUP_REFUNDED',
    REFERAL_CLAIMED_REFERREE = 'REFERAL_CLAIMED_REFERREE',
    REFERAL_CLAIMED_REFERRER = 'REFERAL_CLAIMED_REFERRER',
    USER_ACTIVATION_RESENT = 'USER_ACTIVATION_RESENT',
    ORDER_USER_JOINED = 'ORDER_USER_JOINED',
    CAUSE_GIFT = 'CAUSE_GIFT',
    REFUND = 'REFUND',
    REWARD_BURNED = 'REWARD_BURNED',
    USER_ACTIVATED = 'USER_ACTIVATED',
    REWARD_EARNED = 'REWARD_EARNED',
    CARD_CREATED = 'CARD_CREATED',
    USER_CREATED = 'USER_CREATED',
    USER_CREDITED = 'USER_CREDITED',
    ORDER_USER_BILLED = 'ORDER_USER_BILLED',
    REDEEM_PERK = 'REDEEM_PERK',
    CARD_DELETED = 'CARD_DELETED',
    ORDER_CREATED = 'ORDER_CREATED',
    MEMBER_ADDED = 'MEMBER_ADDED',
    MEMBER_REMOVED = 'MEMBER_REMOVED',
    PURCHASE = 'PURCHASE',
    USER_CHANGED = 'USER_CHANGED'
}
