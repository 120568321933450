import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import pick from 'lodash/pick';
import { ApplePayPaymentDetails } from 'src/integrations/PaymentProvider/ApplePayButton';
import { IPaymentProvider, paymentProviderFactory } from 'src/integrations/PaymentProvider/PaymentProvider';
import { IPaymentOptionValue } from 'components/bill/ui/ViewBill/Checkout/PaymentMethod/PaymentMethodDialog';
import {
    IAdjustmentLocalResource,
    IOrderCreatePaymentV8,
    IOrderPaymentReadResource,
    IOrderReadResourceV18,
    IUpdateOrderDetails,
    OrderPaymentType,
    OrderScenario
} from 'components/order/model/Order';
import { EOrderNonceKey, orderApi } from 'components/order/orderApi';
import { EPaymentProvider } from 'components/settings/model/Settings';
import { setShouldUpdateGuestSession } from 'components/user/localAuth';
import { userApi } from 'components/user/userApi';
import { roundToDecimal } from 'lib/helpers';
import logger from 'lib/logger';
import { isDefined, isString } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { LocationRouteParams, ROUTES } from 'pages/routes';
import { updateUser } from 'store/auth/authActions';
import { resetBasket } from 'store/basket/basketActions';
import { setLastRequest } from 'store/request/requestActions';
import { ApplicationState } from 'store/store';
import { setPaySessionSplits } from '../model/helpers';
import { usePayQueryParams } from './usePayDateCheckId';
import { GooglePayPaymentDetails } from 'src/integrations/GooglePay';
import { generateOrderAdjustments } from 'components/order/helpers';
import { useTippingSettings } from 'components/order/hooks/useTippingSettings';
import { useSnackbar } from 'notistack';

const getChallengeUrl = () =>
    `${process.env.ORDER_SERVICE_URL}/worldpay/3ds/redirectVerification?redirectUrl=${window.location.origin}${ROUTES.WORLDPAY}`;

export function usePayMyOrder(
    selectedPaymentOption: IPaymentOptionValue,
    orderDetails: IOrderReadResourceV18 | null,
    totalToPay: number,
    tipValue: number,
    selectedTip: number | undefined,
    handleAddNewCard: () => void,
    giftCardPayment?: IOrderCreatePaymentV8,
    selectedReward?: IAdjustmentLocalResource,
    yourShares?: number,
    splitShares?: number
) {
    const { merchantId, locationId } = useParams<LocationRouteParams>();
    const { checkId, tableNumber } = usePayQueryParams();
    const tippingSettings = useTippingSettings(OrderScenario.TABLE);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { push } = useLocalHistory();
    const { t } = useTranslation();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { lastRequest } = useSelector((state: ApplicationState) => state.request);
    const { user, isGuest, isCardLoading } = useAuth();
    const [provider, setProvider] = React.useState<IPaymentProvider>();
    const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(false);

    const tipToUse = React.useMemo(() => roundToDecimal(tipValue), [tipValue]);
    const adjustments = React.useMemo(
        () => generateOrderAdjustments(tipToUse, selectedReward),
        [selectedReward, tipToUse]
    );

    React.useEffect(() => {
        if (settings && !provider) {
            paymentProviderFactory.create(settings).then(paymentProvider => {
                setProvider(paymentProvider);
            });
        }
        return () => {
            if (provider && provider.reset) {
                provider.reset();
            }
        };
    }, [provider, provider?.reset, settings]);
    const handleToggleConfirmationDialog = React.useCallback(
        () => setIsConfirmationOpen(!isConfirmationOpen),
        [isConfirmationOpen]
    );
    const handlePlaceOrder = React.useCallback(
        (
                paymentType: OrderPaymentType,
                options?: {
                    auth?: { nonce?: string; threeDSecureData?: { verificationToken: string } };
                    wallet?:
                        | GooglePayPaymentDetails
                        | ApplePayPaymentDetails
                        | { worldpay: GooglePayPaymentDetails | ApplePayPaymentDetails };
                }
            ) =>
            () => {
                async function completeOrderPayment(paymentNonce: string | null) {
                    // Place order
                    try {
                        if (!orderDetails) {
                            throw new Error('No order details');
                        }
                        const paymentsToApply: IOrderCreatePaymentV8[] = [];
                        const payment: IOrderCreatePaymentV8 = {
                            type: paymentType,
                            amount: totalToPay
                        };
                        if (isDefined(yourShares)) {
                            payment.splits = yourShares;
                        }
                        if (options?.auth) {
                            payment.auth = !!paymentNonce
                                ? { ...options.auth, nonce: paymentNonce }
                                : options.auth;
                        }
                        if (options?.wallet) {
                            payment.wallet = options.wallet;
                        }
                        if (!!provider && !payment?.auth) {
                            const deviceDataResult = await provider.collectDeviceData();

                            if (!!deviceDataResult) {
                                const { sessionId, transactionReference } = deviceDataResult;

                                if (isString(sessionId)) {
                                    payment.auth = {
                                        threeDSecureData: {
                                            deviceData: {
                                                collectionReference: sessionId,
                                                userAgentHeader: window.navigator.userAgent
                                            },
                                            challenge: {
                                                returnUrl: getChallengeUrl()
                                            },
                                            transactionReference
                                        }
                                    };
                                }
                            }
                        }
                        if (giftCardPayment) {
                            if (payment.amount !== 0) {
                                paymentsToApply.push(payment);
                            }
                            paymentsToApply.push(
                                pick(giftCardPayment, [
                                    'auth',
                                    'provider',
                                    'type',
                                    'amount'
                                ]) as IOrderPaymentReadResource
                            );
                        } else {
                            paymentsToApply.push(payment);
                        }

                        const orderBody: Partial<IUpdateOrderDetails> = {
                            adjustments,
                            payments: paymentsToApply
                        };
                        if (isDefined(yourShares) && splitShares) {
                            orderBody.splitNumber = splitShares + (orderDetails?.splitTotal?.splitsPaid ?? 0);
                        }
                        if (isDefined(selectedTip)) {
                            if (!!user && !!tippingSettings) {
                                userApi
                                    .updateUser(user._id, {
                                        defaultTip: {
                                            amount: selectedTip,
                                            scheme: tippingSettings.scheme
                                        }
                                    })
                                    .then(userResult => {
                                        dispatch(updateUser(userResult));
                                    })
                                    .catch(error => {
                                        logger.warn(error);
                                    });
                            }
                        }
                        setPaySessionSplits(undefined, merchantId, locationId, checkId, tableNumber);
                        const operation = await orderApi.updateOrder(orderDetails.id, orderBody);
                        if (isDefined(operation.id)) {
                            if (isGuest) {
                                if (merchantId) {
                                    setShouldUpdateGuestSession(merchantId);
                                }
                            }

                            push(
                                ROUTES.QUICKPAY.STATUS,
                                {
                                    operationId: operation.id.toString()
                                },
                                '',
                                { orderBodyToRecreatePat: { ...orderBody, id: orderDetails.id } }
                            );
                        }
                    } catch (err) {
                        if (!err) {
                            handleToggleConfirmationDialog();
                        } else {
                            enqueueSnackbar(t('CHECKOUT_ERROR_PLACING_ORDER'), { variant: 'error' });
                        }
                    }
                }

                completeOrderPayment(null);
            },
        [
            adjustments,
            checkId,
            dispatch,
            enqueueSnackbar,
            giftCardPayment,
            handleToggleConfirmationDialog,
            isGuest,
            locationId,
            merchantId,
            orderDetails,
            provider,
            push,
            selectedTip,
            splitShares,
            t,
            tableNumber,
            tippingSettings,
            totalToPay,
            user,
            yourShares
        ]
    );
    const handlePayMyBillWithCard = React.useCallback(
        (forced?: boolean) => {
            if (selectedPaymentOption.card || forced) {
                if (
                    !forced &&
                    provider &&
                    settings &&
                    settings?.paymentProvider === EPaymentProvider.SQUARE &&
                    user &&
                    user?.card
                ) {
                    userApi.getCards(user.id).then(res => {
                        const userSelectedCard = res.cards.find(
                            c =>
                                selectedPaymentOption.card &&
                                selectedPaymentOption.card._id &&
                                c._id === selectedPaymentOption.card._id
                        );
                        if (userSelectedCard?.cardId) {
                            provider.threeDSecureAuthenticate(
                                {
                                    square: {
                                        cardId: userSelectedCard.cardId,
                                        intent: 'CHARGE',
                                        currency: settings.region.currencyCode,
                                        contact: {
                                            givenName: user?.firstName,
                                            familyName: user?.lastName
                                        },
                                        onVerificationEnd: (err, result) => {
                                            if (err || !result) {
                                                enqueueSnackbar(err?.message || 'Missing the result', {
                                                    variant: 'error'
                                                });
                                            } else {
                                                const createOrderFunction = handlePlaceOrder(
                                                    OrderPaymentType.CARD_ON_FILE,
                                                    {
                                                        auth: {
                                                            threeDSecureData: {
                                                                verificationToken: result.token
                                                            }
                                                        }
                                                    }
                                                );
                                                setLastRequest(createOrderFunction)(dispatch);
                                                createOrderFunction();
                                            }
                                        }
                                    }
                                },
                                {
                                    payments: [
                                        {
                                            amount: totalToPay,
                                            type: OrderPaymentType.CARD_ON_FILE
                                        }
                                    ]
                                }
                            );
                        }
                    });
                } else {
                    const createOrderFunction = handlePlaceOrder(
                        // paymentCreation
                        OrderPaymentType.CARD_ON_FILE
                    );
                    setLastRequest(createOrderFunction)(dispatch);
                    createOrderFunction();
                }
            } else {
                handleAddNewCard();
            }
        },
        [
            dispatch,
            enqueueSnackbar,
            handleAddNewCard,
            handlePlaceOrder,
            provider,
            selectedPaymentOption.card,
            settings,
            totalToPay,
            user
        ]
    );
    const handleDigitalPayment = React.useCallback(
        (cardNonce: string, walletType: OrderPaymentType) => {
            const createOrderFunction = handlePlaceOrder(walletType, { auth: { nonce: cardNonce } });
            setLastRequest(createOrderFunction)(dispatch);
            createOrderFunction();
        },
        [dispatch, handlePlaceOrder]
    );

    const handleJudoDigitalPayment = React.useCallback(
        (data: GooglePayPaymentDetails | ApplePayPaymentDetails, walletType: OrderPaymentType) => {
            const createOrderFunction = handlePlaceOrder(
                walletType,
                settings?.paymentProvider === EPaymentProvider.WORLDPAY
                    ? {
                          wallet: { worldpay: data }
                      }
                    : { wallet: data }
            );
            setLastRequest(createOrderFunction)(dispatch);
            createOrderFunction();
        },
        [dispatch, handlePlaceOrder, settings?.paymentProvider]
    );
    const handleRetry = React.useCallback(() => {
        if (lastRequest) {
            lastRequest();
            handleToggleConfirmationDialog();
        }
    }, [handleToggleConfirmationDialog, lastRequest]);
    const handleErrorDialogClose = React.useCallback(() => {
        resetBasket(dispatch);
        orderApi.resetNonceByKey([EOrderNonceKey.UPDATE_ORDER]);
        push(ROUTES.QUICKPAY.CHECKOUT);
    }, [dispatch, push]);
    return {
        payWithCard: handlePayMyBillWithCard,
        isLoading: isCardLoading,
        isConfirmationOpen,
        handleDigitalPayment,
        handleJudoDigitalPayment,
        onRetry: handleRetry,
        onErrorDialogClose: handleErrorDialogClose
    };
}
