import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Button, createStyles, Divider, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import moment from 'moment';
import { EActionType } from 'components/activity/enums';
import { ResendReceipt } from 'components/activity/ui/List/ResendReceipt';
import { IItemReadResourceV10, ITaxLine } from 'components/basket/model/Basket';
import { ViewBillContentSection } from 'components/bill/ui/ViewBill/ViewBillContentSection';
import { ViewBillOrderItem } from 'components/bill/ui/ViewBill/ViewBillOrderItem';
import { Location } from 'components/location/model/Location';
import { getLocalMerchantId } from 'components/settings/localStore';
import { getTimeslotTimes } from 'components/timeslots/helpers';
import { setOnCloseRedirectUrl } from 'components/user/localAuth';
import { CredentialProvider } from 'components/user/model/User';
import { userApi } from 'components/user/userApi';
import { addOpacity, createUrlParams } from 'lib/helpers';
import { InnerPageLayout } from 'lib/InnerPageLayout';
import { InnerPageLayoutBottom } from 'lib/InnerPageLayout/InnerPageLayoutBottom';
import { InnerPageLayoutContent } from 'lib/InnerPageLayout/InnerPageLayoutContent';
import { MuiMap } from 'lib/MuiMap';
import { isDefined } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import { useCurrencyString } from 'lib/useCurrencyString';
import { useLocalHistory } from 'lib/useLocalHistory';
import { useStringReplace } from 'lib/useStringReplace';
import { ROUTES } from 'pages/routes';
import { setBasket } from 'store/basket/basketActions';
import { ApplicationState } from 'store/store';
import { IOrderReadResourceV12, OrderAdjustmentType, OrderPaymentType, OrderScenario } from './model/Order';
import { orderService } from './orderService';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageContainer: {
            display: 'flex',
            height: '100%',
            paddingBottom: theme.spacing(7),
            flexDirection: 'column',
            overflowY: 'hidden'
        },
        overflowContainer: {
            overflowY: 'auto'
        },
        orderSummaryText: {
            width: '100%',
            textAlign: 'center',
            paddingTop: theme.spacing(1),
            fontWeight: 'bold',
            fontSize: theme.spacing(2.6)
        },
        orderNumberText: {
            width: '100%',
            textAlign: 'center',
            paddingBottom: theme.spacing(1),
            marginTop: -theme.spacing(0.5)
        },
        email: {
            borderTop: `1px solid ${addOpacity(theme.palette.text.primary, 0.16)}`,
            borderBottom: `1px solid ${addOpacity(theme.palette.text.primary, 0.16)}`,
            padding: theme.spacing(1.75),
            textAlign: 'center',
            flexShrink: 0
        },
        map: {
            borderRadius: theme.shape.borderRadius,
            width: '100%',
            // Min/Max needed to prevent collapsing or expanding
            minHeight: '25vh',
            maxHeight: '25vh'
        },
        orderDetails: {
            padding: theme.spacing(1.75),
            paddingBottom: '0',
            flexShrink: 0
        },
        orderDetailsTitleText: {
            fontWeight: 'bolder'
        },
        orderDetailsInfoText: {
            marginBottom: theme.spacing(1.5)
        },
        pricingRow: {
            display: 'flex',
            justifyContent: 'space-between',
            flexShrink: 0,
            width: '100%',
            padding: theme.spacing(1.5)
        },
        guestConversionWrapper: {
            padding: theme.spacing(1),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        guestConversionButton: {
            marginBottom: theme.spacing(1),
            color: theme.palette.titleTextColour
        },
        guestConversionBackText: {
            textDecoration: 'underline',
            cursor: 'pointer',
            color: theme.palette.text.hint
        },
        flexItem: {
            flex: 1
        },
        viewBill: {
            color: theme.palette.text.hint
        },
        color: {
            color: theme.palette.titleTextColour
        }
    })
);

interface IOrderSummaryProps {
    order: IOrderReadResourceV12;
    location: Location;
}

export const OrderSummary: React.FC<IOrderSummaryProps> = props => {
    const { order, location } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const { pathname, search } = useLocation();
    const getCurrencyString = useCurrencyString();
    const { isGuest, user, guestCredential } = useAuth();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const [actionId, setActionId] = React.useState<string>();
    const [isResendReceiptOpen, setIsResendReceiptOpen] = React.useState<boolean>(false);
    const dispatch = useDispatch();

    const isResendAvailable = React.useMemo(
        () =>
            settings &&
            settings.actionNotifications &&
            (settings.actionNotifications[EActionType.ORDER_USER_BILLED]?.email ||
                settings.actionNotifications[EActionType.ORDER_COMPLETED]?.email),

        [settings]
    );
    const { firstName, lastName, identity } = useSelector((state: ApplicationState) => state.guest);
    const { push } = useLocalHistory();
    const replace = useStringReplace();
    const tip = order.adjustments.find(adjustment => adjustment.type === OrderAdjustmentType.TIP);
    const locationMarker = React.useMemo(
        () => ({
            lat: location.geo[1],
            lng: location.geo[0],
            markerName: location.title
        }),
        [location]
    );

    const taxesToShow = React.useMemo(
        () => order.total.taxes.filter(tax => !tax.inclusive),
        [order.total.taxes]
    );

    const urlParams = React.useMemo(
        () =>
            createUrlParams({
                identity,
                firstName,
                lastName
            }),
        [identity, firstName, lastName]
    );

    const handleGuestConvert = React.useCallback(() => {
        const merchantId = getLocalMerchantId();
        if (merchantId) {
            setOnCloseRedirectUrl(pathname, merchantId);
        }
        push(`${ROUTES.USER.REGISTER}`, {}, urlParams);
    }, [push, urlParams, pathname]);
    const handleResendReceipt = React.useCallback(() => {
        if (user) {
            if (!actionId) {
                userApi.getActivityHistory(user?.id).then(list => {
                    const orderRelatedActionId = list.items.find(
                        action =>
                            (action.type === EActionType.ORDER_USER_BILLED ||
                                action.type === EActionType.ORDER_COMPLETED) &&
                            action.metadata.order &&
                            action.metadata.order.id === order.id
                    )?._id;

                    if (orderRelatedActionId) {
                        setActionId(orderRelatedActionId);
                        setIsResendReceiptOpen(true);
                    }
                });
            } else {
                setActionId(actionId);
                setIsResendReceiptOpen(true);
            }
        }
    }, [actionId, order.id, user]);
    const handleGuestBack = React.useCallback(() => {
        if (order.scenario === OrderScenario.PREORDER) {
            push(ROUTES.MENU, {}, search);
        } else {
            push(ROUTES.JOURNEY.LANDING);
        }
    }, [order.scenario, push, search]);

    const renderTaxItem = React.useCallback(
        (tax: ITaxLine) => (
            <Box display="flex" justifyContent="space-between" key={tax.id}>
                <Typography>{tax.name}</Typography>
                <Typography>{getCurrencyString(tax.amount)}</Typography>
            </Box>
        ),
        [getCurrencyString]
    );

    const addressLine = React.useMemo(
        () =>
            !!location
                ? [
                      location.address.address,
                      location.address.town,
                      location.address.postcode,
                      location.address.country
                  ]
                      .filter(item => !!item)
                      .join(', ')
                : '',
        [location]
    );
    const chargeAdjustments = React.useMemo(
        () => order.adjustments.filter(adj => adj.type === OrderAdjustmentType.CHARGE),
        [order.adjustments]
    );
    const paidCash = React.useMemo(
        () => order.payments.find(p => p.type === OrderPaymentType.CASH),
        [order.payments]
    );
    const orderPayments = React.useMemo(() => {
        const lastPaymentDate = moment.max(
            ...order.payments
                .filter(payment => isDefined(payment.completedAt))
                .map(({ completedAt }) => moment(completedAt))
        );
        return order.payments
            .filter(
                payment =>
                    payment.type !== OrderPaymentType.CASH &&
                    payment.type !== OrderPaymentType.CASH_AT_POS &&
                    payment.type !== OrderPaymentType.CARD_AT_POS &&
                    isDefined(payment.completedAt) &&
                    payment.userId === user?.id
            )
            .filter(payment => Math.abs(lastPaymentDate.diff(moment(payment.completedAt), 'seconds')) < 5);
    }, [order.payments, user?.id]);

    const isSplitByItem = React.useMemo(
        () =>
            !!(
                orderPayments &&
                orderPayments.find(orderPayment => orderPayment.itemIds && orderPayment.itemIds.length > 0)
            ),
        [orderPayments]
    );
    const paidItems = React.useMemo(() => {
        if (user) {
            const orderItemsMap = orderService.getSplittableItemMap(order, user);
            const userClaimedItems = orderItemsMap.filter(
                item =>
                    orderPayments &&
                    orderPayments
                        .find(orderPayment => orderPayment.itemIds && orderPayment.itemIds.length > 0)
                        ?.itemIds?.includes(item.id) &&
                    item.claimedBy?.userId === user._id
            );

            return userClaimedItems;
        }
        return [];
    }, [order, orderPayments, user]);

    const handleOnCloseResendReceipt = React.useCallback(() => setIsResendReceiptOpen(false), []);
    const handleViewBill = React.useCallback(() => {
        setBasket({
            locationId: order.locationId,
            deliveryLocation: order.deliveryLocation
        })(dispatch);

        push(ROUTES.JOURNEY.PAT.BILL);
    }, [dispatch, order.deliveryLocation, order.locationId, push]);

    const timePadding = React.useMemo(() => order.prepTimeMins ?? 0, [order.prepTimeMins]);

    const renderOrderItem = React.useCallback(
        (item: IItemReadResourceV10, index: number) => (
            <ViewBillOrderItem
                showPricePerOne={isSplitByItem}
                orderItem={item}
                allItems={order.items || []}
                key={`${item.id}${index}`}
            />
        ),
        [isSplitByItem, order.items]
    );
    const paid = React.useMemo(
        () =>
            orderPayments.reduce<{ giftCard: number; otherPayments: number }>(
                (acc, orderPayment) => {
                    if (orderPayment.amount) {
                        if (orderPayment.type !== OrderPaymentType.GIFT_CARD) {
                            acc.otherPayments += orderPayment.amount;
                        } else {
                            acc.giftCard += orderPayment.amount;
                        }
                    }
                    return acc;
                },
                { giftCard: 0, otherPayments: 0 }
            ),
        [orderPayments]
    );
    return (
        <InnerPageLayout>
            <InnerPageLayoutContent>
                <Box overflow="auto" flex={1}>
                    <Typography variant="h6" className={classes.orderSummaryText}>
                        {t('ORDER_STATUS_ORDER_SUMMARY', { checkId: order.checkId })}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.orderNumberText}>
                        {t('ORDER_STATUS_ORDER_NUMBER', { checkId: order.checkId, orderNumber: order.id })}
                    </Typography>
                    {order.scenario === OrderScenario.PREORDER && (
                        <Box marginBottom={2}>
                            <MuiMap
                                width={0}
                                center={{ ...locationMarker }}
                                className={classes.map}
                                options={{
                                    keyboardShortcuts: false,
                                    draggable: false,
                                    disableDefaultUI: true
                                }}
                                markers={[locationMarker]}
                                zoom={16}
                                useCustomPin
                            />
                        </Box>
                    )}
                    <ViewBillContentSection bottomSpacing={2} title={t('ORDER_STATUS_LOCATION')}>
                        <Typography className={classes.orderDetailsInfoText}>{addressLine}</Typography>
                        {order.scenario === OrderScenario.PREORDER && !order.timeSlot && (
                            <>
                                <Typography>{t('ORDER_STATUS_ORDER_READY_IN')}</Typography>
                                <Typography className={classes.orderDetailsInfoText}>
                                    {t('ORDER_STATUS_ORDER_READY_IN_MINS', {
                                        from: (settings?.preOrder.readyPeriodStart || 5) + timePadding,
                                        to: (settings?.preOrder.readyPeriodEnd || 20) + timePadding
                                    })}
                                </Typography>
                            </>
                        )}
                        {order.scenario === OrderScenario.PREORDER && !!order.timeSlot && (
                            <>
                                <Typography>{t('PREORDER_TIMESLOT_PREFIX')}</Typography>
                                <Typography className={classes.orderDetailsInfoText}>
                                    {getTimeslotTimes(order.timeSlot, t, true)}
                                </Typography>
                            </>
                        )}
                        {order.scenario === OrderScenario.ORDER_TO_TABLE && (
                            <Typography className={classes.orderDetailsInfoText}>
                                {t(
                                    order.checkId &&
                                        (settings?.payAtTable?.retrievalBy === 'CHECK_NUMBER' ||
                                            settings?.payAtTable?.retrievalBy === 'BOTH')
                                        ? 'BILL_TABLE_NUMBER_AND_CHECK'
                                        : 'LABEL_TABLE_NUMBER_PLACEHOLDER',
                                    {
                                        tableNumber: order.deliveryLocation || '',
                                        checkId: order.checkId
                                    }
                                )}
                            </Typography>
                        )}
                        {order.scenario === OrderScenario.TABLE && (
                            <Typography className={classes.orderDetailsInfoText}>
                                {t(
                                    order.checkId &&
                                        (settings?.payAtTable?.retrievalBy === 'CHECK_NUMBER' ||
                                            settings?.payAtTable?.retrievalBy === 'BOTH')
                                        ? 'BILL_TABLE_NUMBER_AND_CHECK'
                                        : 'BILL_TABLE_NUMBER',
                                    {
                                        tableNumber: order.deliveryLocation || '',
                                        checkId: order.checkId
                                    }
                                )}
                            </Typography>
                        )}
                    </ViewBillContentSection>
                    {!order.splitNumber && (
                        <ViewBillContentSection bottomSpacing={2} title={t('ORDER_STATUS_ORDER_DETAILS')}>
                            {!isSplitByItem
                                ? order.items.map(renderOrderItem)
                                : paidItems.map(renderOrderItem)}
                        </ViewBillContentSection>
                    )}
                    <ViewBillContentSection title={t('CHECKOUT_TOTALS_PAYMENTS')} bottomSpacing={2}>
                        {!order.splitNumber && !isSplitByItem && (
                            <>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography>{t('BASKET_SUBTOTAL')}</Typography>
                                    <Typography>{getCurrencyString(order.total.cost)}</Typography>
                                </Box>
                                {!!order.total.charges && (
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography>
                                            {' '}
                                            {chargeAdjustments.length !== 1
                                                ? t('CHECKOUT_TOTALS_CHARGES')
                                                : chargeAdjustments[0].description}
                                        </Typography>
                                        <Typography> {getCurrencyString(order.total.charges)}</Typography>
                                    </Box>
                                )}
                                {taxesToShow.map(renderTaxItem)}
                                {tip && tip.value !== 0 && (
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography>{t('ORDER_STATUS_TIP')}</Typography>
                                        <Typography>{getCurrencyString(tip.value)}</Typography>
                                    </Box>
                                )}
                                {!!order.total.discounts && (
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography>{t('CHECKOUT_TOTALS_DISCOUNT')}</Typography>
                                        <Typography>{getCurrencyString(order.total.discounts)}</Typography>
                                    </Box>
                                )}
                                {!!paidCash && (
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography>{t('CHECKOUT_TOTALS_PAYMENTS')}</Typography>
                                        <Typography>{getCurrencyString(paidCash.amount || 0)}</Typography>
                                    </Box>
                                )}
                                <Box display="flex" justifyContent="space-between">
                                    <Typography>{t('CHECKOUT_TOTAL')}</Typography>
                                    <Typography>
                                        {getCurrencyString(order.total.total + order.total.tips)}
                                    </Typography>
                                </Box>
                            </>
                        )}
                        {(order.splitNumber || isSplitByItem) && (
                            <Box display="flex" justifyContent="space-between">
                                <Typography>{t('BILL_SUMMARY_TOTAL')}</Typography>
                                <Typography>{getCurrencyString(order.total.total)}</Typography>
                            </Box>
                        )}
                        {!!paid.giftCard && (
                            <Box display="flex" justifyContent="space-between">
                                <Typography>{t('PAYMENT_TYPE_GIFT_CARD')}</Typography>
                                <Typography>{`-${getCurrencyString(paid.giftCard || 0)}`}</Typography>
                            </Box>
                        )}
                        {isDefined(paid.otherPayments) && (
                            <Box display="flex" justifyContent="space-between">
                                <Typography>
                                    <b>{t('ORDER_SUMMARY_PAID_BY_USER')}</b>
                                </Typography>
                                <Typography>
                                    <b>{getCurrencyString(paid.otherPayments || 0)}</b>
                                </Typography>
                            </Box>
                        )}
                    </ViewBillContentSection>
                    {order.total.balance > 0 && (
                        <>
                            <ViewBillContentSection bottomSpacing={2}>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography variant="body1">
                                        <b>{t('BILL_SUMMARY_REMAINING_BALANCE')}</b>
                                    </Typography>
                                    <Typography variant="body1">
                                        <b>{getCurrencyString(order.total.balance)}</b>
                                    </Typography>
                                </Box>
                            </ViewBillContentSection>
                            <Typography align="center">
                                <Trans
                                    i18nKey="BILL_SUMMARY_VIEW_BILL"
                                    components={[
                                        <Link
                                            className={classes.viewBill}
                                            onClick={handleViewBill}
                                            key="BILL_SUMMARY_VIEW_BILL"
                                        />
                                    ]}
                                />
                            </Typography>
                        </>
                    )}
                </Box>
                {!isGuest && user?.email && (
                    <Box display="flex" alignItems="center" marginTop={2}>
                        <Divider className={classes.flexItem} />
                        <Box textAlign="center" width="70%" paddingBottom={2}>
                            <Typography variant="caption">
                                {replace('SUMMARY_EMAILSENT', 'email', index => (
                                    <b key={`email-sent-${index}`}>{user?.email}</b>
                                ))}
                            </Typography>
                        </Box>
                        <Divider className={classes.flexItem} />
                    </Box>
                )}
            </InnerPageLayoutContent>
            <InnerPageLayoutBottom>
                {isGuest && guestCredential && guestCredential.provider === CredentialProvider.EMAIL && (
                    <Box display="flex" alignItems="center">
                        <Divider className={classes.flexItem} />
                        <Box textAlign="center" width="70%" paddingBottom={2}>
                            <Typography variant="caption">
                                {replace('SUMMARY_EMAILSENT', 'email', index => (
                                    <b key={`email-sent-${index}`}>{guestCredential.id}</b>
                                ))}
                            </Typography>
                        </Box>
                        <Divider className={classes.flexItem} />
                    </Box>
                )}

                {!user?.email && isResendAvailable && !isGuest && (
                    <>
                        <Box display="flex" alignItems="center">
                            <Divider className={classes.flexItem} />
                            <Button
                                onClick={handleResendReceipt}
                                color="primary"
                                variant="text"
                                className={classes.guestConversionButton}
                            >
                                {t('ORDER_SUMMARY_RESEND_RECEIPT')}
                            </Button>
                            <Divider className={classes.flexItem} />
                        </Box>
                        <Box textAlign="center">
                            <Typography
                                variant="caption"
                                className={classes.guestConversionBackText}
                                onClick={handleGuestBack}
                            >
                                {order.scenario === OrderScenario.PREORDER
                                    ? t('ORDER_STATUS_GUEST_BACK')
                                    : t('GENERAL_RETURN_TO_HOME')}
                            </Typography>
                        </Box>
                        <ResendReceipt
                            actionId={actionId}
                            isOpen={isResendReceiptOpen}
                            onClose={handleOnCloseResendReceipt}
                            fullScreen={false}
                        />
                    </>
                )}
                {isGuest && (
                    <>
                        {(!guestCredential || guestCredential.provider === CredentialProvider.MOBILE) && (
                            <Box display="flex" alignItems="center" textAlign="center">
                                <Divider className={classes.flexItem} />
                                <Button
                                    onClick={handleResendReceipt}
                                    variant="text"
                                    className={classes.color}
                                >
                                    {t('ORDER_SUMMARY_RESEND_RECEIPT')}
                                </Button>
                                <Divider className={classes.flexItem} />
                            </Box>
                        )}
                        <Button
                            onClick={handleGuestConvert}
                            color="primary"
                            variant="contained"
                            fullWidth
                            className={classes.guestConversionButton}
                        >
                            {t('ORDER_STATUS_GUEST_CONVERT')}
                        </Button>
                        <Box textAlign="center">
                            <Typography
                                variant="caption"
                                className={classes.guestConversionBackText}
                                onClick={handleGuestBack}
                            >
                                {order.scenario === OrderScenario.PREORDER
                                    ? t('ORDER_STATUS_GUEST_BACK')
                                    : t('GENERAL_RETURN_TO_HOME')}
                            </Typography>
                        </Box>
                        <ResendReceipt
                            actionId={actionId}
                            isOpen={isResendReceiptOpen}
                            onClose={handleOnCloseResendReceipt}
                            fullScreen={false}
                        />
                    </>
                )}
            </InnerPageLayoutBottom>
        </InnerPageLayout>
    );
};
