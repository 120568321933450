import { isDefined } from 'lib/typeInference';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

export function useMenuProductSearch() {
    const { menu } = useSelector((state: ApplicationState) => state.menu);
    const findProductById = React.useCallback(
        (productId: string, categoryId?: string) => {
            if (!isDefined(categoryId)) {
                return menu?.productIdToProduct.get(productId);
            }
            return menu?.categories
                .find(item => item.id === categoryId)
                ?.products.find(item => item.id === productId);
        },
        [menu?.categories, menu?.productIdToProduct]
    );
    return {
        findProductById
    };
}
