import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useCalories } from 'src/hooks/useCalories';
import { useResponsive } from 'src/hooks/useResponsive';
import { IBasketItem, IItemReadResourceV10 } from 'components/basket/model/Basket';
import { getProductOptionsDetails } from 'components/menu/model/Menu';
import { IOrderReadResourceV12 } from 'components/order/model/Order';
import { RecentOrderItemBox } from 'components/recentOrders/RecentOrderItemBox';
import { CONTENT_MAX_WIDTH } from 'config/constants';
import { BottomDialog } from 'lib/BottomDialog';
import { getCurrencyString, getFadeDownGradient, showCalories } from 'lib/helpers';
import { addBasketItems } from 'store/basket/basketActions';
import { useParams } from 'react-router-dom';
import { LocationRouteParams } from 'pages/routes';
import { EGAEventName, isSweetwaters, useGAHelpers } from 'lib/useGAHelpers';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontWeight: 700,
            fontFamily: `TenantFont, ${theme.typography.fontFamily}`,
            marginBottom: theme.spacing(1),
            color: theme.palette.titleTextColour
        },
        container: ({ isDesktop }: { isDesktop: boolean }) => ({
            paddingTop: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            ...(isDesktop
                ? {
                      minWidth: theme.spacing(50)
                  }
                : {})
        }),
        buttonWrapper: {
            background: getFadeDownGradient(theme.palette.background.default),
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            maxWidth: `${CONTENT_MAX_WIDTH - 16}px`
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center'
        },
        addButton: {
            width: '100%',
            marginTop: theme.spacing(1)
        }
    })
);

interface IRecentOrderItemsSelectionProps {
    order: IOrderReadResourceV12;
    open: boolean;
    onClose: () => void;
}

export const RecentOrderItemsSelection: React.FC<IRecentOrderItemsSelectionProps> = ({
    open,
    onClose,
    order
}) => {
    const products = React.useMemo(() => order.items.filter(item => !item.parentReferenceId), [order.items]);
    const { t } = useTranslation();
    const { isDesktop } = useResponsive();
    const classes = useStyles({ isDesktop });
    const initialValues = React.useMemo(() => products.map(product => product.id), [products]);
    const dispatch = useDispatch();
    const { getOrderItemsCalories } = useCalories();
    const [selectedProductIds, setSelectedProductIds] = React.useState(initialValues);
    const { merchantId } = useParams<LocationRouteParams>();
    const { logUserEvent } = useGAHelpers();

    const getProductWithModifiers = React.useCallback(
        (product: IItemReadResourceV10 | undefined) => {
            if (!!product) {
                return [
                    product,
                    ...order.items.filter(
                        item => !!item.parentReferenceId && item.parentReferenceId === product.referenceId
                    )
                ];
            }
            return [];
        },
        [order.items]
    );

    const handleSubmit = React.useCallback(() => {
        if (isSweetwaters(merchantId)) {
            logUserEvent(EGAEventName.AddFromRecentOrdersSweetwaters);
        }
        const selectedProducts = selectedProductIds.map(value =>
            products.find(product => product.id === value)
        );
        const resultProducts = selectedProducts.reduce<IItemReadResourceV10[]>(
            (acc, product) => [...acc, ...getProductWithModifiers(product)],
            []
        );
        const productsToAdd = resultProducts.reduce<(IBasketItem & { price: number })[]>((acc, product) => {
            if (!!product) {
                const { productId, categoryId, quantity, modifiers, referenceId, parentReferenceId, cost } =
                    product;

                acc.push({
                    productId,
                    categoryId,
                    quantity,
                    modifiers,
                    referenceId,
                    parentReferenceId,
                    price: cost
                });
            }

            return acc;
        }, []);
        addBasketItems(productsToAdd)(dispatch);
        onClose();
    }, [dispatch, getProductWithModifiers, logUserEvent, merchantId, onClose, products, selectedProductIds]);

    const handleItemToggle = React.useCallback(
        (id: number) => {
            if (selectedProductIds.includes(id)) {
                setSelectedProductIds(selectedProductIds.filter(selectedId => selectedId !== id));
            } else {
                setSelectedProductIds(oldSelectedIds => [...oldSelectedIds, id]);
            }
        },
        [selectedProductIds]
    );

    const renderRecentOrderItemBoxes = React.useCallback(
        (product: IItemReadResourceV10, index: number, array: IItemReadResourceV10[]) => {
            const modifierProducts = order.items.filter(
                item => !!item.parentReferenceId && item.parentReferenceId === product.referenceId
            );
            const isLast = index === array.length - 1;
            return isDesktop ? (
                <Box marginBottom={isLast ? 0 : 1}>
                    <RecentOrderItemBox
                        key={product.id}
                        selected={selectedProductIds.includes(product.id)}
                        item={product}
                        modifierItems={modifierProducts}
                        currencyCode={order.currencyCode}
                        onChange={handleItemToggle}
                        isLast={isLast}
                    />
                </Box>
            ) : (
                <RecentOrderItemBox
                    key={product.id}
                    selected={selectedProductIds.includes(product.id)}
                    item={product}
                    modifierItems={modifierProducts}
                    currencyCode={order.currencyCode}
                    onChange={handleItemToggle}
                    isLast={isLast}
                />
            );
        },
        [handleItemToggle, isDesktop, order.currencyCode, order.items, selectedProductIds]
    );

    const totalPrice = React.useMemo(() => {
        const selectedProducts = order.items.filter(item => selectedProductIds.includes(item.id));
        const allProducts = selectedProducts.reduce<IItemReadResourceV10[]>(
            (acc, item) => [...acc, ...getProductWithModifiers(item)],
            []
        );

        return allProducts.reduce((acc, item) => {
            const [, optionsPrice] = getProductOptionsDetails(item.modifiers);
            return acc + item.quantity * item.cost + optionsPrice * item.quantity;
        }, 0);
    }, [getProductWithModifiers, order.items, selectedProductIds]);

    const showCaloriesMessage = React.useMemo(
        () => getOrderItemsCalories(order.items),
        [getOrderItemsCalories, order.items]
    );

    return (
        <BottomDialog open={open} onClose={onClose} paperClassName={classes.container}>
            <Typography className={classes.title} variant="h5" align="center">
                {t('PREORDER_RECENT_ORDER_SCREEN_TITLE')}
            </Typography>
            {products.map(renderRecentOrderItemBoxes)}
            <Box className={classes.buttonContainer}>
                <div className={classes.buttonWrapper}>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.addButton}
                        disabled={!selectedProductIds.length}
                        onClick={handleSubmit}
                    >
                        {t('PREORDER_ADD_TO_BASKET', {
                            quantity: selectedProductIds.length,
                            price: getCurrencyString(totalPrice, order.currencyCode)
                        })}
                    </Button>
                </div>
                {showCalories(showCaloriesMessage) && (
                    <Box marginTop={1}>
                        <Typography variant="caption">{t('ORDER_CALORIES_ADVISORY_MESSAGE')}</Typography>
                    </Box>
                )}
            </Box>
        </BottomDialog>
    );
};
