import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { AddShoppingCartOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import { useCalories } from 'src/hooks/useCalories';
import { IItemReadResourceV10 } from 'components/basket/model/Basket';
import { IOrderReadResourceV12 } from 'components/order/model/Order';
import { getDay } from 'components/timeslots/helpers';
import { getCurrencyString, showCalories } from 'lib/helpers';
import { addBasketItems } from 'store/basket/basketActions';
import { isDefined } from 'lib/typeInference';
import { uuid } from 'uuidv4';
import { EGAEventName, isSweetwaters, useGAHelpers } from 'lib/useGAHelpers';
import { useParams } from 'react-router-dom';
import { LocationRouteParams } from 'pages/routes';

interface IRecentOrderProps {
    order: IOrderReadResourceV12;
    onOrderClick: (order: IOrderReadResourceV12) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    orderPanel: {
        padding: theme.spacing(1, 1.5),
        borderBottom: `1px solid ${theme.palette.divider}`,
        cursor: 'pointer'
    },
    textOpacity: {
        opacity: '0.7'
    },
    icon: {
        color: theme.palette.text.hint
    },
    productNames: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        lineClamp: 2,
        display: 'box',
        '-webkit-box-orient': 'vertical',
        height: theme.spacing(6)
    },
    iconButton: {
        padding: theme.spacing(1, 1.5)
    }
}));

export const RecentOrder: React.FC<IRecentOrderProps> = ({ onOrderClick, order }) => {
    const { getOrderItemsCalories, getCaloriesString } = useCalories();
    const { total, items, createdAt, currencyCode } = order;
    const classes = useStyles();
    const dispatch = useDispatch();
    const { merchantId } = useParams<LocationRouteParams>();
    const { logUserEvent } = useGAHelpers();

    const date = React.useMemo(() => {
        if (!!createdAt) {
            return getDay(createdAt);
        }
        return null;
    }, [createdAt]);
    const handleAddToBasketClick = React.useCallback(
        (event: React.MouseEvent) => {
            event.stopPropagation();
            if (isSweetwaters(merchantId)) {
                logUserEvent(EGAEventName.QuickAddRecentOrdersSweetwaters);
            }
            const mainItems = items.filter(item => !item.parentReferenceId);
            const itemsSortedForBasket = mainItems.reduce<IItemReadResourceV10[]>(
                (acc, item) => [
                    ...acc,
                    item,
                    ...items.filter(
                        innerItem =>
                            !!innerItem.parentReferenceId && innerItem.parentReferenceId === item.referenceId
                    )
                ],
                []
            );
            const referenceIds: Record<string, string> = {};
            const itemsToPush = itemsSortedForBasket.map(item => {
                const { productId, categoryId, quantity, modifiers, referenceId, parentReferenceId, cost } =
                    item;
                let newReferenceId = referenceId;
                let newParentReferenceId = parentReferenceId;

                if (isDefined(newReferenceId)) {
                    if (!isDefined(referenceIds[newReferenceId])) {
                        referenceIds[newReferenceId] = uuid();
                    }
                    newReferenceId = referenceIds[newReferenceId];
                }
                if (isDefined(newParentReferenceId)) {
                    if (!isDefined(referenceIds[newParentReferenceId])) {
                        referenceIds[newParentReferenceId] = uuid();
                    }
                    newParentReferenceId = referenceIds[newParentReferenceId];
                }

                return {
                    productId,
                    categoryId,
                    quantity,
                    modifiers,
                    referenceId: newReferenceId,
                    parentReferenceId: newParentReferenceId,
                    price: cost
                };
            });
            addBasketItems(itemsToPush)(dispatch);
        },
        [dispatch, items, logUserEvent, merchantId]
    );
    const handleOrderClick = React.useCallback(() => {
        onOrderClick(order);
    }, [onOrderClick, order]);
    const productsString = React.useMemo(() => {
        const products = items.filter(item => !item.parentReferenceId);

        return products.map(product => `${product.quantity}x ${product.productName}`).join(', ');
    }, [items]);
    const totalOrderCalories = React.useMemo(
        () => getOrderItemsCalories(order.items),
        [getOrderItemsCalories, order.items]
    );

    const priceString = React.useMemo(() => {
        if (showCalories(totalOrderCalories)) {
            return `${getCurrencyString(total.cost, currencyCode)} | ${getCaloriesString(
                totalOrderCalories.value
            )}`;
        }

        return getCurrencyString(total.cost, currencyCode);
    }, [currencyCode, getCaloriesString, total.cost, totalOrderCalories]);

    return (
        <Box onClick={handleOrderClick} width="100%" className={classes.orderPanel}>
            <Grid container justify="space-between">
                <Grid item xs={7}>
                    <Typography
                        color="textPrimary"
                        className={clsx(classes.textOpacity, classes.productNames)}
                    >
                        {productsString}
                    </Typography>
                </Grid>
                <Grid xs={4}>
                    <Typography color="textPrimary" align="right" className={classes.textOpacity}>
                        {date}
                    </Typography>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography>{priceString}</Typography>
                <IconButton classes={{ root: classes.iconButton }} onClick={handleAddToBasketClick}>
                    <AddShoppingCartOutlined className={classes.icon} />
                </IconButton>
            </Box>
        </Box>
    );
};
