import { Box } from '@material-ui/core';
import { userApi } from 'components/user/userApi';
import logger from 'lib/logger';
import { isString } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import React from 'react';
import { useSelector } from 'react-redux';
import { EOperatingSystem, getOperatingSystem } from 'src/utils/device';
import { ApplicationState } from 'store/store';
import AppleWalletImage from 'static/add-to-apple-wallet.svg';
import GoogleWalletImage from 'static/add-to-google-wallet.svg';
import { EGAEventName, useGAHelpers } from 'lib/useGAHelpers';

enum EWalletAvailable {
    None = 'NONE',
    Apple = 'APPLE',
    Google = 'GOOGLE'
}

const LINK_TO_GOOGLE_WALLET = 'https://pay.google.com/gp/v/save/{token}';

export const AddToWalletButton: React.FC = () => {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { user } = useAuth();
    const [googleToken, setGoogleToken] = React.useState<string>();
    const [appleUrl, setAppleUrl] = React.useState<string>();
    const [appleFileName, setAppleFileName] = React.useState<string>();
    const { logUserEvent } = useGAHelpers();
    const isAppleSupportedPlatform = React.useMemo(() => {
        const operatingSystem = getOperatingSystem();
        return operatingSystem === EOperatingSystem.IOS || operatingSystem === EOperatingSystem.MAC;
    }, []);
    const isGoogleSupportedPlatform = React.useMemo(() => {
        const operatingSystem = getOperatingSystem();
        return operatingSystem === EOperatingSystem.ANDROID || !isAppleSupportedPlatform;
    }, [isAppleSupportedPlatform]);
    const walletToShow = React.useMemo(() => {
        if (settings?.appleWalletEnabled && isAppleSupportedPlatform) {
            return EWalletAvailable.Apple;
        }
        if (settings?.googleWalletEnabled && isGoogleSupportedPlatform) {
            return EWalletAvailable.Google;
        }
        return EWalletAvailable.None;
    }, [
        isAppleSupportedPlatform,
        isGoogleSupportedPlatform,
        settings?.appleWalletEnabled,
        settings?.googleWalletEnabled
    ]);
    React.useEffect(() => {
        if (user && isString(user._id)) {
            if (walletToShow === EWalletAvailable.Google) {
                const loadGoogleUserPass = async () => {
                    try {
                        const response = await userApi.getGoogleWalletPass(user._id);
                        setGoogleToken(response.token);
                    } catch (e) {
                        logger.error(e.message, e);
                    }
                };
                loadGoogleUserPass();
            }
            if (walletToShow === EWalletAvailable.Apple) {
                const loadAppleUserPass = async () => {
                    try {
                        const response = await userApi.getAppleWalletPass(user._id);
                        const url = window.URL.createObjectURL(response);
                        const filename =
                            response?.headers
                                ?.get('content-disposition')
                                ?.split(';')
                                ?.find((n: string) => n.includes('filename='))
                                ?.replace('filename=', '')
                                .trim() ?? `${user._id}.pkpass`;
                        setAppleUrl(url);
                        setAppleFileName(filename);
                    } catch (e) {
                        logger.error(e.message, e);
                    }
                };
                loadAppleUserPass();
            }
        }
    }, [user, walletToShow]);
    const handleAddToGoogleClick = React.useCallback(() => {
        logUserEvent(EGAEventName.AddToGoogleWalletButtonTap);
    }, [logUserEvent]);
    const handleAddToAppleClick = React.useCallback(() => {
        logUserEvent(EGAEventName.AddToAppleWalletButtonTap);
    }, [logUserEvent]);
    if (walletToShow === EWalletAvailable.Apple && appleUrl) {
        return (
            <Box pt={2} pb={2} px={1}>
                <a href={appleUrl} download={appleFileName} onClick={handleAddToAppleClick}>
                    <img width="180px" src={AppleWalletImage} />
                </a>
            </Box>
        );
    }
    if (walletToShow === EWalletAvailable.Google && googleToken) {
        return (
            <Box pt={2} pb={2} px={1}>
                <a
                    href={LINK_TO_GOOGLE_WALLET.replace('{token}', googleToken)}
                    onClick={handleAddToGoogleClick}
                >
                    <img height="48px" src={GoogleWalletImage} />
                </a>
            </Box>
        );
    }
    return null;
};
