import { isDefined } from '@pepperhq/location-sdk/dist/lib/typeInference';
import { ViewBillContentSection } from 'components/bill/ui/ViewBill/ViewBillContentSection';
import {
    IAdjustmentLocalResource,
    IOrderPaymentReadResource,
    OrderScenario
} from 'components/order/model/Order';
import { useCurrencyString } from 'lib/useCurrencyString';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IBasketResourceV10 } from './model/Basket';
import { Box, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        boldText: {
            fontWeight: theme.typography.fontWeightBold
        }
    })
);

interface ViewBasketPaymentInfoProps {
    tip: number;
    scenario: OrderScenario;
    calculatedBasket: IBasketResourceV10 | null;
    giftCardPayment?: IOrderPaymentReadResource;
    total: number;
    selectedAward?: IAdjustmentLocalResource;
}

export const ViewBasketPaymentInfo: React.FC<ViewBasketPaymentInfoProps> = ({
    tip,
    scenario,
    calculatedBasket,
    giftCardPayment,
    total,
    selectedAward
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const getCurrencyString = useCurrencyString();
    const paymentInfoStrings = React.useMemo(() => {
        const strings: [string, string, boolean?][] = [];

        strings.push([
            t('BILL_CHECKOUT_PAYMENT_INFO_ITEMS'),
            getCurrencyString(calculatedBasket?.total.cost ?? 0)
        ]);

        if (calculatedBasket?.total.charges !== 0) {
            strings.push([
                t('BILL_CHECKOUT_PAYMENT_INFO_CHARGES'),
                getCurrencyString(calculatedBasket?.total.charges ?? 0)
            ]);
        }

        if (
            (OrderScenario.PREORDER === scenario || OrderScenario.ORDER_TO_TABLE === scenario) &&
            calculatedBasket?.adjustments.length
        ) {
            calculatedBasket?.adjustments.forEach(item => {
                if (item.type === 'DISCOUNT') {
                    const value = item.quantity ? item.quantity * item.value : item.value;
                    strings.push([
                        item.description || t('BILL_CHECKOUT_PAYMENT_INFO_DISCOUNTS'),
                        getCurrencyString(value)
                    ]);
                }
            });
        }

        if (selectedAward) {
            strings.push([
                selectedAward.description || t('BILL_CHECKOUT_PAYMENT_INFO_DISCOUNTS'),
                getCurrencyString(selectedAward.totalValue)
            ]);
        }

        if (OrderScenario.TABLE === scenario && calculatedBasket?.total.discounts !== 0) {
            strings.push([
                t('BILL_CHECKOUT_PAYMENT_INFO_DISCOUNTS'),
                getCurrencyString(calculatedBasket?.total.discounts ?? 0)
            ]);
        }

        if (calculatedBasket?.total.taxes.length && calculatedBasket.total.taxes.length > 0) {
            calculatedBasket.total.taxes
                .filter(tax => !tax.inclusive)
                .forEach(tax => strings.push([tax.name, getCurrencyString(tax.amount)]));
        }

        if (tip > 0) {
            strings.push([t('BILL_CHECKOUT_PAYMENT_INFO_TIP'), getCurrencyString(tip)]);
        }

        if (giftCardPayment && isDefined(giftCardPayment.amount)) {
            strings.push([t('CHECKOUT_GIFTCARD_SUMMARY'), getCurrencyString(giftCardPayment.amount)]);
        }
        strings.push([t('BILL_CHECKOUT_PAYMENT_INFO_TOTAL_TO_PAY'), getCurrencyString(total), true]);

        return strings;
    }, [
        t,
        getCurrencyString,
        calculatedBasket?.total.cost,
        calculatedBasket?.total.charges,
        calculatedBasket?.total.discounts,
        calculatedBasket?.total.taxes,
        calculatedBasket?.adjustments,
        scenario,
        tip,
        giftCardPayment,
        total,
        selectedAward
    ]);
    return (
        <ViewBillContentSection
            title={t('BILL_CHECKOUT_PAYMENT_INFO_TITLE')}
            subtitle={t('BILL_CHECKOUT_PAYMENT_INFO_BODY')}
            bottomSpacing={2}
        >
            {paymentInfoStrings.map(([key, value, bold]) => (
                <Box key={key} display="flex" justifyContent="space-between">
                    <Typography className={clsx({ [classes.boldText]: bold })}>{key}</Typography>
                    <Typography className={clsx({ [classes.boldText]: bold })}>{value}</Typography>
                </Box>
            ))}
        </ViewBillContentSection>
    );
};
