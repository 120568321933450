import * as React from 'react';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import clsx from 'clsx';
import { addOpacity } from 'lib/helpers';
import { LineIcon } from 'lib/LineIcon';

interface IProps {
    icon?: string;
    title: string;
    subtitle: string;
    onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            borderBottom: `1px solid ${addOpacity(theme.palette.text.primary, 0.1)}`
        },
        title: {
            fontSize: theme.spacing(2)
        },
        clickable: {
            cursor: 'pointer'
        },
        subtitle: {
            fontSize: theme.spacing(1.75),
            color: addOpacity(theme.palette.text.primary, 0.7)
        }
    })
);

export const ActionItem: React.FC<IProps> = React.memo(({ icon, title, subtitle, onClick }) => {
    const classes = useStyles();

    return (
        <Box
            display="flex"
            alignItems="center"
            paddingY={1.5}
            paddingX={2}
            onClick={onClick}
            className={clsx(classes.wrapper, { [classes.clickable]: !!onClick })}
        >
            {icon && <LineIcon fontSize={30} name={icon} />}
            <Box marginLeft={1.5} marginRight="auto">
                <Typography className={classes.title}>{title}</Typography>
                <Typography className={classes.subtitle}>{subtitle}</Typography>
            </Box>
            {onClick && <ArrowForwardIosIcon fontSize="small" />}
        </Box>
    );
});

ActionItem.displayName = 'ActionItem';
