import * as React from 'react';
import { Box, createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';

interface IProps {
    title?: string;
    subtitle?: string;
    bottomSpacing?: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            color: theme.palette.titleTextColour
        }
    })
);

export const ViewBillContentSection: React.FC<React.PropsWithChildren<IProps>> = ({
    title,
    subtitle,
    children,
    bottomSpacing = 0
}) => {
    const classes = useStyles();

    return (
        <Box marginBottom={bottomSpacing}>
            <Paper variant="outlined">
                <Box padding={1.5}>
                    {title && (
                        <Typography align="center" variant="body1" className={classes.title}>
                            {title}
                        </Typography>
                    )}
                    {subtitle && (
                        <Typography display="block" align="center" variant="caption">
                            {subtitle}
                        </Typography>
                    )}
                    <Box marginTop={title ? 1.5 : 0}>{children}</Box>
                </Box>
            </Paper>
        </Box>
    );
};
