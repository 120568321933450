import React, { Suspense } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import random from 'lodash/random';
import sample from 'lodash/sample';
import { getLocalMerchantId } from 'components/settings/localStore';
import { getLoadingImagesFolder, preloadImage } from 'lib/imageHelpers';
import { Throbber } from 'lib/Throbber';
const CoffeeCup = React.lazy(() => import('./CoffeeCup'));
const CoffeeMug = React.lazy(() => import('./CoffeeMug'));
const Cupcake = React.lazy(() => import('./Cupcake'));
const Donut = React.lazy(() => import('./Donut'));
const PanFlip = React.lazy(() => import('./PanFlip'));
const Popsicle = React.lazy(() => import('./Popsicle'));

export enum ELoadingSVG {
    COFFEE_CUP = 'COFFEE_CUP',
    COFFEE_MUG = 'COFFEE_MUG',
    CUPCAKE = 'CUPCAKE',
    DONUT = 'DONUT',
    FRYING_PAN = 'FRYING_PAN',
    POPSICLE = 'POPSICLE'
}

interface IRandomLoadingSVGProps {
    enabledImages?: ELoadingSVG[];
    fallbackText: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loadingSvg: {
            width: '80%',
            maxWidth: theme.spacing(50)
        }
    })
);

async function loadImage(excludeArray: number[] = [], merchantId: string): Promise<string | null> {
    const numbersArray = [1, 2, 3, 4, 5, 6].filter(item => !excludeArray.includes(item));
    const selectedNumber = numbersArray[random(1, numbersArray.length - 1)];
    let imageString: string | undefined;

    imageString = await preloadImage(`${getLoadingImagesFolder(merchantId)}/loading${selectedNumber}.gif`);
    if (!imageString) {
        // Or reassign image variable to be an SVG if current way doesn't work;
        imageString = await preloadImage(
            `${getLoadingImagesFolder(merchantId)}/loading${selectedNumber}.svg`
        );
    }
    if (!imageString) {
        return null;
    }
    return imageString;
}

export const RandomLoading: React.FC<IRandomLoadingSVGProps> = props => {
    const { enabledImages, fallbackText } = props;
    const [merchantLoadingSrc, setMerchantLoadingSrc] = React.useState<string | null>();
    const classes = useStyles();

    React.useEffect(() => {
        const merchantId = getLocalMerchantId();
        if (merchantId) {
            loadImage([], merchantId).then(response => {
                setMerchantLoadingSrc(response);
            });
        }
    }, []);

    const randomSVG = React.useMemo(() => {
        if (!enabledImages) {
            return null;
        }
        const loadingSVGName = sample(enabledImages);
        switch (loadingSVGName) {
            case ELoadingSVG.COFFEE_CUP:
                return CoffeeCup;
            case ELoadingSVG.COFFEE_MUG:
                return CoffeeMug;
            case ELoadingSVG.CUPCAKE:
                return Cupcake;
            case ELoadingSVG.DONUT:
                return Donut;
            case ELoadingSVG.FRYING_PAN:
                return PanFlip;
            case ELoadingSVG.POPSICLE:
                return Popsicle;
            default:
                return null;
        }
    }, [enabledImages]);

    const loadingView = React.useMemo(() => {
        if (merchantLoadingSrc) {
            return <img src={merchantLoadingSrc} className={classes.loadingSvg} alt="loading" />;
        }
        if (merchantLoadingSrc !== undefined) {
            if (randomSVG) {
                return React.createElement(randomSVG, { className: classes.loadingSvg });
            }

            return <Throbber text={fallbackText} />;
        }

        return null;
    }, [classes.loadingSvg, fallbackText, merchantLoadingSrc, randomSVG]);

    return <Suspense fallback={<Throbber text={fallbackText} />}>{loadingView}</Suspense>;
};
