import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { isServerError } from 'src/utils/request';
import * as Yup from 'yup';
import { MerchantTitle } from 'app/MerchantTitle';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { PasswordFormField } from 'lib/form/PasswordFormField';
import { TextFormField } from 'lib/form/TextFormField';
import logger from 'lib/logger';
import { useLocalHistory } from 'lib/useLocalHistory';
import { ROUTES } from 'pages/routes';
import { finalizeSignIn } from 'store/auth/authActions';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/LoadingButton';
import { userApi } from '../userApi';
import { useSnackbar } from 'notistack';
import { EGAEventName, useGAHelpers } from 'lib/useGAHelpers';

interface CreatePasswordProps {
    identity: string;
    isLoading: boolean;
}

interface CreatePasswordFormData {
    code: string;
    password: string;
}

const initialValues: CreatePasswordFormData = {
    code: '',
    password: ''
};

const useStyles = makeStyles(() =>
    createStyles({
        description: {
            whiteSpace: 'pre-wrap'
        }
    })
);

export const CreatePassword: React.FC<CreatePasswordProps> = ({ identity, isLoading }) => {
    const { t } = useTranslation();
    const { push } = useLocalHistory();
    const classes = useStyles();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { logUserEvent } = useGAHelpers();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const validationScheme = React.useMemo(
        () =>
            Yup.object<CreatePasswordFormData>().shape({
                code: Yup.string().required(t('FORMS_VALIDATION_REQUIRED')),
                password: Yup.string().required(t('FORMS_VALIDATION_REQUIRED'))
            }),
        [t]
    );
    const hasAppUserAuth = React.useMemo(
        () => !!settings && !!settings.iam && !!settings.iam.appUserAuth,
        [settings]
    );
    const handleSubmit = React.useCallback(
        async (data: CreatePasswordFormData) => {
            try {
                const userData = await userApi.createNewPassword(identity, data.code, data.password);
                logUserEvent(EGAEventName.CreatePasswordSubmit);
                return finalizeSignIn(userData)(dispatch);
            } catch (e) {
                logger.error(e);
                const generalErrorMessage = e?.data?.message || t('GENERAL_ERROR_PROCESSING_REQUEST');
                let errorMessage;
                switch (e.status) {
                    case 401: {
                        errorMessage = t('DIALOG_RESET_UNAUTHORISED_MESSAGE');
                        break;
                    }
                    case 422: {
                        if (!hasAppUserAuth) {
                            errorMessage = t('DIALOG_RESET_UNAUTHORISED_MESSAGE');
                        } else {
                            errorMessage = generalErrorMessage;
                        }

                        break;
                    }
                    default: {
                        errorMessage = generalErrorMessage;
                        break;
                    }
                }
                if (e?.data?.code === 'E-WAF-0001') {
                    errorMessage = e.data.message;
                }

                enqueueSnackbar(errorMessage || generalErrorMessage, { variant: 'error' });
            }
        },
        [identity, logUserEvent, dispatch, t, enqueueSnackbar, hasAppUserAuth]
    );
    const handleKeyPress = React.useCallback(
        (submit: () => Promise<void>) => (e: React.KeyboardEvent) => {
            if (e.keyCode === 13) {
                submit();
            }
        },
        []
    );

    if (!identity) {
        push(ROUTES.USER.FORGOT_PASSWORD);
    }

    React.useEffect(() => {
        userApi.resetPassword(identity).catch(e => {
            if (!!e?.status && isServerError(e.status)) {
                enqueueSnackbar(t('GENERAL_ERROR_PROCESSING_REQUEST'), { variant: 'error' });
                push(ROUTES.USER.FORGOT_PASSWORD);
            }
        });
    }, [dispatch, enqueueSnackbar, identity, push, t]);

    return (
        <>
            <MerchantTitle title={t('AUTH_CONFIRM_RESET_TITLE')} />
            <MuiAppBar
                title={t('AUTH_CONFIRM_RESET_TITLE')}
                onBackEventName={EGAEventName.CreatePasswordBack}
            />
            <Box padding={2} paddingTop={4}>
                <Box marginBottom={2}>
                    <Typography className={classes.description}>
                        {t('ONBOARDING_RESET_CONFIRMATION_CREDENTIAL_MESSAGE', {
                            credential: identity
                        })}
                    </Typography>
                </Box>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationScheme}
                    onSubmit={handleSubmit}
                >
                    {({ submitForm, isSubmitting, isValid, values: { code, password } }) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextFormField
                                        name="code"
                                        InputProps={{ autoComplete: 'one-time-code' }}
                                        label={t('ONBOARDING_RESET_CONFIRMATION_CODE')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <PasswordFormField
                                        name="password"
                                        label={t('ONBOARDING_RESET_PASSWORD')}
                                        onKeyDown={handleKeyPress(submitForm)}
                                        description={t('ONBOARDING_PASSWORD_STRENGTH_LABEL')}
                                        autoComplete="new-password"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        onClick={submitForm}
                                        disabled={isSubmitting || !isValid || !code || !password || isLoading}
                                        loading={isSubmitting || isLoading}
                                        variant="contained"
                                        fullWidth
                                        color="primary"
                                    >
                                        {t('BUTTON_SUBMIT')}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};
