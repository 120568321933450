import { RequestAction } from './requestActions';
import { RequestActionTypes } from './requestActionTypes';

export interface RequestState {
    lastRequest?: () => void;
}

export const initialState: RequestState = {};

export default function (state: RequestState = initialState, action: RequestAction): RequestState {
    switch (action.type) {
        case RequestActionTypes.SET_LAST_REQUEST: {
            const { request } = action;
            return { ...state, lastRequest: request };
        }
        case RequestActionTypes.CLEAR_LAST_REQUESTS:
            return initialState;
        default:
            return state;
    }
}
