import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Divider, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { addOpacity, roundToDecimal } from 'lib/helpers';
import { isDefined, isNumber, isString } from 'lib/typeInference';
import { useCurrencyString } from 'lib/useCurrencyString';
import { ApplicationState } from 'store/store';
import { useBillSplittingModal } from '../hooks/useBillSplittingModal';
import { QuickPayButton } from '../ui/PayButton';
import { PayLinkButton } from '../ui/PayLinkButton';
import { SplitControls } from '../ui/SplitControls';
import { PayBaseModal } from './PayBaseModal';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        fontWeight: 700,
        fontSize: '24px'
    },
    subtitle: {
        fontSize: '16px'
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    content: {
        fontSize: '16px',
        fontWeight: 500,
        paddingRight: theme.spacing(2)
    },
    youPay: {
        fontWeight: 500,
        fontSize: '24px',
        padding: theme.spacing(2, 0),
        paddingBottom: 0
    },
    description: {
        opacity: 0.4,
        paddingBottom: theme.spacing(2)
    },
    dividerRow: {
        width: '100%',
        position: 'relative'
    },
    dividerOverflow: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        padding: theme.spacing(1, 5),
        backgroundColor: theme.palette.background.paper
    },
    dividerText: {
        color: addOpacity(theme.palette.text.primary, 0.3)
    },
    textField: {
        width: 86,
        margin: theme.spacing(1, 0)
    },
    checkInput: {
        width: 108
    },
    input: {
        padding: theme.spacing(1.5, 2),
        textAlign: 'center'
    },
    controls: {
        position: 'relative'
    },
    blur: {
        filter: 'blur(8px)',
        opacity: 0.5
    },
    controlsOverflow: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    }
}));

interface PayBillSplittingModalProps {
    onClose: () => void;
    onApply: (splits?: number, yourSplits?: number, customAmount?: number) => void;
    open: boolean;
    total?: number;
    yourShares?: number;
    customAmount?: number;
    shares?: number;
    disableShares?: boolean;
    limit?: number;
}

export const PayBillSplittingModal: React.FC<PayBillSplittingModalProps> = ({
    open,
    onClose,
    total,
    onApply,
    yourShares: defaultYourShares,
    shares: defaultShares,
    customAmount: defaultCustomAmount,
    limit
}) => {
    const { t } = useTranslation();
    const region = useSelector((state: ApplicationState) => state.settings.settings?.region);
    const getCurrencyString = useCurrencyString();
    const classes = useStyles();
    const { shares, yourShares, customAmount, toPay } = useBillSplittingModal(
        total ?? 0,
        defaultShares,
        defaultYourShares,
        defaultCustomAmount
    );
    const handleSubmit = React.useCallback(() => {
        if (customAmount.value && !Number.isNaN(Number(customAmount.value))) {
            onApply(undefined, undefined, roundToDecimal(Number(customAmount.value)));
        } else {
            onApply(shares.value, yourShares.value);
        }
        onClose();
    }, [customAmount.value, onApply, onClose, shares.value, yourShares.value]);
    const handlePayFull = React.useCallback(() => {
        if (!!defaultYourShares || isDefined(defaultCustomAmount)) {
            onApply();
            customAmount.onReset();
        }
        onClose();
    }, [customAmount, defaultCustomAmount, defaultYourShares, onApply, onClose]);
    const isValid = React.useMemo(() => {
        if (isDefined(customAmount.value) && isNumber(limit)) {
            return Number(customAmount.value) <= limit && customAmount.value >= 0;
        }
        return true;
    }, [customAmount.value, limit]);
    const handleEnterPress = React.useCallback(
        (e: React.KeyboardEvent) => {
            if (e.key === 'Enter' && isValid) {
                return handleSubmit();
            }
            // Prevent user entering anything that is not number or backspace
            const regex = new RegExp(/([0-9])/g);
            if (!regex.test(e.key) && e.keyCode !== 8) {
                if (e.key === '.') {
                    if (isString(customAmount.value) && customAmount.value.includes('.')) {
                        e.preventDefault();
                    }
                } else {
                    e.preventDefault();
                }
            }
        },
        [customAmount.value, handleSubmit, isValid]
    );
    React.useEffect(() => {
        if (limit && isNumber(defaultCustomAmount) && limit < defaultCustomAmount) {
            onApply();
            customAmount.onReset();
        }
    }, [customAmount, defaultCustomAmount, limit, onApply]);

    if (!isDefined(total)) {
        return null;
    }
    return (
        <PayBaseModal open={open} onClose={onClose}>
            <Box px={1} py={1.5}>
                <Typography className={classes.title} align="center">
                    {t('PAT_QUICKPAY_SPLIT_MODAL_TITLE')}
                </Typography>
                <Typography className={classes.subtitle} align="center">
                    {t('PAT_QUICKPAY_SPLIT_MODAL_SUBTITLE', { total: getCurrencyString(total ?? 0) })}
                </Typography>
                <Box className={classes.controls}>
                    <Box className={clsx({ [classes.blur]: isDefined(customAmount.value) })}>
                        <Box>
                            <Box className={classes.row} pt={3}>
                                <Typography className={classes.content}>
                                    {t('PAT_QUICKPAY_SPLIT_MODAL_SHARES_INPUT')}
                                </Typography>
                                <SplitControls
                                    increase={shares.increase}
                                    decrease={shares.decrease}
                                    value={shares.value}
                                    min={1}
                                    max={9}
                                />
                            </Box>
                            <Box className={classes.row} pt={2} pb={2}>
                                <Typography className={classes.content}>
                                    {t('PAT_QUICKPAY_SPLIT_MODAL_YOUR_SHARES_INPUT')}
                                </Typography>
                                <SplitControls
                                    increase={yourShares.increase}
                                    decrease={yourShares.decrease}
                                    value={yourShares.value}
                                    min={1}
                                    max={shares.value}
                                />
                            </Box>
                        </Box>
                        <Box className={classes.row}>
                            <Typography className={classes.youPay}>
                                {t('PAT_QUICKPAY_SPLIT_MODAL_YOUR_SHARE')}
                            </Typography>
                            <Typography className={classes.youPay}>{getCurrencyString(toPay)}</Typography>
                        </Box>
                        <Box className={classes.row}>
                            <Typography className={classes.description} variant="caption">
                                {t('PAT_QUICKPAY_SPLIT_MODAL_HELPER_TEXT')}
                            </Typography>
                        </Box>
                    </Box>
                    {isDefined(customAmount.value) && (
                        <Box className={classes.controlsOverflow}>
                            <Typography align="center">
                                {t('PAT_QUICKPAY_SPLIt_MODAL_CUSTOM_AMOUNT_RESET')}
                            </Typography>
                            <PayLinkButton onClick={customAmount.onReset}>
                                {t('PAT_QUICKPAY_SPLIT_MODAL_CUSTOM_AMOUNT_RESET_BTN')}
                            </PayLinkButton>
                        </Box>
                    )}
                </Box>
                <Box className={classes.dividerRow} mb={1}>
                    <Divider />
                    <Box className={classes.dividerOverflow}>
                        <Typography className={classes.dividerText}>
                            {t('PAT_QUICKPAY_SPLIT_MODAL_OR')}
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography className={classes.content}>
                        {t('PAT_QUICKPAY_SPLIT_MODAL_CUSTOM_AMOUNT')}
                    </Typography>
                    <TextField
                        variant="outlined"
                        value={customAmount.value ?? ''}
                        type="number"
                        placeholder={String(toPay)}
                        onChange={customAmount.onChange}
                        onKeyPress={handleEnterPress}
                        className={clsx(classes.textField, classes.checkInput)}
                        inputProps={{ className: classes.input }}
                        InputProps={{
                            startAdornment: region?.currencySymbol,
                            autoComplete: 'off',
                            autoFocus: false,
                            inputMode: 'numeric'
                        }}
                    />
                </Box>
                <Box>
                    <QuickPayButton onClick={handlePayFull} secondary>
                        {t('PAT_QUICKPAY_SPLIT_MODAL_PAY_FULL')}
                    </QuickPayButton>
                    <QuickPayButton disabled={!isValid} onClick={handleSubmit}>
                        {isValid
                            ? t('PAT_QUICKPAY_SPLIT_MODAL_SUBMIT')
                            : t('PAT_QUICKPAY_SPLIT_MODAL_INVALID', {
                                  amount: getCurrencyString(limit ?? 0)
                              })}
                    </QuickPayButton>
                </Box>
            </Box>
        </PayBaseModal>
    );
};
