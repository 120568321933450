import { IPaymentOptionValue } from 'components/bill/ui/ViewBill/Checkout/PaymentMethod/PaymentMethodDialog';
import { OrderPaymentType } from 'components/order/model/Order';
import { UserCard } from 'components/user/model/User';
import { useAuth } from 'lib/useAuth';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isApplePayReady } from 'src/integrations/PaymentProvider/ApplePayButton';
import { browser } from 'src/utils/browser';
import { getUserCard } from 'store/auth/authActions';
import { SettingsStateSettings } from 'store/settings/settingsReducer';
import { ApplicationState } from 'store/store';

const getPaymentOptionInitialState = (settings: SettingsStateSettings | undefined) => {
    const isSquare = settings?.paymentProvider === 'SQUARE';
    const isJudo = settings?.paymentProvider === 'JUDOPAY';
    const isStripe = settings?.paymentProvider === 'STRIPE';

    if (settings?.googlePayEnabled && browser().isChrome) {
        return { type: OrderPaymentType.GOOGLEPAY };
    }

    // There is no chance to have a Safari on the non-apple device, but just to doublecheck
    if ((isSquare || isJudo || isStripe) && settings?.applePayEnabled && isApplePayReady()) {
        return { type: OrderPaymentType.APPLEPAY };
    }
    return { type: OrderPaymentType.CARD_ON_FILE };
};

export function useBasketPaymentMethods() {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const dispatch = useDispatch();
    const { user, card } = useAuth();
    const [addNewCardOpen, setAddNewCardOpen] = React.useState(false);
    const [cardsInitialLoaded, setCardsInitialLoaded] = React.useState(false);
    const [selectedPaymentOption, setSelectedPaymentOption] = React.useState<IPaymentOptionValue>(() =>
        getPaymentOptionInitialState(settings)
    );
    React.useEffect(() => {
        if (user && user.id && !cardsInitialLoaded) {
            getUserCard(user.id)(dispatch).then(() => {
                setCardsInitialLoaded(true);
            });
        }
    }, [cardsInitialLoaded, dispatch, user]);
    React.useEffect(() => {
        if (card) {
            setSelectedPaymentOption({ type: OrderPaymentType.CARD_ON_FILE, card });
        } else {
            setSelectedPaymentOption(getPaymentOptionInitialState(settings));
        }
    }, [card, settings]);
    const handleCardAdded = React.useCallback(
        (paymentType: OrderPaymentType, selectedCard: UserCard | null) => () => {
            if (paymentType === OrderPaymentType.CARD_ON_FILE && selectedCard) {
                setSelectedPaymentOption({ card: selectedCard, type: paymentType });
            }
        },
        []
    );
    const handleCardChange = React.useCallback((value: IPaymentOptionValue) => {
        setSelectedPaymentOption(value);
    }, []);
    const handleAddNewCardOpen = React.useCallback(() => {
        setAddNewCardOpen(true);
    }, []);
    const handleAddNewCardClose = React.useCallback(() => {
        setAddNewCardOpen(false);
    }, []);
    return {
        selectedPaymentOption,
        onCardAdded: handleCardAdded,
        onCardChange: handleCardChange,
        onAddNewCardOpen: handleAddNewCardOpen,
        onAddNewCardClose: handleAddNewCardClose,
        addNewCardOpen
    };
}
