import { HttpClient } from 'components/http/HttpClient';
import { getLocalSettings } from 'components/settings/localStore';
import { getRequestDate } from 'lib/helpers';
import { TimeslotData } from './model/Timeslot';

class TimeslotsApi extends HttpClient {
    constructor() {
        super({ baseURL: process.env.TIMESLOT_SERVICE_URL, url: '/timeslots' });
    }
    public getTimeslots(locationId: string) {
        const localManifest = getLocalSettings();
        if (!localManifest) {
            throw new Error('merchantId is missed');
        }
        const tenantId = localManifest.merchantId;
        const date = getRequestDate();
        return this.getRequest<TimeslotData>({
            url: '',
            configs: { params: { locationId, tenantId, date } },
            headerConfigs: { shouldIncludeXApplicationId: false }
        });
    }
}
export const timeslotsApi = new TimeslotsApi();
