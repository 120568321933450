import React from 'react';
import { Button, ButtonProps, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { addOpacity } from 'lib/helpers';

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        height: '40px',
        width: '40px',
        borderRadius: '50%',
        borderColor: addOpacity(theme.palette.text.primary, 0.7),
        color: addOpacity(theme.palette.text.primary, 0.7),
        minWidth: '40px'
    }
}));

export const SplitContolsButton: React.FC<ButtonProps> = ({ className, ...props }) => {
    const classes = useStyles();
    return <Button variant="outlined" {...props} className={clsx(classes.button, className)} />;
};
