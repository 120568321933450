import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Form, Formik } from 'formik';
import omitBy from 'lodash/omitBy';
import isEqual from 'lodash/isEqual';
import { useAsyncData } from 'src/hooks/useAsyncData';
import { isLoading, isSuccess } from 'src/utils/request';
import * as yup from 'yup';
import { MerchantTitle } from 'app/MerchantTitle';
import { CredentialProvider, User } from 'components/user/model/User';
import { userApi } from 'components/user/userApi';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import logger from 'lib/logger';
import { useAuth } from 'lib/useAuth';
import { updateUser } from 'store/auth/authActions';
import { LoadingButton } from 'ui/LoadingButton';
import { EFormFields } from './enums';
import { UserDetailsForm } from './UserDetailsForm';
import { isNull, isString } from 'lib/typeInference';
import { useSnackbar } from 'notistack';

interface IFormFields {
    [EFormFields.FIRST_NAME]: string;
    [EFormFields.LAST_NAME]: string;
    [EFormFields.DATE_OF_BIRTH]: string;
    [EFormFields.MARKETING]: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            padding: theme.spacing(2)
        },
        root: {
            paddingTop: theme.spacing(2)
        },
        buttonWrapper: {
            marginTop: 'auto'
        },
        button: {
            height: theme.spacing(6),
            fontSize: '1.2em'
        },
        form: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }
    })
);

export const UserDetails: React.FC = React.memo(() => {
    const { user } = useAuth();
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [updateUserRequest, setUpdateUserRequest, setUpdateUserRequestInitial] = useAsyncData<User>();
    const [isBirthdateDisabled, setIsBirthdateDisabled] = React.useState<boolean>(Boolean(user?.birthdate));
    const { enqueueSnackbar } = useSnackbar();

    const initialValues = React.useCallback(
        (userDetails?: User) => ({
            [EFormFields.FIRST_NAME]: userDetails?.firstName || '',
            [EFormFields.LAST_NAME]: userDetails?.lastName || '',
            [EFormFields.DATE_OF_BIRTH]: userDetails?.birthdate || '',
            [EFormFields.MARKETING]: userDetails?.hasAgreedToReceiveMarketing || false
        }),
        []
    );

    const primaryCredential = React.useMemo(() => {
        if (user?.credentials) {
            return user.credentials.find(item => item.isPrimary);
        }
        return undefined;
    }, [user?.credentials]);

    const credentials = React.useMemo(() => {
        if (primaryCredential) {
            if (
                primaryCredential.provider === CredentialProvider.APPLE ||
                primaryCredential.provider === CredentialProvider.GOOGLE
            ) {
                const secondaryCredential = user?.credentials.find(item => !item.isPrimary);
                if (secondaryCredential) {
                    return { id: secondaryCredential.id, provider: secondaryCredential.provider };
                }
            }
            return { id: primaryCredential.id, provider: primaryCredential.provider };
        }
        return {
            id: (user?.credentials && user?.credentials[0]?.id) ?? '',
            provider: (user?.credentials && user?.credentials[0]?.provider) ?? ''
        };
    }, [primaryCredential, user?.credentials]);

    const validationSchema = React.useMemo(
        () =>
            yup.object({
                [EFormFields.FIRST_NAME]: yup.string().required(t('FORMS_VALIDATION_REQUIRED')),
                [EFormFields.LAST_NAME]: yup.string().required(t('FORMS_VALIDATION_REQUIRED'))
            }),
        [t]
    );

    const handleSubmit = React.useCallback(
        async (form: IFormFields) => {
            if (user && user.id) {
                const cleanedFormValues = omitBy(
                    form,
                    value => isNull(value) || (isString(value) && value.trim() === '')
                );
                try {
                    const newUserData: User = await setUpdateUserRequest(() =>
                        userApi.updateUser(user.id, cleanedFormValues)
                    );
                    setIsBirthdateDisabled(!!newUserData.birthdate);
                    dispatch(updateUser(newUserData));
                    enqueueSnackbar(t('DIALOG_USER_DETAILS_MESSAGE'), { variant: 'success' });
                } catch (err) {
                    logger.error(err);
                }
            }
        },
        [dispatch, enqueueSnackbar, setUpdateUserRequest, t, user]
    );

    const isCredentialsDisabled = React.useMemo(
        () =>
            primaryCredential?.provider === CredentialProvider.APPLE ||
            primaryCredential?.provider === CredentialProvider.GOOGLE,
        [primaryCredential?.provider]
    );

    return (
        <Box height="100%" flexDirection="column" display="flex" className={classes.wrapper}>
            <MerchantTitle title={t('ACCOUNT_DETAILS')} />
            <MuiAppBar title={t('ACCOUNT_DETAILS')} />
            <Formik
                initialValues={initialValues(user)}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ isValid, values }) => {
                    let isDirty = !isEqual(values, initialValues(user));

                    if (isSuccess(updateUserRequest) && updateUserRequest.data) {
                        isDirty = !isEqual(values, initialValues(updateUserRequest.data));
                        if (!isEqual(values, initialValues(updateUserRequest.data))) {
                            setUpdateUserRequestInitial();
                        }
                    }

                    return (
                        <Form className={classes.form}>
                            <div className={classes.root}>
                                <UserDetailsForm
                                    isBirthDateDisabled={isBirthdateDisabled}
                                    credentials={credentials}
                                    isCredentialsDisabled={isCredentialsDisabled}
                                />
                            </div>
                            <div className={classes.buttonWrapper}>
                                <LoadingButton
                                    className={classes.button}
                                    disabled={!isValid || !isDirty || isLoading(updateUserRequest)}
                                    loading={isLoading(updateUserRequest)}
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    fullWidth
                                >
                                    {t('USER_DETAILS_ACTIONS_SUBMIT')}
                                </LoadingButton>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
});

UserDetails.displayName = 'UserDetails';
