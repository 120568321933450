import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import * as yup from 'yup';
import { IOrderPaymentReadResource } from 'components/order/model/Order';
import { TextFormField } from 'lib/form/TextFormField';
import { isDefined } from 'lib/typeInference';
import { useCurrencyString } from 'lib/useCurrencyString';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            display: 'flex',
            alignItems: 'flex-start'
        },
        inputField: {
            flex: 1
        },
        applyButton: {
            marginLeft: theme.spacing(1),
            height: theme.spacing(5)
        }
    })
);

interface IProps {
    giftCardValue: string | undefined;
    onGiftCardApply: (giftCardNumber: string | undefined) => void;
    payment?: IOrderPaymentReadResource;
}

export const GiftCard: React.FC<IProps> = ({ giftCardValue, payment, onGiftCardApply }) => {
    const classes = useStyles();
    const getCurrencyString = useCurrencyString();
    const { t } = useTranslation();
    const validation = React.useMemo(
        () =>
            yup.object<{ giftCardNumber: string }>().shape({
                giftCardNumber: yup.string().required(t('CHECKOUT_GIFTCARD_INVALID_FORMAT'))
            }),
        [t]
    );
    const initialFields: { giftCardNumber: string } = React.useMemo(
        () => ({
            giftCardNumber: giftCardValue || ''
        }),
        [giftCardValue]
    );
    const handleSubmit = React.useCallback(
        ({ giftCardNumber }: { giftCardNumber: string }) => {
            if (onGiftCardApply) {
                onGiftCardApply(giftCardNumber.replace(/\s/g, ''));
            }
        },
        [onGiftCardApply]
    );
    const handleReset = React.useCallback(
        (resetForm: () => void) => () => {
            resetForm();
            onGiftCardApply(undefined);
        },
        [onGiftCardApply]
    );
    const isInvalid = React.useMemo(() => !payment && !!giftCardValue, [giftCardValue, payment]);
    const isSelected = React.useMemo(() => !isInvalid && giftCardValue, [giftCardValue, isInvalid]);

    return (
        <Formik initialValues={initialFields} validationSchema={validation} onSubmit={handleSubmit}>
            {({ submitForm, resetForm, values }) => (
                <div className={classes.wrapper}>
                    <div className={classes.inputField}>
                        <TextFormField
                            size="small"
                            name="giftCardNumber"
                            label=""
                            placeholder={t('CHECKOUT_GIFTCARD_ENTRY_TEXT')}
                            disabled={!!isSelected}
                            trimWhiteSpaces="all"
                        />
                        {payment && isDefined(payment.remainingBalance) && (
                            <Typography variant="caption">
                                {t('CHECKOUT_GIFTCARD_REMAINING_BALANCE', {
                                    balance: getCurrencyString(payment.remainingBalance)
                                })}
                            </Typography>
                        )}
                    </div>
                    {isSelected ? (
                        <Button
                            onClick={handleReset(resetForm)}
                            className={classes.applyButton}
                            variant="contained"
                            color="primary"
                        >
                            {t('CHECKOUT_GIFTCARD_CLEAR_BTN')}
                        </Button>
                    ) : (
                        <Button
                            onClick={submitForm}
                            className={classes.applyButton}
                            variant="contained"
                            color="primary"
                            disabled={!values.giftCardNumber.trim()}
                        >
                            {t('CHECKOUT_GIFTCARD_APPLY_BTN')}
                        </Button>
                    )}
                </div>
            )}
        </Formik>
    );
};
