import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import { IOrderPaymentReadResource, IUserReadResourceV12 } from 'components/order/model/Order';
import { addOpacity } from 'lib/helpers';
import { isDefined, isNumber } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import { useCurrencyString } from 'lib/useCurrencyString';

interface PaySummaryPayments {
    payments?: IOrderPaymentReadResource[];
    users?: IUserReadResourceV12[];
    balance?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: theme.spacing(1, 0)
    },
    typography: {
        color: addOpacity(theme.palette.text.primary, 0.5),
        lineHeight: 1.7,
        fontSize: '14px',
        fontWeight: 400
    },
    balance: {
        fontSize: '18px',
        fontWeight: 700,
        color: theme.palette.text.primary,
        paddingBottom: theme.spacing(0.5)
    }
}));

export const PaySummaryPayments: React.FC<PaySummaryPayments> = ({ payments, users, balance }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { user } = useAuth();
    const getCurrencyString = useCurrencyString();
    const userIdToName = React.useMemo(() => {
        const newUsersObject: Record<string, string> = {};
        if (Array.isArray(users)) {
            users.forEach(item => {
                const newString = `${item.firstName} ${item.lastName}`;
                newUsersObject[item.userId] =
                    newString === 'Guest Guest' ? 'Guest' : `${item.firstName} ${item.lastName}`;
            });
        }
        if (isDefined(user) && isDefined(user?._id)) {
            newUsersObject[user._id] = 'You';
        }
        return newUsersObject;
    }, [user, users]);
    const paymentsToDisplay = React.useMemo(() => {
        if (!Array.isArray(payments)) {
            return [];
        }
        return payments.filter(item => item.userId !== user?._id);
    }, [user?._id, payments]);
    const renderPayment = React.useCallback(
        (payment: IOrderPaymentReadResource) => {
            if (!isDefined(payment.amount) || !isDefined(payment.userId)) {
                return null;
            }
            return (
                <Box className={classes.row} key={payment.id}>
                    <Typography className={classes.typography}>
                        {userIdToName[payment.userId] ?? 'Unknown'}
                    </Typography>
                    <Typography className={classes.typography}>
                        {getCurrencyString(payment.amount)}
                    </Typography>
                </Box>
            );
        },
        [classes.row, classes.typography, getCurrencyString, userIdToName]
    );
    if (!Array.isArray(payments) || !Array.isArray(users)) {
        return null;
    }
    return (
        <Box flex={1} px={2}>
            {paymentsToDisplay.map(renderPayment)}
            {isNumber(balance) && (
                <Box className={classes.row} flex={1} justifyContent="space-between" alignItems="center">
                    <Typography className={classes.balance}>{t('BILL_SUMMARY_REMAINING_BALANCE')}</Typography>
                    <Typography className={classes.balance}>{getCurrencyString(balance)}</Typography>
                </Box>
            )}
            <Divider />
        </Box>
    );
};
