/* eslint-disable max-len */
import * as React from 'react';
import { SVGIconProps } from './models';

interface IProps extends SVGIconProps {
    color?: string;
}

export const PayBillIcon: React.FC<IProps> = ({ variant = 'overlay', ...props }) =>
    variant === 'overlay' ? (
        <svg width="1em" height="1em" viewBox="0 0 68 80" xmlns="http://www.w3.org/2000/svg" {...props}>
            <title>{'PAyBill'}</title>
            <g stroke="inherit" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g strokeWidth={2.167}>
                    <path d="M44.075 53.245H23.908a2.819 2.819 0 01-2.813-2.823v-45.6A2.819 2.819 0 0123.908 2h26.597a2.819 2.819 0 012.813 2.823v14.075" />
                    <path d="M46.048 45.62H21.095v4.766a2.819 2.819 0 002.813 2.822M33.59 6.655h7.197M33.59 49.433h7.197" />
                </g>
                <path
                    d="M40.787 67.546l-1.126-3.86-.413-.11a12.85 12.85 0 01-9.084-7.794l-1.013-2.537M44.069 58.839v-5.314c0-5.02 2.265-9.785 6.139-12.973l1.023-.843a5.464 5.464 0 001.864-5.46l-1.937-8.393c-.804-3.591 1.096-7.219 4.531-8.538h0c1.096-.403 2.302.22 2.558 1.356L63.18 41.54c.548 2.602.293 5.35-.73 7.806l-3.18 7.513 1.243 4.727M38.86 68.117l24.23-7.125L66 70.849l-24.23 7.125z"
                    strokeWidth={2.167}
                />
                <g strokeWidth={1.548}>
                    <path d="M26.764 30.195v-8.16h2.903c1.14 0 2.059.906 2.059 2.03h0c0 1.125-.92 2.032-2.059 2.032h-2.903M34.225 30.195l.956-6.891c.257-1.778 2.866-1.778 3.124 0l.919 6.891M34.556 27.693h4.3M41.172 21.962l2.94 4.86 2.94-4.86M44.112 26.822v3.482" />
                </g>
                <g strokeWidth={2.167}>
                    <path d="M16.62 29.72c-1.819-.367-3.164-1.98-3.164-3.922 0-1.942 1.345-3.554 3.164-3.92" />
                    <path d="M14.183 35.033c-3.782-1.356-6.437-4.984-6.437-9.235a9.77 9.77 0 016.437-9.198" />
                    <path d="M10.146 39.54A15.651 15.651 0 012 25.798c0-5.9 3.273-11.03 8.074-13.668" />
                </g>
            </g>
        </svg>
    ) : (
        <svg width="1em" height="1em" viewBox="0 0 234 175" xmlns="http://www.w3.org/2000/svg" {...props}>
            <title>{'PayBillIcon'}</title>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M225.75 31.672H41.23v-12.33c0-6.116 4.974-11.093 11.09-11.093h162.34c6.115 0 11.09 4.977 11.09 11.094v12.33zm0 74.512c0 6.116-4.975 11.091-11.09 11.091h-17.637v-48.46c0-8.458-6.883-15.34-15.341-15.34H41.23v-17.55h184.52v70.26zm-32.978 11.091v38.384c0 6.115-4.976 11.09-11.09 11.09H19.342c-6.117 0-11.09-4.975-11.09-11.09V68.816c0-6.116 4.973-11.091 11.09-11.091h162.34c6.114 0 11.09 4.975 11.09 11.09v48.46zM214.66 4H52.32c-8.46 0-15.342 6.883-15.342 15.343v34.132H19.341C10.883 53.475 4 60.357 4 68.815v86.844C4 164.12 10.883 171 19.341 171h162.34c8.46 0 15.342-6.881 15.342-15.34v-34.135h17.638c8.458 0 15.339-6.882 15.339-15.34V19.342C230 10.883 223.12 4 214.66 4h0z"
                    strokeWidth={1.1}
                />
                <path
                    d="M225.75 31.672H41.23v-12.33c0-6.116 4.974-11.093 11.09-11.093h162.34c6.115 0 11.09 4.977 11.09 11.094v12.33zm0 74.512c0 6.116-4.975 11.091-11.09 11.091h-17.637v-48.46c0-8.458-6.883-15.34-15.341-15.34H41.23v-17.55h184.52v70.26zm-32.978 11.091v38.384c0 6.115-4.976 11.09-11.09 11.09H19.342c-6.117 0-11.09-4.975-11.09-11.09V68.816c0-6.116 4.973-11.091 11.09-11.091h162.34c6.114 0 11.09 4.975 11.09 11.09v48.46zM214.66 4H52.32c-8.46 0-15.342 6.883-15.342 15.343v34.132H19.341C10.883 53.475 4 60.357 4 68.815v86.844C4 164.12 10.883 171 19.341 171h162.34c8.46 0 15.342-6.881 15.342-15.34v-34.135h17.638c8.458 0 15.339-6.882 15.339-15.34V19.342C230 10.883 223.12 4 214.66 4h0z"
                    stroke={'inherit'}
                    strokeWidth={7}
                    fill={'inherit'}
                />
                <path
                    d="M43.251 118.527H29.997a.767.767 0 01-.765-.765V94.985c0-.42.344-.765.765-.765h22.775c.423 0 .767.344.767.765v22.777a.767.767 0 01-.767.765h-9.52zm12.8 2.998a4.956 4.956 0 001.44-2.125c.18-.516.297-1.06.297-1.638V94.985a5.022 5.022 0 00-5.016-5.016H29.997a5.023 5.023 0 00-5.016 5.016v22.777a5.023 5.023 0 005.016 5.017h22.775c1.26 0 2.398-.483 3.28-1.254h0z"
                    stroke={'inherit'}
                    strokeWidth={4}
                    fill={'inherit'}
                />
                <path
                    d="M56.402 140.482H35.628a2.125 2.125 0 100 4.25h20.774c1.175 0 2.124-.95 2.124-2.125s-.95-2.125-2.124-2.125M92.667 140.482H71.895a2.124 2.124 0 100 4.25h20.772a2.125 2.125 0 100-4.25M130.953 140.482h-20.776a2.125 2.125 0 100 4.25h20.776a2.124 2.124 0 100-4.25M167.216 140.482h-20.772c-1.175 0-2.125.95-2.125 2.125s.95 2.125 2.125 2.125h20.772a2.125 2.125 0 100-4.25"
                    stroke={'inherit'}
                    strokeWidth={7}
                    fill={'inherit'}
                />
            </g>
        </svg>
    );
