export enum LocationActionTypes {
    START_REQUEST = 'LOCATIONS_START_REQUEST',
    END_REQUEST = 'LOCATIONS_END_REQUEST',
    GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS',
    GET_LOCATIONS_ERROR = 'GET_LOCATIONS_ERROR',
    START_NEXT_PAGE_LOADING = 'START_NEXT_PAGE_LOADING',
    LOAD_LOCATIONS_SUCCESS = 'LOAD_LOCATIONS_SUCCESS',
    GET_COORDINATES_SUCCESS = 'LOCATIONS_GET_COORDINATES_SUCCESS',
    GET_CURRENT_LOCATION_SUCCESS = 'GET_CURRENT_LOCATION_SUCCESS',
    GET_CURRENT_LOCATION_ERROR = 'GET_CURRENT_LOCATION_ERROR',
    RESET_CURRENT_LOCATION = 'RESET_CURRENT_LOCATION'
}
