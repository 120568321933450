import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { IUserReadResourceV12 } from 'components/order/model/Order';
import { addOpacity } from './helpers';

interface IProps {
    user?: IUserReadResourceV12 | undefined;
    classnames?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        userAvatar: {
            width: theme.spacing(6),
            height: theme.spacing(6),
            borderRadius: '50%',
            marginRight: theme.spacing(0.5)
        },
        userInitials: {
            fontSize: theme.spacing(2),
            color: theme.palette.common.black,
            border: `1px solid ${addOpacity(theme.palette.common.black, 0.6)}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            flexShrink: 0
        }
    })
);

export const UserAvatar: React.FC<IProps> = ({ user, classnames }) => {
    const classes = useStyles();
    const [isAvatarAvailable, setIsAvatarAvailable] = React.useState<boolean>(true);
    const handleError = React.useCallback(() => {
        setIsAvatarAvailable(false);
    }, []);
    const userInitials = React.useMemo(() => {
        const firstLetter = user?.firstName ? user?.firstName[0] : '';
        const secondLetter = user?.lastName ? user?.lastName[0] : '';
        return user ? firstLetter + secondLetter : '';
    }, [user]);

    return user && isAvatarAvailable ? (
        <img
            className={clsx(classes.userAvatar, classnames)}
            src={`${process.env.MEDIA_URL}/users/avatars/${user.userId}.jpg`}
            onError={handleError}
        />
    ) : (
        <span className={clsx(classes.userAvatar, classes.userInitials, classnames)}>{userInitials}</span>
    );
};
