import React from 'react';
import { useSelector } from 'react-redux';
import { isApplePayReady } from 'src/integrations/PaymentProvider/ApplePayButton';
import { isGooglePayAvailable } from 'src/integrations/GooglePay/isGooglePayReady';
import { browser } from 'src/utils/browser';
import { IPaymentOptionValue } from 'components/bill/ui/ViewBill/Checkout/PaymentMethod/PaymentMethodDialog';
import { OrderPaymentType } from 'components/order/model/Order';
import { UserCard } from 'components/user/model/User';
import { useAuth } from 'lib/useAuth';
import { SettingsStateSettings } from 'store/settings/settingsReducer';
import { ApplicationState } from 'store/store';

const getPaymentOptionInitialState = async (settings: SettingsStateSettings | undefined) => {
    const isSquare = settings?.paymentProvider === 'SQUARE';
    const isJudo = settings?.paymentProvider === 'JUDOPAY';
    const isStripe = settings?.paymentProvider === 'STRIPE';

    const isGpAvailable = await isGooglePayAvailable(settings);

    if (settings?.googlePayEnabled && browser().isChrome && isGpAvailable) {
        return { type: OrderPaymentType.GOOGLEPAY };
    }

    // There is no chance to have a Safari on the non-apple device, but just to doublecheck
    if ((isSquare || isJudo || isStripe) && settings?.applePayEnabled && isApplePayReady()) {
        return { type: OrderPaymentType.APPLEPAY };
    }
    return { type: OrderPaymentType.CARD_ON_FILE };
};

interface UsePaymentMethod {
    onPaymentMethodChange: (value: IPaymentOptionValue) => void;
    selectedPaymentMethod: IPaymentOptionValue;
    onAddNewCardClick: () => void;
    cards: UserCard[];
    addNewCardOpen: boolean;
    handleCardAdded: (paymentType: OrderPaymentType, selectedCard: UserCard | null) => () => void;
    handleAddNewCardClose: () => void;
}

export function usePaymentMethods(): UsePaymentMethod {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { card, isLoading } = useAuth();
    const userCards = React.useMemo(() => (card ? [card] : []), [card]);
    const [selectedPaymentOption, setSelectedPaymentOption] = React.useState<IPaymentOptionValue>(() => ({
        type: OrderPaymentType.CARD_ON_FILE
    }));
    const handleOnSelectedPaymentMethodChange = React.useCallback((newMethod: IPaymentOptionValue) => {
        setSelectedPaymentOption(newMethod);
    }, []);
    const [addNewCardOpen, setAddNewCardOpen] = React.useState(false);
    React.useEffect(() => {
        const updateSelectedPaymentMethod = async () => {
            if (card) {
                setSelectedPaymentOption({ type: OrderPaymentType.CARD_ON_FILE, card });
            } else {
                const defaultPaymentMethod = await getPaymentOptionInitialState(settings);
                setSelectedPaymentOption(defaultPaymentMethod);
            }
        };
        if (!isLoading) {
            updateSelectedPaymentMethod();
        }
    }, [settings, card, isLoading]);
    const handleAddNewCard = React.useCallback(() => setAddNewCardOpen(true), []);
    const handleCardAdded = React.useCallback(
        (paymentType: OrderPaymentType, selectedCard: UserCard | null) => () => {
            if (paymentType === OrderPaymentType.CARD_ON_FILE && selectedCard) {
                setSelectedPaymentOption({ card: selectedCard, type: paymentType });
            }
        },
        []
    );
    const handleAddNewCardClose = React.useCallback(() => {
        setAddNewCardOpen(false);
    }, []);
    return {
        cards: userCards,
        selectedPaymentMethod: selectedPaymentOption,
        onPaymentMethodChange: handleOnSelectedPaymentMethodChange,
        onAddNewCardClick: handleAddNewCard,
        handleAddNewCardClose,
        handleCardAdded,
        addNewCardOpen: !!addNewCardOpen
    };
}
