import React from 'react';
import { makeStyles } from '@material-ui/core';
import { BottomDialog, IBottomDialogProps } from 'lib/BottomDialog';

const useStyles = makeStyles({
    '@global': {
        '.global-blur': {
            filter: 'blur(1px)',
            opacity: 0.75
        }
    },
    paper: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
    }
});

export const PayBaseModal: React.FC<IBottomDialogProps> = props => {
    const classes = useStyles({ open: props.open });
    React.useEffect(() => {
        const removeBlur = () => {
            const doc = document.getElementById('root');
            if (doc) {
                doc.classList.remove('global-blur');
            }
        };
        if (props.open) {
            const doc = document.getElementById('root');
            if (doc) {
                doc.classList.add('global-blur');
            }
        } else {
            removeBlur();
        }
        return () => removeBlur();
    }, [props.open]);
    return <BottomDialog className="pay-dialog" paperClassName={classes.paper} {...props} />;
};
