import { OrderScenario } from 'components/order/model/Order';
import { CredentialProvider, isPhoneNumberValid } from 'components/user/model/User';
import { useAuth } from 'lib/useAuth';
import parsePhoneNumberFromString from 'libphonenumber-js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { ApplicationState } from 'store/store';

export function usePhoneNumberState() {
    const { t } = useTranslation();
    const [error, setError] = React.useState<string | undefined>();
    const [value, setValue] = React.useState<string | undefined>();
    const [touched, setTouched] = React.useState<boolean>(false);
    const { user } = useAuth();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const [scenarioFromRoute] = useRouteScenario();
    const scenario = React.useMemo(() => scenarioFromRoute ?? OrderScenario.PREORDER, [scenarioFromRoute]);
    const phoneCaptureEnabled = React.useMemo(
        () => !!settings?.capturePhoneNumberOnOrder && settings?.capturePhoneNumberOnOrder[scenario],
        [scenario, settings?.capturePhoneNumberOnOrder]
    );
    const countryCode = React.useMemo(
        () => settings?.region.phoneNumberCode || 'GB',
        [settings?.region?.phoneNumberCode]
    );
    const onChange = React.useCallback(
        (phoneValue?: string) => {
            if (!touched) {
                setTouched(true);
            }
            setValue(phoneValue);
        },
        [touched]
    );

    React.useEffect(() => {
        if (!touched && !value) {
            const { phoneNumberLastUsedToOrder, credentials } = user ?? {};

            const phoneNumberToSet =
                phoneNumberLastUsedToOrder ||
                credentials?.find(c => c.provider === CredentialProvider.MOBILE)?.id;
            if (phoneNumberToSet) {
                onChange(phoneNumberToSet);
            }
        }
    }, [user, touched, value, onChange]);
    const validatePhoneNumber = React.useCallback(() => {
        if (phoneCaptureEnabled) {
            if (!value) {
                setError(t('BILL_CHECKOUT_PHONE_NUMBER_EMPTY_ERROR'));
                return false;
            }
            const isValid = isPhoneNumberValid(value, settings?.region.phoneNumberCode);
            if (!isValid) {
                setError(t('BILL_CHECKOUT_PHONE_NUMBER_INVALID_ERROR'));
                return false;
            }
        }
        return true;
    }, [phoneCaptureEnabled, value, settings?.region.phoneNumberCode, t]);
    const handlePhoneNumberChange = React.useCallback(
        (value: string) => {
            const phone = parsePhoneNumberFromString(value, countryCode);
            onChange(value);
            if (value) {
                if (phone?.isValid()) {
                    setError('');
                } else {
                    setError(t('BILL_CHECKOUT_PHONE_NUMBER_INVALID_ERROR'));
                }
            } else {
                setError(t('BILL_CHECKOUT_PHONE_NUMBER_EMPTY_ERROR'));
            }
        },
        [countryCode, onChange, t]
    );
    return {
        error,
        value,
        onChange: handlePhoneNumberChange,
        validatePhoneNumber,
        phoneCaptureEnabled
    };
}
