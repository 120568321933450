import React from 'react';
import { Box, IconButton, makeStyles, Theme } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { useResponsive } from 'src/hooks/useResponsive';

interface IMenuSearchButton {
    handleOpenMenuSearch: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        color: theme.palette.primary.contrastText,

        '& svg': {
            fontSize: theme.spacing(5)
        }
    }
}));

export const MenuSearchButton: React.FC<IMenuSearchButton> = ({ handleOpenMenuSearch }) => {
    const classes = useStyles();

    const { isDesktop } = useResponsive();

    if (isDesktop) {
        return null;
    }

    return (
        <Box>
            <IconButton className={classes.icon} onClick={handleOpenMenuSearch}>
                <Search />
            </IconButton>
        </Box>
    );
};
