import React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { orderApi } from 'components/order/orderApi';
import { EPaymentProvider } from 'components/settings/model/Settings';
import logger from 'lib/logger';
import { isDefined } from 'lib/typeInference';
import { ApplicationState } from 'store/store';

export function isApplePayReady() {
    try {
        return !!(window as any).ApplePaySession && ApplePaySession.canMakePayments();
    } catch (error) {
        return false;
    }
}

export interface ApplePayPaymentDetails {
    token: ApplePayJS.ApplePayPaymentToken | string;
}

export interface PaymentSessionResponse {
    epochTimestamp: number;
    expiresAt: number;
    merchantSessionIdentifier: string;
    nonce: string;
    merchantIdentifier: string;
    domainName: string;
    displayName: string;
    signature: string;
    retries: number;
    operationalAnalyticsIdentifier: string;
}

type ApplePayMerchantCapability =
    /**
     * Required. This value must be supplied.
     */
    | 'supports3DS'

    /**
     * Include this value only if you support China Union Pay transactions.
     */
    | 'supportsEMV'

    /**
     * Optional. If present, only transactions that are categorized as credit cards are allowed.
     */
    | 'supportsCredit'

    /**
     * Optional. If present, only transactions that are categorized as debit cards are allowed.
     */
    | 'supportsDebit';

interface ApplePayButtonProps {
    price: number;
    onLoad: (data: ApplePayPaymentDetails) => void;
}

export const ApplePayButton: React.FC<ApplePayButtonProps> = ({ onLoad, price }) => {
    const { currentLocation } = useSelector((state: ApplicationState) => state.locations);
    const [ready, setReady] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const fetchPaymentSession = React.useCallback(
        async (validationUrl: string): Promise<PaymentSessionResponse> => {
            if (!currentLocation) {
                throw new Error('Missed the location');
            }
            const result = await orderApi.createPaymentSession(
                validationUrl,
                currentLocation._id,
                currentLocation.title
            );
            return result;
        },
        [currentLocation]
    );
    const parsedAllowedCardNetworks = React.useMemo(() => {
        const result: string[] = [];
        if (settings?.applePay.supportedNetworksMastercard) {
            result.push('masterCard');
        }
        if (settings?.applePay.supportedNetworksVisa) {
            result.push('visa');
        }
        return result;
    }, [settings?.applePay?.supportedNetworksMastercard, settings?.applePay?.supportedNetworksVisa]);
    const paymentDetails = React.useMemo(() => {
        if (!settings) {
            return null;
        }
        return {
            total: {
                label: settings.title,
                amount: { value: String(price), currency: settings.region.currencyCode }
            }
        };
    }, [settings, price]);
    React.useEffect(() => {
        try {
            if (isApplePayReady()) {
                setReady(true);
            }
        } catch {
            setReady(false);
        } finally {
            setLoading(false);
        }
    }, []);

    const handleClick = React.useCallback(() => {
        if (!paymentDetails || !isDefined(settings)) {
            return;
        }

        try {
            const applePayRequest = {
                currencyCode: settings.region.currencyCode,
                countryCode: settings.applePay.countryCode,
                supportedNetworks: parsedAllowedCardNetworks,
                merchantCapabilities: ['supports3DS'] as ApplePayMerchantCapability[],
                total: { label: settings.title, amount: String(price) }
            };

            if (settings.paymentProvider !== EPaymentProvider.WORLDPAY) {
                applePayRequest.merchantCapabilities.push('supportsCredit', 'supportsDebit');
            }
            const session = new ApplePaySession(4, applePayRequest);
            session.onpaymentmethodselected = function () {
                session.completePaymentMethodSelection({
                    newTotal: { label: settings.title, amount: String(price) }
                });
            };
            session.onpaymentauthorized = function (event) {
                session.completePayment(ApplePaySession.STATUS_SUCCESS);
                let token: string | ApplePayJS.ApplePayPaymentToken = '';

                switch (settings.paymentProvider) {
                    case EPaymentProvider.JUDOPAY:
                        // eslint-disable-next-line prefer-destructuring
                        token = event.payment.token;
                        break;
                    case EPaymentProvider.WORLDPAY:
                        token = JSON.stringify(event.payment.token.paymentData);
                        break;
                    default:
                        throw new Error('Payment provider is not supported');
                }

                onLoad({ token });
            };
            session.onshippingcontactselected = function () {
                session.completeShippingContactSelection({
                    newTotal: { label: settings.title, amount: String(price) }
                });
            };
            session.onshippingmethodselected = function () {
                session.completeShippingMethodSelection({
                    newTotal: { label: settings.title, amount: String(price) }
                });
            };
            session.oncancel = () => {};

            session.onvalidatemerchant = function (event: ApplePayJS.ApplePayValidateMerchantEvent) {
                const sessionPromise = fetchPaymentSession(event.validationURL);
                return sessionPromise
                    .then(merchantSession => {
                        session.completeMerchantValidation(merchantSession);
                    })
                    .catch(applePayError => {
                        logger.error(applePayError);
                        session.abort();
                    });
            };
            session.begin();
        } catch (e) {
            logger.error(e);
        }
    }, [fetchPaymentSession, settings, onLoad, parsedAllowedCardNetworks, paymentDetails, price]);
    if (loading) {
        return <CircularProgress />;
    }
    if (!ready) {
        return null;
    }
    return <button className="apple-pay-button" onClick={handleClick} />;
};
