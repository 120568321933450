import * as React from 'react';
import { Theme, Zoom } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import random from 'lodash/random';

interface StampCircleProps {
    stampColor: string;
    stampImage?: string;
    reduced?: boolean;
    randomizeStampCard?: boolean;
    small?: boolean;
    filled?: boolean;
    animationDelay?: number;
}

const useStyles = makeStyles((theme: Theme) => {
    const getStampSize = (small?: boolean, reduced?: boolean) =>
        small ? theme.spacing(reduced ? 2.5 : 3) : theme.spacing(reduced ? 4.5 : 5);
    const getImageSize = (small?: boolean, reduced?: boolean) =>
        small ? theme.spacing(reduced ? 2 : 2.5) : theme.spacing(reduced ? 3.5 : 4);
    const getMarginSize = (small?: boolean) => random(small ? 2 : 6);
    const getImageMargin = (small?: boolean) =>
        `${getMarginSize(small)}px ${getMarginSize(small)}px ${getMarginSize(small)}px ${getMarginSize(
            small
        )}px`;
    return createStyles({
        root: ({ small, stampColor, reduced }: StampCircleProps) => ({
            width: getStampSize(small, reduced),
            height: getStampSize(small, reduced),
            border: `1px solid ${stampColor}`,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
            transition: 'all .1s'
        }),
        img: ({ small, randomizeStampCard, reduced }: StampCircleProps) => ({
            width: getImageSize(small, reduced),
            height: getImageSize(small, reduced),
            margin: randomizeStampCard ? getImageMargin(small) : 0,
            borderRadius: '50%',
            transform: `rotate(${randomizeStampCard ? random(361) : 0}deg)`,
            transition: 'all .2s'
        }),
        div: ({ small, stampColor, reduced }: StampCircleProps) => ({
            width: getImageSize(small, reduced),
            height: getImageSize(small, reduced),
            backgroundColor: stampColor,
            borderRadius: '50%',
            transition: 'all .1s'
        })
    });
});

const StampCircleComponent: React.FC<StampCircleProps> = props => {
    const { stampImage, filled, animationDelay } = props;
    const classes = useStyles(props);
    const [isVisible, setIsVisible] = React.useState(false);

    React.useEffect(() => {
        setTimeout(() => {
            setIsVisible(true);
        }, animationDelay);
    }, [animationDelay]);

    return (
        <div className={classes.root}>
            {filled && (
                <Zoom key={new Date().getTime()} timeout={200} in={isVisible}>
                    {stampImage ? (
                        <img src={stampImage} className={classes.img} alt="" />
                    ) : (
                        <div className={classes.div} />
                    )}
                </Zoom>
            )}
        </div>
    );
};

export const StampCircle = React.memo(
    StampCircleComponent,
    (prevProps, nextProps) =>
        prevProps.filled === nextProps.filled &&
        nextProps.stampImage === prevProps.stampImage &&
        prevProps.stampColor === nextProps.stampColor &&
        prevProps.randomizeStampCard === nextProps.randomizeStampCard &&
        prevProps.reduced === nextProps.reduced
);
