import React from 'react';
import { IPaymentProvider, ThreeDSecureAuthenticationData } from '../PaymentProvider';
import { JudopayAddCard } from './JudopayAddCard';

export class JudopayProvider implements IPaymentProvider {
    createAddCardComponent(postCardAddition: () => void) {
        return React.createElement(JudopayAddCard, { postCardAddition });
    }

    public threeDSecureAuthenticate(_data: ThreeDSecureAuthenticationData) {
        return Promise.resolve(null);
    }
    public collectDeviceData() {
        return Promise.resolve(null);
    }
}

class JudoHelpers {
    // These don't have actual types, blame judopay, sorry
    public createToken(
        judo: any,
        cardDetails: any,
        callback: (result: IJudopayTokenResult | null) => Promise<void>
    ) {
        // This function prevents Judopay internal bug where sometimes
        // the promise will never resolve. We use this self-calling function
        // to detect if the promise is taking a long time to resolve, and to
        // re-invoke the createToken function, ignoring the promise if it eventually
        // does resolve. Judopay should fix this soon -01/07/2020
        let resolved = false;
        let destroyed = false;
        judo.createToken(cardDetails)
            .then((result: IJudopayTokenResult) => {
                resolved = true;
                if (!destroyed) {
                    callback(result);
                }
            })
            .catch(() => {
                resolved = true;
                if (!destroyed) {
                    callback(null);
                }
            });
        setTimeout(() => {
            if (!resolved) {
                destroyed = true;
                this.createToken(judo, cardDetails, callback);
            }
        }, 1500);
    }
}

export const judoHelpers = new JudoHelpers();

export interface IJudopayTokenResult {
    oneUseToken: string;
    cardFunding: string;
    cardLastfour: string;
    cardScheme: string;
    endDate: string;
}
