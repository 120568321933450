import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import { IAdjustmentReadResourceV10 } from 'components/basket/model/Basket';
import { ViewBillContentSection } from '../ViewBillContentSection';
import { ViewBillCheckoutRewardItem } from './ViewBillCheckoutRewardItem';

interface IProps {
    allAdjustments: IAdjustmentReadResourceV10[];
    availableRewards: IAdjustmentReadResourceV10[];
    onRewardSelected: (reward: IProps['selectedReward']) => void;
    selectedReward?: IAdjustmentReadResourceV10 | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        action: {
            fontSize: theme.spacing(2),
            textDecoration: 'none'
        },
        link: {
            color: theme.palette.text.hint
        }
    })
);

export const ViewBillCheckoutRewardList: React.FC<IProps> = ({
    availableRewards,
    onRewardSelected,
    selectedReward,
    allAdjustments
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const selectedAwardFromList = React.useMemo(
        () =>
            allAdjustments.find(
                reward =>
                    (reward.awardId !== undefined && reward.awardId === selectedReward?.awardId) ||
                    reward.id === selectedReward?.id
            ),
        [allAdjustments, selectedReward?.awardId, selectedReward?.id]
    );
    const renderReward = React.useCallback(
        (reward: IAdjustmentReadResourceV10) => (
            <ViewBillCheckoutRewardItem
                reward={reward}
                onRewardSelected={onRewardSelected}
                key={reward.id || reward.awardId}
            />
        ),
        [onRewardSelected]
    );

    const handleReset = React.useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            onRewardSelected(undefined);
        },
        [onRewardSelected]
    );
    return availableRewards.length > 0 || !!selectedAwardFromList ? (
        <ViewBillContentSection title={t('BILL_CHECKOUT_AVAILABLE_REWARDS')} bottomSpacing={2}>
            {!selectedReward
                ? availableRewards.map(renderReward)
                : selectedAwardFromList && (
                      <ViewBillCheckoutRewardItem reward={selectedAwardFromList} selected />
                  )}
            {selectedReward && (
                <Typography align="center" className={classes.action}>
                    <Link className={classes.link} href="" onClick={handleReset} color="inherit">
                        {t('BILL_CHECKOUT_SELECTED_REWARD_CLEAR_BTN')}
                    </Link>
                </Typography>
            )}
        </ViewBillContentSection>
    ) : null;
};
