import { useCallback, useState } from 'react';

export function useInputState(
    initialState = ''
): [string, (e: React.ChangeEvent<{ value: string }>) => void] {
    const [state, setState] = useState(initialState);
    const onChange = useCallback((e: React.ChangeEvent<{ value: string }>) => setState(e.target.value), []);

    return [state, onChange];
}
