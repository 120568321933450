import React from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import { SplitContolsButton } from './SplitControlsButton';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    counter: {
        fontSize: '28px',
        padding: theme.spacing(0, 3)
    },
    icon: {
        fontSize: '1rem'
    }
}));

interface SplitControlsProps {
    value: number;
    increase: () => void;
    decrease: () => void;
    max?: number;
    min?: number;
    disable?: boolean;
}

export const SplitControls: React.FC<SplitControlsProps> = ({
    value,
    increase,
    decrease,
    max,
    min,
    disable
}) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <SplitContolsButton onClick={decrease} disabled={disable || value === min}>
                <Remove className={classes.icon} />
            </SplitContolsButton>
            <Typography className={classes.counter}>{value}</Typography>
            <SplitContolsButton onClick={increase} disabled={disable || value === max}>
                <Add className={classes.icon} />
            </SplitContolsButton>
        </Box>
    );
};
