import React from 'react';
import { Typography } from '@material-ui/core';

const TRANSITION_STEPS = 100;
const STEP_DURATION = 60;

function defaultFormatValue(value: number) {
    return value.toFixed();
}

export interface SmoothNumbersProps {
    value: number;
    stepDuration?: number;
    formatValue?: (value: number) => string;
    transitionSteps?: number;
}

export const SmoothNumbers: React.FC<SmoothNumbersProps> = ({
    value,
    formatValue = defaultFormatValue,
    transitionSteps = TRANSITION_STEPS,
    stepDuration = STEP_DURATION
}) => {
    const [animatedProgress, setAnimatedProgress] = React.useState(value);
    const [stepsLeft, setStepsLeft] = React.useState([value, 0]);
    React.useEffect(() => {
        setStepsLeft([value, transitionSteps - 1]);
    }, [value, transitionSteps]);
    React.useEffect(() => {
        const [currentValue, steps] = stepsLeft;
        if (steps && animatedProgress !== currentValue) {
            const timeout = setTimeout(() => {
                setAnimatedProgress(animatedProgress + (currentValue - animatedProgress) / steps);
                setStepsLeft([currentValue, steps - 1]);
            }, stepDuration);
            return () => clearTimeout(timeout);
        }
    }, [stepsLeft, stepDuration, animatedProgress]);
    return <Typography>{formatValue(animatedProgress)}</Typography>;
};
