import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { getDay } from 'components/timeslots/helpers';
import { Timeslot } from 'components/timeslots/model/Timeslot';
import { TimeslotPickerDialog } from 'components/timeslots/picker/TimeslotPickerDialog';
import { TimeslotPickerDialogSkeleton } from 'components/timeslots/picker/TimeslotPickerDialogSkeleton';
import { timeslotsApi } from 'components/timeslots/timeslotsApi';
import logger from 'lib/logger';
import { useLocalHistory } from 'lib/useLocalHistory';
import { ROUTES } from 'pages/routes';
import { resetBasket, setBasketTimeslot } from 'store/basket/basketActions';
import { SettingsActionTypes } from 'store/settings/settingsActionTypes';
import { ApplicationState } from 'store/store';
import { useQuery } from 'src/hooks/useQuery';
import { useSnackbar } from 'notistack';

export const BasketTimeslotPicker: React.FC = () => {
    const { t } = useTranslation();
    const { push, replace } = useLocalHistory();
    const errorParams = useQuery('error');
    const { isLocationOpen } = useLocationHelpers();
    const { locationId } = useParams<{ locationId: string }>();

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { settings } = useSelector((state: ApplicationState) => state.settings);

    const [availableTimeslots, setAvailableTimeslots] = React.useState<Timeslot[] | undefined>(undefined);
    const [noTimeslots, setNoTimeslots] = React.useState(false);
    const [timeslotsLoading, setTimeslotsLoading] = React.useState(false);
    const [openTimeslotPicker, setTimeslotPicker] = React.useState(false);

    const noAsap = React.useMemo(
        () => (!settings?.asapOrderingEnabled || !isLocationOpen) && settings?.timeSlotsEnabled,
        [isLocationOpen, settings?.asapOrderingEnabled, settings?.timeSlotsEnabled]
    );

    React.useEffect(() => {
        async function loadTimeslots() {
            setTimeslotsLoading(true);
            setTimeslotPicker(true);
            try {
                const result = await timeslotsApi.getTimeslots(locationId);
                const filteredTimeslots = result.items.filter(item => item.available);
                dispatch({
                    type: SettingsActionTypes.GET_TIMESLOTS_SUCCESS,
                    filteredTimeslots
                });
                setAvailableTimeslots(filteredTimeslots);

                if (!noAsap && filteredTimeslots.length === 0) {
                    setNoTimeslots(true);
                }
                if (noAsap && filteredTimeslots.length === 0) {
                    enqueueSnackbar(t('DIALOG_ORDERING_DISABLED_MESSAGE'), { variant: 'warning' });
                    resetBasket(dispatch);
                    push(ROUTES.MENU, {}, '');
                }
            } catch (e) {
                logger.error(e);
                dispatch({
                    type: SettingsActionTypes.GET_TIMESLOTS_ERROR,
                    error: e.data.message
                });
            } finally {
                setTimeslotsLoading(false);
            }
        }

        if (errorParams === 'timeslot') {
            loadTimeslots();
        }
    }, [dispatch, enqueueSnackbar, errorParams, locationId, noAsap, push, t]);

    const formattedTimeslots = React.useMemo(
        () =>
            availableTimeslots
                ? availableTimeslots.reduce<Record<string, Timeslot[]>>((acc, item) => {
                      const currentDay = getDay(item.start);
                      if (!Array.isArray(acc[currentDay])) {
                          acc[currentDay] = [item];
                      } else {
                          acc[currentDay].push(item);
                      }
                      return acc;
                  }, {})
                : {},
        [availableTimeslots]
    );

    const handleSubmitTimeslotPicker = React.useCallback(
        (date?: { day: string; time: string }) => {
            setBasketTimeslot(date ? formattedTimeslots[date.day][Number(date.time)] : undefined)(dispatch);
            setTimeslotPicker(false);
            replace('', {}, '');
        },
        [dispatch, formattedTimeslots, replace]
    );
    const handleCancelOrder = React.useCallback(() => {
        resetBasket(dispatch);
        push(ROUTES.MENU, {}, '');
    }, [dispatch, push]);

    const handleCloseTimeslotPicker = React.useCallback(() => {}, []);
    if (timeslotsLoading) {
        return <TimeslotPickerDialogSkeleton />;
    }

    return (
        <TimeslotPickerDialog
            timeslot={availableTimeslots ? availableTimeslots[0] : undefined}
            noTimeslots={noTimeslots}
            noAsap={noAsap}
            branded={false}
            open={openTimeslotPicker}
            onClose={handleCloseTimeslotPicker}
            onSubmit={handleSubmitTimeslotPicker}
            onCancelOrder={handleCancelOrder}
            options={formattedTimeslots}
            description={t('DIALOG_PREORDER_TIMESLOT_NOT_AVAILABLE')}
            confirmButton={t('DIALOG_PREORDER_TIME_SLOT_NOT_AVAILABLE_CONFIRM_BTN')}
            canselButton={t('DIALOG_PREORDER_TIME_SLOT_NOT_AVAILABLE_CANCEL_BTN')}
        />
    );
};
