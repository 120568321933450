import React from 'react';
import { ETipScheme } from 'components/settings/enums';
import { isDefined } from 'lib/typeInference';
import { useCurrencyString } from 'lib/useCurrencyString';
import { PayTipsItemBox } from './PayTipsItemBox';
import { getTipValueOfOption } from 'components/order/helpers';

interface PayTipsItemProps {
    schema: ETipScheme;
    value: number;
    selectedValue?: number;
    onClick: (tipValue: number, selectorValue?: number) => void;
    subTotal?: number;
    loading?: boolean;
}

export const PayTipsItem: React.FC<PayTipsItemProps> = ({
    onClick,
    schema,
    value,
    selectedValue,
    subTotal,
    loading
}) => {
    const getCurrencyString = useCurrencyString();
    const tipValue = React.useMemo(() => {
        if (isDefined(subTotal)) {
            return getTipValueOfOption(schema, subTotal, value);
        }
        return undefined;
    }, [schema, subTotal, value]);
    const primary = React.useMemo(() => {
        if (isDefined(tipValue)) {
            return getCurrencyString(tipValue);
        }
        return '';
    }, [getCurrencyString, tipValue]);
    const secondary = React.useMemo(() => {
        if (schema === ETipScheme.PERCENTAGE) {
            return `${value}%`;
        }
        return undefined;
    }, [schema, value]);
    const handleClick = React.useCallback(() => {
        onClick(tipValue ?? 0, value);
    }, [onClick, tipValue, value]);
    const selected = React.useMemo(() => {
        if (isDefined(subTotal) && isDefined(selectedValue)) {
            return tipValue === getTipValueOfOption(schema, subTotal, selectedValue);
        }
        return false;
    }, [schema, selectedValue, subTotal, tipValue]);
    return (
        <PayTipsItemBox
            onClick={handleClick}
            primary={primary}
            secondary={secondary}
            selected={selected}
            loading={loading}
        />
    );
};
