import { ActionCreator } from 'redux';
import { getLocalBasket, resetLocalBasket } from 'components/basket/localStore';
import { filterBasket } from 'components/basket/model/Basket';
import { locationApi } from 'components/location/locationApi';
import { menuApi } from 'components/menu/menuApi';
import { IEnrichedMenuWithModifierMaps, mapCategories } from 'components/menu/model/Menu';
import { OrderScenario } from 'components/order/model/Order';
import { getRequestDate } from 'lib/helpers';
import logger from 'lib/logger';
import { isDefined, isString } from 'lib/typeInference';
import { BasketActionTypes } from 'store/basket/basketActionTypes';
import { MenuActionTypes } from './menuActionTypes';
import { enqueueSnackbar } from 'notistack';

interface MenuLoadingAction {
    type: MenuActionTypes.END_REQUEST | MenuActionTypes.START_REQUEST;
}

interface MenuGetSuccessAction {
    type: MenuActionTypes.GET_MENU_SUCCESS;
    menu: IEnrichedMenuWithModifierMaps;
    categories: IEnrichedMenuWithModifierMaps['categories'];
}

interface MenuErrorAction {
    type: MenuActionTypes.GET_MENU_ERROR;
    error: string;
}

export type MenuAction = MenuLoadingAction | MenuGetSuccessAction | MenuErrorAction;

export const getMenu =
    (
        locationId: string,
        date?: string,
        scenario: OrderScenario = OrderScenario.PREORDER,
        basketChangeMessage = '',
        deliveryLocation?: string
    ) =>
    async (dispatch: ActionCreator<MenuAction>) => {
        dispatch({ type: MenuActionTypes.START_REQUEST });
        let myZone: string | undefined;
        if (scenario === OrderScenario.ORDER_TO_TABLE || scenario === OrderScenario.TABLE) {
            if (isDefined(deliveryLocation)) {
                try {
                    const result = await locationApi.getLocationZones(locationId, deliveryLocation);
                    if (isString(result?.zone)) {
                        myZone = result.zone;
                    }
                } catch (e) {
                    logger.error(e);
                }
            }
        }

        try {
            const params: Record<string, string> = {
                locationId,
                scenario,
                date: date || getRequestDate()
            };
            if (isDefined(myZone)) {
                params.zone = myZone;
            }
            const result = await menuApi.getMenu(params);
            const categories = mapCategories(result.categories, result);

            const storedBasketState = getLocalBasket();
            if (storedBasketState) {
                // No need to check scenario here, basket will be checked on the Checkout screen
                // For products no longer in the menu (timed products, sold out products, price changes etc)
                if (storedBasketState.locationId === locationId) {
                    const { items: filteredItems, isValid } = filterBasket(
                        categories as any,
                        storedBasketState.items,
                        result
                    );
                    if (!isValid) {
                        enqueueSnackbar(basketChangeMessage, {
                            variant: 'warning'
                        });
                    }
                    dispatch({
                        type: BasketActionTypes.SET_BASKET,
                        basket: { ...storedBasketState, items: filteredItems }
                    });
                } else {
                    resetLocalBasket();
                    dispatch({
                        type: BasketActionTypes.RESET_BASKET
                    });
                }
            }
            dispatch({ type: BasketActionTypes.SET_BASKET_LOCATION_ID, locationId });
            dispatch({
                categories,
                type: MenuActionTypes.GET_MENU_SUCCESS,
                menu: result
            });
        } catch (e) {
            logger.error(e);
            dispatch({
                type: MenuActionTypes.GET_MENU_ERROR,
                error: e.message
            });
            // Removed resetLocalBasket() as it used to cause an issue, not sure if we need it at all or not
            dispatch({
                type: BasketActionTypes.RESET_BASKET
            });
        }

        return dispatch({ type: MenuActionTypes.END_REQUEST });
    };
