import { PaymentMethod } from '@stripe/stripe-js';
import { TFunction } from 'i18next';
import { isDefined, isEmptyString } from 'lib/typeInference';
import { CountryCode, isSupportedCountry, parsePhoneNumberFromString } from 'libphonenumber-js';
import * as Yup from 'yup';

export enum CredentialProvider {
    EMAIL = 'EMAIL',
    MOBILE = 'MOBILE',
    GOOGLE = 'GOOGLE',
    APPLE = 'APPLE'
}

export interface Credential {
    _id: string;
    id: string;
    provider: CredentialProvider;
    isVerified: boolean;
    isPrimary: boolean;
}

interface AutoTopUp {
    enabled: boolean;
    amount: number;
    trigger: number;
}

export interface UserCard {
    _id: string;
    cardType: string;
    cardId?: string;
    type: string;
    last4: string;
    expires: string;
    gatewayId: string;
    gateway: string;
    email: string;
    methodType: string;
    userId: string;
    autoTopUp: AutoTopUp;
    updated?: string;
    created: string;
}

interface UserTip {
    scheme: 'ABSOLUTE' | 'PERCENTAGE';
    amount: number;
}

export enum EUserRole {
    USER = 'USER',
    ADMIN = 'ADMIN',
    GUEST = 'GUEST'
}

// TODO: add modifires here
interface FavouriteProduct {
    categoryTitle: string;
    title: string;
}

export interface User {
    roles: EUserRole[];
    credentials: Credential[];
    _id: string;
    // WHY do we get id and _id?
    id: string;
    email: string;
    card?: UserCard;
    defaultTip: UserTip | null;
    firstName: string;
    fullName: string;
    lastName: string;
    state: 'ACTIVE' | 'PENDING';
    tenantId: string;
    created: string;
    isRegisteredForPushNotifications: boolean;
    reviewCandidate: string;
    subscribed: boolean;
    hasProfilePhoto: boolean;
    hasPaymentCard: boolean;
    balance: number;
    birthdate: string;
    points: number;
    demo: boolean;
    primaryPlatform: 'UNKNOWN' | 'IOS' | 'ANDROID';
    additionalInformation: { name: string; value: string }[];
    updatedAt: string;
    deleted: boolean;
    hasAgreedToReceiveMarketing: boolean;
    hasAgreedToShareData: boolean;
    favouriteProducts: FavouriteProduct[];
    additionalProperties: Record<string, object>;
    phoneNumberLastUsedToOrder?: string;
    externalLoyaltyId?: string;
}

export interface IUpdateUserRequest {
    firstName: string;
    lastName: string;
    birthdate: string;
    hasAgreedToReceiveMarketing: boolean;
    phoneNumberLastUsedToOrder?: string;
    defaultTip?: UserTip;
}

export function isUser(data: any): data is User {
    return !!data;
}

export interface UserData {
    user: User;
    roles: EUserRole[];
    permanent: boolean;
    _id: string;
    userId: string;
    tenantId: string;
    tenantTitle: string;
    created: string;
}

export interface UserDataSSO extends UserData {
    status: number;
}

export function isUserData(data: any): data is UserData {
    return !!data && isUser(data.user);
}

export function isPhoneNumberValid(phone?: string, countryCode: CountryCode = 'GB') {
    if (!isSupportedCountry(countryCode)) {
        throw new Error('This country is not supported');
    }
    return !!parsePhoneNumberFromString(phone || '', countryCode)?.isValid();
}

export function getIdentityValidationScheme(identity: string, country: CountryCode = 'GB', t: TFunction) {
    if (!isSupportedCountry(country)) {
        throw new Error('This country is not supported');
    }
    if (identity === 'both') {
        return (Yup.string as any)().or(
            [
                Yup.string().email(t('FORMS_VALIDATION_EMAIL')).required(t('FORMS_VALIDATION_REQUIRED')),
                Yup.string()
                    .test(
                        'phone',
                        t('FORMS_VALIDATION_PHONE'),
                        value => !!parsePhoneNumberFromString(value || '', country)?.isValid()
                    )
                    .required(t('FORMS_VALIDATION_REQUIRED'))
            ],
            t('FORMS_VALIDATION_BOTH')
        );
    }
    if (identity === 'email') {
        return Yup.string().email(t('FORMS_VALIDATION_EMAIL')).required(t('FORMS_VALIDATION_REQUIRED'));
    }
    if (identity === 'phone') {
        return Yup.string()
            .test(
                'phone',
                t('FORMS_VALIDATION_PHONE'),
                value => !!parsePhoneNumberFromString(value || '', country)?.isValid()
            )
            .required(t('FORMS_VALIDATION_REQUIRED'));
    }
    throw new TypeError('Unexpected identity');
}

export interface SignUpFormData {
    identity: string;
    firstName: string;
    lastName: string;
    password: string;
    confirmPassword: string;
    hasAgreedToShareData: boolean;
    hasAgreedToReceiveMarketing: boolean;
    birthdate?: string;
}

export interface SignUpDOBData {
    birthDate: string;
}

export interface SignUpDOBData {
    birthDate: string;
}

export enum PaymentProviderType {
    STRIPE = 'STRIPE',
    JUDOPAY = 'JUDOPAY',
    BRAINTREE = 'BRAINTREE',
    SQUARE = 'SQUARE',
    WORLDPAY = 'WORLDPAY'
}

interface IJudopayCardData {
    cardType: PaymentProviderType.JUDOPAY; // JUDOPAY etc
    token: string;
}

export interface ISquareCardData {
    last4: string;
    type: string;
    expires: string;
    gatewayId: string;
    cardType: PaymentProviderType.SQUARE;
    token?: string;
}

interface IStripeCardData {
    last4: string;
    type: string;
    expires: string;
    gatewayId: string;
    cardType: PaymentProviderType.STRIPE;
    cardId: string | PaymentMethod;
}

interface IWorldpayCardData {
    cardType: PaymentProviderType.WORLDPAY;
    token: string;
}

export type ICreateCardData = IJudopayCardData | ISquareCardData | IStripeCardData | IWorldpayCardData;

export interface ICreateUserCredentialRequest {
    id: string;
    provider: CredentialProvider;
    skipVerification?: boolean;
}

export interface ICreateUserCredentialResponse {
    _id: string;
    id: string;
    provider: CredentialProvider;
    isVerified: boolean;
    isPrimary: boolean;
}

export function isUserMissingName(user?: User) {
    return (
        !isDefined(user?.firstName) ||
        isEmptyString(user?.firstName) ||
        !isDefined(user?.lastName) ||
        isEmptyString(user?.lastName)
    );
}
