import { ActionCreator } from 'redux';
import { IEnrichedMenuWithModifierMaps } from 'components/menu/model/Menu';
import { IOrderReadResourceV12, OrderScenario } from 'components/order/model/Order';
import { orderApi } from 'components/order/orderApi';
import { validateRecentOrder } from 'components/recentOrders/utils';
import logger from 'lib/logger';
import { OrdersActionTypes } from './ordersActionTypes';

interface RecentOrdersLoadingAction {
    type: OrdersActionTypes.END_REQUEST | OrdersActionTypes.START_REQUEST;
}

interface RecentOrdersGetSuccessAction {
    type: OrdersActionTypes.GET_RECENT_ORDERS_SUCCESS;
    recentOrders: IOrderReadResourceV12[];
}

interface RecentOrdersErrorAction {
    type: OrdersActionTypes.GET_RECENT_ORDERS_ERROR;
    error: string;
}

export type RecentOrdersAction =
    | RecentOrdersGetSuccessAction
    | RecentOrdersErrorAction
    | RecentOrdersLoadingAction;

export interface IOrdersOptions {
    scenario: OrderScenario;
    locationId?: string;
    limit?: number;
    isOpen?: boolean;
}

export const getRecentOrders =
    (menu: IEnrichedMenuWithModifierMaps, options: IOrdersOptions) =>
    async (dispatch: ActionCreator<RecentOrdersAction>) => {
        dispatch({ type: OrdersActionTypes.START_REQUEST });

        try {
            const response = await orderApi.getOrders(options);
            const validOrders = response.items.filter(recentOrder =>
                validateRecentOrder(recentOrder.items, menu)
            );
            dispatch({ type: OrdersActionTypes.GET_RECENT_ORDERS_SUCCESS, recentOrders: validOrders });
        } catch (error) {
            logger.error(error);
            dispatch({
                type: OrdersActionTypes.GET_RECENT_ORDERS_ERROR,
                error: error?.message
            });
        }

        dispatch({ type: OrdersActionTypes.END_REQUEST });
    };
