import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useResponsive } from 'src/hooks/useResponsive';
import { OrderScenario } from 'components/order/model/Order';

interface IProps {
    scenario: OrderScenario;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: ({ isDesktop }: { isDesktop: boolean }) => ({
            display: 'flex',
            padding: theme.spacing(1.5),
            border: `1px solid ${theme.palette.primary.main}`,
            margin: isDesktop ? theme.spacing(1.5, 0, 2) : theme.spacing(1.5),
            flexShrink: 0,
            width: '100%'
        }),
        icon: {
            color: theme.palette.primary.main,
            marginRight: theme.spacing(1)
        }
    })
);

export const ServiceNotAvailable: React.FC<IProps> = ({ scenario }) => {
    const { isDesktop } = useResponsive();
    const classes = useStyles({ isDesktop });
    const { t } = useTranslation();
    const scenarioErrorString = React.useMemo(() => {
        switch (scenario) {
            case OrderScenario.ORDER_TO_TABLE:
                return t('ERROR_LOCATION_OTT_DISABLED');
            case OrderScenario.TABLE:
                return t('ERROR_LOCATION_PAT_DISABLED');
            case OrderScenario.PREORDER:
            default:
                return t('ERROR_LOCATION_PRE_ORDER_DISABLED');
        }
    }, [scenario, t]);
    return (
        <div className={classes.wrapper}>
            <Typography>{scenarioErrorString}</Typography>
        </div>
    );
};
