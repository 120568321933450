import {
    Box,
    Button,
    Checkbox,
    Dialog,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Link,
    makeStyles,
    Slide,
    SlideProps,
    Theme,
    Typography
} from '@material-ui/core';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { Markdown } from 'lib/Markdown';
import { Throbber } from 'lib/Throbber';
import React from 'react';
import Close from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import Axios from 'axios';
import { useParams } from 'react-router-dom';
import { BaseRouteParams } from 'pages/routes';
import { isString } from 'lib/typeInference';
import { EGAEventName, updateGtagConsent, useGAHelpers } from 'lib/useGAHelpers';
import { useFormik } from 'formik';
import logger from 'lib/logger';
import { useStringReplace } from 'lib/useStringReplace';

const transitionProps: SlideProps = { direction: 'up' };
// essential | functional | analytics
const useStyles = makeStyles((theme: Theme) => ({
    title: {
        textAlign: 'center',
        fontFamily: `TenantFont, ${theme.typography.fontFamily}`,
        fontWeight: 'bold',
        color: theme.palette.titleTextColour
    },
    markdownContainer: {
        padding: theme.spacing(1),
        width: '100%',
        height: '100%',
        paddingBottom: theme.spacing(7),
        overflow: 'hidden auto'
    },
    buttonsContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {
        marginLeft: theme.spacing(1)
    }
}));

const getConsentKey = (merchantId: string) => btoa(`consent-${merchantId}`);
const getConsentValueToSave = (value: string) => btoa(value);
const parseConsentValue = (value: string | null) => {
    if (isString(value)) {
        try {
            return atob(value);
        } catch (e) {
            logger.warn(`Expected base64, but got "${value}"`);
        }
    }
    return undefined;
};

export const getConsentValue = (merchantId: string) =>
    parseConsentValue(localStorage.getItem(getConsentKey(merchantId)));

export const hasConsentTo = (consent: string, resource: 'analytics' | 'functional' | 'essential') => {
    if (consent === 'all') {
        return true;
    }
    if (resource === 'essential') {
        return true;
    }
    if (resource === 'functional') {
        return consent.includes('functional');
    }
    if (resource === 'analytics') {
        return consent.includes('analytics');
    }
};

interface CookieConsentDialogProps {
    onVerify: () => void;
}

export const CookieConsentDialog: React.FC<CookieConsentDialogProps> = ({ onVerify }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { merchantId } = useParams<BaseRouteParams>();
    const [open, setOpen] = React.useState(false);
    const [privacyOpen, setPrivacyOpen] = React.useState(false);
    const [privacyMarkdown, setPrivacyMarkdown] = React.useState<string | null>(null);
    const [expanded, setExpanded] = React.useState(false);
    const { logUserEvent } = useGAHelpers();

    const handlePrivacyClick = React.useCallback(() => setPrivacyOpen(true), []);
    const handlePrivacyClose = React.useCallback(() => setPrivacyOpen(false), []);
    const loadPrivacy = React.useCallback(async () => {
        try {
            setPrivacyMarkdown(null);
            const privacy = await Axios.get(
                `${process.env.MEDIA_URL}/tenants/${merchantId}/app_media/privacy.md`
            );
            setPrivacyMarkdown(
                privacy.status === 200 ? privacy.data : t('AUTH_TERMS_PRIVACY_LOADING_FAILED')
            );
        } catch (err) {
            setPrivacyMarkdown(t('AUTH_TERMS_PRIVACY_LOADING_FAILED'));
        }
    }, [merchantId, t]);

    const checkCookieSettings = React.useCallback(
        (consentState?: string) => {
            if (isString(consentState)) {
                if (hasConsentTo(consentState, 'analytics')) {
                    setTimeout(() => {
                        updateGtagConsent('analyticOnly');
                    }, 1000);
                }
                if (consentState === 'essential') {
                    setTimeout(() => {
                        updateGtagConsent('none');
                    }, 1000);
                }
                onVerify();
            } else {
                setOpen(true);
            }
        },
        [onVerify]
    );

    React.useEffect(() => {
        if (isString(merchantId)) {
            const consentState = getConsentValue(merchantId);
            checkCookieSettings(consentState);
        }
    }, [checkCookieSettings, merchantId]);
    React.useEffect(() => {
        if (privacyOpen && !privacyMarkdown) {
            loadPrivacy();
        }
    }, [loadPrivacy, privacyMarkdown, privacyOpen]);
    const handleRejectAll = React.useCallback(() => {
        if (isString(merchantId)) {
            localStorage.setItem(getConsentKey(merchantId), getConsentValueToSave('essential'));
            setOpen(false);
            logUserEvent(EGAEventName.CookiesRejectAll);
        }
    }, [logUserEvent, merchantId]);
    const handleAcceptAll = React.useCallback(() => {
        if (isString(merchantId)) {
            localStorage.setItem(getConsentKey(merchantId), getConsentValueToSave('all'));
            updateGtagConsent('analyticOnly');
            setOpen(false);
            logUserEvent(EGAEventName.CookiesAcceptAll);
        }
    }, [logUserEvent, merchantId]);
    const handleManage = React.useCallback(() => {
        setExpanded(true);
        logUserEvent(EGAEventName.CookiesManage);
    }, [logUserEvent]);
    const handleSubmit = React.useCallback(
        (values: { functional: boolean; analytical: boolean }) => {
            if (isString(merchantId)) {
                let settingString = 'essential';
                if (values.functional) {
                    settingString += ',functional';
                }
                if (values.analytical) {
                    settingString += ',analytical';
                }
                localStorage.setItem(getConsentKey(merchantId), getConsentValueToSave(settingString));
                checkCookieSettings(settingString);
                setOpen(false);
                logUserEvent(EGAEventName.CookiesSave);
            }
        },
        [checkCookieSettings, logUserEvent, merchantId]
    );
    const formik = useFormik({
        initialValues: {
            essential: true,
            functional: false,
            analytical: false
        },
        onSubmit: handleSubmit
    });
    const handleFormSubmit = React.useCallback(() => {
        formik.handleSubmit();
    }, [formik]);
    const replace = useStringReplace();
    return (
        <React.Fragment>
            <Dialog onClose={undefined} open={open}>
                <Box padding={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography className={classes.title}>
                                {t(expanded ? 'COOKIE_BANNER_MANAGE_TITLE' : 'COOKIE_BANNER_TITLE')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                {replace('COOKIE_BANNER_DESCRIPTION', 'privacy', () => (
                                    <Link key="privacy-link" onClick={handlePrivacyClick}>
                                        {t('COOKIE_BANNER_PRIVACY')}
                                    </Link>
                                ))}
                            </Typography>
                        </Grid>
                        {expanded && (
                            <Grid item xs={12}>
                                <FormControl disabled fullWidth>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                name="essential"
                                                checked={formik.values.essential}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        }
                                        label={t('COOKIE_BANNER_ESSENTIAL_TITLE')}
                                    />
                                    <FormHelperText>
                                        {t('COOKIE_BANNER_ESSENTIAL_DESCRIPTION')}
                                    </FormHelperText>
                                </FormControl>
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                name="functional"
                                                checked={formik.values.functional}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        }
                                        label={t('COOKIE_BANNER_FUNCTIONAL_TITLE')}
                                    />
                                    <FormHelperText>
                                        {t('COOKIE_BANNER_FUNCTIONAL_DESCRIPTION')}
                                    </FormHelperText>
                                </FormControl>
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                color="primary"
                                                name="analytical"
                                                checked={formik.values.analytical}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        }
                                        label={t('COOKIE_BANNER_ANALYTICAL_TITLE')}
                                    />
                                    <FormHelperText>
                                        {t('COOKIE_BANNER_ANALYTICAL_DESCRIPTION')}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item xs={12} className={classes.buttonsContainer}>
                            <Button
                                className={classes.button}
                                variant="outlined"
                                color="primary"
                                onClick={handleRejectAll}
                            >
                                {t('COOKIE_BANNER_BUTTONS_REJECT_ALL')}
                            </Button>
                            {expanded && (
                                <Button
                                    className={classes.button}
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleFormSubmit}
                                >
                                    {t('COOKIE_BANNER_BUTTONS_SAVE')}
                                </Button>
                            )}
                            {!expanded && (
                                <Button
                                    className={classes.button}
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleManage}
                                >
                                    {t('COOKIE_BANNER_BUTTONS_MANAGE')}
                                </Button>
                            )}
                            <Button
                                className={classes.button}
                                color="primary"
                                variant="contained"
                                onClick={handleAcceptAll}
                            >
                                {t('COOKIE_BANNER_BUTTONS_ACCEPT_ALL')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
            <Dialog
                fullScreen
                open={privacyOpen}
                TransitionComponent={Slide}
                TransitionProps={transitionProps}
            >
                <MuiAppBar title={t('ABOUT_PRIVACY')} icon={Close} onBack={handlePrivacyClose} disabledMenu />
                {privacyMarkdown === null && <Throbber text={t('GENERAL_LOADING')} />}
                {privacyMarkdown !== null && (
                    <Box className={classes.markdownContainer}>
                        <Markdown source={privacyMarkdown} />
                    </Box>
                )}
            </Dialog>
        </React.Fragment>
    );
};
