import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import clsx from 'clsx';
import { PaymentProviderType } from 'components/user/model/User';
import { userApi } from 'components/user/userApi';
import { isDefined } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import { LoadingButton } from 'ui/LoadingButton';
import { IAddCardComponentClasses } from '../PaymentProvider';
import { useSnackbar } from 'notistack';

interface IProps {
    postCardAddition: () => void;
    classes?: IAddCardComponentClasses;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            marginTop: 'auto',
            padding: theme.spacing(1)
        },
        stripeBase: {
            marginTop: theme.spacing(2)
        }
    })
);

export const StripeAddCardComponent: React.FC<IProps> = ({
    postCardAddition,
    classes: overrideClasses = {}
}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const classes = useStyles();
    const [addCardInProgress, setAddCardInProgress] = React.useState(false);
    const [errorMessage, setError] = React.useState<string>();
    const { isLoggedIn, hasSession } = useAuth();
    const stripe = useStripe();
    const elements = useElements();
    const { enqueueSnackbar } = useSnackbar();

    const handleAddCardSubmit = React.useCallback(async () => {
        setAddCardInProgress(true);
        setError(undefined);
        try {
            if (!hasSession) {
                throw new Error('User is not authenticated');
            }

            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }

            const cardElement = elements.getElement(CardElement);

            if (!!cardElement) {
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: cardElement
                });

                if (!!error) {
                    setError(t('CHECKOUT_ERROR_VALIDATING_CARD'));
                } else {
                    try {
                        const { stripe: stripeSetupData } = await userApi.getCardSetupData();
                        const intentResponse = await stripe.confirmCardSetup(
                            stripeSetupData.setupIntentClientSecret,
                            {
                                payment_method: { card: cardElement }
                            }
                        );

                        if (!!paymentMethod?.card && !intentResponse.error) {
                            const {
                                brand,
                                last4,
                                exp_month: expMonth,
                                exp_year: expYear
                            } = paymentMethod.card;
                            try {
                                await userApi.createCard({
                                    last4,
                                    expires: `${expMonth > 9 ? expMonth : `0${expMonth}`}${expYear % 100}`,
                                    gatewayId: stripeSetupData.setupIntentCustomerId,
                                    cardType: PaymentProviderType.STRIPE,
                                    type: brand,
                                    cardId: intentResponse.setupIntent.payment_method ?? paymentMethod.id
                                });
                                postCardAddition();
                                enqueueSnackbar(t('CHECKOUT_CARD_ADDED'), { variant: 'success' });
                            } catch (err) {
                                setError(err.data?.message || t('GENERAL_GENERIC_ERROR'));
                            }
                        } else {
                            throw new Error('Card not found');
                        }
                    } catch {
                        setError(t('CHECKOUT_ERROR_VALIDATING_CARD'));
                    }
                }
            } else {
                return;
            }
        } catch {
            setError(t('CHECKOUT_ERROR_VALIDATING_CARD'));
        } finally {
            setAddCardInProgress(false);
        }
    }, [elements, enqueueSnackbar, hasSession, postCardAddition, stripe, t]);
    const handleCardChange = React.useCallback(() => {
        setError(undefined);
    }, []);
    return (
        <>
            <CardElement
                onChange={handleCardChange}
                options={{
                    style: {
                        base: {
                            fontSize: '16px',
                            color: theme.palette.text.primary
                        }
                    },
                    classes: {
                        base: clsx(classes.stripeBase, overrideClasses.root)
                    }
                }}
            />
            <Box pt={2}>
                {isDefined(errorMessage) && <Typography color="error">{errorMessage}</Typography>}
            </Box>
            <div className={clsx(classes.buttonContainer, overrideClasses.buttonContainer)}>
                <LoadingButton
                    disabled={addCardInProgress || !stripe}
                    loading={addCardInProgress}
                    onClick={handleAddCardSubmit}
                    className={overrideClasses.button}
                    color="primary"
                    variant="contained"
                    fullWidth
                >
                    {isLoggedIn ? t('CHECKOUT_ADD_CARD') : t('CHECKOUT_USE_CARD')}
                </LoadingButton>
            </div>
        </>
    );
};
