import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MerchantTitle } from 'app/MerchantTitle';
import { getIdentityLabel } from 'components/settings/model/Settings';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { TextFormField } from 'lib/form/TextFormField';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/LoadingButton';
import { getIdentityValidationScheme } from '../model/User';
import { EGAEventName, useGAHelpers } from 'lib/useGAHelpers';

interface ResetPasswordProps {
    onSubmit: (identity: string) => void;
}

interface ResetPasswordFormData {
    identity: string;
}

const initialValues: ResetPasswordFormData = {
    identity: ''
};

export const ResetPassword: React.FC<ResetPasswordProps> = ({ onSubmit }) => {
    const { t } = useTranslation();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { logUserEvent } = useGAHelpers();
    const identityLabel = React.useMemo(
        () => getIdentityLabel(settings?.emailAuthEnabled, settings?.phoneAuthEnabled),
        [settings?.emailAuthEnabled, settings?.phoneAuthEnabled]
    );
    const validationScheme = React.useMemo(
        () =>
            Yup.object<ResetPasswordFormData>().shape({
                identity: getIdentityValidationScheme(identityLabel, settings?.region.phoneNumberCode, t)
            }),
        [identityLabel, t, settings?.region.phoneNumberCode]
    );
    const handleSubmit = React.useCallback(
        (data: ResetPasswordFormData) => {
            onSubmit(data.identity);
            logUserEvent(EGAEventName.ResetPasswordSubmit);
        },
        [logUserEvent, onSubmit]
    );
    return (
        <>
            <MerchantTitle title={t('ONBOARDING_RESET_TITLE')} />
            <MuiAppBar title={t('ONBOARDING_RESET_TITLE')} onBackEventName={EGAEventName.ResetPasswordBack} />
            <Box padding={2} paddingTop={4}>
                <Box marginBottom={2}>
                    <Typography>{t('ONBOARDING_RESET_MESSAGE', { submit: t('BUTTON_SUBMIT') })}</Typography>
                </Box>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationScheme}
                    onSubmit={handleSubmit}
                >
                    {({ submitForm, isSubmitting, isValid, values: { identity } }) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextFormField
                                        name="identity"
                                        InputProps={{ autoComplete: 'email' }}
                                        label={t(`SIGN_UP_LABEL_${identityLabel.toUpperCase()}`)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        onClick={submitForm}
                                        disabled={isSubmitting || !isValid || !identity}
                                        loading={isSubmitting}
                                        variant="contained"
                                        fullWidth
                                        color="primary"
                                    >
                                        {t('BUTTON_SUBMIT')}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};
