import { ETipScheme } from 'components/settings/enums';
import {
    IAdjustmentLocalResource,
    IAdjustmentReadResourceV10,
    IUpdateOrderAdjustment,
    OrderAdjustmentType
} from './model/Order';

export function isOrderAvailableAdjastment(item: any): item is IAdjustmentReadResourceV10 {
    return !!item && item.type !== OrderAdjustmentType.TIP && !item.deleted;
}

export const generateOrderAdjustments = (tipToUse: number, selectedReward?: IAdjustmentLocalResource) => {
    const newAdjustments: IUpdateOrderAdjustment[] = [];
    if (selectedReward && selectedReward.type === OrderAdjustmentType.DISCOUNT) {
        const { type, quantity, awardId } = selectedReward;
        if (quantity && awardId) {
            newAdjustments.push({ type, quantity, awardId });
        }
    }
    if (tipToUse !== 0) {
        const tipAdjustment: IUpdateOrderAdjustment = { type: OrderAdjustmentType.TIP, value: tipToUse };
        newAdjustments.push(tipAdjustment);
    }
    return newAdjustments;
};

export const getTipValueOfOption = (scheme: ETipScheme, subTotal: number, value: number) =>
    scheme === ETipScheme.PERCENTAGE ? Number(((subTotal * value) / 100).toFixed(2)) : value;
