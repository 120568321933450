import React from 'react';
import { roundToDecimal } from 'lib/helpers';

export function useBillSplittingModal(
    total: number,
    defaultShares?: number,
    defaultYourShares?: number,
    defaultCustomAmount?: number
) {
    const [shares, setShares] = React.useState(defaultShares ?? 2);
    const [yourShares, setYourShares] = React.useState(defaultYourShares ?? 1);
    const [customAmount, setCustomAmount] = React.useState(
        defaultCustomAmount ? String(defaultCustomAmount) : defaultCustomAmount
    );
    const handleIncreaseShares = React.useCallback(() => {
        setShares(share => share + 1);
    }, []);
    const handleDecreaseShares = React.useCallback(() => {
        if (yourShares === shares) {
            setYourShares(shares - 1);
        }
        setShares(shares - 1);
    }, [shares, yourShares]);
    const handleIncreaseYourShares = React.useCallback(() => {
        setYourShares(yourShare => yourShare + 1);
    }, []);
    const handleDecreaseYourShares = React.useCallback(() => {
        setYourShares(yourShare => yourShare - 1);
    }, []);
    const handleCustomAmountChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setCustomAmount(e.target.value === '' ? undefined : e.target.value);
    }, []);
    const handleReset = React.useCallback(() => {
        setCustomAmount(undefined);
    }, []);
    const toPay = React.useMemo(
        () => roundToDecimal((total / shares) * yourShares),
        [shares, total, yourShares]
    );
    React.useEffect(() => {
        setShares(defaultShares ?? 2);
    }, [defaultShares]);
    return {
        shares: { increase: handleIncreaseShares, decrease: handleDecreaseShares, value: shares },
        yourShares: {
            increase: handleIncreaseYourShares,
            decrease: handleDecreaseYourShares,
            value: yourShares
        },
        customAmount: {
            onChange: handleCustomAmountChange,
            onReset: handleReset,
            value: customAmount
        },
        toPay
    };
}
