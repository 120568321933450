import { orderApi } from 'components/order/orderApi';
import { isDefined } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import { LocationRouteParams } from 'pages/routes';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ApplicationState } from 'store/store';

export function useGiftCards(defaultGiftcard?: string) {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { locationId } = useParams<LocationRouteParams>();
    const [giftCard, setGiftCard] = React.useState<string | undefined>(defaultGiftcard);
    const [giftCardBalance, setGiftCardBalance] = React.useState<number>();
    const [giftCardError, setGiftCardError] = React.useState<string>();
    const [giftCardLoading, setGiftCardLoading] = React.useState(false);
    const { user } = useAuth();
    const resetGiftCard = React.useCallback(() => {
        setGiftCard(undefined);
        setGiftCardError(undefined);
        setGiftCardBalance(undefined);
    }, []);
    const submitGiftCard = React.useCallback(
        async (value?: string) => {
            setGiftCardError(undefined);
            if (!isDefined(value)) {
                setGiftCard(undefined);
                setGiftCardBalance(undefined);
            } else {
                setGiftCardLoading(true);
                try {
                    const result = await orderApi.getGiftcard(
                        value,
                        settings?.giftCardPaymentProvider ?? '',
                        locationId ?? ''
                    );
                    setGiftCardBalance(result.balance);
                    setGiftCard(value);
                } catch (e) {
                    setGiftCardError(e.data.message);
                    setGiftCard(undefined);
                } finally {
                    setGiftCardLoading(false);
                }
            }
        },
        [locationId, settings?.giftCardPaymentProvider]
    );
    React.useEffect(() => {
        const checkGiftCard = async () => {
            if (giftCard && user && !isDefined(giftCardBalance)) {
                setGiftCardLoading(true);
                try {
                    const result = await orderApi.getGiftcard(
                        giftCard,
                        settings?.giftCardPaymentProvider ?? '',
                        locationId ?? ''
                    );
                    setGiftCardBalance(result.balance);
                } catch {
                    setGiftCard(undefined);
                } finally {
                    setGiftCardLoading(false);
                }
            }
        };
        checkGiftCard();
    }, [giftCard, giftCardBalance, locationId, settings?.giftCardPaymentProvider, user]);
    const resetGiftCardErrors = React.useCallback(() => {
        setGiftCardError(undefined);
    }, []);
    return {
        giftCard,
        giftCardBalance,
        giftCardError,
        resetGiftCard,
        submitGiftCard,
        resetGiftCardErrors,
        giftCardLoading
    };
}
