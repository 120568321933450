import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

interface MerchantTitleProps {
    title?: string;
}

export const MerchantTitle: React.FC<MerchantTitleProps> = ({ title }) => {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const merchantTitle = React.useMemo(() => {
        if (!settings && !title) {
            return 'Pepper Web App';
        }
        if (!settings) {
            return title;
        }
        if (!title) {
            return settings.app.bundleDisplayName;
        }
        return `${settings.app.bundleDisplayName} - ${title}`;
    }, [title, settings]);
    return (
        <Helmet>
            <title>{merchantTitle}</title>
        </Helmet>
    );
};
