import React from 'react';
import { useSelector } from 'react-redux';
import { ETipScheme } from 'components/settings/enums';
import { ApplicationState } from 'store/store';
import { OrderScenario } from '../model/Order';
import { ITip } from 'components/settings/model/Settings';

export function useTippingSettings(scenario: OrderScenario): ITip & { enabled?: boolean } {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const tippingSettings = React.useMemo(() => {
        switch (scenario) {
            case OrderScenario.TABLE:
                return {
                    enabled: settings?.tippingPayAtTableEnabled ?? settings?.tippingEnabled,
                    default: settings?.tippingPayAtTable?.default ?? settings?.tipping?.default ?? 0,
                    scheme:
                        settings?.tippingPayAtTable?.scheme ??
                        settings?.tipping?.scheme ??
                        ETipScheme.ABSOLUTE,
                    values: settings?.tippingPayAtTable?.values ?? settings?.tipping?.values ?? [0, 0, 0, 0]
                };
            case OrderScenario.PREORDER:
                return {
                    enabled: settings?.tippingPreorderEnabled ?? settings?.tippingEnabled,
                    default: settings?.tippingPreorder?.default ?? settings?.tipping?.default ?? 0,
                    scheme:
                        settings?.tippingPreorder?.scheme ?? settings?.tipping?.scheme ?? ETipScheme.ABSOLUTE,
                    values: settings?.tippingPreorder?.values ?? settings?.tipping?.values ?? [0, 0, 0, 0]
                };
            case OrderScenario.ORDER_TO_TABLE:
                return {
                    enabled: settings?.tippingOrderToTableEnabled ?? settings?.tippingEnabled,
                    default: settings?.tippingOrderToTable?.default ?? settings?.tipping?.default ?? 0,
                    scheme:
                        settings?.tippingOrderToTable?.scheme ??
                        settings?.tipping?.scheme ??
                        ETipScheme.ABSOLUTE,
                    values: settings?.tippingOrderToTable?.values ?? settings?.tipping?.values ?? [0, 0, 0, 0]
                };
            default:
                return {
                    enabled: settings?.tippingEnabled,
                    default: settings?.tipping?.default ?? 0,
                    scheme: settings?.tipping?.scheme ?? ETipScheme.ABSOLUTE,
                    values: settings?.tipping?.values ?? [0, 0, 0, 0]
                };
        }
    }, [
        scenario,
        settings?.tipping?.default,
        settings?.tipping?.scheme,
        settings?.tipping?.values,
        settings?.tippingEnabled,
        settings?.tippingOrderToTable?.default,
        settings?.tippingOrderToTable?.scheme,
        settings?.tippingOrderToTable?.values,
        settings?.tippingOrderToTableEnabled,
        settings?.tippingPayAtTable?.default,
        settings?.tippingPayAtTable?.scheme,
        settings?.tippingPayAtTable?.values,
        settings?.tippingPayAtTableEnabled,
        settings?.tippingPreorder?.default,
        settings?.tippingPreorder?.scheme,
        settings?.tippingPreorder?.values,
        settings?.tippingPreorderEnabled
    ]);
    return tippingSettings;
}
