import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import reactStringReplace from 'react-string-replace';
import {
    Box,
    Dialog,
    IconButton,
    ListItemText,
    makeStyles,
    Paper,
    Slide,
    SlideProps,
    Theme,
    Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import clsx from 'clsx';
import { ISearchCategory, ISearchGroup, useMenuSearch } from 'src/hooks/useMenuSearch';
import { useResponsive } from 'src/hooks/useResponsive';
import { useSuggestionsContext } from 'src/hooks/useSuggestionsContext';
import { SearchInput } from 'lib/SearchInput';
import { isDefined, isEmptyString } from 'lib/typeInference';
import { useLocalHistory } from 'lib/useLocalHistory';
import { ApplicationState } from 'store/store';
import { IEnrichedProduct, IProduct, ProductGroup } from './model/Menu';
import { useAddModal } from './suggestions/useAddModal';
import { MenuProductInfo } from './MenuProductInfo';
import { MenuViewBasketButton } from './MenuViewBasketButton';
import { MenuSearchCategory } from './MenuSearchCategory';
import { useQuery } from 'src/hooks/useQuery';

interface IMenuSearchProps {
    open: boolean;
    fullScreen?: boolean;
    onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    titleGroup: {
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
        color: theme.palette.titleTextColour
    },
    titleCategory: {
        marginBottom: theme.spacing(1),
        fontWeight: 'bold',
        color: theme.palette.titleTextColour
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        flexDirection: 'column',
        height: '100%'
    },
    hint: {
        marginBottom: theme.spacing(1),
        color: theme.palette.text.primary
    },
    appBar: {
        position: 'fixed',
        top: 0,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
        zIndex: 1
    },
    backButton: {
        color: theme.palette.primary.main
    },
    input: {
        maxWidth: '300px',
        width: '100%'
    },
    productsListBox: {
        padding: theme.spacing(1.5, 1.5, 7, 1.5)
    },
    messageBox: {
        transform: 'translateY(-200%)'
    }
}));

export const MenuSearch: React.FC<IMenuSearchProps> = ({ open, onClose }) => {
    const classes = useStyles();
    const transitionProps: SlideProps = { direction: 'left' };

    const { replace } = useLocalHistory();
    const searchParams = useQuery('search');

    const appBarRef = React.useRef<HTMLDivElement>(null);
    const dialogRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);

    const [searchInput, setSearchInput] = React.useState(searchParams ?? '');
    const [selectedProduct, setSelectedProduct] = React.useState(
        null as null | IEnrichedProduct | ProductGroup
    );
    const [selectedCategoryId, setSelectedCategoryId] = React.useState<string>();

    const currency = useSelector(
        (state: ApplicationState) => state.settings?.settings?.region?.currencyCode ?? 'GBP'
    );
    const { menu } = useSelector((state: ApplicationState) => state.menu);

    const { t } = useTranslation();
    const { isDesktop } = useResponsive();
    const { searchMenu, groupsResult, categoriesResult } = useMenuSearch();
    const { addModal } = useAddModal();
    const context = useSuggestionsContext();

    React.useEffect(() => {
        searchMenu(searchInput);
        if (isDefined(searchInput) && !isEmptyString(searchInput)) {
            replace('', {}, `search=${searchInput}`);
        } else {
            replace('', {}, '');
        }
    }, [replace, searchInput, searchMenu]);

    React.useEffect(() => {
        function setBlurForInput() {
            if (inputRef && inputRef.current) {
                inputRef.current.blur();
            }
        }

        const dialog = dialogRef?.current;
        if (dialog) {
            dialog.addEventListener('scroll', setBlurForInput);
        }
        window.addEventListener('scroll', setBlurForInput);

        return () => {
            if (dialog) {
                dialog.removeEventListener('scroll', setBlurForInput);
            }
            window.removeEventListener('scroll', setBlurForInput);
        };
    }, [groupsResult, categoriesResult]);

    React.useEffect(() => {
        if (context.suggestedGroupProduct) {
            setSelectedProduct(context.suggestedGroupProduct);
            setSelectedCategoryId(menu?.productIdToCategoryId.get(context.suggestedGroupProduct.id));
        }
    }, [context.suggestedGroupProduct, menu?.productIdToCategoryId]);

    const handleProductDetailsClose = React.useCallback(() => {
        setSelectedCategoryId(undefined);
        setSelectedProduct(null);
        context.setShowMoreSuggestionInfo(false);
    }, [context]);

    const handleChangeSearchInput = React.useCallback(event => {
        const { value } = event.target;
        setSearchInput(value);
    }, []);

    const clearInput = React.useCallback(() => {
        setSearchInput('');
    }, []);
    const handleProductSelected = React.useCallback(
        (product: IProduct | ProductGroup, categoryId: string) => {
            setSelectedProduct(product);
            setSelectedCategoryId(categoryId);
        },
        []
    );

    const renderLocalisationStrings = React.useCallback(
        stringKey => (
            <Typography key={stringKey} variant="body1" align="center" className={classes.hint}>
                {reactStringReplace(stringKey, 'searchTerm', item => (
                    <b key={`search-term-${item}`}>{searchInput}</b>
                ))}
            </Typography>
        ),
        [classes.hint, searchInput]
    );

    const renderCategory = React.useCallback(
        (category: ISearchCategory) => (
            <MenuSearchCategory
                key={category.id}
                category={category}
                onProductSelect={handleProductSelected}
            />
        ),
        [handleProductSelected]
    );

    const renderCategoryGroup = React.useCallback(
        (group: ISearchGroup) => (
            <Box key={group.groupId} width="100%" marginBottom={2}>
                <ListItemText
                    primaryTypographyProps={{
                        className: classes.titleGroup,
                        variant: 'h5'
                    }}
                    primary={group.groupTitle}
                />
                <Box paddingLeft={2}>{group.categories.map(renderCategory)}</Box>
            </Box>
        ),
        [classes.titleGroup, renderCategory]
    );

    const handleClose = React.useCallback(() => {
        replace('', {}, '');
        onClose();
    }, [onClose, replace]);

    if (isDesktop) {
        return null;
    }

    return (
        <Dialog
            fullWidth
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionProps={transitionProps}
            TransitionComponent={Slide}
            PaperProps={{ ref: dialogRef }}
        >
            <Box height="100%">
                <div style={{ height: appBarRef.current?.clientHeight || 64 }}></div>
                <div ref={appBarRef} className={classes.appBar}>
                    <IconButton onClick={handleClose}>
                        <ArrowBackIcon className={classes.backButton} />
                    </IconButton>

                    <SearchInput
                        autoFocus
                        inputRef={inputRef}
                        onChange={handleChangeSearchInput}
                        onClear={clearInput}
                        value={searchInput}
                        placeholder={t('SEACRCH_MENU_PLACEHOLDER')}
                        componentClasses={{
                            wrapper: classes.input
                        }}
                    />
                </div>

                {(categoriesResult?.length || groupsResult?.length || selectedProduct) && (
                    <Box className={classes.productsListBox}>
                        {categoriesResult && categoriesResult.map(renderCategory)}
                        {groupsResult && groupsResult.map(renderCategoryGroup)}

                        {selectedProduct && isDefined(selectedCategoryId) && (
                            <MenuProductInfo
                                item={selectedProduct}
                                currency={currency}
                                open={!!selectedProduct}
                                onClose={handleProductDetailsClose}
                                categoryId={selectedCategoryId}
                            />
                        )}
                    </Box>
                )}

                {(groupsResult?.length === 0 || categoriesResult?.length === 0) && (
                    <Box style={{ height: `calc(100% - ${appBarRef.current?.clientHeight}px` }}>
                        <Paper className={clsx(classes.paper, classes.center)}>
                            <Box className={clsx(isDesktop ? '' : classes.messageBox)}>
                                {t('MENU_SEARCH_NO_RESULTS').split('\n').map(renderLocalisationStrings)}
                            </Box>
                        </Paper>
                    </Box>
                )}

                <MenuViewBasketButton />
            </Box>
            {addModal()}
        </Dialog>
    );
};
