import React from 'react';
import { Link, LinkProps, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        textTransform: 'none',
        color: theme.palette.primary.main,
        fontWeight: 700,
        fontSize: '16px',
        padding: theme.spacing(1, 0),
        display: 'block',
        cursor: 'pointer'
    }
}));

export const PayLinkButton: React.FC<LinkProps> = props => {
    const classes = useStyles();
    return <Link color="primary" {...props} className={clsx(classes.root, props.className)} />;
};
