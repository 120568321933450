import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useQuery } from 'src/hooks/useQuery';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { IItemReadResourceV10 } from 'components/basket/model/Basket';
import { ViewBillCheckoutNoteList } from 'components/bill/ui/ViewBill/Checkout/ViewBillCheckoutNoteList';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { OperationStatus } from 'components/order/model/Operation';
import { createOrderCreateItemFromBasketItem, OrderErrorCodes } from 'components/order/model/Order';
import { EOrderNonceKey, legacyOrderApi } from 'components/order/orderApi';
import { OPERATION_POLLING_DELAY } from 'config/constants';
import { BottomDialog } from 'lib/BottomDialog';
import { ConfirmDialog } from 'lib/ConfirmDialog';
import { InnerPageLayout } from 'lib/InnerPageLayout';
import { InnerPageLayoutBottom } from 'lib/InnerPageLayout/InnerPageLayoutBottom';
import { InnerPageLayoutContent } from 'lib/InnerPageLayout/InnerPageLayoutContent';
import { QuantitySelector } from 'lib/QuantitySelector';
import { Throbber } from 'lib/Throbber';
import { isDefined } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { LocationRouteParams, ROUTES } from 'pages/routes';
import { setReturnUrl } from 'store/auth/authActions';
import { resetBasket, updateBasketItemQuantity } from 'store/basket/basketActions';
import { getMenu } from 'store/menu/menuActions';
import { setLastRequest } from 'store/request/requestActions';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/LoadingButton';
import { ViewBillContentSection } from '../ViewBill/ViewBillContentSection';
import { ViewBillHeader } from '../ViewBill/ViewBillHeader';
import { ViewBillHeaderInfo } from '../ViewBill/ViewBillHeaderInfo';
import { ViewBillOrderItem } from '../ViewBill/ViewBillOrderItem';
import { EGAEventName, useGAHelpers } from 'lib/useGAHelpers';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        viewBasketButton: {
            padding: theme.spacing(1),
            marginBottom: theme.spacing(1)
        }
    })
);

const SPECIAL_ERROR_CODES: string[] = [
    OrderErrorCodes.DUPLICATE_ORDER_NONCE,
    OrderErrorCodes.DUPLICATE_PENDING_ORDER,
    OrderErrorCodes.UPDATING_THE_BILL
];

export const BasketPat: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        items,
        notes: notesFromState,
        timeSlot,
        deliveryLocation
    } = useSelector((state: ApplicationState) => state.basket);
    const { menu, isLoading: isMenuLoading } = useSelector((state: ApplicationState) => state.menu);
    const { user } = useAuth();
    const { search } = useLocation();
    const { push, getParsedPath } = useLocalHistory();
    const { locationId } = useParams<LocationRouteParams>();
    const [scenario] = useRouteScenario();
    const { isWorkingHour } = useLocationHelpers();
    const orderId = useQuery('orderId');
    const dispatch = useDispatch();
    const { lastRequest } = useSelector((state: ApplicationState) => state.request);
    const { currentLocation } = useSelector((state: ApplicationState) => state.locations);

    const [quantitySelectProductIndex, setQuantitySelectProductIndex] = React.useState(-1);
    const [quantity, setQuantity] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(false);
    const [error, setError] = React.useState<string>();

    const { logUserEvent } = useGAHelpers();

    React.useEffect(() => {
        getMenu(
            locationId || '',
            timeSlot?.start,
            scenario,
            t('DIALOG_TIMESLOT_CHANGED_MESSAGE'),
            deliveryLocation
        )(dispatch);
    }, [dispatch, locationId, scenario, timeSlot?.start, t, deliveryLocation]);

    const notes = React.useMemo(
        () => currentLocation?.ordering?.extendedFields.ORDER_TO_TABLE?.note ?? [],
        [currentLocation?.ordering?.extendedFields?.ORDER_TO_TABLE?.note]
    );

    const mappedItems: IItemReadResourceV10[] = React.useMemo(
        () =>
            items.reduce<IItemReadResourceV10[]>((acc, item) => {
                if (menu) {
                    const productDetailsFromMenu = menu.productIdToProduct.get(item.productId);
                    const category = menu.categories.find(({ id }) => item.categoryId === id);

                    const modifiers = item.modifiers?.map(modifier => {
                        const detailedModifier =
                            category?.modifiers?.find(catModifier => catModifier.id === modifier.id) ??
                            menu.modifiers?.find(menuModifier => menuModifier.id === modifier.id);

                        return {
                            ...detailedModifier,
                            options: detailedModifier?.options?.filter(option =>
                                modifier.options.some(opt => opt.id === option.id)
                            )
                        };
                    });

                    if (productDetailsFromMenu && category && user) {
                        acc.push({
                            ...item,
                            id: new Date().getMilliseconds(),
                            userId: user.id,
                            productName: productDetailsFromMenu.title,
                            categoryName: category.title,
                            cost: item.price,
                            modifiers
                        } as IItemReadResourceV10);
                    }
                }
                return acc;
            }, []),
        [items, menu, user]
    );

    const handleCloseQuantitySelect = React.useCallback(() => {
        setQuantitySelectProductIndex(-1);
    }, []);
    const handleOpenQuantitySelect = React.useCallback(
        (index: number) => {
            setQuantitySelectProductIndex(index);
            setQuantity(mappedItems[index].quantity);
        },
        [mappedItems]
    );
    const handleUpdateItemQuantity = React.useCallback(() => {
        updateBasketItemQuantity(quantitySelectProductIndex, quantity)(dispatch);
        handleCloseQuantitySelect();
    }, [quantitySelectProductIndex, quantity, dispatch, handleCloseQuantitySelect]);

    const handleClickItem = React.useCallback(
        (index: number) => {
            handleOpenQuantitySelect(index);
        },
        [handleOpenQuantitySelect]
    );

    const renderOrderItem = React.useCallback(
        (item: IItemReadResourceV10, index: number) => (
            <ViewBillOrderItem
                key={item.productId}
                onClick={handleClickItem}
                index={index}
                orderItem={item}
                allItems={mappedItems}
            />
        ),
        [handleClickItem, mappedItems]
    );
    const handleClickBack = React.useCallback(() => push(ROUTES.MENU, {}, search), [push, search]);
    const handleToggleConfirmationDialog = React.useCallback(
        () => setIsConfirmationOpen(!isConfirmationOpen),
        [isConfirmationOpen]
    );
    const handleOperation = React.useCallback(
        (operationId: string) => {
            async function getOperationStatus() {
                try {
                    setIsLoading(true);
                    setError(undefined);

                    const operation = await legacyOrderApi.getOperation(operationId);
                    legacyOrderApi.nonceHandlingByOperationStatus(
                        operation.status,
                        EOrderNonceKey.ADD_ITEM_TO_ORDER
                    );
                    switch (operation.status) {
                        case OperationStatus.PENDING:
                            // Recursive Poll for order completion
                            setTimeout(() => {
                                getOperationStatus();
                            }, OPERATION_POLLING_DELAY);

                            break;
                        case OperationStatus.ERROR:
                        case OperationStatus.TIMEOUT:
                            setIsLoading(false);

                            if (!operation.errorCode || !SPECIAL_ERROR_CODES.includes(operation.errorCode)) {
                                setError('Operation error or timeout');
                                handleToggleConfirmationDialog();
                            }
                            if (operation.errorCode === OrderErrorCodes.UPDATING_THE_BILL) {
                                setError(operation.errorMessage);
                                handleToggleConfirmationDialog();
                            }

                            break;
                        case OperationStatus.DONE: {
                            push(ROUTES.JOURNEY.PAT.BILL, {}, null, { showAddItemSuccess: true });
                            setIsLoading(false);
                            setReturnUrl(getParsedPath(ROUTES.JOURNEY.PAT.BILL))(dispatch);
                            // Redirect
                            resetBasket(dispatch);
                            break;
                        }
                    }
                } catch (orderStatusError) {
                    if (isDefined(orderStatusError?.response && orderStatusError.response.status === 404)) {
                        setError(orderStatusError.response.errorMessage);
                    } else {
                        setError(t('GENERAL_GENERIC_ERROR'));

                        if (!isDefined(orderStatusError?.response)) {
                            setError(t('DIALOG_ORDER_STATUS_UNKNOWN'));
                        }
                    }
                    setIsLoading(false);
                    handleToggleConfirmationDialog();
                }
            }
            getOperationStatus();
        },
        [dispatch, getParsedPath, handleToggleConfirmationDialog, push, t]
    );

    const handleAddOrder = React.useCallback(async () => {
        setLastRequest(handleAddOrder)(dispatch);
        setIsLoading(true);
        try {
            logUserEvent(EGAEventName.AddToOrder);
            if (Array.isArray(notes) && !!mappedItems[0]) {
                const orderNotes: string[] = [];
                notes.forEach(note => {
                    const currentNoteValue = notesFromState[note.title];
                    if (!!currentNoteValue) {
                        orderNotes.push(`${note.title}: ${currentNoteValue}`);
                    }
                });
                if (orderNotes.length > 0) {
                    mappedItems[0] = { ...mappedItems[0], note: orderNotes.join('; ') };
                }
            }

            const { id, status } = await legacyOrderApi.addOrderItems(
                Number(orderId),
                mappedItems.map(createOrderCreateItemFromBasketItem)
            );
            if (id && status === OperationStatus.PENDING) {
                handleOperation(id.toString());
            }
        } catch (addToOrderError) {
            if (isDefined(addToOrderError?.response)) {
                setError(addToOrderError?.response?.errorMessage);
            } else {
                setError(t('DIALOG_NO_NETWORK_TEXT'));
            }

            setIsLoading(false);
            handleToggleConfirmationDialog();
        }
    }, [
        dispatch,
        handleOperation,
        handleToggleConfirmationDialog,
        logUserEvent,
        mappedItems,
        notes,
        notesFromState,
        orderId,
        t
    ]);

    const handleRetry = React.useCallback(() => {
        if (lastRequest) {
            lastRequest();
            setError('');
            handleToggleConfirmationDialog();
        }
    }, [handleToggleConfirmationDialog, lastRequest]);
    const handleConfirm = React.useCallback(() => {
        if (error) {
            handleRetry();
        }
    }, [error, handleRetry]);
    const handleErrorDialogClose = React.useCallback(() => {
        resetBasket(dispatch);
        legacyOrderApi.resetNonceByKey([EOrderNonceKey.ADD_ITEM_TO_ORDER]);
        push(ROUTES.JOURNEY.LANDING);
    }, [dispatch, push]);

    return (
        <>
            <InnerPageLayout>
                <ViewBillHeader onBack={handleClickBack} title={t('MPO_BASKET_SCREEN_TITLE')} />
                <Box marginTop={2}>
                    <ViewBillHeaderInfo />
                </Box>
                {isLoading || isMenuLoading ? (
                    <InnerPageLayoutContent>
                        <Box height="100%" display="flex" flexDirection="column">
                            <Throbber text={t('GENERAL_LOADING')} />
                        </Box>
                    </InnerPageLayoutContent>
                ) : (
                    [
                        <InnerPageLayoutContent key="content">
                            <ViewBillContentSection bottomSpacing={2}>
                                {mappedItems.length > 0 && mappedItems.map(renderOrderItem)}
                            </ViewBillContentSection>
                            <ViewBillCheckoutNoteList />
                        </InnerPageLayoutContent>,
                        <InnerPageLayoutBottom key="bottom">
                            <Box marginTop={-1} marginBottom={-2}>
                                <LoadingButton
                                    disabled={!isWorkingHour}
                                    color="primary"
                                    variant="contained"
                                    className={classes.viewBasketButton}
                                    onClick={handleAddOrder}
                                    fullWidth
                                    data-cy="pat-basket-submit"
                                >
                                    {t('MPO_BASKET_SUBMIT_BTN')}
                                </LoadingButton>
                            </Box>
                        </InnerPageLayoutBottom>
                    ]
                )}
            </InnerPageLayout>
            <BottomDialog open={quantitySelectProductIndex !== -1} onClose={handleCloseQuantitySelect}>
                <Box width="100%" height="100%" display="flex" alignItems="center" flexDirection="column">
                    <QuantitySelector min={0} max={999} quantity={quantity} onChange={setQuantity} />

                    <Button fullWidth variant="contained" color="primary" onClick={handleUpdateItemQuantity}>
                        {quantity === 0 ? t('BASKET_REMOVE_ITEM') : t('PREORDER_UPDATE_BASKET_QUANTITY')}
                    </Button>
                </Box>
            </BottomDialog>
            <ConfirmDialog
                open={isConfirmationOpen}
                disableBackdropClick
                disableEscapeKeyDown
                onClose={handleErrorDialogClose}
                confirmMessage={t('RETRY')}
                onConfirm={handleConfirm}
                description={t('DIALOG_ORDER_STATUS_UNKNOWN')}
            />
        </>
    );
};
