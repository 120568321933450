import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
    alpha,
    Box,
    Button,
    createStyles,
    IconButton,
    Link,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import HourGlass from '@material-ui/icons/HourglassEmptyOutlined';
import smartlookClient from 'smartlook-client';
import { useQuery } from 'src/hooks/useQuery';
import {
    IAdjustmentReadResourceV10,
    IItemReadResourceV10,
    OrderAdjustmentType
} from 'components/basket/model/Basket';
import { OrderToTableLocationPicker } from 'components/location/OrderToTableLocationPicker';
import { IOperation } from 'components/order/model/Operation';
import {
    IOrderPaymentReadResource,
    IOrderReadResourceV12,
    OrderPaymentType
} from 'components/order/model/Order';
import { EOrderNonceKey, legacyOrderApi } from 'components/order/orderApi';
import { getLocalMerchantId } from 'components/settings/localStore';
import { OPERATION_POLLING_DELAY } from 'config/constants';
import { ConfirmDialog } from 'lib/ConfirmDialog';
import { addOpacity, createUrlParams, roundToDecimal } from 'lib/helpers';
import { EmptyBillIcon } from 'lib/icons/EmptyBill';
import { InnerPageLayout } from 'lib/InnerPageLayout';
import { InnerPageLayoutBottom } from 'lib/InnerPageLayout/InnerPageLayoutBottom';
import { InnerPageLayoutContent } from 'lib/InnerPageLayout/InnerPageLayoutContent';
import { Throbber } from 'lib/Throbber';
import { useAuth } from 'lib/useAuth';
import { useCurrencyString } from 'lib/useCurrencyString';
import { useLocalHistory } from 'lib/useLocalHistory';
import { LocationRouteParams, ROUTES } from 'pages/routes';
import { setBasket } from 'store/basket/basketActions';
import { ApplicationState } from 'store/store';
import { ViewBillCheckout } from './Checkout';
import { SplitByItem } from './SplitByItem';
import { SplitByPercentage } from './SplitByPercentage';
import { ViewBillContentSection } from './ViewBillContentSection';
import { ViewBillHeader } from './ViewBillHeader';
import { ViewBillHeaderInfo } from './ViewBillHeaderInfo';
import { ViewBillOrderItem } from './ViewBillOrderItem';
import { EGAEventName, useGAHelpers } from 'lib/useGAHelpers';
import { useSnackbar } from 'notistack';

interface IProps {
    orderDetails: IOrderReadResourceV12;
    onRewardSelected: (reward: IProps['selectedReward'] | undefined) => void;
    onClaimItems: React.Dispatch<React.SetStateAction<number[]>>;
    selectedReward: IAdjustmentReadResourceV10 | undefined;
    userSelectedTipValue: number | undefined;
    isLoading: boolean;
    onApplyGiftCard: (giftCardNumber: string | undefined) => void;
    giftCard: string | undefined;
    onTipChange: (tipValue: number, selectorValue?: number) => void;
    tipValue: number;
    resetOrderCustomizations: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paymentUser: {
            color: addOpacity(theme.palette.text.primary, 0.6)
        },
        boldText: {
            fontWeight: theme.typography.fontWeightBold
        },
        emptyItemsText: {
            whiteSpace: 'break-spaces'
        },
        addMoreButton: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            zIndex: 3,
            boxShadow: theme.shadows[3],
            '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.8),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: 'transparent'
                }
            }
        },
        emptyIcon: {
            stroke: theme.palette.text.primary
        },
        icon: {
            fontSize: theme.spacing(7)
        },
        successAlertBody: {
            whiteSpace: 'pre-wrap'
        },
        pageContainer: {
            display: 'flex',
            height: '100%',
            overflowY: 'hidden',
            paddingBottom: theme.spacing(7),
            alignItems: 'center',
            flexDirection: 'column'
        }
    })
);

export const ViewBill: React.FC<IProps> = ({
    orderDetails,
    onClaimItems,
    onRewardSelected,
    selectedReward,
    userSelectedTipValue,
    isLoading,
    onApplyGiftCard,
    giftCard,
    onTipChange,
    tipValue,
    resetOrderCustomizations
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { push, history } = useLocalHistory();
    const { pathname, state: locationState } = useLocation<{ showAddItemSuccess: boolean }>();
    const basket = useSelector((state: ApplicationState) => state.basket);
    const dispatch = useDispatch();
    const getCurrencyString = useCurrencyString();
    const isCheckingOutParam = useQuery('isCheckingOut');
    const { locationId } = useParams<LocationRouteParams>();
    const { enqueueSnackbar } = useSnackbar();
    const [isSmartlookSet, setIsSmartlookSet] = React.useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(
        () => !!locationState && !!locationState.showAddItemSuccess
    );
    const [isSplittingLoading, setIsSplittingLoading] = React.useState(false);
    const { user } = useAuth();

    React.useEffect(() => {
        if (locationState && locationState.showAddItemSuccess) {
            history.replace({ state: { showAddItemSuccess: false } });
        }
    }, [history, locationState]);

    const handleToggleConfirmationDialog = React.useCallback(
        () => setIsConfirmationOpen(!isConfirmationOpen),
        [isConfirmationOpen]
    );
    const orderItems = React.useMemo(() => {
        if (Array.isArray(orderDetails.items)) {
            return orderDetails.items.filter(item => !item.deleted);
        }
        return [];
    }, [orderDetails.items]);
    const isCheckingOut = React.useMemo(() => !!isCheckingOutParam, [isCheckingOutParam]);
    const filteredAdjustments = React.useMemo(
        () => orderDetails.adjustments.filter(adj => adj.type !== OrderAdjustmentType.TIP && !adj.deleted),
        [orderDetails.adjustments]
    );

    const { currentLocation } = useSelector((state: ApplicationState) => state.locations);
    const { checkId } = useSelector((state: ApplicationState) => state.basket);
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const isPayAtTableEnabled = React.useMemo(
        () => currentLocation && currentLocation.services.pay_at_table,
        [currentLocation]
    );
    const payments = React.useMemo(
        () =>
            orderDetails.payments.reduce<{ card: number; cash: number }>(
                (init, { type, amount }) => {
                    if (type === OrderPaymentType.CASH_AT_POS || type === OrderPaymentType.CASH) {
                        init.cash += amount || 0;
                    } else {
                        init.card += amount || 0;
                    }

                    return init;
                },
                { cash: 0, card: 0 }
            ),
        [orderDetails.payments]
    );
    const appPayments = React.useMemo(
        () =>
            orderDetails.payments
                .filter(
                    payment =>
                        !!payment.amount &&
                        payment.amount > 0 &&
                        (payment.type === OrderPaymentType.CARD_ON_FILE ||
                            payment.type === OrderPaymentType.APPLEPAY ||
                            payment.type === OrderPaymentType.GOOGLEPAY ||
                            payment.type === OrderPaymentType.GIFT_CARD)
                )
                .map(payment => {
                    const clonedPayment = { ...payment };
                    const tip = orderDetails.adjustments.find(
                        adj => adj.type === OrderAdjustmentType.TIP && adj.paymentId === payment.id
                    );
                    if (tip && clonedPayment.amount) {
                        clonedPayment.amount = clonedPayment.amount - tip.value;
                    }

                    return clonedPayment;
                })
                .filter(payment => !!payment.amount),
        [orderDetails.adjustments, orderDetails.payments]
    );
    const renderAdjustment = React.useCallback(
        (adj: IAdjustmentReadResourceV10) => {
            const value = !!adj.quantity ? adj.quantity * adj.value : adj.value;

            return (
                <Box key={adj.id} display="flex" justifyContent="space-between">
                    <Typography>{adj.description}</Typography>
                    <Typography>{getCurrencyString(value)}</Typography>
                </Box>
            );
        },
        [getCurrencyString]
    );
    const renderOrderItem = React.useCallback(
        (item: IItemReadResourceV10) => (
            <ViewBillOrderItem key={`bill-item-${item.id}`} orderItem={item} allItems={orderItems} />
        ),
        [orderItems]
    );
    const handleBackClick = React.useCallback(() => push(ROUTES.JOURNEY.LANDING), [push]);
    const handlePayMyBill = React.useCallback(() => {
        push(pathname, {}, '?isCheckingOut=true');
    }, [pathname, push]);
    const handleCheckoutBack = React.useCallback(() => {
        push(pathname);
        onRewardSelected(undefined);
    }, [onRewardSelected, pathname, push]);
    const isSplitByItemOpen = useQuery('isSplitByItemOpen');

    const [isSplitByPercentageOpen, setIsSplitByPercentageOpen] = React.useState(false);
    const handleSplitByPercentageDialog = React.useCallback(() => {
        if (settings?.billSplittingByItemEnabled) {
            push(pathname, {}, 'isSplitByItemOpen=true');
        } else if (settings?.billSplittingByPercentageEnabled) {
            setIsSplitByPercentageOpen(!isSplitByPercentageOpen);
        }
    }, [
        isSplitByPercentageOpen,
        settings?.billSplittingByItemEnabled,
        settings?.billSplittingByPercentageEnabled,
        pathname,
        push
    ]);
    const handleSplittingOperation = React.useCallback(
        (splitting: { splitPart: number; splitNumber?: number }) => (res: IOperation) => {
            switch (res.status) {
                case 'PENDING':
                    if (res.id) {
                        setTimeout(() => {
                            legacyOrderApi
                                .getOperation(String(res.id))
                                .then(handleSplittingOperation(splitting));
                        }, OPERATION_POLLING_DELAY);
                    }
                    break;
                case 'DONE':
                    legacyOrderApi.resetNonceByKey([EOrderNonceKey.UPDATE_ORDER]);
                    push(
                        ROUTES.JOURNEY.PAT.BILL,
                        {},
                        createUrlParams({
                            isCheckingOut: 'true',
                            splitPart: splitting.splitPart.toString()
                        })
                    );
                    setIsSplittingLoading(false);
                    break;
                case 'ERROR':
                case 'TIMEOUT':
                    enqueueSnackbar(t('GENERAL_ERROR_PROCESSING_REQUEST'), { variant: 'error' });
                    setIsSplittingLoading(false);
                    legacyOrderApi.resetNonceByKey([EOrderNonceKey.UPDATE_ORDER]);
                    break;
                default:
                    break;
            }
        },
        [enqueueSnackbar, push, t]
    );
    const handleSplitting = React.useCallback(
        (splitting: { splitPart: number; splitNumber?: number }) => {
            if (!!orderDetails.splitNumber) {
                push(
                    ROUTES.JOURNEY.PAT.BILL,
                    {},
                    createUrlParams({ isCheckingOut: 'true', splitPart: splitting.splitPart.toString() })
                );
            } else if (splitting.splitNumber) {
                setIsSplittingLoading(true);
                setIsSplitByPercentageOpen(false);
                legacyOrderApi
                    .updateOrder(orderDetails.id, { splitNumber: splitting.splitNumber })
                    .then(handleSplittingOperation(splitting));
            }
        },
        [handleSplittingOperation, orderDetails.id, orderDetails.splitNumber, push]
    );
    const renderPayment = React.useCallback(
        (payment: IOrderPaymentReadResource) => {
            const userData = orderDetails.users.find(joinedUser => joinedUser.userId === payment.userId);
            let translationKey: string;

            switch (payment.type) {
                case OrderPaymentType.GOOGLEPAY:
                    translationKey = 'PAYMENT_TYPE_GOOGLE_PAY';
                    break;
                case OrderPaymentType.APPLEPAY:
                    translationKey = 'PAYMENT_TYPE_APPLE_PAY';
                    break;
                case OrderPaymentType.CASH:
                case OrderPaymentType.CASH_AT_POS:
                    translationKey = 'PAYMENT_TYPE_CASH_AT_POS';
                    break;
                case OrderPaymentType.CARD_ON_FILE:
                    translationKey = 'PAYMENT_TYPE_CARD_IN_APP';
                    break;
                case OrderPaymentType.GIFT_CARD:
                    translationKey = 'PAYMENT_TYPE_GIFT_CARD';
                    break;
                case OrderPaymentType.CARD_AT_POS:
                case OrderPaymentType.CARD:
                    translationKey = 'PAYMENT_TYPE_CARD_AT_POS';
                    break;
                default:
                    translationKey = 'PAYMENT_TYPE_OTHER';
                    break;
            }

            return (
                userData && (
                    <Box
                        key={payment.id}
                        marginBottom={1}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box>
                            <Typography>{t(translationKey)}</Typography>
                            <Typography
                                className={classes.paymentUser}
                                variant="caption"
                            >{`${userData?.firstName} ${userData?.lastName}`}</Typography>
                        </Box>
                        {payment.amount !== null && (
                            <Typography>{getCurrencyString(Number(payment.amount))}</Typography>
                        )}
                    </Box>
                )
            );
        },
        [classes.paymentUser, getCurrencyString, orderDetails.users, t]
    );
    const userTips = React.useMemo(() => {
        const tipsAmount = orderDetails.adjustments.reduce((acc, item) => {
            if (item.type === OrderAdjustmentType.TIP && user?.id === item.userId) {
                acc += item.value;
            }

            return acc;
        }, 0);
        return roundToDecimal(tipsAmount);
    }, [orderDetails.adjustments, user?.id]);
    const handleLeaveOrder = React.useCallback(
        () => legacyOrderApi.leaveOrder(orderDetails.id),
        [orderDetails.id]
    );

    const renderTableAndCheckNumberPicker = React.useCallback(
        (onEdit: () => void) => {
            if (
                settings?.payAtTable?.retrievalBy !== 'BOTH' &&
                settings?.payAtTable?.retrievalBy !== 'CHECK_NUMBER'
            ) {
                return null;
            }
            return (
                <ViewBillContentSection bottomSpacing={2}>
                    <Box display="flex" justifyContent="space-between">
                        <Typography>
                            {t('BILL_CHECK_ID', { checkNumber: checkId })}
                            {orderDetails.checkId}
                        </Typography>
                        <Typography>
                            <Link onClick={onEdit}>{t('GENERAL_EDIT')}</Link>
                        </Typography>
                    </Box>
                </ViewBillContentSection>
            );
        },
        [checkId, settings?.payAtTable?.retrievalBy, orderDetails.checkId, t]
    );
    const { logUserEvent } = useGAHelpers();
    const handleAddMore = React.useCallback(() => {
        logUserEvent(EGAEventName.AddMoreItems);
        setBasket({ ...basket })(dispatch);
        push(ROUTES.MENU, {}, `orderId=${orderDetails.id}`);
    }, [basket, dispatch, logUserEvent, orderDetails.id, push]);

    React.useEffect(() => {
        if (settings?.smartlookEnabled) {
            if (!isSmartlookSet && settings?.orderToTable.multiPartEnabled && orderItems.length === 0) {
                smartlookClient.track('mpo_empty_bill', { merchantId: getLocalMerchantId() ?? '' });
                setIsSmartlookSet(true);
            }
        }
    }, [
        settings?.orderToTable?.multiPartEnabled,
        orderItems.length,
        settings?.smartlookEnabled,
        isSmartlookSet
    ]);

    React.useEffect(() => {
        if (!isCheckingOut) {
            resetOrderCustomizations();
        }
    }, [isCheckingOut, resetOrderCustomizations]);

    return !isCheckingOut ? (
        <>
            <InnerPageLayout disabled={!isPayAtTableEnabled}>
                <ViewBillHeader
                    onAuthActionClick={handleLeaveOrder}
                    title={t('VIEW_BILL_SCREEN_TITLE')}
                    onBack={handleBackClick}
                />
                <Box paddingTop={2}>
                    <ViewBillHeaderInfo
                        infoVisible
                        users={orderDetails.users}
                        pin={orderDetails.pin}
                        passphrase={orderDetails.passphrase}
                    />
                </Box>
                {isSplittingLoading && (
                    <Box className={classes.pageContainer}>
                        <Throbber text={t('GENERAL_LOADING')} />
                    </Box>
                )}
                {settings?.orderToTable.multiPartEnabled && orderItems.length > 0 && (
                    <Box marginBottom={-5} display="flex" paddingX={2} justifyContent="flex-end">
                        <IconButton
                            onClick={handleAddMore}
                            color="primary"
                            classes={{ colorPrimary: classes.addMoreButton }}
                            data-cy="add-items-icon"
                        >
                            <AddIcon />
                        </IconButton>
                    </Box>
                )}
                {!isSplittingLoading && (
                    <InnerPageLayoutContent>
                        <OrderToTableLocationPicker
                            pickerTitle="location"
                            locationId={locationId}
                            checkSelectionEnabled
                            renderPickerView={renderTableAndCheckNumberPicker}
                        />
                        {orderItems.length > 0 && (
                            <>
                                <ViewBillContentSection bottomSpacing={2}>
                                    {orderItems.length > 0 && orderItems.map(renderOrderItem)}
                                </ViewBillContentSection>
                                {filteredAdjustments.length > 0 && (
                                    <ViewBillContentSection
                                        bottomSpacing={2}
                                        title={t('VIEW_BILL_ADJUSTMENTS_LABEL')}
                                    >
                                        {filteredAdjustments.map(renderAdjustment)}
                                    </ViewBillContentSection>
                                )}
                                {(payments.cash > 0 || payments.card > 0) && (
                                    <ViewBillContentSection
                                        bottomSpacing={2}
                                        title={t('VIEW_BILL_PAYMENTS_LABEL')}
                                    >
                                        {payments.cash > 0 && (
                                            <Box
                                                marginBottom={payments.card > 0 && 1}
                                                display="flex"
                                                justifyContent="space-between"
                                            >
                                                <Typography>{t('PAYMENT_TYPE_CASH_AT_POS')}</Typography>
                                                <Typography>{getCurrencyString(payments.cash)}</Typography>
                                            </Box>
                                        )}
                                        {payments.card > 0 && appPayments.map(renderPayment)}
                                    </ViewBillContentSection>
                                )}
                                {userTips > 0 && (
                                    <ViewBillContentSection title={t('VIEW_BILL_TIPS_LABEL')}>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography>{t('VIEW_BILL_TIPS_TOTAL')}</Typography>
                                            <Typography>{getCurrencyString(userTips)}</Typography>
                                        </Box>
                                    </ViewBillContentSection>
                                )}
                            </>
                        )}
                        {orderItems.length === 0 && !settings?.orderToTable.multiPartEnabled && (
                            <Box paddingTop={2}>
                                <Typography align="center" variant="body2" className={classes.emptyItemsText}>
                                    {t('EMPTY_TAB_PLACEHOLDER_BODY_2_1')}
                                </Typography>
                                <Typography align="center" variant="body2" className={classes.emptyItemsText}>
                                    {t('EMPTY_TAB_PLACEHOLDER_BODY_2_2')}
                                </Typography>
                            </Box>
                        )}
                        {orderItems.length === 0 && settings?.orderToTable.multiPartEnabled && (
                            <Box
                                paddingTop={2}
                                height="100%"
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box marginBottom={1.5}>
                                    <EmptyBillIcon className={classes.emptyIcon} />
                                </Box>
                                <Typography align="center" className={classes.boldText} variant="body1">
                                    {t('VIEW_BILL_MPO_PLACEHOLDER_TITLE')}
                                </Typography>
                                <Typography align="center" variant="body2">
                                    {t('VIEW_BILL_MPO_PLACEHOLDER_BODY')}
                                </Typography>
                                <Box marginTop={2}>
                                    <IconButton
                                        onClick={handleAddMore}
                                        color="primary"
                                        classes={{ colorPrimary: classes.addMoreButton }}
                                    >
                                        <AddIcon fontSize="large" />
                                    </IconButton>
                                </Box>
                            </Box>
                        )}
                    </InnerPageLayoutContent>
                )}
                {isPayAtTableEnabled && !isSplittingLoading && !!orderItems.length && (
                    <InnerPageLayoutBottom>
                        {(settings?.billSplittingByPercentageEnabled ||
                            settings?.billSplittingByItemEnabled) && (
                            <Button
                                onClick={handleSplitByPercentageDialog}
                                color="primary"
                                variant="contained"
                                fullWidth
                                data-cy="pat-split-button"
                            >
                                {t('VIEW_BILL_SPLIT_BTN')}
                            </Button>
                        )}
                        <Box marginTop={1}>
                            <Button
                                onClick={handlePayMyBill}
                                color="primary"
                                variant="contained"
                                fullWidth
                                data-cy="pat-pay-button"
                            >
                                {t('VIEW_BILL_PAY_BTN', { amount: orderDetails.total.balance })}
                            </Button>
                        </Box>
                    </InnerPageLayoutBottom>
                )}
            </InnerPageLayout>
            {settings?.billSplittingByPercentageEnabled && !isSplittingLoading && (
                <SplitByPercentage
                    isOpen={isSplitByPercentageOpen}
                    onToggle={handleSplitByPercentageDialog}
                    order={orderDetails}
                    onSubmit={handleSplitting}
                />
            )}
            {settings?.billSplittingByItemEnabled && !isSplittingLoading && (
                <SplitByItem
                    users={orderDetails.users}
                    payments={orderDetails.payments}
                    items={orderItems}
                    orderDetails={orderDetails}
                    isOpen={!!isSplitByItemOpen}
                    onClaimItems={onClaimItems}
                />
            )}
            {!isSplittingLoading && (
                <ConfirmDialog
                    title={t('DIALOG_MPO_SUCCESS_TITLE')}
                    open={isConfirmationOpen}
                    disableBackdropClick
                    disableEscapeKeyDown
                    description={
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Box marginBottom={1.5}>
                                <HourGlass className={classes.icon} />
                            </Box>
                            <Typography className={classes.successAlertBody} align="center" variant="body1">
                                {t('DIALOG_MPO_SUCCESS_BODY')}
                            </Typography>
                        </Box>
                    }
                    onClose={handleToggleConfirmationDialog}
                    confirmMessage={t('DIALOG_MPO_SUCCESS_OK')}
                    isCancellable={false}
                    onConfirm={handleToggleConfirmationDialog}
                />
            )}
        </>
    ) : (
        <ViewBillCheckout
            onRewardSelected={onRewardSelected}
            selectedReward={selectedReward}
            orderDetails={orderDetails}
            onClickBack={handleCheckoutBack}
            onAuthActionClick={handleLeaveOrder}
            userSelectedTipValue={userSelectedTipValue}
            isLoading={isLoading}
            onApplyGiftCard={onApplyGiftCard}
            giftCard={giftCard}
            onTipChange={onTipChange}
            tipValue={tipValue}
        />
    );
};
