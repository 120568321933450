import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, TextField } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { ViewBillContentSection } from 'components/bill/ui/ViewBill/ViewBillContentSection';

interface IProps {
    value?: string;
    onChange: (value: string) => void;
    error?: string;
}

export const ViewBillCheckoutPhoneNumber = React.forwardRef<HTMLDivElement, IProps>(
    ({ error, onChange, value = '' }, ref) => {
        const { t } = useTranslation();

        const handleClearMouseDown = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
        }, []);
        const handleChange = React.useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                onChange(e.target.value.replace(/[^0-9+]/g, ''));
            },
            [onChange]
        );
        const handleClear = React.useCallback(() => {
            onChange('');
        }, [onChange]);

        return (
            <ViewBillContentSection
                title={t('BILL_CHECKOUT_PHONE_NUMBER_TITLE')}
                subtitle={t('BILL_CHECKOUT_PHONE_NUMBER_BODY')}
                bottomSpacing={2}
            >
                <TextField
                    type="tel"
                    innerRef={ref}
                    fullWidth
                    value={value}
                    onChange={handleChange}
                    error={!!error}
                    label={t('BILL_CHECKOUT_PHONE_NUMBER_HINT')}
                    helperText={!!error && error}
                    color="secondary"
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                size="small"
                                tabIndex={-1}
                                onMouseDown={handleClearMouseDown}
                                onClick={handleClear}
                            >
                                <Close />
                            </IconButton>
                        )
                    }}
                />
            </ViewBillContentSection>
        );
    }
);

ViewBillCheckoutPhoneNumber.displayName = 'ViewBillCheckoutPhoneNumber';
