import { Box, Button, Theme, createStyles, makeStyles } from '@material-ui/core';
import { IPaymentOptionValue } from 'components/bill/ui/ViewBill/Checkout/PaymentMethod/PaymentMethodDialog';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { OrderPaymentType } from 'components/order/model/Order';
import { CONTENT_MAX_WIDTH } from 'config/constants';
import { useCurrencyString } from 'lib/useCurrencyString';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InView from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GooglePayPaymentDetails } from 'src/integrations/GooglePay';
import { ApplePayPaymentDetails } from 'src/integrations/PaymentProvider/ApplePayButton';
import { DigitalWallets } from 'src/integrations/PaymentProvider/DigitalWallets';
import { IntegratedDigitalWallets } from 'src/integrations/PaymentProvider/IntegratedDigitalWallets';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/LoadingButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { EGAEventName, isSweetwaters, useGAHelpers } from 'lib/useGAHelpers';
import { LocationRouteParams } from 'pages/routes';
import { useQuery } from 'src/hooks/useQuery';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        viewBasketButton: {
            padding: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        indicator: {
            position: 'fixed',
            left: '50%',
            bottom: 0,
            right: 0,
            textAlign: 'center',
            background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.5) 100%)',
            transform: 'translateX(-50%)',
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            maxWidth: `${CONTENT_MAX_WIDTH}px`,
            width: '100%'
        },
        notReaddyToPayButton: {
            borderRadius: theme.shape.borderRadius,
            marginBottom: theme.spacing(1)
        }
    })
);

interface ViewBasketPayButtonProps {
    totalToPay: number;
    selectedPaymentOption: IPaymentOptionValue;
    readyToPay?: boolean;
    onIntegratedWalletsPay: (
        data: GooglePayPaymentDetails | ApplePayPaymentDetails,
        orderPaymentType: OrderPaymentType
    ) => void;
    onWalletsPay: (cardNonce: string, orderPaymentType: OrderPaymentType) => void;
    onCardPay: () => void;
    loading?: boolean; // isPlacingOrder
    validate?: () => void;
}

export const ViewBasketPayButton: React.FC<ViewBasketPayButtonProps> = ({
    totalToPay,
    selectedPaymentOption,
    readyToPay,
    onIntegratedWalletsPay,
    onWalletsPay,
    onCardPay,
    validate,
    loading
}) => {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { t } = useTranslation();
    const classes = useStyles();
    const errorParams = useQuery('error');
    const getCurrencyString = useCurrencyString();
    const { isWorkingHour } = useLocationHelpers();
    const [indicatorVisibility, setIndicatorVisibility] = React.useState(true);
    const lastItemRef = React.useRef<HTMLDivElement>(null);
    const { merchantId } = useParams<LocationRouteParams>();
    const { logUserEvent } = useGAHelpers();
    const handleIndicatorClick = React.useCallback(() => {
        if (lastItemRef.current) {
            lastItemRef.current.scrollIntoView();
        }
    }, []);
    const handleWalletsPay = React.useCallback(
        (cardNonce: string, walletType: OrderPaymentType) => {
            if (isSweetwaters(merchantId)) {
                logUserEvent(EGAEventName.SubmitOrderSweetwaters);
            }
            onWalletsPay(cardNonce, walletType);
        },
        [logUserEvent, merchantId, onWalletsPay]
    );
    const handleCardPay = React.useCallback(() => {
        if (isSweetwaters(merchantId)) {
            logUserEvent(EGAEventName.SubmitOrderSweetwaters);
        }
        onCardPay();
    }, [logUserEvent, merchantId, onCardPay]);
    return (
        <>
            <Box marginTop="auto" paddingTop={1} marginBottom={-1}>
                {!!readyToPay && (
                    <React.Fragment>
                        {selectedPaymentOption.type !== OrderPaymentType.CARD_ON_FILE && (
                            <React.Fragment>
                                {(settings?.paymentProvider === 'JUDOPAY' ||
                                    settings?.paymentProvider === 'WORLDPAY') && (
                                    <IntegratedDigitalWallets
                                        total={totalToPay}
                                        onPay={onIntegratedWalletsPay}
                                        selectedWallet={selectedPaymentOption.type}
                                    />
                                )}
                                {settings?.paymentProvider !== 'JUDOPAY' &&
                                    settings?.paymentProvider !== 'WORLDPAY' && (
                                        <DigitalWallets
                                            total={totalToPay}
                                            onDigitalPaymentMade={handleWalletsPay}
                                            selectedWallet={selectedPaymentOption.type}
                                            disabled={errorParams === 'timeslot'}
                                        >
                                            {t('PREORDER_BASKET_SUBMIT', {
                                                total: getCurrencyString(totalToPay)
                                            })}
                                        </DigitalWallets>
                                    )}
                            </React.Fragment>
                        )}
                        {selectedPaymentOption.type === OrderPaymentType.CARD_ON_FILE && (
                            <div ref={lastItemRef}>
                                <LoadingButton
                                    disabled={!isWorkingHour}
                                    color="primary"
                                    variant="contained"
                                    loading={loading}
                                    onClick={handleCardPay}
                                    className={classes.viewBasketButton}
                                    fullWidth
                                    data-cy="submit"
                                >
                                    {t('PREORDER_BASKET_SUBMIT', {
                                        total: getCurrencyString(totalToPay)
                                    })}
                                </LoadingButton>
                            </div>
                        )}
                    </React.Fragment>
                )}
                {!readyToPay && (
                    <Box boxShadow={1} className={classes.notReaddyToPayButton}>
                        <Button variant="contained" color="primary" fullWidth onClick={validate}>
                            {t('PREORDER_BASKET_SUBMIT', {
                                total: getCurrencyString(totalToPay)
                            })}
                        </Button>
                    </Box>
                )}
                <InView onChange={setIndicatorVisibility}>
                    <Box width="100%" height="2px" />
                </InView>
            </Box>
            {/* If we have no reasons of doing that, just remove CARD_ON_FILE condition here and in the previous section */}
            {!indicatorVisibility && selectedPaymentOption.type === OrderPaymentType.CARD_ON_FILE && (
                <div className={classes.indicator} onClick={handleIndicatorClick}>
                    <ExpandMoreIcon />
                </div>
            )}
        </>
    );
};
