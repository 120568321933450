import React from 'react';
import { ISearchCategory } from 'src/hooks/useMenuSearch';
import { IEnrichedProduct, IProduct, ProductGroup, isProductGroup } from './model/Menu';
import { Box, ListItemText, Theme, makeStyles } from '@material-ui/core';
import { MenuProduct } from './MenuProduct';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

const useStyles = makeStyles((theme: Theme) => ({
    titleCategory: {
        marginBottom: theme.spacing(1),
        fontWeight: 'bold',
        color: theme.palette.titleTextColour
    }
}));

interface MenuSearchCategoryProps {
    category: ISearchCategory;
    onProductSelect: (product: IEnrichedProduct | ProductGroup, categoryId: string) => void;
}

export const MenuSearchCategory: React.FC<MenuSearchCategoryProps> = ({ category, onProductSelect }) => {
    const classes = useStyles();
    const { items } = useSelector((state: ApplicationState) => state.basket);
    const { menu } = useSelector((state: ApplicationState) => state.menu);
    const handleProductSelect = React.useCallback(
        (product: IEnrichedProduct | ProductGroup) => {
            onProductSelect(product, category.id);
        },
        [category.id, onProductSelect]
    );
    const renderProduct = React.useCallback(
        (item: IProduct) => {
            const itemQuantity = items.reduce((acc, basketItem) => {
                if (
                    category.id === basketItem.categoryId &&
                    ((isProductGroup(item) &&
                        item.products.map(product => product.id).includes(basketItem.productId)) ||
                        item.id === basketItem.productId)
                ) {
                    return acc + basketItem.quantity;
                }
                return acc;
            }, 0);
            return (
                <MenuProduct
                    key={item.id}
                    item={item}
                    menu={menu}
                    quantity={itemQuantity}
                    onClick={handleProductSelect}
                />
            );
        },
        [items, menu, handleProductSelect, category.id]
    );
    return (
        <Box>
            <ListItemText
                primaryTypographyProps={{
                    className: classes.titleCategory,
                    variant: 'h5'
                }}
                primary={category.title}
            />
            {category.products.map(renderProduct)}
        </Box>
    );
};
