import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Chip,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import { useCalories } from 'src/hooks/useCalories';
import { addOpacity, toTitleCase } from 'lib/helpers';
import { LoadingImage } from 'lib/LoadingImage';
import { ApplicationState } from 'store/store';
import {
    IEnrichedProduct,
    INutritionMenuEnrichment,
    INutritionMenuEnrichmentFields,
    IProductModifierSettings,
    IProductTagValue
} from './model/Menu';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            marginLeft: theme.spacing(2),
            flex: 1
        },
        card: {
            borderRadius: theme.shape.borderRadius,
            marginBottom: theme.spacing(1),
            border: `1px solid ${addOpacity(theme.palette.text.primary, 0.08)}`
        },
        cardContent: {
            padding: theme.spacing(1)
        },
        description: {
            marginTop: theme.spacing(0)
        },
        allergenTitle: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(0.25),
            color: theme.palette.titleTextColour
        },
        allergenChip: {
            margin: theme.spacing(0.25),
            backgroundColor: theme.palette.text.primary,
            color: theme.palette.background.paper,
            borderRadius: theme.shape.borderRadius ? theme.shape.borderRadius * 2 : 0,
            fontWeight: theme.typography.fontWeightBold
        },
        longDescription: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(0.25)
        },
        tagsTitle: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(0.25),
            fontWeight: 'bold',
            color: theme.palette.titleTextColour
        },
        tagsChip: {
            margin: theme.spacing(0.5),
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            border: `1px solid ${theme.palette.text.primary}`,
            borderRadius: theme.shape.borderRadius ? theme.shape.borderRadius * 2 : 0,
            fontWeight: theme.typography.fontWeightBold,
            height: theme.spacing(4.25),
            padding: `0 ${theme.spacing(0.75)}px`,
            '&:first-child': {
                marginLeft: 0
            }
        },
        showMore: {
            display: 'inline',
            color: theme.palette.text.hint,
            cursor: 'pointer'
        },
        nutritionalTitle: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(0.25),
            fontWeight: 'bold',
            color: theme.palette.titleTextColour
        },
        nutritionalInfoRow: {
            '&:last-child': {
                borderBottom: 'none'
            },
            borderBottom: `1px solid ${addOpacity(theme.palette.text.primary, 0.08)}`,
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: theme.spacing(1, 0)
        },
        tagImageContainer: {
            height: 22,
            width: 22
        },
        tagImage: {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        }
    })
);

interface IMenuProductDetailsProps {
    item: IEnrichedProduct;
    modifiers: IProductModifierSettings[];
    disabledImage?: boolean;
    hasVisibleModifiers?: boolean;
    showModifiers?: boolean;
}

export const MenuProductDetails: React.FC<IMenuProductDetailsProps> = props => {
    const classes = useStyles();
    const { item, disabledImage, hasVisibleModifiers, showModifiers } = props;
    const { menu } = useSelector((state: ApplicationState) => state.menu);
    const { key: energyKey } = useCalories();
    const { t } = useTranslation();

    const nutritionsMatchesWithKey = React.useMemo(() => {
        if (item.nutrition && item.nutrition.length > 0) {
            const result: INutritionMenuEnrichmentFields[] = [];
            item.nutrition
                .map(nutrition => ({
                    ...nutrition,
                    values: nutrition.values.filter(n => n.nutrient === energyKey)
                }))
                .forEach(nutrition => {
                    if (nutrition.values.length > 0) {
                        result.push(nutrition);
                    }
                });
            return result;
        }
        return [];
    }, [energyKey, item]);

    const nutritionHasValueNotMatchesWithKey = React.useCallback(() => {
        let found = false;
        if (item.nutrition && item.nutrition.length > 0) {
            item.nutrition.forEach(nutrition =>
                nutrition.values.some(n => {
                    if (n.nutrient !== energyKey) {
                        found = true;
                    }
                })
            );
        }
        return found;
    }, [item.nutrition, energyKey]);

    const hasMoreInfo = React.useMemo(
        () => item.nutrition.length > 0 || (!!item.tags && item.tags.length > 0) || !!item.longDescription,
        [item.longDescription, item.nutrition.length, item.tags]
    );

    const showButton = React.useMemo(
        () =>
            !nutritionHasValueNotMatchesWithKey() &&
            !!item.tags &&
            item.tags.length === 0 &&
            !!item.longDescription === false,
        [item.longDescription, item.tags, nutritionHasValueNotMatchesWithKey]
    );

    const [showMore, setShowMore] = React.useState(hasMoreInfo && !showModifiers);

    const handleShowMore = React.useCallback(() => setShowMore(!showMore), [showMore]);
    const allergenMap = React.useMemo(() => {
        const kvPairs: [string, string][] = [];
        for (const { id, title } of menu?.options?.allergens || []) {
            kvPairs.push([id, title]);
        }
        return new Map(kvPairs);
    }, [menu?.options?.allergens]);

    const renderAllergenChip = React.useCallback(
        (allergen: string) => (
            <Chip
                key={allergen}
                label={allergenMap.get(allergen) || toTitleCase(allergen)}
                className={classes.allergenChip}
            />
        ),
        [classes.allergenChip, allergenMap]
    );

    const renderTagsChip = React.useCallback(
        (tag: IProductTagValue) => (
            <Box key={tag.id} display="flex" alignItems="center" className={classes.tagsChip}>
                {!!tag.imageUrl && (
                    <Box marginRight={0.75} className={classes.tagImageContainer}>
                        <img className={classes.tagImage} src={tag.imageUrl} alt="" />
                    </Box>
                )}
                <Typography>{tag.title}</Typography>
            </Box>
        ),
        [classes.tagImage, classes.tagImageContainer, classes.tagsChip]
    );

    const renderNutritionalInformationValue = React.useCallback(
        (nutritionalInfoValue: INutritionMenuEnrichment) => (
            <Box className={classes.nutritionalInfoRow} key={nutritionalInfoValue.nutrient}>
                <Typography variant="subtitle1">{nutritionalInfoValue.nutrient}</Typography>
                <Typography variant="subtitle1">{nutritionalInfoValue.value}</Typography>
            </Box>
        ),
        [classes.nutritionalInfoRow]
    );

    const nutritionHasMatchesWithKey = React.useCallback(
        (values: INutritionMenuEnrichment[]) => values.find(n => n.nutrient === energyKey),
        [energyKey]
    );

    const renderNutritions = React.useCallback(
        (nutrition: INutritionMenuEnrichmentFields) => (
            <Box key={nutrition.title}>
                <Typography variant="subtitle1" className={classes.nutritionalTitle}>
                    {nutrition.title}
                </Typography>
                {!!nutritionHasMatchesWithKey(nutrition.values) && (
                    <Typography variant="body1" color="textPrimary">
                        {t('ORDER_CALORIES_ADVISORY_MESSAGE')}
                    </Typography>
                )}

                {nutrition.values.map(renderNutritionalInformationValue)}
            </Box>
        ),
        [classes.nutritionalTitle, nutritionHasMatchesWithKey, renderNutritionalInformationValue, t]
    );
    const isImage = !!item.imageUrl;

    const isProductHasContent = React.useMemo(
        () =>
            !!item.shortDescription ||
            (!disabledImage && isImage) ||
            !!item.allergens ||
            (!hasVisibleModifiers && hasMoreInfo),
        [disabledImage, hasMoreInfo, hasVisibleModifiers, isImage, item.allergens, item.shortDescription]
    );

    if (!isProductHasContent) {
        return null;
    }
    return (
        <Card className={classes.card} elevation={0}>
            {!disabledImage && isImage && <LoadingImage src={item.imageUrl || ''} height={22.5} />}
            <CardContent className={classes.cardContent}>
                {!!item.shortDescription && (
                    <Typography variant="subtitle1" className={classes.description}>
                        {item.shortDescription}
                    </Typography>
                )}
                {!!item.allergens && (
                    <React.Fragment>
                        <Typography variant="subtitle1" className={classes.allergenTitle}>
                            <b>{t('PRODUCT_DETAILS_ALLERGENS_LABEL')}</b>
                        </Typography>
                        <Box display="flex" flexDirection="row" flexWrap="wrap">
                            {item.allergens.map(renderAllergenChip)}
                        </Box>
                    </React.Fragment>
                )}

                {showMore && !!item.tags && item.tags.length > 0 && (
                    <React.Fragment>
                        <Typography variant="subtitle1" className={classes.tagsTitle}>
                            {t('PREORDER_TAGS')}
                        </Typography>
                        <Box display="flex" flexDirection="row" flexWrap="wrap">
                            {item.tags.map(renderTagsChip)}
                        </Box>
                    </React.Fragment>
                )}

                {!showMore && <Box>{nutritionsMatchesWithKey.map(renderNutritions)}</Box>}

                {showMore && item.nutrition.length > 0 && <Box>{item.nutrition.map(renderNutritions)}</Box>}

                {showMore && !!item.longDescription && (
                    <Typography variant="subtitle1" className={classes.longDescription}>
                        {item.longDescription}
                    </Typography>
                )}

                {hasMoreInfo && showModifiers && !showButton && (
                    <CardActions style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
                        <Typography variant="subtitle2" onClick={handleShowMore} className={classes.showMore}>
                            {showMore ? t('COLLAPSE_INFO_BUTTON') : t('PREORDER_MORE_INFORMATION')}
                        </Typography>
                    </CardActions>
                )}
            </CardContent>
        </Card>
    );
};
