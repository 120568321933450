import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, createStyles, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { BottomDialog } from 'lib/BottomDialog';
import { QuantitySelector } from 'lib/QuantitySelector';
import { ISuggestions } from '../model/Menu';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            padding: theme.spacing(1.5, 2)
        },
        title: {
            marginBottom: theme.spacing(0.5),
            fontSize: theme.spacing(2.5),
            fontFamily: `TenantFont, ${theme.typography.fontFamily}`,
            color: theme.palette.titleTextColour
        },
        suggestionBox: {
            marginBottom: theme.spacing(1.5),
            overflow: 'hidden',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius
        },
        descriptionBox: {
            padding: theme.spacing(1.5, 2)
        },
        suggestionDescription: {
            minHeight: '1.5rem'
        },
        imageWrapper: {
            width: '100%',
            display: 'flex'
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        },
        imageHidden: {
            visibility: 'hidden',
            width: 0,
            height: 0,
            padding: 0,
            margin: 0
        },
        quantityBox: {
            marginBottom: theme.spacing(0.5)
        },
        divider: {
            marginBottom: theme.spacing(1.5)
        },
        skeleton: {
            height: '120px',
            width: '100%'
        }
    })
);

interface SuggestionsBogofProps {
    open: boolean;
    onClose: () => void;
    changeQuantity: (newQuantity: number) => void;
    suggestion: ISuggestions;
    quantity: number;
    currency: string;
}

export const MenuSuggestionsBogof: React.FC<SuggestionsBogofProps> = ({
    open,
    onClose,
    changeQuantity,
    suggestion,
    quantity
}) => {
    const { description = '', title, imageUrl } = suggestion;
    const classes = useStyles();
    const { t } = useTranslation();
    const [newQuantity, setNewQuantity] = React.useState(quantity);
    const [imageLoaded, setImageLoaded] = React.useState(false);

    const handleClickNewQuantity = React.useCallback(() => {
        changeQuantity(newQuantity);
    }, [changeQuantity, newQuantity]);

    const handleImageLoaded = React.useCallback(() => {
        setImageLoaded(true);
    }, []);

    const suggestionDescription = React.useMemo(
        () => (description ? description.split('\n') : []),
        [description]
    );

    const renderProduct = React.useCallback(
        (item, index) => (
            <Typography
                key={`suggestion-description-${index}`}
                className={classes.suggestionDescription}
                align="center"
            >
                {item}
            </Typography>
        ),
        [classes.suggestionDescription]
    );
    return (
        <BottomDialog open={open} onClose={onClose}>
            <Box className={classes.content}>
                <Typography className={classes.title} align="center">
                    {title ? title : t('RELATED_PRODUCT_ADD_TITLE')}
                </Typography>
                <Divider className={classes.divider} />
                <Box className={classes.suggestionBox}>
                    {imageUrl && (
                        <>
                            <Box
                                className={clsx(classes.imageWrapper, {
                                    [classes.imageHidden]: !imageLoaded
                                })}
                            >
                                <img className={classes.image} src={imageUrl} onLoad={handleImageLoaded} />
                            </Box>
                            {!imageLoaded && <Skeleton className={classes.skeleton} variant="rect" />}
                        </>
                    )}
                    {suggestionDescription.length > 0 && (
                        <Box className={classes.descriptionBox}>
                            {suggestionDescription.map(renderProduct)}
                        </Box>
                    )}
                </Box>
                <Box
                    className={classes.quantityBox}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <QuantitySelector min={1} max={999} quantity={newQuantity} onChange={setNewQuantity} />
                </Box>
                <Button color="primary" variant="contained" onClick={handleClickNewQuantity} fullWidth>
                    {t('RELATED_PRODUCT_BOGOF_ACCEPT')}
                </Button>
            </Box>
        </BottomDialog>
    );
};
