import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { EOperatingSystem, getOperatingSystem } from 'src/utils/device';
import { OrderPaymentType } from 'components/order/model/Order';
import { ApplicationState } from 'store/store';
import { ApplePayButton, ApplePayPaymentDetails } from './ApplePayButton';
import { MuiGooglePayButton } from './MuiGooglePayButton';
import { GooglePayPaymentDetails } from '../GooglePay';

interface IntegratedDigitalWalletsProps {
    total: number;
    onPay: (
        data: GooglePayPaymentDetails | ApplePayPaymentDetails,
        orderPaymentType: OrderPaymentType
    ) => void;
    selectedWallet?: OrderPaymentType;
}

export const IntegratedDigitalWallets: React.FC<IntegratedDigitalWalletsProps> = ({
    total,
    selectedWallet,
    onPay
}) => {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const os = React.useMemo(() => getOperatingSystem(), []);
    const handleGooglePay = React.useCallback(
        (data: GooglePayPaymentDetails) => {
            onPay(data, OrderPaymentType.GOOGLEPAY);
        },
        [onPay]
    );
    const handleApplePay = React.useCallback(
        (data: ApplePayPaymentDetails) => {
            onPay(data, OrderPaymentType.APPLEPAY);
        },
        [onPay]
    );
    if (!settings || (!settings.googlePayEnabled && !settings.applePayEnabled)) {
        return null;
    }
    return (
        <React.Fragment>
            {(selectedWallet === undefined ||
                (selectedWallet !== undefined && selectedWallet === OrderPaymentType.GOOGLEPAY)) &&
                settings.googlePayEnabled &&
                os !== EOperatingSystem.IOS && (
                    <Grid item xs={12}>
                        <MuiGooglePayButton price={total} onLoad={handleGooglePay} />
                    </Grid>
                )}
            {(selectedWallet === undefined ||
                (selectedWallet !== undefined && selectedWallet === OrderPaymentType.APPLEPAY)) &&
                settings.applePayEnabled &&
                (os === EOperatingSystem.IOS || os === EOperatingSystem.MAC) && (
                    <Grid item xs={12}>
                        <ApplePayButton price={total} onLoad={handleApplePay} />
                    </Grid>
                )}
        </React.Fragment>
    );
};
