import { IAppSetting, IIamSetting, IPublicSettings } from '@pepperhq/location-sdk';
import { EActionType } from 'components/activity/enums';
import { OrderScenario } from 'components/order/model/Order';
import logger from 'lib/logger';
import { isString } from 'lib/typeInference';
import { ETipScheme } from '../enums';
import { ELoyaltyProvider } from 'components/awards/enums';

export enum EPaymentProvider {
    JUDOPAY = 'JUDOPAY',
    SQUARE = 'SQUARE',
    STRIPE = 'STRIPE',
    WORLDPAY = 'WORLDPAY'
}

interface IdentityItem {
    enabled: boolean;
}

export interface ManifestIdentity {
    email: IdentityItem;
    facebook: IdentityItem;
    phone: IdentityItem;
    uniqueDeviceVerification: IdentityItem;
}

interface ManifestRegion {
    code: string;
    currencyCode: 'GBP';
    currencySymbol: '£';
    distanceUnit: 'mi';
    phoneNumberCode: 'GB';
    taxLabel: 'VAT';
    timezone: 'Europe/London';
}

export interface ITip {
    default: number;
    values: number[];
    scheme: ETipScheme;
}

interface INotificationSetting {
    email: boolean;
    sms: boolean;
    push: boolean;
}

export interface ISettings {
    app: IAppSetting;
    actionNotifications: Record<
        EActionType.ORDER_USER_BILLED | EActionType.ORDER_COMPLETED,
        INotificationSetting
    >;
    distanceUnit: 'mi' | 'km';
    uniqueDeviceVerificationEnabled: boolean;
    billSplittingByItemEnabled: boolean;
    billSplittingByPercentageEnabled: boolean;
    tippingEnabled: boolean;
    tippingPreorderEnabled: boolean;
    tippingOrderToTableEnabled: boolean;
    tippingPayAtTableEnabled: boolean;
    tippingTabEnabled: boolean;
    preOrderEnabled: boolean;
    posProvider: string;
    phoneAuthEnabled: boolean;
    paymentProvider: string;
    orderToTableEnabled: boolean;
    payAtTableEnabled: boolean;
    multipleLocationsEnabled: boolean;
    googlePayEnabled: boolean;
    emailAuthEnabled: boolean;
    applePayEnabled: boolean;
    region: ManifestRegion;
    revel: any;
    square: {
        applicationId: string;
        isProduction: boolean;
        locationId: string;
        loyaltyProgramId: string;
    };
    stripe: {
        publishableKey: string;
        isProduction?: boolean;
    };
    tipping: ITip;
    tippingOrderToTable: ITip;
    tippingPreorder: ITip;
    tippingPayAtTable: ITip;
    tippingTab: ITip;
    preOrder: { readyPeriodStart: number; readyPeriodEnd: number; readyPeriodPadding: number };
    omnivore: any;
    orderToTable: {
        canChangeTableNumber: boolean;
        nfcEnabled: boolean;
        limitLocationRange: boolean;
        multiPartEnabled?: boolean;
    };
    ibs: { ibsWebOrdersHost: string; szAppGUID: string; szCompanyCode: string; useKioskOrder: boolean };
    judopay: { isProduction: boolean; judoId: string; clientToken: string; clientSecret: string };
    lavu: any;
    lightspeed: any;
    timeSlotsEnabled?: boolean;
    asapOrderingEnabled?: boolean;
    payAtTable?: {
        nfcEnabled: boolean;
        limitLocationRange: boolean;
        retrievalBy: 'TABLE_NUMBER' | 'CHECK_NUMBER' | 'BOTH';
        noTableCheckFoundUrl?: string;
        canChangeTableNumber: boolean;
    };
    googlePay: {
        countryCode: string;
        merchantId: string;
        merchantTitle: string;
        supportedNetworksMastercard: boolean;
        supportedNetworksVisa: boolean;
        supportedNetworksAmex?: boolean;
    };
    braintree: { isProduction: boolean };
    comtrex: any;
    apns: { keyId: string; teamId: string };
    appIos: { bundleIdentifier: string };
    applePay: {
        countryCode: string;
        merchantId: string;
        supportedNetworksMastercard: boolean;
        supportedNetworksVisa: boolean;
    };
    orderingEnabled: boolean;
    capturePhoneNumberOnOrder?: Record<OrderScenario, boolean | undefined>;
    worldpay: IPublicSettings['worldpay'];
    iam: IIamSetting;
    giftCardPaymentEnabled?: boolean;
    giftCardPaymentProvider?: string;
    giftCardPayments?: {
        tippingIncluded?: boolean;
    };
    smartlookEnabled?: boolean;
    menu: {
        autoRefreshEnabled: boolean;
        calorieNutrientName: string;
        manualRefreshEnabled: boolean;
    };
    loyaltyProvider?: ELoyaltyProvider;
    loyaltyEnabled?: boolean;
    appleWalletEnabled?: boolean;
    googleWalletEnabled?: boolean;
    offlineCheckinEnabled?: boolean;
    googleSSOEnabled?: boolean;
    googleSSO?: {
        webAppClientId?: string;
    };
    appleSSOEnabled?: boolean;
    appleSSO?: {
        webClientId?: string;
    };
    transaction?: {
        minimumAmount?: number;
        maximumAmount?: number;
    };
}

export function getIdentityLabel(
    emailAuthEnabled?: boolean,
    phoneAuthEnabled?: boolean,
    pickOne = false
): string {
    if (emailAuthEnabled && phoneAuthEnabled && pickOne) {
        return 'phone';
    }
    if (emailAuthEnabled && phoneAuthEnabled) {
        return 'both';
    }
    if (emailAuthEnabled) {
        return 'email';
    }
    if (!phoneAuthEnabled) {
        logger.error('Failed to load merchant data, auth cofig is not set');
        throw new Error('Failed to load merchant data, auth cofig is not set');
    }
    return 'phone';
}

export function isStripeConfig(input: any): input is ISettings['stripe'] {
    return isString(input.publishableKey);
}

export interface TenantData {
    title: string;
    platform: { applicationId: string };
}
