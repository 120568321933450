import { HttpClient } from 'components/http/HttpClient';
import { getLocalSettings } from 'components/settings/localStore';
import logger from 'lib/logger';
import { isLocation, isLocationsData, Location, LocationsData } from './model/Location';

export class LocationApi extends HttpClient {
    constructor() {
        super({
            baseURL: process.env.API_SERVER_URL,
            url: '/locations'
        });
    }
    public async getList(params: Record<string, string>): Promise<LocationsData> {
        const localSettings = getLocalSettings();
        if (!localSettings) {
            throw new Error('merchantId and x-application-id are missed');
        }
        const _params = {
            tenantId: localSettings.merchantId,
            ...params
        };
        const data = this.getRequest<LocationsData>({
            url: '',
            configs: {
                params: _params
            },
            headerConfigs: {
                shouldIncludeAuthToken: false
            }
        });
        if (!isLocationsData(data)) {
            // Don't want to break the web app if we get odd data
            logger.warn(`Typechecking failed, expected Location, but got ${String(data)}`, data);
        }
        return data;
    }

    public async getLocation(locationId: string, params: Record<string, string> = {}): Promise<Location> {
        const localSettings = getLocalSettings();
        if (!localSettings) {
            throw new Error('merchantId and x-application-id are missed');
        }
        const _params = {
            tenantId: localSettings.merchantId,
            ...params
        };
        const data = await this.getRequest<Location>({
            url: `/${locationId}`,
            configs: {
                params: _params
            },
            headerConfigs: { shouldIncludeAuthToken: false }
        });
        if (isLocation(location)) {
            // Don't want to break the web app if we get odd data
            logger.warn(`Typechecking failed, expected LocationsData, but got ${String(data)}`, data);
        }
        return data;
    }

    public async getLocationZones(locationId: string, tableId: string) {
        const localSettings = getLocalSettings();
        if (!localSettings) {
            throw new Error('merchantId and x-application-id are missed');
        }
        const params = {
            tenantId: localSettings.merchantId
        };
        const data = await this.getRequest<{ zone?: string }>({
            url: `/${locationId}/tables/${tableId}`,
            configs: {
                params
            },
            headerConfigs: { shouldIncludeAuthToken: false }
        });
        return data;
    }
}

export const locationApi = new LocationApi();
