import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextFormField } from 'lib/form/TextFormField';
import { CheckCircle } from '../ui/CheckCircle';
import { QuickPayButton } from '../ui/PayButton';
import { PayBaseModal } from './PayBaseModal';

interface PayEmailReceiptModalProps {
    open: boolean;
    onClose: () => void;
    defaultEmail?: string;
    onSubmit: (data: { email: string }) => void;
    loading?: boolean;
    sent?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        fontSize: '16px',
        fontWeight: 400,
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(1)
    },
    button: {
        marginTop: theme.spacing(2)
    },
    img: {
        margin: theme.spacing(2, 'auto')
    },
    successWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

export const PayEmailReceiptModal: React.FC<PayEmailReceiptModalProps> = ({
    open,
    onClose,
    defaultEmail,
    onSubmit,
    loading,
    sent
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const schema = React.useMemo(
        () =>
            Yup.object<{ userEmail: string }>().shape({
                email: Yup.string()
                    .required(t('FORMS_VALIDATION_REQUIRED'))
                    .email(t('FORMS_VALIDATION_EMAIL'))
            }),
        [t]
    );
    if (!open) {
        return null;
    }
    return (
        <PayBaseModal open={open} onClose={onClose}>
            <Formik
                onSubmit={onSubmit}
                initialValues={{ email: defaultEmail ?? '' }}
                validationSchema={schema}
            >
                {({ submitForm, isValid }) => (
                    <Form>
                        <Box px={1} py={1.5}>
                            {!sent && (
                                <React.Fragment>
                                    <Typography align="center" className={classes.title}>
                                        {t('PAT_QUICKPAY_EMAIL_RECEIPT_CONTENT')}
                                    </Typography>
                                    <TextFormField disabled={loading} name="email" type="email" />
                                    <QuickPayButton
                                        className={classes.button}
                                        disabled={loading || !isValid}
                                        onClick={submitForm}
                                    >
                                        {t('BUTTON_SUBMIT')}
                                    </QuickPayButton>
                                </React.Fragment>
                            )}
                            {sent && (
                                <Box className={classes.successWrapper}>
                                    <CheckCircle color={theme.palette.primary.main} className={classes.img} />
                                    <Typography align="center" className={classes.title}>
                                        {t('ACTIONS_RESEND_RECEIPT_SUCCESS')}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Form>
                )}
            </Formik>
        </PayBaseModal>
    );
};
