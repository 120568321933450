import React from 'react';
import { Button, ButtonProps, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        textTransform: 'none',
        marginTop: theme.spacing(1),
        padding: theme.spacing(1.5, 2),
        fontWeight: 700,
        height: '50px'
    },
    secondary: {
        borderWidth: '2px',
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
        '&:hover': {
            borderWidth: '2px',
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.background.default
        }
    }
}));

interface QuickPayButton extends ButtonProps {
    secondary?: boolean;
}

export const QuickPayButton: React.FC<QuickPayButton> = ({ secondary, ...props }) => {
    const classes = useStyles();
    return (
        <Button
            fullWidth
            variant={secondary ? 'outlined' : 'contained'}
            color="primary"
            {...props}
            className={clsx(classes.button, { [classes.secondary]: secondary }, props.className)}
        />
    );
};
