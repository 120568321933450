import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@material-ui/core';
import { ELoyaltyTemplate } from 'components/awards/enums';
import { IPointAward } from 'components/awards/models';
import { AdHoc } from './templates/AdHoc';
import { AwardVisualizer } from './templates/AwardVisualizer';
import { PseudoCurrency } from './templates/PseudoCurrency';
import { StampCard } from './templates/StampCard';
import { AwardDetailsDialog } from './AwardDetailsDialog';
import { isAwardAvailableToday } from 'components/awards/utils';
import { AwardUserSection } from '../user-section/AwardUserSection';

interface IProps {
    awards: IPointAward[];
}

export const AwardsList: React.FC<IProps> = React.memo(({ awards }) => {
    const { t } = useTranslation();
    const [isAwardDetailsOpen, setIsAwardDetailsOpen] = React.useState<boolean>(false);
    const [selectedAward, setSelectedAward] = React.useState<IPointAward>();

    const getAwardByType = React.useCallback((award: IPointAward) => {
        switch (award.template) {
            case ELoyaltyTemplate.AD_HOC:
            case ELoyaltyTemplate.CLUB:
                return <AdHoc award={award} />;
            case ELoyaltyTemplate.STAMP_CARD:
                return <StampCard award={award} />;
            case ELoyaltyTemplate.PSEUDO_CURRENCY:
                return <PseudoCurrency award={award} />;
            default:
                return null;
        }
    }, []);

    const handleAwardClick = React.useCallback(
        (award: IPointAward) => {
            setSelectedAward(award);
            setIsAwardDetailsOpen(true);
        },
        [setIsAwardDetailsOpen, setSelectedAward]
    );
    const renderAward = React.useCallback(
        (award: IPointAward) => (
            <Grid item key={award._id} xs={12} sm={4} md={3}>
                <AwardVisualizer
                    ignoreAlignment={award.template === ELoyaltyTemplate.STAMP_CARD}
                    visualization={award.visualisation}
                    award={award}
                    onClick={handleAwardClick}
                >
                    {getAwardByType(award)}
                </AwardVisualizer>
            </Grid>
        ),
        [getAwardByType, handleAwardClick]
    );

    const handleAwardDetailsClose = React.useCallback(() => {
        setIsAwardDetailsOpen(false);
    }, [setIsAwardDetailsOpen]);

    const filteredAwards = React.useMemo(() => awards.filter(item => isAwardAvailableToday(item)), [awards]);

    return (
        <>
            <Box padding={2}>
                <Box>
                    <AwardUserSection />
                </Box>
                <Grid container spacing={2}>
                    {!!filteredAwards.length ? filteredAwards.map(renderAward) : t('AWARDS_LIST_EMPTY')}
                </Grid>
            </Box>
            {selectedAward && (
                <AwardDetailsDialog
                    award={selectedAward}
                    onClose={handleAwardDetailsClose}
                    isVisible={isAwardDetailsOpen}
                />
            )}
        </>
    );
});

AwardsList.displayName = 'AwardsList';
