import { User } from 'components/user/model/User';
import { UserCard } from './../../components/user/model/User';
import { AuthAction } from './authActions';
import { AuthActionTypes } from './authActionTypes';

export interface AuthState {
    error: string;
    isLoading: boolean;
    isInitiallyAttempted: boolean;
    user?: User;
    token?: string;
    registerError?: string;
    activateError?: string;
    isRegistered: boolean;
    isActivated: boolean;
    id?: string;
    returnUrl?: string;
    card?: UserCard;
    isCardLoading?: boolean;
    showMarketing?: boolean;
}

const emptyErrors = {
    registerError: '',
    error: '',
    activateError: ''
};
const clearFlags = {
    isRegistered: false,
    isActivated: false
};

export const initialState: AuthState = {
    error: '',
    isLoading: false,
    isInitiallyAttempted: false,
    user: undefined,
    token: undefined,
    isRegistered: false,
    isActivated: false,
    isCardLoading: true
};

export default function (state: AuthState = initialState, action: AuthAction): AuthState {
    switch (action.type) {
        case AuthActionTypes.START_REQUEST:
            return { ...state, isLoading: true };
        case AuthActionTypes.END_REQUEST:
            return { ...state, isLoading: false };
        case AuthActionTypes.LOGIN_ERROR:
            return {
                ...state,
                ...emptyErrors,
                ...clearFlags,
                error: action.error
            };
        case AuthActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                ...emptyErrors,
                ...clearFlags,
                token: action.token,
                isInitiallyAttempted: true,
                user: action.user,
                card: action.user?.card
            };
        case AuthActionTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                ...emptyErrors,
                ...clearFlags,
                token: undefined,
                user: undefined
            };
        case AuthActionTypes.REGISTER_ERROR:
            return {
                ...state,
                ...emptyErrors,
                ...clearFlags,
                registerError: action.error,
                isRegistered: false,
                id: undefined
            };
        case AuthActionTypes.REGISTER_SUCCESS:
            return { ...state, ...emptyErrors, ...clearFlags, isRegistered: true, id: action.id };
        case AuthActionTypes.ACTIVATION_SUCCESS:
            return { ...state, ...emptyErrors, ...clearFlags, isActivated: true };
        case AuthActionTypes.ACTIVATION_ERROR:
            return {
                ...state,
                ...emptyErrors,
                ...clearFlags,
                activateError: action.error,
                isActivated: false
            };
        case AuthActionTypes.USER_PENDING:
            return { ...state, ...emptyErrors, ...clearFlags, isRegistered: true, id: action.id };
        case AuthActionTypes.CLEAR_ERRORS:
            return { ...state, ...emptyErrors };
        case AuthActionTypes.SET_RETURN_URL:
            return { ...state, returnUrl: action.returnUrl };
        case AuthActionTypes.RESET_RETURN_URL:
            return { ...state, returnUrl: undefined };
        case AuthActionTypes.UPDATE_USER_INFO:
            if (!action.user) {
                return {
                    ...state,
                    user: undefined,
                    card: undefined
                };
            }
            return {
                ...state,
                user: {
                    ...action.user,
                    credentials: action.user?.credentials ?? state.user?.credentials ?? []
                },
                card: action.user?.card
            };
        case AuthActionTypes.UPDATE_USER_INFO_WITH_CREDENTIALS:
            return { ...state, user: action.user, card: action.user?.card };
        case AuthActionTypes.GET_USER_CARD:
            return { ...state, card: action.card, isCardLoading: action.isCardLoading };
        case AuthActionTypes.SET_SHOW_MARKETING:
            return { ...state, showMarketing: action.showMarketing };
        default:
            return state;
    }
}
