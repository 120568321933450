/* eslint-disable max-len */
import React from 'react';

interface IProps extends Partial<React.SVGProps<SVGSVGElement>> {
    color?: string;
}

export const ErrorCircle: React.FC<IProps> = props => (
    <svg
        width="104"
        height="104"
        viewBox="0 0 104 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M52 102C79.6142 102 102 79.6142 102 52C102 24.3858 79.6142 2 52 2C24.3858 2 2 24.3858 2 52C2 79.6142 24.3858 102 52 102Z"
            stroke={props.color}
            strokeWidth="4"
        />
        <path
            d="M72.559 64.9943L40.4177 32.8531C38.6426 31.078 35.7646 31.078 33.9895 32.8531C32.2144 34.6282 32.2144 37.5063 33.9895 39.2814L66.1307 71.4226C67.9058 73.1977 70.7838 73.1977 72.559 71.4226C74.3341 69.6475 74.3341 66.7695 72.559 64.9943Z"
            fill={props.color}
        />
        <path
            d="M72.5588 39.2814L40.4176 71.4226C38.6425 73.1977 35.7645 73.1977 33.9894 71.4226C32.2143 69.6475 32.2143 66.7694 33.9894 64.9943L66.1306 32.8531C67.9057 31.078 70.7837 31.078 72.5588 32.8531C74.3339 34.6282 74.3339 37.5062 72.5588 39.2814Z"
            fill={props.color}
        />
    </svg>
);
