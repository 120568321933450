import * as React from 'react';
import { Box, createStyles, ListItem, makeStyles, Theme, Typography } from '@material-ui/core';
import { IItemReadResourceV10 } from 'components/basket/model/Basket';
import { getModifierProductDetails, getProductOptionsDetails } from 'components/menu/model/Menu';
import { IUserReadResourceV12 } from 'components/order/model/Order';
import { addOpacity } from 'lib/helpers';
import { isDefined } from 'lib/typeInference';
import { useCurrencyString } from 'lib/useCurrencyString';
import { UserAvatar } from 'lib/UserAvatar';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        productWrapper: {
            display: 'flex',
            width: '100%',
            flexShrink: 0
        },
        productQuantity: {
            minWidth: '10%',
            marginRight: theme.spacing(0.5)
        },
        productInfo: {
            flex: 1
        },
        productModifiers: {
            color: addOpacity(theme.palette.text.primary, 0.6)
        },
        productPrice: {
            minWidth: '20%',
            textAlign: 'right'
        },
        avatar: {
            width: theme.spacing(4),
            height: theme.spacing(4)
        }
    })
);

interface IProps {
    orderItem: IItemReadResourceV10;
    allItems: IItemReadResourceV10[];
    index?: number;
    onClick?: (index: number, item?: IItemReadResourceV10) => void;
    showClaimedBy?: boolean;
    user?: IUserReadResourceV12;
    showPricePerOne?: boolean;
}

export const ViewBillOrderItem: React.FC<IProps> = ({
    orderItem,
    showClaimedBy,
    allItems,
    index,
    onClick,
    showPricePerOne,
    user
}) => {
    const classes = useStyles();
    const getCurrencyString = useCurrencyString();

    const modifierProducts = allItems.filter(
        item => item.parentReferenceId && item.parentReferenceId === orderItem.referenceId
    );
    const [modifierProductsString, calculatedItemPrice] = React.useMemo(() => {
        const [modifierString, modifierPrice] = getModifierProductDetails(
            modifierProducts,
            orderItem.quantity
        );
        return [modifierString, orderItem.cost + modifierPrice];
    }, [orderItem.cost, modifierProducts, orderItem.quantity]);
    const [modifierOptionsString, calculatedOptionsPrice] = React.useMemo(
        () => getProductOptionsDetails(orderItem.modifiers),
        [orderItem.modifiers]
    );

    const totalPrice = React.useMemo(
        () => Math.round((calculatedItemPrice + calculatedOptionsPrice) * orderItem.quantity * 100) / 100,
        [calculatedItemPrice, calculatedOptionsPrice, orderItem.quantity]
    );

    const handleClick = React.useCallback(() => {
        if (isDefined(onClick) && isDefined(index)) {
            onClick(index, orderItem);
        }
    }, [onClick, index, orderItem]);

    if (orderItem.parentReferenceId) {
        return null;
    }

    return (
        <ListItem onClick={handleClick} className={classes.productWrapper} disableGutters>
            {showClaimedBy ? (
                <Box marginRight={0.5}>
                    <UserAvatar user={user} classnames={classes.avatar} />
                </Box>
            ) : (
                <Typography className={classes.productQuantity}>
                    {!showPricePerOne ? orderItem.quantity : 1}
                    {'x'}
                </Typography>
            )}
            <Box className={classes.productInfo}>
                <Typography>{orderItem.productName}</Typography>
                <Typography variant="caption" className={classes.productModifiers}>
                    {modifierProductsString}
                    {modifierOptionsString}
                </Typography>
            </Box>
            <Typography className={classes.productPrice}>
                {getCurrencyString(showPricePerOne ? totalPrice / orderItem.quantity : totalPrice)}
            </Typography>
        </ListItem>
    );
};
