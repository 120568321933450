import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import { createStyles, IconButton, makeStyles, Menu, MenuItem, Theme } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HistoryIcon from '@material-ui/icons/History';
import PaymentIcon from '@material-ui/icons/Payment';
import SignUpIcon from '@material-ui/icons/PersonAdd';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import clsx from 'clsx';
import { resetLocalJourney } from 'components/journey/localStore';
import { setOnCloseRedirectUrl } from 'components/user/localAuth';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { BaseRouteParams, ROUTES } from 'pages/routes';
import { logout, resetReturnUrl, setReturnUrl } from 'store/auth/authActions';
import { resetAwards } from 'store/basket/basketActions';
import { ApplicationState } from 'store/store';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            marginRight: theme.spacing(2)
        },
        userAvatar: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            borderRadius: '50%'
        },
        userInitials: {
            fontSize: theme.spacing(1.5),
            fontWeight: theme.typography.fontWeightBold,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    })
);

interface PayAvatarProps {
    onAuthActionClick?: () => Promise<void>;
    className?: string;
}

export const PayAvatar: React.FC<PayAvatarProps> = ({ onAuthActionClick, className }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const menuId = 'pay-menu-id';
    const { push } = useLocalHistory();
    const { isLoggedIn, user } = useAuth();
    const { merchantId } = useParams<BaseRouteParams>();
    const { pathname, search } = useLocation();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>(undefined);
    const [isAvatarAvailable, setIsAvatarAvailable] = React.useState<boolean>(true);
    const userId = React.useMemo(() => (user ? user._id : ''), [user]);
    const userInitials = React.useMemo(() => user && user.firstName[0] + user.lastName[0], [user]);
    const fullPath = React.useMemo(() => pathname + search, [pathname, search]);
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const handleOpen = React.useCallback((e: React.MouseEvent<HTMLElement>) => {
        setAnchorEl((value?: HTMLElement) => (!!value ? undefined : e.currentTarget));
    }, []);
    const closeMenu = React.useCallback(() => setAnchorEl(undefined), []);
    const handleError = React.useCallback(() => {
        setIsAvatarAvailable(false);
    }, []);
    const handleSignIn = React.useCallback(() => {
        setReturnUrl(fullPath)(dispatch);
        if (merchantId) {
            setOnCloseRedirectUrl(fullPath, merchantId);
        }
        if (onAuthActionClick) {
            onAuthActionClick();
        }
        push(ROUTES.USER.LOGIN);
    }, [fullPath, dispatch, merchantId, onAuthActionClick, push]);
    const handleSignUp = React.useCallback(() => {
        if (onAuthActionClick) {
            onAuthActionClick();
        }
        setReturnUrl(fullPath)(dispatch);
        if (merchantId) {
            setOnCloseRedirectUrl(fullPath, merchantId);
        }
        push(ROUTES.USER.REGISTER);
    }, [onAuthActionClick, fullPath, dispatch, merchantId, push]);
    const handleLogOut = React.useCallback(async () => {
        if (onAuthActionClick) {
            await onAuthActionClick();
        }
        await logout(userId)(dispatch);
        resetAwards(dispatch);
        if (!!matchPath(pathname, ROUTES.USER.DETAILS) && merchantId) {
            resetReturnUrl(dispatch);
            resetLocalJourney(merchantId);
        }
        setAnchorEl(undefined);
    }, [dispatch, merchantId, onAuthActionClick, pathname, userId]);
    const handleProfileClick = React.useCallback(() => {
        if (merchantId && !matchPath(pathname, ROUTES.USER.DETAILS)) {
            setOnCloseRedirectUrl(fullPath, merchantId);
        }
        push(ROUTES.USER.DETAILS);
    }, [merchantId, fullPath, push, pathname]);
    const handleRewardsClick = React.useCallback(() => {
        if (merchantId && !matchPath(pathname, ROUTES.USER.DETAILS)) {
            setOnCloseRedirectUrl(fullPath, merchantId);
        }
        push(ROUTES.USER.REWARDS);
    }, [merchantId, pathname, push, fullPath]);
    const handleActivityClick = React.useCallback(() => {
        setReturnUrl(pathname)(dispatch);
        if (merchantId && !matchPath(pathname, ROUTES.USER.DETAILS)) {
            setOnCloseRedirectUrl(fullPath, merchantId);
        }
        push(ROUTES.USER.ACTIVITY);
    }, [dispatch, merchantId, pathname, push, fullPath]);
    const handlePaymentMethodsClick = React.useCallback(() => {
        setReturnUrl(pathname)(dispatch);
        if (merchantId && !matchPath(pathname, ROUTES.USER.DETAILS)) {
            setOnCloseRedirectUrl(fullPath, merchantId);
        }
        push(ROUTES.USER.PAYMENT_METHODS);
    }, [dispatch, merchantId, pathname, push, fullPath]);
    const showPaymentMethods = React.useMemo(
        () =>
            isLoggedIn &&
            !!settings?.paymentProvider &&
            (settings?.preOrderEnabled || settings?.orderToTableEnabled || settings?.payAtTableEnabled),
        [
            isLoggedIn,
            settings?.orderToTableEnabled,
            settings?.payAtTableEnabled,
            settings?.paymentProvider,
            settings?.preOrderEnabled
        ]
    );
    return (
        <React.Fragment>
            <IconButton
                className={className}
                aria-label="show more"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleOpen}
            >
                {isLoggedIn &&
                    (isAvatarAvailable ? (
                        <img
                            className={classes.userAvatar}
                            src={`${process.env.MEDIA_URL}/users/avatars/${userId}.jpg`}
                            onError={handleError}
                        />
                    ) : (
                        <span className={clsx(classes.userAvatar, classes.userInitials)}>{userInitials}</span>
                    ))}
                {!isLoggedIn && <AccountCircle color="inherit" />}
            </IconButton>
            {!!anchorEl && (
                <Menu anchorEl={anchorEl} id={menuId} open={!!anchorEl} onClose={closeMenu}>
                    {!isLoggedIn && (
                        <MenuItem button onClick={handleSignIn}>
                            <ExitToAppIcon className={classes.icon} />
                            {t('ONBOARDING_SIGN_IN')}
                        </MenuItem>
                    )}
                    {!isLoggedIn && (
                        <MenuItem button onClick={handleSignUp}>
                            <SignUpIcon className={classes.icon} />
                            {t('ONBOARDING_SIGN_UP')}
                        </MenuItem>
                    )}
                    {isLoggedIn && (
                        <MenuItem button onClick={handleProfileClick}>
                            <AccountCircle className={classes.icon} />
                            {t('MY_DETAILS_TITLE')}
                        </MenuItem>
                    )}
                    {isLoggedIn && (
                        <MenuItem button onClick={handleRewardsClick}>
                            <StarOutlinedIcon className={classes.icon} />
                            {t('AUTH_REWARDS')}
                        </MenuItem>
                    )}
                    {isLoggedIn && (
                        <MenuItem button onClick={handleActivityClick}>
                            <HistoryIcon className={classes.icon} />
                            {t('MAIN_MENU_ACTIVITY')}
                        </MenuItem>
                    )}
                    {showPaymentMethods && (
                        <MenuItem button onClick={handlePaymentMethodsClick}>
                            <PaymentIcon className={classes.icon} />
                            {t('ACCOUNT_PAYMENT')}
                        </MenuItem>
                    )}
                    {isLoggedIn && (
                        <MenuItem button onClick={handleLogOut}>
                            <ExitToAppIcon className={classes.icon} />
                            {t('AUTH_LOGOUT')}
                        </MenuItem>
                    )}
                </Menu>
            )}
        </React.Fragment>
    );
};
