import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { LoadingTypography } from 'lib/LoadingTypography';
import { isDefined, isNumber } from 'lib/typeInference';
import { useCurrencyString } from 'lib/useCurrencyString';

interface PayItemsHeaderProps {
    loading?: boolean;
    total?: number;
    paid?: boolean;
    splitTotal?: number;
    customSplit?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        fontSize: '24px',
        paddingBottom: theme.spacing(1),
        fontWeight: 500,
        color: theme.palette.text.primary
    },
    subtitle: {
        fontSize: '18px',
        paddingBottom: theme.spacing(0.5),
        fontWeight: 500,
        color: theme.palette.text.primary,
        opacity: 0.5
    }
}));

export const PayItemsHeader: React.FC<PayItemsHeaderProps> = ({
    loading,
    total,
    paid,
    splitTotal,
    customSplit
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const getCurrencyString = useCurrencyString();
    const isSplitting = isDefined(splitTotal);
    const label = React.useMemo(() => {
        if (paid) {
            return t('PAT_QUICKPAY_YOU_PAID');
        }
        if (isSplitting) {
            return t('PAT_QUICKPAY_PAY_YOUR_BILL_SHARE');
        }
        return t('PAT_QUICKPAY_PAY_YOUR_BILL');
    }, [isSplitting, paid, t]);
    const content = React.useMemo(() => {
        if (isNumber(customSplit)) {
            return getCurrencyString(customSplit);
        }
        if (isSplitting && isDefined(splitTotal)) {
            return getCurrencyString(splitTotal);
        }
        if (isDefined(total)) {
            return getCurrencyString(total);
        }
        return '';
    }, [customSplit, getCurrencyString, isSplitting, splitTotal, total]);
    return (
        <Box>
            {(isSplitting || isNumber(customSplit)) && (
                <React.Fragment>
                    <Box display="flex" justifyContent="space-between" pt={0.5}>
                        <LoadingTypography className={classes.subtitle} loading={loading} width={130}>
                            {t('BILL_CHECKOUT_PAYMENT_INFO_TOTAL')}
                        </LoadingTypography>
                        <LoadingTypography className={classes.subtitle} loading={loading} width={80}>
                            {isDefined(total) && getCurrencyString(total)}
                        </LoadingTypography>
                    </Box>
                    <Box>
                        <Divider />
                    </Box>
                </React.Fragment>
            )}
            <Box display="flex" justifyContent="space-between" pt={1}>
                <LoadingTypography className={classes.title} loading={loading} width={130}>
                    {label}
                </LoadingTypography>
                <LoadingTypography className={classes.title} loading={loading} width={80}>
                    {content}
                </LoadingTypography>
            </Box>
            <Divider />
        </Box>
    );
};
