import React from 'react';
import { PayGiftCardApply } from './PayGiftCardApply';
import { PayGiftCardForm } from './PayGiftCardForm';

interface PayGiftCardLoadingProps {
    giftCardNumber?: string;
    empty?: boolean;
}

export const PayGiftCardLoading: React.FC<PayGiftCardLoadingProps> = ({ giftCardNumber, empty }) =>
    empty ? <PayGiftCardForm loading /> : <PayGiftCardApply giftCardNumber={giftCardNumber} loading />;
