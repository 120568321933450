import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    makeStyles,
    Theme
} from '@material-ui/core';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { OrderScenario } from 'components/order/model/Order';
import { setOnCloseRedirectUrl } from 'components/user/localAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { LocationRouteParams, ROUTES } from 'pages/routes';
import { setReturnUrl } from 'store/auth/authActions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            fontSize: '7rem'
        },
        content: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: theme.spacing(1)
        }
    })
);

export const OrderSessionExpired: React.FC = () => {
    const classes = useStyles();
    const { push, getParsedPath } = useLocalHistory();
    const dispatch = useDispatch();
    const [scenario] = useRouteScenario();
    const { merchantId } = useParams<LocationRouteParams>();
    const { t } = useTranslation();
    const isTableOrder = React.useMemo(
        () => [OrderScenario.ORDER_TO_TABLE, OrderScenario.TABLE].includes(scenario as OrderScenario),
        [scenario]
    );
    const handleOk = React.useCallback(() => {
        if (!!merchantId) {
            if (isTableOrder) {
                push(getParsedPath(ROUTES.JOURNEY.LANDING));
            } else {
                push(getParsedPath(ROUTES.MENU));
            }

            return;
        }
        push(ROUTES.BASE);
    }, [getParsedPath, isTableOrder, merchantId, push]);
    const handleSignUp = React.useCallback(() => {
        if (!!merchantId) {
            let returnUrl = getParsedPath(ROUTES.BASE);
            if (isTableOrder) {
                returnUrl = getParsedPath(ROUTES.JOURNEY.LANDING);
            }

            setReturnUrl(returnUrl)(dispatch);
            setOnCloseRedirectUrl(returnUrl, merchantId);
        }
        push(ROUTES.USER.REGISTER);
    }, [dispatch, getParsedPath, isTableOrder, merchantId, push]);
    return (
        <Dialog open>
            <DialogContent className={classes.content}>
                <AvTimerIcon className={classes.icon} fontSize="large" />
                <DialogContentText variant="h6">
                    <b>{t('ORDER_SUMMARY_EXPIRED_TITLE')}</b>
                </DialogContentText>
                {t('ORDER_SUMMARY_EXPIRED_CONTENT')
                    .split('\n')
                    .map((item, index) => (
                        <DialogContentText align="center" key={`tstring-auth.reset.description-${index}`}>
                            {item}
                        </DialogContentText>
                    ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk} variant="contained">
                    {t('ORDER_SUMMARY_EXPIRED_CONTINUE')}
                </Button>
                <Button onClick={handleSignUp} variant="contained" color="primary">
                    {t('ORDER_SUMMARY_EXPIRED_REGISTER')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
