import { initReactI18next } from 'react-i18next';
import i18n, { ResourceStore, TOptions } from 'i18next';
import pickBy from 'lodash/pickBy';
import { localizationApi } from 'components/localization/localizationApi';
import logger from 'lib/logger';
import { isString } from 'lib/typeInference';

const loadTranslation = async (languageCode: string) => {
    try {
        const translation = await localizationApi.getLocalization();
        return translation;
    } catch (err) {
        logger.error(`Error loading translations for ${languageCode}`);
    }
};

// eslint-disable-next-line @typescript-eslint/no-var-requires
const defaultTranslation = require('./translations/default.json');

interface Translator {
    resourceStore: ResourceStore;
    language: string;
}

const postProcessor = {
    type: 'postProcessor' as const,
    name: 'pluralsProcessor',
    process(value: string, [key]: string[], options: TOptions, translator: Translator) {
        if (options.count && options.count !== 1 && options.count !== -1) {
            const pairs: Record<string, string> | undefined | string =
                translator.resourceStore.data[translator.language]?.translation;
            if (!pairs || isString(pairs)) {
                return value;
            }
            const pluralKey = key + '_PLURAL';
            return pairs[pluralKey] || value;
        }
        return value;
    }
};

export const loadTranslations = async (language: string) => {
    const translation = pickBy(await loadTranslation(language), v => v.trim() !== '') || {};

    i18n.use(initReactI18next)
        .use(postProcessor)
        .init({
            resources: {
                [language]: { translation: { ...defaultTranslation.translation, ...translation } }
            },
            lng: language,
            postProcess: ['pluralsProcessor'],
            interpolation: {
                escapeValue: false
            }
        });
};

i18n.use(initReactI18next)
    .use(postProcessor)
    .init({
        resources: {
            default: defaultTranslation
        },
        postProcess: ['pluralsProcessor'],
        lng: 'default',
        interpolation: {
            escapeValue: false
        }
    });
export default i18n;
