import cloneDeep from 'lodash/cloneDeep';
import { IOrderReadResourceV12, IUpdateOrderDetails } from 'components/order/model/Order';

export const orderDetailsToUpdateMapper = ({
    isOpen,
    deliveryLocation,
    payments,
    id,
    total,
    splitTotal
}: IOrderReadResourceV12): IUpdateOrderDetails =>
    cloneDeep({ isOpen, id, adjustments: [], payments, deliveryLocation, total, splitTotal });
