import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, createStyles, Divider, makeStyles, Theme } from '@material-ui/core';
import { ITip } from 'components/settings/model/Settings';
import { roundToDecimal } from 'lib/helpers';
import { LoadingTypography } from 'lib/LoadingTypography';
import { isDefined } from 'lib/typeInference';
import { useCurrencyString } from 'lib/useCurrencyString';
import { ApplicationState } from 'store/store';
import { PayTipsCustomModal } from '../modals/PayTipsCustomModal';
import { PayTipsItem } from './PayTipsItem';
import { PayTipsItemBox } from './PayTipsItemBox';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: '18px',
            fontWeight: 700,
            marginBottom: theme.spacing(1)
        },
        tipsRow: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: theme.spacing(2)
        },
        custom: {
            fontSize: '13px'
        }
    })
);

interface PayTipsProps {
    subTotal?: number;
    loading?: boolean;
    selectedTip?: number;
    tipValue?: number;
    tippingSettings: ITip;
    onChange: (value: number, option?: number) => void;
}

export const PayTips: React.FC<PayTipsProps> = ({
    subTotal,
    loading,
    selectedTip,
    tipValue,
    tippingSettings,
    onChange
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const [open, setOpen] = React.useState(false);
    const getCurrencyString = useCurrencyString();
    const tippingEnabled = React.useMemo(() => {
        if (isDefined(settings?.tippingPayAtTableEnabled)) {
            return settings?.tippingPayAtTableEnabled;
        }
        return settings?.tippingEnabled;
    }, [settings?.tippingEnabled, settings?.tippingPayAtTableEnabled]);
    const customTipString = React.useMemo(() => {
        if (!isDefined(selectedTip) && isDefined(tipValue)) {
            return getCurrencyString(tipValue);
        }
        return t('PAT_QUICKPAY_TIPS_CUSTOM_PLACEHOLDER');
    }, [selectedTip, t, getCurrencyString, tipValue]);
    const handleCustomClick = React.useCallback(() => {
        setOpen(true);
    }, []);
    const handleCustomCancel = React.useCallback(() => {
        setOpen(false);
    }, []);
    const handleCustomSubmit = React.useCallback(
        (tip: number) => {
            onChange(roundToDecimal(tip));
            setOpen(false);
        },
        [onChange]
    );
    const handleClick = React.useCallback(
        (value: number, selectorValue?: number) => {
            onChange(value, selectorValue);
        },
        [onChange]
    );
    const renderTipItem = React.useCallback(
        (item: number, index: number) => (
            <PayTipsItem
                key={`tip-${index}`}
                schema={tippingSettings.scheme}
                value={item}
                onClick={handleClick}
                selectedValue={selectedTip}
                subTotal={subTotal}
                loading={loading}
            />
        ),
        [handleClick, loading, selectedTip, subTotal, tippingSettings.scheme]
    );
    if (!loading && !tippingEnabled) {
        return null;
    }
    return (
        <Box mx={2} pb={1} pt={1}>
            <LoadingTypography width={80} loading={loading} className={classes.title}>
                {t('BILL_CHECKOUT_TIPS_TITLE')}
            </LoadingTypography>
            <Box className={classes.tipsRow}>
                {tippingSettings.values.map(renderTipItem)}
                <PayTipsItemBox
                    key="tip-4"
                    primary={customTipString}
                    onClick={handleCustomClick}
                    selected={!isDefined(selectedTip)}
                    gutterRight={false}
                    loading={loading}
                    primaryClassName={classes.custom}
                />
            </Box>
            <Divider />
            <PayTipsCustomModal
                open={open}
                onClose={handleCustomCancel}
                initialValue={tipValue}
                onSubmit={handleCustomSubmit}
            />
        </Box>
    );
};
