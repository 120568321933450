import React from 'react';
import { useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { ApplicationState } from 'store/store';

export const StripeCardProvider: React.FC = ({ children }) => {
    const [stripePromise, setStripePromise] = React.useState<Stripe | null | Promise<Stripe | null>>(null);
    const { settings } = useSelector((state: ApplicationState) => state.settings);

    const stripePublishableKey = React.useMemo(
        () => !!settings && !!settings.stripe && settings.stripe.publishableKey,
        [settings]
    );

    React.useEffect(() => {
        if (!!stripePublishableKey) {
            setStripePromise(loadStripe(stripePublishableKey));
        }
    }, [stripePublishableKey]);

    return <Elements stripe={stripePromise}>{children}</Elements>;
};
