import { useMediaQuery, useTheme } from '@material-ui/core';

export function useResponsive() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.only('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });

    return { isTablet, isDesktop, isMobile };
}
