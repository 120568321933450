import * as React from 'react';

/**
 * React hook to get current location.
 *
 * @param options {PositionOptions}
 */
export function useCurrentPosition(options?: PositionOptions) {
    const [position, setPosition] = React.useState<GeolocationPosition>();
    const [error, setError] = React.useState<GeolocationPositionError>();

    React.useEffect(() => {
        let canceled = false;

        navigator.geolocation.getCurrentPosition(
            currentPosition => {
                if (!canceled) {
                    setPosition(currentPosition);
                }
            },
            err => {
                if (!canceled) {
                    setError(err);
                }
            },
            options
        );

        return () => {
            canceled = true;
        };
    }, [options]);

    return { position, error };
}
