import { AxiosResponse } from 'axios';
import moment from 'moment';
import { ActionCreator } from 'redux';
import { store } from 'src/App';
import { HttpClient } from 'components/http/HttpClient';
import {
    getWorkingHoursParams,
    isLocationOpen as locationAvailabilityChecker
} from 'components/location/hooks/useLocationHelpers';
import { getLocalMerchantId } from 'components/settings/localStore';
import {
    getLocalAuthentication,
    resetLocalAuthentication,
    resetLocalToken,
    resetShouldUpdateGuestSession,
    resetUserid,
    setLocalAuthentication,
    updateLocalToken,
    updateUserId
} from 'components/user/localAuth';
import { SignUpFormData, User, UserData } from 'components/user/model/User';
import { userApi } from 'components/user/userApi';
import logger from 'lib/logger';
import { isString } from 'lib/typeInference';
import { UserCard } from './../../components/user/model/User';
import { AuthActionTypes } from './authActionTypes';

interface AuthLoadingAction {
    type: AuthActionTypes.END_REQUEST | AuthActionTypes.START_REQUEST | AuthActionTypes.CLEAR_ERRORS;
}

interface AuthLogoutAction {
    type: AuthActionTypes.LOGOUT_SUCCESS;
}

interface AuthErrorAction {
    type: AuthActionTypes.LOGIN_ERROR | AuthActionTypes.ACTIVATION_ERROR | AuthActionTypes.REGISTER_ERROR;
    error: string;
}

interface LoginSuccessAction {
    type: AuthActionTypes.LOGIN_SUCCESS;
    token: string;
    user: User;
}

interface AuthRegisterAction {
    type: AuthActionTypes.REGISTER_SUCCESS | AuthActionTypes.USER_PENDING;
    id: string;
}

interface AuthActivateAction {
    type: AuthActionTypes.ACTIVATION_SUCCESS;
}

interface AuthSetReturnUrlAction {
    type: AuthActionTypes.SET_RETURN_URL;
    returnUrl: string;
}

interface AuthResetReturnUrlAction {
    type: AuthActionTypes.RESET_RETURN_URL;
}

interface UpdateUserAction {
    type: AuthActionTypes.UPDATE_USER_INFO | AuthActionTypes.UPDATE_USER_INFO_WITH_CREDENTIALS;
    user: User | undefined;
}

interface MarketingConsentAction {
    type: AuthActionTypes.SET_SHOW_MARKETING;
    showMarketing?: boolean;
}

interface GetUserCardAction {
    type: AuthActionTypes.GET_USER_CARD;
    card: UserCard | undefined;
    isCardLoading: boolean;
}

export type AuthAction =
    | AuthLoadingAction
    | AuthErrorAction
    | LoginSuccessAction
    | AuthLogoutAction
    | AuthRegisterAction
    | AuthActivateAction
    | AuthSetReturnUrlAction
    | AuthResetReturnUrlAction
    | UpdateUserAction
    | GetUserCardAction
    | MarketingConsentAction;

export const startAuthRequest = {
    type: AuthActionTypes.START_REQUEST
};

export const endAuthRequest = {
    type: AuthActionTypes.END_REQUEST
};

const getSessionExpiryDate = () => {
    const {
        locations: { currentLocation }
    } = store.getState();
    const isLocationOpen = locationAvailabilityChecker(currentLocation?.openingHours);
    let sessionExpiryDate: moment.Moment | undefined;

    if (currentLocation && isLocationOpen) {
        const { actualClosingTime } = getWorkingHoursParams(currentLocation.openingHours);
        sessionExpiryDate = actualClosingTime;
    } else {
        sessionExpiryDate = moment().add(1, 'days').startOf('day');
    }

    return sessionExpiryDate;
};

export const register = (data: SignUpFormData) => async (dispatch: ActionCreator<AuthAction>) => {
    dispatch(startAuthRequest);
    try {
        updateUserId(data.identity);
        try {
            const user = await userApi.createUser(data);
            updateLocalToken({ userId: user.id, token: user._id });
            dispatch({
                type: AuthActionTypes.REGISTER_SUCCESS,
                id: data.identity
            });
            dispatch(endAuthRequest);

            return user;
        } catch (err: any) {
            const error: AxiosResponse<{ message: string; code: string }> = err;
            if (error.status === 422) {
                const { message } = error.data;
                if (isString(message) && message.toUpperCase().includes('PENDING')) {
                    userApi.activateUser(data.identity);
                    return dispatch({
                        type: AuthActionTypes.USER_PENDING,
                        id: data.identity
                    });
                }
                throw new Error(message);
            }
        }
    } catch (e: any) {
        resetLocalToken();
        dispatch({
            type: AuthActionTypes.REGISTER_ERROR,
            error: e.message
        });
        logger.warn(e);
        throw e;
    }
    dispatch(endAuthRequest);
};

export const activate = (id: string, token?: string) => async (dispatch: ActionCreator<AuthAction>) => {
    dispatch(startAuthRequest);
    try {
        const result = await userApi.activateUser(id, token);
        resetUserid();
        const merchantId = getLocalMerchantId();
        if (merchantId) {
            resetShouldUpdateGuestSession(merchantId);
        }
        HttpClient.token = result._id;
        const successData: LoginSuccessAction = {
            type: AuthActionTypes.LOGIN_SUCCESS,
            token: result._id,
            user: result.user
        };
        setLocalAuthentication({
            userId: result.userId,
            token: result._id,
            isGuest: false,
            expiry: 0
        });

        dispatch(successData);
    } catch (e) {
        logger.warn(e);
        dispatch({
            type: AuthActionTypes.ACTIVATION_ERROR,
            error: 'Invalid activation code'
        });
    }
    dispatch(endAuthRequest);
};

export const clearAuthErrors = (dispatch: ActionCreator<AuthAction>) => {
    dispatch({
        type: AuthActionTypes.CLEAR_ERRORS
    });
};

export const setReturnUrl = (returnUrl: string) => (dispatch: ActionCreator<AuthAction>) => {
    dispatch({
        returnUrl,
        type: AuthActionTypes.SET_RETURN_URL
    });
};

export const resetReturnUrl = (dispatch: ActionCreator<AuthAction>) => {
    dispatch({
        type: AuthActionTypes.RESET_RETURN_URL
    });
};

export const updateUser = (user: User | undefined) => ({
    type: AuthActionTypes.UPDATE_USER_INFO,
    user
});

export const getUserDetails =
    (userId: string, noCache?: boolean) => async (dispatch: ActionCreator<AuthAction>) => {
        try {
            const localAuth = getLocalAuthentication();
            if (localAuth && !HttpClient.token) {
                HttpClient.token = localAuth.token;
            }
            const newUserDetails = await userApi.getUserDetails(userId, noCache);

            dispatch({
                type: AuthActionTypes.UPDATE_USER_INFO_WITH_CREDENTIALS,
                user: newUserDetails
            });

            return newUserDetails;
        } catch (e) {
            dispatch({
                type: AuthActionTypes.UPDATE_USER_INFO_WITH_CREDENTIALS,
                user: undefined
            });
        }
    };

export const createGuestSession = async (dispatch: ActionCreator<AuthAction>) => {
    try {
        const result = await userApi.getGuestSession();
        const sessionExpiry = getSessionExpiryDate();

        setLocalAuthentication({
            userId: result.userId,
            token: result._id,
            isGuest: true,
            expiry: sessionExpiry?.unix()
        });

        HttpClient.token = result._id;

        getUserDetails(result.userId)(dispatch);
    } catch (err) {
        logger.info(err);
    }
};

export const finalizeSignIn = (userData: UserData) => (dispatch: ActionCreator<AuthAction>) => {
    const successData: LoginSuccessAction = {
        type: AuthActionTypes.LOGIN_SUCCESS,
        token: userData._id,
        user: userData.user
    };

    HttpClient.token = userData._id;

    setLocalAuthentication({
        userId: userData.userId,
        token: userData._id,
        isGuest: false,
        expiry: 0
    });

    dispatch(successData);
    dispatch(endAuthRequest);

    return userData;
};

export const signIn =
    (credentials: string, token?: string) => async (dispatch: ActionCreator<AuthAction>) => {
        dispatch(startAuthRequest);

        try {
            const result = await (!!token
                ? userApi.loginWithToken(credentials, token)
                : userApi.loginUser(credentials));

            dispatch(finalizeSignIn(result));
            return result;
        } catch (e) {
            logger.warn(e);
            dispatch(endAuthRequest);
            throw e;
        }
    };

export const googleSignIn = (credential: string) => async (dispatch: ActionCreator<AuthAction>) => {
    try {
        const result = await userApi.loginGoogleSSOUser(credential);
        const { status, ...rest } = result;
        if (status === 201) {
            dispatch(setShowMarketing(true));
        }
        dispatch(finalizeSignIn(rest));
        return result;
    } catch (e) {
        dispatch(endAuthRequest);
        logger.warn(e);
        throw e;
    }
};

export const logout = (id: string) => async (dispatch: ActionCreator<AuthAction>) => {
    if (!!id) {
        try {
            await userApi.logoutUser(id);
        } catch (e) {
            logger.error(e);
        }
    }
    resetLocalAuthentication();
    createGuestSession(dispatch);

    dispatch({
        type: AuthActionTypes.LOGOUT_SUCCESS
    });
};

export const getUserCard = (userId: string) => async (dispatch: ActionCreator<AuthAction>) => {
    try {
        const localAuth = getLocalAuthentication();
        if (localAuth && !HttpClient.token) {
            HttpClient.token = localAuth.token;
        }

        dispatch({
            type: AuthActionTypes.GET_USER_CARD,
            isCardLoading: true
        });
        const newUserDetails = await userApi.getUserDetails(userId, true);

        dispatch({
            type: AuthActionTypes.GET_USER_CARD,
            card: newUserDetails.card,
            isCardLoading: false
        });

        return newUserDetails.card;
    } catch (e) {
        dispatch({
            type: AuthActionTypes.GET_USER_CARD,
            card: undefined,
            isCardLoading: false
        });
    }
};

export const setShowMarketing = (value?: boolean) => ({
    type: AuthActionTypes.SET_SHOW_MARKETING,
    showMarketing: !!value
});
