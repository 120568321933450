import React from 'react';
import { createStyles, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Subtract from '@material-ui/icons/Remove';
import { addOpacity } from './helpers';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        quantitySelector: {
            display: 'flex',
            flexDirection: 'row',
            border: `1px solid ${addOpacity(theme.palette.text.primary, 0.08)}`,
            borderRadius: '9999px', // Pill Shape
            alignItems: 'center',
            width: 'max-content',
            marginBottom: theme.spacing(1)
        }
    })
);

interface IQuantitySelectProps {
    min: number;
    max: number;
    quantity: number;
    onChange: (quantity: number) => void;
    disabledMax?: boolean;
}

export const QuantitySelector: React.FC<IQuantitySelectProps> = props => {
    const { min, max, quantity, onChange, disabledMax } = props;
    const classes = useStyles();

    const minusQuantity = React.useCallback(() => {
        if (quantity !== min) {
            onChange(quantity - 1);
        }
    }, [onChange, min, quantity]);
    const addQuantity = React.useCallback(() => {
        if (quantity !== max) {
            onChange(quantity + 1);
        }
    }, [onChange, max, quantity]);

    return (
        <div className={classes.quantitySelector}>
            <IconButton
                disabled={quantity === 0}
                aria-label="Subtract Quantity"
                color="primary"
                onClick={minusQuantity}
            >
                <Subtract />
            </IconButton>
            <Typography variant="subtitle1">{quantity}</Typography>
            <IconButton
                disabled={disabledMax}
                aria-label="Add Quantity"
                color="primary"
                onClick={addQuantity}
            >
                <Add />
            </IconButton>
        </div>
    );
};
