import { isString } from 'lib/typeInference';

export interface Layout {
    anchorBackgroundColour: string;
    anchorTextColour: string;
    primaryActionBackgroundColour: string;
    primaryActionTextColour: string;
    tintColour: string;
    useRoundedCorners: boolean;
    titleTextColour?: string;
    bodyTextColour?: string;
}

export interface LayoutData {
    id: string;
    anchor: {
        properties: Layout;
    };
}

export function isLayoutData(layout: any): layout is LayoutData {
    return !!layout && isString(layout.id) && !!layout.anchor && !!layout.anchor.properties;
}
