import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@material-ui/core';
import { getCardTypeByIssuer } from 'src/utils/payment';
import { OrderPaymentType } from 'components/order/model/Order';
import { UserCard } from 'components/user/model/User';
import { PaymentMethodIcon } from 'lib/PaymentMethodIcon';
import { IPaymentOptionValue, ViewBillPaymentMethodsDialog } from './PaymentMethodDialog';

interface IProps {
    onPaymentMethodChange: (value: IPaymentOptionValue) => void;
    selectedPaymentMethod: IPaymentOptionValue;
    onAddNewCardClick: () => void;
    cards: UserCard[];
}

export const ViewBillPaymentMethod: React.FC<IProps> = ({
    onPaymentMethodChange,
    cards,
    onAddNewCardClick,
    selectedPaymentMethod
}) => {
    const { t } = useTranslation();
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);
    const toggleDialog = React.useCallback(() => setIsDialogOpen(!isDialogOpen), [isDialogOpen]);
    const renderSelectedPaymentMethod = React.useMemo(() => {
        const icon =
            (selectedPaymentMethod?.type === OrderPaymentType.GOOGLEPAY && OrderPaymentType.GOOGLEPAY) ||
            (selectedPaymentMethod?.type === OrderPaymentType.APPLEPAY && OrderPaymentType.APPLEPAY) ||
            (selectedPaymentMethod?.card && getCardTypeByIssuer(selectedPaymentMethod?.card.type));
        return (
            <Box display="flex" alignItems="center">
                {icon && (
                    <Box marginRight={1} display="flex" alignItems="center">
                        <PaymentMethodIcon width="30px" type={icon || ''} />
                    </Box>
                )}
                <Typography variant="body2">
                    {selectedPaymentMethod?.type === OrderPaymentType.GOOGLEPAY &&
                        t('PAYMENT_TYPE_GOOGLE_PAY')}
                    {selectedPaymentMethod?.type === OrderPaymentType.APPLEPAY && t('PAYMENT_TYPE_APPLE_PAY')}
                    {selectedPaymentMethod?.type === OrderPaymentType.CARD_ON_FILE &&
                        selectedPaymentMethod.card &&
                        `**** **** **** ${selectedPaymentMethod.card.last4}`}
                    {selectedPaymentMethod.type === OrderPaymentType.CARD_ON_FILE &&
                        !selectedPaymentMethod.card && (
                            <Button color="primary" variant="text" onClick={onAddNewCardClick}>
                                {t('CHECKOUT_ADD_CARD')}
                            </Button>
                        )}
                </Typography>
            </Box>
        );
    }, [onAddNewCardClick, selectedPaymentMethod.card, selectedPaymentMethod.type, t]);
    const handlePaymentMethodChange = React.useCallback(
        (paymentMethod: IPaymentOptionValue) => {
            if (paymentMethod.type === OrderPaymentType.CARD_ON_FILE && !paymentMethod.card) {
                onAddNewCardClick();
            }

            onPaymentMethodChange(paymentMethod);
        },
        [onAddNewCardClick, onPaymentMethodChange]
    );

    return (
        <>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                {renderSelectedPaymentMethod}
                <Button color="primary" variant="text" onClick={toggleDialog}>
                    {t('BILL_CHECKOUT_PAYMENT_CHANGE_BTN')}
                </Button>
            </Box>
            <ViewBillPaymentMethodsDialog
                onSelectPaymentMethod={handlePaymentMethodChange}
                cards={cards}
                isOpen={isDialogOpen}
                onClose={toggleDialog}
                userSelectedPaymentOption={selectedPaymentMethod}
            />
        </>
    );
};
