import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ButtonBase, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import moment from 'moment';
import { isDefined } from 'lib/typeInference';
import { useLocalHistory } from 'lib/useLocalHistory';
import { ROUTES } from 'pages/routes';

const useStyles = makeStyles((theme: Theme) => ({
    buttonBase: {
        width: '100%',
        color: theme.palette.primary.main,
        padding: theme.spacing(1, 0)
    },
    text: {
        paddingRight: theme.spacing(0.5),
        fontSize: '16px',
        fontWeight: 700,
        color: theme.palette.primary.main
    }
}));

interface PaySummaryReturnProps {
    tableNumber?: string;
    checkId?: string;
    balance?: number;
    createdAt?: Date;
}

export const PaySummaryReturn: React.FC<PaySummaryReturnProps> = ({
    checkId,
    balance,
    createdAt,
    tableNumber
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { push } = useLocalHistory();
    const handleClick = React.useCallback(() => {
        if (isDefined(checkId) && isDefined(createdAt)) {
            return push(
                ROUTES.QUICKPAY.CHECKOUT,
                undefined,
                `?dateCheckId=${moment(createdAt).format('YYYYMMDD')}${checkId}`
            );
        }
        if (isDefined(tableNumber)) {
            return push(ROUTES.QUICKPAY.CHECKOUT, undefined, `?tableNumber=${tableNumber}`);
        }
    }, [checkId, createdAt, push, tableNumber]);
    return (
        <Box flex={1}>
            <ButtonBase onClick={handleClick} className={classes.buttonBase}>
                <Box display="flex" flex={1} alignItems="center" justifyContent="center" paddingY={1}>
                    <ArrowBack color="inherit" />
                    <Typography className={classes.text} color="inherit">
                        {t('PAT_QUICKPAY_SUMMARY_RETURN', { balance })}
                    </Typography>
                </Box>
            </ButtonBase>
            <Box px={2}>
                <Divider />
            </Box>
        </Box>
    );
};
