import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextFormField } from 'lib/form/TextFormField';
import { ApplicationState } from 'store/store';
import { QuickPayButton } from '../ui/PayButton';
import { PayBaseModal } from './PayBaseModal';

interface PayTipsCustomModalProps {
    open: boolean;
    initialValue?: number;
    onClose: () => void;
    onSubmit: (tip: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    body: {
        fontSize: '15px',
        fontWeight: 700
    },
    input: {
        padding: theme.spacing(1.5, 2),
        paddingLeft: 0,
        textAlign: 'center'
    },
    textfield: {
        width: '86px',
        margin: theme.spacing(1, 0)
    }
}));

export const PayTipsCustomModal: React.FC<PayTipsCustomModalProps> = ({
    open,
    onClose,
    initialValue,
    onSubmit
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const currencySymbol = React.useMemo(
        () => settings?.region?.currencySymbol ?? '£',
        [settings?.region?.currencySymbol]
    );
    const initialValues = React.useMemo(
        () => ({ tip: initialValue === 0 ? undefined : initialValue }),
        [initialValue]
    );
    const validationSchema = React.useMemo(
        () =>
            Yup.object().shape({
                tip: Yup.number()
                    .required(t('FORMS_VALIDATION_REQUIRED'))
                    .typeError(t('FORMS_VALIDATION_REQUIRED'))
            }),
        [t]
    );
    const handleSubmit = React.useCallback(
        (data: { tip?: number }) => {
            onSubmit(Number(data.tip));
        },
        [onSubmit]
    );
    const startAdornment = React.useMemo(
        () => (
            <Box>
                <Typography>{currencySymbol}</Typography>
            </Box>
        ),
        [currencySymbol]
    );
    return (
        <PayBaseModal open={!!open} onClose={onClose}>
            <Box px={1} py={1.5}>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {({ submitForm, isValid }) => (
                        <Form>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <Typography className={classes.body}>
                                    {t('PAT_QUICKPAY_TIPS_CUSTOM_BODY')}
                                </Typography>
                                <TextFormField
                                    className={classes.textfield}
                                    type="number"
                                    name="tip"
                                    step={0.01}
                                    InputProps={{
                                        startAdornment,
                                        autoComplete: 'off',
                                        autoFocus: true,
                                        inputMode: 'numeric'
                                    }}
                                    inputProps={{ className: classes.input }}
                                />
                                <QuickPayButton disabled={!isValid} onClick={submitForm}>
                                    {t('BUTTON_SUBMIT')}
                                </QuickPayButton>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </PayBaseModal>
    );
};
