import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { updateLocalJourney } from 'components/journey/localStore';
import { LocationRouteParams } from 'pages/routes';

export const useLocalHistory = () => {
    const history = useHistory();
    const { merchantId, locationId, scenario } = useParams<LocationRouteParams>();
    const getParsedPath = React.useCallback(
        (path: string, overrideParams: Record<string, string> = {}) => {
            const params: Record<string, string | undefined> = {
                merchantId,
                locationId,
                scenario,
                ...overrideParams
            };
            return Object.keys(params).reduce(
                (acc, key) => acc.replace(new RegExp(`:${key}`, 'g'), params[key] ?? `:${key}`),
                path
            );
        },
        [merchantId, locationId, scenario]
    );

    const replace = React.useCallback(
        (
            path: string,
            params: Record<string, string> = {},
            searchState = '',
            state: Record<string, any> = {}
        ) => {
            const parsedPath = getParsedPath(path, params);
            updateLocalJourney(merchantId ?? '', { url: parsedPath });
            history.replace({ pathname: parsedPath, search: searchState, state });
        },
        [history, getParsedPath, merchantId]
    );
    const push = React.useCallback(
        (
            path: string,
            params: Record<string, string> = {},
            searchState = '',
            state: Record<string, any> = {}
        ) => {
            const parsedPath = getParsedPath(path, params);
            updateLocalJourney(merchantId ?? '', { url: parsedPath });
            history.push({ pathname: parsedPath, search: searchState, state });
        },
        [history, getParsedPath, merchantId]
    );
    return { push, history, getParsedPath, replace };
};
