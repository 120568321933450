import moment, { LocaleSpecification } from 'moment';
import { Timeslot } from './model/Timeslot';

export const localConfig: LocaleSpecification = {
    calendar: {
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastWeek: 'dddd',
        nextWeek: 'dddd [at] DD/MM/yyyy',
        sameElse: 'L'
    }
};

export function getDay(date: string | Date) {
    return moment(date, moment.HTML5_FMT.DATETIME_LOCAL_MS).calendar();
}

export function getTimeslotTimes(timeslot: Timeslot, t: (s: string) => string, full?: boolean) {
    let timeString: string;
    if (full) {
        timeString = `${getDay(timeslot.start)} ${moment
            .parseZone(timeslot.start)
            .format('h:mm a')} - ${moment.parseZone(timeslot.end).format('h:mm a')}`;
    } else {
        timeString = `${moment.parseZone(timeslot.start).format('h:mm a')} - ${moment
            .parseZone(timeslot.end)
            .format('h:mm a')}`;
    }
    return timeString.replace(/am/g, t('TIME_AM')).replace(/pm/g, t('TIME_PM'));
}

export function getDateFormatByCountry(countryCode: string): string {
    switch (countryCode) {
        case 'US':
            return 'MM/DD/yyyy';
        case 'UK':
        default:
            return 'DD/MM/yyyy';
    }
}
