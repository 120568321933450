import React from 'react';
import { Button, CircularProgress, Theme } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonProgress: {
            color: theme.palette.text.hint,
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12
        },
        fullWidth: { width: '100%' },
        wrapper: { position: 'relative' },
        gutterBottom: { marginBottom: theme.spacing(2) }
    })
);
export type LoadingButtonProps = ButtonProps & {
    loading?: boolean;
    gutterBottom?: boolean;
};
export const LoadingButton = (props: LoadingButtonProps) => {
    const { loading, gutterBottom, onClick, fullWidth, ...rest } = props;
    const classes = useStyles(props);
    return (
        <div
            className={clsx(classes.wrapper, {
                [classes.gutterBottom]: gutterBottom,
                [classes.fullWidth]: fullWidth
            })}
        >
            <Button {...rest} onClick={loading ? undefined : onClick} fullWidth={fullWidth} />
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
    );
};
