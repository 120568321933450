import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box, Paper } from '@material-ui/core';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { IOrderReadResourceV12, OrderScenario } from 'components/order/model/Order';
import { useTimeSlotHelpers } from 'components/timeslots/useTimeSlotHelpers';
import { ApplicationState } from 'store/store';
import { MenuList } from '../MenuList';
import { MenuViewBasketButton } from '../MenuViewBasketButton';
import { filterCategoryGroups, IEnrichedCategory, IEnrichedMenuWithModifierMaps } from '../model/Menu';
import { RefsMap } from './MenuDesktop';

interface IProps {
    menu: IEnrichedMenuWithModifierMaps;
    categories: IEnrichedCategory[];
    recentOrders: IOrderReadResourceV12[] | undefined;
    refsMap: RefsMap;
    setActiveCategory: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const MenuItems: React.FC<IProps> = ({
    menu,
    categories,
    recentOrders,
    setActiveCategory,
    refsMap
}) => {
    const settings = useSelector((state: ApplicationState) => state.settings.settings);
    const { isWorkingHour } = useLocationHelpers();
    const { isTimeSlotsAvailable, asapEnabled } = useTimeSlotHelpers();
    const [scenario] = useRouteScenario();

    const scenarioToUse = React.useMemo(() => scenario ?? OrderScenario.PREORDER, [scenario]);

    const validCategoryGroupsToShow = React.useMemo(
        () =>
            !!settings &&
            !!settings.app &&
            !!settings.app.showCategoryGroups &&
            !!menu &&
            !!menu.categories &&
            !!menu.categoryGroups &&
            filterCategoryGroups(Object.values(menu.categoryGroups), menu.categories),
        [menu, settings]
    );
    const hasCategoryGroups = React.useMemo(
        () => !!validCategoryGroupsToShow && validCategoryGroupsToShow.length > 1,
        [validCategoryGroupsToShow]
    );
    const showNotWorkingHour = React.useMemo(
        () =>
            !isWorkingHour ||
            (scenarioToUse === OrderScenario.PREORDER && !asapEnabled && !isTimeSlotsAvailable),
        [asapEnabled, isTimeSlotsAvailable, isWorkingHour, scenarioToUse]
    );

    return (
        <Box height="100%" position="relative">
            <Box paddingTop={2} height="100%" overflow="auto">
                <Paper>
                    <MenuList
                        recentOrders={recentOrders}
                        menu={menu}
                        currency={settings?.region.currencyCode || ''}
                        categories={categories}
                        isCollapsible={false}
                        refsMap={refsMap}
                        setActiveCategory={setActiveCategory}
                        hasCategoryGroups={hasCategoryGroups}
                        showNotWorkingHour={showNotWorkingHour}
                    />
                </Paper>
            </Box>
            <MenuViewBasketButton />
        </Box>
    );
};
