import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import AccessTime from '@material-ui/icons/AccessTime';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            display: 'flex',
            padding: theme.spacing(1.5),
            border: `1px solid ${theme.palette.primary.main}`,
            margin: theme.spacing(1.5),
            flexShrink: 0
        },
        icon: {
            color: theme.palette.primary.main,
            marginRight: theme.spacing(1)
        }
    })
);

export const OrderingDisabled: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.wrapper}>
            <AccessTime className={classes.icon} />
            <Typography>{t('DIALOG_ORDERING_DISABLED_MESSAGE')}</Typography>
        </div>
    );
};
