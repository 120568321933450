import React from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import Check from '@material-ui/icons/CheckCircleOutline';
import Circle from '@material-ui/icons/RadioButtonUnchecked';
import { isDefined } from 'lib/typeInference';
import { useCurrencyString } from 'lib/useCurrencyString';
import { IAdjustmentLocalResource } from 'components/order/model/Order';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1)
    },
    text: {
        fontSize: '14px',
        fontWeight: 400
    }
}));

interface PayRewardItemProps {
    item: IAdjustmentLocalResource;
    onClick: (id: number) => void;
    selected?: boolean;
}

export const PayRewardItem: React.FC<PayRewardItemProps> = ({ item, onClick, selected }) => {
    const classes = useStyles();
    const getCurrencyString = useCurrencyString();
    const handleClick = React.useCallback(() => {
        if (isDefined(item.id)) {
            onClick(item.id);
        }
    }, [item, onClick]);
    return (
        <Box onClick={handleClick} className={classes.root}>
            <Typography className={classes.text}>
                {item.description}
                {item.totalValue ? ` (${getCurrencyString(item.totalValue)})` : ''}
            </Typography>
            {selected ? <Check color="inherit" /> : <Circle color="inherit" />}
        </Box>
    );
};
