import * as React from 'react';
import { ERequestStatus } from 'src/enums';
import { IAsyncData } from 'src/models';

export function useAsyncData<T>(initialData?: T) {
    const [state, setState] = React.useState<IAsyncData<T>>(() => {
        if (initialData) {
            return {
                status: ERequestStatus.SUCCESS,
                data: initialData,
                error: null
            };
        }

        return {
            status: ERequestStatus.IDLE,
            data: null,
            error: null
        };
    });

    const serviceCall = React.useCallback((service: () => Promise<T>) => {
        setState(currentState => ({ ...currentState, status: ERequestStatus.PENDING, error: null }));

        return service()
            .then(response => {
                setState({
                    status: ERequestStatus.SUCCESS,
                    data: response,
                    error: null
                });

                return response;
            })
            .catch(error => {
                setState({
                    status: ERequestStatus.ERROR,
                    data: error.data,
                    error: error.data
                });

                return Promise.reject(error);
            });
    }, []);

    const setToInitial = React.useCallback(() => {
        setState({
            status: ERequestStatus.IDLE,
            data: null,
            error: null
        });
    }, []);

    return [state, serviceCall, setToInitial] as const;
}
