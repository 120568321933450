import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Checkbox,
    createStyles,
    FormControlLabel,
    IconButton,
    Input,
    InputLabel,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { usePrevious } from 'src/hooks/usePrevious';
import { ETipScheme } from 'components/settings/enums';
import { ISettings } from 'components/settings/model/Settings';
import { roundToDecimal } from 'lib/helpers';
import { isDefined, isNumber } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import { ViewBillTipSelector } from './ViewBillTipSelector';

interface IProps {
    onTipUpdate: (tipAmount: number, actualSelectorValue?: number) => void;
    tipping: ISettings['tipping'];
    currencySymbol?: string;
    total: number;
    useTipAsDefault: boolean;
    onUseTipAsDefaultChange: (value: boolean) => void;
    userDefaultSelectedValue?: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        customTipLabel: {
            color: theme.palette.text.primary,
            fontSize: theme.spacing(1.5)
        }
    })
);

export const ViewBillCheckoutTip: React.FC<IProps> = ({
    tipping,
    currencySymbol = '£',
    onTipUpdate,
    total,
    onUseTipAsDefaultChange,
    useTipAsDefault,
    userDefaultSelectedValue
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [selectorValue, setSelectorValue] = React.useState<number | null>(userDefaultSelectedValue || null);
    const [customTipValue, setCustomTipValue] = React.useState('');
    const [isDefaultSet, setIsDefaultSet] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    const { user } = useAuth();
    const previousTotal = usePrevious(total);

    const handleSelectorValueChange = React.useCallback(
        (value: number) => {
            onUseTipAsDefaultChange(false);
            if (tipping.scheme === ETipScheme.PERCENTAGE) {
                const tipValue = (total * value) / 100;
                onTipUpdate(tipValue, value);
                setCustomTipValue(roundToDecimal(tipValue).toFixed(2));
            } else {
                onTipUpdate(value, value);
                setCustomTipValue(value?.toFixed(2));
            }

            setSelectorValue(value);
        },
        [onUseTipAsDefaultChange, total, tipping.scheme, onTipUpdate]
    );
    const handleCustomTipChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            onUseTipAsDefaultChange(false);
            if (Number(event.target.value) >= 0) {
                setCustomTipValue(event.target.value);
            }
        },
        [onUseTipAsDefaultChange]
    );

    const handleInputBlur = React.useCallback(
        ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => {
            const tipValue = +value;
            const validatedValue = isNaN(tipValue) ? 0 : tipValue;
            setCustomTipValue(roundToDecimal(validatedValue).toFixed(2));
            onTipUpdate(validatedValue);
            setSelectorValue(null);
        },
        [onTipUpdate]
    );
    const handleClearTip = React.useCallback(() => {
        setCustomTipValue('');
        setSelectorValue(null);
        onTipUpdate(0);
    }, [onTipUpdate]);

    const usersDefaultTip = React.useMemo(() => {
        if (!!user && !!user.defaultTip) {
            if (user.defaultTip.scheme === ETipScheme.PERCENTAGE) {
                return roundToDecimal((total * user.defaultTip.amount) / 100).toString();
            }
            return roundToDecimal(user.defaultTip.amount).toString();
        }
    }, [total, user]);

    const valuesFromMerchant = React.useMemo(() => {
        if (!!tipping) {
            if (tipping.scheme === ETipScheme.PERCENTAGE) {
                return tipping.values.map(value => roundToDecimal((total * value) / 100).toString());
            }
            return tipping.values.map(value => value.toFixed(2));
        }
    }, [tipping, total]);

    const defaultFromMerchant = React.useMemo(
        () => tipping.values[tipping.default] ?? tipping.values[0],
        [tipping]
    );
    const handleCalculateDefault = React.useCallback(() => {
        const usersTipAsNumber = Number(usersDefaultTip);
        if (!!usersDefaultTip && !isNaN(usersTipAsNumber) && usersTipAsNumber >= 0) {
            setCustomTipValue(Number(usersDefaultTip).toFixed(2));
            onTipUpdate(usersTipAsNumber);
            if (!!valuesFromMerchant) {
                if (isDefined(userDefaultSelectedValue)) {
                    const usersDefaultFromSelector = valuesFromMerchant.find(
                        value => Number(value).toString() === usersTipAsNumber.toString()
                    );
                    if (!!usersDefaultFromSelector) {
                        handleSelectorValueChange(
                            tipping.values[valuesFromMerchant.indexOf(usersDefaultFromSelector)]
                        );
                    } else {
                        handleSelectorValueChange(defaultFromMerchant);
                    }
                } else if (isDefined(defaultFromMerchant)) {
                    handleSelectorValueChange(defaultFromMerchant);
                }
            }
        } else {
            handleSelectorValueChange(defaultFromMerchant);
        }
        setIsDefaultSet(true);
    }, [
        defaultFromMerchant,
        handleSelectorValueChange,
        onTipUpdate,
        tipping.values,
        userDefaultSelectedValue,
        usersDefaultTip,
        valuesFromMerchant
    ]);
    React.useEffect(() => {
        if (total !== previousTotal && isDefaultSet && selectorValue) {
            handleSelectorValueChange(selectorValue);
        }
    }, [
        handleCalculateDefault,
        handleSelectorValueChange,
        isDefaultSet,
        previousTotal,
        selectorValue,
        total
    ]);
    React.useEffect(() => {
        const formattedCustomTipValue = Number(customTipValue).toString();
        const formattedUsersDefaultTipValue = Number(usersDefaultTip).toString();
        if (!isDefaultSet && (!selectorValue || !customTipValue)) {
            handleCalculateDefault();
        }

        if (
            (!!valuesFromMerchant &&
                !valuesFromMerchant.map(val => Number(val).toString()).includes(formattedCustomTipValue)) ||
            formattedCustomTipValue === formattedUsersDefaultTipValue
        ) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }

        if (formattedCustomTipValue === formattedUsersDefaultTipValue) {
            onUseTipAsDefaultChange(true);
        } else {
            onUseTipAsDefaultChange(false);
        }
    }, [
        customTipValue,
        handleSelectorValueChange,
        isDefaultSet,
        onUseTipAsDefaultChange,
        selectorValue,
        tipping.default,
        tipping.values,
        total,
        usersDefaultTip,
        valuesFromMerchant,
        userDefaultSelectedValue,
        handleCalculateDefault
    ]);

    const handleUseAsDefaultChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onUseTipAsDefaultChange(event.target.checked);
        },
        [onUseTipAsDefaultChange]
    );

    return (
        <Box textAlign="center">
            {(total !== 0 || (total === 0 && tipping.scheme === ETipScheme.ABSOLUTE)) &&
                !!tipping.values &&
                isNumber(total) &&
                total > 0 && (
                    <>
                        <ViewBillTipSelector
                            tipping={tipping}
                            selected={selectorValue}
                            onTipOptionSelected={handleSelectorValueChange}
                        />
                        <Box display="flex" justifyContent="flex-start">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={handleUseAsDefaultChange}
                                        disabled={disabled}
                                        checked={useTipAsDefault}
                                    />
                                }
                                label={t('BILL_CHECKOUT_SAVE_TIP_AS_DEFAULT_LABEL')}
                            />
                        </Box>
                    </>
                )}
            <Box mt={2} mb={2}>
                <InputLabel className={classes.customTipLabel} htmlFor="tipAmount">
                    {t('BILL_CHECKOUT_CUSTOM_TIP_LABEL')}
                </InputLabel>
                <Input
                    id="tipAmount"
                    color="secondary"
                    value={customTipValue}
                    inputProps={{ min: '0' }}
                    onBlur={handleInputBlur}
                    type="number"
                    fullWidth
                    onChange={handleCustomTipChange}
                    startAdornment={
                        <Box>
                            <Typography>{currencySymbol}</Typography>
                        </Box>
                    }
                    inputMode="decimal"
                    endAdornment={
                        <IconButton onClick={handleClearTip} size="small" tabIndex={-1}>
                            <Close />
                        </IconButton>
                    }
                />
            </Box>
        </Box>
    );
};
