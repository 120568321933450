import React from 'react';
import { IBasketRequestResource, IBasketResourceV10, filterBasket } from '../model/Basket';
import { ApplicationState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { isDefined } from '@pepperhq/location-sdk/dist/lib/typeInference';
import { basketApi } from '../basketApi';
import { AxiosResponse } from 'axios';
import { useAuth } from 'lib/useAuth';
import { getLocalMerchantId } from 'components/settings/localStore';
import { getShouldUpdateGuestSession, resetShouldUpdateGuestSession } from 'components/user/localAuth';
import { createGuestSession } from 'store/auth/authActions';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { OrderScenario } from 'components/order/model/Order';
import { useSnackbar } from 'notistack';

export function useCalculateBasket(menuIsReady: boolean) {
    const dispatch = useDispatch();
    const { isGuest, isLoading: isAuthLoading } = useAuth();
    const [scenarioFromRoute] = useRouteScenario();
    const { enqueueSnackbar } = useSnackbar();
    const scenario = React.useMemo(() => scenarioFromRoute ?? OrderScenario.PREORDER, [scenarioFromRoute]);
    const [calculatedBasket, setCalculatedBasket] = React.useState<null | IBasketResourceV10>(null);
    const [loading, setLoading] = React.useState(true);
    const { menu, categories, isLoading } = useSelector((state: ApplicationState) => state.menu);
    const basket = useSelector((state: ApplicationState) => state.basket);
    React.useEffect(() => {
        if (basket.items.length === 0) {
            setLoading(false);
            setCalculatedBasket(null);
            return;
        }
        if (isLoading || !menuIsReady) {
            return;
        }
        setLoading(true);
        function basketCall() {
            const { items: filteredItems } = filterBasket(
                categories,
                // eslint-disable-next-line unused-imports/no-unused-vars-ts
                basket.items.map(({ price, ...item }) => item),
                menu
            );
            const finalBasket: IBasketRequestResource = {
                locationId: basket.locationId,
                scenario,
                items: filteredItems
            };

            if (isDefined(basket.deliveryLocation)) {
                finalBasket.deliveryLocation = basket.deliveryLocation;
            }
            if (isDefined(basket.timeSlot) && scenario !== OrderScenario.ORDER_TO_TABLE) {
                finalBasket.timeSlot = {
                    start: basket.timeSlot.start,
                    end: basket.timeSlot.end
                };
            }

            basketApi
                .calculateBasket(finalBasket)
                .then(newlyCalculatedBasket => {
                    setCalculatedBasket(newlyCalculatedBasket);
                })
                .catch((err: AxiosResponse<{ code: string; httpCode: number; message: string }>) => {
                    if (err.data.code === 'E-ORD-0358') {
                        enqueueSnackbar(err.data.message, { variant: 'error' });
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        if (isGuest) {
            const merchantId = getLocalMerchantId();
            if (merchantId) {
                const shouldUpdateGuestSession = getShouldUpdateGuestSession(merchantId);
                if (shouldUpdateGuestSession) {
                    createGuestSession(dispatch).then(() => {
                        resetShouldUpdateGuestSession(merchantId);
                        basketCall();
                    });
                    return;
                }
            }
        }

        basketCall();
    }, [
        basket.deliveryLocation,
        basket.items,
        basket.locationId,
        basket.timeSlot,
        categories,
        dispatch,
        enqueueSnackbar,
        isGuest,
        isLoading,
        menu,
        menuIsReady,
        scenario
    ]);
    const isAnythingLoading = React.useMemo(
        () => loading || isLoading || isAuthLoading,
        [isAuthLoading, isLoading, loading]
    );
    return {
        calculatedBasket,
        loading: isAnythingLoading,
        scenario
    };
}
