import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

interface LoadingTypographyProsp extends TypographyProps {
    loading?: boolean;
    width?: number;
}

export const LoadingTypography: React.FC<LoadingTypographyProsp> = ({
    loading,
    width,
    className,
    ...props
}) => {
    if (loading) {
        return <Skeleton className={className} variant="text" width={width} />;
    }
    return <Typography className={className} {...props} />;
};
