import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import pick from 'lodash/pick';
import smartlookClient from 'smartlook-client';
import { useQuery } from 'src/hooks/useQuery';
import { ApplePayPaymentDetails, isApplePayReady } from 'src/integrations/PaymentProvider/ApplePayButton';
import { DigitalWallets } from 'src/integrations/PaymentProvider/DigitalWallets';
import { IntegratedDigitalWallets } from 'src/integrations/PaymentProvider/IntegratedDigitalWallets';
import { IPaymentProvider, paymentProviderFactory } from 'src/integrations/PaymentProvider/PaymentProvider';
import { browser } from 'src/utils/browser';
import { CardPayment } from 'components/basket/CardPayment';
import { GiftCard } from 'components/basket/GiftCard';
import { IAdjustmentReadResourceV10, OrderAdjustmentType } from 'components/basket/model/Basket';
import {
    IOrderCreatePaymentV8,
    IOrderPaymentReadResource,
    IOrderReadResourceV12,
    IUpdateOrderAdjustment,
    IUpdateOrderDetails,
    OrderPaymentType,
    OrderScenario,
    SplittableItem
} from 'components/order/model/Order';
import { EOrderNonceKey, legacyOrderApi } from 'components/order/orderApi';
import { orderService } from 'components/order/orderService';
import { ETipScheme } from 'components/settings/enums';
import { getLocalMerchantId } from 'components/settings/localStore';
import { EPaymentProvider, ITip } from 'components/settings/model/Settings';
import { setShouldUpdateGuestSession } from 'components/user/localAuth';
import { UserCard } from 'components/user/model/User';
import { userApi } from 'components/user/userApi';
import { CONTENT_MAX_WIDTH } from 'config/constants';
import { ConfirmDialog } from 'lib/ConfirmDialog';
import { addOpacity, roundToDecimal } from 'lib/helpers';
import { InnerPageLayout } from 'lib/InnerPageLayout';
import { InnerPageLayoutContent } from 'lib/InnerPageLayout/InnerPageLayoutContent';
import logger from 'lib/logger';
import { Throbber } from 'lib/Throbber';
import { isDefined, isString } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import { useCurrencyString } from 'lib/useCurrencyString';
import { useLocalHistory } from 'lib/useLocalHistory';
import { ROUTES } from 'pages/routes';
import { getUserCard, updateUser } from 'store/auth/authActions';
import { resetBasket } from 'store/basket/basketActions';
import { setLastRequest } from 'store/request/requestActions';
import { SettingsStateSettings } from 'store/settings/settingsReducer';
import { ApplicationState } from 'store/store';
import { orderDetailsToUpdateMapper } from '../../utils';
import { GuestCheckout } from '../GuestCheckout';
import { ViewBillContentSection } from '../ViewBillContentSection';
import { ViewBillHeader } from '../ViewBillHeader';
import { ViewBillHeaderInfo } from '../ViewBillHeaderInfo';
import { ViewBillOrderItem } from '../ViewBillOrderItem';
import { IPaymentOptionValue } from './PaymentMethod/PaymentMethodDialog';
import { ViewBillPaymentMethod } from './PaymentMethod';
import { ViewBillCheckoutTip } from './Tip';
import { ViewBillCheckoutRewardList } from './ViewBillCheckoutRewardList';
import { GooglePayPaymentDetails } from 'src/integrations/GooglePay';
import { useSnackbar } from 'notistack';
import { useTransactionLimitValidation } from 'components/basket/hooks/useTransactionLimitValidation';

// TODO: move it to the PaymentProvider if we get another 3DS
const getChallengeUrl = () =>
    `${process.env.ORDER_SERVICE_URL}/worldpay/3ds/redirectVerification?redirectUrl=${window.location.origin}${ROUTES.WORLDPAY}`;

interface IProps {
    orderDetails: IOrderReadResourceV12;
    onRewardSelected: (reward: IProps['selectedReward']) => void;
    selectedReward?: IAdjustmentReadResourceV10 | undefined;
    onClickBack: () => void;
    onAuthActionClick?: () => Promise<any>;
    userSelectedTipValue: number | undefined;
    isLoading: boolean;
    onApplyGiftCard: (giftCardNumber: string | undefined) => void;
    giftCard: string | undefined;
    onTipChange: (tipValue: number, selectorValue?: number) => void;
    tipValue: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        boldText: {
            fontWeight: theme.typography.fontWeightBold
        },
        indicator: {
            position: 'fixed',
            left: '50%',
            bottom: 0,
            right: 0,
            textAlign: 'center',
            background: `linear-gradient(180deg, ${theme.palette.background.default} 0%, ${addOpacity(
                theme.palette.background.default,
                0.5
            )} 100%)`,
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            transform: 'translateX(-50%)',
            maxWidth: `${CONTENT_MAX_WIDTH}px`,
            width: '100%'
        },
        notReaddyToPayButton: {
            borderRadius: theme.shape.borderRadius,
            marginBottom: theme.spacing(1)
        }
    })
);

const getPaymentOptionInitialState = (settings: SettingsStateSettings | undefined) => {
    const isSquare = settings?.paymentProvider === 'SQUARE';
    const isJudo = settings?.paymentProvider === 'JUDOPAY';
    const isStripe = settings?.paymentProvider === 'STRIPE';

    if (settings?.googlePayEnabled && browser().isChrome) {
        return { type: OrderPaymentType.GOOGLEPAY };
    }

    // There is no chance to have a Safari on the non-apple device, but just to doublecheck
    if ((isSquare || isJudo || isStripe) && settings?.applePayEnabled && isApplePayReady()) {
        return { type: OrderPaymentType.APPLEPAY };
    }
    return { type: OrderPaymentType.CARD_ON_FILE };
};

export const ViewBillCheckout: React.FC<IProps> = ({
    orderDetails,
    onRewardSelected,
    selectedReward,
    onClickBack,
    onAuthActionClick,
    userSelectedTipValue,
    isLoading,
    onApplyGiftCard,
    giftCard,
    onTipChange,
    tipValue
}) => {
    const [isSmartlookSet, setIsSmartlookSet] = React.useState(false);
    const classes = useStyles();
    const { t } = useTranslation();
    const { push } = useLocalHistory();
    const { user, isGuest, isVerifiedGuest, isCardLoading, card } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const lastItemRef = React.useRef<HTMLDivElement>(null);
    const [indicatorVisibility, setIndicatorVisibility] = React.useState(true);
    const [isConfirmationOpen, setIsConfirmationOpen] = React.useState(false);
    const [useTipAsDefault, setUseTipAsDefault] = React.useState(!!user?.defaultTip);
    const [cardsInitialLoaded, setCardsInitialLoaded] = React.useState(false);
    const getCurrencyString = useCurrencyString();
    const dispatch = useDispatch();
    const isSplitByPercentage = useQuery('splitPart');
    const isSplitBillItem = useQuery('isSplitBillItem');
    const { currentLocation } = useSelector((state: ApplicationState) => state.locations);
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { lastRequest } = useSelector((state: ApplicationState) => state.request);
    const [provider, setProvider] = React.useState<IPaymentProvider>();
    const userCards = React.useMemo(() => (card ? [card] : []), [card]);

    const handleTipChange = React.useCallback(
        (value: number, selectorValue?: number) => {
            onTipChange(value, selectorValue);
        },
        [onTipChange]
    );

    const tipToUse = React.useMemo(() => roundToDecimal(tipValue), [tipValue]);
    const isSplitting = React.useMemo(
        () => isSplitByPercentage || isSplitBillItem,
        [isSplitBillItem, isSplitByPercentage]
    );

    React.useEffect(() => {
        if (settings && !provider) {
            paymentProviderFactory.create(settings).then(paymentProvider => {
                setProvider(paymentProvider);
            });
        }
        return () => {
            if (provider && provider.reset) {
                provider.reset();
            }
        };
    }, [provider, provider?.reset, settings]);
    const total = React.useMemo(() => {
        if ((isSplitByPercentage || isSplitBillItem === 'true') && orderDetails.splitTotal) {
            return { ...orderDetails.splitTotal, balance: orderDetails.splitTotal.balance };
        }
        return orderDetails.total;
    }, [isSplitBillItem, isSplitByPercentage, orderDetails.splitTotal, orderDetails.total]);
    const isPayAtTableEnabled = React.useMemo(
        () => currentLocation && currentLocation.services.pay_at_table,
        [currentLocation]
    );
    const payments = React.useMemo(
        () =>
            orderDetails.payments.reduce<{ card: number; cash: number }>(
                (init, { type, amount, auth }) => {
                    if (type === OrderPaymentType.CASH_AT_POS || type === OrderPaymentType.CASH) {
                        init.cash += amount || 0;
                    } else {
                        if (type === OrderPaymentType.GIFT_CARD) {
                            if (!!auth) {
                                return init;
                            }
                        }
                        init.card += amount || 0;
                    }

                    return init;
                },
                { cash: 0, card: 0 }
            ),
        [orderDetails.payments]
    );
    const userClaimedItems = React.useMemo(() => {
        if (user) {
            const items = orderService.getSplittableItemMap(orderDetails, user);
            return items.filter(item => !item.isPaid && item.claimedBy?.userId === user?._id);
        }
        return [];
    }, [orderDetails, user]);
    const handleToggleConfirmationDialog = React.useCallback(
        () => setIsConfirmationOpen(!isConfirmationOpen),
        [isConfirmationOpen]
    );
    const renderOrderItem = React.useCallback(
        (item: SplittableItem) => (
            <ViewBillOrderItem showPricePerOne orderItem={item} allItems={orderDetails.items} />
        ),
        [orderDetails.items]
    );
    const handleBackClick = React.useCallback(() => {
        onClickBack();
    }, [onClickBack]);

    const [selectedPaymentOption, setSelectedPaymentOption] = React.useState<IPaymentOptionValue>(() =>
        getPaymentOptionInitialState(settings)
    );
    const shouldUseBalanceToPay = React.useMemo(
        () => (!isSplitting && total.total !== total.balance) || (isSplitting && total.total > total.balance),
        [isSplitting, total.balance, total.total]
    );
    const finalizedBillWithAdjustments: IUpdateOrderDetails = React.useMemo(() => {
        const newOrderDetails: IUpdateOrderDetails = orderDetailsToUpdateMapper({
            ...orderDetails,
            total
        });
        newOrderDetails.adjustments = [];
        if (selectedReward && selectedReward.type === OrderAdjustmentType.DISCOUNT) {
            const { type, quantity, awardId } = selectedReward;
            if (quantity && awardId) {
                newOrderDetails.adjustments.push({ type, quantity, awardId });
            }
        }

        if (tipToUse !== 0) {
            const tipAdjustment: IUpdateOrderAdjustment = { type: OrderAdjustmentType.TIP, value: tipToUse };
            newOrderDetails.adjustments.push(tipAdjustment);
            newOrderDetails.total.total =
                newOrderDetails.total.balance < newOrderDetails.total.total
                    ? newOrderDetails.total.balance
                    : newOrderDetails.total.total;
        } else {
            newOrderDetails.total.total =
                newOrderDetails.total.balance < newOrderDetails.total.total
                    ? Number(newOrderDetails.total.balance)
                    : Number(newOrderDetails.total.total.toFixed(2));
            if (total.balance) {
                newOrderDetails.total.balance = Number(newOrderDetails.total.balance.toFixed(2));
            }
        }
        return newOrderDetails;
    }, [orderDetails, selectedReward, tipToUse, total]);
    const [addNewCardOpen, setAddNewCardOpen] = React.useState(false);
    const { isTippingEnabled, tippingToUse } = React.useMemo(() => {
        if (!!settings) {
            let finalTippingEnabled: boolean = settings.tippingEnabled;
            let finalTipping: ITip = settings.tipping;

            switch (orderDetails.scenario) {
                case OrderScenario.TABLE: {
                    finalTippingEnabled = settings.tippingPayAtTableEnabled ?? settings.tippingEnabled;
                    finalTipping = settings.tippingPayAtTable ?? settings.tipping;
                    break;
                }
                case OrderScenario.TAB: {
                    finalTippingEnabled = settings.tippingTabEnabled ?? settings.tippingEnabled;
                    finalTipping = settings.tippingTab ?? settings.tipping;
                    break;
                }
                default: {
                    break;
                }
            }

            return { isTippingEnabled: finalTippingEnabled, tippingToUse: finalTipping };
        }

        return {};
    }, [settings, orderDetails.scenario]);
    const finalTipAmount = React.useMemo(
        () =>
            !!tippingToUse && !!total && tippingToUse.scheme === ETipScheme.PERCENTAGE
                ? roundToDecimal((tipToUse / total.total) * 100, 1)
                : tipToUse,
        [tipToUse, tippingToUse, total]
    );
    const giftCardPayment = React.useMemo(
        () =>
            orderDetails.payments &&
            orderDetails.payments.find(payment => payment.type === OrderPaymentType.GIFT_CARD) &&
            orderDetails.payments.find(payment => !!payment.auth),
        [orderDetails.payments]
    );
    const hasGiftCardApplied = React.useMemo(() => giftCardPayment && giftCard, [giftCard, giftCardPayment]);

    const totalToPay = React.useMemo(() => {
        const orderPrice = shouldUseBalanceToPay ? total.balance : total.total;

        if (hasGiftCardApplied && giftCardPayment && isDefined(giftCardPayment?.amount)) {
            return orderPrice;
        }
        return orderPrice + tipValue;
    }, [giftCardPayment, hasGiftCardApplied, shouldUseBalanceToPay, tipValue, total.balance, total.total]);
    const handleSaveDefaultTip = React.useCallback(async () => {
        if (!!user && !!tippingToUse && useTipAsDefault && finalTipAmount) {
            try {
                const userResult = await userApi.updateUser(user._id, {
                    defaultTip: { amount: finalTipAmount, scheme: tippingToUse.scheme }
                });
                return dispatch(updateUser(userResult));
            } catch (error) {
                logger.warn(error);
            }
        }
    }, [user, tippingToUse, useTipAsDefault, finalTipAmount, dispatch]);
    const handlePlaceOrder = React.useCallback(
        (
                paymentType: OrderPaymentType,
                _paymentCard: UserCard | null = null,
                options?: {
                    auth?: { nonce?: string; threeDSecureData?: { verificationToken: string } };
                    wallet?:
                        | GooglePayPaymentDetails
                        | ApplePayPaymentDetails
                        | { worldpay: GooglePayPaymentDetails | ApplePayPaymentDetails };
                }
            ) =>
            () => {
                async function completeOrderPayment(paymentNonce: string | null) {
                    // Update default tips if checked
                    await handleSaveDefaultTip();
                    // Place order
                    try {
                        const paymentsToApply: IOrderCreatePaymentV8[] = [];
                        const payment: IOrderCreatePaymentV8 = {
                            type: paymentType,
                            amount: totalToPay,
                            ...(isSplitByPercentage && { splits: Number(isSplitByPercentage) })
                        };
                        if (options?.auth) {
                            payment.auth = !!paymentNonce
                                ? { ...options.auth, nonce: paymentNonce }
                                : options.auth;
                        }
                        if (options?.wallet) {
                            payment.wallet = options.wallet;
                        }
                        if (!!provider && !payment?.auth) {
                            const deviceDataResult = await provider.collectDeviceData();

                            if (!!deviceDataResult) {
                                const { sessionId, transactionReference } = deviceDataResult;

                                if (isString(sessionId)) {
                                    payment.auth = {
                                        threeDSecureData: {
                                            deviceData: {
                                                collectionReference: sessionId,
                                                userAgentHeader: window.navigator.userAgent
                                            },
                                            challenge: {
                                                returnUrl: getChallengeUrl()
                                            },
                                            transactionReference
                                        }
                                    };
                                }
                            }
                        }

                        if (hasGiftCardApplied) {
                            if (payment.amount !== 0) {
                                paymentsToApply.push(payment);
                            }
                            paymentsToApply.push(
                                pick(giftCardPayment, [
                                    'auth',
                                    'provider',
                                    'type',
                                    'amount'
                                ]) as IOrderPaymentReadResource
                            );
                        } else {
                            paymentsToApply.push(payment);
                        }

                        const orderBody = {
                            adjustments: finalizedBillWithAdjustments.adjustments,
                            deliveryLocation: finalizedBillWithAdjustments.deliveryLocation,
                            payments: paymentsToApply
                        };
                        if (isSplitBillItem && user) {
                            const items = orderService.getSplittableItemMap(orderDetails, user);
                            const claimedItemIds = orderService.getUserClaimedItemIds(items, user);

                            payment.itemIds = [...claimedItemIds];
                        }
                        if (useTipAsDefault && userSelectedTipValue) {
                            if (!!user && !!tippingToUse) {
                                try {
                                    const userResult = await userApi.updateUser(user._id, {
                                        defaultTip: {
                                            amount: userSelectedTipValue,
                                            scheme: tippingToUse.scheme
                                        }
                                    });
                                    dispatch(updateUser(userResult));
                                } catch (error) {
                                    logger.warn(error);
                                }
                            }
                        }
                        const operation = await legacyOrderApi.updateOrder(orderDetails.id, orderBody);
                        if (isDefined(operation.id)) {
                            if (isGuest) {
                                const merchantId = getLocalMerchantId();
                                if (merchantId) {
                                    setShouldUpdateGuestSession(merchantId);
                                }
                            }

                            push(
                                ROUTES.ORDER.OPERATION,
                                {
                                    operationId: operation.id.toString()
                                },
                                '',
                                { orderBodyToRecreatePat: { ...orderBody, id: orderDetails.id } }
                            );
                        }
                    } catch (err) {
                        if (!err) {
                            handleToggleConfirmationDialog();
                        } else {
                            enqueueSnackbar(t('CHECKOUT_ERROR_PLACING_ORDER'), { variant: 'error' });
                        }
                    }
                }

                completeOrderPayment(null);
            },
        [
            dispatch,
            enqueueSnackbar,
            finalizedBillWithAdjustments.adjustments,
            finalizedBillWithAdjustments.deliveryLocation,
            giftCardPayment,
            handleSaveDefaultTip,
            handleToggleConfirmationDialog,
            hasGiftCardApplied,
            isGuest,
            isSplitBillItem,
            isSplitByPercentage,
            orderDetails,
            provider,
            push,
            t,
            tippingToUse,
            totalToPay,
            useTipAsDefault,
            user,
            userSelectedTipValue
        ]
    );
    const handleCardAdded = React.useCallback(
        (paymentType: OrderPaymentType, selectedCard: UserCard | null) => () => {
            if (paymentType === OrderPaymentType.CARD_ON_FILE && selectedCard) {
                setSelectedPaymentOption({ card: selectedCard, type: paymentType });
            }
        },
        []
    );
    const handleAddNewCardClose = React.useCallback(() => {
        setAddNewCardOpen(false);
    }, []);

    const handlePayMyBillWithCard = React.useCallback(() => {
        if (selectedPaymentOption.card) {
            if (
                provider &&
                settings &&
                settings?.paymentProvider === EPaymentProvider.SQUARE &&
                user &&
                user?.card
            ) {
                userApi.getCards(user.id).then(res => {
                    const userSelectedCard = res.cards.find(
                        c =>
                            selectedPaymentOption.card &&
                            selectedPaymentOption.card._id &&
                            c._id === selectedPaymentOption.card._id
                    );
                    if (userSelectedCard?.cardId) {
                        provider.threeDSecureAuthenticate(
                            {
                                square: {
                                    cardId: userSelectedCard.cardId,
                                    intent: 'CHARGE',
                                    currency: settings.region.currencyCode,
                                    contact: {
                                        givenName: user?.firstName,
                                        familyName: user?.lastName
                                    },
                                    onVerificationEnd: (err, result) => {
                                        if (err || !result) {
                                            enqueueSnackbar(err?.message ?? 'Something went wrong', {
                                                variant: 'error'
                                            });
                                        } else {
                                            const createOrderFunction = handlePlaceOrder(
                                                OrderPaymentType.CARD_ON_FILE,
                                                userSelectedCard,
                                                {
                                                    auth: {
                                                        threeDSecureData: {
                                                            verificationToken: result.token
                                                        }
                                                    }
                                                }
                                            );
                                            setLastRequest(createOrderFunction)(dispatch);
                                            createOrderFunction();
                                        }
                                    }
                                }
                            },
                            {
                                payments: [
                                    {
                                        amount: totalToPay,
                                        type: OrderPaymentType.CARD_ON_FILE
                                    }
                                ]
                            }
                        );
                    }
                });
            } else {
                const createOrderFunction = handlePlaceOrder(
                    // paymentCreation
                    OrderPaymentType.CARD_ON_FILE,
                    selectedPaymentOption.card
                );
                setLastRequest(createOrderFunction)(dispatch);
                createOrderFunction();
            }
        } else {
            setAddNewCardOpen(true);
        }
    }, [
        dispatch,
        enqueueSnackbar,
        handlePlaceOrder,
        provider,
        selectedPaymentOption.card,
        settings,
        totalToPay,
        user
    ]);
    const handleAddNewCard = React.useCallback(() => setAddNewCardOpen(true), []);
    const handleDigitalPayment = React.useCallback(
        (cardNonce: string, walletType: OrderPaymentType) => {
            const createOrderFunction = handlePlaceOrder(walletType, null, { auth: { nonce: cardNonce } });
            setLastRequest(createOrderFunction)(dispatch);
            createOrderFunction();
        },
        [dispatch, handlePlaceOrder]
    );

    const handleJudoDigitalPayment = React.useCallback(
        (data: GooglePayPaymentDetails | ApplePayPaymentDetails, walletType: OrderPaymentType) => {
            const createOrderFunction = handlePlaceOrder(
                walletType,
                null,
                settings?.paymentProvider === EPaymentProvider.WORLDPAY
                    ? {
                          wallet: { worldpay: data }
                      }
                    : { wallet: data }
            );
            setLastRequest(createOrderFunction)(dispatch);
            createOrderFunction();
        },
        [dispatch, handlePlaceOrder, settings?.paymentProvider]
    );

    const handleIndicatorClick = React.useCallback(() => {
        if (lastItemRef.current) {
            lastItemRef.current.scrollIntoView();
        }
    }, []);

    const paymentInfoStrings = React.useMemo(() => {
        const strings: [string, string, boolean?][] = [];
        const orderPrice = shouldUseBalanceToPay ? total.balance : total.total;

        if (isSplitByPercentage) {
            strings.push([
                t('BILL_CHECKOUT_PAYMENT_INFO_SPLIT_BY_PERCENTAGE'),
                getCurrencyString(total.cost)
            ]);
        } else {
            strings.push([t('BILL_CHECKOUT_PAYMENT_INFO_ITEMS'), getCurrencyString(total.cost)]);
        }

        if (total.charges !== 0) {
            strings.push([t('BILL_CHECKOUT_PAYMENT_INFO_CHARGES'), getCurrencyString(total.charges)]);
        }

        if (total.discounts !== 0) {
            strings.push([t('BILL_CHECKOUT_PAYMENT_INFO_DISCOUNTS'), getCurrencyString(total.discounts)]);
        }

        if (total.taxes.length > 0) {
            total.taxes
                .filter(tax => !tax.inclusive)
                .forEach(tax => strings.push([tax.name, getCurrencyString(tax.amount)]));
        }

        if (total.total > total.balance) {
            if (!isSplitByPercentage && (payments.cash || payments.card)) {
                strings.push([
                    t('BILL_CHECKOUT_PAYMENT_INFO_PAYMENTS'),
                    `-${getCurrencyString(payments.cash + payments.card)}`
                ]);
            }

            strings.push([t('BILL_CHECKOUT_PAYMENT_INFO_BALANCE'), getCurrencyString(total.balance)]);
        }
        if (tipToUse > 0) {
            strings.push([t('BILL_CHECKOUT_PAYMENT_INFO_TIP'), getCurrencyString(tipToUse)]);
        }

        if (hasGiftCardApplied && giftCardPayment && isDefined(giftCardPayment?.amount)) {
            strings.push([t('CHECKOUT_GIFTCARD_SUMMARY'), `-${getCurrencyString(giftCardPayment.amount)}`]);
            strings.push([
                t('BILL_CHECKOUT_PAYMENT_INFO_TOTAL_TO_PAY'),
                getCurrencyString(finalizedBillWithAdjustments.total.balance),
                true
            ]);
        } else {
            strings.push([
                t('BILL_CHECKOUT_PAYMENT_INFO_TOTAL_TO_PAY'),
                getCurrencyString(orderPrice + tipValue),
                true
            ]);
        }

        return strings;
    }, [
        finalizedBillWithAdjustments.total.balance,
        getCurrencyString,
        giftCardPayment,
        hasGiftCardApplied,
        isSplitByPercentage,
        payments.card,
        payments.cash,
        shouldUseBalanceToPay,
        t,
        tipToUse,
        tipValue,
        total.balance,
        total.charges,
        total.cost,
        total.discounts,
        total.taxes,
        total.total
    ]);

    React.useEffect(() => {
        if (user && user.id) {
            getUserCard(user.id)(dispatch).then(() => {
                setCardsInitialLoaded(true);
            });
        }
    }, [dispatch, user]);

    React.useEffect(() => {
        if (card) {
            setSelectedPaymentOption({ type: OrderPaymentType.CARD_ON_FILE, card });
        } else {
            setSelectedPaymentOption(getPaymentOptionInitialState(settings));
        }
    }, [settings, card]);

    React.useEffect(() => {
        if (!isSmartlookSet && settings?.smartlookEnabled && ((isGuest && isVerifiedGuest) || !isGuest)) {
            try {
                // Can be populated as this view is implemented for all screens
                switch (orderDetails.scenario) {
                    case OrderScenario.TABLE:
                        smartlookClient.track('PAT_checkout', { merchantId: getLocalMerchantId() ?? '' });
                        break;

                    default:
                        setIsSmartlookSet(true);
                        break;
                }
            } catch (err) {
                logger.error(err);
            }
        }
    }, [isGuest, isSmartlookSet, isVerifiedGuest, orderDetails.scenario, settings?.smartlookEnabled]);

    const handleRetry = React.useCallback(() => {
        if (lastRequest) {
            lastRequest();
            handleToggleConfirmationDialog();
        }
    }, [handleToggleConfirmationDialog, lastRequest]);
    const handleErrorDialogClose = React.useCallback(() => {
        resetBasket(dispatch);
        legacyOrderApi.resetNonceByKey([EOrderNonceKey.UPDATE_ORDER]);
        push(ROUTES.JOURNEY.LANDING);
    }, [dispatch, push]);

    const handleUseTipAsDefaultChange = React.useCallback((value: boolean) => {
        setUseTipAsDefault(value);
    }, []);

    const {
        isOutOfLimit,
        handleExceededLimitDialogClose,
        handleValidateTransactionLimit,
        showExceededLimitDialog,
        transactionLimitDescription
    } = useTransactionLimitValidation(totalToPay);

    const readyToPay = React.useMemo(() => !isOutOfLimit, [isOutOfLimit]);

    return (
        <>
            <InnerPageLayout disabled={!isPayAtTableEnabled}>
                <ViewBillHeader
                    onAuthActionClick={onAuthActionClick}
                    title={t('BILL_CHECKOUT_TITLE')}
                    onBack={handleBackClick}
                />
                <Box paddingTop={2}>
                    <ViewBillHeaderInfo />
                </Box>
                <InnerPageLayoutContent>
                    <Throbber
                        isLoading={isLoading || isCardLoading || !cardsInitialLoaded}
                        text={t('GENERAL_LOADING')}
                        isOverlay
                    />
                    {settings?.billSplittingByItemEnabled && isSplitBillItem && (
                        <ViewBillContentSection bottomSpacing={2}>
                            {orderDetails.items.length > 0 &&
                                userClaimedItems.length > 0 &&
                                userClaimedItems.map(renderOrderItem)}
                        </ViewBillContentSection>
                    )}
                    <ViewBillCheckoutRewardList
                        selectedReward={selectedReward}
                        onRewardSelected={onRewardSelected}
                        availableRewards={orderDetails.availableAdjustments}
                        allAdjustments={orderDetails.adjustments}
                    />
                    {isTippingEnabled && !!tippingToUse && !!settings && (
                        <ViewBillContentSection
                            title={t('BILL_CHECKOUT_TIPS_TITLE')}
                            subtitle={t('BILL_CHECKOUT_TIPS_BODY')}
                            bottomSpacing={2}
                        >
                            <ViewBillCheckoutTip
                                useTipAsDefault={useTipAsDefault}
                                onUseTipAsDefaultChange={handleUseTipAsDefaultChange}
                                total={total.total}
                                tipping={tippingToUse}
                                onTipUpdate={handleTipChange}
                                currencySymbol={settings.region.currencySymbol}
                                userDefaultSelectedValue={userSelectedTipValue}
                            />
                        </ViewBillContentSection>
                    )}
                    <ViewBillContentSection title={t('BILL_CHECKOUT_PAYMENT_METHOD_TITLE')} bottomSpacing={2}>
                        <ViewBillPaymentMethod
                            cards={userCards}
                            selectedPaymentMethod={selectedPaymentOption}
                            onPaymentMethodChange={setSelectedPaymentOption}
                            onAddNewCardClick={handleAddNewCard}
                        />
                    </ViewBillContentSection>
                    {settings?.giftCardPaymentEnabled && (
                        <ViewBillContentSection>
                            <GiftCard
                                payment={giftCardPayment}
                                onGiftCardApply={onApplyGiftCard}
                                giftCardValue={giftCard}
                            />
                        </ViewBillContentSection>
                    )}
                    <ViewBillContentSection
                        title={t('BILL_CHECKOUT_PAYMENT_INFO_TITLE')}
                        subtitle={t('BILL_CHECKOUT_PAYMENT_INFO_BODY')}
                        bottomSpacing={2}
                    >
                        {paymentInfoStrings.map(([key, value, bold]) => (
                            <Box key={key} display="flex" justifyContent="space-between">
                                <Typography className={clsx({ [classes.boldText]: bold })}>{key}</Typography>
                                <Typography className={clsx({ [classes.boldText]: bold })}>
                                    {value}
                                </Typography>
                            </Box>
                        ))}
                    </ViewBillContentSection>
                    <div ref={lastItemRef}>
                        <InView onChange={setIndicatorVisibility}>
                            {readyToPay && (
                                <React.Fragment>
                                    {selectedPaymentOption.type === OrderPaymentType.CARD_ON_FILE && (
                                        <Box marginTop={1}>
                                            <Button
                                                onClick={handlePayMyBillWithCard}
                                                color="primary"
                                                variant="contained"
                                                fullWidth
                                                data-cy="submit"
                                            >
                                                {t('BILL_CHECKOUT_CONFIRM_AND_PAY_BTN', {
                                                    amount: getCurrencyString(totalToPay)
                                                })}
                                            </Button>
                                        </Box>
                                    )}
                                    {(settings?.paymentProvider === EPaymentProvider.JUDOPAY ||
                                        settings?.paymentProvider === EPaymentProvider.WORLDPAY) && (
                                        <Box marginBottom={1}>
                                            <IntegratedDigitalWallets
                                                total={totalToPay}
                                                onPay={handleJudoDigitalPayment}
                                                selectedWallet={selectedPaymentOption.type}
                                            />
                                        </Box>
                                    )}
                                    {settings?.paymentProvider !== EPaymentProvider.JUDOPAY &&
                                        settings?.paymentProvider !== EPaymentProvider.WORLDPAY && (
                                            <Box marginTop={1}>
                                                <DigitalWallets
                                                    total={totalToPay}
                                                    onDigitalPaymentMade={handleDigitalPayment}
                                                    selectedWallet={selectedPaymentOption.type}
                                                >
                                                    {t('BILL_CHECKOUT_CONFIRM_AND_PAY_BTN', {
                                                        amount: getCurrencyString(totalToPay)
                                                    })}
                                                </DigitalWallets>
                                            </Box>
                                        )}
                                </React.Fragment>
                            )}
                            {!readyToPay && (
                                <Box boxShadow={1} className={classes.notReaddyToPayButton}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={handleValidateTransactionLimit}
                                    >
                                        {t('PREORDER_BASKET_SUBMIT', {
                                            total: getCurrencyString(totalToPay)
                                        })}
                                    </Button>
                                </Box>
                            )}
                        </InView>
                    </div>
                    {!indicatorVisibility && (
                        <div className={classes.indicator} onClick={handleIndicatorClick}>
                            <ExpandMoreIcon />
                        </div>
                    )}
                </InnerPageLayoutContent>
            </InnerPageLayout>
            <CardPayment
                open={addNewCardOpen}
                handleCreateOrder={handleCardAdded}
                onClose={handleAddNewCardClose}
                isPlacingOrder={false}
            />
            <ConfirmDialog
                open={isConfirmationOpen}
                disableBackdropClick
                disableEscapeKeyDown
                onClose={handleErrorDialogClose}
                confirmMessage={t('RETRY')}
                onConfirm={handleRetry}
                description={t('DIALOG_ORDER_STATUS_UNKNOWN')}
            />
            <ConfirmDialog
                open={showExceededLimitDialog}
                disableBackdropClick
                disableEscapeKeyDown
                isCancellable={false}
                onClose={handleExceededLimitDialogClose}
                confirmMessage={t('OK')}
                onConfirm={handleExceededLimitDialogClose}
                description={transactionLimitDescription}
                title={t('DIALOG_OOPS')}
                align="center"
            />
            <GuestCheckout onAuthActionClick={onAuthActionClick} onClickBack={handleBackClick} />
        </>
    );
};
