export interface BaseRouteParams {
    merchantId?: string;
}

export interface LocationRouteParams extends BaseRouteParams {
    locationId?: string;
    scenario?: string;
}

const BASE_ROUTE = '/:merchantId';
const BASE_LOCATION_ROUTE = `${BASE_ROUTE}/:locationId/:scenario`;
const BASE_LOCATION_WITHOUT_SCENARIO = `${BASE_ROUTE}/:locationId`;
const BASE_WITH_SCENARIO = `${BASE_ROUTE}/:scenario`;

export const ROUTES = {
    BASE: BASE_ROUTE,
    WORLDPAY: '/worldpay',
    USER: {
        DETAILS: `${BASE_WITH_SCENARIO}/user/details`,
        ACTIVITY: `${BASE_WITH_SCENARIO}/user/activity`,
        LOGIN: `${BASE_WITH_SCENARIO}/user/sign-in`,
        REGISTER: `${BASE_WITH_SCENARIO}/user/sign-up`,
        ACTIVATE: `${BASE_WITH_SCENARIO}/user/activate`,
        EMAIL_CAPTURE: `${BASE_WITH_SCENARIO}/user/email-capture`,
        REWARDS: `${BASE_WITH_SCENARIO}/user/rewards`,
        FORGOT_PASSWORD: `${BASE_WITH_SCENARIO}/user/forgot-password`,
        PAYMENT_METHODS: `${BASE_WITH_SCENARIO}/user/payment-methods`,
        TWO_FACTOR_AUTHENTICATION: `${BASE_WITH_SCENARIO}/user/2fa`
    },
    ORDER: {
        ENTRY: `${BASE_ROUTE}/order`,
        BASKET: `${BASE_LOCATION_ROUTE}/basket`,
        SUMMARY: `${BASE_LOCATION_ROUTE}/order/:orderId`,
        OPERATION: `${BASE_LOCATION_ROUTE}/status/:operationId`
    },
    QUICKPAY: {
        CHECKOUT: `${BASE_LOCATION_ROUTE}/pay`,
        STATUS: `${BASE_LOCATION_ROUTE}/o/:operationId`,
        SUMMARY: `${BASE_LOCATION_ROUTE}/s/:orderId`
    },
    JOURNEY: {
        LANDING: `${BASE_ROUTE}/:locationId/journey`,
        PAT: {
            BILL: `${BASE_LOCATION_ROUTE}/pat/bill`,
            BILL_WITHOUT_SCENARIO: `${BASE_LOCATION_WITHOUT_SCENARIO}/pat/bill`,
            CHECKOUT: `${BASE_LOCATION_ROUTE}/pat/bill/checkout`
        }
    },
    MENU: `${BASE_LOCATION_ROUTE}`,
    MENU_WITHOUT_SCENARIO: BASE_LOCATION_WITHOUT_SCENARIO
};
