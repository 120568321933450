import React from 'react';
import { useSelector } from 'react-redux';
import { EUserRole } from 'components/user/model/User';
import { ApplicationState } from 'store/store';
import { getLocalAuthentication } from './../components/user/localAuth';

export const useAuth = () => {
    const { user, isLoading, card, isCardLoading, showMarketing } = useSelector(
        (state: ApplicationState) => state.auth
    );

    const hasSession = React.useMemo(() => !!user, [user]);
    const isGuest = React.useMemo(
        () => (!!user && user.roles?.includes(EUserRole.GUEST)) || !!getLocalAuthentication()?.isGuest,
        [user]
    );
    const isLoggedIn = React.useMemo(() => hasSession && !isGuest, [hasSession, isGuest]);
    const isVerifiedGuest = React.useMemo(
        () => isGuest && user && user.credentials?.length > 0,
        [isGuest, user]
    );
    const guestCredential = React.useMemo(
        () => isVerifiedGuest && user && user.credentials[0],
        [isVerifiedGuest, user]
    );

    return {
        hasSession,
        isGuest,
        isVerifiedGuest,
        card,
        isCardLoading,
        guestCredential,
        isLoggedIn,
        user,
        isLoading,
        showMarketing
    };
};
