import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { PaymentRequest } from '@stripe/stripe-js';
import { OrderPaymentType } from 'components/order/model/Order';
import { EPaymentProvider } from 'components/settings/model/Settings';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/LoadingButton';
interface IProps {
    walletType: OrderPaymentType.APPLEPAY | OrderPaymentType.GOOGLEPAY;
    id: string;
    disabled?: boolean;
    hidden?: boolean;
    onClick?: (walletType: IProps['walletType']) => void;
    stripePaymentRequest?: PaymentRequest | null;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            borderRadius: theme.shape.borderRadius,
            marginBottom: theme.spacing(1)
        }
    })
);

export const DigitalWalletButtonLegacy: React.FC<IProps> = ({
    walletType,
    disabled,
    id,
    onClick,
    hidden,
    children,
    stripePaymentRequest
}) => {
    const classes = useStyles();
    const { settings } = useSelector((state: ApplicationState) => state.settings);

    const isStripe = React.useMemo(
        () =>
            !!settings &&
            settings.paymentProvider === EPaymentProvider.STRIPE &&
            !!settings.stripe.publishableKey,
        [settings]
    );

    const handleClick = React.useCallback(() => {
        if (isStripe && stripePaymentRequest) {
            return stripePaymentRequest.show();
        }
        if (onClick) {
            onClick(walletType);
        }
    }, [isStripe, onClick, stripePaymentRequest, walletType]);

    return (
        <Box boxShadow={1} className={classes.wrapper}>
            <LoadingButton
                variant="contained"
                color="primary"
                id={id}
                fullWidth
                disabled={disabled || hidden}
                onClick={handleClick}
            >
                {children}
            </LoadingButton>
        </Box>
    );
};
