import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { notificationsApi } from 'components/notifications/notificationsApi';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { BottomDialog } from 'lib/BottomDialog';
import { TextFormField } from 'lib/form/TextFormField';
import { useAuth } from 'lib/useAuth';
import { LoadingButton } from 'ui/LoadingButton';
import { useSnackbar } from 'notistack';

interface IProps {
    actionId?: string;
    isOpen: boolean;
    onReceiptSent?: () => void;
    onClose: () => void;
    fullScreen?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            padding: theme.spacing(2)
        },
        root: {
            paddingTop: theme.spacing(4)
        },
        buttonWrapper: {
            marginTop: 'auto'
        },
        button: {
            height: theme.spacing(6),
            fontSize: '1.2em'
        },
        form: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }
    })
);

export const ResendReceipt: React.FC<IProps> = ({
    actionId,
    onClose,
    onReceiptSent,
    isOpen,
    fullScreen = true
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useAuth();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const initialFields: { userEmail: string } = React.useMemo(
        () => ({
            userEmail: user?.email || ''
        }),
        [user?.email]
    );

    const validation = React.useMemo(
        () =>
            Yup.object<{ userEmail: string }>().shape({
                userEmail: Yup.string()
                    .required(t('FORMS_VALIDATION_REQUIRED'))
                    .email(t('FORMS_VALIDATION_EMAIL'))
            }),
        [t]
    );

    const handleSubmit = React.useCallback(
        ({ userEmail }: { userEmail: string }) => {
            if (actionId) {
                setIsSubmitting(true);
                notificationsApi
                    .resendReceipt({ userEmail, actionId })
                    .then(() => {
                        enqueueSnackbar(t('ACTIONS_RESEND_RECEIPT_SUCCESS'), { variant: 'success' });
                        if (onReceiptSent) {
                            onReceiptSent();
                        }
                        onClose();
                    })
                    .finally(() => setIsSubmitting(false));
            }
        },
        [actionId, enqueueSnackbar, onClose, onReceiptSent, t]
    );
    const handleClearEmail = React.useCallback(
        (setFieldValue: FormikHelpers<any>['setFieldValue']) => () => {
            setFieldValue('userEmail', '');
        },
        []
    );

    const handleMouseDownEmail = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    }, []);

    return (
        <BottomDialog fullScreen={fullScreen} open={isOpen} onClose={onClose}>
            {fullScreen && <MuiAppBar onBack={onClose} title={t('ACCOUNT_EMAIL_RECEIPT')} disabledMenu />}
            <Box padding={2} flex={1}>
                <Formik
                    validateOnMount
                    initialValues={initialFields}
                    validationSchema={validation}
                    onSubmit={handleSubmit}
                >
                    {({ isValid, setFieldValue }) => (
                        <Form className={classes.form}>
                            <Box marginBottom={2}>
                                <Typography>{t('ACTIONS_RESEND_RECEIPT_DESCRIPTION')}</Typography>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box marginBottom={2}>
                                        <TextFormField
                                            name="userEmail"
                                            label="Email"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton
                                                        onClick={handleClearEmail(setFieldValue)}
                                                        onMouseDown={handleMouseDownEmail}
                                                        size="small"
                                                        tabIndex={-1}
                                                    >
                                                        <Close />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <div className={classes.buttonWrapper}>
                                <LoadingButton
                                    className={classes.button}
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    disabled={!isValid || isSubmitting}
                                    loading={isSubmitting}
                                    fullWidth
                                >
                                    {t('ACTIONS_RESEND_RECEIPT_BUTTON')}
                                </LoadingButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Box>
        </BottomDialog>
    );
};
