import { loadStripe } from '@stripe/stripe-js';
import { IOrderCreatePaymentV8 } from 'components/order/model/Order';
import { EPaymentProvider, isStripeConfig } from 'components/settings/model/Settings';
import { SettingsStateSettings } from 'store/settings/settingsReducer';
import { JudopayProvider } from './Judopay/JudopayProvider';
import { BillingContact, SqVerificationCallback } from './Square/models';
import { SquareProvider } from './Square/SquareProvider';
import { StripeProvider } from './Stripe/StripeProvider';
import { WorldpayProvider } from './Worldpay/WorldpayProvider';

export interface ThreeDSecureAuthenticationData {
    stripe?: {
        paymentIntentClientSecret: string;
        paymentIntentId: string;
    };
    worldpay?: {
        challenge: {
            jwt: string;
            url: string;
            reference: string;
        };
    };
    square?: {
        intent: 'CHARGE' | 'STORE';
        cardId: string;
        contact: Partial<BillingContact>;
        currency?: string;
        onVerificationEnd: SqVerificationCallback;
    };
}

interface IDeviceDataFromProvider {
    sessionId: string;
    transactionReference?: string;
}

export interface IAddCardComponentClasses {
    root?: string;
    button?: string;
    buttonContainer?: string;
}

export interface IPaymentProvider {
    createAddCardComponent: (
        postCardAddition: () => void,
        classes?: IAddCardComponentClasses
    ) => React.FunctionComponentElement<any>;
    threeDSecureAuthenticate: <T extends { payments: IOrderCreatePaymentV8[] }>(
        data: ThreeDSecureAuthenticationData,
        order: T
    ) => Promise<T | null>;
    collectDeviceData: () => Promise<IDeviceDataFromProvider | null>;
    reset?: () => void;
}

export type PaymentDetails = {
    type: 'cardOnFile' | 'digitalWallet';
    order?: {
        total: string;
        label: string;
        currencyCode: string;
        countryCode: string;
    };
};

export class PaymentProviderFactory {
    public async create(settings: SettingsStateSettings): Promise<IPaymentProvider> {
        switch (settings.paymentProvider.toUpperCase()) {
            case EPaymentProvider.JUDOPAY:
                return new JudopayProvider();
            case EPaymentProvider.WORLDPAY:
                return new WorldpayProvider(settings.worldpay);
            case EPaymentProvider.SQUARE:
                return new SquareProvider(settings.square);
            case EPaymentProvider.STRIPE: {
                if (!isStripeConfig(settings.stripe)) {
                    throw new Error('Stripe malformed settings');
                }
                const gateway = await loadStripe(settings.stripe.publishableKey);
                if (!gateway) {
                    throw new Error('Failed to create stripe gateway');
                }
                return new StripeProvider(gateway);
            }
            default:
                throw new Error(`Unable to create payment gateway of provider ${settings.paymentProvider}`);
        }
    }
}

export const paymentProviderFactory = new PaymentProviderFactory();
