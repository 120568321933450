import { OrderScenario } from 'components/order/model/Order';
import { roundToDecimal } from 'lib/helpers';
import { useAuth } from 'lib/useAuth';
import React from 'react';
import { ExistingSharedBasketData } from '../helpers';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

export function useBasketTips(
    total: number,
    scenario: OrderScenario,
    existingParams: ExistingSharedBasketData
) {
    const { user } = useAuth();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const [userSelectedTipValue, setUserSelectedTipValue] = React.useState(
        existingParams.userSelectedTipValue ?? user?.defaultTip?.amount
    );
    const [useTipAsDefault, setUseTipAsDefault] = React.useState(!!user?.defaultTip);
    const [tipAmount, setTipAmount] = React.useState(existingParams.tipValue ?? 0);

    const roundedTipAmount = React.useMemo(() => roundToDecimal(tipAmount), [tipAmount]);

    const handleSubmitTip = React.useCallback(
        (value: number, actualTipValue?: number) => {
            setTipAmount(value);
            setUserSelectedTipValue(actualTipValue);
        },
        [setUserSelectedTipValue]
    );
    const handleUseTipAsDefaultChange = React.useCallback((value: boolean) => {
        setUseTipAsDefault(value);
    }, []);

    const finalTipAmount = React.useMemo(() => {
        if (!!total || scenario === OrderScenario.TABLE || !settings?.app?.hideTippingWhenPaymentZero) {
            return roundedTipAmount;
        }
        return 0;
    }, [roundedTipAmount, scenario, settings?.app?.hideTippingWhenPaymentZero, total]);

    return {
        tipAmount: finalTipAmount,
        onSubmitTip: handleSubmitTip,
        onUseTipAsDefaultChange: handleUseTipAsDefaultChange,
        useTipAsDefault,
        userSelectedTipValue
    };
}
