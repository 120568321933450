import * as React from 'react';
import { IOrderCreatePaymentV8 } from 'components/order/model/Order';
import { ISettings } from 'components/settings/model/Settings';
import { isDefined } from 'lib/typeInference';
import { IPaymentProvider, ThreeDSecureAuthenticationData } from '../PaymentProvider';
import { ApplePay, Card, GooglePay, Payments, Square, TokenResult } from './models';
import { SquareAddCard } from './SquareAddCard';

declare global {
    interface Window {
        Square?: Square;
    }
}

export interface SquareDigital {
    googlePay?: GooglePay;
    applePay?: ApplePay;
}

export class SquareProvider implements IPaymentProvider {
    public static instance: Payments | undefined;

    private src = this.settings.isProduction
        ? 'https://web.squarecdn.com/v1/square.js'
        : 'https://sandbox.web.squarecdn.com/v1/square.js';

    constructor(private settings: ISettings['square']) {
        this.init();
    }

    public threeDSecureAuthenticate = <T extends { payments: IOrderCreatePaymentV8[] }>(
        _data: ThreeDSecureAuthenticationData,
        order?: T
    ) => {
        if (_data.square?.cardId && SquareProvider.instance) {
            if (_data.square?.intent === 'STORE') {
                SquareProvider.instance
                    .verifyBuyer(_data.square.cardId, {
                        intent: 'STORE',
                        billingContact: _data.square.contact
                    })
                    .then((result: any) => {
                        if (_data.square) {
                            _data.square.onVerificationEnd(undefined, result);
                        }
                    })
                    .catch((e: any) => {
                        if (_data.square) {
                            _data.square.onVerificationEnd(e);
                        }
                    });
            } else if (order && isDefined(order?.payments[0].amount) && _data.square.currency) {
                SquareProvider.instance
                    .verifyBuyer(_data.square.cardId, {
                        intent: 'CHARGE',
                        billingContact: _data.square.contact,
                        amount: order.payments[0].amount.toString(),
                        currencyCode: _data.square.currency
                    })
                    .then((result: any) => {
                        if (_data.square) {
                            _data.square.onVerificationEnd(undefined, result);
                        }
                    })
                    .catch((e: any) => {
                        if (_data.square) {
                            _data.square.onVerificationEnd(e);
                        }
                    });
            }
        }
        return Promise.resolve(null);
    };
    public collectDeviceData() {
        return Promise.resolve(null);
    }
    createAddCardComponent = (postCardAddition: () => void) =>
        React.createElement(SquareAddCard, {
            settings: this.settings,
            postCardAddition,
            squareInstance: this
        });

    public initCard: () => Promise<Card> = () => {
        const runInit = async () => {
            if (SquareProvider.instance) {
                const card = await SquareProvider.instance.card();
                await card.attach('#card-container');
                return card;
            }
        };
        let counter = 10;
        const tryInitCard = async () => {
            if (isDefined(SquareProvider.instance)) {
                return runInit();
            }
            return new Promise(resolve => {
                setTimeout(() => {
                    if (counter > 0) {
                        counter--;
                        resolve(tryInitCard());
                    }
                }, 1000);
            });
        };
        return tryInitCard();
    };

    public tokenize = async (paymentMethod: Card): Promise<TokenResult> => {
        const tokenResult = await paymentMethod.tokenize();
        if (tokenResult.status === 'OK') {
            return tokenResult;
        }
        let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
        if (tokenResult.errors) {
            errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
        }
        throw new Error(errorMessage);
    };

    private injectScript = (onLoad: () => void) => {
        const sqPaymentScript = document.createElement('script');
        sqPaymentScript.src = this.src;
        sqPaymentScript.type = 'text/javascript';
        sqPaymentScript.async = false;
        sqPaymentScript.onload = onLoad;
        document.getElementsByTagName('head')[0].appendChild(sqPaymentScript);
    };

    private isScriptInjected = () => document.querySelector(`script[src="${this.src}"]`);

    private init = () => {
        if (!this.isScriptInjected()) {
            this.injectScript(this.init);
            return;
        }
        if (window.Square) {
            SquareProvider.instance = window.Square.payments(
                this.settings.isProduction
                    ? process.env.SQUARE_LIVE_APPLICATION_ID
                    : process.env.SQUARE_SANDBOX_APPLICATION_ID,
                this.settings.locationId
            );
        }
    };
}
