import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { addOpacity } from 'lib/helpers';
import { LoadingTypography } from 'lib/LoadingTypography';

const useStyles = makeStyles((theme: Theme) => ({
    change: {
        color: theme.palette.primary.main,
        fontWeight: 700
    },
    buttonBase: {
        display: 'flex',
        flexDirection: 'column'
    },
    subtitle: {
        color: addOpacity(theme.palette.text.primary, 0.4)
    }
}));

interface PayTableInfoProps {
    isLoading: boolean;
    table?: string | null;
    checkId?: string | null;
    disableEdit?: boolean;
}

const SPLITTER_ELEMENT = ' | ';

export const PayTableInfo: React.FC<PayTableInfoProps> = ({ isLoading, table, checkId, disableEdit }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Box display="flex" alignItems="center">
            <LoadingTypography className={classes.subtitle} loading={isLoading} width={80}>
                {checkId
                    ? t('BILL_CHECK_NUMBER', { checkId })
                    : t('BILL_TABLE_NUMBER', { tableNumber: table })}
            </LoadingTypography>
            {!disableEdit && (
                <Box px={1}>
                    <Typography className={classes.subtitle}>{SPLITTER_ELEMENT}</Typography>
                </Box>
            )}
            {!disableEdit && (
                <LoadingTypography className={classes.change} width={80} loading={isLoading}>
                    {t('GENERAL_EDIT')}
                </LoadingTypography>
            )}
        </Box>
    );
};
