import { ERouteScenario } from 'src/enums';
import { OrderScenario } from 'components/order/model/Order';

const ROUTE_TO_NORMAL_SCENARIO: Record<Partial<ERouteScenario>, OrderScenario> = {
    [ERouteScenario.ORDER_TO_TABLE]: OrderScenario.ORDER_TO_TABLE,
    [ERouteScenario.PREORDER]: OrderScenario.PREORDER,
    [ERouteScenario.TABLE]: OrderScenario.TABLE,
    [ERouteScenario.PAYATPOS]: OrderScenario.PAYATPOS,
    [ERouteScenario.TAB]: OrderScenario.TAB
};

export const getRouteScenario = (shortScenario: ERouteScenario) => ROUTE_TO_NORMAL_SCENARIO[shortScenario];

export const makeRouteScenario = (scenario: OrderScenario) => ERouteScenario[scenario];
