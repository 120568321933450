import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useCurrentPosition } from 'src/hooks/useCurrentPosition';
import { getLocalMerchantId } from 'components/settings/localStore';

interface Point {
    lat: number;
    lng: number;
}

export interface IPoint extends Point {
    markerName: string;
}

interface GMapProps {
    center: Point;
    width: number;
    className?: string;
    height?: number;
    markers?: IPoint[];
    name?: string;
    options?: google.maps.MapOptions;
    zoom?: number;
    useCustomPin?: boolean;
    boundAll?: boolean;
    boundTo?: Point;
}

function fitMapBounds(lat: number, lng: number, width: number, map: google.maps.Map) {
    if (lat && lng && width && map) {
        map.fitBounds(
            new google.maps.LatLngBounds(
                new google.maps.LatLng(lat, lng - width / 2),
                new google.maps.LatLng(lat, lng + width / 2)
            )
        );
    }
}

export const MuiMap: React.FC<GMapProps> = props => {
    const { center, width, name, className, height, boundAll, options, markers, useCustomPin, ...rest } =
        props;
    const { lat, lng } = center;
    const [map, setMap] = React.useState(null as null | google.maps.Map);
    const { position: devicePosition } = useCurrentPosition();
    const [isCustomPinAvailable, setIsCustomPinAvailable] = React.useState(false);
    const merchantId = getLocalMerchantId();
    const customPinPath = React.useMemo(
        () => `${process.env.MEDIA_URL}/tenants/${merchantId}/app_media/map_pin.png`,
        [merchantId]
    );
    React.useEffect(() => {
        if (map) {
            if (markers && boundAll) {
                const bounds = new google.maps.LatLngBounds();

                markers.map(marker => {
                    const position = new google.maps.LatLng(marker.lat, marker.lng);

                    bounds.extend(position);
                });
                map.fitBounds(bounds);
            } else if (props.boundTo) {
                const bounds = new google.maps.LatLngBounds();
                bounds.extend({ lat, lng });
                bounds.extend({ lat: props.boundTo.lat, lng: props.boundTo.lng });
                map.fitBounds(bounds);
            } else {
                fitMapBounds(lat, lng, width, map);
            }
        }
    }, [boundAll, lat, lng, map, markers, props.boundTo, width]);

    const onMapLoad = React.useCallback(
        (loadedMap: google.maps.Map) => {
            setMap(loadedMap);
            fitMapBounds(lat, lng, width, loadedMap);
        },
        [lat, lng, width]
    );

    // Check if merchant has uploaded custom pin
    React.useEffect(() => {
        const img = new Image();
        img.onload = () => {
            setIsCustomPinAvailable(true);
        };

        img.src = customPinPath;
    }, [customPinPath, merchantId]);

    const mapOptions: google.maps.MapOptions = {
        disableDefaultUI: false,
        disableDoubleClickZoom: true,
        draggable: true,
        gestureHandling: 'auto',
        fullscreenControl: false,
        ...options
    };

    return (
        <GoogleMap
            id={`gmap-${name}`}
            clickableIcons={false}
            center={center}
            onLoad={onMapLoad}
            mapContainerClassName={className}
            mapContainerStyle={{}}
            options={mapOptions}
            {...rest}
        >
            {devicePosition && (
                <Marker
                    position={{ lat: devicePosition.coords.latitude, lng: devicePosition.coords.longitude }}
                    icon="current-location.svg"
                />
            )}
            {Array.isArray(markers) &&
                markers.map(item => (
                    <Marker
                        key={`marker-${item.lat}-${item.lng}-${item.markerName}`}
                        position={{ lat: item.lat, lng: item.lng }}
                        icon={useCustomPin && isCustomPinAvailable ? customPinPath : undefined}
                    />
                ))}
            <div style={{ height: height || 'inherit', width: '100%' }} />
        </GoogleMap>
    );
};
