import React from 'react';
import Cards from 'react-credit-cards';
import { Box } from '@material-ui/core';
import 'react-credit-cards/es/styles-compiled.css';

interface CardViewProps {
    expiry: string;
    name: string;
    number: string;
    issuer: string;
    preview?: boolean;
}
export const CardView: React.FC<CardViewProps> = ({ expiry, name, number, issuer, preview = true }) => (
    <Box>
        <Cards preview={preview} cvc="" expiry={expiry} name={name} number={number} issuer={issuer} />
    </Box>
);
