import React from 'react';
import { createStyles, ListItem, ListItemText, makeStyles, Theme } from '@material-ui/core';

interface ViewBillCheckoutNoteItemProps {
    title: string;
    onClick: (index: number) => void;
    value?: string;
    index: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            '&:last-child': {
                borderBottom: 'none'
            }
        }
    })
);

export const ViewBillCheckoutNoteItem: React.FC<ViewBillCheckoutNoteItemProps> = ({
    title,
    onClick,
    value,
    index
}) => {
    const classes = useStyles();
    const handleClick = React.useCallback(() => {
        onClick(index);
    }, [index, onClick]);
    return (
        <ListItem button onClick={handleClick} className={classes.root} disableGutters>
            <ListItemText primary={title} secondary={value} />
        </ListItem>
    );
};
