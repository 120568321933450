import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, createStyles, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { ServiceNotAvailable } from 'components/location/ServiceNotAvailable';
import { ICategoryGroup, IEnrichedCategory, IEnrichedMenuWithModifierMaps } from 'components/menu/model/Menu';
import { IOrderReadResourceV12, OrderScenario } from 'components/order/model/Order';
import { ApplicationState } from 'store/store';
import { MenuItems } from './MenuItems';
import { MenuSidebar } from './MenuSidebar';

interface IProps {
    menu: IEnrichedMenuWithModifierMaps | undefined;
    orderAhead: boolean;
    recentOrders: IOrderReadResourceV12[] | undefined;
    showCategoryGroups: boolean;
    categoryGroups?: ICategoryGroup[];
    noValidCategoryGroups: boolean;
}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginTop: theme.spacing(-2),
            height: `calc(100% + ${theme.spacing(2)}px)`
        },
        scrollingElement: {
            height: '100%',
            paddingTop: '0 !important',
            paddingBottom: '0 !important'
        },
        errorWrapper: {
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        categoryListItems: {
            maxHeight: '100%',
            overflowY: 'hidden'
        }
    })
);

export type RefsMap = Record<string, { ref: React.MutableRefObject<HTMLDivElement | null>; active: boolean }>;

export const MenuDesktop: React.FC<IProps> = ({
    menu,
    orderAhead,
    recentOrders,
    showCategoryGroups,
    categoryGroups,
    noValidCategoryGroups
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { categories } = useSelector((state: ApplicationState) => state.menu);
    const refs: RefsMap = {};
    const [activeCategory, setActiveCategory] = React.useState<string>();
    const [scenario] = useRouteScenario();
    const scenarioToUse = React.useMemo(() => scenario ?? OrderScenario.PREORDER, [scenario]);
    const { checkServiceAvailability } = useLocationHelpers();

    const categoryGroupToCategories = React.useMemo(() => {
        if (showCategoryGroups && categoryGroups) {
            const sortedGroups = [...categoryGroups].sort((a, b) => a.sort - b.sort);
            return sortedGroups.reduce<[ICategoryGroup, IEnrichedCategory[]][]>((acc, categoryGroup) => {
                const groupCategories = categories
                    .filter(category => categoryGroup.categoryIds.includes(category.id))
                    .sort((a, b) => a.sort - b.sort)
                    .map(category => ({ ...category, groupCategoryId: categoryGroup.id }));

                acc.push([categoryGroup, groupCategories]);

                return acc;
            }, []);
        }
    }, [categories, categoryGroups, showCategoryGroups]);
    const sortedCategories = React.useMemo(() => {
        if (showCategoryGroups && categoryGroupToCategories) {
            return categoryGroupToCategories.reduce<IEnrichedCategory[]>((acc, [_, groupCategories]) => {
                acc.push(...groupCategories);

                return acc;
            }, []);
        }
        if (!!categoryGroups && categoryGroups.length === 1) {
            return categories.filter(category => categoryGroups[0].categoryIds.includes(category.id));
        }

        return categories;
    }, [categories, categoryGroupToCategories, showCategoryGroups, categoryGroups]);
    if (!menu || noValidCategoryGroups) {
        return (
            <Paper className={classes.errorWrapper}>
                <Typography>{t('MENU_EMPTY')}</Typography>
            </Paper>
        );
    }
    return (
        <Box paddingTop={2} height="0" maxHeight="100%" flex="1">
            <Grid container spacing={2} className={classes.container}>
                <Grid item xs={4} className={classes.categoryListItems}>
                    <MenuSidebar
                        activeCategory={activeCategory}
                        categoryRefs={refs}
                        menu={menu}
                        orderAhead={orderAhead}
                        categories={categories}
                        showCategoryGroups={showCategoryGroups}
                        categoryGroups={categoryGroups}
                        recentOrders={recentOrders}
                        categoryGroupToCategories={categoryGroupToCategories}
                    />
                </Grid>
                <Grid item xs={8} className={classes.scrollingElement}>
                    {!checkServiceAvailability(scenarioToUse) && (
                        <Box>
                            <ServiceNotAvailable scenario={scenarioToUse} />
                        </Box>
                    )}
                    {!!menu && (
                        <MenuItems
                            refsMap={refs}
                            recentOrders={recentOrders}
                            categories={sortedCategories}
                            menu={menu}
                            setActiveCategory={setActiveCategory}
                        />
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};
