import React from 'react';

export interface ImageProps
    extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    onLoad?: () => void;
    onError?: () => void;
    onFallbackError?: () => void;
    fallbackSrc: string;
}

export const Image: React.FC<ImageProps> = ({
    onError,
    onLoad,
    src,
    fallbackSrc,
    onFallbackError,
    ...props
}) => {
    const [showDefault, setShowDefault] = React.useState<boolean>();

    const handleError = React.useCallback(() => {
        setShowDefault(true);
        if (onError) {
            onError();
        }
    }, [onError]);

    const handleLoad = React.useCallback(() => {
        if (onLoad) {
            onLoad();
        }
    }, [onLoad]);
    if (!showDefault) {
        return <img onError={handleError} onLoad={handleLoad} src={src} {...props} />;
    }
    return <img src={fallbackSrc} onError={onFallbackError} {...props} />;
};
