import moment from 'moment';
import { IPointAward } from './models';
import { isDefined } from 'lib/typeInference';

export function replacePluralString(value: number, pluralizableString: string) {
    return pluralizableString
        .replace('{{s}}', value !== 1 ? 's' : '')
        .replace('{{S}}', value !== 1 ? 'S' : '');
}

export const isAwardAvailableToday = (award: IPointAward) => {
    if (!award.dateTimeRestriction) {
        return true;
    }
    const restrictions = award.dateTimeRestriction;
    const currentDate = moment();
    if (
        isDefined(restrictions.startDate) &&
        moment(restrictions.startDate).startOf('day').isAfter(currentDate)
    ) {
        return false;
    }
    if (isDefined(restrictions.endDate) && moment(restrictions.endDate).endOf('day').isBefore(currentDate)) {
        return false;
    }
    return true;
};

export const isAwardAvailableAt = (award: IPointAward, date?: Date) => {
    if (!award.dateTimeRestriction) {
        return true;
    }
    const restrictions = award.dateTimeRestriction;
    const currentDate = moment(date);
    if (
        isDefined(restrictions.startDate) &&
        moment(restrictions.startDate).startOf('day').isAfter(currentDate)
    ) {
        return false;
    }
    if (isDefined(restrictions.endDate) && moment(restrictions.endDate).endOf('day').isBefore(currentDate)) {
        return false;
    }
    if (
        isDefined(restrictions.startTime) &&
        moment(currentDate).startOf('day').add(restrictions.startTime).isAfter(currentDate)
    ) {
        return false;
    }
    if (
        isDefined(restrictions.endTime) &&
        moment(currentDate).startOf('day').add(restrictions.endTime).isBefore(currentDate)
    ) {
        return false;
    }
    if (isDefined(restrictions.daysOfWeek)) {
        const dayOfWeekToBinary: Record<number, number> = {
            1: 1,
            2: 2,
            3: 4,
            4: 8,
            5: 16,
            6: 32,
            7: 64
        };
        const currentDay = moment().isoWeekday();
        // eslint-disable-next-line no-bitwise
        if (!(dayOfWeekToBinary[currentDay] & restrictions.daysOfWeek)) {
            return false;
        }
    }
    return true;
};
