/* eslint-disable react/jsx-no-literals */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import { PaymentProviderType } from 'components/user/model/User';
import { userApi } from 'components/user/userApi';
import { LoadingButton } from 'ui/LoadingButton';
import './WorldpayAddCard.css';
import { useSnackbar } from 'notistack';
export interface WorldpayAddCardProps {
    merchantId?: string;
    postCardAddition?: () => void;
}

const WORLDPAY_ELEMENT_ID = 'worldpay-app-card';

export const WorldpayAddCard: React.FC<WorldpayAddCardProps> = ({
    merchantId,
    postCardAddition = () => {}
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(true);
    const [valid, setValid] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    React.useEffect(() => {
        const loadWorldpay = () => {
            if (!globalThis.Worldpay) {
                return;
            }
            const form = document.getElementById(WORLDPAY_ELEMENT_ID);
            if (!form) {
                setLoading(false);
                return;
            }
            const fields = {
                pan: {
                    selector: '#card-pan',
                    placeholder: '4444333322221111'
                },
                cvv: {
                    selector: '#card-cvv',
                    placeholder: '123'
                },
                expiry: {
                    selector: '#card-expiry',
                    placeholder: 'MM/YY'
                }
            };
            const styles = {
                input: {
                    color: 'black',
                    'font-weight': 'bold',
                    'font-size': '20px',
                    'letter-spacing': '3px'
                },
                'input#pan': {
                    'font-size': '24px'
                },
                'input.is-valid': {
                    color: 'green'
                },
                'input.is-invalid': {
                    color: 'red'
                },
                'input.is-onfocus': {
                    color: 'black'
                }
            };
            globalThis.Worldpay.checkout.init(
                {
                    id: merchantId || '',
                    form: `#${WORLDPAY_ELEMENT_ID}`,
                    fields,
                    styles
                },
                (error: Error, checkout: any) => {
                    if (error) {
                        console.error(error);
                        return;
                    }

                    form.addEventListener('submit', event => {
                        event.preventDefault();
                        setLoading(true);
                        checkout.generateSessionState((innerError: Error, sessionState?: string) => {
                            if (innerError || !sessionState) {
                                enqueueSnackbar(t('CHECKOUT_ERROR_VALIDATING_CARD'), { variant: 'error' });
                                setLoading(false);
                                return;
                            }
                            // Call Pepper API
                            const stateParts = sessionState.split('/');
                            const token = stateParts.pop();
                            if (!token) {
                                enqueueSnackbar(t('CHECKOUT_ERROR_VALIDATING_CARD'), { variant: 'error' });
                                setLoading(false);
                                return;
                            }
                            userApi
                                .createCard({
                                    cardType: PaymentProviderType.WORLDPAY,
                                    token
                                })
                                .then(() => {
                                    postCardAddition();
                                    enqueueSnackbar(t('CHECKOUT_CARD_ADDED'), { variant: 'success' });
                                    setLoading(false);
                                })
                                .catch(err => {
                                    enqueueSnackbar(err.data.message, { variant: 'error' });
                                    setLoading(false);
                                });
                        });
                    });
                    form.addEventListener('wp:form:change', (event: any) => {
                        if (event.detail['is-valid']) {
                            setValid(true);
                        } else {
                            setValid(false);
                        }
                    });
                }
            );
            setLoading(false);
        };
        // wait for Worldpay loading for a minute
        let repeats = 60;
        const pollForWorldpay = setInterval(() => {
            if (!repeats) {
                clearInterval(pollForWorldpay);
                return;
            }
            if (!globalThis.Worldpay) {
                repeats--;
                return;
            }
            loadWorldpay();
            repeats = 60;
            clearInterval(pollForWorldpay);
        }, 1000);
    }, [dispatch, enqueueSnackbar, merchantId, postCardAddition, t]);
    return (
        <div className="Worldpay-Add-Card-Form">
            <form className="checkout" id={WORLDPAY_ELEMENT_ID}>
                <label className="label" htmlFor="card-pan">
                    Card number <span className="type"></span>
                </label>
                <section id="card-pan" className="field"></section>
                <section className="col-2">
                    <section className="col">
                        <label className="label" htmlFor="card-expiry">
                            Expiry date
                        </label>
                        <section id="card-expiry" className="field"></section>
                    </section>
                    <section className="col">
                        <label className="label" htmlFor="card-cvv">
                            CVV
                        </label>
                        <section id="card-cvv" className="field"></section>
                    </section>
                </section>
                <Box marginTop={2}>
                    <LoadingButton
                        loading={loading}
                        disabled={loading || !valid}
                        fullWidth
                        className="submit"
                        type="submit"
                        color="primary"
                        variant="contained"
                    >
                        Submit
                    </LoadingButton>
                </Box>
            </form>
        </div>
    );
};
