import { HttpClient } from 'components/http/HttpClient';
import logger from 'lib/logger';
import { IBasketRequestResource, IBasketResourceV10, isBasketCalculatorData } from './model/Basket';

class BasketApi extends HttpClient {
    constructor() {
        super({
            baseURL: process.env.ORDER_SERVICE_URL
        });
    }
    public async calculateBasket(data: IBasketRequestResource): Promise<IBasketResourceV10> {
        const response = await this.postRequest<IBasketRequestResource, IBasketResourceV10>({
            url: '/baskets',
            data,
            headers: {
                'x-api-version': '21'
            }
        });
        if (!isBasketCalculatorData(response)) {
            // Don't want to break the web app if we get odd data
            logger.warn(
                `Typechecking failed, expected BasketCalculatorData, but got ${String(response)}`,
                response
            );
        }
        return response;
    }
}

export const basketApi = new BasketApi();
