/* eslint-disable react/no-multi-comp */
import React from 'react';
import { Box, List, makeStyles, Theme } from '@material-ui/core';
import { useResponsive } from 'src/hooks/useResponsive';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { NotWorkingHour } from 'components/location/NotWorkingHour';
import { OrderingDisabled } from 'components/location/OrderingDisabled';
import { IOrderReadResourceV12 } from 'components/order/model/Order';
import { RecentOrdersList } from 'components/recentOrders/RecentOrdersList';
import { useSuggestionsContext } from '../../hooks/useSuggestionsContext';
import { RefsMap } from './Desktop/MenuDesktop';
import {
    IEnrichedCategory,
    IEnrichedMenu,
    IEnrichedMenuWithModifierMaps,
    IEnrichedProduct,
    ProductGroup
} from './model/Menu';
import { useAddModal } from './suggestions/useAddModal';
import { MenuCategory } from './MenuCategory';
import { MenuProductInfo } from './MenuProductInfo';
import { isDefined } from 'lib/typeInference';
import { getCompoundKey } from 'lib/helpers';

interface MenuListProps {
    categories: IEnrichedMenu['categories'];
    onClick?: (product: IEnrichedProduct | ProductGroup) => void;
    currency: string;
    menu?: IEnrichedMenuWithModifierMaps;
    recentOrders?: IOrderReadResourceV12[];
    hasCategoryGroups: boolean;
    showNotWorkingHour: boolean;
    isCollapsible?: boolean;
    refsMap?: RefsMap;
    setActiveCategory?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const useStyles = makeStyles((theme: Theme) => ({
    menuContainer: ({ isDesktop }: { isDesktop: boolean }) => ({
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        flex: 1,
        width: '100%',
        position: 'relative',
        ...(isDesktop
            ? {
                  paddingBottom: 'calc(var(--vh, 1vh) * 100)'
              }
            : {})
    }),
    menu: {
        flex: 1,
        padding: theme.spacing(0),
        paddingBottom: theme.spacing(7)
    }
}));

export const MenuList: React.FC<MenuListProps> = ({
    categories,
    currency,
    menu,
    recentOrders,
    hasCategoryGroups,
    showNotWorkingHour,
    isCollapsible = true,
    setActiveCategory,
    refsMap
}) => {
    const { isDesktop } = useResponsive();
    const [selectedProduct, setSelectedProduct] = React.useState(
        null as null | IEnrichedProduct | ProductGroup
    );
    const [selectedCategoryId, setSelectedCategoryId] = React.useState<string>();
    const [productDetailsOpen, setProductDetailsOpen] = React.useState(false);
    const [scenario] = useRouteScenario();
    const classes = useStyles({ isDesktop });
    const { isLocationOrderEnabled } = useLocationHelpers();
    const context = useSuggestionsContext();
    const { addModal } = useAddModal();

    const handleProductSelected = React.useCallback(
        (product: IEnrichedProduct | ProductGroup, categoryId: string) => {
            setSelectedCategoryId(categoryId);
            setSelectedProduct(product);
            setProductDetailsOpen(true);
        },
        []
    );
    const renderRecentOrders = React.useCallback(() => {
        if (recentOrders) {
            if (refsMap) {
                refsMap['recent-orders'] = { ref: React.createRef<HTMLDivElement>(), active: false };
                return (
                    <div ref={refsMap['recent-orders'].ref}>
                        <Box paddingX={1} paddingTop={1.5}>
                            <RecentOrdersList
                                setActiveCategory={setActiveCategory}
                                isCollapsible={!isDesktop}
                                orders={recentOrders}
                            />
                        </Box>
                    </div>
                );
            }

            return <RecentOrdersList orders={recentOrders} />;
        }
        return null;
    }, [isDesktop, recentOrders, refsMap, setActiveCategory]);
    const renderCategories = React.useCallback(
        (item: IEnrichedCategory, index: number, iteratedCategories: IEnrichedCategory[]) => {
            const isExpandedInitially = iteratedCategories.length === 1 && index === 0;
            if (refsMap) {
                const key = getCompoundKey(item.groupCategoryId, item.id);
                refsMap[key] = {
                    ref: React.createRef<HTMLDivElement>(),
                    active: false
                };

                return (
                    <div ref={refsMap[key].ref} key={`${item.id}^${item.groupCategoryId}`}>
                        <Box paddingX={1}>
                            <MenuCategory
                                isCollapsible={isCollapsible}
                                item={item}
                                onProductSelect={handleProductSelected}
                                setActiveCategory={setActiveCategory}
                                isExpandedInitially={isExpandedInitially}
                                menu={menu}
                            />
                        </Box>
                    </div>
                );
            }
            return (
                <MenuCategory
                    isCollapsible={isCollapsible}
                    item={item}
                    key={item.id}
                    onProductSelect={handleProductSelected}
                    isExpandedInitially={isExpandedInitially}
                    menu={menu}
                />
            );
        },
        [refsMap, isCollapsible, handleProductSelected, menu, setActiveCategory]
    );

    const handleProductDetailsClose = React.useCallback(() => {
        setProductDetailsOpen(false);
        setSelectedProduct(null);
        setSelectedCategoryId(undefined);
        context.setShowMoreSuggestionInfo(false);
    }, [context]);

    React.useEffect(() => {
        if (context.suggestedGroupProduct) {
            setSelectedProduct(context.suggestedGroupProduct);
            setSelectedCategoryId(menu?.productIdToCategoryId.get(context.suggestedGroupProduct.id));
            setProductDetailsOpen(true);
        }
    }, [context.suggestedGroupProduct, menu?.productIdToCategoryId]);

    return (
        <div className={classes.menuContainer} id="menu-container">
            {showNotWorkingHour && !hasCategoryGroups && scenario && <NotWorkingHour scenario={scenario} />}
            {!isLocationOrderEnabled && (!hasCategoryGroups || isDesktop) && <OrderingDisabled />}

            <List className={classes.menu}>
                {(isDesktop || (!isDesktop && !hasCategoryGroups)) && renderRecentOrders()}
                {categories.map(renderCategories)}
            </List>
            {addModal()}
            {selectedProduct && isDefined(selectedCategoryId) && (
                <MenuProductInfo
                    categoryId={selectedCategoryId}
                    item={selectedProduct}
                    currency={currency}
                    open={productDetailsOpen}
                    onClose={handleProductDetailsClose}
                />
            )}
        </div>
    );
};
