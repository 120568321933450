import { BottomDialog } from 'lib/BottomDialog';
import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { UserData } from './model/User';
import { AxiosResponse } from 'axios';
import { ApplicationState } from 'store/store';
import { useAppleSSO } from './hooks/useAppleSSO';
import { SSO_BUTTON_MAX_WIDTH } from 'config/constants';
import { useTranslation } from 'react-i18next';
import { Throbber } from 'lib/Throbber';

interface AppleSSOProps {
    onSignInSuccess: (data: UserData) => void;
    onSignInError: (error: AxiosResponse<{ message: string; code: string }>) => void;
}

export const AppleSSO: React.FC<AppleSSOProps> = ({ onSignInSuccess, onSignInError }) => {
    const { t } = useTranslation();
    const container = React.useRef<HTMLDivElement>();
    const { settings } = useSelector((state: ApplicationState) => state.settings);

    const { brokenUserPopup, loading } = useAppleSSO(onSignInSuccess, onSignInError);
    const handleRefresh = React.useCallback(() => {
        window.location.reload();
    }, []);
    const buttonWidth =
        (container.current?.clientWidth ?? 0) > SSO_BUTTON_MAX_WIDTH
            ? SSO_BUTTON_MAX_WIDTH
            : container.current?.clientWidth ?? 0;
    return (
        <React.Fragment>
            {settings?.appleSSOEnabled && (
                <Grid item xs={12} innerRef={container}>
                    <Box display="flex" justifyContent="center" fontSize="12px">
                        <div id="appleid-signin" data-height="40" data-width={buttonWidth} />
                    </Box>
                </Grid>
            )}
            <BottomDialog open={brokenUserPopup}>
                <Box>
                    <Typography variant="h6" align="center">
                        {t('ACCOUNT_EXISTING_SSO_ERROR_TITLE')}
                    </Typography>
                    <Typography align="center">{t('ACCOUNT_EXISTING_SSO_ERROR_MESSAGE')}</Typography>
                    <Button variant="contained" color="primary" onClick={handleRefresh}>
                        {t('GENERAL_NOTIFICATION_DISMISS')}
                    </Button>
                </Box>
            </BottomDialog>
            {loading && <Throbber isFullScreen text="" />}
        </React.Fragment>
    );
};
