import * as React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { ETipScheme } from 'components/settings/enums';
import { useCurrencyString } from 'lib/useCurrencyString';

interface IProps {
    value: number;
    scheme: ETipScheme;
    isLast: boolean;
    onClick: (value: number) => void;
    selected: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tipOptionSelectorItem: {
            flex: 1,
            padding: theme.spacing(1)
        },
        bordered: {
            borderRight: `1px solid ${theme.palette.primary.main}`
        },
        active: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.getContrastText(theme.palette.primary.main)
        }
    })
);

export const ViewBillTipSelectorItem: React.FC<IProps> = ({ value, scheme, isLast, onClick, selected }) => {
    const classes = useStyles();
    const getCurrencyString = useCurrencyString();
    const tipString = React.useMemo(() => {
        if (scheme === ETipScheme.PERCENTAGE) {
            return `${value}%`;
        }
        return getCurrencyString(value);
    }, [getCurrencyString, scheme, value]);
    const handleSelectorClick = React.useCallback(() => onClick(value), [onClick, value]);

    return (
        <Box
            onClick={handleSelectorClick}
            className={clsx(classes.tipOptionSelectorItem, {
                [classes.bordered]: !isLast,
                [classes.active]: selected
            })}
        >
            {tipString}
        </Box>
    );
};
