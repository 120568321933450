import React from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { QuantitySelector } from './QuantitySelector';
import clsx from 'clsx';

export interface QuantityFieldProps {
    label: React.ReactNode;
    description?: string;
    value: number;
    onChange: (value: number) => void;
    min?: number;
    image?: string;
    max?: number;
    disabledMax?: boolean;
    disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    image: ({ image }: QuantityFieldProps) => ({
        height: theme.spacing(5),
        width: theme.spacing(5),
        backgroundImage: `url(${image})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        marginRight: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden'
    }),
    secondary: {
        opacity: 0.7
    }
}));

export const QuantityField: React.FC<QuantityFieldProps> = props => {
    const { label, value, onChange, description, disabledMax, image, disabled, min = 0, max = 999 } = props;
    const classes = useStyles(props);
    return (
        <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
            <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Typography className={clsx({ [classes.secondary]: disabled })}>{label}</Typography>
                {description && (
                    <Typography variant="caption" className={classes.secondary}>
                        {description}
                    </Typography>
                )}
            </Box>
            <Box display="flex" alignItems="center">
                {!!image && <div className={classes.image} />}
                <QuantitySelector
                    disabledMax={disabledMax || disabled}
                    min={min}
                    max={max}
                    quantity={value}
                    onChange={onChange}
                />
            </Box>
        </Box>
    );
};
