import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Button, Grid, RadioGroup } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useResponsive } from 'src/hooks/useResponsive';
import { BottomDialog } from 'lib/BottomDialog';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/LoadingButton';

export const TimeslotPickerDialogSkeleton: React.FC = () => {
    const { t } = useTranslation();
    const { currentLocation } = useSelector((state: ApplicationState) => state.locations);
    const { isDesktop } = useResponsive();

    return (
        <BottomDialog open={true}>
            <Box paddingX={1} minWidth={isDesktop && !currentLocation?.mainImageUrl ? 445 : 0}>
                <Box marginTop={1}>
                    <Box width="100%" display="flex" alignItems="center" flexDirection="column">
                        <Skeleton height={30} width="90%" />
                        <Skeleton height={30} width="50%" />
                    </Box>
                </Box>

                <Box marginBottom={2}>
                    <RadioGroup>
                        <Box width="100%" display="flex" alignItems="flex-start" flexDirection="column">
                            <Box width="100%" display="flex" alignItems="center" marginBottom={2}>
                                <Skeleton width={20} height={20} variant="circle" />
                                <Box marginLeft={1}>
                                    <Skeleton height={20} width={35} />
                                </Box>
                            </Box>
                            <Box width="100%" display="flex" alignItems="center">
                                <Skeleton width={20} height={20} variant="circle" />
                                <Box marginLeft={1}>
                                    <Skeleton height={20} width={30} />
                                </Box>
                            </Box>
                        </Box>
                    </RadioGroup>
                </Box>

                <Box width="100%" marginBottom={2}>
                    <Skeleton width="100%" height={50} />
                </Box>

                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <LoadingButton disabled variant="contained" color="primary" fullWidth>
                            {t('DIALOG_TIME_SLOT_PICKER_PICK_BTN')}
                        </LoadingButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Box width="100%">
                            <Button variant="contained" fullWidth disabled>
                                {t('DIALOG_PREORDER_TIME_SLOT_NOT_AVAILABLE_CANCEL_BTN')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </BottomDialog>
    );
};
