import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, createStyles, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { IUserReadResourceV12, OrderScenario } from 'components/order/model/Order';
import { getTimeslotTimes } from 'components/timeslots/helpers';
import { addOpacity } from 'lib/helpers';
import { UserAvatar } from 'lib/UserAvatar';
import { ApplicationState } from 'store/store';

interface IProps {
    users?: IUserReadResourceV12[];
    pin?: string;
    passphrase?: string;
    infoVisible?: boolean;
    locationVisible?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            textAlign: 'center',
            fontFamily: `TenantFont, ${theme.typography.fontFamily}`,
            fontWeight: 'bold',
            color: theme.palette.text.primary
        },
        userInitials: {
            fontSize: theme.spacing(2),
            color: theme.palette.common.black,
            border: `1px solid ${addOpacity(theme.palette.common.black, 0.6)}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            width: theme.spacing(6),
            height: theme.spacing(6),
            marginRight: theme.spacing(0.5),
            flexShrink: 0
        },
        users: {
            display: 'flex',
            paddingLeft: theme.spacing(2),
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
                display: 'none'
            }
        },
        hint: {
            color: theme.palette.text.hint
        },
        icon: {
            color: theme.palette.text.hint
        },
        textBold: {
            fontWeight: 700
        }
    })
);

export const ViewBillHeaderInfo: React.FC<IProps> = ({
    users,
    pin,
    passphrase,
    locationVisible = true,
    infoVisible = false
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [isHeaderVisible, setIsHeaderVisible] = React.useState(true);
    const [scenario] = useRouteScenario();

    const { currentLocation } = useSelector((state: ApplicationState) => state.locations);
    const { deliveryLocation, timeSlot, prepTimePadding } = useSelector(
        (state: ApplicationState) => state.basket
    );
    const scenarioToUse = React.useMemo(() => scenario ?? OrderScenario.PREORDER, [scenario]);
    const { settings } = useSelector((state: ApplicationState) => state.settings);

    const joinedUsers = React.useMemo(() => (users ? users.filter(user => !user.hasLeft) : []), [users]);
    const timePadding = React.useMemo(() => prepTimePadding ?? 0, [prepTimePadding]);

    const renderUserInitials = React.useCallback(
        (user: IUserReadResourceV12) => <UserAvatar user={user} key={user.id} />,
        []
    );
    const handleInfoClick = React.useCallback(() => setIsHeaderVisible(!isHeaderVisible), [isHeaderVisible]);
    const subtitle = React.useMemo(() => {
        switch (scenarioToUse) {
            case OrderScenario.TABLE:
                return (
                    deliveryLocation && (
                        <Typography
                            align="center"
                            variant="body2"
                            className={classes.textBold}
                            data-cy="table-number"
                        >
                            {t('BILL_TABLE_NUMBER', { tableNumber: deliveryLocation })}
                        </Typography>
                    )
                );

            case OrderScenario.PREORDER:
                return timeSlot ? (
                    <Typography align="center" variant="body2" className={classes.textBold}>
                        {getTimeslotTimes(timeSlot, t, true)}
                    </Typography>
                ) : (
                    <Typography align="center" variant="body2" className={classes.textBold}>
                        {t('ORDER_STATUS_ORDER_READY_IN_MINS', {
                            from: (settings?.preOrder.readyPeriodStart || 5) + timePadding,
                            to: (settings?.preOrder.readyPeriodEnd || 20) + timePadding
                        })}
                    </Typography>
                );
            case OrderScenario.ORDER_TO_TABLE:
                return (
                    <Typography align="center" variant="body2" className={classes.textBold}>
                        {t('LABEL_TABLE_NUMBER_PLACEHOLDER', { tableNumber: deliveryLocation })}
                    </Typography>
                );

            default:
        }
    }, [
        classes.textBold,
        deliveryLocation,
        settings?.preOrder?.readyPeriodEnd,
        settings?.preOrder?.readyPeriodStart,
        scenarioToUse,
        t,
        timePadding,
        timeSlot
    ]);

    return (
        <Box>
            <Box display="flex" alignItems="center" justifyContent="center">
                {locationVisible && (
                    <Typography className={classes.title} variant="h6">
                        {currentLocation?.title}
                    </Typography>
                )}
                {infoVisible && joinedUsers.length > 1 && (
                    <IconButton size="small" onClick={handleInfoClick}>
                        <InfoIcon className={classes.icon} />
                    </IconButton>
                )}
            </Box>
            {subtitle}

            {users && joinedUsers.length > 1 && isHeaderVisible && (
                <Box className={classes.users} paddingLeft={2}>
                    {joinedUsers.map(renderUserInitials)}
                </Box>
            )}
            {currentLocation && isHeaderVisible && !currentLocation.services.pay_at_table && (
                <>
                    {pin && (
                        <Typography align="center">
                            {t('VIEW_BILL_INVITE_PIN_LABEL')}
                            <span className={classes.hint}>{pin}</span>
                        </Typography>
                    )}
                    {passphrase && (
                        <Typography align="center">
                            {t('VIEW_BILL_PASSPHRASE_LABEL')}
                            <span className={classes.hint}>{passphrase}</span>
                        </Typography>
                    )}
                </>
            )}
        </Box>
    );
};
