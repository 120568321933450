import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    Accordion,
    AccordionSummary,
    Box,
    Button,
    createStyles,
    List,
    ListItem,
    makeStyles,
    Theme
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import random from 'lodash/random';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { ApplicationState } from 'store/store';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuContainer: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            padding: theme.spacing(1, 0),
            paddingBottom: theme.spacing(7),
            overflowY: 'hidden'
        },
        menu: {
            flex: 1,
            overflowY: 'auto',
            padding: theme.spacing(0, 1.5)
        },
        listItem: {
            padding: theme.spacing(1, 0),
            borderBottom: `1px solid ${theme.palette.divider}`
        },
        panel: {
            width: '100%'
        },
        viewBasketButton: {
            width: `calc(100% - ${theme.spacing(2)}px)`,
            padding: theme.spacing(1),
            margin: theme.spacing(0, 1),
            marginTop: 'auto'
        },
        expandIcon: {
            padding: theme.spacing(3, 1.5),
            '& svg': {
                fontSize: '32px'
            }
        },
        panelSummary: {
            padding: 0,
            alignItems: 'flex-start'
        }
    })
);

export const MenuSkeleton: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { currentLocation } = useSelector((state: ApplicationState) => state.locations);

    return (
        <Box height="100%" width="100%" className="hidden-scroll">
            <MuiAppBar title={currentLocation ? currentLocation.title : 'Mechu'} />
            <div className={classes.menuContainer}>
                <div className={classes.menuContainer}>
                    <List className={classes.menu}>
                        {Array(7)
                            .fill('')
                            .map((_, index) => (
                                <ListItem key={index} disableGutters className={classes.listItem}>
                                    <Accordion
                                        expanded={false}
                                        className={classes.panel}
                                        TransitionProps={{ unmountOnExit: true, exit: false }}
                                        elevation={0}
                                    >
                                        <AccordionSummary
                                            classes={{
                                                expandIcon: classes.expandIcon
                                            }}
                                            className={classes.panelSummary}
                                            expandIcon={<ExpandMoreIcon />}
                                        >
                                            <Box width="100%">
                                                <Skeleton height={32} width={random(70, 120)} />
                                                <Skeleton height={20} width={random(90, 140)} />
                                            </Box>
                                        </AccordionSummary>
                                    </Accordion>
                                </ListItem>
                            ))}
                    </List>
                </div>
                <Button color="primary" variant="contained" className={classes.viewBasketButton} disabled>
                    {t('PREORDER_VIEW_BASKET')}
                </Button>
            </div>
        </Box>
    );
};
