import * as React from 'react';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import sortBy from 'lodash/sortBy';
import { IPointAward } from 'components/awards/models';
import { replacePluralString } from 'components/awards/utils';
import { StampCircle } from '../StampCircle';

interface IProps {
    award: IPointAward;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: `${theme.spacing(3)}px`,
            fontWeight: theme.typography.fontWeightBold
        },
        subTitle: {
            fontSize: `${theme.spacing(2)}px`
        },
        availableCards: {
            fontWeight: theme.typography.fontWeightBold
        },
        availableCardCopyText: {
            fontSize: `${theme.spacing(2.25)}px`
        },
        currentStamps: {
            marginTop: 'auto'
        },
        nextCardProgressCopy: {
            fontSize: `${theme.spacing(1.75)}px`
        }
    })
);

export const StampCard: React.FC<IProps> = ({
    award: {
        points: { available, redemption },
        visualisation: {
            subtitleText,
            stampColour,
            stampImageUrl,
            availableCardCopy,
            nextCardProgressCopy,
            textColour,
            titleText
        }
    }
}) => {
    const classes = useStyles();
    const isReedemable = React.useMemo(() => available > redemption, [available, redemption]);
    const availableCards = React.useMemo(
        () => (isReedemable ? Math.floor(available / redemption) : available),
        [available, isReedemable, redemption]
    );

    const currentStamps = React.useMemo(() => available % redemption, [available, redemption]);
    const stampsArray = React.useMemo(() => {
        const rows: { selected: boolean; delay: number }[][] = [];
        const filled = [Math.ceil(currentStamps / 2), currentStamps - Math.ceil(currentStamps / 2)];
        let delay = 100;
        for (let i = 1; i <= redemption; i++) {
            if (i <= Math.ceil(redemption / 2)) {
                const stamp = { selected: false, delay: 0 };
                if (filled[0] > 0) {
                    stamp.selected = true;
                    stamp.delay = delay;
                    delay += 100;
                    filled[0]--;
                }
                rows[0] = rows[0] ? [...rows[0], stamp] : [stamp];
            } else {
                const stamp = { selected: false, delay: 0 };
                if (filled[1] > 0) {
                    stamp.selected = true;
                    stamp.delay = delay;
                    delay += 100;
                    filled[1]--;
                }
                rows[1] = rows[1] ? [...rows[1], stamp] : [stamp];
            }
        }

        return rows;
    }, [currentStamps, redemption]);

    const renderStampCircle = React.useCallback(
        ({ selected, delay }, index) => (
            <StampCircle
                key={index}
                filled={selected}
                stampColor={stampColour || ''}
                stampImage={stampImageUrl}
                animationDelay={delay}
                small
            />
        ),
        [stampColour, stampImageUrl]
    );
    const oneLineStamps = React.useMemo(
        () => sortBy(stampsArray.flat(), stamp => !stamp.selected),
        [stampsArray]
    );

    return (
        <Box width="100%" display="flex" flexDirection="column" height="100%" color={textColour}>
            {!isReedemable ? (
                <>
                    <Typography color="inherit" display="block" className={classes.title}>
                        {titleText}
                    </Typography>
                    <Typography color="inherit" display="block" className={classes.subTitle}>
                        {subtitleText}
                    </Typography>
                    <Box marginTop="auto">
                        {stampsArray.map((stamps, index) => (
                            <Box display="flex" key={index} marginLeft={index === 1 ? 2.75 : 0}>
                                {stamps.map(({ selected, delay }, i) => (
                                    <StampCircle
                                        key={i}
                                        filled={selected}
                                        stampColor={stampColour || ''}
                                        stampImage={stampImageUrl}
                                        animationDelay={delay}
                                    />
                                ))}
                            </Box>
                        ))}
                    </Box>
                </>
            ) : (
                <Box marginBottom="auto" display="flex" flexDirection="column" height="100%">
                    <Box display="flex" alignItems="center">
                        <Typography className={classes.availableCards} variant="h1" color="inherit">
                            {availableCards}
                        </Typography>
                        <Box marginLeft={2}>
                            <Typography color="inherit" className={classes.availableCardCopyText}>
                                {replacePluralString(availableCards, availableCardCopy || '')}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={classes.currentStamps}>
                        <Typography color="inherit" className={classes.nextCardProgressCopy}>
                            {nextCardProgressCopy}
                        </Typography>
                        <Box display="flex" marginTop={1}>
                            {oneLineStamps.map(renderStampCircle)}
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};
