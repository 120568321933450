import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { useGoogleSSO } from './hooks/useGoogleSSO';
import { useSelector } from 'react-redux';
import { UserData } from './model/User';
import { AxiosResponse } from 'axios';
import { ApplicationState } from 'store/store';
import { Throbber } from 'lib/Throbber';

interface GoogleSSOProps {
    onSignInSuccess: (data: UserData) => void;
    onSignInError: (error: AxiosResponse<{ message: string; code: string }>) => void;
    onStart?: () => void;
}

export const GoogleSSO: React.FC<GoogleSSOProps> = ({ onSignInSuccess, onSignInError, onStart }) => {
    const { settings } = useSelector((state: ApplicationState) => state.settings);

    const { googleSSOId, container, loading } = useGoogleSSO(onSignInSuccess, onSignInError, onStart);
    if (!settings?.googleSSOEnabled) {
        return null;
    }
    return (
        <Grid item xs={12} innerRef={container}>
            <Box display="flex" justifyContent="center">
                <div id={googleSSOId} />
            </Box>
            {loading && <Throbber isFullScreen text="" />}
        </Grid>
    );
};
