import * as React from 'react';
import { InView } from 'react-intersection-observer';
import { Box, CircularProgress } from '@material-ui/core';
import isFunction from 'lodash/isFunction';

interface IProps<T, D> {
    list: T[];
    loadMore: () => Promise<D>;
    dataListGetter: (data: D) => T[];
    hasNext: boolean;
    isLoading: boolean;
}

export function InfiniteLoader<T, D>({
    list,
    loadMore,
    dataListGetter,
    isLoading,
    hasNext,
    children
}: React.PropsWithChildren<IProps<T, D>>): JSX.Element {
    const [scrollingList, setScrollingList] = React.useState<T[]>(list);

    const handleOnView = React.useCallback(
        async (inView: boolean) => {
            if (!isLoading && inView && hasNext) {
                const data = await loadMore();
                const newList = dataListGetter(data);
                setScrollingList(l => [...l, ...newList]);
            }
        },
        [hasNext, isLoading, dataListGetter, loadMore]
    );

    return (
        <>
            {isFunction(children) && children(scrollingList)}
            {hasNext && (
                <InView onChange={handleOnView}>
                    {({ inView, ref }) => (
                        <div ref={ref}>
                            <Box display="flex" paddingY={1} justifyContent="center">
                                {inView && <CircularProgress variant="indeterminate" />}
                            </Box>
                        </div>
                    )}
                </InView>
            )}
        </>
    );
}
