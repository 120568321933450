const GOOGLE_PAY_URL = 'https://pay.google.com/gp/p/js/pay.js';

let cached: Promise<any> | undefined = undefined;

export function loadGooglePay(): Promise<void> {
    const existing = cached;
    if (existing) {
        return existing;
    }

    const promise = new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.src = GOOGLE_PAY_URL;
        script.async = true;
        const onScriptLoad = (): void => {
            resolve();
        };

        const onScriptError = (): void => {
            cleanup();
            cached = undefined;
            script.remove();

            reject(new Error(`Unable to load script ${GOOGLE_PAY_URL}`));
        };

        script.addEventListener('load', onScriptLoad);
        script.addEventListener('error', onScriptError);
        document.body.appendChild(script);

        function cleanup(): void {
            script.removeEventListener('load', onScriptLoad);
            script.removeEventListener('error', onScriptError);
        }
    });
    cached = promise;

    return promise;
}
