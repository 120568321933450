import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { isOrderAvailableAdjastment } from '../helpers';
import { roundToDecimal, roundToEvenDP } from 'lib/helpers';
import {
    ExistingSharedOrderData,
    IAdjustmentLocalResource,
    IAdjustmentReadResourceV10
} from 'components/order/model/Order';
import { ExistingSharedBasketData } from 'components/basket/helpers';
import { isNumber } from 'lib/typeInference';

export function useOrderRewards(
    adjustments: IAdjustmentReadResourceV10[] | undefined,
    orderPrice: number,
    existingParams: ExistingSharedOrderData | ExistingSharedBasketData
) {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const [selectedReward, setSelectedReward] = React.useState<IAdjustmentLocalResource | undefined>(
        existingParams.selectedReward ?? undefined
    );
    const availableRewards: IAdjustmentLocalResource[] = React.useMemo(() => {
        if (!Array.isArray(adjustments)) {
            return [];
        }
        return adjustments
            .filter(item => isOrderAvailableAdjastment(item))
            .map(item => {
                const itemQuantity = item.quantity ?? 1;
                let fixedQuantity = itemQuantity;
                const chargeDiscountPerUnit = isNumber(item.charges) ? item.charges : 0;
                const valueAfterTax = item.value + (item.taxToAdd ?? 0) + chargeDiscountPerUnit;
                if (fixedQuantity > 1 && orderPrice <= Math.abs(valueAfterTax * (item.quantity ?? 0))) {
                    fixedQuantity = Math.ceil(
                        roundToDecimal(Math.abs(roundToEvenDP(orderPrice / valueAfterTax)))
                    );
                }
                return {
                    ...item,
                    quantity: itemQuantity - fixedQuantity === 1 ? itemQuantity : fixedQuantity,
                    totalValue: roundToEvenDP(fixedQuantity * valueAfterTax)
                };
            });
    }, [adjustments, orderPrice]);
    React.useEffect(() => {
        if (adjustments && selectedReward) {
            const updatedAdjustment = availableRewards.find(item => item.id === selectedReward.id);
            if (updatedAdjustment) {
                setSelectedReward(updatedAdjustment);
            } else {
                setSelectedReward(undefined);
            }
        }
    }, [availableRewards, adjustments, selectedReward]);
    const handleSetReward = React.useCallback(
        (id: number) => {
            const selected = availableRewards.find(item => item.id === id);
            setSelectedReward(selected);
        },
        [availableRewards]
    );
    const handleClearRewards = React.useCallback(() => {
        setSelectedReward(undefined);
    }, []);
    const rewardsEnabled = React.useMemo(() => !!(settings && !!settings.loyaltyEnabled), [settings]);
    return { availableRewards, selectedReward, handleSetReward, handleClearRewards, rewardsEnabled };
}
