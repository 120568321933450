import * as React from 'react';
import { useCountUp } from 'react-countup';
import { IPointAward } from 'components/awards/models';
import { replacePluralString } from 'components/awards/utils';
import { useCurrencyString } from 'lib/useCurrencyString';

export const useTokenizedString = (award: IPointAward) => {
    const { countUp: points } = useCountUp({ start: 0, end: award.points.available, duration: 3 });
    const { countUp: balance } = useCountUp({
        start: 0,
        end: award.points.available * award.discount.value,
        decimals: 2,
        duration: 3
    });
    const getCurrencyString = useCurrencyString();
    const renderTokenizedString = React.useCallback(
        (str: string) => {
            let tokenizedText = str;
            if (str) {
                if (tokenizedText.includes('{{available}}')) {
                    tokenizedText = replacePluralString(
                        Number(points),
                        tokenizedText.replace('{{available}}', points.toString()) || ''
                    );
                }
                if (tokenizedText.includes('{{currency}}')) {
                    tokenizedText = tokenizedText.replace('{{currency}}', getCurrencyString(Number(balance)));
                }
            }

            return tokenizedText;
        },
        [balance, getCurrencyString, points]
    );

    return { renderTokenizedString };
};
