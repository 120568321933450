import React from 'react';
import {
    createStyles,
    FormControl,
    FormControlLabel,
    FormLabel,
    makeStyles,
    Radio,
    RadioGroup,
    Theme
} from '@material-ui/core';
import { PaymentMethodItem } from './PaymentMethodItem';

export interface Option {
    label: string | React.ReactNode;
    value: any;
    onClickValue?: any;
}

interface PaymentMethodGroupProps {
    label?: string;
    value: any;
    options: Option[];
    onChange: (value: string) => void;
    onClick?: (onClickValue: any) => void;
    loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        radio: {
            justifyContent: 'space-between',
            width: '100%',
            margin: 0
        },
        dot: {
            color: theme.palette.primary.main,
            '&.Mui-checked': {
                color: theme.palette.primary.main
            }
        }
    })
);

export const PaymentMethodGroup: React.FC<PaymentMethodGroupProps> = ({
    label,
    value,
    options,
    loading,
    onChange,
    onClick
}) => {
    const classes = useStyles();

    const handleChange = React.useCallback(
        (_event: any, newValue: string) => {
            onChange(newValue);
        },
        [onChange]
    );

    const handleClick = React.useCallback(
        (onClickValue: any) => () => onClick && onClick(onClickValue),
        [onClick]
    );
    const renderRadioButton = React.useCallback(
        (option: Option) => (
            <FormControlLabel
                className={classes.radio}
                value={option.value}
                control={<Radio className={classes.dot} />}
                labelPlacement="start"
                label={option.label}
                key={option.value}
                onClick={handleClick(option.onClickValue)}
            />
        ),
        [classes.dot, classes.radio, handleClick]
    );

    if (loading) {
        return (
            <FormControl component="fieldset" fullWidth>
                <RadioGroup name="mui-radio-group">
                    <FormControlLabel
                        className={classes.radio}
                        control={<Radio disabled />}
                        labelPlacement="start"
                        label={<PaymentMethodItem loading type="APPLEPAY" />}
                    />
                </RadioGroup>
            </FormControl>
        );
    }

    return (
        <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup name="mui-radio-group" value={value} onChange={handleChange}>
                {options.map(renderRadioButton)}
            </RadioGroup>
        </FormControl>
    );
};
