/* eslint-disable no-bitwise */
import { ExistingSharedOrderData } from 'components/order/model/Order';
import logger from 'lib/logger';
import { isDefined } from 'lib/typeInference';

const byteArrayToLong = (byteArray: number[]) => {
    let value = BigInt(0);
    for (let i = byteArray.length - 1; i >= 0; i--) {
        value = value * BigInt(256) + BigInt(byteArray[i]);
    }

    return value;
};

const simpleUrlDecode = (url: string) => {
    url += '=';
    url = url.replaceAll('-', '+'); // 62nd char of encoding
    url = url.replaceAll('_', '/'); // 63rd char of encoding
    if (new Uint32Array(new Uint8Array([1, 2, 3, 4]).buffer)[0] === 0x04030201) {
        return url;
    }
    const splitString = url.split('');
    const reverseArray = splitString.reverse();
    return reverseArray.join('');
};

export const decodeDateCheckNumString = (value: string) => {
    const buffer = atob(simpleUrlDecode(value));
    const charCodesArray = buffer.split('').map(item => item.charCodeAt(0));
    const longRepresentation = byteArrayToLong(charCodesArray);
    const dateAsBytes = longRepresentation >> BigInt(32);
    const checkId = String(BigInt(longRepresentation) - (dateAsBytes << BigInt(32)));
    const year = dateAsBytes >> BigInt(16);
    const monthAndDay = dateAsBytes - (year << BigInt(16));
    const month = monthAndDay >> BigInt(8);
    const day = dateAsBytes - ((year << BigInt(16)) | (month << BigInt(8)));
    const dayString = day.toString();
    const monthString = month.toString();
    const date = `${year}-${monthString.length > 1 ? monthString : `0${monthString}`}-${
        dayString.length > 1 ? dayString : `0${dayString}`
    }`;
    return { checkId, date };
};

export const encodeDateCheckNumString = (checkId: string) => {
    const longToByteArray = (long: bigint) => {
        // we want to represent the input as a 8-bytes array
        const byteArray: bigint[] = [
            BigInt(0),
            BigInt(0),
            BigInt(0),
            BigInt(0),
            BigInt(0),
            BigInt(0),
            BigInt(0),
            BigInt(0)
        ];
        for (let index = 0; index < byteArray.length; index++) {
            const byte = long & BigInt(0xff);
            byteArray[index] = byte;
            long = (long - byte) / BigInt(256);
        }
        return byteArray;
    };
    const arrayBufferToBase64 = (buffer: bigint[]) => {
        let binary = '';
        const bytes = new Uint8Array(buffer.map(item => Number(item.toString())));
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
    };
    const getB64 = (value: bigint) => {
        const bytes = longToByteArray(value);
        return arrayBufferToBase64(bytes);
    };
    const urlEncode = (url: string) => {
        if (url.endsWith('=')) {
            url = url.substring(0, url.length - 1);
        }
        url = url.replaceAll('+', '-'); // 62nd char of encoding
        url = url.replaceAll('/', '_'); // 63rd char of encoding
        if (new Uint32Array(new Uint8Array([1, 2, 3, 4]).buffer)[0] === 0x04030201) {
            return url;
        }
        const splitString = url.split('');
        const reverseArray = splitString.reverse();
        return reverseArray.join('');
    };
    const getCheckNum = (id: number) => (id >> 20) * 10000 + (id & 0xffff);
    const date = new Date();
    const dateAsBytes = (date.getFullYear() << 16) | ((date.getMonth() + 1) << 8) | date.getUTCDate();
    const valueToEncode = (BigInt(dateAsBytes) << BigInt(32)) | BigInt(getCheckNum(Number(checkId)));
    return urlEncode(getB64(valueToEncode));
};

export function formatDateCheckNum(value: string) {
    const year = value.slice(0, 4);
    const month = value.slice(4, 6);
    const day = value.slice(6, 8);
    const date = `${year}-${month}-${day}`;
    const checkId = value.slice(8);
    return { checkId, date };
}

const PAY_SESSION_PREFIX = 'quickpay';
const PAY_SESSION_SPLITS_PREFIX = 'quickpay-spl';

export const setPaySession = (
    params: ExistingSharedOrderData,
    merchantId?: string,
    locationId?: string,
    checkId?: string,
    tableNumber?: string
) => {
    sessionStorage.setItem(
        `${PAY_SESSION_PREFIX}-${merchantId}-${locationId}-${checkId}-${tableNumber}`,
        JSON.stringify(params)
    );
};

export const getPaySessionString = (
    merchantId?: string,
    locationId?: string,
    checkId?: string,
    tableNumber?: string
) => {
    const storedString = sessionStorage.getItem(
        `${PAY_SESSION_PREFIX}-${merchantId}-${locationId}-${checkId}-${tableNumber}`
    );
    return storedString;
};

export const getPaySession = (
    merchantId?: string,
    locationId?: string,
    checkId?: string,
    tableNumber?: string
) => {
    let params: ExistingSharedOrderData | undefined;
    try {
        const paySession = getPaySessionString(merchantId, locationId, checkId, tableNumber);
        if (paySession) {
            const parsedSession = JSON.parse(paySession);
            if (parsedSession) {
                params = parsedSession;
            }
        }
    } catch (e) {
        logger.error(e);
    }
    return params;
};

interface SplitSessionParams {
    splitNumber?: number;
    splits?: number;
    custom?: number;
}

export const setPaySessionSplits = (
    params?: SplitSessionParams,
    merchantId?: string,
    locationId?: string,
    checkId?: string,
    tableNumber?: string
) => {
    const key = `${PAY_SESSION_SPLITS_PREFIX}-${merchantId}-${locationId}-${checkId}-${tableNumber}`;
    if (isDefined(params) && (isDefined(params.custom) || isDefined(params.splits))) {
        sessionStorage.setItem(key, JSON.stringify(params));
    } else {
        sessionStorage.removeItem(key);
    }
};

export const getPaySessionSplits = (
    merchantId?: string,
    locationId?: string,
    checkId?: string,
    tableNumber?: string
): SplitSessionParams | undefined => {
    const storedString = sessionStorage.getItem(
        `${PAY_SESSION_SPLITS_PREFIX}-${merchantId}-${locationId}-${checkId}-${tableNumber}`
    );
    try {
        if (storedString) {
            const parsedData = JSON.parse(storedString);
            if (parsedData) {
                return parsedData;
            }
        }
    } catch (e) {
        logger.error(e);
    }
    return undefined;
};
