import * as React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

export const useTimeSlotHelpers = () => {
    const { timeSlot } = useSelector((state: ApplicationState) => state.basket);
    const settings = useSelector((state: ApplicationState) => state.settings.settings);

    const isTimeSlotsAvailable = React.useMemo(
        () => settings?.timeSlotsEnabled && timeSlot && timeSlot.available,
        [settings?.timeSlotsEnabled, timeSlot]
    );

    return { isTimeSlotsAvailable, asapEnabled: settings?.asapOrderingEnabled };
};
