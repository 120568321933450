import { IOrderReadResourceV12 } from 'components/order/model/Order';
import { RecentOrdersAction } from './ordersActions';
import { OrdersActionTypes } from './ordersActionTypes';

export interface OrdersState {
    error: string;
    isLoading: boolean;
    recentOrders?: IOrderReadResourceV12[];
}

export const initialState: OrdersState = {
    error: '',
    isLoading: true,
    recentOrders: undefined
};

export default function (state: OrdersState = initialState, action: RecentOrdersAction): OrdersState {
    switch (action.type) {
        case OrdersActionTypes.END_REQUEST:
            return { ...state, isLoading: false };
        case OrdersActionTypes.START_REQUEST:
            return { ...state, isLoading: true };
        case OrdersActionTypes.GET_RECENT_ORDERS_ERROR:
            return { ...state, recentOrders: undefined, error: action.error };
        case OrdersActionTypes.GET_RECENT_ORDERS_SUCCESS:
            return { ...state, error: '', recentOrders: action.recentOrders };
        default:
            return state;
    }
}
