import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { AxiosResponse } from 'axios';
import noop from 'lodash/noop';
import random from 'lodash/random';
import sortBy from 'lodash/sortBy';
import { useAsyncData } from 'src/hooks/useAsyncData';
import { useResponsive } from 'src/hooks/useResponsive';
import { isLoading } from 'src/utils/request';
import { IItemReadResourceV10 } from 'components/basket/model/Basket';
import { IOrderReadResourceV12, IUserReadResourceV12, SplittableItem } from 'components/order/model/Order';
import { legacyOrderApi } from 'components/order/orderApi';
import { orderService } from 'components/order/orderService';
import { BottomDialog } from 'lib/BottomDialog';
import { InnerPageLayout } from 'lib/InnerPageLayout';
import { InnerPageLayoutBottom } from 'lib/InnerPageLayout/InnerPageLayoutBottom';
import { InnerPageLayoutContent } from 'lib/InnerPageLayout/InnerPageLayoutContent';
import { useAuth } from 'lib/useAuth';
import { useCurrencyString } from 'lib/useCurrencyString';
import { useLocalHistory } from 'lib/useLocalHistory';
import { ROUTES } from 'pages/routes';
import { ViewBillContentSection } from './ViewBillContentSection';
import { ViewBillHeader } from './ViewBillHeader';
import { ViewBillHeaderInfo } from './ViewBillHeaderInfo';
import { ViewBillOrderItem } from './ViewBillOrderItem';
import { useSnackbar } from 'notistack';

interface IProps {
    isOpen: boolean;
    items: IItemReadResourceV10[];
    payments: IOrderReadResourceV12['payments'];
    users: IUserReadResourceV12[];
    orderDetails: IOrderReadResourceV12;
    onClaimItems: React.Dispatch<React.SetStateAction<number[]>>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textButton: {
            color: theme.palette.text.hint,
            textTransform: 'initial'
        },
        dialog: {
            padding: 0,
            [theme.breakpoints.up('sm')]: {
                minWidth: theme.spacing(64),
                maxHeight: '80vh'
            }
        },
        innerLayout: {
            [theme.breakpoints.up('sm')]: {
                overflowY: 'auto'
            }
        }
    })
);

export const SplitByItem: React.FC<IProps> = ({ isOpen, orderDetails, onClaimItems }) => {
    const classes = useStyles();
    const history = useHistory();
    const getCurrencyString = useCurrencyString();
    const [orderDetailsData, setOrderDetailsData] = useAsyncData<IOrderReadResourceV12>(orderDetails);
    const { user: currentUser } = useAuth();
    const { t } = useTranslation();
    const { push } = useLocalHistory();
    const { enqueueSnackbar } = useSnackbar();

    const mappedItems = React.useMemo(() => {
        if (orderDetailsData.data && currentUser) {
            return orderService.getSplittableItemMap(orderDetailsData.data, currentUser);
        }

        return [];
    }, [currentUser, orderDetailsData.data]);

    const unpaidItems = React.useMemo(
        () =>
            mappedItems
                .filter(item => !item.isPaid)
                .sort((item1, item2) => {
                    if (currentUser) {
                        if (
                            item1.claimedBy?.userId === currentUser.id &&
                            item2.claimedBy?.userId !== currentUser.id
                        ) {
                            return -1;
                        }
                        if (
                            item1.claimedBy?.userId !== currentUser.id &&
                            item2.claimedBy?.userId === currentUser.id
                        ) {
                            return 1;
                        }
                        if (item1.claimedBy !== undefined && item2.claimedBy === undefined) {
                            return 1;
                        }
                        if (item1.claimedBy === undefined && item2.claimedBy !== undefined) {
                            return -1;
                        }
                    }
                    return 0;
                }),
        [currentUser, mappedItems]
    );
    const paidItems = React.useMemo(
        () =>
            sortBy(
                mappedItems.filter(item => item.isPaid),
                item => item.claimedBy?.userId
            ),
        [mappedItems]
    );
    const claimedItems = React.useMemo(
        () => unpaidItems.filter(item => item.claimedBy?.userId === currentUser?._id && !item.isPaid),
        [currentUser?._id, unpaidItems]
    );
    const hasClaimedItems = React.useMemo(() => claimedItems && claimedItems.length > 0, [claimedItems]);

    const handleClaimRequest = React.useCallback(
        async (itemIds: number[]) =>
            setOrderDetailsData(() => legacyOrderApi.claimItems(itemIds, orderDetails.id.toString())).catch(
                (err: AxiosResponse) => {
                    if (err.status === 409) {
                        enqueueSnackbar(t('ERROR_CLAIM_CLASH'), { variant: 'error' });
                    }
                }
            ),
        [enqueueSnackbar, orderDetails.id, setOrderDetailsData, t]
    );
    const handleClick = React.useCallback(
        (index: number) => {
            const item = unpaidItems[index];
            const claimedItemIds = mappedItems
                .filter(mappedItem => mappedItem.claimedBy?.userId === currentUser?._id && !mappedItem.isPaid)
                .map(mappedItem => mappedItem.id);
            if (item.claimedBy?.userId === currentUser?._id) {
                const itemIndex = claimedItemIds.indexOf(item.id);

                if (itemIndex !== -1) {
                    claimedItemIds.splice(itemIndex, 1);
                }
            } else if (item.claimedBy === undefined) {
                claimedItemIds.push(item.id);
            }

            // Add condiment products to the list
            claimedItemIds.forEach(itemId => {
                const claimedItem = unpaidItems.find(({ id }) => id === itemId);

                if (claimedItem && claimedItem.condiments.length > 0) {
                    claimedItem.condiments.forEach(condiment => {
                        claimedItemIds.push(
                            ...Array(condiment.quantity / claimedItem.quantity).fill(condiment.id)
                        );
                    });
                }
            });

            handleClaimRequest(claimedItemIds);
        },
        [currentUser?._id, handleClaimRequest, mappedItems, unpaidItems]
    );

    const renderOrderItem = React.useCallback(
        (item: SplittableItem, index: number) => {
            if (orderDetailsData.data) {
                if (isLoading(orderDetailsData)) {
                    const hasDesc = orderDetailsData.data.items.some(
                        orderItem => orderItem.parentReferenceId === item.referenceId
                    );
                    return (
                        <Box
                            key={`split-item-${item.id}-${index}`}
                            paddingY={1}
                            display="flex"
                            alignItems="center"
                        >
                            <Box marginRight={0.5} flexShrink={0}>
                                <Skeleton variant="circle" width="32px" height="32px" />
                            </Box>
                            <Box flex={1}>
                                <Skeleton variant="text" width={random(30, 90)} />
                                {hasDesc && <Skeleton variant="text" width={random(30, 90)} />}
                            </Box>
                            <Skeleton variant="text" width={random(30, 45)} />
                        </Box>
                    );
                }

                return (
                    <ViewBillOrderItem
                        index={index}
                        showClaimedBy
                        user={item.claimedBy}
                        orderItem={item}
                        allItems={orderDetailsData.data?.items}
                        onClick={item.available ? handleClick : noop}
                        showPricePerOne
                        key={index}
                    />
                );
            }
            return null;
        },
        [handleClick, orderDetailsData]
    );
    const handleClaimAllRemaining = React.useCallback(() => {
        const claimedItemIds = unpaidItems
            .filter(item => item.claimedBy?.userId === currentUser?._id || item.claimedBy === undefined)
            .map(item => item.id);

        // Add condiment products to the list
        claimedItemIds.forEach(itemId => {
            const claimedItem = unpaidItems.find(({ id }) => id === itemId);

            if (claimedItem && claimedItem.condiments.length > 0) {
                claimedItem.condiments.forEach(condiment => claimedItemIds.push(condiment.id));
            }
        });

        handleClaimRequest(claimedItemIds);
    }, [currentUser?._id, handleClaimRequest, unpaidItems]);
    const handleClearAllClaims = React.useCallback(() => {
        setOrderDetailsData(() => legacyOrderApi.claimItems([], orderDetails.id.toString()));
    }, [orderDetails.id, setOrderDetailsData]);

    const total = React.useMemo(
        () =>
            claimedItems.reduce((acc, item) => {
                acc += item.cost;

                if (item.condiments.length > 0) {
                    item.condiments.forEach(
                        condiment => (acc += (condiment.cost * condiment.quantity) / item.quantity)
                    );
                }

                if (item.modifiers && !!item.modifiers.length) {
                    item.modifiers.forEach(({ options }) => {
                        options.forEach(({ cost }) => (acc += cost));
                    });
                }
                return acc;
            }, 0),
        [claimedItems]
    );

    const handleCheckout = React.useCallback(() => {
        if (currentUser && orderDetailsData.data) {
            const items = orderService.getSplittableItemMap(orderDetailsData.data, currentUser);
            const claimedItemIds = orderService.getUserClaimedItemIds(items, currentUser);

            onClaimItems(claimedItemIds);
        }

        push(ROUTES.JOURNEY.PAT.BILL, {}, '?isCheckingOut=true&isSplitBillItem=true');
    }, [currentUser, onClaimItems, orderDetailsData.data, push]);
    const { isMobile } = useResponsive();
    const handleClose = React.useCallback(() => {
        history.goBack();
    }, [history]);

    return (
        <BottomDialog
            paperClassName={classes.dialog}
            fullScreen={isMobile}
            open={isOpen}
            onClose={handleClose}
        >
            <InnerPageLayout className={classes.innerLayout}>
                {isMobile && <ViewBillHeader title={t('BILL_SPLIT_BY_ITEM_TITLE')} />}
                <Box marginY={1}>
                    <ViewBillHeaderInfo />
                </Box>
                <Box paddingX={1} display="flex" justifyContent="space-between">
                    <Button
                        disabled={isLoading(orderDetailsData)}
                        onClick={handleClaimAllRemaining}
                        className={classes.textButton}
                        variant="text"
                    >
                        {t('TAB_CLAIM_ALL_BUTTON_TITLE')}
                    </Button>
                    <Button
                        disabled={isLoading(orderDetailsData)}
                        onClick={handleClearAllClaims}
                        className={classes.textButton}
                        variant="text"
                    >
                        {t('TAB_CLAIM_NONE_BUTTON_TITLE')}
                    </Button>
                </Box>
                <InnerPageLayoutContent>
                    <ViewBillContentSection bottomSpacing={2}>
                        {unpaidItems.map(renderOrderItem)}
                    </ViewBillContentSection>
                    {paidItems.length > 0 && (
                        <ViewBillContentSection
                            title={t('BILL_SPLIT_BY_ITEM_PAID_ITEMS_LABEL')}
                            bottomSpacing={2}
                        >
                            {paidItems.map(renderOrderItem)}
                        </ViewBillContentSection>
                    )}
                </InnerPageLayoutContent>
                <InnerPageLayoutBottom>
                    <Button
                        disabled={isLoading(orderDetailsData) || !hasClaimedItems}
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={handleCheckout}
                    >
                        {hasClaimedItems
                            ? t('BILL_SPLIT_BY_ITEM_PAY_BTN', {
                                  amount: getCurrencyString(total)
                              })
                            : t('BILL_SPLIT_BY_ITEM_PAY_BTN_DISABLED')}
                    </Button>
                </InnerPageLayoutBottom>
            </InnerPageLayout>
        </BottomDialog>
    );
};
