import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
    Box,
    createStyles,
    Divider,
    makeStyles,
    Paper,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { AxiosResponse } from 'axios';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { makeRouteScenario } from 'src/utils/route';
import { IOrderReadResourceV18, OrderPaymentType, OrderScenario } from 'components/order/model/Order';
import { orderApi } from 'components/order/orderApi';
import { PayError } from 'components/pay/errors/PayError';
import { PayHeader } from 'components/pay/header/PayHeader';
import { PayToolbar } from 'components/pay/header/PayToolbar';
import { PayItemsHeader } from 'components/pay/order-item/PayItemsHeader';
import { PayEmailReceipt } from 'components/pay/summary/PayEmailReceipt';
import { PayGuestConversion } from 'components/pay/summary/PayGuestConversion';
import { PaySummaryItems } from 'components/pay/summary/PaySummaryItems';
import { PaySummaryPayments } from 'components/pay/summary/PaySummaryPayments';
import { PaySummaryReturn } from 'components/pay/summary/PaySummaryReturn';
import { CheckCircle } from 'components/pay/ui/CheckCircle';
import { setOnCloseRedirectUrl } from 'components/user/localAuth';
import { LoadingTypography } from 'lib/LoadingTypography';
import { isDefined } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { BaseRouteParams, ROUTES } from '../routes';
import { PAY_MAX_WIDTH } from '.';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        img: {
            margin: theme.spacing(2, 0),
            color: theme.palette.primary.main
        },
        title: {
            fontSize: '24px',
            paddingTop: theme.spacing(1),
            fontWeight: 500,
            color: theme.palette.text.primary
        },
        text: {
            color: theme.palette.text.primary
        }
    })
);

enum ErrorType {
    GENERIC,
    NOT_FOUND,
    NONE
}

export const PaySummaryPage: React.FC = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(ErrorType.NONE);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [order, setOrder] = React.useState<null | IOrderReadResourceV18>(null);
    const { locationId, orderId } = useParams<{ locationId: string; orderId: string }>();
    const { push } = useLocalHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [scenario] = useRouteScenario();
    const { isGuest, user } = useAuth();
    const theme = useTheme();

    const paid = React.useMemo(() => {
        if (!!order && Array.isArray(order?.payments)) {
            return order.payments.reduce<{ giftCard: number; otherPayments: number }>(
                (acc, orderPayment) => {
                    if (orderPayment.amount) {
                        if (orderPayment.userId !== user?._id) {
                            return acc;
                        }
                        if (orderPayment.type !== OrderPaymentType.GIFT_CARD) {
                            acc.otherPayments += orderPayment.amount;
                        } else {
                            acc.giftCard += orderPayment.amount;
                        }
                    }
                    return acc;
                },
                { giftCard: 0, otherPayments: 0 }
            );
        }
        return { giftCard: 0, otherPayments: 0 };
    }, [order, user?._id]);

    const { merchantId } = useParams<BaseRouteParams>();
    const { pathname, search } = useLocation();
    const fullPath = React.useMemo(() => pathname + search, [pathname, search]);
    React.useEffect(() => {
        if (merchantId) {
            setOnCloseRedirectUrl(fullPath, merchantId);
        }
    }, [fullPath, merchantId]);

    React.useEffect(() => {
        async function getOrderStatus() {
            try {
                const orderResult = await orderApi.getOrder(orderId);
                setOrder(orderResult);
                setLoading(false);
            } catch (orderStatusError) {
                const err: AxiosResponse<{ message: string }> = orderStatusError;
                if (isDefined(err.data && err.status === 404)) {
                    setError(ErrorType.NOT_FOUND);
                    setErrorMessage('Order not found');
                } else {
                    setError(ErrorType.GENERIC);
                    setErrorMessage(err.data.message);
                }
                setLoading(false);
            }
        }
        if (!!user) {
            getOrderStatus();
        }
    }, [dispatch, orderId, locationId, t, isGuest, user]);

    React.useEffect(() => {
        if (!!order && order.scenario !== (scenario as OrderScenario)) {
            push(ROUTES.ORDER.SUMMARY, { scenario: makeRouteScenario(order.scenario), orderId });
        }
    }, [push, scenario, order, orderId]);

    if (loading) {
        return (
            <Box
                height="100%"
                width="100%"
                className="hidden-scroll"
                marginX="auto"
                maxWidth={PAY_MAX_WIDTH}
                overflow="scroll"
            >
                <Paper style={{ minHeight: '100%' }}>
                    <PayHeader variant="small">
                        <PayToolbar loading checkId="" disableBack disableEdit />
                    </PayHeader>
                    <Box display="flex" alignItems="center" flexDirection="column">
                        <LoadingTypography className={classes.title} loading width={150} />
                        <LoadingTypography className={classes.text} loading width={100} />
                        <div className={classes.img}>
                            <Skeleton width={83} height={83} variant="circle" />
                        </div>
                        <Divider />
                    </Box>
                    <Box px={2}>
                        <Divider />
                        <PayItemsHeader paid loading />
                    </Box>
                    <PaySummaryItems loading />
                </Paper>
            </Box>
        );
    }

    return (
        <Box
            height="100%"
            width="100%"
            className="hidden-scroll"
            marginX="auto"
            maxWidth={PAY_MAX_WIDTH}
            overflow="scroll"
        >
            <Paper style={{ minHeight: '100%' }}>
                <PayHeader variant="small">
                    <PayToolbar
                        loading={loading}
                        table={order?.deliveryLocation}
                        checkId={order?.checkId}
                        disableBack
                        disableEdit
                    />
                </PayHeader>
                {!!order && (
                    <Box display="flex" alignItems="center" flexDirection="column">
                        <Typography className={classes.title}>
                            {t('PAT_QUICKPAY_PAYMENT_SUCCESSFUL_TITLE')}
                        </Typography>
                        <Typography className={classes.text}>
                            {order?.isOpen
                                ? t('PAT_QUICKPAY_PAYMENT_SUCCESSFUL_CONTENT_SPLIT')
                                : t('PAT_QUICKPAY_PAYMENT_SUCCESSFUL_CONTENT')}
                        </Typography>
                        <CheckCircle color={theme.palette.primary.main} className={classes.img} />
                        <Divider />
                    </Box>
                )}
                {!loading && error === ErrorType.GENERIC && <PayError errorMessage={errorMessage} />}
                {!loading && (error === ErrorType.NOT_FOUND || !order) && (
                    <PayError errorMessage={errorMessage} />
                )}
                {!!order && (
                    <Box px={2}>
                        <Divider />
                        <PayItemsHeader paid total={paid.otherPayments} />
                    </Box>
                )}
                {!!order && !!order?.splitNumber && (
                    <PaySummaryPayments
                        payments={order?.payments}
                        users={order?.users}
                        balance={order?.total?.balance}
                    />
                )}
                {!!order && <PaySummaryItems order={order} collapseItems={!!order?.splitNumber || isGuest} />}
                {!!order && !order?.isOpen && <PayEmailReceipt orderId={order?.id} />}
                {!!order && !!order?.isOpen && (
                    <PaySummaryReturn
                        checkId={order?.checkId}
                        balance={order?.total?.balance}
                        createdAt={order?.createdAt}
                        tableNumber={order?.deliveryLocation}
                    />
                )}
                {!!order && isGuest && <PayGuestConversion locationId={locationId} />}
            </Paper>
        </Box>
    );
};
