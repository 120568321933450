import { BasketState } from 'store/basket/basketReducer';

export const updateLocalBasket = (basket: BasketState) => {
    localStorage.setItem('basket', JSON.stringify(basket));
    localStorage.setItem('basket_expiration', (Date.now() + 86400000).toString());
};

export const resetLocalBasket = () => {
    localStorage.removeItem('basket');
    localStorage.removeItem('basket_expiration');
};

export const getLocalBasket = (): BasketState | null => {
    const basketString = localStorage.getItem('basket');
    const expiration = localStorage.getItem('basket_expiration');
    if (!basketString || !expiration || Date.now() - parseInt(expiration, 10) > 86400000) {
        resetLocalBasket();
        return null;
    }
    return JSON.parse(basketString);
};
