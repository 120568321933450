interface IWindowExtended extends Record<string, any>, Window {}
/**
 * Gets the browser name or returns an empty string if unknown.
 * This function also caches the result to provide for any
 * future calls this function has.
 *
 * @returns {string}
 */
export function browser() {
    const windowTyped: IWindowExtended = window;

    // Opera 8.0+
    const isOpera =
        (!!windowTyped.opr && !!windowTyped.opr.addons) ||
        !!windowTyped.opera ||
        windowTyped.navigator.userAgent.includes(' OPR/');

    // Firefox 1.0+
    const isFirefox = typeof windowTyped.InstallTrigger !== 'undefined';

    // Safari 3.0+ "[object HTMLElementConstructor]"
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    // Internet Explorer 6-11
    const isIE = /* @cc_on!@*/ false || !!(document as any).documentMode;

    // Edge 20+
    const isEdge = !isIE && !!windowTyped.StyleMedia;

    // Chrome 1+
    const isChrome = !!windowTyped.chrome;

    // Blink engine detection
    const isBlink = (isChrome || isOpera) && !!windowTyped.CSS;

    return {
        isOpera,
        isFirefox,
        isSafari,
        isChrome,
        isIE,
        isEdge,
        isBlink
    };
}
