import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { MerchantTitle } from 'app/MerchantTitle';
import { IAction } from 'components/activity/models';
import { ActivityList } from 'components/activity/ui/List';
import { ActionListSkeleton } from 'components/activity/ui/List/ActionListSkeleton';
import { userApi } from 'components/user/userApi';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { useAuth } from 'lib/useAuth';
import logger from 'lib/logger';

export const ActivityPage: React.FC = () => {
    const { t } = useTranslation();
    const [activity, setActivity] = React.useState<IAction[]>();
    const [loading, setLoading] = React.useState(true);
    const [nextKey, setNextKey] = React.useState<string | undefined>();
    const { user } = useAuth();
    React.useEffect(() => {
        const loadActivity = async () => {
            if (user) {
                try {
                    const result = await userApi.getActivityHistory(user?.id);
                    setActivity(result.items);
                    setNextKey(result.page.nextKey);
                    setLoading(false);
                } catch (e: any) {
                    logger.error(e.message, e);
                }
            }
        };
        loadActivity();
    }, [user, user?.id]);
    const handleLoadMoreItems = React.useCallback(async () => {
        if (user?.id && nextKey) {
            setLoading(true);
            try {
                const result = await userApi.getActivityHistory(user?.id, { startKey: nextKey });
                setActivity(items => [...(items ?? []), ...result.items]);
                setNextKey(result.page.nextKey);
                setLoading(false);
            } catch (e: any) {
                logger.error(e.message, e);
            }
        }
    }, [nextKey, user?.id]);

    return (
        <Box height="100%" width="100%" className="hidden-scroll">
            <MerchantTitle title={t('MAIN_MENU_ACTIVITY')} />
            <MuiAppBar title={t('MAIN_MENU_ACTIVITY')} />
            {activity?.length &&
                !loading &&
                (!!activity.length ? (
                    <ActivityList
                        loadMore={handleLoadMoreItems}
                        isLoading={loading}
                        list={activity}
                        hasNext={!!nextKey}
                    />
                ) : (
                    t('ACTIVITY_LIST_EMPTY')
                ))}
            {loading && <ActionListSkeleton />}
        </Box>
    );
};
