import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { IPaymentProvider, paymentProviderFactory } from 'src/integrations/PaymentProvider/PaymentProvider';
import { addGuestOrder } from 'components/order/localGuestOrders';
import { OperationStatus } from 'components/order/model/Operation';
import {
    IOrderCreateResourceV8,
    OrderAdjustmentType,
    OrderErrorCodes,
    OrderPaymentType,
    OrderScenario
} from 'components/order/model/Order';
import { EOrderNonceKey, orderApi } from 'components/order/orderApi';
import { OPERATION_POLLING_DELAY } from 'config/constants';
import logger from 'lib/logger';
import { isDefined } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import { useCurrencyString } from 'lib/useCurrencyString';
import { useLocalHistory } from 'lib/useLocalHistory';
import { LocationRouteParams, ROUTES } from 'pages/routes';
import { resetBasket } from 'store/basket/basketActions';
import { loadTimeslots } from 'store/settings/settingsActions';
import { ApplicationState } from 'store/store';
import { userApi } from 'components/user/userApi';
import { getUserCard } from 'store/auth/authActions';
import { useSnackbar } from 'notistack';

interface IOperationPageParams extends LocationRouteParams {
    operationId: string;
}

interface IOperationHistoryParams {
    orderBodyToRecreate?: IOrderCreateResourceV8;
    orderBodyToRecreatePat?: IOrderCreateResourceV8 & { id: number };
}

export enum OperationErrorType {
    GENERIC,
    NOT_FOUND,
    NONE,
    THREEDSECURE_AUTH_REQUIRED
}

const SPECIAL_ERROR_CODES: string[] = [
    OrderErrorCodes.DUPLICATE_ORDER_NONCE,
    OrderErrorCodes.DUPLICATE_PENDING_ORDER,
    OrderErrorCodes.UPDATING_THE_BILL
];

export function useOperationStatus() {
    const { operationId, locationId } = useParams<IOperationPageParams>();
    const { settings, timeslots } = useSelector((state: ApplicationState) => state.settings);
    const { lastRequest } = useSelector((state: ApplicationState) => state.request);
    const { enqueueSnackbar } = useSnackbar();
    const getCurrencyString = useCurrencyString();
    const [loading, setLoading] = React.useState(true);
    const [provider, setProvider] = React.useState<IPaymentProvider>();
    const [error, setError] = React.useState(OperationErrorType.NONE);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [showRetry, setShowRetry] = React.useState(false);
    const [changedPriceOrder, setChangedPriceOrder] = React.useState<
        IOrderCreateResourceV8 & { id: number }
    >();
    const [payInstead, setPayInstead] = React.useState<number>();
    const [changedPriceOrderExtraTip, setChangedPriceOrderExtraTip] = React.useState<
        IOrderCreateResourceV8 & { id: number }
    >();
    const [extraTipValue, setExtraTipValue] = React.useState<number>();

    const dispatch = useDispatch();
    const { push } = useLocalHistory();
    const history = useHistory<IOperationHistoryParams>();
    const { t } = useTranslation();
    const { isGuest, card, user } = useAuth();
    const [scenario] = useRouteScenario();

    React.useEffect(() => {
        if (settings && !provider) {
            paymentProviderFactory.create(settings).then(paymentProvider => {
                setProvider(paymentProvider);
            });
        }
        return () => {
            if (provider && provider.reset) {
                provider.reset();
            }
        };
    }, [provider, provider?.reset, settings]);

    const checkOperationStatus = React.useCallback(async () => {
        setChangedPriceOrder(undefined);
        setPayInstead(undefined);
        if (!provider) {
            return;
        }
        setLoading(true);
        try {
            const operation = await orderApi.getOperation(operationId);
            if (!isDefined(operation?.status)) {
                throw new Error(t('DIALOG_ORDER_STATUS_UNKNOWN'));
            }
            orderApi.nonceHandlingByOperationStatus(operation.status, [
                EOrderNonceKey.CREATE_ORDER,
                EOrderNonceKey.UPDATE_ORDER
            ]);

            switch (operation.status) {
                case OperationStatus.PENDING:
                    // Recursive Poll for order completion
                    setTimeout(() => {
                        checkOperationStatus();
                    }, OPERATION_POLLING_DELAY);
                    break;
                case OperationStatus.ERROR:
                case OperationStatus.TIMEOUT:
                    if (operation.isRetryable) {
                        setShowRetry(true);
                    }
                    if (
                        operation.errorCode === 'E-ORD-0354' &&
                        !!operation.errorData &&
                        (!!history.location.state?.orderBodyToRecreate ||
                            history.location.state?.orderBodyToRecreatePat) &&
                        !!settings
                    ) {
                        setError(OperationErrorType.THREEDSECURE_AUTH_REQUIRED);
                        setLoading(false);
                        const { orderBodyToRecreate } = history.location.state;
                        const { orderBodyToRecreatePat } = history.location.state;
                        try {
                            if (!!orderBodyToRecreate) {
                                const orderBody = await provider.threeDSecureAuthenticate(
                                    operation.errorData,
                                    orderBodyToRecreate
                                );
                                if (orderBody !== null) {
                                    const operationResponse = await orderApi.createOrder(orderBody);

                                    if (isDefined(operationResponse.id)) {
                                        push(ROUTES.QUICKPAY.STATUS, {
                                            operationId: operationResponse.id.toString()
                                        });
                                        // We do not want to show the previous operation's error
                                        return;
                                    }
                                } else {
                                    throw new Error('Error while submitting payment');
                                }
                            } else if (!!orderBodyToRecreatePat && isDefined(orderBodyToRecreatePat.id)) {
                                const { id, ...orderToRecreate } = orderBodyToRecreatePat;
                                const orderBody = await provider.threeDSecureAuthenticate(
                                    operation.errorData,
                                    orderToRecreate
                                );

                                if (orderBody !== null) {
                                    const operationResponse = await orderApi.updateOrder(id, orderBody);

                                    if (isDefined(operationResponse.id)) {
                                        push(ROUTES.QUICKPAY.STATUS, {
                                            operationId: operationResponse.id.toString()
                                        });
                                        // We do not want to show the previous operation's error
                                        return;
                                    }
                                } else {
                                    throw new Error('Error while submitting payment');
                                }
                            }
                        } catch (e) {
                            logger.error(e);
                            enqueueSnackbar(t('CHECKOUT_ERROR_PLACING_ORDER'), {
                                variant: 'error'
                            });
                        }
                    }
                    // Overpayment
                    if (
                        operation.errorCode === 'E-ORD-0298' &&
                        !!history.location.state.orderBodyToRecreatePat &&
                        !!settings
                    ) {
                        const orderBody = history.location.state.orderBodyToRecreatePat;
                        const [oldPayment] = orderBody.payments.filter(
                            item => item.type !== OrderPaymentType.GIFT_CARD
                        );
                        const filteredPayments = orderBody.payments.filter(
                            item => item.type === OrderPaymentType.GIFT_CARD
                        );
                        if (!!oldPayment) {
                            const filteredAdjustments =
                                orderBody.adjustments?.filter(
                                    item => item.type !== OrderAdjustmentType.TIP
                                ) ?? [];
                            const { errorData } = operation;
                            if (
                                !errorData ||
                                !isDefined(errorData.balanceBefore) ||
                                !isDefined(errorData.paymentError)
                            ) {
                                throw new Error(operation.errorMessage);
                            }
                            const oldTip = orderBody.adjustments?.find(
                                item => item.type === OrderAdjustmentType.TIP
                            );
                            const payInsteadValue = errorData.balanceBefore + (oldTip?.value ?? 0);
                            const extraTip = (oldPayment.amount ?? 0) - payInsteadValue;
                            filteredPayments.push({
                                ...oldPayment,
                                amount: payInsteadValue
                            });
                            filteredAdjustments.push({
                                type: OrderAdjustmentType.TIP,
                                value: (oldTip?.value ?? 0) + extraTip
                            });
                            const orderAlternative: IOrderCreateResourceV8 & { id: number } = {
                                ...orderBody,
                                payments: [...filteredPayments]
                            };
                            const orderExtraTipAlternative: IOrderCreateResourceV8 & { id: number } = {
                                ...orderBody,
                                adjustments: filteredAdjustments
                            };
                            setPayInstead(payInsteadValue);
                            setChangedPriceOrder(orderAlternative);
                            setExtraTipValue(extraTip);
                            setChangedPriceOrderExtraTip(orderExtraTipAlternative);
                            setError(OperationErrorType.GENERIC);
                            setErrorMessage(
                                t('PAT_QUICKPAY_ERROR_OVERPAYMENT', {
                                    amount: getCurrencyString(payInsteadValue)
                                })
                            );
                            return setLoading(false);
                        }
                    }
                    if (!operation.errorCode || !SPECIAL_ERROR_CODES.includes(operation.errorCode)) {
                        throw new Error(operation.errorMessage || 'Operation error or timeout');
                    }
                    if (operation.errorCode === OrderErrorCodes.UPDATING_THE_BILL) {
                        throw new Error(operation.errorMessage);
                    }
                // eslint-disable-next-line no-fallthrough
                case OperationStatus.DONE: {
                    const orderId = operation.resultId ?? null;
                    if (!orderId) {
                        throw new Error('No order id supplied in operation');
                    }
                    if (scenario === OrderScenario.PREORDER && !!timeslots && !!locationId) {
                        loadTimeslots(locationId, undefined)(dispatch);
                    }
                    resetBasket(dispatch);
                    if (isGuest) {
                        addGuestOrder(orderId.toString());
                        if (card) {
                            await userApi.removeCard(card._id);
                            if (user?.id) {
                                await getUserCard(user.id)(dispatch);
                            }
                        }
                    }
                    // Redirect
                    push(ROUTES.QUICKPAY.SUMMARY, { orderId: orderId.toString() });
                }
            }
        } catch (orderStatusError) {
            if (isDefined(orderStatusError?.response) && orderStatusError.response?.status === 404) {
                setError(OperationErrorType.NOT_FOUND);
                setErrorMessage('Order not found');
            } else {
                setError(OperationErrorType.GENERIC);
                setErrorMessage(orderStatusError);
                if (!isDefined(orderStatusError)) {
                    setErrorMessage(t('DIALOG_ORDER_STATUS_UNKNOWN'));
                    setShowRetry(true);
                }
            }
            setLoading(false);
        }
    }, [
        card,
        dispatch,
        enqueueSnackbar,
        getCurrencyString,
        history.location.state,
        isGuest,
        locationId,
        operationId,
        provider,
        push,
        scenario,
        settings,
        t,
        timeslots,
        user?.id
    ]);
    React.useEffect(() => {
        checkOperationStatus();
    }, [checkOperationStatus]);

    const handleRetry = React.useCallback(() => {
        if (lastRequest) {
            setError(OperationErrorType.NONE);
            setErrorMessage('');
            lastRequest();
            checkOperationStatus();
        }
    }, [checkOperationStatus, lastRequest]);

    const handleCancel = React.useCallback(() => {
        if (scenario === OrderScenario.ORDER_TO_TABLE || scenario === OrderScenario.PREORDER) {
            orderApi.resetNonceByKey(EOrderNonceKey.CREATE_ORDER);
        } else if (scenario === OrderScenario.TABLE) {
            orderApi.resetNonceByKey(EOrderNonceKey.UPDATE_ORDER);
        }

        if (scenario === OrderScenario.PREORDER) {
            push(ROUTES.BASE);
        } else {
            push(ROUTES.JOURNEY.LANDING);
        }

        resetBasket(dispatch);
    }, [dispatch, push, scenario]);

    return {
        provider,
        handleRetry,
        handleCancel,
        loading,
        error,
        errorMessage,
        showRetry,
        changedPriceOrder,
        payInstead,
        changedPriceOrderExtraTip,
        extraTipValue
    };
}
