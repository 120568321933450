import { EPaymentProvider } from 'components/settings/model/Settings';
import { SettingsStateSettings } from 'store/settings/settingsReducer';
import { loadGooglePay } from './loadGooglePay';

function isGooglePayLoaded(): boolean {
    return 'google' in (window || global) && !!google?.payments?.api?.PaymentsClient;
}

export const isGooglePayAvailable = async (settings?: SettingsStateSettings) => {
    if (!settings) {
        return false;
    }
    let isProduction = false;
    switch (settings?.paymentProvider) {
        case EPaymentProvider.JUDOPAY:
            isProduction = !!settings?.judopay?.isProduction;
            break;
        case EPaymentProvider.WORLDPAY:
            isProduction = !!settings?.worldpay?.isProduction;
            break;
        case EPaymentProvider.STRIPE:
            isProduction = !!settings?.stripe?.isProduction;
            break;
        case EPaymentProvider.SQUARE:
            isProduction = !!settings?.square?.isProduction;
            break;
        default:
            break;
    }

    const environment = isProduction ? 'PRODUCTION' : 'TEST';
    const baseRequest = {
        apiVersion: 2,
        apiVersionMinor: 0
    };
    const allowedCardNetworks = [];
    if (settings?.googlePay.supportedNetworksMastercard) {
        allowedCardNetworks.push('MASTERCARD');
    }
    if (settings?.googlePay.supportedNetworksVisa) {
        allowedCardNetworks.push('VISA');
    }
    if (settings?.googlePay.supportedNetworksAmex) {
        allowedCardNetworks.push('AMEX');
    }
    const baseCardPaymentMethod = {
        type: 'CARD',
        parameters: {
            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
            allowedCardNetworks
        }
    };
    const isReadyToPayRequest: any = Object.assign({}, baseRequest);
    isReadyToPayRequest.allowedPaymentMethods = [baseCardPaymentMethod];
    try {
        if (!isGooglePayLoaded()) {
            await loadGooglePay();
        }
        const paymentsClient = new google.payments.api.PaymentsClient({ environment });
        await paymentsClient.isReadyToPay(isReadyToPayRequest);
        return true;
    } catch {
        return false;
    }
};
