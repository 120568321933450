import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    Box,
    Button,
    createStyles,
    IconButton,
    makeStyles,
    Paper,
    Theme,
    Typography
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Skeleton } from '@material-ui/lab';
import random from 'lodash/random';
import moment from 'moment';
import { useResponsive } from 'src/hooks/useResponsive';
import { IAction } from 'components/activity/models';
import { ITaxLine } from 'components/basket/model/Basket';
import { ViewBillOrderItem } from 'components/bill/ui/ViewBill/ViewBillOrderItem';
import { locationApi } from 'components/location/locationApi';
import { IOrderReadResourceV12, OrderScenario } from 'components/order/model/Order';
import { BottomDialog } from 'lib/BottomDialog';
import { getCurrencyString } from 'lib/helpers';
import { LoadingImage } from 'lib/LoadingImage';
import logger from 'lib/logger';
import { Location } from '../../../location/model/Location';
import { orderApi } from 'components/order/orderApi';
import { useSnackbar } from 'notistack';

interface IProps {
    action: IAction | null;
    isOpen: boolean;
    onClose: () => void;
    openResendReceipt: (actionId: string | undefined) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: (props: { isDesktop: boolean }) => ({
            ...(!props.isDesktop ? { backgroundColor: theme.palette.primary.contrastText } : {})
        }),
        appBarTitle: (props: { isDesktop: boolean }) => ({
            padding: theme.spacing(1.5, 0),
            textAlign: 'center',
            ...(!props.isDesktop ? { width: `calc(100% - ${theme.spacing(12)}px)` } : { width: '100%' }),
            fontFamily: `TenantFont, ${theme.typography.fontFamily}`,
            color: theme.palette.titleTextColour
        }),
        wrapper: (props: { isDesktop: boolean }) => ({
            ...(!props.isDesktop ? { padding: theme.spacing(1) } : { padding: theme.spacing(0, 1, 1, 1) })
        }),
        cardImage: {
            marginBottom: theme.spacing(2),
            overflow: 'hidden'
        },
        cardOrderDetails: {
            padding: theme.spacing(1, 1.5),
            marginBottom: theme.spacing(2)
        },
        cardPayments: {
            padding: theme.spacing(1, 1.5)
        },
        title: {
            fontFamily: `TenantFont, ${theme.typography.fontFamily}`,
            color: theme.palette.titleTextColour
        },
        bold: {
            fontWeight: 'bold'
        },
        buttonBox: {
            marginTop: 'auto',
            padding: theme.spacing(1)
        },
        button: { height: theme.spacing(6), fontSize: '1.2em' },
        dialog: {
            padding: 0,
            width: '100%'
        }
    })
);

export const OrderDetails: React.FC<IProps> = ({ isOpen, onClose, action, openResendReceipt }) => {
    const { t } = useTranslation();
    const { isDesktop } = useResponsive();
    const classes = useStyles({ isDesktop });
    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();
    const [location, setLocation] = React.useState<Location | null>(null);
    const [order, setOrder] = React.useState<IOrderReadResourceV12 | null>(null);
    const [orderLoading, setOrderLoading] = React.useState(false);
    const [locationLoading, setLocationLoading] = React.useState(false);

    React.useEffect(() => {
        async function getOrder() {
            setOrderLoading(true);
            try {
                const result = await orderApi.getOrder(action?.metadata.order.id);
                setOrder(result);
            } catch (e) {
                logger.error(e);
                enqueueSnackbar(t('ORDER_DETAILS_REQUEST_ERROR'), { variant: 'error' });
            } finally {
                setOrderLoading(false);
            }
        }
        getOrder();
    }, [action?.metadata.order.id, dispatch, enqueueSnackbar, t]);

    React.useEffect(() => {
        async function getLocation() {
            setLocationLoading(true);
            try {
                if (action?.context?.location) {
                    const locationResult = await locationApi.getLocation(action?.context?.location._id);
                    setLocation(locationResult);
                }
            } catch (e) {
                logger.error(e);
            } finally {
                setLocationLoading(false);
            }
        }
        getLocation();
    }, [action?.context?.location, action?.context?.location?._id]);

    const renderItems = React.useCallback(
        item => (order ? <ViewBillOrderItem orderItem={item} allItems={order.items} key={item.id} /> : null),
        [order]
    );

    const renderItemsSkeleton = React.useCallback(
        (_, index) => (
            <Box key={index} display="flex" width="100%">
                <Skeleton height={24} width="10%" />
                <Box display="flex" width="100%" justifyContent="space-between" paddingLeft={2}>
                    <Skeleton height={24} width={random(100, 200)} />
                    <Skeleton height={24} width={random(40, 60)} />
                </Box>
            </Box>
        ),
        []
    );

    const renderPaymentsSkeleton = React.useCallback(
        (_, index) => (
            <Box key={index} display="flex" justifyContent="space-between" width="100%">
                <Skeleton height={24} width={random(100, 200)} />
                <Skeleton height={24} width={random(50, 70)} />
            </Box>
        ),
        []
    );

    const handleClickOpenResendReceipt = React.useCallback(
        () => openResendReceipt(action?._id),
        [action?._id, openResendReceipt]
    );

    const processTaxes = React.useCallback(
        (taxes: ITaxLine[]) =>
            taxes.reduce((acc: number, item: ITaxLine): number => {
                if (!item.inclusive) {
                    return (acc += item.amount);
                }
                return acc;
            }, 0),
        []
    );

    const orderData = React.useMemo(
        () => moment(action?.when.timestamp).format('dddd Do MMMM YYYY [at] hh:mm A'),
        [action?.when?.timestamp]
    );

    const totalSum = React.useMemo(() => {
        if (order) {
            return order.total.payments !== 0
                ? getCurrencyString(order.total.payments, order.currencyCode)
                : getCurrencyString(order.total.total, order.currencyCode);
        }
    }, [order]);

    return (
        <BottomDialog open={isOpen} onClose={onClose} paperClassName={classes.dialog} fullScreen={!isDesktop}>
            <Box display="flex" alignItems="center" className={classes.appBar}>
                {!isDesktop && (
                    <IconButton onClick={onClose}>
                        <ArrowBackIcon />
                    </IconButton>
                )}

                <Typography className={classes.appBarTitle}>
                    {t('ORDER_TITLE') + action?.metadata.order.id}
                </Typography>
            </Box>
            <Box className={classes.wrapper}>
                <Box width="100%">
                    {location && !locationLoading && (
                        <Paper className={classes.cardImage} variant="outlined">
                            {location && location?.mainImageUrl && (
                                <LoadingImage src={location.mainImageUrl} height={22.5} />
                            )}
                            <Box padding={1.5}>
                                <Typography className={classes.bold} variant="body1" align="center">
                                    {location.title}
                                </Typography>
                                <Typography variant="body1" align="center">
                                    {orderData}
                                </Typography>
                            </Box>
                        </Paper>
                    )}

                    {order && !orderLoading && (
                        <>
                            <Paper className={classes.cardOrderDetails} variant="outlined">
                                <Box marginBottom={1}>
                                    <Typography className={classes.title} variant="h6" align="center">
                                        {t('ORDER_STATUS_ORDER_DETAILS')}
                                    </Typography>
                                </Box>
                                {order?.items && order.items.map(renderItems)}
                            </Paper>

                            <Paper className={classes.cardPayments} variant="outlined">
                                <Box marginBottom={1}>
                                    <Typography className={classes.title} variant="h6" align="center">
                                        {t('ONBOARDING_PAYMENTS_TITLE')}
                                    </Typography>
                                </Box>

                                {order.total.discounts !== 0 && (
                                    <Box
                                        marginBottom={1}
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography>{t('PAYMENT_ITEM_REWARDS')}</Typography>
                                        <Typography>
                                            {getCurrencyString(order.total.discounts, order.currencyCode)}
                                        </Typography>
                                    </Box>
                                )}

                                {order.total.charges > 0 && (
                                    <Box
                                        marginBottom={1}
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography>{t('PAYMENT_ITEM_CHARGES')}</Typography>
                                        <Typography>
                                            {getCurrencyString(order.total.charges, order.currencyCode)}
                                        </Typography>
                                    </Box>
                                )}

                                {order.total.tips > 0 && (
                                    <Box
                                        marginBottom={1}
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography>{t('PAYMENT_ITEM_TIP')}</Typography>
                                        <Typography>
                                            {getCurrencyString(order.total.tips, order.currencyCode)}
                                        </Typography>
                                    </Box>
                                )}

                                {order.total.taxes &&
                                    order.total.taxes.length > 0 &&
                                    processTaxes(order.total.taxes) > 0 && (
                                        <Box
                                            marginBottom={1}
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Typography>{t('PAYMENT_ITEM_VAT_INCLUDED')}</Typography>
                                            <Typography>
                                                {getCurrencyString(
                                                    processTaxes(order.total.taxes),
                                                    order.currencyCode
                                                )}
                                            </Typography>
                                        </Box>
                                    )}

                                <Box
                                    marginBottom={1}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Typography>{t('CHECKOUT_TOTAL')}</Typography>
                                    <Typography>{totalSum}</Typography>
                                </Box>
                            </Paper>
                        </>
                    )}

                    {locationLoading && (
                        <Paper className={classes.cardImage} variant="outlined">
                            <Skeleton width="100%" height={180} variant="rect" />
                            <Box padding={1.5} display="flex" alignItems="center" flexDirection="column">
                                <Skeleton height={26} width="50%" />
                                <Skeleton height={24} width="75%" />
                            </Box>
                        </Paper>
                    )}

                    {orderLoading && (
                        <>
                            <Paper className={classes.cardOrderDetails} variant="outlined">
                                <Box marginBottom={1}>
                                    <Typography className={classes.title} variant="h6" align="center">
                                        {t('ORDER_STATUS_ORDER_DETAILS')}
                                    </Typography>
                                </Box>
                                {Array(4).fill('').map(renderItemsSkeleton)}
                            </Paper>

                            <Paper className={classes.cardPayments} variant="outlined">
                                <Box marginBottom={1}>
                                    <Typography className={classes.title} variant="h6" align="center">
                                        {t('ONBOARDING_PAYMENTS_TITLE')}
                                    </Typography>
                                </Box>
                                {Array(3).fill('').map(renderPaymentsSkeleton)}
                            </Paper>
                        </>
                    )}
                </Box>
            </Box>
            {order?.scenario !== OrderScenario.PAYATPOS && (
                <Box className={classes.buttonBox}>
                    <Button
                        className={classes.button}
                        color="primary"
                        variant="contained"
                        fullWidth
                        disabled={orderLoading}
                        onClick={handleClickOpenResendReceipt}
                    >
                        {'Resend Receipt'}
                    </Button>
                </Box>
            )}
        </BottomDialog>
    );
};
