import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import * as Yup from 'yup';
import { MerchantTitle } from 'app/MerchantTitle';
import { OrderScenario } from 'components/order/model/Order';
import { getLocalMerchantId } from 'components/settings/localStore';
import { getOnCloseRedirectUrl, getUserId } from 'components/user/localAuth';
import { User } from 'components/user/model/User';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { TextFormField } from 'lib/form/TextFormField';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { activate, clearAuthErrors } from 'store/auth/authActions';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/LoadingButton';
import { ROUTES } from './routes';

interface ActivateFormData {
    code: string;
}

const initialFields: ActivateFormData = {
    code: ''
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            paddingTop: theme.spacing(4)
        },
        button: {
            height: theme.spacing(6),
            fontSize: '1.2em'
        }
    })
);

const isLoading = false;

export const ActivatePage: React.FC = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { getParsedPath, push } = useLocalHistory();
    const { id, user, activateError } = useSelector((state: ApplicationState) => state.auth);
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { locationId } = useSelector((state: ApplicationState) => state.basket);
    const [scenario] = useRouteScenario();
    const { isGuest, isLoggedIn } = useAuth();
    const { state: locationState } = useLocation<{ user: User }>();
    const signinValidation = React.useMemo(
        () =>
            Yup.object<ActivateFormData>().shape({
                code: Yup.string().required(t('FORMS_VALIDATION_REQUIRED'))
            }),
        [t]
    );
    const userId = React.useMemo(() => id || getUserId(), [id]);
    const handleSubmit = React.useCallback(
        (data: ActivateFormData) => activate(userId, String(data.code))(dispatch),
        [userId, dispatch]
    );
    const shouldEnterEmail = React.useMemo(
        () => !!settings && settings.emailAuthEnabled && settings.phoneAuthEnabled,
        [settings]
    );
    React.useEffect(() => {
        if (isLoggedIn && !isGuest) {
            const merchantId = getLocalMerchantId();
            if (merchantId) {
                if (shouldEnterEmail) {
                    push(getParsedPath(ROUTES.USER.EMAIL_CAPTURE, { merchantId }));
                } else {
                    const returnUrl = getOnCloseRedirectUrl(merchantId);
                    if (returnUrl) {
                        const [path, search] = returnUrl.split('?');
                        push(path, {}, search);
                    } else if (scenario === OrderScenario.TABLE) {
                        push(ROUTES.JOURNEY.LANDING, { merchantId, locationId });
                    } else {
                        push(ROUTES.BASE);
                    }
                }
            }
        }
    }, [user, push, isGuest, isLoggedIn, getParsedPath, scenario, locationId, shouldEnterEmail]);
    React.useEffect(() => () => clearAuthErrors(dispatch), [dispatch]);

    const handleBack = React.useCallback(() => {
        if (locationState && locationState.user) {
            push(ROUTES.USER.REGISTER, {}, '', { user: locationState.user, identity: userId });
        }
    }, [locationState, push, userId]);

    if (!userId) {
        return <Redirect to={getParsedPath(ROUTES.USER.LOGIN)} />;
    }

    return (
        <div>
            <MerchantTitle title={t('ONBOARDING_ACTIVATION_TITLE')} />
            <MuiAppBar title={t('ONBOARDING_ACTIVATION_TITLE')} onBack={handleBack} />
            <Formik initialValues={initialFields} validationSchema={signinValidation} onSubmit={handleSubmit}>
                {({ submitForm, isSubmitting, isValid, values: { code } }) => (
                    <Form className={classes.root}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>{t('ONBOARDING_ACTIVATION_MESSAGE')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextFormField
                                    name="code"
                                    type="number"
                                    label={t('ONBOARDING_ACTIVATION_CODE')}
                                />
                            </Grid>
                            {!!activateError && (
                                <Grid item xs={12}>
                                    <Typography color="error">{activateError}</Typography>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    disabled={!isValid || isLoading || !code || isSubmitting}
                                    fullWidth
                                    onClick={submitForm}
                                    className={classes.button}
                                    loading={isSubmitting}
                                >
                                    {t('BUTTON_SUBMIT')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
