import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
    Box,
    createStyles,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    Theme,
    Tooltip,
    Typography
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { AxiosResponse } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import moment from 'moment';
import { useQuery } from 'src/hooks/useQuery';
import * as Yup from 'yup';
import { MerchantTitle } from 'app/MerchantTitle';
import { getIdentityLabel } from 'components/settings/model/Settings';
import { AgeVerification } from 'components/user/ageVerification';
import {
    clearSignupDataFromSession,
    getSignupDataFromSession,
    setSignupDataToSession
} from 'components/user/localAuth';
import { getIdentityValidationScheme, SignUpDOBData, SignUpFormData, User } from 'components/user/model/User';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { CheckboxFormField } from 'lib/form/CheckboxFormField';
import { PasswordFormField } from 'lib/form/PasswordFormField';
import { TextFormField } from 'lib/form/TextFormField';
import { Link } from 'lib/Link';
import logger from 'lib/logger';
import { isDefined } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { clearAuthErrors, register } from 'store/auth/authActions';
import { resetGuestState } from 'store/guest/guestActions';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/LoadingButton';
import { ROUTES } from './routes';
import { GoogleSSO } from 'components/user/GoogleSSO';
import { AuthActionTypes } from 'store/auth/authActionTypes';
import { AppleSSO } from 'components/user/AppleSSO';
import { TermsAndConditions } from 'components/user/TermsAndCondition';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2)
        },
        button: {
            height: theme.spacing(6),
            fontSize: '1.2em'
        },
        forgot: {
            margin: theme.spacing(0, 1.75),
            marginTop: theme.spacing(1),
            display: 'block'
        },
        orContainer: {
            position: 'relative',
            padding: theme.spacing(0, 5),
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2)
        },
        or: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(1, 3),
            color: theme.palette.divider
        }
    })
);

const birthDateInitialValues: SignUpDOBData = {
    birthDate: ''
};

export const SignUpPage: React.FC = () => {
    const { t } = useTranslation();
    const { state: locationState } = useLocation<{ user: User; identity: string }>();
    const classes = useStyles();
    const dispatch = useDispatch();
    const paramIdentity = useQuery('identity');
    const paramFirstName = useQuery('firstName');
    const paramLastName = useQuery('lastName');
    const { getParsedPath, push } = useLocalHistory();
    const [isAgePageOpen, setAgePageOpen] = React.useState(false);
    const [signUpData, setSignUpData] = React.useState<SignUpFormData>();
    const [SSOInProgress, setSSOInProgress] = React.useState(false);
    const { merchantId } = useParams<{ merchantId: string }>();
    const { registerError, returnUrl } = useSelector((state: ApplicationState) => state.auth);
    const { isLoggedIn } = useAuth();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const initialFields: SignUpFormData = React.useMemo(() => {
        if (!!signUpData) {
            return {
                identity: (paramIdentity || signUpData.identity) ?? '',
                firstName: (paramFirstName || signUpData.firstName) ?? '',
                lastName: (paramLastName || signUpData.lastName) ?? '',
                password: signUpData.password ?? '',
                confirmPassword: signUpData.confirmPassword ?? '',
                hasAgreedToShareData: signUpData.hasAgreedToShareData ?? false,
                hasAgreedToReceiveMarketing: signUpData.hasAgreedToReceiveMarketing ?? false
            };
        }
        return {
            identity: paramIdentity || locationState?.identity || '',
            firstName: paramFirstName || locationState?.user?.firstName || '',
            lastName: paramLastName || locationState?.user?.lastName || '',
            password: '',
            confirmPassword: '',
            hasAgreedToShareData: false || locationState?.user?.hasAgreedToShareData,
            hasAgreedToReceiveMarketing: false || locationState?.user?.hasAgreedToReceiveMarketing
        };
    }, [
        signUpData,
        paramIdentity,
        locationState?.identity,
        locationState?.user?.firstName,
        locationState?.user?.lastName,
        locationState?.user?.hasAgreedToShareData,
        locationState?.user?.hasAgreedToReceiveMarketing,
        paramFirstName,
        paramLastName
    ]);
    const shouldApplyAgeRequest = React.useMemo(
        () =>
            !!settings &&
            !!settings.app &&
            settings.app.ageRequestEnabled &&
            isDefined(settings.app.registrationMinimumAge),
        [settings]
    );
    const identityLabel = React.useMemo(
        () => getIdentityLabel(settings?.emailAuthEnabled, settings?.phoneAuthEnabled, true),
        [settings?.emailAuthEnabled, settings?.phoneAuthEnabled]
    );
    const signupValidation = React.useMemo(
        () =>
            Yup.object<SignUpFormData>().shape({
                identity: getIdentityValidationScheme(identityLabel, settings?.region.phoneNumberCode, t),
                firstName: Yup.string().required(t('FORMS_VALIDATION_REQUIRED')),
                lastName: Yup.string().required(t('FORMS_VALIDATION_REQUIRED')),
                password: Yup.string()
                    .min(8, t('FORMS_VALIDATION_MIN', { count: 8 }))
                    .required(t('FORMS_VALIDATION_REQUIRED')),
                confirmPassword: Yup.string()
                    .oneOf([Yup.ref('password')], t('ERROR_SIGN_UP_REPEAT_PASSWORD'))
                    .required(t('FORMS_VALIDATION_REQUIRED')),
                hasAgreedToShareData: Yup.boolean()
                    .required(t('FORMS_VALIDATION_REQUIRED'))
                    .equals([true], t('FORMS_VALIDATION_REQUIRED'))
            }),
        [identityLabel, t, settings?.region.phoneNumberCode]
    );

    const birthDateValidation = React.useMemo(
        () =>
            Yup.object<SignUpDOBData>().shape({
                birthDate: Yup.string()
                    .required(t('FORMS_VALIDATION_REQUIRED'))
                    .test(
                        'isAgeValid',
                        t('ONBOARDING_DOB_MESSAGE'),
                        (val: string) =>
                            !(
                                !!settings &&
                                !!settings.app &&
                                moment(val)
                                    .add(settings.app.registrationMinimumAge, 'years')
                                    .isAfter(moment(new Date()))
                            )
                    )
            }),
        [settings, t]
    );

    const handleKeyPress = React.useCallback(
        (submit: () => Promise<void>) => (e: React.KeyboardEvent) => {
            if (e.keyCode === 13) {
                submit();
            }
        },
        []
    );
    const handleSubmit = React.useCallback(
        (data: SignUpFormData, { setSubmitting }: FormikHelpers<SignUpFormData>) => {
            if (shouldApplyAgeRequest) {
                const { firstName, lastName, identity, hasAgreedToShareData, hasAgreedToReceiveMarketing } =
                    data;
                setSignUpData(data);
                setSignupDataToSession(merchantId, {
                    firstName,
                    lastName,
                    identity,
                    hasAgreedToShareData,
                    hasAgreedToReceiveMarketing
                });
                setAgePageOpen(true);
                setSubmitting(false);
                return;
            }
            clearSignupDataFromSession(merchantId);
            register(data)(dispatch)
                .then(res => {
                    push(ROUTES.USER.ACTIVATE, {}, '', { user: res });
                })
                .catch(err => {
                    logger.warn(err);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
        [dispatch, merchantId, push, shouldApplyAgeRequest]
    );
    const handleDOBSubmit = React.useCallback(
        (dobValues: SignUpDOBData, helpers: FormikHelpers<SignUpDOBData>) => {
            if (!!signUpData) {
                clearSignupDataFromSession(merchantId);
                register({ ...signUpData, birthdate: dobValues.birthDate })(dispatch)
                    .then(res => {
                        push(ROUTES.USER.ACTIVATE, {}, '', { user: res });
                    })
                    .catch(err => {
                        logger.warn(err);
                    })
                    .finally(() => {
                        helpers.setSubmitting(false);
                    });
            }
        },
        [dispatch, merchantId, push, signUpData]
    );

    const handleSSOSuccess = React.useCallback(() => {
        setSSOInProgress(false);
        clearSignupDataFromSession(merchantId);
    }, [merchantId]);

    const handleSSOError = React.useCallback(
        (e: AxiosResponse<{ message: string }>) => {
            setSSOInProgress(false);
            dispatch({
                type: AuthActionTypes.REGISTER_ERROR,
                error: e.data.message
            });
        },
        [dispatch]
    );

    const handleSSOStart = React.useCallback(() => {
        setSSOInProgress(true);
    }, []);

    React.useEffect(() => {
        if (isLoggedIn && !SSOInProgress) {
            if (returnUrl) {
                const [path, search] = returnUrl.split('?');
                push(path, {}, search);
            } else {
                push(ROUTES.BASE);
            }
        }
    }, [SSOInProgress, isLoggedIn, push, returnUrl]);

    React.useEffect(() => {
        resetGuestState(dispatch);
        return () => clearAuthErrors(dispatch);
    }, [dispatch]);
    React.useEffect(() => {
        const signupDataFromSessionAsString = getSignupDataFromSession(merchantId);
        if (!!signupDataFromSessionAsString) {
            const { firstName, lastName, identity, hasAgreedToShareData, hasAgreedToReceiveMarketing } =
                JSON.parse(signupDataFromSessionAsString);
            setSignUpData(oldData => ({
                firstName,
                lastName,
                identity,
                hasAgreedToShareData,
                hasAgreedToReceiveMarketing,
                password: oldData?.password ?? '',
                confirmPassword: oldData?.confirmPassword ?? ''
            }));
        }
    }, [merchantId]);

    const handleMouseDownIdentity = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    }, []);

    const handleClearIdentity = React.useCallback(
        (setFieldValue: FormikHelpers<any>['setFieldValue']) => () => {
            setFieldValue('identity', '');
        },
        []
    );
    const handleBackFromAgeVerification = React.useCallback(() => {
        setAgePageOpen(false);
    }, []);
    return (
        <div>
            {isAgePageOpen ? (
                <AgeVerification
                    error={registerError}
                    onBack={handleBackFromAgeVerification}
                    initialValues={birthDateInitialValues}
                    birthDateValidation={birthDateValidation}
                    onSubmit={handleDOBSubmit}
                />
            ) : (
                <>
                    <MerchantTitle title={t('ONBOARDING_SIGN_UP')} />
                    <MuiAppBar title={t('ONBOARDING_SIGN_UP')} disabledMenu icon={Close} />
                    <Formik
                        initialValues={initialFields}
                        validationSchema={signupValidation}
                        onSubmit={handleSubmit}
                        enableReinitialize
                    >
                        {({
                            submitForm,
                            setFieldValue,
                            isSubmitting,
                            isValid,
                            values: { hasAgreedToShareData }
                        }) => (
                            <Form className={classes.root}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography align="center">
                                            {t('AUTH_ALREADY_HAVE_ACCOUNT') + ' '}
                                            <Link to={getParsedPath(ROUTES.USER.LOGIN)}>
                                                {t('ONBOARDING_SIGN_IN')}
                                            </Link>
                                        </Typography>
                                    </Grid>
                                    <GoogleSSO
                                        onSignInError={handleSSOError}
                                        onSignInSuccess={handleSSOSuccess}
                                        onStart={handleSSOStart}
                                    />
                                    <AppleSSO
                                        onSignInError={handleSSOError}
                                        onSignInSuccess={handleSSOSuccess}
                                    />
                                    {(settings?.appleSSOEnabled || settings?.googleSSOEnabled) && (
                                        <Grid item xs={12}>
                                            <Box className={classes.orContainer}>
                                                <Divider />
                                                <Typography className={classes.or}>OR</Typography>
                                            </Box>
                                        </Grid>
                                    )}
                                    <Grid item xs={6}>
                                        <TextFormField
                                            name="firstName"
                                            label={t('ONBOARDING_SIGNUP_FIRSTNAME')}
                                            trimWhiteSpaces="sides"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextFormField
                                            name="lastName"
                                            label={t('ONBOARDING_SIGNUP_LASTNAME')}
                                            trimWhiteSpaces="sides"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextFormField
                                            name="identity"
                                            type={identityLabel}
                                            label={t(
                                                `ONBOARDING_SIGNUP_LABEL_${identityLabel.toUpperCase()}`
                                            )}
                                            trimWhiteSpaces="sides"
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton
                                                        onClick={handleClearIdentity(setFieldValue)}
                                                        onMouseDown={handleMouseDownIdentity}
                                                        size="small"
                                                        tabIndex={-1}
                                                    >
                                                        <Close />
                                                    </IconButton>
                                                ),
                                                autoComplete: 'email'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PasswordFormField
                                            name="password"
                                            label={t('ONBOARDING_SIGNIN_PASSWORD')}
                                            onKeyDown={handleKeyPress(submitForm)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PasswordFormField
                                            name="confirmPassword"
                                            label={t('SIGN_UP_REPEAT_PASSWORD')}
                                            onKeyDown={handleKeyPress(submitForm)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {(settings?.app?.registrationShowMarketingOptIn ?? true) && (
                                            <Box paddingLeft={2}>
                                                <CheckboxFormField
                                                    name="hasAgreedToReceiveMarketing"
                                                    label={t('GDPR_MARKETING_CHECKBOX_TITLE')}
                                                />
                                            </Box>
                                        )}
                                        <Box paddingLeft={2}>
                                            <CheckboxFormField
                                                name="hasAgreedToShareData"
                                                label={t('GDPR_DATA_CHECKBOX_TITLE')}
                                                color="primary"
                                            />
                                        </Box>
                                    </Grid>
                                    {!!registerError && (
                                        <Grid item xs={12}>
                                            <Typography color="error">{registerError}</Typography>
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        {!hasAgreedToShareData && (
                                            <Tooltip title={t('AUTH_FORM_SIGNUP_TIP') || ''}>
                                                <div>
                                                    <LoadingButton
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={true}
                                                        fullWidth
                                                        onClick={submitForm}
                                                        className={classes.button}
                                                        loading={isSubmitting}
                                                    >
                                                        {t('AUTH_FORM_SIGNUP')}
                                                    </LoadingButton>
                                                </div>
                                            </Tooltip>
                                        )}
                                        {hasAgreedToShareData && (
                                            <LoadingButton
                                                variant="contained"
                                                color="primary"
                                                disabled={!isValid || isSubmitting || SSOInProgress}
                                                fullWidth
                                                onClick={submitForm}
                                                className={classes.button}
                                                loading={isSubmitting || SSOInProgress}
                                            >
                                                {t('AUTH_FORM_SIGNUP')}
                                            </LoadingButton>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TermsAndConditions />
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </div>
    );
};
