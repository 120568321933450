import { IAdjustmentLocalResource } from 'components/order/model/Order';
import logger from 'lib/logger';

export interface ExistingSharedBasketData {
    selectedReward?: IAdjustmentLocalResource | null;
    giftCard?: string | undefined;
    userSelectedTipValue?: number;
    tipValue?: number;
}

const BASKET_SESSION_PREFIX = 'quickpay';

export const setBasketSession = (
    params: ExistingSharedBasketData,
    merchantId?: string,
    locationId?: string,
    checkId?: string,
    tableNumber?: string
) => {
    sessionStorage.setItem(
        `${BASKET_SESSION_PREFIX}-${merchantId}-${locationId}-${checkId}-${tableNumber}`,
        JSON.stringify(params)
    );
};

export const getBasketSessionString = (
    merchantId?: string,
    locationId?: string,
    checkId?: string,
    tableNumber?: string
) => {
    const storedString = sessionStorage.getItem(
        `${BASKET_SESSION_PREFIX}-${merchantId}-${locationId}-${checkId}-${tableNumber}`
    );
    return storedString;
};

export const getBasketSession = (
    merchantId?: string,
    locationId?: string,
    checkId?: string,
    tableNumber?: string
) => {
    let params: IAdjustmentLocalResource | undefined;
    try {
        const session = getBasketSessionString(merchantId, locationId, checkId, tableNumber);
        if (session) {
            const parsedSession = JSON.parse(session);
            if (parsedSession) {
                params = parsedSession;
            }
        }
    } catch (e) {
        logger.error(e);
    }
    return params;
};
