import * as React from 'react';

export interface ISummary {
    total: number;
    count: number;
    pages: number;
    page: number;
}

interface IPage {
    summary: ISummary;
}

interface IConfiguration {
    initialState?: ISummary | null;
    perPage: number;
}

interface IPagination {
    currentPage: number;
    nextPage: number;
    hasNextPage: boolean;
    isLoading: boolean;
}

export function usePagination<T>(configuration: IConfiguration) {
    const [summary, setSummary] = React.useState<ISummary>({
        total: 0,
        count: 0,
        pages: 1,
        page: 1,
        ...(configuration.initialState || {})
    });
    const [pagination, setPagination] = React.useState<IPagination>({
        currentPage: 1,
        hasNextPage: true,
        isLoading: false,
        nextPage: 2
    });

    React.useEffect(() => {
        if (configuration.initialState) {
            setPagination({
                hasNextPage: Boolean(configuration.initialState.page !== configuration.initialState.pages),
                nextPage: configuration.initialState.page + 1,
                currentPage: configuration.initialState.page,
                isLoading: false
            });
        }
    }, [configuration.initialState]);

    const getParams = React.useCallback(
        (paginationConfig: IPagination) => ({
            page: String(paginationConfig.nextPage),
            per_page: String(configuration.perPage),
            state: 'ACTIVE',
            services: 'pre_order'
        }),
        [configuration.perPage]
    );

    const loadMoreItems = React.useCallback(
        async (fn: (params: Record<string, string>) => Promise<IPage & T> | Promise<null>) => {
            if (!pagination.isLoading && pagination.hasNextPage) {
                const newPagination = {
                    ...pagination,
                    isLoading: true,
                    nextPage: pagination.currentPage + 1
                };
                setPagination(newPagination);
                try {
                    const data = await fn(getParams(newPagination));
                    if (data) {
                        setSummary(data.summary);
                        setPagination({
                            hasNextPage: Boolean(data.summary.page !== data.summary.pages),
                            nextPage: data.summary.page + 1,
                            currentPage: data.summary.page,
                            isLoading: false
                        });

                        return Promise.resolve(data);
                    }
                } catch (err) {
                    setPagination(p => ({ ...p, isLoading: false }));
                    return Promise.reject(err);
                }
            }
        },
        [getParams, pagination]
    );

    return {
        loadMoreItems,
        isLoading: pagination.isLoading,
        total: summary.total,
        hasNext: pagination.hasNextPage
    } as const;
}
