import React from 'react';
import {
    Box,
    createStyles,
    FormControl,
    FormControlLabel,
    FormLabel,
    makeStyles,
    Radio,
    RadioGroup
} from '@material-ui/core';

export interface Option {
    label: string | React.ReactNode;
    value: any;
    onClickValue?: any;
}

interface IMuiRadioGroupProps {
    label?: string;
    value: any;
    options: Option[];
    renderActionButton?: (option: Option) => JSX.Element | null;
    onChange: (value: string) => void;
    onClick?: (onClickValue: any) => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        radioLabel: {
            flex: '1'
        }
    })
);

export const MuiRadioGroup: React.FC<IMuiRadioGroupProps> = props => {
    const { label, value, options, onChange, renderActionButton, onClick } = props;
    const classes = useStyles();

    const handleChange = React.useCallback(
        (_event: any, newValue: string) => {
            onChange(newValue);
        },
        [onChange]
    );

    const handleClick = React.useCallback(
        (onClickValue: any) => () => onClick && onClick(onClickValue),
        [onClick]
    );
    const renderRadioButton = React.useCallback(
        (option: Option) =>
            renderActionButton ? (
                <Box display="flex" alignItems="center" justifyContent="space-between" key={option.value}>
                    <FormControlLabel
                        value={option.value}
                        control={<Radio />}
                        className={classes.radioLabel}
                        label={option.label}
                        onClick={handleClick(option.onClickValue)}
                    />
                    {renderActionButton(option)}
                </Box>
            ) : (
                <FormControlLabel
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                    key={option.value}
                    onClick={handleClick(option.onClickValue)}
                />
            ),
        [classes.radioLabel, handleClick, renderActionButton]
    );

    return (
        <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{label}</FormLabel>
            <RadioGroup name="mui-radio-group" value={value} onChange={handleChange}>
                {options.map(renderRadioButton)}
            </RadioGroup>
        </FormControl>
    );
};
