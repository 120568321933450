import * as React from 'react';
import { Route as ReactRoute, RouteComponentProps, RouteProps } from 'react-router-dom';
import { createStyles, makeStyles, Paper } from '@material-ui/core';
import { useResponsive } from 'src/hooks/useResponsive';

export interface IRouteProps extends RouteProps {
    useBackground?: boolean;
    fullHeight?: boolean;
}

const useStyles = makeStyles(() =>
    createStyles({
        wrapper: (props: { fullHeight: boolean }) => ({
            width: '100%',
            height: props.fullHeight ? '100%' : 'auto'
        })
    })
);

export const Route: React.FC<IRouteProps> = ({
    component,
    render,
    fullHeight = false,
    useBackground = false,
    ...props
}) => {
    const classes = useStyles({ fullHeight });
    const { isDesktop } = useResponsive();
    const renderer = React.useCallback(
        (routeProps: RouteComponentProps<any>) => {
            if (component) {
                const Component = component;
                return useBackground && isDesktop ? (
                    <Paper className={classes.wrapper}>
                        <Component {...routeProps} />
                    </Paper>
                ) : (
                    <Component {...routeProps} />
                );
            }
            if (render) {
                return useBackground && isDesktop ? (
                    <Paper className={classes.wrapper}>{render(routeProps)}</Paper>
                ) : (
                    render(routeProps)
                );
            }
        },
        [classes.wrapper, component, isDesktop, render, useBackground]
    );

    return <ReactRoute render={renderer} {...props} />;
};
