import { HttpClient } from 'components/http/HttpClient';
import { getLocalSettings } from 'components/settings/localStore';
import logger from 'lib/logger';
import { isLayoutData, LayoutData } from './model/Layout';

class LayoutApi extends HttpClient {
    constructor() {
        super({
            baseURL: process.env.CONTENT_SERVICE_URL,
            url: '/applayouts'
        });
    }

    public async getActive(params?: Record<string, string>): Promise<LayoutData> {
        const localSettings = getLocalSettings();
        if (!localSettings) {
            throw new Error('merchantId and x-application-id are missed');
        }

        const response = await this.getRequest<LayoutData>({
            url: '/active',
            configs: {
                params: {
                    tenantId: localSettings.merchantId,
                    ...params
                }
            },
            headerConfigs: {
                shouldIncludeAuthToken: false
            }
        });
        if (!isLayoutData(response)) {
            // Don't want to break the web app if we get odd data
            logger.warn(`Typechecking failed, expected Layout, but got ${String(response)}`, response);
        }
        return response;
    }
}

export const layoutApi = new LayoutApi();
