import React from 'react';
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { useId } from '@react-aria/utils';
import clsx from 'clsx';

const useStyles = makeStyles({
    selectRoot: {
        display: 'flex'
    }
});

export interface MuiSelectOption {
    label: React.ReactNode;
    value: string;
    disabled?: boolean;
}

interface MuiSelectProps {
    options: MuiSelectOption[];
    label?: string;
    defaultValue: MuiSelectOption['value'];
    onSelect: (value: MuiSelectOption['value']) => void;
    className?: string;
    defaultOpen?: boolean;
}

// TODO: Rename it to be ProductSelect, and move it to the menu folder
export const MuiSelect: React.FC<MuiSelectProps> = props => {
    const { options, label, defaultValue, onSelect, className, defaultOpen } = props;
    const [selectedValue, setSelectedValue] = React.useState(defaultValue);
    const [isOpen, setOpen] = React.useState(!!defaultOpen);
    const classes = useStyles();
    const labelId = useId();
    const renderSelectOption = React.useCallback(
        (option: MuiSelectOption) => (
            <MenuItem value={option.value} key={option.value} disabled={option.disabled}>
                {option.label}
            </MenuItem>
        ),
        []
    );

    const handleValueChanged = React.useCallback(
        (event: React.ChangeEvent<{ value: unknown }>) => {
            setSelectedValue(event.target.value as string);
            onSelect(event.target.value as string);
        },
        [onSelect]
    );

    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, []);
    const handleOpen = React.useCallback(() => {
        setOpen(true);
    }, []);

    React.useEffect(() => {
        if (defaultOpen) {
            setOpen(true);
        }
    }, [defaultOpen]);

    return (
        <FormControl className={clsx(classes.selectRoot, className)}>
            {!!label && <InputLabel id={labelId}>{label}</InputLabel>}
            <Select
                labelId={labelId}
                value={selectedValue}
                onChange={handleValueChanged}
                onClose={handleClose}
                onOpen={handleOpen}
                open={isOpen}
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    marginThreshold: 0
                }}
            >
                {options.map(renderSelectOption)}
            </Select>
        </FormControl>
    );
};
