import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { EDateFormat } from 'src/enums';
import { CheckboxFormField } from 'lib/form/CheckboxFormField';
import { TextFormField } from 'lib/form/TextFormField';
import { IUserInfoForm } from '../../models';
import { EFormFields } from './enums';

interface IProps {
    isBirthDateDisabled: boolean;
    credentials: {
        id: string;
        provider: string;
    };
    isCredentialsDisabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            marginTop: theme.spacing(2)
        }
    })
);

export const UserDetailsForm: React.FC<IProps> = ({
    isBirthDateDisabled,
    isCredentialsDisabled,
    credentials: { id, provider }
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        values: { birthdate },
        setFieldValue
    } = useFormikContext<IUserInfoForm>();

    const handleDateChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue(EFormFields.DATE_OF_BIRTH, e.target.value);
        },
        [setFieldValue]
    );

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextFormField
                        trimWhiteSpaces="sides"
                        name={EFormFields.FIRST_NAME}
                        label={t('FIRST_NAME')}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFormField
                        trimWhiteSpaces="sides"
                        name={EFormFields.LAST_NAME}
                        label={t('LAST_NAME')}
                    />
                </Grid>
            </Grid>
            {provider && id && (
                <TextField
                    label={provider}
                    name={provider}
                    variant="outlined"
                    fullWidth
                    value={id}
                    className={classes.input}
                    disabled={isCredentialsDisabled}
                    color="secondary"
                />
            )}
            <TextField
                label={t('USER_DETAILS_BIRTHDATE_TITLE')}
                type="date"
                name={EFormFields.DATE_OF_BIRTH}
                variant="outlined"
                fullWidth
                onChange={handleDateChange}
                value={birthdate}
                className={classes.input}
                disabled={isBirthDateDisabled}
                color="secondary"
                inputProps={{ max: moment().format(EDateFormat.ISO) }}
                InputLabelProps={{
                    shrink: true
                }}
            />
            <Box marginTop={2} display="flex" alignItems="center" justifyContent="space-between">
                <CheckboxFormField name={EFormFields.MARKETING} label={t('GDPR_MARKETING_CHECKBOX_TITLE')} />
            </Box>
        </>
    );
};
