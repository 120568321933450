import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    Box,
    Button,
    Collapse,
    createStyles,
    Link,
    makeStyles,
    Paper,
    Theme,
    Typography
} from '@material-ui/core';
import { LocationOn } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';
import moment from 'moment';
import { DESKTOP_COLORS } from 'src/consts';
import { useResponsive } from 'src/hooks/useResponsive';
import { makeRouteScenario } from 'src/utils/route';
import { OrderScenario } from 'components/order/model/Order';
import { addOpacity } from 'lib/helpers';
import { isDefined } from 'lib/typeInference';
import { useLocalHistory } from 'lib/useLocalHistory';
import { ROUTES } from 'pages/routes';
import { ApplicationState } from 'store/store';
import { getWorkingHoursParams, isLocationOpen, isLocationOpenToday } from './hooks/useLocationHelpers';
import { IOpeningHours, Location } from './model/Location';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        address: (props: { isDesktop: boolean }) => ({
            ...(props.isDesktop
                ? {
                      color: DESKTOP_COLORS.TEXT_SECONDARY,
                      fontSize: theme.spacing(1.75),
                      fontWeight: theme.typography.fontWeightMedium
                  }
                : {})
        }),
        phone: (props: { isDesktop: boolean }) => ({
            ...(props.isDesktop
                ? {
                      color: DESKTOP_COLORS.TEXT_PRIMARY
                  }
                : {
                      color: theme.palette.text.primary
                  })
        }),
        locationPaper: (props: { isDesktop: boolean }) => ({
            height: '100%',
            ...(props.isDesktop
                ? {
                      boxShadow: `3px 3px 7px 6px ${addOpacity(theme.palette.common.black, 0.07)}`,
                      backgroundColor: theme.palette.common.white
                  }
                : {
                      border: `1px solid ${theme.palette.divider}`
                  })
        }),
        locationTitle: (props: { isDesktop: boolean }) => ({
            fontWeight: theme.typography.fontWeightBold,
            ...(props.isDesktop
                ? {
                      fontSize: theme.spacing(2.5),
                      color: DESKTOP_COLORS.TEXT_PRIMARY
                  }
                : {})
        }),
        locationImg: (props: { isDesktop: boolean }) => {
            const widthAndHeight = props.isDesktop ? 12.5 : 8;
            return {
                width: theme.spacing(widthAndHeight),
                height: theme.spacing(widthAndHeight),
                borderRadius: theme.shape.borderRadius,
                marginLeft: 'auto',
                flexShrink: 0,
                objectFit: 'cover'
            };
        },
        locationIcon: (props: { isDesktop: boolean }) => ({
            marginRight: theme.spacing(0.5),
            color: theme.palette.common.black,
            ...(props.isDesktop
                ? {
                      width: theme.spacing(2)
                  }
                : {})
        }),
        seeMore: (props: { isDesktop: boolean }) => ({
            textTransform: 'initial',
            padding: 0,
            ...(props.isDesktop
                ? {
                      fontSize: theme.spacing(1.75),
                      color: DESKTOP_COLORS.TEXT_PRIMARY
                  }
                : {
                      fontSize: theme.typography.body1.fontSize
                  })
        }),
        seeMoreIcon: {
            transition: 'transform .1s',
            '&.active': {
                transform: 'rotate(180deg)'
            }
        },
        workingHoursToday: (props: { isDesktop: boolean }) => ({
            ...(props.isDesktop
                ? {
                      fontSize: theme.spacing(1.75),
                      color: DESKTOP_COLORS.TEXT_PRIMARY
                  }
                : {})
        }),
        orderCta: {
            height: '100%'
        },
        fullAddress: (props: { isDesktop: boolean }) => ({
            ...(props.isDesktop
                ? {
                      color: DESKTOP_COLORS.TEXT_PRIMARY
                  }
                : {})
        }),
        workingHours: (props: { isDesktop: boolean }) => ({
            width: 'max-content',
            fontSize: theme.typography.body1.fontSize,
            borderSpacing: 0,
            marginLeft: theme.spacing(-1),
            ...(props.isDesktop
                ? {
                      color: DESKTOP_COLORS.TEXT_PRIMARY
                  }
                : {}),
            '& tr>td': {
                padding: theme.spacing(0.5, 1, 0.5, 1),
                '&:first-child': {
                    borderTopLeftRadius: theme.shape.borderRadius,
                    borderBottomLeftRadius: theme.shape.borderRadius
                },
                '&:last-child': {
                    borderTopRightRadius: theme.shape.borderRadius,
                    borderBottomRightRadius: theme.shape.borderRadius
                }
            },
            '& tr.active': {
                backgroundColor: theme.palette.divider,
                fontWeight: theme.typography.fontWeightBold
            }
        })
    })
);

interface IProps {
    location: Location;
}

export const LocationCard: React.FC<IProps> = ({ location }) => {
    const { isDesktop, isMobile } = useResponsive();
    const classes = useStyles({ isDesktop });
    const { t } = useTranslation();
    const { push } = useLocalHistory();
    const [isMoreOpen, setIsMoreOpen] = React.useState(false);

    const distanceUnit = useSelector(
        (state: ApplicationState) => state.settings.settings?.region.distanceUnit
    );

    let { distance } = location;
    if (distanceUnit === 'mi') {
        distance = isDefined(distance) ? distance * 0.621371 : undefined;
    }
    const isOpenAtm = isLocationOpen(location.openingHours);
    const { workingHoursOfToday } = getWorkingHoursParams(location.openingHours);

    const getWorkingHours = React.useCallback(() => {
        if (isOpenAtm) {
            return (
                <Trans
                    i18nKey="LOCATION_OPEN"
                    values={{ workingHours: workingHoursOfToday }}
                    components={[<b key="bold" />]}
                />
            );
        }
        const isOpenToday = isLocationOpenToday(location.openingHours);

        if (!isOpenToday) {
            return 'Closed';
        }
        return (
            <Trans
                i18nKey="LOCATIONS_LIST_CLOSED"
                values={{
                    time: workingHoursOfToday
                }}
                components={[<b key="bold" />]}
            />
        );
    }, [isOpenAtm, location.openingHours, workingHoursOfToday]);

    const toggleShowMore = React.useCallback(() => setIsMoreOpen(value => !value), []);

    const addressLine = React.useMemo(
        () =>
            !!location
                ? [
                      location.address.address,
                      location.address.town,
                      location.address.country,
                      location.address.postcode
                  ]
                      .filter(item => !!item)
                      .join(', ')
                : '',
        [location]
    );
    const workingHours = React.useMemo(() => {
        const startOfTheWeek = moment().startOf('week').add(1, 'day');
        const endOfTheWeek = moment().endOf('week').add(1, 'day');
        const options: { label: string; value: string }[] = [];

        while (startOfTheWeek.isSameOrBefore(endOfTheWeek)) {
            const weekDay = startOfTheWeek.format('dddd');
            const workingHourOfTheDay = location.openingHours[weekDay.toLowerCase() as keyof IOpeningHours];
            options.push({
                label: weekDay,
                value: workingHourOfTheDay.includes('closed') ? 'Closed' : workingHourOfTheDay
            });
            startOfTheWeek.add(1, 'day');
        }

        return options;
    }, [location.openingHours]);
    const renderWorkingHourRow = React.useCallback((day: { label: string; value: string }) => {
        const isToday = moment().format('dddd') === day.label;
        return (
            <tr key={`day-${day.label}`} className={clsx({ active: isToday })}>
                <td>{day.label}</td>
                <td>{day.value}</td>
            </tr>
        );
    }, []);

    const handleClick = React.useCallback(() => {
        push(ROUTES.MENU, { locationId: location._id, scenario: makeRouteScenario(OrderScenario.PREORDER) });
    }, [location._id, push]);

    return (
        <div key={location._id}>
            <Box height="100%" marginTop={1}>
                <Paper elevation={0} className={classes.locationPaper}>
                    <Box padding={1.5}>
                        <Box marginBottom={2} display="flex">
                            <div>
                                <Typography className={classes.locationTitle} variant="h6">
                                    {location.title}
                                </Typography>
                                <Typography className={classes.address} variant="body2">
                                    {location.address.summary}
                                </Typography>
                                {!!distance && (
                                    <Typography className={classes.address} variant="caption">
                                        <LocationOn className={classes.locationIcon} fontSize="inherit" />
                                        {`${Math.floor(distance).toLocaleString()}${distanceUnit || 'km'}`}
                                    </Typography>
                                )}
                            </div>
                            {location.listingImageUrl ? (
                                <img
                                    className={classes.locationImg}
                                    src={location.listingImageUrl}
                                    alt={location.title}
                                />
                            ) : (
                                <div className={classes.locationImg} />
                            )}
                        </Box>

                        <Box marginTop={1.5} display="flex">
                            <Box>
                                <Typography className={classes.workingHoursToday}>
                                    {getWorkingHours()}
                                </Typography>
                                <Button className={classes.seeMore} onClick={toggleShowMore} variant="text">
                                    <b>{t('LOCATION_DETAILS_SHOW_MORE')}</b>
                                    <KeyboardArrowDownIcon
                                        className={clsx(classes.seeMoreIcon, { active: isMoreOpen })}
                                    />
                                </Button>
                            </Box>
                            {!isMobile && (
                                <Box marginLeft="auto" maxWidth="100px" width="100%">
                                    <Button
                                        onClick={handleClick}
                                        variant="contained"
                                        fullWidth
                                        color="primary"
                                        className={classes.orderCta}
                                    >
                                        {t('LOCATION_START_COLLECTION_CTA')}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                        <Collapse in={isMoreOpen}>
                            <Box marginTop={1}>
                                <Typography className={classes.fullAddress}>
                                    <Trans
                                        i18nKey="LOCATION_ADDRESS"
                                        components={[<b key="address" />]}
                                        values={{ address: addressLine }}
                                    />
                                </Typography>
                                {location.contacts.phone && (
                                    <Link className={classes.phone} href={`tel:${location.contacts.phone}`}>
                                        <Typography>
                                            <b>
                                                {t('LOCATION_CONTACT_PHONE', {
                                                    phone: location.contacts.phone
                                                })}
                                            </b>
                                        </Typography>
                                    </Link>
                                )}
                                <Box marginTop={1}>
                                    <table className={classes.workingHours}>
                                        <tbody>{workingHours.map(renderWorkingHourRow)}</tbody>
                                    </table>
                                </Box>
                            </Box>
                        </Collapse>
                        {isMobile && (
                            <Box marginTop={1}>
                                <Button onClick={handleClick} variant="contained" fullWidth color="primary">
                                    {t('LOCATION_START_COLLECTION_CTA')}
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Paper>
            </Box>
        </div>
    );
};
