import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import AccessTime from '@material-ui/icons/AccessTime';
import { OrderScenario } from 'components/order/model/Order';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            display: 'flex',
            padding: theme.spacing(1.5),
            border: `1px solid ${theme.palette.primary.main}`,
            margin: theme.spacing(1.5),
            flexShrink: 0
        },
        icon: {
            color: theme.palette.primary.main,
            marginRight: theme.spacing(1)
        }
    })
);

interface NotWorkingHourProps {
    scenario: OrderScenario;
}

export const NotWorkingHour: React.FC<NotWorkingHourProps> = ({ scenario }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const description = React.useMemo(() => {
        switch (scenario) {
            case OrderScenario.PREORDER:
                return t('LOCATION_OUT_OF_HOURS_MESSAGE');
            case OrderScenario.ORDER_TO_TABLE:
                return t('LOCATION_OUT_OF_HOURS_OTT_MESSAGE');
            default:
                return t('LOCATION_OUT_OF_HOURS_OTT_MESSAGE');
        }
    }, [scenario, t]);

    return (
        <div className={classes.wrapper}>
            <AccessTime className={classes.icon} />
            <Typography>{description}</Typography>
        </div>
    );
};

NotWorkingHour.displayName = 'NotWorkingHour';
