import React from 'react';
import { useSelector } from 'react-redux';
import { Button, CssBaseline } from '@material-ui/core';
import { StylesProvider, ThemeProvider } from '@material-ui/styles';
import { createTheme } from 'config/theme';
import { ApplicationState } from 'store/store';
import { ErrorBoundary } from './ErrorBoundary';
import { SnackbarProvider, closeSnackbar } from 'notistack';

interface AppWrapperProps {
    children: React.ReactNode;
}

export function AppWrapper(props: AppWrapperProps) {
    const { children } = props;
    const { layout } = useSelector((state: ApplicationState) => state.layout);
    const { merchantId } = useSelector((state: ApplicationState) => state.settings);
    const theme = React.useMemo(() => createTheme(merchantId ?? '', layout), [layout, merchantId]);
    const handleDismissSnackbar = React.useCallback(
        (snackbarId: string) => () => {
            closeSnackbar(snackbarId);
        },
        []
    );
    const dismissAction = React.useCallback(
        (snackbarId: string) => (
            <Button color="inherit" onClick={handleDismissSnackbar(snackbarId)}>
                Dismiss
            </Button>
        ),
        [handleDismissSnackbar]
    );

    return (
        <StylesProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <SnackbarProvider
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    autoHideDuration={3000}
                    action={dismissAction}
                >
                    <ErrorBoundary>{children}</ErrorBoundary>
                </SnackbarProvider>
            </ThemeProvider>
        </StylesProvider>
    );
}
