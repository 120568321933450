import React from 'react';
import { useSelector } from 'react-redux';
import GooglePayButton from '@google-pay/button-react';
import { EPaymentProvider } from 'components/settings/model/Settings';
import { ApplicationState } from 'store/store';
import { GooglePayPaymentDetails } from '../GooglePay';

interface MuiGooglePayButtonProps {
    price: number;
    onLoad: (data: GooglePayPaymentDetails) => void;
}

export const MuiGooglePayButton: React.FC<MuiGooglePayButtonProps> = ({ price, onLoad }) => {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const environment = React.useMemo(() => {
        let isProduction = false;
        switch (settings?.paymentProvider) {
            case EPaymentProvider.JUDOPAY:
                isProduction = !!settings?.judopay?.isProduction;
                break;
            case EPaymentProvider.WORLDPAY:
                isProduction = !!settings?.worldpay?.isProduction;
                break;
            default:
                break;
        }

        return isProduction ? 'PRODUCTION' : 'TEST';
    }, [settings?.judopay?.isProduction, settings?.paymentProvider, settings?.worldpay?.isProduction]);
    const parsedAllowedCardNetworks = React.useMemo(() => {
        const result: google.payments.api.CardNetwork[] = [];
        if (settings?.googlePay.supportedNetworksMastercard) {
            result.push('MASTERCARD');
        }
        if (settings?.googlePay.supportedNetworksVisa) {
            result.push('VISA');
        }
        if (settings?.googlePay.supportedNetworksAmex) {
            result.push('AMEX');
        }
        return result;
    }, [
        settings?.googlePay?.supportedNetworksAmex,
        settings?.googlePay?.supportedNetworksMastercard,
        settings?.googlePay?.supportedNetworksVisa
    ]);
    const merchantInfo = React.useMemo(
        () => ({
            merchantName: settings?.googlePay.merchantTitle || '',
            merchantId: settings?.googlePay.merchantId || ''
        }),
        [settings?.googlePay?.merchantId, settings?.googlePay?.merchantTitle]
    );
    const transactionInfo = React.useMemo<google.payments.api.TransactionInfo>(
        () => ({
            totalPriceStatus: 'FINAL',
            totalPrice: price.toFixed(2).toString(),
            currencyCode: settings?.region.currencyCode || '',
            countryCode: settings?.googlePay.countryCode || ''
        }),
        [settings?.googlePay?.countryCode, settings?.region?.currencyCode, price]
    );
    const handleLoadPaymentData = React.useCallback(
        (data: google.payments.api.PaymentData) => {
            onLoad({
                cardDetails: data.paymentMethodData.info?.cardDetails,
                cardNetwork: data.paymentMethodData.info?.cardNetwork,
                token: data.paymentMethodData.tokenizationData.token
            });
        },
        [onLoad]
    );
    const gateWaySettings = React.useMemo(() => {
        if (settings) {
            if (
                (settings.paymentProvider === EPaymentProvider.JUDOPAY && settings.judopay) ||
                (settings.paymentProvider === EPaymentProvider.WORLDPAY && !!settings.worldpay)
            ) {
                switch (settings.paymentProvider) {
                    case EPaymentProvider.JUDOPAY:
                        return {
                            gateway: 'judopay',
                            gatewayMerchantId: settings.judopay.judoId
                        };
                    case EPaymentProvider.WORLDPAY:
                        return {
                            gateway: 'worldpay',
                            gatewayMerchantId: settings.worldpay?.googlePayMerchantId || ''
                        };
                    default:
                        break;
                }
            }
        }
    }, [settings]);

    return gateWaySettings ? (
        <GooglePayButton
            environment={environment}
            buttonSizeMode="fill"
            style={{ width: '100%' }}
            paymentRequest={{
                apiVersion: 2,
                apiVersionMinor: 0,
                allowedPaymentMethods: [
                    {
                        type: 'CARD',
                        parameters: {
                            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                            allowedCardNetworks: parsedAllowedCardNetworks
                        },
                        tokenizationSpecification: {
                            type: 'PAYMENT_GATEWAY',
                            parameters: gateWaySettings
                        }
                    }
                ],
                merchantInfo,
                transactionInfo
            }}
            onLoadPaymentData={handleLoadPaymentData}
        />
    ) : null;
};
