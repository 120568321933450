import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { MerchantTitle } from 'app/MerchantTitle';
import { SignUpDOBData } from 'components/user/model/User';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { TextFormField } from 'lib/form/TextFormField';
import { LoadingButton } from 'ui/LoadingButton';

interface IAgeVerificationProps {
    onBack: () => void;
    initialValues: SignUpDOBData;
    birthDateValidation: Yup.ObjectSchema<Yup.Shape<SignUpDOBData | undefined, { birthDate: string }>>;
    onSubmit: (dobData: SignUpDOBData, formikHelpers: FormikHelpers<SignUpDOBData>) => void;
    error?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            paddingTop: theme.spacing(4)
        },
        button: {
            height: theme.spacing(6),
            fontSize: '1.2em'
        }
    })
);

export const AgeVerification: React.FC<IAgeVerificationProps> = ({
    onBack,
    initialValues,
    birthDateValidation,
    onSubmit,
    error
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <MerchantTitle title={t('ONBOARDING_DOB_TITLE')} />
            <MuiAppBar title={t('ONBOARDING_DOB_TITLE')} onBack={onBack} disabledMenu icon={Close} />
            <Formik initialValues={initialValues} validationSchema={birthDateValidation} onSubmit={onSubmit}>
                {({ submitForm, isValid, values: { birthDate }, isSubmitting }) => (
                    <Form className={classes.root}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>{t('ONBOARDING_DOB_MESSAGE')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextFormField
                                    name="birthDate"
                                    autoFocus
                                    type="date"
                                    label={t('USER_DETAILS_BIRTHDATE_TITLE')}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            {!!error && (
                                <Grid item xs={12}>
                                    <Typography color="error">{error}</Typography>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    disabled={!isValid || isSubmitting || !birthDate}
                                    fullWidth
                                    onClick={submitForm}
                                    className={classes.button}
                                    loading={isSubmitting}
                                >
                                    {t('BUTTON_SUBMIT')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};
