import React from 'react';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { addOpacity } from './helpers';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            color: theme.palette.titleTextColour
        },
        paragraph: {
            marginTop: theme.spacing(0.5),
            color: addOpacity(theme.palette.text.primary, 0.5)
        }
    })
);

interface EmptyStateProps {
    headerText: string;
    paragraphText: string;
}

export const EmptyState: React.FC<EmptyStateProps> = props => {
    const classes = useStyles();
    const { headerText, paragraphText, children } = props;
    return (
        <Box
            display="flex"
            flexDirection="column"
            flex={1}
            justifyContent="center"
            alignItems="center"
            padding={2}
        >
            {children}
            <Typography align="center" variant="h5" className={classes.header}>
                {headerText}
            </Typography>
            <Typography align="center" variant="h6" color="textSecondary" className={classes.paragraph}>
                {paragraphText}
            </Typography>
        </Box>
    );
};
