/* eslint-disable react/no-multi-comp */
import React from 'react';
import { InputBase, InputBaseProps, InputLabelProps, TextField } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import { Field, FieldAttributes, FieldProps } from 'formik';

interface TextFieldProps {
    label?: React.ReactNode;
    placeholder?: string;
    multiline?: boolean;
    rowsMax?: number;
    disabled?: boolean;
    textColor?: string;
    inputRef?: React.Ref<HTMLInputElement>;
    description?: string;
    type?: string;
    fontSize?: string;
    textAlign?: CSSProperties['textAlign'];
    naked?: boolean;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    trimWhiteSpaces?: 'all' | 'sides';
    max?: number;
    min?: number;
    className?: string;
    InputProps?: Partial<InputBaseProps>;
    inputProps?: Partial<InputBaseProps['inputProps']>;
    variant?: 'outlined' | 'standard';
    InputLabelProps?: Partial<InputLabelProps>;
    size?: 'small' | 'medium';
}

const TextFormFieldComponent: React.FC<FieldProps & TextFieldProps> = ({
    field,
    meta,
    label,
    type,
    fontSize,
    placeholder,
    description,
    multiline,
    textColor,
    min,
    max,
    disabled,
    inputRef,
    textAlign,
    naked,
    onKeyDown,
    rowsMax,
    trimWhiteSpaces: removeWhiteSpaces,
    form,
    InputProps,
    inputProps = {},
    className,
    size,
    variant,
    InputLabelProps: inputLabelProps
}) => {
    const handleBlur = React.useCallback(
        (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            field.onBlur(event);
            if (removeWhiteSpaces) {
                if (removeWhiteSpaces === 'all') {
                    form.setFieldValue(field.name, event.target.value.replace(/\s/g, ''));
                } else {
                    form.setFieldValue(field.name, event.target.value.trim());
                }
            }
        },
        [field, form, removeWhiteSpaces]
    );

    if (naked) {
        return (
            <InputBase
                fullWidth
                placeholder={placeholder}
                className={className}
                type={type}
                multiline={multiline}
                rowsMax={rowsMax || (multiline ? 4 : undefined)}
                name={field.name}
                disabled={disabled}
                style={fontSize ? { fontSize } : {}}
                inputProps={{
                    min,
                    max,
                    step: 'any',
                    style: { textAlign: textAlign || 'start', color: textColor },
                    ...inputProps
                }}
                inputRef={inputRef}
                value={field.value}
                onBlur={handleBlur}
                onChange={field.onChange}
                onKeyDown={onKeyDown}
                color="secondary"
                {...InputProps}
            />
        );
    }
    return (
        <TextField
            fullWidth
            variant={variant ?? 'outlined'}
            placeholder={placeholder}
            type={type}
            multiline={multiline}
            rowsMax={rowsMax || (multiline ? 4 : undefined)}
            name={field.name}
            label={label}
            disabled={disabled}
            inputRef={inputRef}
            value={field.value}
            onChange={field.onChange}
            inputProps={{ min, max, step: 'any', ...inputProps }}
            onBlur={handleBlur}
            onKeyDown={onKeyDown}
            error={meta.touched && !!meta.error}
            helperText={(meta.touched && !!meta.error && meta.error) || description}
            InputProps={InputProps}
            InputLabelProps={inputLabelProps}
            size={size}
            color="secondary"
            className={className}
        />
    );
};

export const TextFormField: React.FC<FieldAttributes<TextFieldProps>> = ({
    label,
    placeholder,
    description,
    multiline,
    textColor,
    disabled,
    naked,
    textAlign,
    fontSize,
    rowsMax,
    inputRef,
    onKeyDown,
    max,
    min,
    inputProps,
    InputProps,
    onBlur,
    variant,
    className,
    size,
    trimWhiteSpaces: removeWhiteSpaces = 'sides',
    InputLabelProps: inputLabelProps,
    ...props
}) => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <TextFormFieldComponent
                label={label}
                placeholder={placeholder}
                multiline={multiline}
                type={props.type}
                naked={naked}
                textColor={textColor}
                textAlign={textAlign}
                disabled={disabled}
                fontSize={fontSize}
                inputRef={inputRef}
                description={description}
                trimWhiteSpaces={removeWhiteSpaces}
                onBlur={onBlur}
                rowsMax={rowsMax}
                min={min}
                max={max}
                onKeyDown={onKeyDown}
                InputProps={InputProps}
                inputProps={inputProps}
                variant={variant}
                InputLabelProps={inputLabelProps}
                size={size}
                className={className}
                {...childProps}
            />
        )}
    </Field>
);
