import groupBy from 'lodash/groupBy';
import { User } from 'components/user/model/User';
import { IOrderReadResourceV12, IUserReadResourceV12, SplittableItem } from './model/Order';

class OrderService {
    getSplittableItemMap(orderDetails: IOrderReadResourceV12, currentUser: User) {
        if (orderDetails) {
            const { users, payments, items } = orderDetails;
            const usersMap = users.reduce<Record<string, IUserReadResourceV12>>((acc, user) => {
                acc[user.userId] = user;

                return acc;
            }, {});
            const paymentsMap = payments.filter(
                payment => !payment.deleted && (payment.itemIds || [])?.length > 0
            );
            const userToPayments = groupBy(paymentsMap, payment => payment.userId);
            const userToPaidItems = Object.entries(userToPayments).reduce<Record<string, number[]>>(
                (acc, [userId, userPayments]) => {
                    const itemIds = userPayments.map(payment => payment.itemIds || []).flat(1);
                    acc[userId] = itemIds;

                    return acc;
                },
                {}
            );

            const allItems: SplittableItem[] = [];

            items.forEach(item => {
                if (!item.parentReferenceId) {
                    const claims = item.isClaimedBy ?? [];

                    for (let i = 0; i < item.quantity; i++) {
                        const claimedById = claims[i];
                        const claimedBy = claimedById ? usersMap[claimedById] : undefined;
                        const isPaid = userToPaidItems[claimedById]
                            ? userToPaidItems[claimedById].includes(item.id)
                            : false;
                        const condiments = items.filter(
                            orderItem =>
                                !!orderItem.parentReferenceId &&
                                orderItem.parentReferenceId === item.referenceId
                        );

                        if (isPaid) {
                            const indexOfItem = userToPaidItems[claimedById].indexOf(item.id);
                            if (indexOfItem !== -1) {
                                userToPaidItems[claimedById].splice(indexOfItem, 1);
                            }
                        }

                        allItems.push({
                            ...item,
                            isPaid,
                            claimedBy,
                            condiments,
                            available:
                                !isPaid && (claimedBy === undefined || claimedById === currentUser?._id)
                        });
                    }
                }
            });

            return allItems;
        }

        return [];
    }

    getUserClaimedItemIds(items: SplittableItem[], currentUser: User) {
        const claimedItemIds = items
            .filter(item => item.claimedBy?.userId === currentUser?._id && !item.isPaid)
            .map(item => item.id);
        const unpaidItems = items.filter(item => !item.isPaid);

        // Add condiment products to the list
        claimedItemIds.forEach(itemId => {
            const claimedItem = unpaidItems.find(({ id }) => id === itemId);

            if (claimedItem && claimedItem.condiments.length > 0) {
                claimedItem.condiments.forEach(condiment => {
                    claimedItemIds.push(
                        ...Array(condiment.quantity / claimedItem.quantity).fill(condiment.id)
                    );
                });
            }
        });

        return claimedItemIds;
    }
}

export const orderService = new OrderService();
