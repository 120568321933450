import { isBoolean, isNumber, isOptional, isOptionalString } from 'lib/typeInference';

export enum OperationStatus {
    PENDING = 'PENDING',
    DONE = 'DONE',
    ERROR = 'ERROR',
    TIMEOUT = 'TIMEOUT'
}

export interface IOperation {
    id?: number;
    status: OperationStatus;
    etaMs: number;
    // TODO - we should not store full href, but urlPath only - to allow for host changes
    href?: string;
    resultHref?: string;
    resultId?: number;
    errorMessage?: string;
    // TODO add errorData - stringified error.data?
    errorCode?: string;
    isRetryable?: boolean;
    createdAt?: Date;
    updatedAt?: Date;
    httpCode: number;
    errorData?: Record<string, any>;
}

export const isOperation = (input: any): input is IOperation =>
    isOptional(isNumber, input.id) &&
    (input.status === OperationStatus.PENDING ||
        input.status === OperationStatus.DONE ||
        input.status === OperationStatus.ERROR ||
        input.status === OperationStatus.TIMEOUT) &&
    isNumber(input.etaMs) &&
    isOptionalString(input.href) &&
    isOptionalString(input.resultHref) &&
    isOptional(isNumber, input.resultId) &&
    isOptionalString(input.errorMessage) &&
    isOptionalString(input.errorCode) &&
    isOptional(isBoolean, input.isRetryable);
