import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { ELoadingSVG, RandomLoading } from 'lib/animations/RandomLoadingSVG';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageContainer: {
            display: 'flex',
            height: '100%',
            paddingBottom: theme.spacing(7),
            overflowY: 'hidden',
            flexDirection: 'column'
        },
        pageCenter: {
            alignItems: 'center'
        },
        verticalCenter: {
            justifyContent: 'center'
        }
    })
);

export const WorldpayMessanger: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    window.parent.postMessage(JSON.stringify(params), '*');
    // Using a memo here to stop this component changing over 3DS2 Auth / re-order operation change
    // Much more seamless experience for the user
    const RandomLoadingComponent = React.useMemo(
        () => (
            <RandomLoading
                fallbackText={t('GENERAL_GENERIC_ERROR')}
                enabledImages={[
                    ELoadingSVG.COFFEE_CUP,
                    ELoadingSVG.COFFEE_MUG,
                    ELoadingSVG.CUPCAKE,
                    ELoadingSVG.DONUT,
                    ELoadingSVG.FRYING_PAN,
                    ELoadingSVG.POPSICLE
                ]}
            />
        ),
        [t]
    );
    return (
        <Box className={clsx(classes.pageContainer, classes.pageCenter, classes.verticalCenter)}>
            {RandomLoadingComponent}
        </Box>
    );
};
