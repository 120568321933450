/* eslint-disable react/no-multi-comp */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Button,
    createStyles,
    Dialog,
    makeStyles,
    Slide,
    SlideProps,
    Theme,
    Typography
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useCalories } from 'src/hooks/useCalories';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { OrderScenario } from 'components/order/model/Order';
import { useTimeSlotHelpers } from 'components/timeslots/useTimeSlotHelpers';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { BottomDialog } from 'lib/BottomDialog';
import { showCalories } from 'lib/helpers';
import { QuantitySelector } from 'lib/QuantitySelector';
import { useCurrencyString } from 'lib/useCurrencyString';
import { addBasketItem, setSuggestionsShowed } from 'store/basket/basketActions';
import { ApplicationState } from 'store/store';
import { useSuggestionsContext } from '../../hooks/useSuggestionsContext';
import { IEnrichedProduct, isProductGroup, ProductGroup } from './model/Menu';
import { useBogofModal } from './suggestions/useBogofModal';
import { MenuEnrichedProductGroupInfo } from './MenuEnrichedProductGroupInfo';
import { MenuEnrichedProductInfo } from './MenuEnrichedProductInfo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            width: '100%',
            height: '100%'
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            height: '0',
            flex: 1,
            padding: theme.spacing(1)
        },
        addToBasketButton: {
            marginTop: theme.spacing(1),
            padding: theme.spacing(1)
        }
    })
);

interface MenuProductInfoProps {
    item: IEnrichedProduct | ProductGroup;
    open: boolean;
    currency: string;
    onClose: () => void;
    categoryId: string;
}

export const MenuProductInfo: React.FC<MenuProductInfoProps> = ({ item, open, onClose, categoryId }) => {
    const { key: energyKey, getProductCalories, getCaloriesString } = useCalories();
    const classes = useStyles();
    const isGroup = React.useMemo(() => isProductGroup(item), [item]);
    const [entered, setEntered] = React.useState(false);
    const onEntered = React.useCallback(() => {
        setEntered(true);
    }, []);
    const onExited = React.useCallback(() => {
        setEntered(false);
    }, []);
    const transitionProps: SlideProps = { direction: 'up', onEntered, onExited };
    const { t } = useTranslation();
    const menu = useSelector((state: ApplicationState) => state.menu.menu);
    const [scenario] = useRouteScenario();
    const { isTimeSlotsAvailable, asapEnabled } = useTimeSlotHelpers();
    const [quantity, setQuantity] = React.useState(1);
    const dispatch = useDispatch();
    const { isWorkingHour, isLocationOrderEnabled, checkServiceAvailability } = useLocationHelpers();
    const getCurrencyString = useCurrencyString();
    const context = useSuggestionsContext();
    const { bogofModal, setBogofSuggestion, setProduct } = useBogofModal(onClose, quantity);
    const nutritions = React.useMemo(() => {
        const prodItem = item as IEnrichedProduct;
        if (prodItem.nutrition && prodItem.nutrition.length > 0) {
            return prodItem.nutrition.map(nutrition => ({
                ...nutrition,
                values: nutrition.values.filter(n => n.nutrient !== energyKey)
            }));
        }

        return [];
    }, [energyKey, item]);

    const itemHasInfo = React.useMemo(() => {
        if (isGroup) {
            return true;
        }
        const prodItem = item as IEnrichedProduct;
        let hasModifiers = false;
        if (prodItem.modifiers && prodItem.modifiers.length !== 0 && menu) {
            for (const modifierId of prodItem.modifiers) {
                const categoryId = menu.productIdToCategoryId.get(prodItem.id);
                if (
                    (!!categoryId &&
                        menu.modifierIdToCategoryOptionModifierSettings.get(categoryId)?.get(modifierId)) ??
                    menu.modifierIdToOptionModifierSettings.get(modifierId) ??
                    menu.modifierIdToProductModifierSettings.get(modifierId)
                ) {
                    hasModifiers = true;
                }
            }
        }
        return (
            prodItem.imageUrl ||
            prodItem.prepTimeMins ||
            hasModifiers ||
            (prodItem.allergens && prodItem.allergens.length !== 0) ||
            (prodItem.shortDescription && prodItem.shortDescription.length !== 0) ||
            (prodItem.tags && prodItem.tags.length !== 0) ||
            (nutritions.length > 0 && nutritions[0]?.values.length !== 0)
        );
    }, [isGroup, item, menu, nutritions]);

    const handleAddItemToBasket = React.useCallback(() => {
        if (!menu) {
            return;
        }
        const prodItem = item as IEnrichedProduct;
        if (context.isSuggestionTypeAdd(prodItem) && prodItem.suggestions) {
            context.setSuggestion(prodItem.suggestions[0]);
            context.setAddedProduct(prodItem);
            setSuggestionsShowed()(dispatch);
            onClose();
        }
        if (context.isSuggestionTypeBogof(prodItem) && prodItem.suggestions) {
            setBogofSuggestion(prodItem.suggestions[0]);
            setProduct(prodItem);
            return;
        }
        const categoryId = menu.productIdToCategoryId.get(prodItem.id);
        addBasketItem(prodItem, categoryId || '', quantity, undefined, prodItem.price)(dispatch);
        onClose();
    }, [menu, item, context, quantity, dispatch, onClose, setBogofSuggestion, setProduct]);

    if (!itemHasInfo) {
        const productCalories = getProductCalories(item);
        return (
            <>
                <BottomDialog open={open} onClose={onClose}>
                    <Box width="100%" height="100%" display="flex" alignItems="center" flexDirection="column">
                        <Typography variant="body1">{item.title}</Typography>
                        {showCalories(productCalories) && (
                            <Typography variant="caption">
                                {`${getCaloriesString(productCalories.value)} ${t(
                                    'ORDER_CALORIES_PER_UNIT'
                                )}`}
                            </Typography>
                        )}
                        <Box marginTop={1}>
                            <QuantitySelector min={1} max={999} quantity={quantity} onChange={setQuantity} />
                        </Box>
                        <Button
                            className={classes.addToBasketButton}
                            color="primary"
                            variant="contained"
                            onClick={handleAddItemToBasket}
                            disabled={
                                !isWorkingHour ||
                                !isLocationOrderEnabled ||
                                (scenario === OrderScenario.PREORDER &&
                                    !asapEnabled &&
                                    !isTimeSlotsAvailable) ||
                                !isWorkingHour ||
                                !isLocationOrderEnabled ||
                                (!!scenario && !checkServiceAvailability(scenario))
                            }
                            fullWidth
                        >
                            {t('PREORDER_ADD_TO_BASKET', {
                                price: getCurrencyString((item as IEnrichedProduct).price * quantity)
                            })}
                        </Button>
                        {showCalories(productCalories) && (
                            <Box marginTop={1}>
                                <Typography variant="caption">
                                    {t('ORDER_CALORIES_ADVISORY_MESSAGE')}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </BottomDialog>
                {bogofModal()}
            </>
        );
    }
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            className={classes.dialog}
            TransitionComponent={Slide}
            TransitionProps={transitionProps}
        >
            <MuiAppBar title={item.title} onBack={onClose} icon={Close} ignoreGA />
            <Box className={classes.content}>
                {isGroup && (
                    <MenuEnrichedProductGroupInfo
                        items={(item as ProductGroup).products}
                        handlePostAddedToBasket={onClose}
                        showSelect={entered}
                        categoryId={categoryId}
                    />
                )}
                {!isGroup && (
                    <MenuEnrichedProductInfo
                        categoryId={categoryId}
                        item={item as IEnrichedProduct}
                        handlePostAddedToBasket={onClose}
                    />
                )}
            </Box>
        </Dialog>
    );
};
