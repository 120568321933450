/* eslint-disable react/no-multi-comp */
import React from 'react';
import { IconButton, TextField } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { CSSProperties } from '@material-ui/styles';
import { Field, FieldAttributes, FieldProps } from 'formik';

interface PasswordFieldProps {
    label?: React.ReactNode;
    placeholder?: string;
    multiline?: boolean;
    rowsMax?: number;
    disabled?: boolean;
    textColor?: string;
    inputRef?: React.Ref<HTMLInputElement>;
    description?: string;
    fontSize?: string;
    textAlign?: CSSProperties['textAlign'];
    naked?: boolean;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    max?: number;
    min?: number;
}
const PasswordFormFieldComponent: React.FC<FieldProps & PasswordFieldProps> = ({
    field,
    meta,
    label,
    placeholder,
    description,
    multiline,
    min,
    max,
    disabled,
    inputRef,
    onKeyDown,
    rowsMax
}) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const handleMouseDown = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    }, []);
    const handleShowPassword = React.useCallback(() => {
        setShowPassword(!showPassword);
    }, [showPassword]);
    return (
        <TextField
            fullWidth
            variant="outlined"
            placeholder={placeholder}
            type={showPassword ? 'text' : 'password'}
            multiline={multiline}
            rowsMax={rowsMax || (multiline ? 4 : undefined)}
            name={field.name}
            label={label}
            disabled={disabled}
            inputRef={inputRef}
            value={field.value}
            onChange={field.onChange}
            inputProps={{ min, max }}
            onBlur={field.onBlur}
            onKeyDown={onKeyDown}
            error={meta.touched && !!meta.error}
            helperText={(meta.touched && !!meta.error && meta.error) || description}
            color="secondary"
            InputProps={{
                endAdornment: (
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        onMouseDown={handleMouseDown}
                        size="small"
                        tabIndex={-1}
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                )
            }}
        />
    );
};

export const PasswordFormField: React.FC<FieldAttributes<PasswordFieldProps>> = ({
    label,
    placeholder,
    description,
    multiline,
    textColor,
    disabled,
    rowsMax,
    inputRef,
    onKeyDown,
    max,
    min,
    ...props
}) => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <PasswordFormFieldComponent
                label={label}
                placeholder={placeholder}
                multiline={multiline}
                textColor={textColor}
                disabled={disabled}
                inputRef={inputRef}
                description={description}
                rowsMax={rowsMax}
                min={min}
                max={max}
                onKeyDown={onKeyDown}
                {...childProps}
            />
        )}
    </Field>
);
