import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { isDefined } from 'lib/typeInference';
import { useCurrencyString } from 'lib/useCurrencyString';
import { PAY_MAX_WIDTH } from 'pages/pay';
import { ErrorCircle } from '../ui/ErrorCircle';
import { QuickPayButton } from '../ui/PayButton';

interface IProps {
    errorMessage?: string | null;
    showRetry?: boolean;
    lastRequest?: () => void;
    handleRetry?: () => void;
    payInstead?: number;
    onPayInstead?: () => void;
    extraTips?: number;
    onPayExtraTips?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: '24px',
            fontWeight: 500,
            color: theme.palette.text.primary
        },
        icon: {
            height: 100,
            width: 100
        },
        buttonContainer: {
            position: 'fixed',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            maxWidth: PAY_MAX_WIDTH,
            right: 0,
            padding: theme.spacing(1)
        }
    })
);

export const PayError: React.FC<IProps> = ({
    errorMessage,
    showRetry,
    handleRetry,
    lastRequest,
    payInstead,
    onPayInstead,
    extraTips,
    onPayExtraTips
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const getCurrencyString = useCurrencyString();

    return (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" paddingTop={2}>
            <Typography className={classes.title}>{t('PAT_QUICKPAY_ERROR_TITLE')}</Typography>
            {errorMessage && (
                <Box paddingX={1}>
                    <Typography align="center">{errorMessage}</Typography>
                </Box>
            )}
            <Box paddingTop={5}>
                <ErrorCircle className={classes.icon} color={theme.palette.primary.main} />
            </Box>
            {showRetry && lastRequest && (
                <div className={classes.buttonContainer}>
                    <QuickPayButton fullWidth onClick={handleRetry}>
                        {t('RETRY')}
                    </QuickPayButton>
                </div>
            )}
            {isDefined(payInstead) && onPayInstead && (
                <div className={classes.buttonContainer}>
                    {isDefined(extraTips) && onPayExtraTips && (
                        <QuickPayButton fullWidth onClick={onPayExtraTips}>
                            {t('PAT_QUICKPAY_ERROR_OVERPAYMENT_EXTRA_TIP_BUTTON', {
                                tip: getCurrencyString(extraTips)
                            })}
                        </QuickPayButton>
                    )}
                    <QuickPayButton fullWidth onClick={onPayInstead}>
                        {t('PAT_QUICKPAY_ERROR_OVERPAYMENT_BUTTON', {
                            amount: getCurrencyString(payInstead)
                        })}
                    </QuickPayButton>
                </div>
            )}
        </Box>
    );
};
