import React from 'react';
import { DigitalWalletButtonLegacy } from './DigitalWalletButtonLegacy';
import { DigitalWalletButtonProps, DigitalWalletButtonV2 } from './DigitalWalletButtonV2';

interface IProps extends DigitalWalletButtonProps {
    legacyUi?: boolean;
}

export const DigitalWalletButton: React.FC<IProps> = ({ legacyUi = true, ...props }) => {
    if (legacyUi) {
        return <DigitalWalletButtonLegacy {...props} />;
    }
    return <DigitalWalletButtonV2 {...props} />;
};
