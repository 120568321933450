import { applyMiddleware, combineReducers, createStore, Middleware, Reducer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import reduxThunk from 'redux-thunk';
import ordersReducer, { initialState as ordersInitial, OrdersState } from 'store/orders/ordersReducer';
import authReducer, { AuthState, initialState as authInitial } from './auth/authReducer';
import basketReducer, { BasketState, initialState as basketInitial } from './basket/basketReducer';
import guestReducer, { GuestState, initialState as guestInitial } from './guest/guestReducer';
import layoutReducer, { initialState as layoutInitial, LayoutState } from './layout/layoutReducer';
import locationsReducer, {
    initialState as locationInitial,
    LocationsState
} from './locations/locationReducer';
import menuReducer, { initialState as menuInitial, MenuState } from './menu/menuReducer';
import requestReducer, { initialState as requestInitial, RequestState } from './request/requestReducer';
import settingsReducer, { initialState as settingsInitial, SettingsState } from './settings/settingsReducer';

export interface ApplicationState {
    auth: AuthState;
    locations: LocationsState;
    settings: SettingsState;
    layout: LayoutState;
    menu: MenuState;
    basket: BasketState;
    guest: GuestState;
    request: RequestState;
    orders: OrdersState;
}

const appReducer: Reducer<ApplicationState> = combineReducers<ApplicationState>({
    auth: authReducer,
    locations: locationsReducer,
    settings: settingsReducer,
    layout: layoutReducer,
    menu: menuReducer,
    basket: basketReducer,
    guest: guestReducer,
    request: requestReducer,
    orders: ordersReducer
});

const emptyState: ApplicationState = {
    auth: authInitial,
    locations: locationInitial,
    settings: settingsInitial,
    layout: layoutInitial,
    menu: menuInitial,
    basket: basketInitial,
    guest: guestInitial,
    request: requestInitial,
    orders: ordersInitial
};

const rootReducer: Reducer<ApplicationState> = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === 'DESTROY_SESSION' && !!state) {
        state = { ...emptyState, auth: state.auth };
    }

    return appReducer(state, action);
};

// Init store
export const initStore = (initialState = emptyState) => {
    const middlewares: [Middleware] = [reduxThunk];
    // Client side logger
    if (process.env.NODE_ENV !== 'production') {
        middlewares.push(createLogger());
    }
    return createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middlewares)));
};
