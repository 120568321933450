import * as React from 'react';
import { Box, createStyles, makeStyles, Paper, Theme } from '@material-ui/core';
import color from 'color';
import { IAwardVisualization, IPointAward } from 'components/awards/models';
import { CONTENT_MAX_WIDTH } from 'config/constants';

interface IProps {
    visualization: IAwardVisualization;
    ignoreAlignment: boolean;
    onClick: (award: IPointAward) => void;
    award: IPointAward;
}

const useStyles = ({
    backgroundImageUrl,
    backgroundOverlayColour,
    backgroundOverlayOpacity,
    textColour
}: IAwardVisualization) => {
    const isValidBackground = /^#([0-9A-F]{3}){1,2}$/i.test(backgroundOverlayColour || '');

    return makeStyles((theme: Theme) =>
        createStyles({
            container: {
                position: 'relative',
                borderRadius: theme.shape.borderRadius,
                backgroundImage: `url(${backgroundImageUrl})`,
                backgroundSize: 'cover',
                backgroundPositionY: 'center',
                overflow: 'hidden',
                marginBottom: theme.spacing(2),
                color: textColour
            },
            overlay: {
                backgroundColor: isValidBackground
                    ? color(backgroundOverlayColour)
                          .alpha(backgroundOverlayOpacity || 0)
                          .rgb()
                          .string()
                    : 'initial',
                [theme.breakpoints.down('sm')]: {
                    height: `calc((100vw - ${theme.spacing(6)}px)/3*2)`
                },
                [theme.breakpoints.only('sm')]: {
                    height: `calc((100vw - ${theme.spacing(8)}px)/9*2)`
                },
                [theme.breakpoints.up('md')]: {
                    height: `calc((${CONTENT_MAX_WIDTH}px - ${theme.spacing(10)}px)/12*2)`
                }
            }
        })
    );
};

export const AwardVisualizer: React.FC<React.PropsWithChildren<IProps>> = React.memo(
    ({ children, visualization, ignoreAlignment, onClick, award }) => {
        const classes = useStyles(visualization)();
        const alignItems = React.useMemo(() => {
            if (!ignoreAlignment) {
                switch (visualization.verticalAlignment) {
                    case 'CENTER':
                        return 'center';
                    case 'BOTTOM':
                        return 'flex-end';
                    default:
                        return 'flex-start';
                }
            }
            return 'flex-start';
        }, [ignoreAlignment, visualization.verticalAlignment]);
        const handleClick = React.useCallback(() => onClick(award), [award, onClick]);

        return (
            <Paper elevation={0} onClick={handleClick}>
                <div className={classes.container}>
                    <Box
                        display="flex"
                        padding={2}
                        alignItems={alignItems}
                        textAlign={visualization.textAlignment?.toLowerCase()}
                        className={classes.overlay}
                    >
                        {children}
                    </Box>
                </div>
            </Paper>
        );
    },
    (prevProps, nextProps) => prevProps.onClick === nextProps.onClick
);

AwardVisualizer.displayName = 'AwardVisualizer';
