export enum EDateFormat {
    ISO = 'YYYY-MM-DD'
}

export enum ERequestStatus {
    IDLE = 'IDLE',
    PENDING = 'PENDING',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS'
}

export enum ERouteScenario {
    TABLE = 'pat',
    PREORDER = 'po',
    ORDER_TO_TABLE = 'ott',
    TAB = 'tab',
    PAYATPOS = 'pap'
}
