export enum EOperatingSystem {
    WINDOWS_PHONE,
    IOS,
    MAC,
    ANDROID,
    UNKNOWN
}

export function getOperatingSystem(): EOperatingSystem {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return EOperatingSystem.WINDOWS_PHONE;
    }

    if (/android/i.test(userAgent)) {
        return EOperatingSystem.ANDROID;
    }

    // iOS and Mac detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return EOperatingSystem.IOS;
    }

    if (userAgent.includes('Mac') && !window.MSStream) {
        return EOperatingSystem.MAC;
    }

    return EOperatingSystem.UNKNOWN;
}
