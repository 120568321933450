import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import { ViewBillContentSection } from 'components/bill/ui/ViewBill/ViewBillContentSection';
import { IAdjustmentLocalResource } from 'components/order/model/Order';
import { ViewBasketRewardItem } from './ViewBasketRewardItem';

interface IProps {
    availableRewards: IAdjustmentLocalResource[];
    onRewardSelected: (reward?: IAdjustmentLocalResource) => void;
    selected?: IAdjustmentLocalResource;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        action: {
            fontSize: theme.spacing(2),
            textDecoration: 'none'
        },
        link: {
            color: theme.palette.text.hint
        }
    })
);

export const ViewBasketRewardList: React.FC<IProps> = ({ availableRewards, onRewardSelected, selected }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const selectedAwardFromList = React.useMemo(
        () =>
            availableRewards.find(
                reward =>
                    (reward.awardId !== undefined && reward.awardId === selected?.awardId) ||
                    reward.id === selected?.id
            ),
        [availableRewards, selected?.awardId, selected?.id]
    );
    const renderReward = React.useCallback(
        (reward: IAdjustmentLocalResource) => (
            <ViewBasketRewardItem
                reward={reward}
                onRewardSelected={onRewardSelected}
                key={reward.id || reward.awardId}
            />
        ),
        [onRewardSelected]
    );

    const handleReset = React.useCallback(
        (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            onRewardSelected(undefined);
        },
        [onRewardSelected]
    );
    return availableRewards.length > 0 || !!selected ? (
        <ViewBillContentSection title={t('BILL_CHECKOUT_AVAILABLE_REWARDS')} bottomSpacing={2}>
            {!selectedAwardFromList
                ? availableRewards.map(renderReward)
                : selected && <ViewBasketRewardItem reward={selectedAwardFromList} selected />}
            {!!selectedAwardFromList && (
                <Typography align="center" className={classes.action}>
                    <Link className={classes.link} href="" onClick={handleReset} color="inherit">
                        {t('BILL_CHECKOUT_SELECTED_REWARD_CLEAR_BTN')}
                    </Link>
                </Typography>
            )}
        </ViewBillContentSection>
    ) : null;
};
