import * as React from 'react';
import { useResponsive } from 'src/hooks/useResponsive';

interface IProps {
    renderMobileView: JSX.Element;
    renderTabletView?: JSX.Element;
    renderDesktopView?: JSX.Element;
}

export const ResponsiveLayout: React.FC<IProps> = ({
    renderMobileView,
    renderDesktopView,
    renderTabletView
}) => {
    const { isTablet, isDesktop } = useResponsive();

    const renderView = React.useMemo(() => {
        if (isDesktop && renderDesktopView) {
            return renderDesktopView;
        }
        if (isTablet && renderTabletView) {
            return renderTabletView;
        }

        return renderMobileView;
    }, [isDesktop, isTablet, renderDesktopView, renderMobileView, renderTabletView]);

    return renderView;
};
