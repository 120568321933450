import React from 'react';
import { useSelector } from 'react-redux';
import { ButtonBase, createStyles, Divider, IconButton, makeStyles, Theme, Toolbar } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MenuIcon from '@material-ui/icons/Menu';
import { LoadingTypography } from 'lib/LoadingTypography';
import { isString } from 'lib/typeInference';
import { useLocalHistory } from 'lib/useLocalHistory';
import { ApplicationState } from 'store/store';
import { PayAvatar } from './PayAvatar';
import { PayTableInfo } from './PayTableInfo';

interface PayToolbarProps {
    table?: string | null;
    checkId?: string | null;
    onChangeClick?: () => void;
    disableBack?: boolean;
    onBack?: () => void;
    loading?: boolean;
    showMenu?: boolean;
    onMenuClick?: () => void;
    disableEdit?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontFamily: `TenantFont, ${theme.typography.fontFamily}`,
            fontWeight: 'bold',
            color: theme.palette.titleTextColour,
            height: '30px'
        },
        toolbar: {
            width: '100%',
            margin: '0 auto',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            paddingBottom: theme.spacing(1)
        },
        emptyButton: {
            width: theme.spacing(3),
            height: 1
        },
        iconButton: {
            margin: theme.spacing(-1.5)
        },
        buttonBase: {
            display: 'flex',
            flexDirection: 'column'
        }
    })
);

export const PayToolbar: React.FC<PayToolbarProps> = ({
    table,
    checkId,
    disableBack,
    onBack,
    onChangeClick,
    showMenu,
    onMenuClick,
    disableEdit,
    loading
}) => {
    const classes = useStyles();
    const { history } = useLocalHistory();
    const { currentLocation, isCurrentLocationLoading } = useSelector(
        (state: ApplicationState) => state.locations
    );
    const handleLeftClick = React.useCallback(() => {
        if (typeof onBack === 'function') {
            onBack();
        } else {
            history.goBack();
        }
    }, [onBack, history]);
    const handleChangeClick = React.useCallback(() => {
        if (!disableEdit && onChangeClick) {
            onChangeClick();
        }
    }, [disableEdit, onChangeClick]);
    const isLoading = loading || isCurrentLocationLoading;
    return (
        <React.Fragment>
            <Toolbar className={classes.toolbar} disableGutters variant="dense">
                {!disableBack && (
                    <IconButton onClick={handleLeftClick} className={classes.iconButton}>
                        <ArrowBackIcon color="inherit" />
                    </IconButton>
                )}
                {disableBack &&
                    (showMenu && !!onMenuClick ? (
                        <IconButton onClick={onMenuClick} className={classes.iconButton}>
                            <MenuIcon color="inherit" />
                        </IconButton>
                    ) : (
                        <div className={classes.emptyButton} />
                    ))}
                <ButtonBase
                    className={classes.buttonBase}
                    disableRipple={disableEdit}
                    onClick={handleChangeClick}
                >
                    <LoadingTypography
                        loading={isLoading}
                        align="center"
                        variant="h6"
                        className={classes.title}
                        width={200}
                    >
                        {currentLocation?.title}
                    </LoadingTypography>
                    {(isString(table) || isString(checkId)) && (
                        <PayTableInfo
                            isLoading={isLoading}
                            checkId={checkId}
                            table={table}
                            disableEdit={disableEdit}
                        />
                    )}
                </ButtonBase>
                <PayAvatar className={classes.iconButton} />
            </Toolbar>
            <Divider />
        </React.Fragment>
    );
};
