/* eslint-disable max-len */
import React from 'react';

interface IProps extends Partial<React.SVGProps<SVGSVGElement>> {
    color?: string;
}

export const CheckCircle: React.FC<IProps> = props => (
    <svg width="84" height="83" viewBox="0 0 84 83" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M42 80.8333C64.0914 80.8333 82 63.1859 82 41.4167C82 19.6474 64.0914 2 42 2C19.9086 2 2 19.6474 2 41.4167C2 63.1859 19.9086 80.8333 42 80.8333Z"
            stroke={props.color}
            strokeWidth="4"
        />
        <path
            d="M58.8372 34.6847C60.2573 33.2854 60.2573 31.0165 58.8372 29.6171C57.4171 28.2178 55.1147 28.2178 53.6946 29.6171L35.6955 47.3537C34.2754 48.7531 34.2754 51.022 35.6955 52.4213C37.1156 53.8207 39.418 53.8207 40.8381 52.4213L58.8372 34.6847Z"
            fill={props.color}
        />
        <path
            d="M26.4164 42.0109C27.8334 40.6146 30.1309 40.6146 31.5479 42.0109C31.5498 42.0128 31.5516 42.0146 31.5535 42.0164L38.8877 49.2749C40.3056 50.6781 40.3031 52.9477 38.8822 54.3479C37.4651 55.7443 35.1677 55.7443 33.7506 54.3479C33.7488 54.3461 33.7469 54.3443 33.7451 54.3425L26.4109 47.084C24.9929 45.6807 24.9954 43.4112 26.4164 42.0109Z"
            fill={props.color}
        />
    </svg>
);
