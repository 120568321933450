import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, makeStyles, TextField, Theme } from '@material-ui/core';
import { LoadingTypography } from 'lib/LoadingTypography';
import { useInputState } from 'lib/useInputState';
import { QuickPayButton } from '../ui/PayButton';

interface PayGiftCardFormProps {
    onSubmit?: (value: string) => void;
    loading?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    body: {
        fontSize: '24px',
        fontWeight: 700
    },
    input: {
        padding: theme.spacing(1.5, 2)
    },
    textfield: {
        margin: theme.spacing(3, 0, 0, 0)
    }
}));

export const PayGiftCardForm: React.FC<PayGiftCardFormProps> = ({ onSubmit, loading }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [value, onChange] = useInputState('');
    const handleSubmit = React.useCallback(() => {
        if (onSubmit) {
            onSubmit(value);
        }
    }, [value, onSubmit]);
    return (
        <Box px={1} py={1.5}>
            <Box display="flex" flexDirection="column" alignItems="center">
                <LoadingTypography loading={loading} width={90} className={classes.body}>
                    {t('PAT_QUICKPAY_GIFTCARD_FORM_TITLE')}
                </LoadingTypography>
                <TextField
                    className={classes.textfield}
                    disabled={loading}
                    value={value}
                    onChange={onChange}
                    placeholder={t('CHECKOUT_GIFTCARD_ENTRY_TEXT')}
                    variant="outlined"
                    name="giftcard"
                    fullWidth
                    InputProps={{
                        autoComplete: 'off',
                        autoFocus: true
                    }}
                    inputProps={{ className: classes.input }}
                />
                <QuickPayButton disabled={value.length === 0 || loading} onClick={handleSubmit}>
                    {t('CHECKOUT_GIFTCARD_APPLY_BTN')}
                </QuickPayButton>
            </Box>
        </Box>
    );
};
