import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Link } from 'lib/Link';

interface ErrorLayoutProps {
    description?: string;
    title: string;
    fallbackUrl?: string;
    fallbackTitle?: string;
    errorMessage?: string;
    onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            height: '100%',
            width: '100%',
            padding: theme.spacing(1)
        },
        logo: {
            marginBottom: theme.spacing(3),
            width: theme.spacing(18)
        },
        fallback: {
            textDecoration: 'none',
            display: 'inline-block',
            cursor: 'pointer',
            color: theme.palette.primary.main
        },
        topMargin: {
            marginTop: theme.spacing(1)
        }
    })
);

export const ErrorLayout = (props: ErrorLayoutProps) => {
    const { description, fallbackTitle, fallbackUrl, errorMessage, title, onClick } = props;
    const { t } = useTranslation();
    const classes = useStyles(props);

    return (
        <Grid className={classes.container} direction="column" container justify="center" alignItems="center">
            <Grid item>
                <Typography variant="h4" color="textPrimary">
                    {title}
                </Typography>
            </Grid>
            {description && description.length && (
                <Grid item className={classes.topMargin}>
                    <Typography variant="body1">{description}</Typography>
                </Grid>
            )}
            {errorMessage && errorMessage.length && (
                <Grid item className={classes.topMargin}>
                    <Typography variant="body1" color="error">
                        {errorMessage}
                    </Typography>
                </Grid>
            )}
            {fallbackUrl && (
                <Grid item className={classes.topMargin}>
                    <Typography variant="body1">
                        {t('GENERAL_RETURN_TO')}{' '}
                        <Link to={fallbackUrl} onClick={onClick}>
                            <span className={classes.fallback}>{fallbackTitle}</span>
                        </Link>
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};
