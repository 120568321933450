import { settingsService } from '@pepperhq/location-sdk';
import { HttpClient } from 'components/http/HttpClient';
import logger from 'lib/logger';
import { TenantData } from './model/Settings';

class SettingsApi extends HttpClient {
    constructor() {
        super({
            baseURL: process.env.TENANT_SERVICE_URL,
            url: ''
        });
    }
    public async getTenant(merchantId: string): Promise<TenantData> {
        return this.getRequest({
            url: `/tenants/${merchantId}`,
            headerConfigs: { shouldIncludeAuthToken: false, shouldIncludeXApplicationId: false }
        });
    }

    public getSettings(merchantId: string, locationId?: string): Promise<any> {
        return new settingsService(process.env.TIER).getPublicSettings(
            {
                logger,
                session: { tenantId: merchantId }
            },
            locationId
        );
    }
}

export const settingsApi = new SettingsApi();
