import { ActionCreator } from 'redux';
import { GuestActionTypes } from './guestActionTypes';

interface GuestSetContactInformationAction {
    type: GuestActionTypes.SET_CONTACT_INFORMATION;
    firstName: string;
    lastName: string;
    identity: string;
}

interface GuestResetStateAction {
    type: GuestActionTypes.RESET_GUEST_STATE;
}

export type GuestAction = GuestSetContactInformationAction | GuestResetStateAction;

export const setGuestContactInformation =
    (firstName: string, lastName: string, identity: string) => (dispatch: ActionCreator<GuestAction>) => {
        dispatch({
            firstName,
            lastName,
            identity,
            type: GuestActionTypes.SET_CONTACT_INFORMATION
        });
    };

export const resetGuestState = (dispatch: ActionCreator<GuestAction>) => {
    dispatch({
        type: GuestActionTypes.RESET_GUEST_STATE
    });
};
