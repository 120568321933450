import React from 'react';
import { useSelector } from 'react-redux';
import { IProduct, isProductAvailable, isProductGroup } from 'components/menu/model/Menu';
import { ApplicationState } from 'store/store';

export interface ISearchCategory {
    id: string;
    title: string;
    products: IProduct[];
}

export interface ISearchGroup {
    groupId: string;
    groupTitle: string;
    categories: ISearchCategory[];
}

export interface ISearchState {
    groupsResult: ISearchGroup[] | null;
    categoriesResult: ISearchCategory[] | null;
}

export const useMenuSearch = () => {
    const [{ groupsResult, categoriesResult }, setSearchResult] = React.useState<ISearchState>({
        groupsResult: null,
        categoriesResult: null
    });

    const categories = useSelector((state: ApplicationState) => state.menu.categories);
    const categoryGroups = useSelector((state: ApplicationState) => state.menu.menu?.categoryGroups);
    const showCategoryGroups = useSelector(
        (state: ApplicationState) => state.settings.settings?.app.showCategoryGroups
    );

    const isStringLengthEnough = React.useCallback((searchInput: string) => searchInput.length >= 3, []);

    const searchTags = React.useCallback((product: IProduct, value) => {
        let result = false;

        if (isProductGroup(product)) {
            product.products.forEach(item => {
                if (item?.tags.length > 0) {
                    item.tags.some(tag => {
                        if (tag.title.toLowerCase().includes(value)) {
                            result = true;
                        }
                    });
                }
            });
            return result;
        }

        if (product?.tags.length > 0) {
            product.tags.some(tag => {
                if (tag.title.toLowerCase().includes(value)) {
                    result = true;
                }
            });
            return result;
        }

        return result;
    }, []);

    const searchMenuGroups = React.useCallback(
        (inputValue: string) => {
            const resultGroups: ISearchGroup[] = [];
            const searchValue = inputValue.trim().toLowerCase();

            if (showCategoryGroups && categoryGroups && categories) {
                for (const key in categoryGroups) {
                    if (categoryGroups[key].displayCode !== 'HIDE') {
                        categoryGroups[key].categoryIds.forEach(categoryID => {
                            const foundCategory = categories.find(category => category.id === categoryID);
                            if (foundCategory && foundCategory.visible) {
                                foundCategory.products.forEach((product: IProduct) => {
                                    if (
                                        (!foundCategory.hideUnavailableProducts ||
                                            isProductAvailable(product)) &&
                                        (product.title.toLowerCase().includes(searchValue) ||
                                            searchTags(product, searchValue))
                                    ) {
                                        const groupExist = resultGroups.find(
                                            (group: ISearchGroup) => group.groupId === categoryGroups[key].id
                                        );

                                        if (groupExist) {
                                            const categoryExist = groupExist.categories.find(
                                                (category: ISearchCategory) =>
                                                    category.id === foundCategory.id
                                            );

                                            if (categoryExist) {
                                                categoryExist.products = [...categoryExist.products, product];
                                            } else {
                                                groupExist.categories.push({
                                                    id: foundCategory.id,
                                                    title: foundCategory.title,
                                                    products: [product]
                                                });
                                            }
                                        } else {
                                            resultGroups.push({
                                                groupId: categoryGroups[key].id,
                                                groupTitle: categoryGroups[key].title,
                                                categories: [
                                                    {
                                                        id: foundCategory.id,
                                                        title: foundCategory.title,
                                                        products: [product]
                                                    }
                                                ]
                                            });
                                        }
                                    }
                                });
                            }
                        });
                    }
                }
            }
            return resultGroups;
        },
        [showCategoryGroups, categoryGroups, categories, searchTags]
    );

    const searchMenuCategories = React.useCallback(
        (inputValue: string) => {
            const resultCategories: ISearchCategory[] = [];
            const searchValue = inputValue.trim().toLowerCase();

            if (!showCategoryGroups && categories) {
                categories.forEach(category => {
                    if (category.visible) {
                        category.products.forEach(product => {
                            if (
                                ((!category.hideUnavailableProducts || isProductAvailable(product)) &&
                                    product.title.toLowerCase().includes(searchValue)) ||
                                searchTags(product, searchValue)
                            ) {
                                const existingCategory = resultCategories.find(
                                    (item: ISearchCategory) => item.id === category.id
                                );

                                if (existingCategory) {
                                    existingCategory.products.push(product);
                                } else {
                                    resultCategories.push({
                                        id: category.id,
                                        title: category.title,
                                        products: [product]
                                    });
                                }
                            }
                        });
                    }
                });
            }
            return resultCategories;
        },
        [showCategoryGroups, categories, searchTags]
    );

    const searchMenu = React.useCallback(
        (searchInput: string) => {
            if (showCategoryGroups && isStringLengthEnough(searchInput)) {
                return setSearchResult({
                    categoriesResult: null,
                    groupsResult: searchMenuGroups(searchInput)
                });
            }
            if (!showCategoryGroups && isStringLengthEnough(searchInput)) {
                return setSearchResult({
                    categoriesResult: searchMenuCategories(searchInput),
                    groupsResult: null
                });
            }
            setSearchResult({ categoriesResult: null, groupsResult: null });
        },
        [isStringLengthEnough, searchMenuCategories, searchMenuGroups, showCategoryGroups]
    );

    return {
        searchMenu,
        groupsResult,
        categoriesResult
    };
};
