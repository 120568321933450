/* eslint-disable max-len */
import * as React from 'react';
import { SVGIconProps } from './models';

interface IProps extends SVGIconProps {
    color?: string;
}

export const DineInIcon: React.FC<IProps> = ({ variant = 'overlay', ...props }) =>
    variant === 'overlay' ? (
        <svg width="1em" height="1em" viewBox="0 0 68 69" xmlns="http://www.w3.org/2000/svg" {...props}>
            <title>{'OderTable'}</title>
            <g stroke="inherit" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <path
                    d="M43.263 51.242v12.19c0 1.97-1.608 3.568-3.591 3.568H5.592C3.608 67 2 65.402 2 63.432V5.568C2 3.598 3.609 2 5.591 2h34.043c1.983 0 3.592 1.598 3.592 3.568v2.378"
                    strokeWidth={2.251}
                />
                <path
                    d="M44.263 7.858c-12.027 0-21.775 9.68-21.775 21.62 0 3.264.71 6.342 2.017 9.086l-3.698 12.312 11.429-3.374a21.807 21.807 0 0011.99 3.597c12.026 0 21.774-9.68 21.774-21.62 0-11.942-9.71-21.62-21.737-21.62zM43.263 57.236H2v6.145C2 65.379 3.609 67 5.591 67h34.043c1.983 0 3.592-1.621 3.592-3.62v-6.144h.037zM18 7.998h9.263M18 62.118h9.263"
                    strokeWidth={2.251}
                />
                <g transform="translate(29.79 18.738)" strokeWidth={1.608}>
                    <path d="M5.318 5.738C7.19 2.758 10.524.82 14.306.82a10.6 10.6 0 017.715 3.316M24.418 14.53c-1.349 4.285-5.356 7.415-10.112 7.415a10.653 10.653 0 01-8.988-4.918" />
                    <path d="M0 6.744v7.079h2.472l-.562 6.222a1.727 1.727 0 001.723 1.863h0c.936 0 1.722-.783 1.722-1.714V0h0A6.94 6.94 0 000 6.744zM27.414 5.589V1.714A1.3 1.3 0 0128.724.41h0a1.3 1.3 0 011.311 1.304v4.955c0 .857-.337 1.677-.974 2.31l-1.685 1.64.6 9.65c.074 1.117-.825 2.049-1.948 2.049h0c-1.124 0-1.985-.932-1.947-2.05l.599-9.65-1.686-1.639c-.636-.596-.973-1.453-.973-2.31V1.714A1.3 1.3 0 0123.33.41h0a1.3 1.3 0 011.311 1.304v3.875" />
                    <path d="M24.755 6.408V1.714A1.3 1.3 0 0126.065.41h0a1.3 1.3 0 011.311 1.304v4.694" />
                    <ellipse cx={14.306} cy={11.364} rx={6.329} ry={6.297} />
                </g>
            </g>
        </svg>
    ) : (
        <svg
            fill={props.color || '#000'}
            width="1em"
            height="1em"
            viewBox="0 0 363 206"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <title>{'DineInIcon'}</title>
            <g fill={props.color} stroke="inherit" fillRule="evenodd">
                <path
                    d="M178.194 69.714c-54.257-.35-88.728-10.44-88.728-17.43 0-7.115 35.69-17.448 91.621-17.448 55.929 0 91.622 10.333 91.622 17.448 0 6.99-34.471 17.08-88.728 17.43-.96.006-1.915.018-2.894.018-.975 0-1.93-.012-2.893-.018zm49.632 125.608H183.98V75.5c39.066-.297 94.518-6.589 94.518-23.216 0-17.043-58.255-23.23-97.412-23.23-39.153 0-97.411 6.187-97.411 23.23 0 16.627 55.451 22.919 94.518 23.216v119.822h-43.848a2.894 2.894 0 00-2.893 2.892 2.893 2.893 0 002.893 2.89h93.48c1.6 0 2.896-1.295 2.896-2.89a2.894 2.894 0 00-2.896-2.892h0z"
                    strokeWidth={6}
                />
                <path
                    d="M55.411 124.651c-27.024 0-43.076-4.55-45.393-7.367-.048-.06-.13-.126-.168-.183.039-.06.12-.123.168-.186 2.32-2.815 18.369-7.365 45.393-7.365 27.608 0 43.8 4.754 45.562 7.55-1.762 2.797-17.954 7.551-45.562 7.551zm-45.393-14.4V7.89A2.891 2.891 0 007.124 5a2.89 2.89 0 00-2.893 2.89v107.784A4.946 4.946 0 004 117.101c0 .491.093.962.231 1.424v80.582a2.894 2.894 0 005.787 0V123.95c10.256 4.964 31.38 6.48 45.393 6.48 14.293 0 35.988-1.573 46-6.777v75.454a2.894 2.894 0 005.787 0v-82.006c0-.74-.297-1.397-.753-1.91-3.662-8.959-33.148-11.423-51.034-11.423-14.013 0-35.137 1.52-45.393 6.484zM307.787 123.651c27.025 0 43.076-4.55 45.393-7.367.048-.06.13-.126.168-.183-.039-.06-.12-.123-.168-.186-2.32-2.815-18.368-7.365-45.393-7.365-27.607 0-43.8 4.754-45.562 7.55 1.762 2.797 17.955 7.551 45.562 7.551zm45.393-14.4V6.89A2.891 2.891 0 01356.074 4a2.89 2.89 0 012.893 2.89v107.784c.138.462.231.932.231 1.427 0 .491-.093.962-.231 1.424v80.582a2.894 2.894 0 01-5.787 0V122.95c-10.256 4.964-31.38 6.48-45.393 6.48-14.293 0-35.987-1.573-46-6.777v75.454a2.894 2.894 0 01-5.787 0v-82.006c0-.74.297-1.397.753-1.91 3.662-8.959 33.148-11.423 51.034-11.423 14.013 0 35.137 1.52 45.393 6.484z"
                    strokeWidth={7}
                />
            </g>
        </svg>
    );
