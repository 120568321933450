import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, createStyles, makeStyles, MenuItem, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useResponsive } from 'src/hooks/useResponsive';
import { SuggestionsProvider } from 'src/hooks/useSuggestionsContext';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { MenuSearchButton } from 'components/menu/MenuSearchButton';
import { MenuSearch } from 'components/menu/MenuSearchDialog';
import { isDefined, isEmptyString, isNotNull } from 'lib/typeInference';
import { useLocalHistory } from 'lib/useLocalHistory';
import { setBasketTimeslot } from 'store/basket/basketActions';
import { ApplicationState } from 'store/store';
import { getDay, getTimeslotTimes } from '../helpers';
import { Timeslot } from '../model/Timeslot';
import { TimeslotPickerDialog } from './TimeslotPickerDialog';
import { useQuery } from 'src/hooks/useQuery';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        confirmButton: {
            marginTop: theme.spacing(1),
            padding: theme.spacing(1)
        },
        editText: {
            color: theme.palette.primary.contrastText
        },
        textBold: {
            fontWeight: 700
        },
        noPadding: {
            padding: theme.spacing(0)
        },
        textColor: {
            color: theme.palette.background.default
        },
        rootBox: ({ isDesktop }: { isDesktop: boolean }) => ({
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            backgroundColor: theme.palette.titleTextColour,
            borderRadius: isDesktop ? theme.shape.borderRadius : 0
        }),
        lineHeightReset: {
            lineHeight: '1'
        },
        collectionFontSize: {
            fontSize: '1.125rem'
        }
    })
);

export const TimeslotPicker: React.FC = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { push } = useLocalHistory();
    const { isDesktop } = useResponsive();
    const classes = useStyles({ isDesktop });
    const dispatch = useDispatch();
    const { state: locationState } = useLocation<{ isTimeslotPickerShown: boolean }>();
    const { isCurrentLocationLoading } = useSelector((state: ApplicationState) => state.locations);
    const { timeslots, settings } = useSelector((state: ApplicationState) => state.settings);
    const { timeSlot, prepTimePadding } = useSelector((state: ApplicationState) => state.basket);
    const { isLocationOpen, isLocationOrderEnabled } = useLocationHelpers();
    const currentSearch = useQuery('search');
    const [openMenuSearch, setOpenMenuSearch] = React.useState(() => {
        if (isNotNull(currentSearch) && isDefined(currentSearch) && !isEmptyString(currentSearch)) {
            return true;
        }
        return false;
    });

    const handleCloseMenuSearch = React.useCallback(() => {
        setOpenMenuSearch(false);
    }, []);
    const handleOpenMenuSearch = React.useCallback(() => {
        setOpenMenuSearch(true);
    }, []);

    const formattedTimeslots = React.useMemo(
        () =>
            timeslots
                ? timeslots.reduce<Record<string, Timeslot[]>>((acc, item) => {
                      const currentDay = getDay(item.start);
                      if (!Array.isArray(acc[currentDay])) {
                          acc[currentDay] = [item];
                      } else {
                          acc[currentDay].push(item);
                      }
                      return acc;
                  }, {})
                : {},
        [timeslots]
    );
    const noAsap = React.useMemo(
        () => (!settings?.asapOrderingEnabled || !isLocationOpen) && settings?.timeSlotsEnabled,
        [settings?.asapOrderingEnabled, settings?.timeSlotsEnabled, isLocationOpen]
    );
    const description = React.useMemo(() => {
        if (!!timeSlot) {
            return getTimeslotTimes(timeSlot, t, true);
        }
        const timePadding = prepTimePadding ?? 0;
        return `${t('ORDER_STATUS_ORDER_READY_IN')} ${t('ORDER_STATUS_ORDER_READY_IN_MINS', {
            from: (settings?.preOrder?.readyPeriodStart || 5) + timePadding,
            to: (settings?.preOrder?.readyPeriodEnd || 20) + timePadding
        })}`;
    }, [
        t,
        timeSlot,
        settings?.preOrder?.readyPeriodStart,
        settings?.preOrder?.readyPeriodEnd,
        prepTimePadding
    ]);
    const [open, setOpen] = React.useState(
        () => !!(!isDefined(locationState?.isTimeslotPickerShown) && settings?.timeSlotsEnabled)
    );
    const handleEdit = React.useCallback(() => {
        if (!!timeslots && !!timeslots.length && !!settings?.timeSlotsEnabled && !!isLocationOrderEnabled) {
            setOpen(true);
        }
    }, [timeslots, settings?.timeSlotsEnabled, isLocationOrderEnabled]);
    const handleClose = React.useCallback(() => {
        setOpen(false);
        push(pathname, {}, '', { isTimeslotPickerShown: true });
    }, [pathname, push]);
    const handleSubmit = React.useCallback(
        (data?: { day: string; time: string }) => {
            setBasketTimeslot(data ? formattedTimeslots[data.day][Number(data.time)] : undefined)(dispatch);
            handleClose();
        },
        [handleClose, formattedTimeslots, dispatch]
    );
    React.useEffect(() => {
        if (noAsap && !timeSlot && timeslots && timeslots.length) {
            setBasketTimeslot(timeslots[0])(dispatch);
        }
    }, [noAsap, timeSlot, timeslots, dispatch]);
    if (
        isCurrentLocationLoading ||
        !settings ||
        (settings.timeSlotsEnabled && (!timeslots || !timeslots.length))
    ) {
        return null;
    }
    // TODO:
    // !!timeslots && !!timeslots.length && !!settings.timeSlotsEnabled ? true : undefined
    // This is due to the strange material ui typings, I can do nothing with that
    return (
        <Box className={classes.rootBox}>
            <MenuItem
                button={!!timeslots && !!timeslots.length && !!settings.timeSlotsEnabled ? true : undefined}
                onClick={handleEdit}
            >
                <Box
                    display="flex"
                    flex={1}
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box display="flex" flexDirection="column" paddingTop={1} paddingBottom={1}>
                        <Typography
                            className={clsx(
                                classes.textColor,
                                classes.textBold,
                                classes.lineHeightReset,
                                classes.collectionFontSize
                            )}
                        >
                            {t('MENU_COLLECTION')}
                        </Typography>
                        <Box display="flex" alignItems="center" className={classes.lineHeightReset}>
                            <Box>
                                <Typography
                                    className={clsx(classes.textColor, classes.lineHeightReset)}
                                    variant="caption"
                                >
                                    {description}
                                </Typography>
                            </Box>
                            {settings.timeSlotsEnabled && (
                                <Box marginLeft={2.5}>
                                    <Typography
                                        className={clsx(
                                            classes.editText,
                                            classes.textBold,
                                            classes.lineHeightReset
                                        )}
                                        variant="caption"
                                    >
                                        {t('GENERAL_EDIT')}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </MenuItem>
            <TimeslotPickerDialog
                open={open}
                noAsap={noAsap}
                onClose={handleClose}
                onSubmit={handleSubmit}
                options={formattedTimeslots}
                timeslot={timeSlot}
            />
            {!!openMenuSearch && (
                <SuggestionsProvider>
                    <MenuSearch open={openMenuSearch} onClose={handleCloseMenuSearch} />
                </SuggestionsProvider>
            )}
            <MenuSearchButton handleOpenMenuSearch={handleOpenMenuSearch} />
        </Box>
    );
};
