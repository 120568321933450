import { ActionCreator } from 'redux';
import { resetLocalBasket } from 'components/basket/localStore';
import { IAdjustmentReadResourceV10, IBasketItem } from 'components/basket/model/Basket';
import { IEnrichedProduct } from 'components/menu/model/Menu';
import { Timeslot } from 'components/timeslots/model/Timeslot';
import { isDefined, isNumber } from 'lib/typeInference';
import { ApplicationState } from 'store/store';
import { BasketActionTypes } from './basketActionTypes';
import { BasketState } from './basketReducer';

interface BasketAddItemAction {
    type: BasketActionTypes.ADD_ITEM;
    item: IBasketItem & { price: number };
}

interface BasketAddItemsAction {
    type: BasketActionTypes.ADD_ITEMS;
    items: (IBasketItem & { price: number })[];
}

interface BasketUpdateQuantityAction {
    type: BasketActionTypes.UPDATE_QUANTITY;
    itemIndex: number;
    quantity: number;
}

interface BasketRemoveItemAction {
    type: BasketActionTypes.REMOVE_ITEM;
    itemIndex: number;
}

interface BasketSetBasketAction {
    type: BasketActionTypes.SET_BASKET;
    basket: BasketState;
}

interface BasketClaimReward {
    type: BasketActionTypes.ADD_AWARD_ADJUSTMENT;
    award: IAdjustmentReadResourceV10;
}

interface BasketRemoveReward {
    type: BasketActionTypes.REMOVE_REWARD;
    rewardId: string;
}

interface BasketResetReward {
    type: BasketActionTypes.RESET_REWARDS;
    rewardId: string;
}

interface BasketSetLocationIdAction {
    type: BasketActionTypes.SET_BASKET_LOCATION_ID;
    locationId: string;
}

interface BasketResetAction {
    type: BasketActionTypes.RESET_BASKET;
}

interface BasketSetDeliveryLocation {
    type: BasketActionTypes.SET_DELIVERY_LOCATION;
    deliveryLocation?: string;
}

interface BasketSetCheckId {
    type: BasketActionTypes.SET_CHECK_ID;
    checkId?: string;
}

interface BasketSetTimeslot {
    type: BasketActionTypes.SET_TIMESLOT;
    timeslot?: Timeslot;
}

interface BasketSetNote {
    type: BasketActionTypes.SET_NOTE;
    title: string;
    note?: string;
}

interface BasketSetTimePadding {
    type: BasketActionTypes.SET_PREP_TIME_PADDING;
    prepTimePadding?: number;
}
interface BasketSetSugegstionsShowed {
    type: BasketActionTypes.BASKET_SET_SUGGESTIONS_SHOWED;
}
export type BasketAction =
    | BasketAddItemAction
    | BasketAddItemsAction
    | BasketUpdateQuantityAction
    | BasketRemoveItemAction
    | BasketSetBasketAction
    | BasketSetLocationIdAction
    | BasketResetAction
    | BasketClaimReward
    | BasketRemoveReward
    | BasketResetReward
    | BasketSetDeliveryLocation
    | BasketSetTimeslot
    | BasketSetCheckId
    | BasketSetNote
    | BasketSetTimePadding
    | BasketSetSugegstionsShowed;

export const resetAwards = (dispatch: ActionCreator<BasketAction>) => {
    dispatch({
        type: BasketActionTypes.RESET_REWARDS
    });
};

export const addBasketItem =
    (
        item: IEnrichedProduct,
        categoryId: string,
        quantity: number,
        modifiers: IBasketItem['modifiers'],
        pricePerItem: number,
        referenceId?: string
    ) =>
    (dispatch: ActionCreator<BasketAction>) => {
        const basketItem: IBasketItem & { price: number } = {
            categoryId,
            quantity,
            modifiers,
            referenceId,
            productId: item.id,
            price: pricePerItem
        };
        resetAwards(dispatch);
        dispatch({
            type: BasketActionTypes.ADD_ITEM,
            item: basketItem
        });
    };

export const addBasketItems = (items: IBasketItem[]) => (dispatch: ActionCreator<BasketAction>) => {
    resetAwards(dispatch);
    dispatch({
        items,
        type: BasketActionTypes.ADD_ITEMS
    });
};

export const claimRewardItems =
    (award: IAdjustmentReadResourceV10) => (dispatch: ActionCreator<BasketAction>) => {
        dispatch({
            award,
            type: BasketActionTypes.ADD_AWARD_ADJUSTMENT
        });
    };

export const removeRewardItems = (rewardId: string) => (dispatch: ActionCreator<BasketAction>) => {
    dispatch({
        type: BasketActionTypes.REMOVE_REWARD,
        rewardId
    });
};

export const removeBasketItem = (itemIndex: number) => (dispatch: ActionCreator<BasketAction>) => {
    resetAwards(dispatch);
    dispatch({
        itemIndex,
        type: BasketActionTypes.REMOVE_ITEM
    });
};

export const updateBasketItemQuantity =
    (itemIndex: number, quantity: number) => (dispatch: ActionCreator<BasketAction>) => {
        resetAwards(dispatch);
        dispatch({
            itemIndex,
            quantity,
            type: BasketActionTypes.UPDATE_QUANTITY
        });
    };

export const setBasket = (basket: Partial<BasketState>) => (dispatch: ActionCreator<BasketAction>) => {
    if (isDefined(basket.locationId)) {
        dispatch({ type: BasketActionTypes.SET_BASKET_LOCATION_ID, locationId: basket.locationId });
    }
    dispatch({
        basket,
        type: BasketActionTypes.SET_BASKET
    });
};

export const resetBasket = (dispatch: ActionCreator<BasketAction>) => {
    dispatch({
        type: BasketActionTypes.RESET_BASKET
    });
    resetLocalBasket();
};

export const setDeliveryLocation = (deliveryLocation?: string) => (dispatch: ActionCreator<BasketAction>) => {
    dispatch({
        deliveryLocation,
        type: BasketActionTypes.SET_DELIVERY_LOCATION
    });
};

export const setCheckNumber = (checkId?: string) => (dispatch: ActionCreator<BasketAction>) => {
    dispatch({
        checkId,
        type: BasketActionTypes.SET_CHECK_ID
    });
};

export const setBasketTimeslot = (timeslot?: Timeslot) => (dispatch: ActionCreator<BasketSetTimeslot>) => {
    dispatch({
        timeslot,
        type: BasketActionTypes.SET_TIMESLOT
    });
};

export const setBasketNote = (title: string, note: string) => (dispatch: ActionCreator<BasketSetNote>) => {
    dispatch({
        title,
        note,
        type: BasketActionTypes.SET_NOTE
    });
};

export const setBasketPrepTime =
    (prepTimePadding?: number) => (dispatch: ActionCreator<BasketSetTimePadding>) => {
        dispatch({
            prepTimePadding,
            type: BasketActionTypes.SET_PREP_TIME_PADDING
        });
    };

export const updateTimePadding =
    () => (dispatch: ActionCreator<BasketSetTimePadding>, getState: () => ApplicationState) => {
        const { menu, basket } = getState();
        const { items, prepTimePadding } = basket;
        let newPadding = prepTimePadding;
        const productById = menu.menu?.productIdToProduct;
        if (isDefined(newPadding) && !!productById) {
            newPadding = 0;
            items.forEach(item => {
                const product = productById.get(item.productId);
                if (
                    isDefined(product) &&
                    isNumber(product.prepTimeMins) &&
                    product.prepTimeMins > Number(newPadding)
                ) {
                    newPadding = product.prepTimeMins;
                }
            });
            if (newPadding > 0) {
                return dispatch({
                    prepTimePadding: newPadding,
                    type: BasketActionTypes.SET_PREP_TIME_PADDING
                });
            }
        }
        dispatch({
            prepTimePadding: undefined,
            type: BasketActionTypes.SET_PREP_TIME_PADDING
        });
    };

export const setSuggestionsShowed = () => (dispatch: ActionCreator<BasketSetSugegstionsShowed>) => {
    dispatch({
        type: BasketActionTypes.BASKET_SET_SUGGESTIONS_SHOWED
    });
};
