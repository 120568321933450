import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import * as Yup from 'yup';
import { MerchantTitle } from 'app/MerchantTitle';
import { OrderScenario } from 'components/order/model/Order';
import { getLocalMerchantId } from 'components/settings/localStore';
import { getOnCloseRedirectUrl, getUserId } from 'components/user/localAuth';
import { CredentialProvider } from 'components/user/model/User';
import { userApi } from 'components/user/userApi';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { TextFormField } from 'lib/form/TextFormField';
import logger from 'lib/logger';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { updateUser } from 'store/auth/authActions';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/LoadingButton';
import { ROUTES } from './routes';

interface EmailFormData {
    email: string;
    emailConfirmation: string;
}

const initialValues: EmailFormData = {
    email: '',
    emailConfirmation: ''
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            paddingTop: theme.spacing(4)
        },
        button: {
            height: theme.spacing(6),
            fontSize: '1.2em'
        }
    })
);

export const EmailCapturePage: React.FC = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { getParsedPath, push } = useLocalHistory();
    const { id, user } = useSelector((state: ApplicationState) => state.auth);
    const { locationId } = useSelector((state: ApplicationState) => state.basket);
    const [scenario] = useRouteScenario();
    const [credentialsError, setCredentialsError] = React.useState('');
    const { isGuest, isLoggedIn } = useAuth();
    const emailValidation = React.useMemo(
        () =>
            Yup.object<EmailFormData>().shape({
                email: Yup.string()
                    .required(t('FORMS_VALIDATION_REQUIRED'))
                    .email(t('FORMS_VALIDATION_EMAIL')),
                emailConfirmation: Yup.string()
                    .oneOf([Yup.ref('email')], t('ONBOARDING_SIGNUP_ERROR_REPEAT_EMAIL_DONT_MATCH'))
                    .required(t('FORMS_VALIDATION_REQUIRED'))
            }),
        [t]
    );
    const userId = React.useMemo(() => id || getUserId(), [id]);
    const handleSubmit = React.useCallback(
        async (data: EmailFormData) => {
            if (!!user) {
                try {
                    const credentialData = await userApi.createCredential(
                        data.email,
                        CredentialProvider.EMAIL,
                        true
                    );
                    dispatch(updateUser({ ...user, email: credentialData.id }));
                } catch (e) {
                    setCredentialsError(e?.data?.message || t('GENERAL_ERROR_PROCESSING_REQUEST'));
                    logger.error(e);
                }
            }
        },
        [dispatch, t, user]
    );
    React.useEffect(() => {
        if (!!user?.email) {
            const merchantId = getLocalMerchantId();
            if (merchantId) {
                const returnUrl = getOnCloseRedirectUrl(merchantId);
                if (returnUrl) {
                    const [path, search] = returnUrl.split('?');
                    push(path, {}, search);
                } else if (scenario === OrderScenario.TABLE) {
                    push(ROUTES.JOURNEY.LANDING, { merchantId, locationId });
                } else {
                    push(ROUTES.BASE);
                }
            }
        }
    }, [user, push, isGuest, isLoggedIn, scenario, locationId, user?.email]);
    if (!userId) {
        return <Redirect to={getParsedPath(ROUTES.USER.LOGIN)} />;
    }

    return (
        <div>
            <MerchantTitle title={t('ONBOARDING_SIGNUP_ADDITIONAL_EMAIL_TITLE')} />
            <MuiAppBar title={t('ONBOARDING_SIGNUP_ADDITIONAL_EMAIL_TITLE')} disableBack />
            <Formik initialValues={initialValues} validationSchema={emailValidation} onSubmit={handleSubmit}>
                {({ submitForm, isSubmitting, isValid, values: { email, emailConfirmation } }) => (
                    <Form className={classes.root}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>{t('ONBOARDING_SIGNUP_ADDITIONAL_EMAIL_MESSAGE')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextFormField
                                    name="email"
                                    type="email"
                                    label={t('ONBOARDING_SIGNUP_ADDITIONAL_EMAIL')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextFormField
                                    name="emailConfirmation"
                                    type="email"
                                    label={t('ONBOARDING_SIGNUP_ADDITIONAL_CONFIRM_EMAIL')}
                                />
                            </Grid>
                            {!!credentialsError && (
                                <Grid item xs={12}>
                                    <Typography color="error">{credentialsError}</Typography>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                        !isValid ||
                                        !email ||
                                        !emailConfirmation ||
                                        isSubmitting ||
                                        email !== emailConfirmation
                                    }
                                    fullWidth
                                    onClick={submitForm}
                                    className={classes.button}
                                    loading={isSubmitting}
                                >
                                    {t('BUTTON_SUBMIT')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </div>
    );
};
