import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, createStyles, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { StripeCardProvider } from 'src/integrations/PaymentProvider/Stripe/StripeCardProvider';
import { EOperatingSystem, getOperatingSystem } from 'src/utils/device';
import { OrderPaymentType } from 'components/order/model/Order';
import { EPaymentProvider } from 'components/settings/model/Settings';
import { ApplicationState } from 'store/store';
import { usePaymentProvider } from './hooks/usePaymentProvider';
import { DigitalWalletButton } from './ui/DigitalWalletButton';

interface IProps {
    total: number;
    onDigitalPaymentMade: (cardNonce: string, walletType: OrderPaymentType) => void;
    disabled?: boolean;
    selectedWallet?: OrderPaymentType;
    legacyUi?: boolean;
    children?: React.ReactNode;
}

const useStyles = makeStyles(() =>
    createStyles({
        hidden: {
            display: 'none'
        }
    })
);
export const DigitalWallets: React.FC<IProps> = ({
    total,
    onDigitalPaymentMade,
    disabled,
    selectedWallet,
    legacyUi = true,
    children
}) => {
    const classes = useStyles();
    const settings = useSelector((state: ApplicationState) => state.settings.settings);
    const os = React.useMemo(() => getOperatingSystem(), []);
    const order = React.useMemo(
        () =>
            settings && {
                total: total.toString(),
                label: settings.title,
                currencyCode: settings.region.currencyCode,
                // TODO: shouldn't we use different settings for different payment methods?
                countryCode: (settings.applePay?.countryCode || settings.googlePay?.countryCode) ?? 'GB'
            },
        [settings, total]
    );
    const isProviderStripe = React.useMemo(
        () =>
            !!settings &&
            settings.paymentProvider === EPaymentProvider.STRIPE &&
            !!settings.stripe.publishableKey,
        [settings]
    );
    const { walletsAvailability, isWalletsLoading, isProviderLoading, stripePaymentRequest, squareDigital } =
        usePaymentProvider({ type: 'digitalWallet', order }, undefined, onDigitalPaymentMade);
    const handleGooglePay = React.useCallback(async () => {
        if (squareDigital && squareDigital.googlePay) {
            const result = await squareDigital.googlePay.tokenize();
            if (result.token && result.status === 'OK') {
                onDigitalPaymentMade(result.token, OrderPaymentType.GOOGLEPAY);
            }
        }
    }, [onDigitalPaymentMade, squareDigital]);
    const handleApplePay = React.useCallback(async () => {
        if (squareDigital && squareDigital.applePay) {
            const result = await squareDigital.applePay.tokenize();
            if (result.token && result.status === 'OK') {
                onDigitalPaymentMade(result.token, OrderPaymentType.APPLEPAY);
            }
        }
    }, [onDigitalPaymentMade, squareDigital]);

    return (
        <>
            {legacyUi &&
                (isWalletsLoading || isProviderLoading) &&
                (settings?.applePayEnabled || settings?.googlePayEnabled) && (
                    <Box display="flex" width="100%" marginBottom={1} justifyContent="center">
                        <CircularProgress size={20} />
                    </Box>
                )}
            <Grid
                item
                xs={12}
                className={clsx({
                    [classes.hidden]:
                        !walletsAvailability.googlePay ||
                        (legacyUi && (isWalletsLoading || isProviderLoading)) ||
                        !settings?.googlePayEnabled ||
                        (!!selectedWallet && selectedWallet !== OrderPaymentType.GOOGLEPAY)
                })}
            >
                {isProviderStripe ? (
                    <StripeCardProvider>
                        <DigitalWalletButton
                            stripePaymentRequest={stripePaymentRequest}
                            walletType={OrderPaymentType.GOOGLEPAY}
                            id="google-pay"
                            disabled={disabled}
                            hidden={os === EOperatingSystem.IOS}
                            legacyUi={legacyUi}
                        >
                            {children}
                        </DigitalWalletButton>
                    </StripeCardProvider>
                ) : (
                    <DigitalWalletButton
                        walletType={OrderPaymentType.GOOGLEPAY}
                        id="google-pay-button"
                        disabled={disabled}
                        hidden={os === EOperatingSystem.IOS}
                        onClick={handleGooglePay}
                        legacyUi={legacyUi}
                    >
                        {children}
                    </DigitalWalletButton>
                )}
            </Grid>
            <Grid
                item
                xs={12}
                className={clsx({
                    [classes.hidden]:
                        !walletsAvailability.applePay ||
                        (legacyUi && (isWalletsLoading || isProviderLoading)) ||
                        !settings?.applePayEnabled ||
                        (!!selectedWallet && selectedWallet !== OrderPaymentType.APPLEPAY)
                })}
            >
                {isProviderStripe ? (
                    <StripeCardProvider>
                        <DigitalWalletButton
                            stripePaymentRequest={stripePaymentRequest}
                            walletType={OrderPaymentType.APPLEPAY}
                            id="apple-pay-button"
                            disabled={disabled}
                            hidden={os !== EOperatingSystem.IOS && os !== EOperatingSystem.MAC}
                            legacyUi={legacyUi}
                        >
                            {children}
                        </DigitalWalletButton>
                    </StripeCardProvider>
                ) : (
                    <DigitalWalletButton
                        walletType={OrderPaymentType.APPLEPAY}
                        id="apple-pay"
                        disabled={disabled}
                        hidden={os !== EOperatingSystem.IOS && os !== EOperatingSystem.MAC}
                        onClick={handleApplePay}
                        legacyUi={legacyUi}
                    >
                        {children}
                    </DigitalWalletButton>
                )}
            </Grid>
        </>
    );
};

DigitalWallets.displayName = 'DigitalWallets';
