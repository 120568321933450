import * as React from 'react';

interface IProps
    extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    type: 'AMEX' | 'MASTER' | 'VISA' | 'APPLEPAY' | 'GOOGLEPAY' | string;
}

export const PaymentMethodIcon: React.FC<IProps> = ({ type, width = '50px', src, ...rest }) => {
    const iconSrc = React.useMemo(() => {
        const imagesUrl = `${process.env.PUBLIC_URL}/images/payment`;
        switch (type) {
            case 'AMEX':
                return `${imagesUrl}/amex.svg`;
            case 'VISA':
                return `${imagesUrl}/visa.svg`;
            case 'APPLEPAY':
                return `${imagesUrl}/apple-pay.svg`;
            case 'GOOGLEPAY':
                return `${imagesUrl}/google-pay.svg`;
            case 'MASTERCARD':
                return `${imagesUrl}/mastercard.svg`;
            default:
                break;
        }
    }, [type]);

    return <img src={src || iconSrc} width={width} {...rest} />;
};
