import * as React from 'react';
import { Box, createStyles, ListItem, makeStyles, Radio, Theme, Typography } from '@material-ui/core';
import { IAdjustmentReadResourceV10 } from 'components/basket/model/Basket';
import { addOpacity } from 'lib/helpers';
import { useCurrencyString } from 'lib/useCurrencyString';
import { IAdjustmentLocalResource } from 'components/order/model/Order';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rewardPrice: {
            color: addOpacity(theme.palette.text.primary, 0.6)
        }
    })
);

interface IProps {
    reward: IAdjustmentReadResourceV10;
    onRewardSelected?: (reward?: IAdjustmentReadResourceV10 | IAdjustmentLocalResource) => void;
    selected?: boolean;
}

export const ViewBillCheckoutRewardItem: React.FC<IProps> = ({ reward, onRewardSelected, selected }) => {
    const classes = useStyles();
    const getCurrencyString = useCurrencyString();
    const rewardPrice = React.useMemo(
        () => (reward.quantity ? reward.quantity * reward.value : reward.value),
        [reward.quantity, reward.value]
    );
    const handleRewardClick = React.useCallback(
        () => onRewardSelected && onRewardSelected(reward),
        [onRewardSelected, reward]
    );
    return (
        <ListItem disableGutters button onClick={handleRewardClick}>
            <Box display="flex" alignItems="center" width="100%">
                <Box display="flex" flexDirection="column" marginRight="auto">
                    <Typography>{reward.description}</Typography>
                    {!selected && (
                        <Typography className={classes.rewardPrice}>
                            {getCurrencyString(rewardPrice)}
                        </Typography>
                    )}
                </Box>
                {!selected ? (
                    <Radio checked={false} />
                ) : (
                    <Typography>{getCurrencyString(rewardPrice)}</Typography>
                )}
            </Box>
        </ListItem>
    );
};
