import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Paper } from '@material-ui/core';
import { isSuccess } from 'src/utils/request';
import { ErrorLayout } from 'app/ErrorLayout';
import { PayError } from 'components/pay/errors/PayError';
import { PayHeader } from 'components/pay/header/PayHeader';
import { PayToolbar } from 'components/pay/header/PayToolbar';
import { useGiftCardModal } from 'components/pay/hooks/useGiftCardModal';
import { usePayActions } from 'components/pay/hooks/usePayActions';
import { usePayAtTable } from 'components/pay/hooks/usePayAtTable';
import { usePayQueryParams } from 'components/pay/hooks/usePayDateCheckId';
import { usePaymentMethods } from 'components/pay/hooks/usePaymentMethods';
import { usePayMyOrder } from 'components/pay/hooks/usePayMyOrder';
import { useOrderTips } from 'components/order/hooks/useOrderTips';
import { PayActionModal } from 'components/pay/modals/PayActionsModal';
import { PayBillSplittingModal } from 'components/pay/modals/PayBillSplittingModal';
import { PayGiftCardModal } from 'components/pay/modals/PayGiftCardModal';
import { PayTableSelector } from 'components/pay/modals/PayTableSelector';
import {
    getPaySession,
    getPaySessionSplits,
    setPaySession,
    setPaySessionSplits
} from 'components/pay/model/helpers';
import { PayItems } from 'components/pay/order-item/PayItems';
import { PayItemsHeader } from 'components/pay/order-item/PayItemsHeader';
import { PaymentButtons } from 'components/pay/payment-method/PaymentButtons';
import { PaymentMethods } from 'components/pay/payment-method/PaymentMethods';
import { PaymentBreakdown } from 'components/pay/PaymentBreakdown';
import { PayRewards } from 'components/pay/rewards/PayRewards';
import { PayTips } from 'components/pay/tips/PayTips';
import { PayLinkButton } from 'components/pay/ui/PayLinkButton';
import { ConfirmDialog } from 'lib/ConfirmDialog';
import { isDefined, isEmptyString, isNumber } from 'lib/typeInference';
import { useLocalHistory } from 'lib/useLocalHistory';
import { LocationRouteParams } from 'pages/routes';
import { setBasket } from 'store/basket/basketActions';
import { ApplicationState } from 'store/store';
import { useBillSplitting } from 'components/pay/hooks/useBillSplitting';
import { clearSessionStorage, roundToDecimal } from 'lib/helpers';
import { ExistingSharedOrderData, OrderScenario } from 'components/order/model/Order';
import { useOrderRewards } from 'components/order/hooks/useOrderRewards';
import { useGiftCards } from 'components/order/hooks/useGiftCards';
import { useOrderGiftcardCalculator } from 'components/order/hooks/usePayGiftcardCalculator';
import { useTransactionLimitValidation } from 'components/basket/hooks/useTransactionLimitValidation';

export const PAY_MAX_WIDTH = '550px';

export const PayPage = () => {
    const { merchantId, locationId } = useParams<LocationRouteParams>();
    const { checkId, tableNumber } = usePayQueryParams();
    const { replace } = useLocalHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [openTableSelector, setOpenTableSelector] = React.useState(
        !isDefined(tableNumber) && !isDefined(checkId)
    );
    const [openBillSplitting, setOpenBillSplitting] = React.useState(false);
    const [existingParams, setExistingParams] = React.useState<ExistingSharedOrderData>(
        () => getPaySession(merchantId, locationId, checkId, tableNumber) ?? {}
    );
    const { isLoading: settingsLoading } = useSelector((state: ApplicationState) => state.settings);
    const { isLoading: layoutLoading } = useSelector((state: ApplicationState) => state.layout);
    const { currentLocation, isCurrentLocationLoading } = useSelector(
        (state: ApplicationState) => state.locations
    );
    const { selectedPaymentMethod, onAddNewCardClick, ...paymentMethodsProps } = usePaymentMethods();
    const { orderDetails, isLoading: isOrderLoading, isOrderNotFound, operationError } = usePayAtTable();

    const { splitShares, yourShares, handleSplit, customSplit } = useBillSplitting(
        orderDetails?.data?.splitTotal?.splitsToPay
    );

    const balance = React.useMemo(() => {
        if (!orderDetails?.data?.total) {
            return 0;
        }
        const shouldUseBalanceToPay = orderDetails?.data?.total.total > orderDetails?.data?.total.balance;
        const orderPrice = shouldUseBalanceToPay
            ? orderDetails?.data?.total.balance
            : orderDetails?.data?.total.total;
        return orderPrice ?? 0;
    }, [orderDetails?.data?.total]);

    const totalAfterSplit = React.useMemo(() => {
        if (!balance) {
            return 0;
        }
        if (customSplit) {
            if (customSplit > balance || Math.abs((balance ?? 0) - customSplit) <= 0.1) {
                return balance;
            }
            return customSplit;
        }
        if (yourShares && splitShares) {
            let dividedTotal = (balance / splitShares) * yourShares;
            if (roundToDecimal(dividedTotal, 3) - roundToDecimal(dividedTotal, 2) > 0) {
                dividedTotal = roundToDecimal(dividedTotal, 2) + 0.01;
            }
            if (Math.abs((balance ?? 0) - dividedTotal) <= 0.1) {
                dividedTotal = balance ?? 0;
            }
            return roundToDecimal(dividedTotal, 2);
        }
        return balance;
    }, [balance, customSplit, splitShares, yourShares]);

    const { availableRewards, selectedReward, handleSetReward, handleClearRewards, rewardsEnabled } =
        useOrderRewards(orderDetails?.data?.availableAdjustments, totalAfterSplit, existingParams);

    const totalAfterReward = React.useMemo(() => {
        const afterRewardIsApplied = totalAfterSplit + (selectedReward?.totalValue ?? 0);
        if (afterRewardIsApplied < 0) {
            return 0;
        }
        return afterRewardIsApplied;
    }, [selectedReward?.totalValue, totalAfterSplit]);

    const {
        giftCard,
        giftCardBalance,
        giftCardError,
        giftCardLoading,
        submitGiftCard,
        resetGiftCard,
        resetGiftCardErrors
    } = useGiftCards(existingParams?.giftCard);
    const { tipValue, onTipChange, tippingSettings, selectedTip } = useOrderTips(
        OrderScenario.TABLE,
        totalAfterReward,
        existingParams
    );
    const { isGiftCardsEnabled, showGiftCardModal, handleCloseGiftCard, handleOpenGiftCard } =
        useGiftCardModal();
    const { giftCardPayment, totalValue, giftCardRemainingBalance } = useOrderGiftcardCalculator(
        totalAfterReward,
        tipValue,
        giftCard,
        giftCardBalance
    );
    const { payWithCard, isConfirmationOpen, onRetry, onErrorDialogClose, handleDigitalPayment } =
        usePayMyOrder(
            selectedPaymentMethod,
            orderDetails.data,
            totalValue ?? 0,
            tipValue ?? 0,
            selectedTip,
            onAddNewCardClick,
            giftCardPayment,
            selectedReward,
            yourShares,
            splitShares
        );
    const {
        showDialog: actionsMenuOpen,
        onClose: onActionsMenuClose,
        onOpen: onActionMenuOpen,
        isMenuAvailable
    } = usePayActions(openTableSelector, isOrderLoading, isOrderNotFound);

    React.useEffect(() => {
        const splitParams = getPaySessionSplits(merchantId, locationId, checkId, tableNumber);
        clearSessionStorage();
        const params: ExistingSharedOrderData = {
            selectedReward,
            tipValue,
            giftCard,
            userSelectedTipValue: selectedTip
        };
        setExistingParams(params);
        setPaySession(params, merchantId, locationId, checkId, tableNumber);
        setPaySessionSplits(splitParams, merchantId, locationId, checkId, tableNumber);
    }, [selectedReward, selectedTip, tipValue, giftCard, merchantId, locationId, checkId, tableNumber]);

    React.useEffect(() => {
        setBasket({ locationId: currentLocation?._id })(dispatch);
    }, [currentLocation?._id, dispatch]);

    const handleTableChange = React.useCallback(
        (tNumber?: string, cId?: string) => {
            handleClearRewards();
            handleSplit();
            if (!isEmptyString(cId)) {
                replace('', {}, `checkId=${cId}`);
            } else {
                replace('', {}, `tableNumber=${tNumber}`);
            }
            setOpenTableSelector(false);
        },
        [handleClearRewards, handleSplit, replace]
    );
    const handleChangeTableClick = React.useCallback(() => {
        setOpenTableSelector(true);
    }, []);
    const handleChangeTableClose = React.useCallback(() => {
        setOpenTableSelector(false);
    }, []);
    const handleBillSplittingOpen = React.useCallback(() => {
        setOpenBillSplitting(true);
    }, []);
    const handleBillSplittingClose = React.useCallback(() => {
        setOpenBillSplitting(false);
    }, []);

    const globalLoading = React.useMemo(
        () => settingsLoading || layoutLoading,
        [layoutLoading, settingsLoading]
    );

    const operationErrorData = React.useMemo(() => {
        if (isSuccess(operationError) && operationError.data) {
            return operationError.data;
        }
        return null;
    }, [operationError]);

    const {
        isOutOfLimit,
        handleExceededLimitDialogClose,
        handleValidateTransactionLimit,
        showExceededLimitDialog,
        transactionLimitDescription
    } = useTransactionLimitValidation(totalValue);

    const handlePayWithCard = React.useCallback(
        (forced?: boolean) => {
            if (isOutOfLimit) {
                handleValidateTransactionLimit();
            } else {
                payWithCard(forced);
            }
        },
        [handleValidateTransactionLimit, isOutOfLimit, payWithCard]
    );

    if (!currentLocation && !isCurrentLocationLoading) {
        return <ErrorLayout title={t('GENERAL_GENERIC_ERROR')} />;
    }
    return (
        <Box
            height="100%"
            width="100%"
            className="hidden-scroll"
            marginX="auto"
            maxWidth={PAY_MAX_WIDTH}
            overflow="scroll"
        >
            <Paper style={{ minHeight: '100%' }}>
                <PayHeader variant={isDefined(yourShares) || isNumber(customSplit) ? 'large' : 'default'}>
                    <PayToolbar
                        loading={globalLoading}
                        table={tableNumber}
                        checkId={checkId}
                        disableBack
                        onChangeClick={handleChangeTableClick}
                        onMenuClick={onActionMenuOpen}
                        showMenu={isMenuAvailable}
                    />
                    {!isOrderNotFound && !operationErrorData && (
                        <PayItemsHeader
                            loading={isOrderLoading}
                            total={balance + (tipValue ?? 0)}
                            splitTotal={!!yourShares ? totalAfterReward : undefined}
                            customSplit={isNumber(customSplit) ? customSplit + (tipValue ?? 0) : undefined}
                        />
                    )}
                </PayHeader>
                {!isOrderNotFound && !operationErrorData && (
                    <React.Fragment>
                        <PayItems
                            loading={isOrderLoading}
                            items={orderDetails.data?.items}
                            total={orderDetails?.data?.total}
                        />
                        <PayRewards
                            disabled={!rewardsEnabled}
                            loading={isOrderLoading}
                            rewards={availableRewards}
                            selected={selectedReward}
                            onSelect={handleSetReward}
                            onClear={handleClearRewards}
                        />
                        <PayTips
                            loading={isOrderLoading}
                            selectedTip={selectedTip}
                            onChange={onTipChange}
                            tipValue={tipValue}
                            tippingSettings={tippingSettings}
                            subTotal={totalAfterReward}
                        />
                        <PaymentMethods
                            selectedPaymentMethod={selectedPaymentMethod}
                            onAddNewCardClick={onAddNewCardClick}
                            {...paymentMethodsProps}
                            loading={isOrderLoading}
                        />
                        {isGiftCardsEnabled && !isOrderLoading && (
                            <Box px={2}>
                                <PayLinkButton onClick={giftCard ? resetGiftCard : handleOpenGiftCard}>
                                    {giftCard
                                        ? t('PAT_QUICKPAY_REMOVE_GIFTCARD')
                                        : t('PAT_QUICKPAY_APPLY_GIFTCARD')}
                                </PayLinkButton>
                            </Box>
                        )}
                        <PaymentBreakdown
                            loading={isOrderLoading}
                            total={orderDetails?.data?.total}
                            tip={tipValue ?? 0}
                            giftCardPayment={giftCardPayment}
                            payments={orderDetails?.data?.payments}
                            reward={selectedReward}
                        />
                        {!isOrderNotFound && (
                            <PaymentButtons
                                loading={isOrderLoading}
                                amount={totalValue}
                                onClick={handlePayWithCard}
                                onDigitalPaymentMade={handleDigitalPayment}
                                selectedWallet={selectedPaymentMethod?.type}
                                cards={paymentMethodsProps.cards}
                                onBillSplit={handleBillSplittingOpen}
                                isSplit={!!yourShares || isDefined(customSplit)}
                                outOfTransactionLimit={isOutOfLimit}
                            />
                        )}
                    </React.Fragment>
                )}
                {!!operationErrorData && <PayError errorMessage={operationErrorData?.errorMessage} />}
                <PayTableSelector
                    open={openTableSelector && !globalLoading}
                    disableBackdropClick={!isDefined(tableNumber) && !isDefined(checkId)}
                    onChange={handleTableChange}
                    onClose={handleChangeTableClose}
                />
                <ConfirmDialog
                    open={isConfirmationOpen}
                    disableBackdropClick
                    disableEscapeKeyDown
                    onClose={onErrorDialogClose}
                    confirmMessage={t('RETRY')}
                    onConfirm={onRetry}
                    description={t('DIALOG_ORDER_STATUS_UNKNOWN')}
                />
                <ConfirmDialog
                    open={showExceededLimitDialog}
                    disableBackdropClick
                    disableEscapeKeyDown
                    isCancellable={false}
                    onClose={handleExceededLimitDialogClose}
                    confirmMessage={t('OK')}
                    onConfirm={handleExceededLimitDialogClose}
                    description={transactionLimitDescription}
                    title={t('DIALOG_OOPS')}
                    align="center"
                />
                <PayActionModal
                    open={actionsMenuOpen && !operationErrorData}
                    onClose={onActionsMenuClose}
                    handleChange={handleChangeTableClick}
                    table={tableNumber}
                    checkId={checkId}
                    orderNotFound={isOrderNotFound}
                    onBillSplit={handleBillSplittingOpen}
                />
                <PayBillSplittingModal
                    open={openBillSplitting}
                    onClose={handleBillSplittingClose}
                    total={balance}
                    limit={balance}
                    customAmount={customSplit}
                    onApply={handleSplit}
                    shares={splitShares}
                    yourShares={yourShares}
                />
                {isGiftCardsEnabled && (
                    <PayGiftCardModal
                        open={showGiftCardModal}
                        onClose={handleCloseGiftCard}
                        loading={isOrderLoading || giftCardLoading}
                        onGiftcardApply={submitGiftCard}
                        error={giftCardError}
                        giftCardPayment={giftCardPayment?.amount}
                        remainingBalance={giftCardRemainingBalance}
                        resetErrors={resetGiftCardErrors}
                    />
                )}
            </Paper>
        </Box>
    );
};
