export enum AuthActionTypes {
    START_REQUEST = 'AUTH_START_REQUEST',
    END_REQUEST = 'AUTH_END_REQUEST',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_ERROR = 'LOGIN_ERROR',
    LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
    REGISTER_SUCCESS = 'REGISTER_SUCCESS',
    REGISTER_ERROR = 'REGISTER_ERROR',
    USER_PENDING = 'USER_PENDING',
    ACTIVATION_ERROR = 'ACTIVATION_ERROR',
    ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS',
    CLEAR_ERRORS = 'AUTH_CLEAR_ERRORS',
    SET_RETURN_URL = 'AUTH_SET_RETURN_URL',
    RESET_RETURN_URL = 'AUTH_RESET_RETURN_URL',
    UPDATE_USER_INFO = 'UPDATE_USER_INFO',
    UPDATE_USER_INFO_WITH_CREDENTIALS = 'UPDATE_USER_INFO_WITH_CREDENTIALS',
    GET_USER_CARD = 'GET_USER_CARD',
    SET_SHOW_MARKETING = 'SET_SHOW_MARKETING'
}
