import { useEffect, useState } from 'react';
import { hexToRgb } from '@material-ui/core';
import moment from 'moment';
import { isEmptyString, isString } from './typeInference';

export const updateArrayItem = <T>(arr: T[], i: number, value: T): T[] =>
    Object.assign([...arr], { [i]: value });

export const removeArrayItem = <T>(arr: T[], index: number): T[] => {
    if (typeof index !== 'number') {
        console.warn('index must be a number');
        return arr;
    }
    if (index < 0) {
        console.warn('index must be greater than 0');
        return arr;
    }
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
};

export default function useDebounce(value: any, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return debouncedValue;
}

export const toTitleCase = (str: string) =>
    str.replace(/\w\S*/g, (txt: string) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

export function getCurrencyString(value: number, currency?: string) {
    if (!currency || currency === 'UNKNOWN') {
        return String(value);
    }
    const language = navigator.language || 'en-EN';

    return new Intl.NumberFormat(language, {
        style: 'currency',
        currencyDisplay: 'symbol',
        currency
    }).format(value);
}

export const addOpacity = (colour: string, opacity: number) => hexToRgb(colour).replace(')', `, ${opacity})`);

export const getFadeDownGradient = (
    color: string,
    colorsArray: [number, number, number] = [0, 0.3, 0.75],
    percentArray: [string, string, string] = ['0%', '40%', '100%']
) =>
    `linear-gradient(180deg, ${addOpacity(color, colorsArray[0])} ${percentArray[0]}, ${addOpacity(
        color,
        colorsArray[1]
    )} ${percentArray[1]}, ${addOpacity(color, colorsArray[2])} ${percentArray[2]})`;

/* For ease of combining multiple potentially nullable key values into a nice URL Param string */
export type CreateURLParams = { [paramName: string]: string | undefined };
export const createUrlParams = (params: CreateURLParams): string => {
    const urlParams = new URLSearchParams();
    for (const key of Object.keys(params)) {
        const value = params[key];
        if (!!value) {
            urlParams.append(key, value);
        }
    }
    const paramsString = urlParams.toString();
    return !!paramsString ? `?${paramsString}` : '';
};

export function getRequestDate() {
    return moment().toISOString(true);
}

export function isEmail(str: string): boolean {
    const re =
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(str).toLowerCase());
}

export const roundToDecimal = (num: number, decimalCount = 2) => {
    const powerOfTen = Math.pow(10, decimalCount);
    return Math.round((num + Number.EPSILON) * powerOfTen) / powerOfTen;
};

export const roundToEvenDP = (num: number, decimals = 2): number => {
    if (num < 0) {
        return -roundToEvenDP(-num, decimals);
    }
    const factor = 10 ** decimals;
    const scaled = +(decimals ? num * factor : num).toFixed(12); // Avoid rounding errors
    const floor = Math.floor(scaled);
    const diff = scaled - floor;
    const e = 1e-12; // Allow for rounding errors in diff
    const scaledRounded = diff > 0.5 - e && diff < 0.5 + e ? floor + (floor % 2) : Math.round(scaled);
    const rounded = decimals ? scaledRounded / factor : scaledRounded;
    const formatted = +rounded.toFixed(decimals);
    return formatted;
};

export const showCalories = (totalCalories: { value: number; isSet: boolean }) => {
    if (totalCalories.isSet && totalCalories.value >= 0) {
        return true;
    }
    return false;
};

export const clearSessionStorage = () => {
    sessionStorage.clear();
};

export const getCompoundKey = (parent: string | undefined, id: string) => {
    if (isString(parent) && !isEmptyString(parent)) {
        return `${id}^${parent}`;
    }
    return id;
};

export const decompoundKey = (key: string) => {
    if (isString(key) && key.includes('^')) {
        const [parentId, childId] = key.split('^');
        return { parentId, childId };
    }
    return { childId: key };
};
