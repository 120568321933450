import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    Box,
    Collapse,
    createStyles,
    List,
    ListItem,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { OrderToTableLocationPicker } from 'components/location/OrderToTableLocationPicker';
import { IOrderReadResourceV12, OrderScenario } from 'components/order/model/Order';
import { TimeslotPicker } from 'components/timeslots/picker/TimeslotPicker';
import { addOpacity, decompoundKey, getCompoundKey } from 'lib/helpers';
import { ApplicationState } from 'store/store';
import { ICategoryGroup, IEnrichedCategory, IEnrichedMenuWithModifierMaps } from '../model/Menu';
import { RefsMap } from './MenuDesktop';

interface IProps {
    menu: IEnrichedMenuWithModifierMaps | undefined;
    orderAhead: boolean;
    categoryRefs: RefsMap;
    activeCategory: string | undefined;
    categories: IEnrichedCategory[];
    showCategoryGroups: boolean;
    categoryGroups?: ICategoryGroup[];
    recentOrders: IOrderReadResourceV12[] | undefined;
    categoryGroupToCategories: [ICategoryGroup, IEnrichedCategory[]][] | undefined;
}

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectedCategoryGroup: {
            backgroundColor: addOpacity(theme.palette.common.black, 0.08)
        },
        categoryTitle: {
            fontFamily: `TenantFont, ${theme.typography.fontFamily}`,
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center',
            color: theme.palette.titleTextColour
        },
        categoryItem: {
            padding: theme.spacing(2, 2, 2, 2)
        },
        categoryItemText: {
            fontWeight: 'inherit'
        },
        categoryGroupText: {
            fontSize: theme.spacing(2.25),
            fontWeight: 'inherit'
        },
        selectedItem: ({ showCategoryGroups }: { showCategoryGroups: boolean }) => ({
            fontWeight: theme.typography.fontWeightBold,
            backgroundColor: `${
                showCategoryGroups ? 'transparent' : addOpacity(theme.palette.common.black, 0.08)
            } !important`
        }),
        nested: {
            paddingLeft: theme.spacing(4)
        },
        menuTitle: {
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.background.paper
        },
        menuCategoryList: {
            borderBottomLeftRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.background.paper
        }
    })
);

export const MenuSidebar: React.FC<IProps> = ({
    menu,
    categories,
    orderAhead,
    categoryRefs,
    activeCategory,
    showCategoryGroups,
    categoryGroupToCategories,
    recentOrders
}) => {
    const { t } = useTranslation();
    const classes = useStyles({ showCategoryGroups });
    const { checkServiceAvailability } = useLocationHelpers();
    const { deliveryLocation } = useSelector((state: ApplicationState) => state.basket);
    const [activeCategoryGroup, setActiveCategoryGroup] = React.useState<string | undefined>();
    React.useEffect(() => {
        if (
            (!recentOrders || (recentOrders && recentOrders.length === 0)) &&
            categoryGroupToCategories &&
            showCategoryGroups
        ) {
            setActiveCategoryGroup(categoryGroupToCategories[0][0].id);
        } else {
            setActiveCategoryGroup(undefined);
        }
    }, [categoryGroupToCategories, recentOrders, showCategoryGroups]);

    React.useEffect(() => {
        const { parentId: categoryGroupId } = decompoundKey(activeCategory || '');
        if (categoryGroupId && showCategoryGroups) {
            setTimeout(() => {
                setActiveCategoryGroup(currentCategoryGroup => {
                    if (currentCategoryGroup !== categoryGroupId) {
                        return categoryGroupId;
                    }

                    return currentCategoryGroup;
                });
            }, 500);
        }
    }, [activeCategory, showCategoryGroups]);
    const [scenario] = useRouteScenario();
    const handleCategoryClick = React.useCallback(
        (refName: string) => () => {
            if (
                refName &&
                categoryRefs[refName] &&
                categoryRefs[refName].ref !== null &&
                categoryRefs[refName].ref.current !== null
            ) {
                (categoryRefs[refName].ref as React.MutableRefObject<HTMLDivElement>).current.scrollIntoView({
                    behavior: 'smooth',
                    inline: 'start'
                });
                categoryRefs[refName].active = true;
            }
        },
        [categoryRefs]
    );

    const renderCategoryItem = React.useCallback(
        (category: IEnrichedCategory, categoryGroupId?: string) => {
            const { childId: categoryId, parentId: activeCategoryParentGroupId } = decompoundKey(
                activeCategory || ''
            );
            const selected =
                categoryId === category.id &&
                (showCategoryGroups ? categoryGroupId === activeCategoryParentGroupId : true);
            return (
                <ListItem
                    key={`${categoryGroupId}/${category.id}`}
                    classes={{ selected: classes.selectedItem }}
                    className={clsx(classes.categoryItem)}
                    button
                    onClick={handleCategoryClick(getCompoundKey(category.groupCategoryId, category.id))}
                    selected={selected}
                >
                    <Typography variant="body1" className={classes.categoryItemText}>
                        {category.title}
                    </Typography>
                </ListItem>
            );
        },
        [
            activeCategory,
            classes.categoryItem,
            classes.categoryItemText,
            classes.selectedItem,
            handleCategoryClick,
            showCategoryGroups
        ]
    );
    const rendetCategoryItemMapped = React.useCallback(
        (parentCategoryId?: string) => (category: IEnrichedCategory) =>
            renderCategoryItem(category, parentCategoryId),
        [renderCategoryItem]
    );
    const handleCategoryGroupClick = React.useCallback(
        (id: string) => () => {
            setActiveCategoryGroup(active => (active === id ? undefined : id));
        },
        []
    );
    const renderCategoryGroup = React.useCallback(
        ([parentCategory, groupCategories]: [ICategoryGroup, IEnrichedCategory[]]) => {
            const { parentId: parentCategoryId } = decompoundKey(activeCategory || '');
            const selected = parentCategory.id === parentCategoryId;

            return (
                <React.Fragment key={parentCategory.id}>
                    <ListItem
                        selected={selected}
                        button
                        onClick={handleCategoryGroupClick(parentCategory.id)}
                    >
                        <Typography variant="body1" className={classes.categoryGroupText}>
                            {parentCategory.title}
                        </Typography>
                        <Box marginLeft="auto">
                            {activeCategoryGroup === parentCategory.id ? <ExpandLess /> : <ExpandMore />}
                        </Box>
                    </ListItem>
                    <Collapse
                        className={clsx({
                            [classes.selectedCategoryGroup]: selected
                        })}
                        in={activeCategoryGroup === parentCategory.id}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            {groupCategories.map(rendetCategoryItemMapped(parentCategory.id))}
                        </List>
                    </Collapse>
                </React.Fragment>
            );
        },
        [
            activeCategory,
            activeCategoryGroup,
            classes.categoryGroupText,
            classes.selectedCategoryGroup,
            handleCategoryGroupClick,
            rendetCategoryItemMapped
        ]
    );
    const renderRecentOrderItem = React.useMemo(
        () => (
            <ListItem
                button
                onClick={handleCategoryClick('recent-orders')}
                selected={activeCategory === 'recent-orders'}
                {...(!showCategoryGroups
                    ? {
                          classes: { selected: classes.selectedItem },
                          className: clsx(classes.categoryItem, {
                              [classes.nested]: showCategoryGroups
                          })
                      }
                    : {})}
            >
                <Typography
                    variant="body1"
                    className={showCategoryGroups ? classes.categoryGroupText : classes.categoryItemText}
                >
                    {t('PREORDER_PREVIOUS_ORDERS')}
                </Typography>
            </ListItem>
        ),
        [
            activeCategory,
            classes.categoryGroupText,
            classes.categoryItem,
            classes.categoryItemText,
            classes.nested,
            classes.selectedItem,
            handleCategoryClick,
            showCategoryGroups,
            t
        ]
    );
    return (
        <Box display="flex" flexDirection="column" paddingTop={1} height="100%">
            {!!menu && orderAhead && <TimeslotPicker />}

            {!!menu &&
                (scenario === OrderScenario.ORDER_TO_TABLE || scenario === OrderScenario.TABLE) &&
                checkServiceAvailability(scenario) && (
                    <OrderToTableLocationPicker isDialogOpenInitially={!deliveryLocation} />
                )}
            <Box display="flex" flexDirection="column" marginTop={2} flex="1" maxHeight="100%">
                <Box paddingTop={3} paddingBottom={2} className={classes.menuTitle}>
                    <Typography className={classes.categoryTitle} variant="h6">
                        {t('OUR_MENU')}
                    </Typography>
                </Box>
                <Box flex={1} overflow="auto" className="hidden-scroll" paddingBottom={10}>
                    <Box className={classes.menuCategoryList}>
                        <List>
                            {!!recentOrders?.length && renderRecentOrderItem}
                            {!showCategoryGroups && !!menu && categories.map(rendetCategoryItemMapped())}
                            {showCategoryGroups &&
                                !!menu &&
                                categoryGroupToCategories?.map(renderCategoryGroup)}
                        </List>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
