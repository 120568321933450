import { loadScript } from 'lib/loadScript';
import logger from 'lib/logger';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { uuid } from 'uuidv4';
import { googleSignIn } from 'store/auth/authActions';
import { UserData } from '../model/User';
import { AxiosResponse } from 'axios';
import { SSO_BUTTON_MAX_WIDTH } from 'config/constants';

export function useGoogleSSO(
    onSuccess: (res: UserData) => void,
    onError: (error: AxiosResponse<{ message: string; code: string }>) => void,
    onStart?: () => void
) {
    const [scriptLoaded, setScriptLoaded] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const dispatch = useDispatch();
    const container = React.useRef<HTMLDivElement>();
    const handleCredentialResponse = React.useCallback(
        async ({ credential }: google.accounts.id.CredentialResponse) => {
            setLoading(true);
            if (onStart) {
                onStart();
            }
            try {
                const result = await googleSignIn(credential)(dispatch);
                onSuccess(result);
            } catch (e: any) {
                onError(e);
            } finally {
                setLoading(false);
            }
        },
        [dispatch, onError, onStart, onSuccess]
    );
    const googleSSOId = React.useMemo(() => uuid(), []);
    React.useEffect(() => {
        const initGoogleSSO = async () => {
            if (settings?.googleSSO?.webAppClientId && settings?.googleSSOEnabled) {
                try {
                    if (!scriptLoaded) {
                        await loadScript('https://accounts.google.com/gsi/client');
                        setScriptLoaded(true);
                    } else {
                        const buttonEl = document.getElementById(googleSSOId);
                        if (buttonEl) {
                            window.google.accounts.id.initialize({
                                client_id:
                                    '47326208711-75ch0667okof4a1008phbldgc0jk9jes.apps.googleusercontent.com',
                                callback: handleCredentialResponse,
                                nonce: uuid(),
                                use_fedcm_for_prompt: true
                            });
                            google.accounts.id.renderButton(buttonEl, {
                                theme: 'outline',
                                size: 'large',
                                type: 'standard',
                                width:
                                    (container.current?.clientWidth ?? 0) > SSO_BUTTON_MAX_WIDTH
                                        ? SSO_BUTTON_MAX_WIDTH
                                        : container.current?.clientWidth
                            });
                            google.accounts.id.prompt();
                        }
                    }
                } catch (e: any) {
                    logger.error(e.message, e);
                }
            }
        };
        initGoogleSSO();
    }, [
        googleSSOId,
        handleCredentialResponse,
        scriptLoaded,
        settings?.googleSSO?.webAppClientId,
        settings?.googleSSOEnabled
    ]);
    return {
        container,
        googleSSOId,
        loading
    };
}
