// TODO: rewrite this component
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Collapse,
    createStyles,
    FormControl,
    FormControlLabel,
    Grid,
    makeStyles,
    Radio,
    RadioGroup,
    Theme
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { MultiSelectFormField, SelectOption } from 'lib/form/MultiSelectFormField';
import { PickUpIcon } from 'lib/icons/PickUpIcon';
import { MerchantBrandedDialog } from 'lib/MerchantBrandedDialog';
import { LoadingButton } from 'ui/LoadingButton';
import { getDay, getTimeslotTimes } from '../helpers';
import { Timeslot } from '../model/Timeslot';

enum OrderAheadTypes {
    ASAP = 'ASAP',
    Later = 'Later'
}

type TimeslotFormData = {
    day: string;
    time: Record<string, string>;
};

interface TimeslotPickerDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (data?: { day: string; time: string }) => void;
    onCancelOrder?: () => void;
    options: Record<string, Timeslot[]>;
    timeslot?: Timeslot;
    noAsap?: boolean;
    isLoading?: boolean;
    noTimeslots?: boolean;
    description?: string;
    confirmButton?: string;
    canselButton?: string;
    branded?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectBox: {
            maxHeight: theme.spacing(200 / 8),
            borderRadius: theme.shape.borderRadius
        },
        selectBoxList: {
            padding: theme.spacing(0)
        },
        boldText: {
            fontWeight: theme.typography.fontWeightBold
        },
        icon: {
            stroke: theme.palette.background.default,
            fontSize: 'inherit'
        }
    })
);

export const TimeslotPickerDialog: React.FC<TimeslotPickerDialogProps> = ({
    open,
    onClose,
    onSubmit,
    timeslot,
    options,
    noAsap,
    noTimeslots,
    description,
    confirmButton,
    canselButton,
    onCancelOrder,
    branded = true
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [orderAheadType, setOrderAheadType] = React.useState<string>(
        timeslot ? OrderAheadTypes.Later : OrderAheadTypes.ASAP
    );
    const dayOptions = React.useMemo(
        () => Object.keys(options).map(item => ({ value: item, label: item })),
        [options]
    );
    const timeOptions = React.useMemo(
        () =>
            Object.entries(options).reduce<Record<string, SelectOption[]>>((acc, [day, timeArray]) => {
                acc[day] = timeArray.map((item, index) => ({
                    value: String(index),
                    label: getTimeslotTimes(item, t)
                }));
                return acc;
            }, {}),
        [options, t]
    );
    const initialValues = React.useMemo(() => {
        const currentDay = timeslot ? getDay(timeslot.start) : Object.keys(options)[0];
        const newInitials: TimeslotFormData = {
            day: currentDay,
            time: timeslot
                ? {
                      [currentDay]: String(
                          options[currentDay]?.findIndex(predicate => predicate.start === timeslot.start)
                      )
                  }
                : Object.keys(options).reduce<Record<string, string>>((acc, day) => {
                      acc[day] = timeOptions[day][0].value;
                      return acc;
                  }, {})
        };
        return newInitials;
    }, [options, timeOptions, timeslot]);
    const handleChange = React.useCallback((_: React.ChangeEvent<HTMLInputElement>, value: string) => {
        setOrderAheadType(value);
    }, []);
    const handleSubmit = React.useCallback(
        (data: TimeslotFormData) => {
            onSubmit(
                orderAheadType === OrderAheadTypes.ASAP
                    ? undefined
                    : { day: data.day, time: data.time[data.day] }
            );
        },
        [onSubmit, orderAheadType]
    );
    const renderTimeSelect = React.useCallback(
        (day: string) => (item: string) => {
            if (day === item) {
                return (
                    <Grid item xs={6} key={`timeslot-picker-${item}`}>
                        <MultiSelectFormField
                            selectClasses={{ selectMenu: classes.boldText }}
                            name={`time[${item}]`}
                            options={timeOptions[item] || []}
                            MenuProps={{ classes: { paper: classes.selectBox, list: classes.selectBoxList } }}
                            MenuItemClasses={{ selected: classes.boldText }}
                        />
                    </Grid>
                );
            }
            return null;
        },
        [classes.boldText, classes.selectBox, classes.selectBoxList, timeOptions]
    );

    return (
        <MerchantBrandedDialog
            title={t('MENU_COLLECTION')}
            subtitle={description ? description : t('ORDER_AHEAD_SELECTION_TITLE')}
            icon={<PickUpIcon className={classes.icon} />}
            open={open}
            onClose={onClose}
            hideImage={!branded}
            hideTitle={!branded}
        >
            <Box paddingY={1}>
                <FormControl component="fieldset" fullWidth>
                    <RadioGroup value={orderAheadType} onChange={handleChange}>
                        {!noAsap && (
                            <FormControlLabel
                                value="ASAP"
                                control={<Radio color="primary" />}
                                label={t('GENERAL_ASAP')}
                            />
                        )}
                        <FormControlLabel
                            disabled={noTimeslots}
                            value="Later"
                            control={<Radio color="primary" />}
                            label={t('BUTTON_LATER')}
                        />
                    </RadioGroup>
                </FormControl>
            </Box>
            <Box paddingY={1}>
                <Formik onSubmit={handleSubmit} initialValues={initialValues}>
                    {({ submitForm, isSubmitting, isValid, values: { day } }) => (
                        <Form>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Collapse in={OrderAheadTypes.Later === orderAheadType}>
                                        <Box paddingBottom={1} paddingTop={1}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <MultiSelectFormField
                                                        selectClasses={{ selectMenu: classes.boldText }}
                                                        name="day"
                                                        options={dayOptions}
                                                    />
                                                </Grid>
                                                {Object.keys(timeOptions).map(renderTimeSelect(day))}
                                            </Grid>
                                        </Box>
                                    </Collapse>
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        loading={isSubmitting}
                                        disabled={isSubmitting || !isValid}
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={submitForm}
                                    >
                                        {confirmButton
                                            ? confirmButton
                                            : t('DIALOG_TIME_SLOT_PICKER_PICK_BTN')}
                                    </LoadingButton>
                                </Grid>
                                {canselButton && (
                                    <Grid item xs={12}>
                                        <Box width="100%">
                                            <Button variant="contained" fullWidth onClick={onCancelOrder}>
                                                {canselButton}
                                            </Button>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </MerchantBrandedDialog>
    );
};
