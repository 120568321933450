import * as React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { ISettings } from 'components/settings/model/Settings';
import { ViewBillTipSelectorItem } from './ViewBillTipSelectorItem';

interface IProps {
    tipping: ISettings['tipping'];
    onTipOptionSelected: (value: number) => void;
    selected: number | null;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tipOptionSelector: {
            display: 'flex',
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: theme.shape.borderRadius,
            marginTop: theme.spacing(1),
            overflow: 'hidden'
        }
    })
);

export const ViewBillTipSelector: React.FC<IProps> = ({
    tipping: { scheme, values },
    selected,
    onTipOptionSelected
}) => {
    const classes = useStyles();
    const tipSelectorOptions = React.useMemo(
        () =>
            values.map((tipValue, index) => (
                <ViewBillTipSelectorItem
                    value={tipValue}
                    key={tipValue}
                    isLast={values.length - 1 === index}
                    scheme={scheme}
                    onClick={onTipOptionSelected}
                    selected={tipValue === selected}
                />
            )),
        [onTipOptionSelected, scheme, selected, values]
    );

    return <Box className={classes.tipOptionSelector}>{tipSelectorOptions}</Box>;
};
