import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, createStyles, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import clsx from 'clsx';
import { useResponsive } from 'src/hooks/useResponsive';
import { usePaymentProvider } from 'src/integrations/PaymentProvider/hooks/usePaymentProvider';
import { OrderPaymentType } from 'components/order/model/Order';
import { getLocalMerchantId } from 'components/settings/localStore';
import { setOnCloseRedirectUrl } from 'components/user/localAuth';
import { UserCard } from 'components/user/model/User';
import { CONTENT_MAX_WIDTH } from 'config/constants';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { BottomDialog } from 'lib/BottomDialog';
import { addOpacity } from 'lib/helpers';
import { MuiRadioGroup } from 'lib/MuiRadioGroup';
import { Throbber } from 'lib/Throbber';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { ROUTES } from 'pages/routes';
import { getUserCard } from 'store/auth/authActions';
import { setLastRequest } from 'store/request/requestActions';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/LoadingButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        payButton: {
            marginTop: theme.spacing(1),
            padding: theme.spacing(1)
        },
        selectCardHeader: {
            width: '100%',
            textAlign: 'left',
            fontWeight: 'bold'
        },
        noPadding: {
            padding: '0'
        },
        addCardButton: {
            width: '100%',
            textAlign: 'center'
        },
        dialog: (props: { isDesktop: boolean }) => ({
            display: 'flex',
            padding: theme.spacing(1, 2),
            ...(props.isDesktop
                ? {
                      maxWidth: CONTENT_MAX_WIDTH,
                      width: CONTENT_MAX_WIDTH
                  }
                : {})
        }),
        iframe: {
            flex: '1',
            height: 'initial'
        },
        action: {
            color: theme.palette.text.secondary,
            fontSize: theme.spacing(1.5),
            textDecoration: 'underline'
        },
        link: {
            color: addOpacity(theme.palette.text.primary, 0.7)
        }
    })
);

interface ICardPaymentProps {
    handleCreateOrder: (paymentType: OrderPaymentType, selectedCard: UserCard | null) => () => void;
    isPlacingOrder: boolean;
    open: boolean;
    onClose: () => void;
}

export const CardPayment: React.FC<ICardPaymentProps> = props => {
    const { isDesktop } = useResponsive();
    const { handleCreateOrder, isPlacingOrder, open, onClose } = props;
    const [userCards, setUserCards] = React.useState<null | UserCard[]>(null);
    const { push } = useLocalHistory();
    const { pathname } = useLocation();
    const { isLoggedIn, card, user } = useAuth();
    const [selectedCardId, setSelectedCardId] = React.useState<string>('');
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { t } = useTranslation();
    const classes = useStyles({ isDesktop });
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (card) {
            if (card) {
                const currentUserCards = [card];
                setUserCards(currentUserCards);
            }
        }
        return () => setUserCards(null);
    }, [card]);

    React.useEffect(() => {
        if (userCards) {
            setSelectedCardId(userCards.length > 0 ? userCards[0]._id : '');
        }
    }, [userCards]);

    const cardOptions = React.useMemo(() => {
        if (!userCards) {
            return [];
        }
        return userCards.map(userCard => ({
            label: `${userCard.type.toUpperCase()}: **** **** **** ${userCard.last4}`,
            value: userCard._id
        }));
    }, [userCards]);

    const handleCardSelected = React.useCallback((cardId: string) => {
        setSelectedCardId(cardId);
    }, []);
    // For multi-card support
    // Currently not supported, but uncomment when supported
    // const handleAddCardClicked = React.useCallback(() => setCheckoutState(ECheckoutState.ADD_CARD), []);
    const handleAddCardClosed = React.useCallback(() => {
        if (cardOptions.length === 0) {
            return onClose();
        }
    }, [onClose, cardOptions]);

    const selectedCard = React.useMemo(() => {
        if (!userCards || userCards.length === 0) {
            return null;
        }
        return userCards.find(userCard => userCard._id === selectedCardId) || null;
    }, [userCards, selectedCardId]);

    const handleCardAdded = React.useCallback(() => {
        setUserCards(null);
        if (user && user.id) {
            getUserCard(user.id)(dispatch).then(userCard => {
                if (userCard) {
                    const requestFunction = handleCreateOrder(OrderPaymentType.CARD_ON_FILE, userCard);
                    setLastRequest(requestFunction)(dispatch);
                    requestFunction();
                    onClose();
                }
            });
        }
    }, [user, dispatch, handleCreateOrder, onClose]);
    const handleCreateOrderClick = React.useCallback(() => {
        const request = handleCreateOrder(OrderPaymentType.CARD_ON_FILE, selectedCard);
        setLastRequest(request)(dispatch);
        request();
    }, [handleCreateOrder, selectedCard, dispatch]);
    const userHasCards = React.useMemo(() => userCards && userCards.length !== 0, [userCards]);

    const handleRemoveCard = React.useCallback(() => {
        const merchantId = getLocalMerchantId();
        if (merchantId && isLoggedIn) {
            setOnCloseRedirectUrl(`${pathname}?isCheckingOut=true`, merchantId);
            push(ROUTES.USER.PAYMENT_METHODS, { merchantId });
        }
    }, [isLoggedIn, pathname, push]);
    const renderActionButton = React.useCallback(
        () =>
            isLoggedIn ? (
                <Typography className={classes.action}>
                    <Link className={classes.link} href="" onClick={handleRemoveCard} color="inherit">
                        {t('DIALOG_REMOVE_CARD_OK')}
                    </Link>
                </Typography>
            ) : null,
        [classes.action, classes.link, handleRemoveCard, isLoggedIn, t]
    );
    const { addCardComponent, isProviderLoading } = usePaymentProvider(
        { type: 'cardOnFile' },
        { onCardAdded: handleCardAdded }
    );
    return settings ? (
        <BottomDialog
            fullScreen={!userHasCards}
            open={open}
            onClose={onClose}
            paperClassName={clsx({ [classes.noPadding]: !userHasCards }, classes.dialog)}
            disableEnforceFocus
        >
            {open && (
                <>
                    {!isProviderLoading && !userHasCards && !!addCardComponent && open && (
                        <React.Fragment>
                            <MuiAppBar
                                title={isLoggedIn ? t('CHECKOUT_ADD_CARD') : t('CHECKOUT_USE_CARD')}
                                onBack={handleAddCardClosed}
                                icon={Close}
                                ignoreGA
                            />
                            {addCardComponent}
                        </React.Fragment>
                    )}
                    {!isProviderLoading && userHasCards && !addCardComponent && (
                        <Typography variant="subtitle2">
                            {/* TODO */}
                            {t('CHECKOUT_ADD_CARD_NOT_SUPPORTED')}
                        </Typography>
                    )}
                    {!isProviderLoading && userHasCards && (
                        <Box
                            width="100%"
                            height="100%"
                            display="flex"
                            alignItems="center"
                            flexDirection="column"
                        >
                            <Typography variant="h6" className={classes.selectCardHeader}>
                                {t('CHECKOUT_SELECT_PAYMENT_METHOD')}
                            </Typography>
                            <MuiRadioGroup
                                options={cardOptions}
                                value={selectedCardId}
                                onChange={handleCardSelected}
                                renderActionButton={renderActionButton}
                            />
                            {/* We do not support currently multiple cards. But this would work to do so */}
                            {/* <Button onClick={handleAddCardClicked} variant="text" className={classes.addCardButton}>
                        {'+'} {t('CHECKOUT_ADD_CARD')}
                    </Button>*/}
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                disabled={!selectedCard || isPlacingOrder}
                                loading={isPlacingOrder}
                                onClick={handleCreateOrderClick}
                                fullWidth
                                className={classes.payButton}
                            >
                                {t('CHECKOUT_ORDER_AND_PAY')}
                            </LoadingButton>
                        </Box>
                    )}
                    {isProviderLoading && <Throbber text={t('GENERAL_LOADING')} />}
                </>
            )}
        </BottomDialog>
    ) : null;
};
