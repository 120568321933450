import { HttpClient } from 'components/http/HttpClient';
import { getLocalSettings } from 'components/settings/localStore';
import { LocalizationData } from './model/Localization';

class LocalizationApi extends HttpClient {
    constructor() {
        super({
            baseURL: process.env.CONTENT_SERVICE_URL,
            url: '/apps/localization'
        });
    }

    public async getLocalization(): Promise<LocalizationData> {
        const localSettings = getLocalSettings();
        if (!localSettings) {
            throw new Error('merchantId and x-application-id are missed');
        }

        const response = await this.getRequest<LocalizationData>({
            url: '',
            headerConfigs: { shouldIncludeAuthToken: false },
            headers: {
                'accept-language': 'en',
                'x-application-id': localSettings.applicationId
            },
            configs: {
                params: {
                    tenantId: localSettings.merchantId
                }
            }
        });
        return response;
    }
}

export const localizationApi = new LocalizationApi();
