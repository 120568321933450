import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { isDefined } from '@pepperhq/location-sdk/dist/lib/typeInference';
import clsx from 'clsx';
import { OperationErrorType, useOperationStatus } from 'components/operation/hooks/useOperationStatus';
import { orderApi } from 'components/order/orderApi';
import { PayError } from 'components/pay/errors/PayError';
import { PayHeader } from 'components/pay/header/PayHeader';
import { PayToolbar } from 'components/pay/header/PayToolbar';
import { getLocalMerchantId } from 'components/settings/localStore';
import { setShouldUpdateGuestSession } from 'components/user/localAuth';
import { ELoadingSVG, RandomLoading } from 'lib/animations/RandomLoadingSVG';
import { EmptyState } from 'lib/EmptyState';
import { addOpacity } from 'lib/helpers';
import { InnerPageLayout } from 'lib/InnerPageLayout';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { ROUTES } from 'pages/routes';
import { ApplicationState } from 'store/store';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageContainer: {
            display: 'flex',
            height: '100%',
            paddingBottom: theme.spacing(7),
            overflowY: 'hidden',
            flexDirection: 'column'
        },
        pageCenter: {
            alignItems: 'center'
        },
        verticalCenter: {
            justifyContent: 'center'
        },
        basketError: {
            fontSize: theme.spacing(12),
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(-6)
        },
        paragraph: {
            marginTop: theme.spacing(0.5),
            color: addOpacity(theme.palette.text.primary, 0.5)
        }
    })
);

export const PayOperationStatusPage: React.FC = () => {
    const { t } = useTranslation();
    const { lastRequest } = useSelector((state: ApplicationState) => state.request);
    const { isLoading: settingsLoading } = useSelector((state: ApplicationState) => state.settings);
    const { isLoading: layoutLoading } = useSelector((state: ApplicationState) => state.layout);
    const {
        showRetry,
        loading,
        error,
        errorMessage,
        handleRetry,
        changedPriceOrder,
        payInstead,
        changedPriceOrderExtraTip,
        extraTipValue
    } = useOperationStatus();
    const { isGuest } = useAuth();
    const { push } = useLocalHistory();
    const classes = useStyles();

    // Using a memo here to stop this component changing over 3DS2 Auth / re-order operation change
    // Much more seamless experience for the user
    const RandomLoadingComponent = React.useMemo(
        () => (
            <RandomLoading
                fallbackText={t('GENERAL_GENERIC_ERROR')}
                enabledImages={[
                    ELoadingSVG.COFFEE_CUP,
                    ELoadingSVG.COFFEE_MUG,
                    ELoadingSVG.CUPCAKE,
                    ELoadingSVG.DONUT,
                    ELoadingSVG.FRYING_PAN,
                    ELoadingSVG.POPSICLE
                ]}
            />
        ),
        [t]
    );

    const globalLoading = React.useMemo(
        () => settingsLoading || layoutLoading,
        [layoutLoading, settingsLoading]
    );

    const handlePay = React.useCallback(async () => {
        if (changedPriceOrder) {
            const { id, ...orderBody } = changedPriceOrder;
            const operation = await orderApi.updateOrder(id, orderBody);
            if (isDefined(operation.id)) {
                if (isGuest) {
                    const merchantId = getLocalMerchantId();
                    if (merchantId) {
                        setShouldUpdateGuestSession(merchantId);
                    }
                }

                push(
                    ROUTES.QUICKPAY.STATUS,
                    {
                        operationId: operation.id.toString()
                    },
                    '',
                    { orderBodyToRecreatePat: { ...orderBody, id } }
                );
            }
        }
    }, [changedPriceOrder, isGuest, push]);

    const handlePayExtraTips = React.useCallback(async () => {
        if (changedPriceOrderExtraTip) {
            const { id, ...orderBody } = changedPriceOrderExtraTip;
            const operation = await orderApi.updateOrder(id, orderBody);
            if (isDefined(operation.id)) {
                if (isGuest) {
                    const merchantId = getLocalMerchantId();
                    if (merchantId) {
                        setShouldUpdateGuestSession(merchantId);
                    }
                }

                push(
                    ROUTES.QUICKPAY.STATUS,
                    {
                        operationId: operation.id.toString()
                    },
                    '',
                    { orderBodyToRecreatePat: { ...orderBody, id } }
                );
            }
        }
    }, [changedPriceOrderExtraTip, isGuest, push]);

    return (
        <InnerPageLayout>
            {error !== OperationErrorType.NONE && (
                <PayHeader variant="small">
                    <PayToolbar loading={globalLoading} disableBack={loading} disableEdit />
                </PayHeader>
            )}
            {(loading || error === OperationErrorType.THREEDSECURE_AUTH_REQUIRED) && (
                <Box className={clsx(classes.pageContainer, classes.pageCenter, classes.verticalCenter)}>
                    {RandomLoadingComponent}
                </Box>
            )}
            {!loading && error === OperationErrorType.GENERIC && (
                <Box className={clsx(classes.pageContainer, classes.pageCenter)}>
                    <PayError
                        errorMessage={t('OPERATION_STATUS_ERROR_LOADING', { errorMessage })}
                        showRetry={showRetry}
                        handleRetry={handleRetry}
                        lastRequest={lastRequest}
                        payInstead={payInstead}
                        onPayInstead={handlePay}
                        extraTips={extraTipValue}
                        onPayExtraTips={handlePayExtraTips}
                    />
                </Box>
            )}
            {!loading && error === OperationErrorType.NOT_FOUND && (
                <Box className={clsx(classes.pageContainer, classes.pageCenter)}>
                    <EmptyState
                        headerText={t('GENERAL_GENERIC_ERROR')}
                        paragraphText={t('ORDER_STATUS_NOT_FOUND')}
                    >
                        <ErrorOutline className={classes.basketError} />
                    </EmptyState>
                </Box>
            )}
        </InnerPageLayout>
    );
};
