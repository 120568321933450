import axios from 'axios';

export const getLoadingImagesFolder = (merchantId: string) =>
    `${process.env.MEDIA_URL}/tenants/${merchantId}/web_loading_images`;

function arrayBufferToBase64(buffer: Iterable<number>) {
    let binary = '';
    const bytes = [].slice.call(new Uint8Array(buffer));

    bytes.forEach(b => (binary += String.fromCharCode(b)));

    return window.btoa(binary);
}

export async function preloadImage(link: string) {
    const loaderExtensionType = link.split('.').pop();
    const finalizedDataType = loaderExtensionType === 'svg' ? 'svg+xml' : loaderExtensionType;
    try {
        const result = await axios.get(link, {
            responseType: 'arraybuffer'
        });
        const buffer = await result.data;
        const base64Flag = `data:image/${finalizedDataType};base64,`;
        return base64Flag + arrayBufferToBase64(buffer);
    } catch (e) {
        return undefined;
    }
}
