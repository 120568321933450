import { Layout } from 'components/layout/model/Layout';
import { LayoutAction } from './layoutActions';
import { LayoutActionTypes } from './layoutActionTypes';

export interface LayoutState {
    layout?: Layout;
    error: string;
    isLoading: boolean;
    tenantId: string;
}

export const initialState: LayoutState = {
    layout: undefined,
    error: '',
    isLoading: true,
    tenantId: ''
};

export default function (state: LayoutState = initialState, action: LayoutAction): LayoutState {
    switch (action.type) {
        case LayoutActionTypes.END_REQUEST:
            return { ...state, isLoading: false };
        case LayoutActionTypes.START_REQUEST:
            return { ...state, isLoading: true };
        case LayoutActionTypes.GET_LAYOUT_ERROR:
            return { ...state, layout: undefined, error: action.error };
        case LayoutActionTypes.GET_LAYOUT_SUCCESS:
            return { ...state, error: '', layout: action.layout, tenantId: action.tenantId };
        default:
            return state;
    }
}
