import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import {
    ESuggestionType,
    IProduct,
    isProductAvailable,
    ISuggestions,
    ProductGroup
} from '../components/menu/model/Menu';

interface SuggestionsContext {
    suggestion: ISuggestions | null;
    addedProduct: IProduct | null;
    suggestedProduct: IProduct | null;
    suggestedGroupProduct: IProduct | ProductGroup | undefined;
    showMoreSuggestionInfo: boolean;
    defaultSuggestedValue: IProduct | null;
    setSuggestion: (suggestion: ISuggestions | null) => void;
    setAddedProduct: (addedProduct: IProduct | null) => void;
    setSuggestedGroupProduct: (product: IProduct | ProductGroup | undefined) => void;
    setShowMoreSuggestionInfo: React.Dispatch<React.SetStateAction<boolean>>;
    setDefaultSuggestedValue: (product: IProduct) => void;
    isSuggestionTypeBogof: (product: IProduct) => boolean;
    isSuggestionTypeAdd: (product: IProduct) => boolean;
}

const useSuggestionsProvider = () => {
    const { menu } = useSelector((state: ApplicationState) => state.menu);
    const { suggestionShowed } = useSelector((state: ApplicationState) => state.basket);

    const [suggestion, setSuggestion] = React.useState<ISuggestions | null>(null);
    const [suggestedProduct, setSuggestedProduct] = React.useState<IProduct | null>(null);
    const [addedProduct, setAddedProduct] = React.useState<IProduct | null>(null);
    const [defaultSuggestedValue, setDefaultSuggestedValue] = React.useState<IProduct | null>(null);
    const [showMoreSuggestionInfo, setShowMoreSuggestionInfo] = React.useState(false);
    const [suggestedGroupProduct, setSuggestedGroupProduct] = React.useState<
        IProduct | ProductGroup | undefined
    >(undefined);

    const isSuggestionTypeBogof = (product: IProduct) =>
        !!(
            product?.suggestions &&
            product.suggestions.length &&
            product.suggestions[0].type === ESuggestionType.BOGOF
        );
    const isSuggestionTypeAdd = (item: IProduct) => {
        if (
            item?.suggestions &&
            item.suggestions.length &&
            item.suggestions[0].type === ESuggestionType.ADD &&
            !showMoreSuggestionInfo &&
            menu &&
            !suggestionShowed
        ) {
            const product = menu.productIdToProduct.get(String(item.suggestions[0]?.productId));
            if (product && isProductAvailable(product)) {
                setSuggestedProduct(product);
                return true;
            }

            return false;
        }
        return false;
    };

    return {
        suggestion,
        addedProduct,
        suggestedProduct,
        showMoreSuggestionInfo,
        defaultSuggestedValue,
        suggestedGroupProduct,
        setSuggestion,
        setAddedProduct,
        setSuggestedGroupProduct,
        setDefaultSuggestedValue,
        setShowMoreSuggestionInfo,
        isSuggestionTypeAdd,
        isSuggestionTypeBogof
    };
};
const SuggestionsContext = React.createContext<SuggestionsContext>({} as SuggestionsContext);

export const SuggestionsProvider: React.FC = ({ children }) => {
    const suggestion: SuggestionsContext = useSuggestionsProvider();
    return <SuggestionsContext.Provider value={suggestion}>{children}</SuggestionsContext.Provider>;
};

export const useSuggestionsContext = () => React.useContext(SuggestionsContext);
