/* eslint-disable react/no-multi-comp */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { DESKTOP_COLORS } from 'src/consts';
import { useResponsive } from 'src/hooks/useResponsive';
import { InfiniteLoader } from 'ui/InfiniteLoader';
import { Location, LocationsData } from './model/Location';
import { LocationCard } from './LocationCard';

interface LocationsListProps {
    list: Location[];
    loadMore: () => any;
    isLoading: boolean;
    hasNext: boolean;
    hasGeoLocation: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        locationSectionTitle: (props: { isDesktop: boolean }) => ({
            fontFamily: `TenantFont, ${theme.typography.fontFamily}`,
            textAlign: props.isDesktop ? 'center' : 'left',
            ...(props.isDesktop
                ? {
                      color: DESKTOP_COLORS.TEXT_PRIMARY
                  }
                : {})
        })
    })
);

export const LocationsList: React.FC<LocationsListProps> = props => {
    const { isDesktop } = useResponsive();
    const classes = useStyles({ isDesktop });
    const { t } = useTranslation();
    const { list, hasNext, loadMore, isLoading, hasGeoLocation } = props;

    const { closest, others } = React.useMemo(
        () =>
            list.reduce<{ closest: Location | null; others: Location[] }>(
                (acc, location) => {
                    // Compare closest location with previous one
                    if (!acc.closest && hasGeoLocation) {
                        acc.closest = location;
                    } else if (!!acc.closest && acc.closest.distance && location.distance) {
                        if (location.distance < acc.closest.distance) {
                            acc.closest = location;
                        }
                    }
                    if (acc.closest?._id !== location._id || !acc.closest) {
                        acc.others.push(location);
                    }

                    return acc;
                },
                { closest: null, others: [] }
            ),
        [list, hasGeoLocation]
    );
    const renderRow = React.useCallback(
        (location: Location, index: number, arr: Location[]) => (
            <Box key={location._id} marginBottom={index < arr.length - 1 && isDesktop ? 3.25 : 0}>
                <LocationCard location={location} />
            </Box>
        ),
        [isDesktop]
    );
    const handleListGetter = React.useCallback((data: LocationsData) => data.locations, []);
    return (
        <Box padding={1.5} width="100%" flex={1}>
            {!!closest && hasGeoLocation && (
                <>
                    <Typography variant="h6" className={classes.locationSectionTitle}>
                        {t('LOCATIONS_CLOSEST')}
                    </Typography>
                    <LocationCard location={closest} />
                </>
            )}
            {others.length > 0 && hasGeoLocation && (
                <Box marginTop={2}>
                    <Typography variant="h6" className={classes.locationSectionTitle}>
                        {t('LOCATIONS_OTHERS')}
                    </Typography>
                </Box>
            )}
            <InfiniteLoader<Location, LocationsData>
                list={others}
                loadMore={loadMore}
                dataListGetter={handleListGetter}
                isLoading={isLoading}
                hasNext={hasNext}
            >
                {(locations: Location[]) => locations.map(renderRow)}
            </InfiniteLoader>
        </Box>
    );
};
