import React from 'react';
import { IAddCardComponentClasses } from '../PaymentProvider';
import { StripeAddCardComponent } from './StripeAddCardComponent';
import { StripeCardProvider } from './StripeCardProvider';

interface IProps {
    postCardAddition: () => void;
    classes?: IAddCardComponentClasses;
}

export const StripeAddCard: React.FC<IProps> = ({ postCardAddition, classes }) => (
    <StripeCardProvider>
        <StripeAddCardComponent postCardAddition={postCardAddition} classes={classes} />
    </StripeCardProvider>
);
