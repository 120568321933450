import React from 'react';
import {
    Box,
    Checkbox,
    createStyles,
    makeStyles,
    MenuItem,
    Radio,
    Theme,
    Typography
} from '@material-ui/core';

export interface IModifierSelectOption {
    label: string;
    description?: string;
    value: string;
    disabled?: boolean;
    image?: string;
}

interface ModifierSelectOptionProps {
    item: IModifierSelectOption;
    multi: boolean;
    selected: boolean;
    disabled: boolean;
    onClick: (value: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        label: {
            whiteSpace: 'pre-wrap'
        },
        description: {
            opacity: 0.7
        },
        image: {
            height: theme.spacing(5),
            width: theme.spacing(5),
            backgroundPosition: 'center',
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
            backgroundSize: 'cover',
            marginLeft: 'auto'
        },
        icon: {
            padding: 0,
            color: theme.palette.text.hint,
            '&.Mui-checked': {
                color: theme.palette.text.hint
            }
        }
    })
);

export const ModifierSelectOption: React.FC<ModifierSelectOptionProps> = ({
    item,
    multi,
    selected,
    disabled,
    onClick
}) => {
    const classes = useStyles();
    const handleClick = React.useCallback(() => {
        onClick(item.value);
    }, [onClick, item.value]);
    return (
        <MenuItem value={item.value} key={item.value} disabled={disabled} onClick={handleClick}>
            <Box flex={1}>
                <Typography className={classes.label}>{item.label}</Typography>
                {item.description && (
                    <Typography className={classes.description} variant="caption">
                        {item.description}
                    </Typography>
                )}
            </Box>
            {!!item.image && (
                <div className={classes.image} style={{ backgroundImage: `url(${item.image})` }} />
            )}
            {multi ? (
                <Checkbox className={classes.icon} disableRipple disableTouchRipple checked={selected} />
            ) : (
                <Radio className={classes.icon} disableRipple disableTouchRipple checked={selected} />
            )}
        </MenuItem>
    );
};
