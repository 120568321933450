import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { ELineIconName } from './enum';

interface IProps {
    name: ELineIconName | string;
    fontSize: number;
}

const useStyles = (name: string, fontSize: number) =>
    makeStyles(() =>
        createStyles({
            icon: {
                fontFamily: 'SimpleLineIcon',
                '&::after': {
                    content: `"\\${name}"`,
                    fontSize: `${fontSize}px`
                }
            }
        })
    )();

export const LineIcon: React.FC<IProps> = ({ name, fontSize }) => {
    const classes = useStyles(name, fontSize);

    return <i className={classes.icon}></i>;
};
