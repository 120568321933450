export interface Journey {
    url?: string;
}

const getJourneyKey = (merchantId: string, name?: string) => `${name ?? 'journey'}-${merchantId}`;
const JOURNEY_EXP_KEY = 'journey-exp';

export const updateLocalJourney = (merchantId: string, journey: Journey) => {
    localStorage.setItem(getJourneyKey(merchantId), JSON.stringify(journey));
    localStorage.setItem(getJourneyKey(merchantId, JOURNEY_EXP_KEY), (Date.now() + 86400000).toString());
};

export const resetLocalJourney = (merchantId: string) => {
    localStorage.removeItem(getJourneyKey(merchantId));
    localStorage.removeItem(getJourneyKey(merchantId, JOURNEY_EXP_KEY));
};

export const getLocalJourney = (merchantId: string): Journey | null => {
    const journeyString = localStorage.getItem(getJourneyKey(merchantId));
    const expiration = localStorage.getItem(getJourneyKey(merchantId, JOURNEY_EXP_KEY));
    if (!journeyString || !expiration || Date.now() - parseInt(expiration, 10) > 86400000) {
        resetLocalJourney(merchantId);
        return null;
    }
    return JSON.parse(journeyString);
};
