export const getLocalMerchantId = () => localStorage.getItem('merchantId');

export const updateLocalSettings = (merchantId: string, applicationId: string) => {
    localStorage.setItem('applicationId', applicationId);
    localStorage.setItem('merchantId', merchantId);
};

export const resetLocalSettings = () => {
    localStorage.removeItem('applicationId');
    localStorage.removeItem('merchantId');
};

export const getLocalSettings = () => {
    const applicationId = localStorage.getItem('applicationId');
    const merchantId = getLocalMerchantId();
    if (!applicationId || !merchantId) {
        resetLocalSettings();
        return null;
    }
    return { applicationId, merchantId };
};
