import { ISettings } from 'components/settings/model/Settings';
import { Timeslot } from 'components/timeslots/model/Timeslot';
import { SettingsAction } from './settingsActions';
import { SettingsActionTypes } from './settingsActionTypes';

export type SettingsStateSettings = ISettings & { applicationId: string; title: string; locationId: string };
export interface SettingsState {
    settings?: SettingsStateSettings;
    merchantId: string;
    error: string;
    isLoading: boolean;
    isInitiallyLoaded: boolean;
    timeslots?: Timeslot[];
    loadingImage?: string;
}

export const initialState: SettingsState = {
    settings: undefined,
    error: '',
    merchantId: '',
    isLoading: true,
    isInitiallyLoaded: false
};

export default function (state: SettingsState = initialState, action: SettingsAction): SettingsState {
    switch (action.type) {
        case SettingsActionTypes.END_REQUEST:
            return { ...state, isLoading: false };
        case SettingsActionTypes.START_REQUEST:
            return { ...state, isLoading: true };
        case SettingsActionTypes.GET_SETTINGS_ERROR:
            return { ...state, settings: undefined, error: action.error, merchantId: '' };
        case SettingsActionTypes.GET_SETTINGS_SUCCESS:
            return {
                ...state,
                error: '',
                settings: action.settings,
                isInitiallyLoaded: true,
                merchantId: action.merchantId
            };
        case SettingsActionTypes.GET_TIMESLOTS_ERROR:
            return { ...state, timeslots: undefined, error: action.error };
        case SettingsActionTypes.GET_TIMESLOTS_SUCCESS:
            return { ...state, timeslots: action.timeslots, error: '' };
        case SettingsActionTypes.GET_LOADING_IMAGE:
            return { ...state, loadingImage: action.loadingImage };
        default:
            return state;
    }
}
