import React from 'react';
import { useQuery } from 'src/hooks/useQuery';
import { isEmptyString } from 'lib/typeInference';
import { decodeDateCheckNumString, formatDateCheckNum } from '../model/helpers';

interface IPayQueryParams {
    checkId?: string;
    date?: string;
    tableNumber?: string;
}

export function usePayQueryParams() {
    const queryTableNumber = useQuery('tableNumber');
    const queryDateCheckIdB64 = useQuery('dateCheckIdB64');
    const queryDateCheckId = useQuery('dateCheckId');
    const queryCheckId = useQuery('checkId');

    const { checkId, date, tableNumber } = React.useMemo<IPayQueryParams>(() => {
        if (!isEmptyString(queryDateCheckIdB64)) {
            return decodeDateCheckNumString(queryDateCheckIdB64);
        }
        if (!isEmptyString(queryDateCheckId)) {
            return formatDateCheckNum(queryDateCheckId);
        }
        if (!isEmptyString(queryCheckId)) {
            return { checkId: queryCheckId, date: undefined };
        }
        if (!isEmptyString(queryTableNumber)) {
            return { tableNumber: queryTableNumber };
        }
        return {};
    }, [queryCheckId, queryDateCheckIdB64, queryDateCheckId, queryTableNumber]);

    return { checkId, date, tableNumber };
}
