import React from 'react';
import { IOrderCreatePaymentV8 } from 'components/order/model/Order';
import { orderApi } from 'components/order/orderApi';
import { ISettings } from 'components/settings/model/Settings';
import logger from 'lib/logger';
import { IPaymentProvider, ThreeDSecureAuthenticationData } from '../PaymentProvider';
import { WorldpayAddCard, WorldpayAddCardProps } from './WorldpayAddCard';
import { isString } from 'lib/typeInference';
interface Worldpay {
    checkout?: any;
}

declare global {
    // eslint-disable-next-line no-var
    var Worldpay: Worldpay | undefined;
}

export class WorldpayProvider implements IPaymentProvider {
    private sessionId?: string = undefined;
    private collectionFinished = false;
    private TransactionId?: string = undefined;
    private challengeFinished = false;
    private transactionReference?: string = undefined;
    private ddcSrc = this.settings?.isProduction
        ? 'https://access.worldpay.com/access-checkout/v2/checkout.js'
        : 'https://try.access.worldpay.com/access-checkout/v2/checkout.js';

    constructor(private settings: ISettings['worldpay']) {
        this.init();
    }
    public get isScriptInjected() {
        return document.querySelector(`script[src="${this.ddcSrc}"]`);
    }
    public reset() {
        const [form3ds] = document.getElementsByClassName('Worldpay-3DSForm');
        if (form3ds) {
            form3ds.remove();
        }
        const [formDdc] = document.getElementsByClassName('collectionForm');
        if (formDdc) {
            formDdc.remove();
        }
        this.TransactionId = undefined;
        this.challengeFinished = false;
        this.sessionId = undefined;
        this.collectionFinished = false;
    }
    public async threeDSecureAuthenticate<T extends { payments: IOrderCreatePaymentV8[] }>(
        data: ThreeDSecureAuthenticationData,
        order: T
    ) {
        if (!data.worldpay) {
            return Promise.resolve(null);
        }
        window.addEventListener('message', this.listenWorldpay.bind(this));
        this.create3DSForm(
            data.worldpay.challenge.url,
            data.worldpay.challenge.jwt,
            data.worldpay.challenge.reference
        );
        const result = await this.waitFor3DS();
        const orderBody: T = {
            ...order,
            payments: order.payments.map(payment => {
                const currentAuth = payment.auth || {};
                const currentChallenge = currentAuth.threeDSecureData?.challenge;
                return {
                    ...payment,
                    auth: {
                        ...payment.auth,
                        threeDSecureData: {
                            deviceData: currentAuth.threeDSecureData?.deviceData,
                            challenge: { returnUrl: currentChallenge?.returnUrl, reference: result },
                            transactionReference: this.transactionReference
                        }
                    }
                };
            })
        };
        this.reset();
        return Promise.resolve(orderBody);
    }
    public createAddCardComponent(
        postCardAddition: () => void
    ): React.FunctionComponentElement<WorldpayAddCardProps> {
        return React.createElement(WorldpayAddCard, {
            merchantId: this.settings?.checkoutId || '',
            postCardAddition
        });
    }
    public async collectDeviceData() {
        try {
            const result = await orderApi.createDeviceData();
            window.addEventListener('message', this.listenMessage.bind(this));
            const {
                deviceData: { url, bin, jwt },
                transactionReference
            } = result;
            this.transactionReference = transactionReference;
            this.createDDCForm(url, bin, jwt);
            const sessionId = await this.waitForDDC();
            window.removeEventListener('message', this.listenMessage.bind(this));
            this.reset();
            return { sessionId, transactionReference };
        } catch (e) {
            logger.error(e);
            this.reset();
            window.removeEventListener('message', this.listenMessage.bind(this));
            return null;
        }
    }
    private waitForDDC(): Promise<string> {
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (this.collectionFinished) {
                    clearInterval(interval);
                    if (this.sessionId) {
                        resolve(this.sessionId);
                    } else {
                        reject(new Error("Couldn't get SessionId"));
                    }
                }
            }, 500);
        });
    }
    private createDDCForm(actionUrl: string, bin: string, jwt: string) {
        const iFrameWrapper = document.createElement('div');
        const iFrame = document.createElement('iframe');
        iFrame.height = '1';
        iFrame.width = '1';
        iFrame.style.display = 'none';
        iFrameWrapper.className = 'collectionForm';

        iFrame.srcdoc = `
        <!-- Set the action to the value in the 'deviceDataCollection.url' from the device data initialization response --->
        <form id="collectionForm" name="devicedata" method="POST" action="${actionUrl}">

          <input type="hidden" name="Bin" value="${bin}" />
          <!-- Use value from 'deviceDataCollection.bin' from the device data initialization response or add the card number -->

          <!-- Set to the value of 'deviceDataCollection.jwt' from the device data initialization response  --->
          <input type="hidden" name="JWT" value="${jwt}" />

          <script>
          window.onload = function() {
            document.getElementById('collectionForm').submit();
          }
          </script>

        </form>`;
        iFrameWrapper.appendChild(iFrame);
        document.getElementsByTagName('body')[0].appendChild(iFrameWrapper);
    }
    private waitFor3DS(): Promise<string> {
        return new Promise((resolve, reject) => {
            const interval = setInterval(() => {
                if (this.challengeFinished) {
                    clearInterval(interval);
                    if (this.TransactionId) {
                        resolve(this.TransactionId);
                    } else {
                        reject(new Error("Couldn't get SessionId"));
                    }
                }
            }, 500);
        });
    }
    private create3DSForm(actionUrl: string, jwt: string, reference: string) {
        const iFrameWrapper = document.createElement('div');
        const iFrame = document.createElement('iframe');
        iFrame.height = `${window.innerHeight}`;
        iFrame.width = `${window.innerWidth}`;
        iFrameWrapper.className = 'Worldpay-3DSForm';

        iFrame.srcdoc = `
        <form id="challengeForm" method="POST" action="${actionUrl}">
          <input type="hidden" name="JWT" value="${jwt}" />
          <input type="hidden" name="MD" value="merchantSessionId=${reference}" />
        </form>

        <script>
            window.onload = function() {
                // Auto submit form on page load
                document.getElementById('challengeForm').submit();
            }
        </script>`;
        iFrameWrapper.appendChild(iFrame);
        document.getElementsByTagName('body')[0].appendChild(iFrameWrapper);
    }
    private listenMessage(event: { data: any; origin: string; source: any }) {
        const cardinalOrigin = this.settings?.isProduction
            ? 'https://centinelapi.cardinalcommerce.com'
            : 'https://centinelapistag.cardinalcommerce.com';
        if (event.origin.startsWith(cardinalOrigin)) {
            // The data was sent from your site.
            // Data sent with postMessage is stored in event.data:
            const data = JSON.parse(event.data);
            if (data.Status === true && data.SessionId) {
                this.sessionId = data.SessionId;
            }
            this.collectionFinished = true;
        }
    }
    private listenWorldpay(event: { data?: string; origin: string; source: any }) {
        const originUrl = window.location.origin;
        if (
            event.origin.startsWith(originUrl) &&
            isString(event.data) &&
            event.data.includes('TransactionId')
        ) {
            this.TransactionId = JSON.parse(event.data).TransactionId;
            this.challengeFinished = true;
        }
    }
    private injectScript() {
        const worldpaySdkScript = document.createElement('script');
        worldpaySdkScript.src = this.ddcSrc;
        worldpaySdkScript.type = 'text/javascript';
        worldpaySdkScript.async = false;
        document.getElementsByTagName('head')[0].appendChild(worldpaySdkScript);
    }
    private init() {
        if (!this.isScriptInjected) {
            this.injectScript();
        }
    }
}
