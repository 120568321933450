/* eslint-disable react/no-multi-comp */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { SuggestionsProvider } from 'src/hooks/useSuggestionsContext';
import { makeRouteScenario } from 'src/utils/route';
import { MerchantTitle } from 'app/MerchantTitle';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { NotWorkingHour } from 'components/location/NotWorkingHour';
import { OrderingDisabled } from 'components/location/OrderingDisabled';
import { OrderToTableLocationPicker } from 'components/location/OrderToTableLocationPicker';
import { ServiceNotAvailable } from 'components/location/ServiceNotAvailable';
import { MenuDesktop } from 'components/menu/Desktop/MenuDesktop';
import { MenuDesktopSkeleton } from 'components/menu/Desktop/MenuDesktopSkeleton';
import { MenuCategoryGroupsList } from 'components/menu/MenuCategoryGroupsList';
import { MenuList } from 'components/menu/MenuList';
import { MenuSkeleton } from 'components/menu/MenuSkeleton';
import { MenuViewBasketButton } from 'components/menu/MenuViewBasketButton';
import { filterCategoryGroups, ICategoryGroup } from 'components/menu/model/Menu';
import { OrderScenario } from 'components/order/model/Order';
import { TimeslotPicker } from 'components/timeslots/picker/TimeslotPicker';
import { useTimeSlotHelpers } from 'components/timeslots/useTimeSlotHelpers';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { ResponsiveLayout } from 'lib/Responsive';
import { Throbber } from 'lib/Throbber';
import { useLocalHistory } from 'lib/useLocalHistory';
import { resetGuestState } from 'store/guest/guestActions';
import { getMenu } from 'store/menu/menuActions';
import { getRecentOrders } from 'store/orders/ordersActions';
import { loadTimeslots } from 'store/settings/settingsActions';
import { ApplicationState } from 'store/store';
import { LocationRouteParams, ROUTES } from './routes';
import { LocationState } from 'components/location/model/Location';

const useStyles = makeStyles({
    pageContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        overflowY: 'hidden',
        flexDirection: 'column'
    }
});

export const MenuPage: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { locationId } = useParams<LocationRouteParams>();
    const [scenario] = useRouteScenario();
    const dispatch = useDispatch();
    const { isTimeSlotsAvailable, asapEnabled } = useTimeSlotHelpers();
    const { isWorkingHour, isLocationOrderEnabled, checkServiceAvailability } = useLocationHelpers();
    const [selectedCategoryGroup, setSelectedCategoryGroup] = React.useState<ICategoryGroup>();
    const {
        isLoading: isMenuLoading,
        menu,
        categories
    } = useSelector((state: ApplicationState) => state.menu);
    const { deliveryLocation, timeSlot } = useSelector((state: ApplicationState) => state.basket);
    const settings = useSelector((state: ApplicationState) => state.settings.settings);
    const { push, replace } = useLocalHistory();

    const { isCurrentLocationLoading, currentLocation } = useSelector(
        (state: ApplicationState) => state.locations
    );
    const { recentOrders } = useSelector((state: ApplicationState) => state.orders);

    const scenarioToUse = React.useMemo(() => scenario ?? OrderScenario.PREORDER, [scenario]);

    React.useEffect(() => {
        if (!scenario) {
            push(ROUTES.MENU, { scenario: makeRouteScenario(OrderScenario.PREORDER) });
        }
    }, [push, scenario]);

    React.useEffect(() => {
        if (currentLocation && currentLocation.state === LocationState.RETIRED) {
            replace(ROUTES.BASE);
        }
    }, [currentLocation, replace]);

    React.useEffect(() => {
        getMenu(
            locationId || '',
            timeSlot?.start,
            scenarioToUse,
            t('DIALOG_TIMESLOT_CHANGED_MESSAGE'),
            deliveryLocation
        )(dispatch);
    }, [dispatch, locationId, scenarioToUse, timeSlot?.start, t, deliveryLocation]);

    React.useEffect(() => {
        resetGuestState(dispatch);
    }, [dispatch]);

    const isLoading = React.useMemo(() => isMenuLoading || !settings, [isMenuLoading, settings]);

    React.useEffect(() => {
        if (!!menu && !isLoading && !!scenarioToUse) {
            getRecentOrders(menu, { scenario: scenarioToUse, locationId })(dispatch);
        }
    }, [dispatch, locationId, menu, scenarioToUse, isLoading]);

    const orderAhead = React.useMemo(
        () =>
            scenarioToUse === OrderScenario.PREORDER &&
            (settings?.asapOrderingEnabled || settings?.timeSlotsEnabled),
        [scenarioToUse, settings?.asapOrderingEnabled, settings?.timeSlotsEnabled]
    );
    React.useEffect(() => {
        if (orderAhead) {
            loadTimeslots(locationId || '', timeSlot, t('DIALOG_PREORDER_TIMESLOT_NOT_AVAILABLE'))(dispatch);
        }
    }, [dispatch, locationId, orderAhead, t, timeSlot]);

    const categoryGroupsEnabled = React.useMemo(
        () => !!settings && !!settings.app && !!settings.app.showCategoryGroups,
        [settings]
    );

    const validCategoryGroupsToShow = React.useMemo(
        () =>
            categoryGroupsEnabled &&
            !!menu &&
            !!menu.categories &&
            !!menu.categoryGroups &&
            filterCategoryGroups(Object.values(menu.categoryGroups), menu.categories).sort(
                (a, b) => a.sort - b.sort
            ),
        [menu, categoryGroupsEnabled]
    );

    const hasCategoryGroups = React.useMemo(
        () => !!validCategoryGroupsToShow && validCategoryGroupsToShow.length > 1,
        [validCategoryGroupsToShow]
    );

    const noValidCategoryGroups = React.useMemo(
        () =>
            categoryGroupsEnabled &&
            (validCategoryGroupsToShow ? validCategoryGroupsToShow.length === 0 : true),
        [categoryGroupsEnabled, validCategoryGroupsToShow]
    );

    const recentOrdersToShow = React.useMemo(() => {
        if (!!recentOrders && !!settings && !!settings.app && settings.app.showRecentOrders !== false) {
            if (hasCategoryGroups) {
                return recentOrders.slice(0, 10);
            }
            return recentOrders.slice(0, 3);
        }
    }, [hasCategoryGroups, recentOrders, settings]);

    const categoriesToShow = React.useMemo(() => {
        if (hasCategoryGroups) {
            if (!!selectedCategoryGroup) {
                return categories.filter(category => selectedCategoryGroup.categoryIds.includes(category.id));
            }
        } else if (!!validCategoryGroupsToShow && validCategoryGroupsToShow.length === 1) {
            return categories.filter(category =>
                validCategoryGroupsToShow[0].categoryIds.includes(category.id)
            );
        } else {
            return categories;
        }
    }, [categories, hasCategoryGroups, selectedCategoryGroup, validCategoryGroupsToShow]);

    const handleOnBack = React.useCallback(() => {
        if (!!selectedCategoryGroup) {
            setSelectedCategoryGroup(undefined);
        } else if (scenarioToUse && scenarioToUse === OrderScenario.ORDER_TO_TABLE) {
            push(ROUTES.JOURNEY.LANDING);
        } else if (scenarioToUse === OrderScenario.TABLE) {
            push(ROUTES.JOURNEY.PAT.BILL);
        } else {
            push(ROUTES.BASE);
        }
    }, [push, scenarioToUse, selectedCategoryGroup]);
    const title = React.useMemo(() => {
        if (scenarioToUse === OrderScenario.TABLE) {
            return t('OTT_TITLE');
        }

        return currentLocation ? currentLocation.title : 'Unknown menu';
    }, [currentLocation, scenarioToUse, t]);

    const handleCategoryGroupClick = React.useCallback((categoryGroup: ICategoryGroup) => {
        setSelectedCategoryGroup(categoryGroup);
    }, []);

    const shouldShowTablePicker = React.useMemo(
        () =>
            !!menu &&
            (scenarioToUse === OrderScenario.ORDER_TO_TABLE || scenarioToUse === OrderScenario.TABLE) &&
            checkServiceAvailability(scenarioToUse),
        [checkServiceAvailability, menu, scenarioToUse]
    );
    const shouldShowTimeslotPicker = React.useMemo(() => !!menu && orderAhead, [menu, orderAhead]);

    const showNotWorkingHour = React.useMemo(
        () =>
            !isWorkingHour ||
            (scenarioToUse === OrderScenario.PREORDER && !asapEnabled && !isTimeSlotsAvailable),
        [asapEnabled, isTimeSlotsAvailable, isWorkingHour, scenarioToUse]
    );

    if (isCurrentLocationLoading) {
        return <Throbber text={t('GENERAL_LOADING')} />;
    }

    if (isLoading) {
        return (
            <ResponsiveLayout
                renderMobileView={<MenuSkeleton />}
                renderDesktopView={<MenuDesktopSkeleton />}
            />
        );
    }

    return (
        <Box display="flex" flexDirection="column" width="100%" height="100%" className="hidden-scroll">
            <MerchantTitle title={currentLocation?.title} />
            <MuiAppBar title={title} onBack={handleOnBack} />
            <ResponsiveLayout
                renderMobileView={
                    <Box className={classes.pageContainer}>
                        <Box display="flex" flexDirection="column" justifyContent="center" width="100%">
                            {shouldShowTimeslotPicker && <TimeslotPicker />}
                            {shouldShowTablePicker && (
                                <OrderToTableLocationPicker
                                    isDialogOpenInitially={!deliveryLocation}
                                    searchButton
                                />
                            )}
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            width="100%"
                            height="100%"
                            overflow="hidden"
                        >
                            {!menu || noValidCategoryGroups ? (
                                <Box padding={2}>
                                    <Typography>{t('MENU_EMPTY')}</Typography>
                                </Box>
                            ) : (
                                <>
                                    {!!menu && !categoriesToShow && (
                                        <>
                                            {showNotWorkingHour && (
                                                <NotWorkingHour scenario={scenarioToUse} />
                                            )}
                                            {!isLocationOrderEnabled && <OrderingDisabled />}
                                            <MenuCategoryGroupsList
                                                recentOrders={recentOrdersToShow}
                                                onClick={handleCategoryGroupClick}
                                                categoryGroups={
                                                    !!validCategoryGroupsToShow
                                                        ? validCategoryGroupsToShow
                                                        : undefined
                                                }
                                            />
                                        </>
                                    )}
                                    {!!menu && !!categoriesToShow && (
                                        <SuggestionsProvider>
                                            <MenuList
                                                showNotWorkingHour={showNotWorkingHour}
                                                recentOrders={recentOrdersToShow}
                                                menu={menu}
                                                hasCategoryGroups={hasCategoryGroups}
                                                categories={categoriesToShow}
                                                currency={settings?.region.currencyCode || ''}
                                            />
                                        </SuggestionsProvider>
                                    )}
                                </>
                            )}
                            {!checkServiceAvailability(scenarioToUse) && (
                                <ServiceNotAvailable scenario={scenarioToUse} />
                            )}
                            <MenuViewBasketButton />
                        </Box>
                    </Box>
                }
                renderDesktopView={
                    <SuggestionsProvider>
                        <MenuDesktop
                            showCategoryGroups={hasCategoryGroups}
                            categoryGroups={validCategoryGroupsToShow || []}
                            menu={menu}
                            recentOrders={recentOrdersToShow}
                            orderAhead={!!orderAhead}
                            noValidCategoryGroups={noValidCategoryGroups}
                        />
                    </SuggestionsProvider>
                }
            />
        </Box>
    );
};
