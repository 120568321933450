import {
    IItemReadResourceV10,
    isOptionModifiersValid,
    isProductModifierValid
} from 'components/basket/model/Basket';
import { IEnrichedMenuWithModifierMaps, IEnrichedProduct, IProduct } from 'components/menu/model/Menu';
import { isDefined } from 'lib/typeInference';

function isRecentOrderValid(
    products: IEnrichedProduct[],
    items: IItemReadResourceV10[],
    menu: IEnrichedMenuWithModifierMaps
) {
    return (item: IItemReadResourceV10) => {
        const product = menu.productIdToProduct.get(item.productId);
        if (!product || (isDefined(product.available) && !product.available)) {
            return false;
        }
        const productModifierValid = isProductModifierValid(item, products, items);
        const optionsModifierValid =
            !item.modifiers ||
            !item.modifiers.length ||
            item.modifiers.every(isOptionModifiersValid(menu, item.categoryId));
        const isCategoryValid = menu.categories.some(category => category.id === item.categoryId);
        return productModifierValid && optionsModifierValid && isCategoryValid;
    };
}

export function validateRecentOrder(items: IItemReadResourceV10[], menu: IEnrichedMenuWithModifierMaps) {
    const products = items.reduce<IProduct[]>((acc, currentItem) => {
        const prod = menu.productIdToProduct.get(currentItem.productId);
        if (!!prod) {
            acc.push(prod);
        }

        return acc;
    }, []);
    const newItems = items.filter(isRecentOrderValid(products, items, menu));
    return newItems.length === items.length;
}
