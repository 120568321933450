import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Divider, Grid, List, ListItem, Paper, Typography, useTheme } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import random from 'lodash/random';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { ApplicationState } from 'store/store';
import { useStyles as useDesktopViewStyles } from './MenuDesktop';
import { useStyles as useSideBarStyles } from './MenuSidebar';

export const MenuDesktopSkeleton: React.FC = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const desktopViewStyles = useDesktopViewStyles();
    const sideBarStyles = useSideBarStyles({ showCategoryGroups: false });
    const { currentLocation } = useSelector((state: ApplicationState) => state.locations);
    const renderSidebarSkeleton = React.useCallback(
        (_, index: number) => (
            <ListItem key={index} className={sideBarStyles.categoryItem} button>
                <Typography variant="body1" className={sideBarStyles.categoryItemText}>
                    <Skeleton variant="text" width={random(30, 50)} />
                </Typography>
            </ListItem>
        ),
        [sideBarStyles.categoryItem, sideBarStyles.categoryItemText]
    );

    const renderCategorySkeleton = React.useCallback(
        (_, index: number) => (
            <React.Fragment key={index}>
                <Box marginY={1} display="flex" justifyContent="space-between">
                    <Box>
                        <Skeleton width={random(40, 80)} variant="text" />
                        <Skeleton width={random(70, 150)} variant="text" />
                        <Skeleton width={40} variant="text" />
                    </Box>
                    {random(1, 0) === 1 && (
                        <Skeleton variant="rect" width={theme.spacing(10)} height={theme.spacing(10)} />
                    )}
                </Box>
                <Divider />
            </React.Fragment>
        ),
        [theme]
    );

    const renderMenuSkeleton = React.useCallback(
        (val, i) => (
            <div key={i + val}>
                <Skeleton height={230} width="100%" variant="rect" />
                <Box marginY={2}>
                    <Skeleton width={random(30, 70)} />
                </Box>
                <Divider />
                {Array(8).fill('').map(renderCategorySkeleton)}
            </div>
        ),
        [renderCategorySkeleton]
    );

    return (
        <>
            <MuiAppBar title={currentLocation ? currentLocation.title : 'Mechu'} />
            <Box paddingTop={4} height="100%" flex="1">
                <Grid container spacing={2} className={desktopViewStyles.container}>
                    <Grid item xs={4} className={desktopViewStyles.scrollingElement}>
                        <Box>
                            <Paper>
                                <Box paddingTop={3} paddingBottom={2}>
                                    <Typography className={sideBarStyles.categoryTitle} variant="h6">
                                        {t('OUR_MENU')}
                                    </Typography>
                                </Box>
                                <List>{Array(8).fill('').map(renderSidebarSkeleton)}</List>
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid item xs={8} className={desktopViewStyles.scrollingElement}>
                        <Paper>
                            <List>
                                <ListItem>
                                    <Box display="flex" flexDirection="column" width="100%">
                                        {Array(2).fill('').map(renderMenuSkeleton)}
                                    </Box>
                                </ListItem>
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};
