import { IOrderCreatePaymentV8, OrderPaymentType } from 'components/order/model/Order';
import { roundToDecimal } from 'lib/helpers';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

export function useOrderGiftcardCalculator(
    totalAfterSplit: number,
    tipValue?: number,
    giftCard?: string,
    giftCardBalance?: number
) {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const totalAfterSplitWithTip = React.useMemo(
        () => totalAfterSplit + (tipValue ?? 0),
        [totalAfterSplit, tipValue]
    );
    const toPayWithGiftcard = React.useMemo(
        () => (settings?.giftCardPayments?.tippingIncluded ? totalAfterSplitWithTip : totalAfterSplit),
        [settings?.giftCardPayments?.tippingIncluded, totalAfterSplitWithTip, totalAfterSplit]
    );
    const giftCardRemainingBalance = React.useMemo(() => {
        if (giftCard && giftCardBalance) {
            return giftCardBalance > toPayWithGiftcard ? giftCardBalance - toPayWithGiftcard : 0;
        }
        return undefined;
    }, [toPayWithGiftcard, giftCard, giftCardBalance]);
    const giftCardPaymentAmount = React.useMemo(() => {
        if (giftCard && giftCardBalance) {
            return giftCardBalance > toPayWithGiftcard ? toPayWithGiftcard : giftCardBalance;
        }
        return 0;
    }, [giftCard, giftCardBalance, toPayWithGiftcard]);
    const giftCardPayment = React.useMemo<IOrderCreatePaymentV8 | undefined>(() => {
        if (giftCardPaymentAmount) {
            return {
                amount: giftCardPaymentAmount,
                auth: {
                    giftCardNumber: giftCard
                },
                provider: settings?.giftCardPaymentProvider,
                type: OrderPaymentType.GIFT_CARD
            };
        }
        return undefined;
    }, [giftCardPaymentAmount, giftCard, settings?.giftCardPaymentProvider]);
    const totalValue = React.useMemo(
        () => totalAfterSplitWithTip - giftCardPaymentAmount,
        [giftCardPaymentAmount, totalAfterSplitWithTip]
    );
    return {
        giftCardPayment,
        giftCardRemainingBalance,
        totalValue: roundToDecimal(totalValue)
    };
}
