import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { IOrderReadResourceV12 } from 'components/order/model/Order';
import { BottomDialog } from 'lib/BottomDialog';
import { useCurrencyString } from 'lib/useCurrencyString';
import { Counter } from './Counter';

interface IProps {
    order: IOrderReadResourceV12;
    isOpen: boolean;
    onToggle: () => void;
    onSubmit: (value: { splitPart: number; splitNumber?: number }) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            textAlign: 'center',
            fontFamily: `TenantFont, ${theme.typography.fontFamily}`,
            fontWeight: 'bold',
            color: theme.palette.titleTextColour
        },
        total: {
            fontWeight: 'bold',
            textAlign: 'center'
        }
    })
);

export const SplitByPercentage: React.FC<IProps> = ({ isOpen, onToggle, order, onSubmit }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const getCurrencyString = useCurrencyString();

    const joinedGuestCount = React.useMemo(() => {
        const count = order.users.filter(user => !user.hasLeft).length;

        return count >= 2 ? count : 2;
    }, [order.users]);
    const isSplitEnabled = React.useMemo(() => !order.splitNumber, [order.splitNumber]);
    const [guestCount, setGuestCount] = React.useState(() => {
        if (!order.splitNumber) {
            return joinedGuestCount;
        }
        return order.splitNumber || 2;
    });

    const orderTotal = React.useMemo(() => order.total.splittableTotal || 0, [order.total.splittableTotal]);
    const perGuestAmount = React.useMemo(
        () => Number((orderTotal / guestCount).toFixed(2)),
        [orderTotal, guestCount]
    );
    const [payingFor, setPayingFor] = React.useState(1);

    const handleSubmit = React.useCallback(() => {
        if (isSplitEnabled) {
            onSubmit({ splitNumber: guestCount, splitPart: payingFor });
        } else {
            onSubmit({ splitPart: payingFor });
        }
    }, [guestCount, isSplitEnabled, onSubmit, payingFor]);

    return (
        <BottomDialog open={isOpen} onClose={onToggle}>
            <Typography className={classes.title} variant="h6">
                {t('SPLIT_BILL_BY_PERCENT_TITLE')}
            </Typography>
            <Box marginTop={1}>
                <Typography className={classes.total}>{getCurrencyString(orderTotal)}</Typography>
            </Box>
            {isSplitEnabled ? (
                <Box display="flex" alignItems="center" flexDirection="column" marginTop={2}>
                    <Typography variant="body1">{t('BILL_SPLIT_BY_PERCENT_COVERS_LABEL')}</Typography>
                    <Counter min={2} max={14} value={guestCount} onChange={setGuestCount} />
                </Box>
            ) : (
                <Box marginY={2}>
                    <Typography align="center" variant="body1">
                        {t('BILL_SPLIT_BY_PERCENT_COVERS_COUNT', { count: guestCount })}
                    </Typography>
                </Box>
            )}
            <Box>
                <Paper variant="outlined">
                    <Box padding={2}>
                        <Typography align="center" variant="body1">
                            {t('BILL_SPLIT_BY_PERCENT_PER_GUEST_LABEL')}
                        </Typography>
                        <Typography align="center" variant="h5">
                            {getCurrencyString(Number(perGuestAmount))}
                        </Typography>
                    </Box>
                </Paper>
            </Box>
            <Box display="flex" alignItems="center" flexDirection="column" marginTop={2}>
                <Typography variant="body1">{t('BILL_SPLIT_BY_PERCENT_COUNT_LABEL')}</Typography>
                <Counter min={1} max={guestCount} value={payingFor} onChange={setPayingFor} />
            </Box>
            <Box marginTop={1}>
                <Button color="primary" onClick={handleSubmit} variant="contained" fullWidth>
                    {t('BILL_SPLIT_BY_PERCENT_PAY_BTN', {
                        amount: getCurrencyString(payingFor * perGuestAmount)
                    })}
                </Button>
            </Box>
        </BottomDialog>
    );
};
