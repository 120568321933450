/* eslint-disable react/no-multi-comp */
import React, { ChangeEvent } from 'react';
import {
    Box,
    Checkbox,
    createStyles,
    FormControl,
    FormHelperText,
    InputLabel,
    makeStyles,
    MenuItem,
    MenuItemClassKey,
    MenuProps as IMenuProps,
    Select,
    SelectClassKey,
    Theme,
    Typography
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { Field, FieldAttributes, FieldProps } from 'formik';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuItem: {
            maxWidth: '100%'
        },
        checkbox: {
            padding: 0
        },
        labelWrapper: {
            display: 'flex',
            paddingRight: theme.spacing(1),
            flex: 1,
            alignItems: 'center'
        },
        multipleLabelWrapper: {
            padding: theme.spacing(0, 1)
        },
        label: {
            whiteSpace: 'break-spaces'
        },
        formControl: {
            width: '100%'
        },
        image: {
            height: theme.spacing(5),
            width: theme.spacing(5),
            backgroundPosition: 'center',
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
            backgroundSize: 'cover',
            marginLeft: 'auto'
        },
        labelEnd: {
            paddingRight: theme.spacing(1)
        }
    })
);

export interface SelectOption {
    label: string;
    labelEnd?: string;
    value: any;
    disabled?: boolean;
    image?: string;
}
interface MultiSelectFieldProps {
    autoWidth?: boolean;
    defaultValue?: any;
    description?: string;
    id?: string;
    label?: React.ReactNode;
    labelId?: string;
    labelWidth?: number;
    multiple?: boolean;
    native?: boolean;
    onChange?: (e: ChangeEvent) => void;
    maxSelection?: number;
    open?: boolean;
    options: SelectOption[];
    variant?: 'filled' | 'outlined' | 'standard';
    selectClasses?: Partial<ClassNameMap<SelectClassKey>>;
    MenuProps?: Partial<IMenuProps>;
    MenuItemClasses?: Partial<Record<MenuItemClassKey, string>>;
}

const MultiSelectFormFieldComponent: React.FC<FieldProps & MultiSelectFieldProps> = ({
    field,
    meta,
    autoWidth,
    description,
    label,
    labelWidth,
    multiple,
    defaultValue,
    maxSelection,
    native,
    form,
    options,
    variant,
    selectClasses,
    MenuProps,
    MenuItemClasses
}) => {
    const classes = useStyles();
    // Formik will pass in undefined as the initial value regardless of what
    // you set the intial value to in the formik component it seems before updating
    // to the correct value later.
    // This fixes the issue that muiselect needs an array value when multiple is true
    const fixedValue: string | string[] = React.useMemo(() => {
        let modifiedValue = field.value || '';
        if (multiple && !Array.isArray(modifiedValue)) {
            modifiedValue = [];
            if (defaultValue && Array.isArray(defaultValue)) {
                modifiedValue = [...defaultValue];
            }
        }
        return modifiedValue;
    }, [field.value, defaultValue, multiple]);

    const renderSelectOption = React.useCallback(
        (option: SelectOption) => {
            const selected = multiple ? fixedValue.includes(option.value) : fixedValue === option.value;
            const disabled = option.disabled || (multiple && !selected && fixedValue.length === maxSelection);
            return (
                <MenuItem
                    value={option.value}
                    key={option.value}
                    disabled={disabled}
                    className={classes.menuItem}
                    classes={MenuItemClasses}
                >
                    {multiple && (
                        <Checkbox checked={selected} disabled={disabled} className={classes.checkbox} />
                    )}
                    <Box
                        className={clsx(classes.labelWrapper, {
                            [classes.multipleLabelWrapper]: multiple
                        })}
                    >
                        <Typography
                            className={clsx(
                                classes.label,
                                !!MenuItemClasses && !!selected && MenuItemClasses.selected
                            )}
                        >
                            {option.label}
                        </Typography>
                    </Box>
                    {!!option.labelEnd && (
                        <Typography className={classes.labelEnd}>{option.labelEnd}</Typography>
                    )}
                    {!!option.image && (
                        <div className={classes.image} style={{ backgroundImage: `url(${option.image})` }} />
                    )}
                </MenuItem>
            );
        },
        [
            multiple,
            fixedValue,
            maxSelection,
            classes.menuItem,
            classes.checkbox,
            classes.labelWrapper,
            classes.multipleLabelWrapper,
            classes.label,
            classes.labelEnd,
            classes.image,
            MenuItemClasses
        ]
    );

    const hasError = meta.touched && !!meta.error;
    const handleChange = React.useCallback(
        event => {
            if (multiple && maxSelection) {
                if (event.target.value.length <= maxSelection) {
                    form.setFieldValue(field.name, event.target.value, true);
                }
            } else {
                field.onChange(event);
            }
        },
        [field, form, maxSelection, multiple]
    );
    const validateFieldAndSetTouched = React.useCallback(() => {
        form.validateField(field.name);
        form.setFieldTouched(field.name, true);
    }, [field.name, form]);

    // We need to set the render value as we now use a more complicated rendering
    // inside the MenuItem, so it cannot infer this automatically.
    const optionValues: Map<string, string> = React.useMemo(
        () =>
            new Map(
                options.map(option => [
                    option.value.toString(),
                    `${option.label}${option.labelEnd ? ' ' + option.labelEnd : ''}`
                ])
            ),
        [options]
    );
    const selectedValueLabel: string = React.useMemo(() => {
        if (Array.isArray(fixedValue)) {
            // Get the label and labelEnd, this will always exist as we loop the options.
            // Cannot select an option that doesn't exist
            return fixedValue.map((value: any) => optionValues.get(value.toString()) as string).join(', ');
        }
        // Cannot select an option that doesn't exist
        return fixedValue ? (optionValues.get(fixedValue.toString()) as string) : '';
    }, [fixedValue, optionValues]);
    // Select requires a function to get this, and React/ESLint requires a callback
    // This code is entirely pointless otherwise.
    const renderValue = React.useCallback(() => selectedValueLabel, [selectedValueLabel]);
    return (
        <FormControl className={classes.formControl} error={hasError}>
            {!!label && <InputLabel id="selectLabel">{label}</InputLabel>}
            <Select
                labelId="selectLabel"
                value={fixedValue}
                onChange={handleChange}
                onBlur={validateFieldAndSetTouched}
                name={field.name}
                error={hasError}
                autoWidth={autoWidth}
                labelWidth={labelWidth}
                classes={selectClasses}
                multiple={multiple}
                native={native}
                variant={variant}
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    ...MenuProps
                }}
                renderValue={renderValue}
            >
                {options.map(renderSelectOption)}
            </Select>
            {((meta.touched && !!meta.error) || !!description) && (
                <FormHelperText>{(meta.touched && !!meta.error && meta.error) || description}</FormHelperText>
            )}
        </FormControl>
    );
};

export const MultiSelectFormField: React.FC<FieldAttributes<MultiSelectFieldProps>> = ({
    autoWidth,
    description,
    id,
    label,
    labelId,
    labelWidth,
    multiple,
    native,
    open,
    options,
    maxSelection,
    variant,
    defaultValue,
    validate,
    selectClasses: classes,
    MenuProps,
    MenuItemClasses,
    ...props
}) => (
    <Field {...props} multiple={multiple} validate={validate}>
        {(childProps: FieldProps) => (
            <MultiSelectFormFieldComponent
                autoWidth={autoWidth}
                description={description}
                id={id}
                label={label}
                labelId={labelId}
                labelWidth={labelWidth}
                defaultValue={defaultValue}
                multiple={multiple}
                native={native}
                open={open}
                options={options}
                maxSelection={maxSelection}
                variant={variant}
                selectClasses={classes}
                MenuProps={MenuProps}
                MenuItemClasses={MenuItemClasses}
                {...childProps}
            />
        )}
    </Field>
);
