import React from 'react';
import { createStyles, Dialog, makeStyles, Slide, SlideProps, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { useResponsive } from 'src/hooks/useResponsive';
import { CONTENT_MAX_WIDTH, DIALOG_DESKTOP_MAX_WIDTH } from 'config/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogPaper: (props: { isDesktop: boolean }) => ({
            margin: 0,
            position: 'fixed',
            maxWidth: CONTENT_MAX_WIDTH,
            padding: theme.spacing(1),
            ...(!props.isDesktop
                ? {
                      width: '100%',
                      bottom: 0
                  }
                : {
                      width: 'auto',
                      maxWidth: DIALOG_DESKTOP_MAX_WIDTH
                  })
        })
    })
);

export interface IBottomDialogProps {
    open: boolean;
    fullScreen?: boolean;
    onClose?: () => void;
    className?: string;
    paperClassName?: string;
    disableBackdropClick?: boolean;
    disableEnforceFocus?: boolean;
}

export const BottomDialog: React.FC<IBottomDialogProps> = props => {
    const { isDesktop } = useResponsive();
    const {
        onClose,
        open,
        fullScreen,
        className,
        paperClassName,
        disableBackdropClick,
        disableEnforceFocus,
        children
    } = props;
    const classes = useStyles({ isDesktop });
    const transitionProps: SlideProps = { direction: 'up' };
    const handleClose = React.useCallback(() => {
        if (!disableBackdropClick && onClose) {
            onClose();
        }
    }, [disableBackdropClick, onClose]);

    // TODO: shouldn't we set the square property in this way?(possibly get it from the layout data)
    return (
        <Dialog
            className={className}
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth={false}
            PaperProps={{
                className: clsx(classes.dialogPaper, paperClassName)
            }}
            TransitionComponent={Slide}
            TransitionProps={transitionProps}
            fullScreen={fullScreen}
            disableEnforceFocus={disableEnforceFocus}
        >
            {children}
        </Dialog>
    );
};
