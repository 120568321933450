import React from 'react';
import { useSelector } from 'react-redux';
import { IPublicSettings } from '@pepperhq/location-sdk';
import { User } from 'components/user/model/User';
import { isDefined, isDefinedAndNotNull, isNumber } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import { ApplicationState } from 'store/store';
import { useTippingSettings } from './useTippingSettings';
import { ETipScheme } from 'components/settings/enums';
import { ExistingSharedOrderData, OrderScenario } from 'components/order/model/Order';
import { getTipValueOfOption } from 'components/order/helpers';

const getDefaultTip = (user?: User, settings?: IPublicSettings): number | undefined => {
    if (user && user.defaultTip && isDefinedAndNotNull(user?.defaultTip?.amount)) {
        return user.defaultTip.amount;
    }
    if (settings && settings.tippingPayAtTable && isDefinedAndNotNull(settings?.tippingPayAtTable?.default)) {
        if (settings.tippingPayAtTable.values) {
            return settings?.tippingPayAtTable.values[settings.tippingPayAtTable.default] ?? undefined;
        }
        return settings.tippingPayAtTable.default;
    }
    if (settings && settings.tipping && isDefinedAndNotNull(settings?.tipping?.default)) {
        if (settings.tipping.values) {
            return settings?.tipping.values[settings.tipping.default] ?? undefined;
        }
        return settings.tipping.default;
    }
    return undefined;
};

export function useOrderTips(
    scenario: OrderScenario,
    subTotal?: number,
    existingParams?: ExistingSharedOrderData
) {
    const tippingSettings = useTippingSettings(scenario);
    const isCustomTipStored =
        existingParams &&
        isNumber(existingParams?.tipValue) &&
        !isDefined(existingParams?.userSelectedTipValue);
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { user } = useAuth();
    const [selectedTip, setSelectedTip] = React.useState(
        isCustomTipStored ? undefined : existingParams?.userSelectedTipValue ?? getDefaultTip(user, settings)
    );
    const [tipValue, setTipValue] = React.useState(existingParams?.tipValue);
    const [tipTouched, setTipTouched] = React.useState(false);

    React.useEffect(() => {
        if (!tipTouched) {
            setSelectedTip(
                isCustomTipStored
                    ? undefined
                    : existingParams?.userSelectedTipValue ?? getDefaultTip(user, settings)
            );
        }
    }, [existingParams?.userSelectedTipValue, isCustomTipStored, settings, tipTouched, user]);

    React.useEffect(() => {
        if (!tipTouched) {
            if (isCustomTipStored) {
                setTipValue(existingParams?.tipValue ?? 0);
            } else {
                const defaultTip = existingParams?.userSelectedTipValue ?? getDefaultTip(user, settings);
                if (isDefined(subTotal) && defaultTip) {
                    setTipValue(getTipValueOfOption(tippingSettings.scheme, subTotal, defaultTip));
                }
            }
        }
    }, [
        existingParams,
        existingParams?.tipValue,
        existingParams?.userSelectedTipValue,
        isCustomTipStored,
        settings,
        subTotal,
        tipTouched,
        tipValue,
        tippingSettings.scheme,
        user
    ]);

    React.useEffect(() => {
        if (
            tippingSettings.scheme === ETipScheme.PERCENTAGE &&
            isDefined(selectedTip) &&
            isDefined(subTotal)
        ) {
            const selectedTipValue = getTipValueOfOption(tippingSettings.scheme, subTotal, selectedTip);
            if (selectedTipValue !== tipValue) {
                setTipValue(selectedTipValue);
            }
        }
    }, [selectedTip, subTotal, tipValue, tippingSettings.scheme]);

    const onTipChange = React.useCallback((value: number, option?: number) => {
        setTipTouched(true);
        if (isDefined(option)) {
            setSelectedTip(option);
        } else {
            setSelectedTip(undefined);
        }
        setTipValue(value);
    }, []);

    return { selectedTip, tipValue, tippingSettings, onTipChange };
}
