import * as React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import useMutationObserver from '@rooks/use-mutation-observer';
import clsx from 'clsx';
import { addOpacity } from 'lib/helpers';
import { InnerPageLayoutBottom } from './InnerPageLayoutBottom';
import { InnerPageLayoutContent } from './InnerPageLayoutContent';

interface IProps {
    disabled?: boolean;
    disableContentSpaces?: boolean;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        contentShadow: {
            boxShadow: `1px -5px 8px ${addOpacity(theme.palette.text.primary, 0.12)}`
        },
        contentSpacing: {
            padding: theme.spacing(0, 2, 2, 2)
        },
        contentWrapper: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
        },
        content: ({ disableContentSpaces }: { disableContentSpaces: boolean }) => ({
            overflowY: 'scroll',
            flex: 1,
            marginTop: !disableContentSpaces ? theme.spacing(2) : 0
        }),
        contentDisabled: {
            opacity: 0.4,
            pointerEvents: 'none'
        }
    })
);

export const InnerPageLayout: React.FC<React.PropsWithChildren<IProps>> = ({
    children,
    disableContentSpaces,
    disabled,
    className
}) => {
    const classes = useStyles({ disableContentSpaces: !!disableContentSpaces });
    const [isBottomFloating, setIsBottomFloating] = React.useState(false);
    const contentRef = React.useRef<HTMLDivElement>(null);
    const contentScrollerRef = React.useRef<HTMLDivElement>(null);
    const content = React.useMemo(
        () =>
            React.Children.toArray(children).find(
                child => (child as React.ReactElement).type === InnerPageLayoutContent
            ),
        [children]
    );
    const bottom = React.useMemo(
        () =>
            React.Children.toArray(children).find(
                child => (child as React.ReactElement).type === InnerPageLayoutBottom
            ),
        [children]
    );
    const unkownChildren = React.useMemo(
        () =>
            React.Children.toArray(children).filter(
                child =>
                    (child as React.ReactElement).type !== InnerPageLayoutBottom &&
                    (child as React.ReactElement).type !== InnerPageLayoutContent
            ),
        [children]
    );

    const handleScrollableDetect = React.useCallback(() => {
        if (contentScrollerRef.current) {
            if (contentScrollerRef.current.clientHeight < contentScrollerRef.current.scrollHeight) {
                setIsBottomFloating(true);
            }
        }
    }, []);

    React.useEffect(handleScrollableDetect, [handleScrollableDetect]);
    useMutationObserver(contentRef, handleScrollableDetect);

    const hasBottomContent = React.useMemo(
        () => bottom && React.Children.toArray((bottom as any).props.children).some(element => !!element),
        [bottom]
    );

    return (
        <Box display="flex" flexDirection="column" height="100%" className={className}>
            {unkownChildren}
            {content && (
                <div
                    className={clsx(classes.contentSpacing, classes.content, {
                        [classes.contentDisabled]: disabled
                    })}
                    ref={contentScrollerRef}
                >
                    <div ref={contentRef} className={classes.contentWrapper}>
                        {content}
                    </div>
                </div>
            )}
            {bottom && hasBottomContent && (
                <Box
                    className={clsx({ [classes.contentShadow]: isBottomFloating })}
                    padding={2}
                    position="relative"
                    marginTop="auto"
                >
                    {bottom}
                </Box>
            )}
        </Box>
    );
};
