import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useSuggestionsContext } from 'src/hooks/useSuggestionsContext';
import { MuiSelect } from 'lib/MuiSelect';
import { useCurrencyString } from 'lib/useCurrencyString';
import { isProductAvailable, ProductGroup } from './model/Menu';
import { MenuEnrichedProductInfo } from './MenuEnrichedProductInfo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        groupItemSelect: {
            marginBottom: theme.spacing(1)
        }
    })
);

interface MenuEnrichedProductGroupInfoProps {
    items: ProductGroup['products'];
    handlePostAddedToBasket: () => void;
    showSelect?: boolean;
    categoryId: string;
}

export const MenuEnrichedProductGroupInfo: React.FC<MenuEnrichedProductGroupInfoProps> = ({
    items,
    handlePostAddedToBasket,
    showSelect,
    categoryId
}) => {
    const classes = useStyles();
    const getCurrencyString = useCurrencyString();
    const sortedItems = React.useMemo(() => items.slice().sort((a, b) => a.sort - b.sort), [items]);
    const options = React.useMemo(
        () =>
            sortedItems.map(item => ({
                value: item.id,
                label: `${item.title} ${getCurrencyString(item.price)}`,
                disabled: !isProductAvailable(item)
            })),
        [sortedItems, getCurrencyString]
    );
    const context = useSuggestionsContext();
    const defaultOptionValue = React.useMemo(() => {
        if (context.defaultSuggestedValue) {
            return context.defaultSuggestedValue.id;
        }
        const defaultItem = sortedItems.find(item => item.productGroupDefault && item.available);

        if (!!defaultItem) {
            return defaultItem.id;
        }

        const firstAvailableOption = sortedItems.find(item => item.available);
        return firstAvailableOption?.id ?? options[0].value;
    }, [context.defaultSuggestedValue, options, sortedItems]);
    const [selectedProduct, setSelectedProduct] = React.useState(
        () => items.find(item => item.id === defaultOptionValue) || items[0]
    );
    const handleProductChanged = React.useCallback(
        (productId: string) => {
            const product = sortedItems.find(item => item.id === productId);
            if (!!product) {
                setSelectedProduct(product);
            }
        },
        [sortedItems]
    );
    return (
        <React.Fragment>
            <MuiSelect
                defaultOpen={options.length > 1 && showSelect}
                options={options}
                defaultValue={defaultOptionValue}
                onSelect={handleProductChanged}
                className={classes.groupItemSelect}
            />
            <MenuEnrichedProductInfo
                categoryId={categoryId}
                item={selectedProduct}
                handlePostAddedToBasket={handlePostAddedToBasket}
            />
        </React.Fragment>
    );
};
