import React from 'react';
import { Stripe } from '@stripe/stripe-js';
import { IOrderCreatePaymentV8, OrderPaymentType } from 'components/order/model/Order';
import {
    IAddCardComponentClasses,
    IPaymentProvider,
    ThreeDSecureAuthenticationData
} from '../PaymentProvider';
import { StripeAddCard } from './StripeAddCard';

export class StripeProvider implements IPaymentProvider {
    constructor(private gateway: Stripe) {}

    createAddCardComponent(postCardAddition: () => void, classes?: IAddCardComponentClasses) {
        return React.createElement(StripeAddCard, { postCardAddition, classes });
    }
    public collectDeviceData() {
        return Promise.resolve(null);
    }

    public async threeDSecureAuthenticate<T extends { payments: IOrderCreatePaymentV8[] }>(
        data: ThreeDSecureAuthenticationData,
        order: T
    ): Promise<T> {
        if (!data.stripe?.paymentIntentClientSecret) {
            throw new Error('Invalid Stripe threeDSecureAuthenticate body');
        }
        try {
            // handleCardAction does not work here, even though it should
            // Stripe issue.
            const stripeConfirmResponse = await this.gateway.confirmCardPayment(
                data.stripe?.paymentIntentClientSecret
            );
            if (!stripeConfirmResponse.error) {
                return {
                    ...order,
                    payments: order.payments.map(payment => {
                        if (payment.type !== OrderPaymentType.GIFT_CARD) {
                            return {
                                ...payment,
                                auth: {
                                    nonce: stripeConfirmResponse.paymentIntent.id,
                                    threeDSecureData: { verificationToken: data.stripe?.paymentIntentId }
                                }
                            };
                        }
                        return payment;
                    })
                };
            }
            throw new Error('Could not confirm card payment');
        } catch (err) {
            throw err ?? new Error('Unexpected error occurred');
        }
    }

    public getGateway() {
        return this.gateway;
    }
}
