import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

export function useGiftCardModal() {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const isGiftCardsEnabled = React.useMemo(
        () => settings && settings.giftCardPaymentEnabled && settings.giftCardPaymentProvider,
        [settings]
    );
    const [open, setOpen] = React.useState(false);
    const handleOpenGiftCard = React.useCallback(() => {
        setOpen(true);
    }, []);
    const handleCloseGiftCard = React.useCallback(() => {
        setOpen(false);
    }, []);
    return { showGiftCardModal: open, isGiftCardsEnabled, handleOpenGiftCard, handleCloseGiftCard };
}
