import { Box, Grid, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { CheckboxFormField } from 'lib/form/CheckboxFormField';
import { TextFormField } from 'lib/form/TextFormField';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/LoadingButton';
import * as Yup from 'yup';
import { TermsAndConditions } from './TermsAndCondition';
import { User, isUserMissingName } from './model/User';

export interface UserDataFormData {
    firstName?: string;
    lastName?: string;
    birthdate?: string;
    hasAgreedToReceiveMarketing?: boolean;
}

interface UserSSODataScreenProps {
    onSubmit: (data: UserDataFormData) => void;
    user?: User;
    birthdateRequired?: boolean;
    birthdateEnabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            paddingTop: theme.spacing(4)
        },
        button: {
            height: theme.spacing(6),
            fontSize: '1.2em'
        },
        box: {
            borderWidth: '1px',
            borderColor: theme.palette.divider,
            borderRadius: theme.shape.borderRadius,
            borderStyle: 'solid',
            padding: theme.spacing(2)
        }
    })
);

export const UserMissingDataScreen: React.FC<UserSSODataScreenProps> = ({
    onSubmit,
    birthdateRequired,
    birthdateEnabled,
    user
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const showName = React.useMemo(() => isUserMissingName(user), [user]);
    const validationSchema = React.useMemo(
        () =>
            Yup.object().shape({
                firstName: showName
                    ? Yup.string().required('FORMS_VALIDATION_REQUIRED')
                    : Yup.string().notRequired(),
                lastName: showName
                    ? Yup.string().required('FORMS_VALIDATION_REQUIRED')
                    : Yup.string().notRequired(),
                birthdate:
                    !user?.birthdate && birthdateEnabled && birthdateRequired
                        ? Yup.string()
                              .required(t('FORMS_VALIDATION_REQUIRED'))
                              .test(
                                  'isAgeValid',
                                  t('ONBOARDING_DOB_MESSAGE'),
                                  (val: string) =>
                                      !(
                                          !!settings &&
                                          !!settings.app &&
                                          !!settings?.app?.registrationMinimumAge &&
                                          moment(val)
                                              .add(settings?.app?.registrationMinimumAge, 'years')
                                              .isAfter(moment(new Date()))
                                      )
                              )
                        : Yup.string().nullable().notRequired()
            }),
        [birthdateEnabled, birthdateRequired, settings, showName, t, user?.birthdate]
    );
    const initialValues = React.useMemo<UserDataFormData>(
        () => ({
            firstName: user?.firstName ?? '',
            lastName: user?.lastName ?? '',
            birthdate: undefined,
            hasAgreedToReceiveMarketing: false
        }),
        [user?.firstName, user?.lastName]
    );
    const showInputs = React.useMemo(() => birthdateEnabled || showName, [birthdateEnabled, showName]);
    return (
        <Box>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ submitForm, isValid, isSubmitting, values }) => (
                    <Form className={classes.root}>
                        <Grid container spacing={2}>
                            {showInputs && (
                                <Grid item xs={12}>
                                    <Box className={classes.box}>
                                        {birthdateEnabled && (
                                            <React.Fragment>
                                                <Typography align="center" variant="h6">
                                                    {t('ONBOARDING_DOB_TITLE')}
                                                </Typography>
                                                <Typography gutterBottom align="center">
                                                    {t('ONBOARDING_DOB_MESSAGE')}
                                                </Typography>
                                            </React.Fragment>
                                        )}
                                        <Grid container spacing={2}>
                                            {showName && (
                                                <Grid item xs={6}>
                                                    <TextFormField
                                                        name="firstName"
                                                        label={t('ONBOARDING_SIGNUP_FIRSTNAME')}
                                                        trimWhiteSpaces="sides"
                                                    />
                                                </Grid>
                                            )}
                                            {showName && (
                                                <Grid item xs={6}>
                                                    <TextFormField
                                                        name="lastName"
                                                        label={t('ONBOARDING_SIGNUP_LASTNAME')}
                                                        trimWhiteSpaces="sides"
                                                    />
                                                </Grid>
                                            )}
                                            {!user?.birthdate && birthdateEnabled && (
                                                <Grid item xs={12}>
                                                    <TextFormField
                                                        name="birthdate"
                                                        type="date"
                                                        label={t('USER_DETAILS_BIRTHDATE_TITLE')}
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Box>
                                </Grid>
                            )}
                            {(settings?.app?.registrationShowMarketingOptIn ?? true) && (
                                <Grid item xs={12}>
                                    <Box className={classes.box}>
                                        <Typography gutterBottom>
                                            {t('GDPR_BODY', {
                                                applicationName: settings?.app?.bundleDisplayName
                                            })}
                                        </Typography>
                                        <CheckboxFormField
                                            name="hasAgreedToReceiveMarketing"
                                            label={t('GDPR_MARKETING_CHECKBOX_TITLE')}
                                        />
                                        <Box mt={2}>
                                            <TermsAndConditions variant="caption" />
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                        !isValid ||
                                        isSubmitting ||
                                        (!values.birthdate && settings?.app?.ageRequestEnabled)
                                    }
                                    fullWidth
                                    onClick={submitForm}
                                    className={classes.button}
                                    loading={isSubmitting}
                                >
                                    {t('BUTTON_SUBMIT')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};
