export enum ELineIconName {
    CARD = 'EB6E',
    MONEY = 'eb57',
    MONETIZATION = 'EB57',
    STAR = 'EE40',
    HEART = 'EE32',
    PERSON_ADD = 'EE0C',
    CUP = 'EB00',
    HANDSHAKE = 'EB97',
    TICKET = 'E925'
}
