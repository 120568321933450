import isObject from 'lodash/isObject';
import { clearGuestOrders } from 'components/order/localGuestOrders';
import { getLocalMerchantId } from 'components/settings/localStore';
import { SignUpFormData } from 'components/user/model/User';
import { isDefined } from 'lib/typeInference';

interface ILocalAuth {
    userId: string;
    token: string;
    expiry: number;
    isGuest: boolean;
}

export const updateLocalToken = (credentials: { userId: string; token: string }) => {
    const merchantId = getLocalMerchantId();
    if (merchantId) {
        const auth = localStorage.getItem('auth') || '{}';
        const parsedAuth = JSON.parse(auth);
        parsedAuth[merchantId] = credentials;
        localStorage.setItem('auth', JSON.stringify(parsedAuth));
    }
};

export const updateGuestSession = (token: string, userId: string, expiryDate?: number) => {
    const merchantId = getLocalMerchantId();
    clearGuestOrders();
    if (merchantId) {
        const localGuestSessions = localStorage.getItem('guestSession') || '{}';
        const parsedTokens = JSON.parse(localGuestSessions);
        parsedTokens[merchantId] = { token, userId, expiryDate };
        localStorage.setItem('guestSession', JSON.stringify(parsedTokens));
    }
};

export const resetGuestSession = () => {
    const merchantId = getLocalMerchantId();
    const localGuestSessions = localStorage.getItem('guestSession');
    clearGuestOrders();
    if (localGuestSessions && merchantId) {
        const parsedTokens = JSON.parse(localGuestSessions);
        delete parsedTokens[merchantId];
        localStorage.setItem('guestSession', JSON.stringify(parsedTokens));
    }
};

export const getLocalGuestSession = (): { token: string; userId: string; expiryDate: number } | null => {
    const merchantId = getLocalMerchantId();
    const parsedTokens = localStorage.getItem('guestSession');
    if (parsedTokens && merchantId) {
        const parsedAuth = JSON.parse(parsedTokens);
        return parsedAuth[merchantId];
    }

    return null;
};

export const resetLocalToken = () => {
    const merchantId = getLocalMerchantId();
    const auth = localStorage.getItem('auth');
    if (auth && merchantId) {
        const parsedAuth = JSON.parse(auth);
        delete parsedAuth[merchantId];
        localStorage.setItem('auth', JSON.stringify(parsedAuth));
    }
};

export const getLocalToken = () => {
    const merchantId = getLocalMerchantId();
    const auth = localStorage.getItem('auth');
    if (auth && merchantId) {
        const parsedAuth = JSON.parse(auth);
        return parsedAuth[merchantId] as { userId: string; token: string };
    }
    return null;
};

export const updateUserId = (token: string) => {
    const merchantId = getLocalMerchantId();
    if (merchantId) {
        const userId = localStorage.getItem('userId') || '{}';
        const parsedAuth = JSON.parse(userId);
        parsedAuth[merchantId] = token;
        localStorage.setItem('userId', JSON.stringify(parsedAuth));
    }
};

export const resetUserid = () => {
    const merchantId = getLocalMerchantId();
    const userId = localStorage.getItem('userId');
    if (userId && merchantId) {
        const parsedAuth = JSON.parse(userId);
        delete parsedAuth[merchantId];
        localStorage.setItem('userId', JSON.stringify(parsedAuth));
    }
};

export const getUserId = () => {
    const merchantId = getLocalMerchantId();
    const userId = localStorage.getItem('userId');
    if (userId && merchantId) {
        const parsedAuth = JSON.parse(userId);
        return parsedAuth[merchantId];
    }
    return '';
};

// TODO: I would move them all to variables;
const ON_CLOSE_AUTH_REDIRECT = 'ON_CLOSE_AUTH_REDIRECT-R';

export const setOnCloseRedirectUrl = (path: string, merchantId: string) => {
    localStorage.setItem(`${ON_CLOSE_AUTH_REDIRECT}-${merchantId}`, path);
};

export const getOnCloseRedirectUrl = (merchantId: string) =>
    localStorage.getItem(`${ON_CLOSE_AUTH_REDIRECT}-${merchantId}`);

export const deleteOnCloseRedirectUrl = (merchantId: string) => {
    localStorage.removeItem(`${ON_CLOSE_AUTH_REDIRECT}-${merchantId}`);
};

export const setShouldUpdateGuestSession = (merchantId: string) =>
    localStorage.setItem(`UPDATE-GUEST-SESSION-${merchantId}`, 'true');

export const getShouldUpdateGuestSession = (merchantId: string) =>
    localStorage.getItem(`UPDATE-GUEST-SESSION-${merchantId}`);

export const resetShouldUpdateGuestSession = (merchantId: string) =>
    localStorage.removeItem(`UPDATE-GUEST-SESSION-${merchantId}`);

export const setSignupDataToSession = (merchantId: string, userData: Partial<SignUpFormData>) => {
    sessionStorage.setItem(`USER-SIGNUP-DATA-${merchantId}`, JSON.stringify(userData));
};

export const getSignupDataFromSession = (merchantId: string) =>
    sessionStorage.getItem(`USER-SIGNUP-DATA-${merchantId}`);

export const clearSignupDataFromSession = (merchantId: string) => {
    sessionStorage.removeItem(`USER-SIGNUP-DATA-${merchantId}`);
};

export const setLocalAuthentication = (value: ILocalAuth): ILocalAuth | null => {
    const merchantId = getLocalMerchantId();

    if (merchantId) {
        const localAuthentication = localStorage.getItem('authentication') || '{}';
        const parsedAuthentication: Record<string, ILocalAuth> = JSON.parse(localAuthentication);
        const valueToSet = { ...parsedAuthentication, [merchantId]: value };

        localStorage.setItem('authentication', JSON.stringify(valueToSet));

        return valueToSet[merchantId];
    }

    return null;
};

export const getLocalAuthentication = (): ILocalAuth | null => {
    const merchantId = getLocalMerchantId();

    if (merchantId) {
        const localAuthentication = localStorage.getItem('authentication');
        if (localAuthentication) {
            const parsedAuthentication: ILocalAuth = JSON.parse(localAuthentication)[merchantId];

            if (isDefined(parsedAuthentication) && isObject(parsedAuthentication)) {
                return parsedAuthentication;
            }

            return null;
        }

        return null;
    }

    return null;
};

export const resetLocalAuthentication = () => {
    const merchantId = getLocalMerchantId();

    if (merchantId) {
        const localAuthentication = localStorage.getItem('authentication') || '{}';
        const parsedAuthentication: Record<string, ILocalAuth> = JSON.parse(localAuthentication);

        if (isDefined(parsedAuthentication) && isObject(parsedAuthentication)) {
            delete parsedAuthentication[merchantId];
        }

        if (
            isDefined(parsedAuthentication) &&
            isObject(parsedAuthentication) &&
            Object.keys(parsedAuthentication).length > 0
        ) {
            localStorage.setItem('authentication', JSON.stringify(parsedAuthentication));
        } else {
            localStorage.removeItem('authentication');
        }
        return parsedAuthentication;
    }

    return null;
};
