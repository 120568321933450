import { OrderScenario } from 'components/order/model/Order';
import { ITip } from 'components/settings/model/Settings';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

export function useBasketTipSettings(scenario: OrderScenario) {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { isTippingEnabled, tippingToUse } = React.useMemo(() => {
        if (!!settings) {
            let finalTippingEnabled: boolean = settings.tippingEnabled;
            let finalTipping: ITip = settings.tipping;

            switch (scenario) {
                case OrderScenario.PREORDER: {
                    finalTippingEnabled = settings.tippingPreorderEnabled ?? settings.tippingEnabled;
                    finalTipping = settings.tippingPreorder ?? settings.tipping;
                    break;
                }
                case OrderScenario.ORDER_TO_TABLE: {
                    finalTippingEnabled = settings.tippingOrderToTableEnabled ?? settings.tippingEnabled;
                    finalTipping = settings.tippingOrderToTable ?? settings.tipping;
                    break;
                }
                default: {
                    break;
                }
            }

            return { isTippingEnabled: finalTippingEnabled, tippingToUse: finalTipping };
        }

        return {};
    }, [settings, scenario]);
    return { tippingToUse, isTippingEnabled };
}
