import React from 'react';
import { List, makeStyles, Theme } from '@material-ui/core';
import { MenuCategoryGroup } from 'components/menu/MenuCategoryGroup';
import { ICategoryGroup } from 'components/menu/model/Menu';
import { IOrderReadResourceV12 } from 'components/order/model/Order';
import { RecentOrdersList } from 'components/recentOrders/RecentOrdersList';

interface IMenuCategoryGroupsListProps {
    onClick: (categoryGroup: ICategoryGroup) => void;
    recentOrders?: IOrderReadResourceV12[];
    categoryGroups?: ICategoryGroup[];
}

const useStyles = makeStyles((theme: Theme) => ({
    categoryGroupsContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        flex: 1,
        width: '100%',
        paddingBottom: theme.spacing(6),
        paddingTop: theme.spacing(1)
    },
    categoryGroups: {
        flex: 1,
        padding: theme.spacing(0, 1.5, 7, 1.5)
    }
}));

export const MenuCategoryGroupsList: React.FC<IMenuCategoryGroupsListProps> = ({
    onClick,
    recentOrders,
    categoryGroups
}) => {
    const classes = useStyles();

    const renderCategoryGroupItem = React.useCallback(
        (item: ICategoryGroup) => <MenuCategoryGroup onClick={onClick} categoryGroup={item} key={item.id} />,
        [onClick]
    );

    return (
        <div className={classes.categoryGroupsContainer}>
            <List className={classes.categoryGroups}>
                {!!recentOrders && <RecentOrdersList orders={recentOrders} />}
                {!!categoryGroups && categoryGroups.map(renderCategoryGroupItem)}
            </List>
        </div>
    );
};
