import { getCurrencyString } from 'lib/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

export function useTransactionLimitValidation(totalValue: number) {
    const { t } = useTranslation();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const [showExceededLimitDialog, setShowExceededLimitDialog] = React.useState(false);
    const handleExceededLimitDialogClose = React.useCallback(() => {
        setShowExceededLimitDialog(false);
    }, []);

    const isLessThanLimitAmount = React.useMemo(
        () => !!settings?.transaction?.minimumAmount && settings?.transaction?.minimumAmount > totalValue,
        [settings?.transaction?.minimumAmount, totalValue]
    );
    const isMoreThanLimitAmount = React.useMemo(
        () => !!settings?.transaction?.maximumAmount && settings?.transaction?.maximumAmount < totalValue,
        [settings?.transaction?.maximumAmount, totalValue]
    );
    const isOutOfLimit = React.useMemo(
        () => (isLessThanLimitAmount || isMoreThanLimitAmount) && totalValue !== 0,
        [isLessThanLimitAmount, isMoreThanLimitAmount, totalValue]
    );
    const handleValidateTransactionLimit = React.useCallback(() => {
        if (isOutOfLimit) {
            setShowExceededLimitDialog(true);
        }
    }, [isOutOfLimit]);
    const transactionLimitDescription = React.useMemo(
        () =>
            t(
                isMoreThanLimitAmount
                    ? 'DIALOG_ORDER_EXCEEDED_MAXIMUM_TRANSACTION_LIMIT_MESSAGE'
                    : 'DIALOG_ORDER_BELOW_MINIMUM_TRANSACTION_LIMIT_MESSAGE',
                {
                    limit: getCurrencyString(
                        (isMoreThanLimitAmount
                            ? settings?.transaction?.maximumAmount
                            : settings?.transaction?.minimumAmount) ?? 0,
                        settings?.region?.currencyCode
                    )
                }
            ),
        [
            isMoreThanLimitAmount,
            settings?.region?.currencyCode,
            settings?.transaction?.maximumAmount,
            settings?.transaction?.minimumAmount,
            t
        ]
    );
    return {
        isOutOfLimit,
        transactionLimitDescription,
        handleExceededLimitDialogClose,
        handleValidateTransactionLimit,
        showExceededLimitDialog
    };
}
