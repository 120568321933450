import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { IEnrichedProduct } from './model/Menu';
import { MenuProductInfoModifiers } from './MenuProductInfoModifiers';

const useStyles = makeStyles({
    containerBox: {
        display: 'flex',
        height: 0,
        flexDirection: 'column'
    }
});

interface MenuEnrichedProductInfoProps {
    item: IEnrichedProduct;
    handlePostAddedToBasket: () => void;
    categoryId: string;
}

export const MenuEnrichedProductInfo: React.FC<MenuEnrichedProductInfoProps> = ({
    item,
    handlePostAddedToBasket,
    categoryId
}) => {
    const classes = useStyles();

    return (
        <Box className={classes.containerBox} flex={1}>
            <MenuProductInfoModifiers
                categoryId={categoryId}
                item={item}
                postAddedToBasket={handlePostAddedToBasket}
            />
        </Box>
    );
};
