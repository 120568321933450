import { EOrderNonceKey, orderApi } from 'components/order/orderApi';
import { isDefined } from 'lib/typeInference';
import { LocationRouteParams } from 'pages/routes';
import React from 'react';
import { useParams } from 'react-router-dom';
import { getPaySessionSplits, setPaySessionSplits } from '../model/helpers';
import { usePayQueryParams } from './usePayDateCheckId';

export function useBillSplitting(splitsToPay?: number) {
    const { merchantId, locationId } = useParams<LocationRouteParams>();
    const { checkId, tableNumber } = usePayQueryParams();
    const [customSplit, setCustomSplit] = React.useState<number | undefined>(() => {
        const params = getPaySessionSplits(merchantId, locationId, checkId, tableNumber);
        return params?.custom;
    });
    const [splitShares, setSplitShares] = React.useState<number | undefined>(() => {
        const params = getPaySessionSplits(merchantId, locationId, checkId, tableNumber);
        return params?.splitNumber;
    });
    const [yourShares, setYourShares] = React.useState<number | undefined>(() => {
        const params = getPaySessionSplits(merchantId, locationId, checkId, tableNumber);
        return params?.splits;
    });
    React.useEffect(() => {
        if (!isDefined(splitShares) && splitsToPay) {
            setSplitShares(splitsToPay);
        }
    }, [splitShares, splitsToPay]);
    const handleSplit = React.useCallback(
        (shares?: number, _yourShares?: number, customAmount?: number) => {
            if (shares && _yourShares) {
                orderApi.resetNonceByKey([EOrderNonceKey.UPDATE_ORDER]);
                setSplitShares(shares);
                setYourShares(_yourShares);
                setPaySessionSplits(
                    { splits: _yourShares, splitNumber: shares },
                    merchantId,
                    locationId,
                    checkId,
                    tableNumber
                );
            } else {
                setYourShares(undefined);
                setSplitShares(undefined);
                setPaySessionSplits(undefined, merchantId, locationId, checkId, tableNumber);
            }
            setCustomSplit(customAmount);
            if (isDefined(customAmount)) {
                setPaySessionSplits({ custom: customAmount }, merchantId, locationId, checkId, tableNumber);
            }
        },
        [checkId, locationId, merchantId, tableNumber]
    );
    return {
        customSplit,
        splitShares,
        yourShares,
        handleSplit
    };
}
