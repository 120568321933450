import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
    getModifierOptionsForProduct,
    getProductData,
    INutritionMenuEnrichmentFields,
    ProductGroup
} from 'components/menu/model/Menu';
import { ApplicationState } from 'store/store';
import { IItemReadResourceV10 } from './../components/basket/model/Basket';
import { IEnrichedProduct } from './../components/menu/model/Menu';

export const useCalories = () => {
    const { t } = useTranslation();
    const { menu } = useSelector((state: ApplicationState) => state.menu);
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const key = settings?.menu?.calorieNutrientName;

    const getCaloriesByNutrition = React.useCallback(
        (nutritions: INutritionMenuEnrichmentFields[]) => {
            const calories = nutritions[0].values.find(n => n.nutrient === key);
            if (!!calories) {
                const match = new RegExp(/[\d\\.]+/).exec(calories.value);

                return match ? match[0] : null;
            }
            return null;
        },
        [key]
    );

    const getCaloriesString = React.useCallback(
        (calories: number) => `${calories} ${t('ORDER_CALORIES_UNIT')}`,
        [t]
    );
    const getProductCalories = React.useCallback(
        (item: IEnrichedProduct | ProductGroup) => {
            const sum = { value: 0, isSet: false };

            if (menu) {
                const { nutrition, modifiers } = getProductData(item, 1);
                const optionModifiers = getModifierOptionsForProduct(menu, item.id, modifiers);

                let modifierOptions;
                if (menu) {
                    modifierOptions = optionModifiers.filter(
                        optionModifier =>
                            optionModifier.bundle &&
                            optionModifier.options.length === 1 &&
                            optionModifier.options[0].selected
                    );
                }

                if (nutrition && nutrition.length > 0) {
                    const value = getCaloriesByNutrition(nutrition);
                    if (!!value && Number(value) >= 0) {
                        sum.value += Number(value);
                        sum.isSet = true;
                    }
                }

                if (modifierOptions && modifierOptions.length > 0) {
                    modifierOptions.forEach(modifier => {
                        modifier.options.forEach(option => {
                            if (option.nutrition && option.nutrition.length > 0) {
                                const value = getCaloriesByNutrition(option.nutrition);

                                if (!!value && Number(value) >= 0) {
                                    sum.value += Number(value);
                                    sum.isSet = true;
                                }
                            }
                        });
                    });
                }
            }

            return sum;
        },
        [getCaloriesByNutrition, menu]
    );

    const getOrderItemsCalories = React.useCallback(
        (items: IItemReadResourceV10[]) => {
            if (menu) {
                return items.reduce<{ isSet: boolean; value: number }>(
                    (acc, item) => {
                        const product = menu.productIdToProduct.get(item.productId);
                        if (product && product.nutrition.length > 0) {
                            const { modifiers } = getProductData(product, 1);
                            const optionModifiers = getModifierOptionsForProduct(menu, product.id, modifiers);
                            const sum = getProductCalories(product);
                            let productCalories = sum.value * item.quantity;
                            acc.isSet = sum.isSet;
                            if (item.modifiers && item.modifiers.length > 0) {
                                item.modifiers.forEach(modifier => {
                                    const optionModifier = optionModifiers.find(
                                        _optionModifier => _optionModifier.id === modifier.id
                                    );
                                    if (modifier.options.length > 0 && !optionModifier?.bundle) {
                                        modifier.options.forEach(modifierOption => {
                                            const option = optionModifier?.options.find(
                                                _optionModifier => _optionModifier.id === modifierOption.id
                                            );
                                            if (option && option.nutrition && option.nutrition.length > 0) {
                                                productCalories +=
                                                    Number(getCaloriesByNutrition(option.nutrition)) || 0;
                                                acc.isSet = true;
                                            }
                                        });
                                    }
                                });
                            }
                            acc.value += Number(productCalories);
                        }
                        return acc;
                    },
                    { value: 0, isSet: false }
                );
            }

            return { value: 0, isSet: false };
        },
        [getCaloriesByNutrition, getProductCalories, menu]
    );

    return {
        key,
        getCaloriesByNutrition,
        getCaloriesString,
        getProductCalories,
        getOrderItemsCalories
    } as const;
};
