import { HttpClient } from 'components/http/HttpClient';
import { IResendReceiptRq } from './models';

class NotificationsApi extends HttpClient {
    constructor() {
        super({
            baseURL: process.env.API_SERVER_URL + '/eventBus/consumers/notifications'
        });
    }

    public resendReceipt(resendForm: IResendReceiptRq): Promise<void> {
        return this.postRequest<IResendReceiptRq, void>({
            url: '',
            headers: {
                'x-api-version': '7'
            },
            data: resendForm
        });
    }
}

export const notificationsApi = new NotificationsApi();
