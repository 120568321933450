import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { PaymentProviderType } from 'components/user/model/User';
import { userApi } from 'components/user/userApi';
import { useAuth } from 'lib/useAuth';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/LoadingButton';
import { IJudopayTokenResult, judoHelpers } from './JudopayProvider';
import './JudopayAddCard.css';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iframeContainer: {
            width: '100%',
            maxWidth: '100%',
            minWidth: '100%',
            padding: theme.spacing(1),
            height: '420px'
        },
        buttonContainer: {
            marginTop: 'auto',
            padding: theme.spacing(1)
        }
    })
);

interface IJudopayAddCardProps {
    postCardAddition: () => void;
}

export const JudopayAddCard: React.FC<IJudopayAddCardProps> = props => {
    const { postCardAddition } = props;
    const classes = useStyles();
    const [cardDetails, setCardDetails] = React.useState<Record<string, unknown> | null>(null);
    const [addCardInProgress, setAddCardInProgress] = React.useState(false);
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { enqueueSnackbar } = useSnackbar();
    const judoPaySettings = settings?.judopay;
    const isCountryAndPostcodeVisible = React.useMemo(() => {
        switch (settings?.region.code) {
            case 'UK':
                return true;
            case 'US':
            default:
                return false;
        }
    }, [settings?.region?.code]);
    const { t } = useTranslation();
    const { isLoggedIn, hasSession } = useAuth();
    const judo = React.useMemo(
        () =>
            // JudoPay has no types, no module, nothing useful at all...
            // Perhaps create some types and add to window definition?
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            new window.JudoPay(judoPaySettings.clientToken, !judoPaySettings.isProduction),
        [judoPaySettings]
    );
    React.useEffect(() => {
        const newCardDetails = judo.createCardDetails('judopay-iframe', {
            layout: 'vertical',
            iframe: {
                isCountryAndPostcodeVisible
            }
        });
        setCardDetails(newCardDetails);
    }, [isCountryAndPostcodeVisible, judo]);
    const handleAddCardSubmit = React.useCallback(() => {
        try {
            if (!hasSession) {
                throw new Error('User is not authenticated');
            }
            setAddCardInProgress(true);
            judoHelpers.createToken(judo, cardDetails, async (result: IJudopayTokenResult | null) => {
                if (!result) {
                    enqueueSnackbar(t('CHECKOUT_ERROR_VALIDATING_CARD'), { variant: 'error' });
                    setAddCardInProgress(false);
                    return;
                }
                // Call Pepper API
                try {
                    await userApi.createCard({
                        cardType: PaymentProviderType.JUDOPAY,
                        token: result.oneUseToken
                    });
                    postCardAddition();
                    enqueueSnackbar(t('CHECKOUT_CARD_ADDED'), { variant: 'success' });
                    setAddCardInProgress(false);
                } catch (err: any) {
                    enqueueSnackbar(err.data.message, { variant: 'error' });
                    setAddCardInProgress(false);
                }
            });
        } catch (err: any) {
            enqueueSnackbar(err, { variant: 'error' });
            setAddCardInProgress(false);
        }
    }, [hasSession, judo, cardDetails, enqueueSnackbar, t, postCardAddition]);
    return (
        <Box flex={1} display="flex" flexDirection="column">
            <div id="judopay-iframe" className={classes.iframeContainer} />
            <div className={classes.buttonContainer}>
                <LoadingButton
                    disabled={addCardInProgress}
                    loading={addCardInProgress}
                    onClick={handleAddCardSubmit}
                    color="primary"
                    variant="contained"
                    fullWidth
                >
                    {isLoggedIn ? t('CHECKOUT_ADD_CARD') : t('CHECKOUT_USE_CARD')}
                </LoadingButton>
            </div>
        </Box>
    );
};
