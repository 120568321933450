import { GuestAction } from './guestActions';
import { GuestActionTypes } from './guestActionTypes';

export interface GuestState {
    firstName?: string;
    lastName?: string;
    identity?: string;
}

export const initialState: GuestState = {};

export default function (state: GuestState = initialState, action: GuestAction): GuestState {
    switch (action.type) {
        case GuestActionTypes.SET_CONTACT_INFORMATION: {
            const { firstName, lastName, identity } = action;
            return { ...state, firstName, lastName, identity };
        }
        case GuestActionTypes.RESET_GUEST_STATE:
            return initialState;
        default:
            return state;
    }
}
