import { Box, Theme, Typography, makeStyles } from '@material-ui/core';
import { ELoyaltyProvider } from 'components/awards/enums';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: '#fff',
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    code: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

interface QrCodeBlockProps {
    children: React.ReactChild;
    qrCodeValue: string;
    loyaltyProvider?: ELoyaltyProvider;
    showTitle?: boolean;
}

export const QrCodeBlock: React.FC<QrCodeBlockProps> = ({
    children,
    qrCodeValue,
    loyaltyProvider,
    showTitle
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            {showTitle && (
                <Typography align="center" gutterBottom>
                    {t('DIALOG_BARCODE_MESSAGE')}
                </Typography>
            )}
            <Box p={3} pb={1} className={classes.root}>
                <div className={classes.code}>{children}</div>
                {loyaltyProvider !== ELoyaltyProvider.Powercard && (
                    <Typography align="center">{qrCodeValue}</Typography>
                )}
            </Box>
        </div>
    );
};
