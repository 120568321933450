import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { AppWrapper } from 'app/AppWrapper';
import { Head } from 'app/Head';
import { Root } from 'pages/Root';
import { initStore } from 'store/store';
import { addVhSupportForMobile } from './utils/document';
import { checkStaticId } from './utils/local-info';
import throttle from 'lodash/throttle';

export const store = initStore();

const App: React.FC = () => {
    React.useEffect(() => {
        addVhSupportForMobile();
        window.addEventListener('resize', throttle(addVhSupportForMobile, 100));
        checkStaticId();

        return () => window.removeEventListener('resize', addVhSupportForMobile);
    });

    return (
        <Provider store={store}>
            <BrowserRouter>
                <AppWrapper>
                    <Head />
                    <Root />
                </AppWrapper>
            </BrowserRouter>
        </Provider>
    );
};

export default App;
