import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useAsyncData } from 'src/hooks/useAsyncData';
import { isLoading, isSuccess } from 'src/utils/request';
import { MerchantTitle } from 'app/MerchantTitle';
import { awardsApi } from 'components/awards/awardsApi';
import { IAwardsResponse } from 'components/awards/models';
import { AwardsList } from 'components/awards/ui/List';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { ELoyaltyProvider } from 'components/awards/enums';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        loadingSkeleton: {
            borderRadius: theme.shape.borderRadius,
            marginBottom: theme.spacing(2)
        }
    })
);

export const AwardsPage: React.FC = () => {
    const { t } = useTranslation();
    const [awardsResponse, setAwardsResponse] = useAsyncData<IAwardsResponse>();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const classes = useStyles();

    React.useEffect(() => {
        if (settings) {
            setAwardsResponse(() =>
                awardsApi.getAwards(
                    settings?.loyaltyProvider === ELoyaltyProvider.Square ||
                        settings?.loyaltyProvider === ELoyaltyProvider.Powercard
                )
            );
        }
    }, [setAwardsResponse, settings]);

    const loadingSkeleton = React.useMemo(() => {
        const skeletonArray = [];
        for (let i = 0; i < 8; i++) {
            skeletonArray.push(
                <Grid item key={i} xs={12} sm={4} md={3}>
                    <Skeleton
                        className={classes.loadingSkeleton}
                        variant="rect"
                        animation="wave"
                        height={200}
                    />
                </Grid>
            );
        }

        return (
            <Grid container spacing={2}>
                {skeletonArray}
            </Grid>
        );
    }, [classes.loadingSkeleton]);

    return (
        <>
            <MerchantTitle title={t('AWARDS_TITLE')} />
            <MuiAppBar title={t('AWARDS_TITLE')} />
            {isSuccess(awardsResponse) && awardsResponse.data && (
                <AwardsList awards={awardsResponse.data.awards} />
            )}
            {isLoading(awardsResponse) && <Box padding={2}>{loadingSkeleton}</Box>}
        </>
    );
};
