import React, { ChangeEvent } from 'react';
import {
    Box,
    Collapse,
    createStyles,
    FormHelperText,
    List,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import { Field, FieldAttributes, FieldProps } from 'formik';
import { removeArrayItem } from 'lib/helpers';
import { IModifierSelectOption, ModifierSelectOption } from './ModifierSelectOption';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';
import { EGAEventName, useGAHelpers } from 'lib/useGAHelpers';
import { isDefined } from 'lib/typeInference';

interface ModifierSelectFormFieldProps {
    autoWidth?: boolean;
    defaultValue?: any;
    description?: string;
    id?: string;
    label?: React.ReactNode;
    multiple?: boolean;
    onChange?: (e: ChangeEvent) => void;
    maxSelection?: number;
    options: IModifierSelectOption[];
    collapsed?: boolean;
    required?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        label: {
            whiteSpace: 'pre-wrap'
        },
        title: {
            fontWeight: 'bold',
            color: theme.palette.titleTextColour
        },
        panel: {
            cursor: 'pointer',
            padding: theme.spacing(2),
            paddingBottom: theme.spacing(1)
        },
        icon: {
            transition: 'transform .25s',
            marginLeft: theme.spacing(1),
            color: theme.palette.text.hint
        },
        expandedIcon: {
            transform: 'rotate(180deg)'
        }
    })
);

const ModifierSelectFormFieldComponent: React.FC<FieldProps & ModifierSelectFormFieldProps> = ({
    label,
    options,
    maxSelection,
    multiple,
    field,
    form,
    defaultValue,
    meta,
    description,
    collapsed: collapsedByDefault,
    required
}) => {
    const classes = useStyles();
    const { logUserEvent } = useGAHelpers();
    const [collapsed, setCollapsed] = React.useState(!!collapsedByDefault);
    const handleCollapse = React.useCallback(() => {
        setCollapsed(isCollapsed => !isCollapsed);
        logUserEvent(EGAEventName.ModifierHeaderTapped);
    }, [logUserEvent]);
    // Formik will pass in undefined as the initial value regardless of what
    // you set the intial value to in the formik component it seems before updating
    // to the correct value later.
    // This fixes the issue that muiselect needs an array value when multiple is true
    const fixedValue: string | string[] = React.useMemo(() => {
        let modifiedValue = field.value || '';
        if (multiple && !Array.isArray(modifiedValue)) {
            modifiedValue = [];
            if (defaultValue && Array.isArray(defaultValue)) {
                modifiedValue = [...defaultValue];
            }
        }
        if (!multiple && Array.isArray(modifiedValue)) {
            return modifiedValue[0];
        }
        return modifiedValue;
    }, [field.value, defaultValue, multiple]);

    const handleChange = React.useCallback(
        value => {
            // Third argument in setFieldValue will trigger validation
            form.setFieldValue(field.name, value, true);
        },
        [field, form]
    );

    const handleClick = React.useCallback(
        (value: string) => {
            if (multiple) {
                if (Array.isArray(fixedValue)) {
                    const itemIndex = fixedValue.findIndex(item => item === value);
                    if (itemIndex > -1) {
                        handleChange(removeArrayItem(fixedValue, itemIndex));
                    } else {
                        handleChange([...fixedValue, value]);
                    }
                }
            } else if (fixedValue === value) {
                handleChange('');
            } else {
                handleChange(value);
            }
        },
        [handleChange, fixedValue, multiple]
    );
    const renderSelectOption = React.useCallback(
        (item: IModifierSelectOption) => {
            const selected = multiple ? fixedValue.includes(item.value) : fixedValue === item.value;
            const disabled = item.disabled || (multiple && !selected && fixedValue.length === maxSelection);
            return (
                <ModifierSelectOption
                    key={item.value}
                    item={item}
                    multi={!!multiple}
                    disabled={!!disabled}
                    selected={selected}
                    onClick={handleClick}
                />
            );
        },
        [multiple, fixedValue, maxSelection, handleClick]
    );
    const selectedOptions = React.useMemo(() => {
        const resultOptions: string[] = [];
        if (multiple && Array.isArray(fixedValue)) {
            fixedValue.forEach(item => {
                const option = options.find(option => option.value === item);
                if (isDefined(option)) {
                    resultOptions.push(option.label);
                }
            });
        } else {
            const option = options.find(item => item.value === fixedValue);
            if (isDefined(option)) {
                resultOptions.push(option.label);
            }
        }
        return resultOptions.join(', ');
    }, [fixedValue, multiple, options]);
    return (
        <div className={classes.root}>
            <Box
                onClick={handleCollapse}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                className={classes.panel}
            >
                <Typography className={classes.title}>
                    {label}
                    {required ? ' *' : ''}
                </Typography>
                <KeyboardArrowDownIcon
                    color="primary"
                    className={clsx(classes.icon, { [classes.expandedIcon]: !collapsed })}
                />
            </Box>
            {!!collapsed && !!selectedOptions.length && (
                <Box flex={1} px={2}>
                    <Typography className={classes.label}>{selectedOptions}</Typography>
                </Box>
            )}
            <Collapse in={!collapsed}>
                <React.Fragment>
                    <List>{options.map(renderSelectOption)}</List>
                    {((meta.touched && !!meta.error) || !!description) && (
                        <Box px={2}>
                            <FormHelperText>
                                {(meta.touched && !!meta.error && meta.error) || description}
                            </FormHelperText>
                        </Box>
                    )}
                </React.Fragment>
            </Collapse>
        </div>
    );
};

// eslint-disable-next-line react/no-multi-comp
export const ModifierSelectFormField: React.FC<FieldAttributes<ModifierSelectFormFieldProps>> = ({
    description,
    id,
    label,
    multiple,
    options,
    maxSelection,
    defaultValue,
    collapsed,
    validate,
    required,
    ...props
}) => (
    <Field {...props} multiple={multiple} validate={validate}>
        {(childProps: FieldProps) => (
            <ModifierSelectFormFieldComponent
                description={description}
                id={id}
                label={label}
                defaultValue={defaultValue}
                multiple={multiple}
                options={options}
                maxSelection={maxSelection}
                collapsed={collapsed}
                required={required}
                {...childProps}
            />
        )}
    </Field>
);
