import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Collapse, Divider, Theme } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { IItemReadResourceV10 } from 'components/basket/model/Basket';
import { IOrderTotal } from 'components/order/model/Order';
import { getFadeDownGradient } from 'lib/helpers';
import { PayOrderItem } from './PayOrderItem';

interface PayItemsProps {
    loading?: boolean;
    items?: IItemReadResourceV10[];
    total?: IOrderTotal;
    keepOpen?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    itemsBox: {
        position: 'relative'
    },
    itemsButton: {
        textTransform: 'unset',
        backgroundColor: theme.palette.background.default,
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(1.25),
        borderRadius: 0,
        '&.MuiButton-root:hover': {
            backgroundColor: theme.palette.background.default
        }
    },
    itemsButtonWrapper: {
        position: 'absolute',
        bottom: 1,
        width: `calc(100% - ${theme.spacing(4)}px)`
    },
    itemsFade: {
        width: '100%',
        height: '34px',
        background: getFadeDownGradient(theme.palette.background.default, [0, 0.6, 1])
    }
}));

const SKELETON_ITEMS_ARRAY = [0, 1, 2, 3];

export const PayItems: React.FC<PayItemsProps> = ({ loading, items, keepOpen }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    const handleClick = React.useCallback(() => {
        setOpen(isOpen => !isOpen);
    }, []);

    const filteredItems = React.useMemo(() => {
        if (Array.isArray(items)) {
            return items.filter(item => !item.deleted);
        }
        return [];
    }, [items]);

    const renderItem = React.useCallback(
        (item: IItemReadResourceV10) => <PayOrderItem key={item.id} item={item} allItems={filteredItems} />,
        [filteredItems]
    );
    const renderSkeleton = React.useCallback(
        (item: number) => (
            <Box display="flex" justifyContent="space-between" key={`skeleton-${item}`} py={0.25}>
                <Box display="flex">
                    <Box paddingRight={1}>
                        <Skeleton variant="text" width={18} />
                    </Box>
                    <Skeleton variant="text" width={120} />
                </Box>
                <Skeleton variant="text" width={36} />
            </Box>
        ),
        []
    );
    const collapse = React.useMemo(() => {
        if (!filteredItems.length || keepOpen) {
            return false;
        }
        const count = filteredItems.reduce((acc, item) => {
            if (item.modifiers) {
                item.modifiers.forEach(modifier => {
                    modifier.options.forEach(_ => {
                        acc += 1;
                    });
                });
            }
            acc += 1;
            return acc;
        }, 0);
        return count > 4;
    }, [filteredItems, keepOpen]);
    if (loading) {
        return (
            <Box px={2} className={classes.itemsBox}>
                <Box mb={2.5}>{SKELETON_ITEMS_ARRAY.map(renderSkeleton)}</Box>
                <Divider />
            </Box>
        );
    }
    if (!!filteredItems.length) {
        return (
            <Box px={2} className={classes.itemsBox}>
                {collapse && (
                    <Collapse in={open} collapsedSize={112}>
                        <Box mb={4}>{filteredItems.map(renderItem)} </Box>
                    </Collapse>
                )}
                {!collapse && <Box mb={keepOpen ? 1 : 2.5}>{filteredItems.map(renderItem)} </Box>}
                {collapse && (
                    <Box className={classes.itemsButtonWrapper}>
                        {!open && <div className={classes.itemsFade} />}
                        <Button
                            fullWidth
                            onClick={handleClick}
                            className={classes.itemsButton}
                            disableTouchRipple
                        >
                            {open ? t('PAT_QUICKPAY_SHOW_LESS_ITEMS') : t('PAT_QUICKPAY_SHOW_MORE_ITEMS')}
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </Button>
                    </Box>
                )}
                <Divider />
            </Box>
        );
    }
    return null;
};
