import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box,
    Card,
    CardContent,
    createStyles,
    makeStyles,
    Paper,
    RootRef,
    Theme,
    Typography
} from '@material-ui/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { useCalories } from 'src/hooks/useCalories';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { uuid } from 'uuidv4';
import { IBasketItem } from 'components/basket/model/Basket';
import { useLocationHelpers } from 'components/location/hooks/useLocationHelpers';
import { OrderScenario } from 'components/order/model/Order';
import { useTimeSlotHelpers } from 'components/timeslots/useTimeSlotHelpers';
import { QuantityFormField, QuantityLabel } from 'lib/form/QuantityFormField';
import { addOpacity, decompoundKey } from 'lib/helpers';
import { QuantitySelector } from 'lib/QuantitySelector';
import { Throbber } from 'lib/Throbber';
import { isDefined, isEmptyString, isNumber, isString } from 'lib/typeInference';
import { useCurrencyString } from 'lib/useCurrencyString';
import { useWindowSize } from 'lib/useWindowSize';
import { addBasketItems, setBasketPrepTime, setSuggestionsShowed } from 'store/basket/basketActions';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/LoadingButton';
import { useSuggestionsContext } from '../../hooks/useSuggestionsContext';
import {
    getModifierOptionsForProduct,
    getModifierProductsForProduct,
    IEnrichedProduct,
    IOptionModifierSettings,
    IOptionModifierSettingsOption,
    IProduct,
    IProductModifierSettings,
    isProductAvailable
} from './model/Menu';
import { ModifierSelectFormField } from './modifierSelect/ModifierSelectFormField';
import { useBogofModal } from './suggestions/useBogofModal';
import { FormikModifierWrapper } from './FormikModifierWrapper';
import { DYNAMIC_IMAGE_ASPECT_RATIO, MAX_DYNAMIC_IMAGE_WIDTH, MenuDynamicImage } from './MenuDynamicImage';
import { MenuProductDetails } from './MenuProductDetails';
import { MenuProductInfoTimePaddingDialog, PrepTimeModalInfo } from './MenuProductInfoTimePaddingDialog';
import { EGAEventName, isHIBSV4, isSweetwaters, useGAHelpers } from 'lib/useGAHelpers';
import { useParams } from 'react-router-dom';
import { LocationRouteParams } from 'pages/routes';
import { useMenuProductSearch } from './model/useMenuProductSearch';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            borderRadius: theme.shape.borderRadius,
            marginBottom: theme.spacing(1),
            border: `1px solid ${addOpacity(theme.palette.text.primary, 0.08)}`
        },
        cardContent: {
            padding: 0
        },
        form: {
            display: 'flex',
            height: '100%',
            width: '100%',
            flexDirection: 'column'
        },
        customisationsTitle: {
            marginTop: theme.spacing(0.5),
            padding: theme.spacing(1)
        },
        modifierSelect: {
            marginTop: theme.spacing(1),
            color: theme.palette.text.primary
        },
        quantityModifierSelect: {
            padding: theme.spacing(2)
        },
        quantityWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(1),
            margin: -theme.spacing(1),
            marginTop: 'auto',
            zIndex: 9999
        }
    })
);

interface FormikValues {
    productModifiers: (string[] | '')[];
    optionModifiers: (string[] | '')[];
}

interface IMenuProductInfoModifiersProps {
    postAddedToBasket: () => void;
    item: IEnrichedProduct;
    categoryId: string;
}

interface SubmitData {
    data: { productModifiers: (string | string[])[]; optionModifiers: (string | string[])[] } | null;
    formikHelpers: FormikHelpers<FormikValues> | null;
}

export const MenuProductInfoModifiers: React.FC<IMenuProductInfoModifiersProps> = ({
    item,
    postAddedToBasket,
    categoryId
}) => {
    const { getCaloriesByNutrition, getCaloriesString } = useCalories();
    const [quantity, setQuantity] = React.useState(1);
    const { t } = useTranslation();
    const getCurrencyString = useCurrencyString();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { menu, isLoading: isLoadingProduct } = useSelector((state: ApplicationState) => state.menu);
    const { isWorkingHour, isLocationOrderEnabled, checkServiceAvailability } = useLocationHelpers();
    const [atTheBottom, setAtTheBottom] = React.useState(false);
    const modifiersRef = React.useRef<HTMLDivElement>(null);
    const { prepTimePadding, timeSlot } = useSelector((state: ApplicationState) => state.basket);
    const [scenario] = useRouteScenario();
    const { isTimeSlotsAvailable, asapEnabled } = useTimeSlotHelpers();
    const [prepTimeModalInfo, setPrepTimeModalInfo] = React.useState<PrepTimeModalInfo | undefined>();
    const [submitData, setSubmitData] = React.useState<SubmitData>({ data: null, formikHelpers: null });
    const context = useSuggestionsContext();
    const { bogofModal, setBogofSuggestion, setProduct, newQuantity, setNewQuantity } = useBogofModal(
        postAddedToBasket,
        quantity,
        true
    );
    const { merchantId, locationId } = useParams<LocationRouteParams>();
    const { logUserEvent, getBasketEventBody } = useGAHelpers();
    const { findProductById } = useMenuProductSearch();

    const calculatePosition = React.useCallback(() => {
        if (modifiersRef && modifiersRef.current) {
            if (
                modifiersRef.current.scrollHeight - modifiersRef.current.scrollTop ===
                modifiersRef.current.clientHeight
            ) {
                setAtTheBottom(true);
            }
        }
    }, [modifiersRef]);

    React.useEffect(() => {
        calculatePosition();
    }, [calculatePosition]);

    const handleScroll = React.useCallback(
        (event: React.UIEvent<HTMLDivElement>) => {
            const bottom =
                event.currentTarget.scrollHeight - event.currentTarget.scrollTop ===
                event.currentTarget.clientHeight;
            if (bottom && !atTheBottom) {
                setAtTheBottom(true);
            }
            if (!bottom && atTheBottom) {
                setAtTheBottom(false);
            }
        },
        [atTheBottom]
    );

    const productModifierSettings = React.useMemo<IProductModifierSettings[]>(
        () =>
            (menu ? getModifierProductsForProduct(menu, item.modifiers || []) : []).map(modifier => ({
                ...modifier,
                products: modifier.products.slice().sort((a, b) => a.sort - b.sort)
            })),
        [menu, item.modifiers]
    );
    const optionModifierSettings = React.useMemo<IOptionModifierSettings[]>(
        () => (menu ? getModifierOptionsForProduct(menu, item.id, item.modifiers) : []),
        [menu, item]
    );

    const renderModifierSelectionInfo = React.useCallback(
        (min: number, max: number) => {
            if (min === 1 && max === min) {
                return t('MENU_MODIFIERS_CUSTOMIZATIONS_REQUIRED_SINGLE');
            }
            if (min >= 1) {
                if (max === min) {
                    return t('MENU_MODIFIERS_CUSTOMIZATIONS_REQUIRED_MULTIPLE', { min });
                }
                return t('MENU_MODIFIERS_CUSTOMIZATIONS_REQUIRED_MULTIPLEDIFFERENCE', { min, max });
            }
        },
        [t]
    );

    const modifierValidation = React.useCallback(
        (modifier: IProductModifierSettings | IOptionModifierSettings) => (modifiers: string[]) => {
            let error;
            // If only one modifier should be selected component returns string
            // instead of one item inside array.
            const flattenModifiers = [modifiers].flat();
            if (modifier.min !== 0) {
                if (
                    !modifiers ||
                    flattenModifiers.length < modifier.min ||
                    flattenModifiers.length > modifier.max
                ) {
                    error = renderModifierSelectionInfo(modifier.min, modifier.max);
                }
            }
            return error;
        },
        [renderModifierSelectionInfo]
    );

    const getProductModifierOptions = React.useCallback(
        (modifierProduct: IProduct) => {
            const details = {
                label: `${modifierProduct.title} +${getCurrencyString(modifierProduct.price)}`,
                value: modifierProduct.id,
                selected: modifierProduct.selected,
                description: ''
            };
            if (modifierProduct.nutrition && modifierProduct.nutrition.length > 0) {
                const modifierCalories = Number(getCaloriesByNutrition(modifierProduct.nutrition));
                if (modifierCalories >= 0) {
                    const caloriesString = `${getCaloriesString(modifierCalories)} ${t(
                        'ORDER_CALORIES_PER_UNIT'
                    )}`;
                    details.description +=
                        details.description.length > 0 ? ` | ${caloriesString}` : caloriesString;
                }
            }
            return details;
        },
        [getCaloriesByNutrition, getCaloriesString, getCurrencyString, t]
    );

    const showPrice = React.useCallback(
        (price: number) => {
            if (isNumber(price) && price > 0) {
                return `+${getCurrencyString(price)}`;
            }
            return '';
        },
        [getCurrencyString]
    );

    const renderProductModifierOptions = React.useCallback(
        (isBundle?: boolean) => (modifierProduct: IProduct) => {
            const details = {
                label: modifierProduct.title,
                price: isBundle ? undefined : `+${getCurrencyString(modifierProduct.price)}`,
                value: modifierProduct.id,
                disabled: !isProductAvailable(modifierProduct),
                image:
                    modifierProduct?.modifierImage ||
                    modifierProduct.listingImageUrl ||
                    modifierProduct.imageUrl,
                description: isBundle ? '' : showPrice(modifierProduct.price)
            };
            if (modifierProduct.nutrition && modifierProduct.nutrition.length > 0) {
                const modifierCalories = getCaloriesByNutrition(modifierProduct.nutrition);
                if (modifierCalories && Number(modifierCalories) >= 0) {
                    const caloriesString = `${getCaloriesString(Number(modifierCalories))}  ${t(
                        'ORDER_CALORIES_PER_UNIT'
                    )}`;
                    details.description +=
                        details.description.length > 0 ? ` | ${caloriesString}` : caloriesString;
                }
            }

            return details;
        },
        [getCaloriesByNutrition, getCaloriesString, getCurrencyString, showPrice, t]
    );

    const getProductQuantityModifierOptions = React.useCallback(
        (isBundle?: boolean) => (acc: Record<string, QuantityLabel>, modifierProduct: IProduct) => {
            acc[modifierProduct.id] = {
                title: modifierProduct.title,
                description: isBundle ? undefined : showPrice(modifierProduct.price),
                image:
                    modifierProduct?.modifierImage ||
                    modifierProduct.listingImageUrl ||
                    modifierProduct.imageUrl,
                disabled: !isProductAvailable(modifierProduct)
            };
            if (modifierProduct.nutrition && modifierProduct.nutrition.length > 0) {
                const modifierCalories = getCaloriesByNutrition(modifierProduct.nutrition);

                if (modifierCalories && Number(modifierCalories) >= 0) {
                    const caloriesString = `${getCaloriesString(Number(modifierCalories))}  ${t(
                        'ORDER_CALORIES_PER_UNIT'
                    )}`;

                    if (!isDefined(acc[modifierProduct.id].description)) {
                        acc[modifierProduct.id].description = caloriesString;
                    } else if (isString(acc[modifierProduct.id].description)) {
                        acc[modifierProduct.id].description += ` | ${caloriesString}`;
                    }
                }
            }
            return acc;
        },
        [getCaloriesByNutrition, getCaloriesString, showPrice, t]
    );

    const getOptionQuantityModifierOptions = React.useCallback(
        (isBundle?: boolean) =>
            (acc: Record<string, QuantityLabel>, modifierOption: IOptionModifierSettingsOption) => {
                acc[modifierOption.id] = {
                    title: modifierOption.title,
                    description: isBundle ? undefined : showPrice(modifierOption.price),
                    image: modifierOption.imageUrl
                };

                if (modifierOption.nutrition && modifierOption.nutrition.length > 0) {
                    const modifierCalories = getCaloriesByNutrition(modifierOption.nutrition);

                    if (modifierCalories && Number(modifierCalories) >= 0) {
                        const caloriesString = `${getCaloriesString(Number(modifierCalories))}  ${t(
                            'ORDER_CALORIES_PER_UNIT'
                        )}`;

                        if (
                            !isDefined(acc[modifierOption.id].description) ||
                            isEmptyString(acc[modifierOption.id].description)
                        ) {
                            acc[modifierOption.id].description = caloriesString;
                        } else if (isString(acc[modifierOption.id].description)) {
                            acc[modifierOption.id].description += ` | ${caloriesString}`;
                        }
                    }
                }
                return acc;
            },
        [getCaloriesByNutrition, getCaloriesString, showPrice, t]
    );

    const isHiddenBundleProductModifier = React.useCallback(
        (modifier: IProductModifierSettings) => {
            const products = modifier.products.map(getProductModifierOptions);
            const selectedIds = products.filter(product => product.selected).map(product => product.value);
            return !(modifier && modifier.products.length === 1 && !!selectedIds.length);
        },
        [getProductModifierOptions]
    );

    const isHiddenBundleOptionModifier = React.useCallback((modifier: IOptionModifierSettings) => {
        const selectedIds = modifier.options.filter(option => option.selected);
        return !(modifier && modifier.options.length === 1 && !!selectedIds.length);
    }, []);

    const showModifiersBox = React.useMemo(
        () =>
            productModifierSettings.filter(isHiddenBundleProductModifier).length > 0 ||
            optionModifierSettings.filter(isHiddenBundleOptionModifier).length > 0,
        [
            productModifierSettings,
            optionModifierSettings,
            isHiddenBundleProductModifier,
            isHiddenBundleOptionModifier
        ]
    );

    const renderProductModifierSelect = React.useCallback(
        (modifier: IProductModifierSettings, index: number) => {
            const products = modifier.products.map(getProductModifierOptions);
            const selectedIds = products.filter(product => product.selected).map(product => product.value);
            if (modifier.bundle && modifier.products.length === 1 && selectedIds.length) {
                return null;
            }

            if (modifier.allowQuantitySelect && modifier.max > 1) {
                return (
                    <div className={classes.quantityModifierSelect} key={`modifier-${modifier.title}`}>
                        <QuantityFormField
                            name={`productModifiers[${index}]`}
                            label={modifier.title}
                            labels={modifier.products.reduce<Record<string, QuantityLabel>>(
                                getProductQuantityModifierOptions(modifier.bundle),
                                {}
                            )}
                            collapsed={modifier.isCollapsed}
                            validate={modifierValidation(modifier)}
                            className={classes.modifierSelect}
                            max={modifier.max}
                            min={modifier.min}
                        />
                    </div>
                );
            }
            return (
                <div className={classes.modifierSelect} key={`modifier-${modifier.title}`}>
                    <ModifierSelectFormField
                        name={`productModifiers[${index}]`}
                        label={modifier.title}
                        options={modifier.products.map(renderProductModifierOptions(modifier.bundle))}
                        validate={modifierValidation(modifier)}
                        defaultValue={selectedIds}
                        className={classes.modifierSelect}
                        multiple={modifier.max > 1}
                        collapsed={modifier.isCollapsed}
                        description={renderModifierSelectionInfo(modifier.min, modifier.max)}
                        maxSelection={modifier.max}
                        required={modifier.min > 0}
                    />
                </div>
            );
        },
        [
            classes.modifierSelect,
            modifierValidation,
            renderModifierSelectionInfo,
            renderProductModifierOptions,
            getProductModifierOptions,
            getProductQuantityModifierOptions,
            classes.quantityModifierSelect
        ]
    );
    const renderOptionModifierSelect = React.useCallback(
        (modifier: IOptionModifierSettings, index: number) => {
            const options = modifier.options.map(modifierOption => {
                let description = '';
                if (!modifier.bundle) {
                    if (modifierOption.price > 0) {
                        description += `+${getCurrencyString(modifierOption.price)}`;
                    }
                }
                if (modifierOption.nutrition) {
                    const modifierCalories = getCaloriesByNutrition(modifierOption.nutrition);
                    if (modifierCalories && Number(modifierCalories) >= 0) {
                        const caloriesString = `${getCaloriesString(Number(modifierCalories))}  ${t(
                            'ORDER_CALORIES_PER_UNIT'
                        )}`;
                        description += description.length > 0 ? ` | ${caloriesString}` : caloriesString;
                    }
                }
                return {
                    label: modifierOption.title,
                    labelEnd: modifier.bundle ? undefined : `+${getCurrencyString(modifierOption.price)}`,
                    value: modifierOption.id,
                    selected: modifierOption.selected,
                    description,
                    image: modifierOption.imageUrl
                };
            });
            const selectedIds = options.filter(option => option.selected).map(option => option.value);
            if (modifier.bundle && modifier.options.length === 1 && selectedIds.length) {
                return null;
            }

            if (modifier.allowQuantitySelect && modifier.max > 1) {
                return (
                    <div className={classes.quantityModifierSelect} key={`modifier-${modifier.title}`}>
                        <QuantityFormField
                            name={`optionModifiers[${index}]`}
                            label={modifier.title}
                            labels={modifier.options.reduce<Record<string, QuantityLabel>>(
                                getOptionQuantityModifierOptions(modifier.bundle),
                                {}
                            )}
                            collapsed={modifier.isCollapsed}
                            className={classes.modifierSelect}
                            max={modifier.max}
                            min={modifier.min}
                        />
                    </div>
                );
            }

            return (
                <div className={classes.modifierSelect} key={`modifier-${modifier.title}`}>
                    <ModifierSelectFormField
                        name={`optionModifiers[${index}]`}
                        label={modifier.title}
                        options={options}
                        collapsed={modifier.isCollapsed}
                        defaultValue={selectedIds}
                        className={classes.modifierSelect}
                        multiple={modifier.max > 1}
                        validate={modifierValidation(modifier)}
                        maxSelection={modifier.max}
                        required={modifier.min > 0}
                    />
                </div>
            );
        },
        [
            classes.modifierSelect,
            classes.quantityModifierSelect,
            modifierValidation,
            getCurrencyString,
            getCaloriesByNutrition,
            getCaloriesString,
            t,
            getOptionQuantityModifierOptions
        ]
    );

    const getSingularPrice = React.useCallback(
        (formOptionModifiers: (string | string[])[], formProductModifiers: (string | string[])[]) => {
            let { price } = item;
            for (const productId of [...formOptionModifiers, ...formProductModifiers]) {
                const productIds = Array.isArray(productId) ? productId : [productId];
                for (const actualProductId of productIds) {
                    const modifierOption = optionModifierSettings
                        .find(modifier => modifier.options.some(option => option.id === actualProductId))
                        ?.options.find(option => option.id === actualProductId);
                    const menuProduct =
                        menu && (menu.productIdToProduct.get(actualProductId) || modifierOption);

                    if (menuProduct) {
                        price += menuProduct.price;
                    }
                }
            }
            return Math.round(price * 100) / 100; // Prevent .00000001
        },
        [item, menu, optionModifierSettings]
    );

    const handleCancelPrepTimeInfo = React.useCallback(() => {
        setNewQuantity(null);
        setPrepTimeModalInfo(undefined);
        setBogofSuggestion(null);
        setProduct(null);
    }, [setBogofSuggestion, setNewQuantity, setProduct]);

    const processHandleSubmit = React.useCallback(
        (
            data: { productModifiers: (string | string[])[]; optionModifiers: (string | string[])[] },
            formikHelpers: FormikHelpers<FormikValues>,
            newBogofQuantity?: number
        ) => {
            if (!menu) {
                return;
            }
            let timePadding = 0;
            const optionModifiers: IBasketItem['modifiers'] = [];
            const productModifiers: (IBasketItem & { price: number })[] = [];
            const parentReferenceId = uuid();
            data.productModifiers.forEach(value => {
                const valueArr = Array.isArray(value) ? value : [value];
                for (const modifierProductId of valueArr) {
                    const { childId, parentId } = decompoundKey(modifierProductId);
                    const modifierProduct = parentId
                        ? findProductById(childId, parentId)
                        : menu.productIdToProduct.get(childId);
                    if (modifierProduct) {
                        const currentModifier = productModifiers.find(
                            productModifier => productModifier.productId === modifierProduct.id
                        );
                        if (currentModifier) {
                            currentModifier.quantity += isDefined(newBogofQuantity)
                                ? newBogofQuantity
                                : quantity;
                        } else {
                            if (
                                isNumber(modifierProduct.prepTimeMins) &&
                                modifierProduct.prepTimeMins > timePadding
                            ) {
                                timePadding = modifierProduct.prepTimeMins;
                            }
                            productModifiers.push({
                                parentReferenceId,
                                quantity: isDefined(newBogofQuantity) ? newBogofQuantity : quantity,
                                categoryId:
                                    parentId ?? menu.productIdToCategoryId.get(modifierProduct.id) ?? '',
                                productId: modifierProduct.id,
                                price: 0
                            });
                        }
                    }
                }
            });
            data.optionModifiers.forEach((value, index) => {
                if (optionModifierSettings && optionModifierSettings[index]) {
                    let valueArr: string[];

                    if (Array.isArray(value)) {
                        valueArr = value;
                    } else if (isEmptyString(value)) {
                        valueArr = [];
                    } else {
                        valueArr = [value];
                    }

                    optionModifiers.push({
                        id: optionModifierSettings[index].id,
                        options: valueArr.map(modifierValue => ({ id: modifierValue }))
                    });
                }
            });
            // The Actual Product to Add, with it's ModifierOptions
            const basketItem: IBasketItem & { price: number } = {
                productId: item.id,
                categoryId,
                quantity: isDefined(newBogofQuantity) ? newBogofQuantity : quantity,
                modifiers: optionModifiers.length === 0 ? undefined : optionModifiers,
                price: getSingularPrice(data.optionModifiers, data.productModifiers),
                referenceId: productModifiers.length === 0 ? undefined : parentReferenceId
            };
            if (isNumber(item.prepTimeMins) && item.prepTimeMins > timePadding) {
                timePadding = item.prepTimeMins;
            }
            const isPaddingBigger =
                scenario === OrderScenario.PREORDER && !timeSlot && timePadding > (prepTimePadding ?? 0);
            const addItemToBasketFunction = () => {
                const productsToAdd = [basketItem, ...productModifiers];
                if (isPaddingBigger) {
                    setBasketPrepTime(timePadding)(dispatch);
                }
                if (isHIBSV4(merchantId) && isString(locationId)) {
                    logUserEvent(
                        EGAEventName.AddToBasketDetailed,
                        getBasketEventBody(productsToAdd, locationId)
                    );
                }
                addBasketItems(productsToAdd)(dispatch);
                context.setShowMoreSuggestionInfo(false);
                if (context.isSuggestionTypeAdd(item) && item.suggestions) {
                    context.setSuggestion(item.suggestions[0]);
                    context.setAddedProduct(item);
                    setSuggestionsShowed()(dispatch);
                }
                postAddedToBasket();
            };
            if (isPaddingBigger) {
                formikHelpers.setSubmitting(false);
                setPrepTimeModalInfo({
                    onSuccess: addItemToBasketFunction,
                    onCancel: handleCancelPrepTimeInfo,
                    productTitle: item.title,
                    timePadding
                });
            } else {
                addItemToBasketFunction();
            }
            // Add Product and All it's Modifier Products
        },
        [
            menu,
            item,
            categoryId,
            quantity,
            getSingularPrice,
            scenario,
            timeSlot,
            prepTimePadding,
            findProductById,
            optionModifierSettings,
            merchantId,
            dispatch,
            context,
            postAddedToBasket,
            logUserEvent,
            getBasketEventBody,
            locationId,
            handleCancelPrepTimeInfo
        ]
    );

    const handleSubmit = React.useCallback(
        (
            data: { productModifiers: (string | string[])[]; optionModifiers: (string | string[])[] },
            formikHelpers: FormikHelpers<FormikValues>
        ) => {
            if (isSweetwaters(merchantId)) {
                logUserEvent(EGAEventName.AddToBasketSweetwaters);
            }
            if (context.isSuggestionTypeBogof(item) && item.suggestions) {
                setBogofSuggestion(item.suggestions[0]);
                setProduct(item);
                setSubmitData({ data, formikHelpers });
                return Promise.resolve();
            }
            processHandleSubmit(data, formikHelpers);
        },
        [context, item, logUserEvent, merchantId, processHandleSubmit, setBogofSuggestion, setProduct]
    );

    React.useEffect(() => {
        if (newQuantity && submitData.data && submitData.formikHelpers) {
            return processHandleSubmit(submitData.data, submitData.formikHelpers, newQuantity);
        }
    }, [newQuantity, processHandleSubmit, submitData.data, submitData.formikHelpers]);

    const defaultProductModifiers = React.useMemo(
        () =>
            productModifierSettings.map(modifier => {
                const selectedProducts = modifier.products.filter(product => product.selected);
                if (selectedProducts.length) {
                    return selectedProducts.slice(0, modifier.max).map(product => product.id);
                }
                if (modifier.max === 1) {
                    return '';
                }
                return [];
            }),
        [productModifierSettings]
    );
    const defaultOptionModifiers = React.useMemo(
        () =>
            optionModifierSettings.map(modifier => {
                const selectedOptions = modifier.options.filter(option => option.selected);

                if (selectedOptions.length) {
                    return selectedOptions.slice(0, modifier.max).map(option => option.id);
                }
                if (modifier.max === 1) {
                    return '';
                }
                return [];
            }),
        [optionModifierSettings]
    );

    const windowSize = useWindowSize();
    const imageWidth = React.useMemo(
        () =>
            !windowSize.width || windowSize.width > MAX_DYNAMIC_IMAGE_WIDTH
                ? MAX_DYNAMIC_IMAGE_WIDTH
                : windowSize.width,
        [windowSize.width]
    );
    const imageHeight = React.useMemo(() => imageWidth * DYNAMIC_IMAGE_ASPECT_RATIO, [imageWidth]);

    if (isLoadingProduct) {
        return <Throbber text={t('GENERAL_LOADING')} />;
    }
    const isDynamicImage = !!item.dynamicImagery && !!item.dynamicImagery.baseImageUrl;

    return (
        <Formik
            initialValues={{
                productModifiers: defaultProductModifiers,
                optionModifiers: defaultOptionModifiers
            }}
            onSubmit={handleSubmit}
        >
            {({ submitForm, isSubmitting, values, initialValues, isValid, validateForm }) => {
                const getTotalPrice = () =>
                    getSingularPrice(values.optionModifiers, values.productModifiers) * quantity;
                const formProductModifiers = values.productModifiers;
                return (
                    <FormikModifierWrapper initialValues={initialValues} validateForm={validateForm}>
                        {!isLoadingProduct && (
                            <Form
                                className={classes.form}
                                style={{ paddingTop: isDynamicImage ? imageHeight - 8 : 0 }}
                            >
                                <RootRef rootRef={modifiersRef}>
                                    <Box
                                        overflow="auto"
                                        flex={1}
                                        onScroll={handleScroll}
                                        paddingTop={isDynamicImage ? 1 : 0}
                                    >
                                        <MenuProductDetails
                                            showModifiers={showModifiersBox}
                                            disabledImage={isDynamicImage}
                                            item={item}
                                            modifiers={productModifierSettings}
                                            hasVisibleModifiers={showModifiersBox}
                                        />
                                        {showModifiersBox && (
                                            <Card className={classes.card} elevation={0}>
                                                <CardContent className={classes.cardContent}>
                                                    <Typography
                                                        variant="subtitle2"
                                                        className={classes.customisationsTitle}
                                                    >
                                                        {t('PRODUCT_DETAILS_CUSTOMISATIONS_LABEL')}
                                                    </Typography>
                                                    {productModifierSettings.map(renderProductModifierSelect)}
                                                    {optionModifierSettings.map(renderOptionModifierSelect)}
                                                </CardContent>
                                            </Card>
                                        )}
                                    </Box>
                                </RootRef>
                                <Paper
                                    elevation={atTheBottom ? 0 : 4}
                                    square
                                    className={classes.quantityWrapper}
                                >
                                    <QuantitySelector
                                        min={1}
                                        max={999}
                                        quantity={quantity}
                                        onChange={setQuantity}
                                    />
                                    <LoadingButton
                                        onClick={submitForm}
                                        loading={isSubmitting}
                                        disabled={
                                            isSubmitting ||
                                            !isWorkingHour ||
                                            !isValid ||
                                            !isLocationOrderEnabled ||
                                            (scenario === OrderScenario.PREORDER &&
                                                !asapEnabled &&
                                                !isTimeSlotsAvailable) ||
                                            (!!scenario && !checkServiceAvailability(scenario))
                                        }
                                        color="primary"
                                        variant="contained"
                                        fullWidth
                                        data-cy="menu-add-button"
                                    >
                                        {t(
                                            isValid
                                                ? 'PREORDER_ADD_TO_BASKET'
                                                : 'PREORDER_MODIFY_ITEM_BUTTON_TITLE',
                                            {
                                                quantity,
                                                price: getCurrencyString(getTotalPrice())
                                            }
                                        )}
                                    </LoadingButton>
                                </Paper>
                                {isDynamicImage && (
                                    <MenuDynamicImage
                                        product={item}
                                        modifierProducts={formProductModifiers}
                                    />
                                )}

                                <MenuProductInfoTimePaddingDialog
                                    open={!!prepTimeModalInfo}
                                    info={prepTimeModalInfo}
                                    onCancel={handleCancelPrepTimeInfo}
                                />
                                {bogofModal()}
                            </Form>
                        )}
                    </FormikModifierWrapper>
                );
            }}
        </Formik>
    );
};
