import React from 'react';
import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    makeStyles,
    PropTypes,
    Theme,
    Typography
} from '@material-ui/core';
import { LoadingButton } from 'ui/LoadingButton';
import { isString } from './typeInference';

interface ConfirmDialogProps extends DialogProps {
    title?: string;
    onClose: () => void;
    description: string | JSX.Element;
    actionButtons?: React.ReactNode;
    isLoading?: boolean;
    confirmMessage?: string;
    cancelMessage?: string;
    isCancellable?: boolean;
    onConfirm: () => void;
    align?: PropTypes.Alignment;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogWrapper: {
            padding: theme.spacing(2)
        }
    })
);

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    actionButtons = null,
    title,
    description,
    confirmMessage = 'Confirm',
    cancelMessage = 'Cancel',
    isCancellable = true,
    isLoading,
    align,
    onConfirm,
    onClose,
    disableBackdropClick,
    ...props
}) => {
    const classes = useStyles();
    const handleClose = React.useCallback(() => {
        if (!disableBackdropClick) {
            onClose();
        }
    }, [disableBackdropClick, onClose]);

    return (
        <Dialog onClose={handleClose} {...props} className={classes.dialogWrapper}>
            {title && (
                <DialogTitle>
                    <Typography align={align} variant="h5">
                        {title}
                    </Typography>
                </DialogTitle>
            )}
            <DialogContent>
                {isString(description) ? (
                    <DialogContentText color="inherit">
                        <Typography align={align} variant="body1">
                            {description}
                        </Typography>
                    </DialogContentText>
                ) : (
                    description
                )}
            </DialogContent>
            <DialogActions>
                {actionButtons}
                {isCancellable && (
                    <Button color="primary" variant="outlined" onClick={onClose} disabled={isLoading}>
                        {cancelMessage}
                    </Button>
                )}
                <LoadingButton
                    onClick={onConfirm}
                    variant="contained"
                    color="primary"
                    loading={isLoading}
                    disabled={isLoading}
                    fullWidth={!isCancellable && !actionButtons}
                    data-cy="mpo-success-ok"
                >
                    {confirmMessage}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
