import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';

export function usePayActions(tableSelectorOpen: boolean, orderLoading: boolean, isOrderNotFound?: boolean) {
    const [open, setOpen] = React.useState(true);
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const isMenuAvailable = React.useMemo(
        () => !!settings?.payAtTable?.noTableCheckFoundUrl && !isOrderNotFound,
        [isOrderNotFound, settings?.payAtTable?.noTableCheckFoundUrl]
    );
    const showDialog = React.useMemo(
        () => isOrderNotFound || (open && isMenuAvailable && !tableSelectorOpen && !orderLoading),
        [isMenuAvailable, isOrderNotFound, open, orderLoading, tableSelectorOpen]
    );
    const onOpen = React.useCallback(() => {
        setOpen(true);
    }, []);
    const onClose = React.useCallback(() => {
        setOpen(false);
    }, []);
    return { showDialog, isMenuAvailable, onOpen, onClose };
}
