import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, createStyles, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { useCalories } from 'src/hooks/useCalories';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { useSuggestionsContext } from 'src/hooks/useSuggestionsContext';
import { OrderScenario } from 'components/order/model/Order';
import { BottomDialog } from 'lib/BottomDialog';
import { QuantitySelector } from 'lib/QuantitySelector';
import { isDefined, isNumber } from 'lib/typeInference';
import { useCurrencyString } from 'lib/useCurrencyString';
import { addBasketItem, setBasketPrepTime } from 'store/basket/basketActions';
import { ApplicationState } from 'store/store';
import { MenuProductInfoTimePaddingDialog, PrepTimeModalInfo } from '../MenuProductInfoTimePaddingDialog';
import { IEnrichedProduct, IProduct, ISuggestions, ProductGroup } from '../model/Menu';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: '20px',
            fontWeight: 'bold',
            fontFamily: `TenantFont, ${theme.typography.fontFamily}`,
            color: theme.palette.titleTextColour
        },
        subTitle: {
            marginBottom: theme.spacing(0.5),
            fontSize: '20px',
            color: theme.palette.titleTextColour
        },
        content: {
            padding: theme.spacing(1.5, 2)
        },
        suggestionBox: {
            marginBottom: theme.spacing(2),
            overflow: 'hidden',
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius
        },
        suggestionInfoBox: {
            padding: theme.spacing(1.5, 2)
        },
        suggestionTitle: {
            fontWeight: 'bold',
            color: theme.palette.titleTextColour
        },
        productBox: {
            marginBottom: theme.spacing(1.5),
            padding: theme.spacing(1.5, 2),
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius
        },
        productDescriptionBox: {
            width: '80%'
        },
        productDescription: {
            opacity: '0.8'
        },
        calories: {
            opacity: '0.8'
        },
        button: {
            marginBottom: theme.spacing(1)
        },
        imageSuggestionWrapper: {
            width: '100%',
            overflow: 'hidden'
        },
        imageProductWrapper: {
            width: '60px',
            minWidth: '60px',
            height: '60px',
            marginLeft: theme.spacing(1.5),
            overflow: 'hidden',
            borderRadius: theme.shape.borderRadius
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        },
        imageHidden: {
            visibility: 'hidden',
            width: 0,
            height: 0,
            padding: 0,
            margin: 0
        },
        quantityBox: {
            marginBottom: theme.spacing(0.5)
        },
        divider: {
            marginBottom: theme.spacing(1)
        },
        skeleton: {
            height: '120px',
            width: '100%'
        }
    })
);

interface MenuSuggestionsAddProps {
    open: boolean;
    onClose: () => void;
    showMoreInfo: (productId: IProduct | ProductGroup | undefined) => void;
    currency: string;
    suggestion: ISuggestions;
    addedItem: IEnrichedProduct;
    suggestedProduct: IEnrichedProduct;
}

export const MenuSuggestionsAdd: React.FC<MenuSuggestionsAddProps> = ({
    open,
    onClose,
    showMoreInfo,
    addedItem,
    suggestion,
    suggestedProduct
}) => {
    const { title, description, imageUrl } = suggestion;
    const { key: energyKey, getProductCalories, getCaloriesString } = useCalories();
    const classes = useStyles();
    const dispatch = useDispatch();
    const menu = useSelector((state: ApplicationState) => state.menu.menu);
    const categories = useSelector((state: ApplicationState) => state.menu.categories);
    const { prepTimePadding, timeSlot } = useSelector((state: ApplicationState) => state.basket);
    const getCurrencyString = useCurrencyString();
    const [scenario] = useRouteScenario();
    const { t } = useTranslation();
    const [quantity, setQuantity] = React.useState(1);
    const [imageLoaded, setImageLoaded] = React.useState(false);
    const [imageProductLoaded, setImageProductLoaded] = React.useState(false);
    const [prepTimeModalInfo, setPrepTimeModalInfo] = React.useState<PrepTimeModalInfo | undefined>();
    const productNutrition = React.useMemo(
        () => getProductCalories(suggestedProduct),
        [getProductCalories, suggestedProduct]
    );

    const context = useSuggestionsContext();
    const itemHasInfo = React.useMemo(() => {
        const prodItem = suggestedProduct;
        let hasModifiers = false;
        if (prodItem.modifiers && prodItem.modifiers.length !== 0 && menu) {
            for (const modifierId of prodItem.modifiers) {
                const categoryId = menu.productIdToCategoryId.get(prodItem.id);
                if (
                    (!!categoryId &&
                        menu.modifierIdToCategoryOptionModifierSettings.get(categoryId)?.get(modifierId)) ??
                    menu.modifierIdToOptionModifierSettings.get(modifierId) ??
                    menu.modifierIdToProductModifierSettings.get(modifierId)
                ) {
                    hasModifiers = true;
                }
            }
        }
        const nutrition =
            prodItem.nutrition &&
            prodItem.nutrition.length !== 0 &&
            prodItem.nutrition[0].values.filter(item => item.nutrient !== energyKey).length > 0;
        return (
            prodItem.imageUrl ||
            hasModifiers ||
            (prodItem.allergens && prodItem.allergens.length !== 0) ||
            (prodItem.shortDescription && prodItem.shortDescription.length !== 0) ||
            (prodItem.tags && prodItem.tags.length !== 0) ||
            nutrition
        );
    }, [suggestedProduct, menu, energyKey]);

    const handleShowMoreinfo = React.useCallback(() => {
        if (menu) {
            context.setDefaultSuggestedValue(suggestedProduct);
            if (suggestedProduct?.productGroupId) {
                const categoryId = menu.productIdToCategoryId.get(suggestedProduct.id);
                const categoryGroup = categories
                    .find(item => item.id === categoryId)
                    ?.products.find(item => item.id === suggestedProduct.productGroupId);

                if (categoryGroup) {
                    return showMoreInfo(categoryGroup);
                }
            }
            const product: IProduct | undefined = menu.productIdToProduct.get(String(suggestedProduct.id));
            showMoreInfo(product);
        }
    }, [categories, context, menu, showMoreInfo, suggestedProduct]);

    const handleCancelPrepTimeInfo = React.useCallback(() => {
        setPrepTimeModalInfo(undefined);
        onClose();
    }, [onClose]);

    const handleAddItemToBasket = React.useCallback(() => {
        let timePadding = 0;
        let paddingBigger = false;

        const addItemToBasketFunction = () => {
            if (!menu) {
                return;
            }
            const categoryId = menu.productIdToCategoryId.get(suggestedProduct.id);
            addBasketItem(
                suggestedProduct,
                categoryId || '',
                quantity,
                undefined,
                suggestedProduct.price
            )(dispatch);
            if (paddingBigger) {
                setBasketPrepTime(timePadding)(dispatch);
            }
            onClose();
        };
        if (isNumber(suggestedProduct?.prepTimeMins)) {
            timePadding = suggestedProduct.prepTimeMins;
            const isPaddingBigger =
                scenario === OrderScenario.PREORDER && !timeSlot && timePadding > (prepTimePadding ?? 0);

            if (isPaddingBigger) {
                paddingBigger = true;
                setPrepTimeModalInfo({
                    onSuccess: addItemToBasketFunction,
                    onCancel: handleCancelPrepTimeInfo,
                    productTitle: suggestedProduct.title,
                    timePadding
                });
                return;
            }
        }
        addItemToBasketFunction();
    }, [
        menu,
        suggestedProduct,
        quantity,
        dispatch,
        onClose,
        scenario,
        timeSlot,
        prepTimePadding,
        handleCancelPrepTimeInfo
    ]);

    const suggestedProductTitle = React.useMemo(
        () => suggestedProduct.productGroupName ?? suggestedProduct.title,
        [suggestedProduct.productGroupName, suggestedProduct.title]
    );
    const suggestedProductDescription = React.useMemo(
        () => suggestedProduct?.shortDescription ?? '',
        [suggestedProduct?.shortDescription]
    );
    const suggestionsTitle = React.useMemo(() => title ?? '', [title]);
    const suggestionsDescription = React.useMemo(() => {
        if (description && description.includes('{{product}}')) {
            return description.replace('{{product}}', suggestedProductTitle);
        }
        if (description) {
            return description;
        }
        return false;
    }, [description, suggestedProductTitle]);
    const addedProductTitle = React.useMemo(
        () =>
            isDefined(addedItem?.productGroupName)
                ? addedItem.productGroupName + ' ' + addedItem.title
                : addedItem.title,
        [addedItem]
    );
    const suggestionImage = React.useMemo(() => imageUrl ?? false, [imageUrl]);
    const handleImageLoaded = React.useCallback(() => {
        setImageLoaded(true);
    }, []);
    const suggestedProductImage = React.useMemo(
        () => suggestedProduct?.listingImageUrl || suggestedProduct?.imageUrl || false,
        [suggestedProduct?.imageUrl, suggestedProduct?.listingImageUrl]
    );
    const handleImageProductLoaded = React.useCallback(() => {
        setImageProductLoaded(true);
    }, []);
    return (
        <>
            <BottomDialog open={open} onClose={onClose}>
                <Box className={classes.content}>
                    {addedProductTitle && (
                        <Typography className={classes.title} align="center">
                            {addedProductTitle}
                        </Typography>
                    )}
                    <Typography className={classes.subTitle} align="center">
                        {t('RELATED_PRODUCT_ADD_CONFIRMATION')}
                    </Typography>
                    <Divider className={classes.divider} />
                    <Box className={classes.suggestionBox}>
                        {suggestionImage && (
                            <>
                                <Box
                                    className={clsx(classes.imageSuggestionWrapper, {
                                        [classes.imageHidden]: !imageLoaded
                                    })}
                                >
                                    <img
                                        className={classes.image}
                                        src={suggestionImage}
                                        onLoad={handleImageLoaded}
                                        alt={suggestionsTitle}
                                    />
                                </Box>
                                {!imageLoaded && <Skeleton className={classes.skeleton} variant="rect" />}
                            </>
                        )}
                        <Box className={classes.suggestionInfoBox}>
                            <Typography className={classes.suggestionTitle} align="center">
                                {suggestionsTitle ? suggestionsTitle : t('RELATED_PRODUCT_ADD_TITLE')}
                            </Typography>
                            {suggestionsDescription && (
                                <Typography align="center">{suggestionsDescription}</Typography>
                            )}
                        </Box>
                    </Box>

                    <Box
                        className={classes.productBox}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Box className={classes.productDescriptionBox}>
                            <Typography>{suggestedProductTitle}</Typography>
                            {suggestedProductDescription && (
                                <Typography className={classes.productDescription}>
                                    {suggestedProductDescription}
                                </Typography>
                            )}
                            {!itemHasInfo && productNutrition.isSet && (
                                <Typography variant="body2" className={classes.calories}>
                                    {`${getCaloriesString(productNutrition.value)} ${t(
                                        'ORDER_CALORIES_PER_UNIT'
                                    )}`}
                                </Typography>
                            )}
                        </Box>

                        {suggestedProductImage && (
                            <>
                                <Box
                                    className={clsx(classes.imageProductWrapper, {
                                        [classes.imageHidden]: !imageProductLoaded
                                    })}
                                >
                                    <img
                                        className={classes.image}
                                        onLoad={handleImageProductLoaded}
                                        src={suggestedProductImage}
                                        alt={suggestedProductTitle}
                                    />
                                </Box>
                                {!imageProductLoaded && (
                                    <Skeleton className={classes.imageProductWrapper} variant="rect" />
                                )}
                            </>
                        )}
                    </Box>

                    {itemHasInfo ? (
                        <Button color="primary" variant="contained" onClick={handleShowMoreinfo} fullWidth>
                            {t('RELATED_PRODUCT_ADD_VIEW_DETAILS')}
                        </Button>
                    ) : (
                        <>
                            <Box
                                className={classes.quantityBox}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <QuantitySelector
                                    min={1}
                                    max={999}
                                    quantity={quantity}
                                    onChange={setQuantity}
                                />
                            </Box>
                            <Button
                                className={classes.button}
                                color="primary"
                                variant="contained"
                                onClick={handleAddItemToBasket}
                                fullWidth
                            >
                                {t('PREORDER_ADD_TO_BASKET', {
                                    price: getCurrencyString(suggestedProduct.price * quantity)
                                })}
                            </Button>
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <Typography variant="caption" align="center">
                                    {t('ORDER_CALORIES_ADVISORY_MESSAGE')}
                                </Typography>
                            </Box>
                        </>
                    )}
                </Box>
            </BottomDialog>
            <MenuProductInfoTimePaddingDialog
                open={!!prepTimeModalInfo}
                info={prepTimeModalInfo}
                onCancel={handleCancelPrepTimeInfo}
            />
        </>
    );
};
