import * as React from 'react';
import { useSelector } from 'react-redux';
import { PaymentRequest } from '@stripe/stripe-js';
import { OrderPaymentType } from 'components/order/model/Order';
import { QuickPayButton } from 'components/pay/ui/PayButton';
import { EPaymentProvider } from 'components/settings/model/Settings';
import { ApplicationState } from 'store/store';

export interface DigitalWalletButtonProps {
    walletType: OrderPaymentType.APPLEPAY | OrderPaymentType.GOOGLEPAY;
    id: string;
    disabled?: boolean;
    hidden?: boolean;
    onClick?: (walletType: DigitalWalletButtonProps['walletType']) => void;
    stripePaymentRequest?: PaymentRequest | null;
    children?: React.ReactNode;
}

export const DigitalWalletButtonV2: React.FC<DigitalWalletButtonProps> = ({
    walletType,
    disabled,
    id,
    onClick,
    hidden,
    stripePaymentRequest,
    children
}) => {
    const { settings } = useSelector((state: ApplicationState) => state.settings);

    const isStripe = React.useMemo(
        () =>
            !!settings &&
            settings.paymentProvider === EPaymentProvider.STRIPE &&
            !!settings.stripe.publishableKey,
        [settings]
    );

    const handleClick = React.useCallback(() => {
        if (isStripe && stripePaymentRequest) {
            return stripePaymentRequest.show();
        }
        if (onClick) {
            onClick(walletType);
        }
    }, [isStripe, onClick, stripePaymentRequest, walletType]);

    return (
        <QuickPayButton id={id} fullWidth disabled={disabled || hidden} onClick={handleClick}>
            {children}
        </QuickPayButton>
    );
};
