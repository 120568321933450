import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { AxiosResponse } from 'axios';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MerchantTitle } from 'app/MerchantTitle';
import { userApi } from 'components/user/userApi';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { PasswordFormField } from 'lib/form/PasswordFormField';
import { TextFormField } from 'lib/form/TextFormField';
import { useLocalHistory } from 'lib/useLocalHistory';
import { ROUTES } from 'pages/routes';
import { signIn } from 'store/auth/authActions';
import { resetAwards } from 'store/basket/basketActions';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/LoadingButton';
import { useSnackbar } from 'notistack';

interface ITwoFactorAuthenticationData {
    token: string;
    password: string;
}

const initialValues: ITwoFactorAuthenticationData = {
    token: '',
    password: ''
};

interface ITwoFactorAuthHistoryState {
    credentials?: string;
    id?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            paddingTop: theme.spacing(4)
        },
        button: {
            height: theme.spacing(6),
            fontSize: '1.2em'
        },
        forgot: {
            margin: theme.spacing(0, 1.75),
            marginTop: theme.spacing(1),
            display: 'block'
        },
        message: {
            whiteSpace: 'pre-wrap'
        }
    })
);

export const TwoFactorAuthenticationPage: React.FC = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { push } = useLocalHistory();
    const dispatch = useDispatch();
    const { state } = useLocation<ITwoFactorAuthHistoryState>();
    const { enqueueSnackbar } = useSnackbar();
    const { isLoading: isAuthLoading, returnUrl } = useSelector(
        (appState: ApplicationState) => appState.auth
    );

    const twoFactorAuthenticationValidation = React.useMemo(
        () =>
            Yup.object<ITwoFactorAuthenticationData>().shape({
                token: Yup.string().required(t('FORMS_VALIDATION_REQUIRED')),
                password: Yup.string().required(t('FORMS_VALIDATION_REQUIRED'))
            }),
        [t]
    );

    const handleBack = React.useCallback(() => {
        push(ROUTES.USER.LOGIN);
    }, [push]);

    const handleSubmit = React.useCallback(
        ({ token, password }: ITwoFactorAuthenticationData) => {
            if (!!state.id) {
                const credentials = btoa(`${state.id}:${password}`);

                return signIn(
                    credentials,
                    token
                )(dispatch)
                    .then(res => {
                        resetAwards(dispatch);
                        if (returnUrl) {
                            const [path, search] = returnUrl.split('?');
                            push(path, {}, search);
                        } else {
                            push(ROUTES.BASE);
                        }

                        return res;
                    })
                    .catch((error: AxiosResponse<{ message: string; code: string }>) => {
                        let finalErrorMessage;
                        switch (error.status) {
                            case 401:
                                if (error.data.code === 'InvalidCredentials') {
                                    finalErrorMessage =
                                        "Sorry! We couldn't find you with that email and password combo.";
                                } else {
                                    finalErrorMessage = t('DIALOG_RESET_UNAUTHORISED_MESSAGE');
                                }

                                break;
                            default:
                                finalErrorMessage = t('GENERAL_ERROR_PROCESSING_REQUEST');
                                break;
                        }
                        if (error.data.code === 'E-WAF-0001') {
                            finalErrorMessage = error.data.message;
                        }

                        enqueueSnackbar(finalErrorMessage, { variant: 'error' });
                    });
            }
        },
        [dispatch, enqueueSnackbar, push, returnUrl, state.id, t]
    );

    React.useEffect(() => {
        if (!!state && !!state.id) {
            userApi.requestFor2FA(state.id).catch(error => {
                enqueueSnackbar(error?.data?.message || t('GENERAL_ERROR_PROCESSING_REQUEST'), {
                    variant: 'error'
                });
            });
        } else {
            push(ROUTES.USER.LOGIN);
        }
    }, [dispatch, enqueueSnackbar, push, state, t]);

    const handleKeyPress = React.useCallback(
        (submit: () => Promise<void>) => (e: React.KeyboardEvent) => {
            if (e.keyCode === 13) {
                submit();
            }
        },
        []
    );

    return (
        <>
            <MerchantTitle title={t('ONBOARDING_SIGNIN_VERIFY_TITLE')} />
            <MuiAppBar
                title={t('ONBOARDING_SIGNIN_VERIFY_TITLE')}
                onBack={handleBack}
                disabledMenu
                icon={Close}
            />
            <Formik
                initialValues={initialValues}
                validationSchema={twoFactorAuthenticationValidation}
                onSubmit={handleSubmit}
            >
                {({ submitForm, isValid, values: { token, password }, isSubmitting }) => (
                    <Form className={classes.root}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography className={classes.message}>
                                    {t('ONBOARDING_SIGNIN_VERIFY_MESSAGE', {
                                        credential: state.id
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextFormField name="token" label={t('ONBOARDING_SIGNIN_VERIFY_CODE')} />
                            </Grid>
                            <Grid item xs={12}>
                                <PasswordFormField
                                    name="password"
                                    label={t('ONBOARDING_SIGNIN_PASSWORD')}
                                    onKeyDown={handleKeyPress(submitForm)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    disabled={!isValid || isSubmitting || !token || !password}
                                    fullWidth
                                    onClick={submitForm}
                                    className={classes.button}
                                    loading={isSubmitting || isAuthLoading}
                                >
                                    {t('AUTH_FORM_LOGIN')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};
