import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { CONTENT_MAX_WIDTH } from 'config/constants';
import { useWindowSize } from 'lib/useWindowSize';
import {
    IDynamicContainer,
    IDynamicImage,
    IDynamicLayerContainer,
    IEnrichedProduct,
    isBoxImagery,
    isLayerImagery
} from './model/Menu';

export const MAX_DYNAMIC_IMAGE_WIDTH = 600;
export const DYNAMIC_IMAGE_ASPECT_RATIO = 0.5625;

interface MenuDynamicImageProps {
    product: IEnrichedProduct;
    modifierProducts: (string[] | string)[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            position: 'fixed',
            top: theme.spacing(6),
            left: '50%',
            right: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[1],
            maxWidth: `${CONTENT_MAX_WIDTH}px`,
            transform: 'translateX(-50%)'
        },
        image: ({ imageUrl }: { imageUrl: string }) => ({
            maxHeight: MAX_DYNAMIC_IMAGE_WIDTH * DYNAMIC_IMAGE_ASPECT_RATIO,
            maxWidth: MAX_DYNAMIC_IMAGE_WIDTH,
            backgroundImage: `url(${imageUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '100% 100%',
            position: 'relative'
        }),
        container: {
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column'
        },
        containerHorizontal: {
            flexDirection: 'row'
        }
    })
);

function getBoxContainerStyle(container: IDynamicContainer, width: number, height: number) {
    return {
        left: (container.x || 0) * width,
        top: (container.y || 0) * height,
        height: (container.height || 1) * height,
        width: (container.width || 1) * width
    };
}

function getLayerContainerStyle(container: IDynamicLayerContainer, width: number, height: number) {
    return {
        left: (container.x || 0) * width,
        top: (container.y || 0) * height,
        height: (container.height || 1) * height,
        width: (container.width || 1) * width
    };
}

function getLayerImageStyle(container: IDynamicLayerContainer, width: number, height: number): CSSProperties {
    return {
        left: (container.x || 0) * width,
        top: (container.y || 0) * height,
        height: (container.height || 1) * height,
        width: (container.width || 1) * width,
        position: 'absolute'
    };
}

export const MenuDynamicImage: React.FC<MenuDynamicImageProps> = ({ product, modifierProducts }) => {
    const productsArray = React.useMemo(
        () =>
            modifierProducts
                .reduce<string[]>((acc, item) => {
                    if (Array.isArray(item)) {
                        return [...acc, ...item];
                    }
                    return [...acc, item];
                }, [])
                .filter(item => !!item),
        [modifierProducts]
    );
    const classes = useStyles({ imageUrl: product.dynamicImagery?.baseImageUrl || '' });
    const windowSize = useWindowSize();
    const imageWidth = React.useMemo(
        () =>
            !windowSize.width || windowSize.width > MAX_DYNAMIC_IMAGE_WIDTH
                ? MAX_DYNAMIC_IMAGE_WIDTH
                : windowSize.width,
        [windowSize.width]
    );
    const imageHeight = React.useMemo(() => imageWidth * DYNAMIC_IMAGE_ASPECT_RATIO, [imageWidth]);
    const imagery = React.useMemo(() => product.dynamicImagery, [product.dynamicImagery]);
    const productToDynamicImageMap = React.useMemo(() => {
        if (!imagery || !imagery.images) {
            return {};
        }
        return imagery.images;
    }, [imagery]);

    const renderBoxImagery = React.useCallback(
        (_products: string[], skipCheck = false) => {
            if (!imagery || (!skipCheck && !isBoxImagery(imagery))) {
                return null;
            }
            const imageContainers: IDynamicImage[][] = imagery.containers.map(_ => []);
            _products.forEach(item => {
                const containerIndex = imagery.containers.findIndex(
                    (container, index) =>
                        container.count > imageContainers[index].length &&
                        container.suitableFor.includes(item)
                );
                if (containerIndex >= 0) {
                    const dynamicImage = productToDynamicImageMap[item];
                    if (dynamicImage) {
                        imageContainers[containerIndex].push(dynamicImage);
                    }
                }
            });
            return imagery.containers.map((item, containerIndex) => {
                if (!imageContainers[containerIndex].length) {
                    return (
                        <div
                            className={clsx(classes.container, {
                                [classes.containerHorizontal]: item.division === 'HORIZONTAL'
                            })}
                            style={getBoxContainerStyle(item, imageWidth, imageHeight)}
                            key={`container-${containerIndex}`}
                        />
                    );
                }
                const resultImages = imageContainers[containerIndex]
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ imageUrl }) => imageUrl);
                return (
                    <div
                        className={clsx(classes.container, {
                            [classes.containerHorizontal]: item.division === 'HORIZONTAL'
                        })}
                        style={getBoxContainerStyle(item, imageWidth, imageHeight)}
                        key={`container-${containerIndex}`}
                    >
                        {resultImages.map((image, index) => (
                            <img
                                style={
                                    item.division === 'HORIZONTAL'
                                        ? {
                                              height: '100%',
                                              width: (imageWidth * (item.width || 1)) / item.count
                                          }
                                        : {
                                              width: '100%',
                                              height: (imageHeight * (item.height || 1)) / item.count
                                          }
                                }
                                src={image}
                                key={`image-${index}`}
                            />
                        ))}
                    </div>
                );
            });
        },
        [imagery, classes, imageWidth, imageHeight, productToDynamicImageMap]
    );

    const renderLayerImagery = React.useCallback(
        (_products: string[], skipCheck = false) => {
            if (!imagery || (!skipCheck && !isLayerImagery(imagery))) {
                return null;
            }
            const imageContainers: IDynamicImage[][] = imagery.containers.map(_ => []);
            _products.forEach(item => {
                const containerIndex = imagery.containers.findIndex(
                    (container, index) =>
                        container.count > imageContainers[index].length &&
                        container.suitableFor.includes(item)
                );
                if (containerIndex >= 0) {
                    const dynamicImage = productToDynamicImageMap[item];
                    if (dynamicImage) {
                        imageContainers[containerIndex].push(dynamicImage);
                    }
                }
            });
            return imagery.containers.map((item, containerIndex) => {
                if (!imageContainers[containerIndex].length) {
                    return (
                        <div
                            className={classes.container}
                            style={getLayerContainerStyle(item, imageWidth, imageHeight)}
                            key={`container-${containerIndex}`}
                        />
                    );
                }
                const resultImages = imageContainers[containerIndex]
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ imageUrl }) => imageUrl);

                return (
                    <div
                        className={classes.container}
                        style={getLayerContainerStyle(item, imageWidth, imageHeight)}
                        key={`container-${containerIndex}`}
                    >
                        {resultImages.map((image, index) => (
                            <img
                                style={getLayerImageStyle(item, imageWidth, imageHeight)}
                                src={image}
                                key={`image-${index}`}
                            />
                        ))}
                    </div>
                );
            });
        },
        [imagery, classes, imageHeight, imageWidth, productToDynamicImageMap]
    );

    // this is the new model of imagery
    const renderImageryWithoutType = React.useCallback(
        (_products: string[]) => {
            if (!imagery || !imagery.containers) {
                return null;
            }
            const containersSuitableForProducts = imagery.containers.filter(container =>
                _products.some(p => container.suitableFor.includes(p))
            );
            const layeredContainers = containersSuitableForProducts.filter(c => c.division === 'LAYERED');
            const layeredProducts = _products.filter(_product =>
                layeredContainers.some(container => container.suitableFor.includes(_product))
            );
            const boxImageryProducts = _products.filter(_product => !layeredProducts.includes(_product));

            const imageriesToRender = [];

            if (layeredContainers.length > 0 && layeredProducts.length > 0) {
                imageriesToRender.push(...(renderLayerImagery(layeredProducts, true) || []));
            }

            if (boxImageryProducts.length > 0) {
                imageriesToRender.push(...(renderBoxImagery(boxImageryProducts, true) || []));
            }

            return imageriesToRender;
        },
        [imagery, renderBoxImagery, renderLayerImagery]
    );

    return (
        <div className={classes.wrapper} style={{ height: imageHeight }}>
            <div className={classes.image} style={{ height: imageHeight, width: imageWidth }}>
                {imagery?.type ? (
                    <>
                        {renderBoxImagery(productsArray)}
                        {renderLayerImagery(productsArray)}
                    </>
                ) : (
                    renderImageryWithoutType(productsArray)
                )}
            </div>
        </div>
    );
};
