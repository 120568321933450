import * as React from 'react';
import { Box } from '@material-ui/core';

interface IProps {
    validateForm: () => void;
    // FIXME: why do we have any?
    initialValues: any;
}

export const FormikModifierWrapper: React.FC<React.PropsWithChildren<IProps>> = ({
    children,
    initialValues,
    validateForm
}) => {
    React.useEffect(() => {
        (() => validateForm())();
        // TODO: should we really have initialValues in the deps array? Looks like something gone wrong
    }, [initialValues, validateForm]);

    return (
        <Box height="0" display="flex" flex={1}>
            {children}
        </Box>
    );
};

FormikModifierWrapper.displayName = 'FormikModifierWrapper';
