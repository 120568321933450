import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { OrderScenario } from 'components/order/model/Order';
import { isDefined, isString } from 'lib/typeInference';
import { LocationRouteParams } from 'pages/routes';
import { getCurrentLocation } from 'store/locations/locationActions';
import { ApplicationState } from 'store/store';
import { IOpeningHours, Location } from '../model/Location';

interface ILocationHelper {
    isWorkingHour: boolean;
    isLocationOpen: boolean;
    isLocationOrderEnabled: boolean;
    currentLocation: Location | undefined;
    checkServiceAvailability: (scenario: OrderScenario | OrderScenario[]) => boolean;
}

export const getWorkingHoursDetails = (openingHours: IOpeningHours, applyAfterMidnight = false) => {
    const getWeekDayOpeningHours = (weekDay: string) => openingHours[weekDay as keyof IOpeningHours];
    const workingHoursOfToday = applyAfterMidnight
        ? getWeekDayOpeningHours(moment().subtract(1, 'day').format('dddd').toLowerCase())
        : getWeekDayOpeningHours(moment().format('dddd').toLowerCase());
    const workingHoursOfTodayArray = workingHoursOfToday.split('-');
    const todayFrom = workingHoursOfTodayArray[0] ? workingHoursOfTodayArray[0].trim() : '';
    const todayTo = workingHoursOfTodayArray[1] ? workingHoursOfTodayArray[1].trim() : '';
    // Work out closing time, could be next day if closing time is earlier than opening time
    let actualClosingTime = moment(todayTo, 'LT').isSameOrBefore(moment(todayFrom, 'LT'))
        ? moment(todayTo, 'LT').add(1, 'day')
        : moment(todayTo, 'LT');

    // Work out opening time, will always be today, even if this location opens after it closes.
    // This is because the closing time will be treated as "Tomorrow" if it's before the opening time
    // on any given day
    let actualOpeningTime = moment(todayFrom, 'LT');

    if (applyAfterMidnight) {
        actualClosingTime = actualClosingTime.subtract(1, 'day');
        actualOpeningTime = actualOpeningTime.subtract(1, 'day');
    }

    return {
        workingHoursOfToday,
        workingHoursOfTodayArray,
        todayFrom,
        todayTo,
        actualClosingTime,
        actualOpeningTime
    };
};

export const getWorkingHoursParams = (openingHours: IOpeningHours) => {
    const workingHoursDetails = getWorkingHoursDetails(openingHours);
    const midnight = moment('00:00am', 'LT');
    if (
        workingHoursDetails.actualOpeningTime.isAfter(midnight) &&
        moment().isAfter(midnight) &&
        moment().isBefore(workingHoursDetails.actualOpeningTime)
    ) {
        return getWorkingHoursDetails(openingHours, true);
    }
    return workingHoursDetails;
};

export function isLocationOpen(openingHours?: IOpeningHours): boolean {
    if (openingHours) {
        const {
            workingHoursOfToday,
            workingHoursOfTodayArray,
            todayFrom,
            todayTo,
            actualClosingTime,
            actualOpeningTime
        } = getWorkingHoursParams(openingHours);
        if (workingHoursOfToday.includes('closed')) {
            return false;
        }
        if (!workingHoursOfToday.trim()) {
            return true;
        }
        if (!workingHoursOfTodayArray[0] || !workingHoursOfTodayArray[1]) {
            return false;
        }
        // Location is closed today, would be 'closed-closed' but handle any case where it's not
        if (todayFrom.toLowerCase() === 'closed' || todayTo.toLowerCase() === 'closed') {
            return false;
        }

        const now = moment();

        return now.isBefore(actualClosingTime) && now.isSameOrAfter(actualOpeningTime);
    }

    return true;
}

export function isLocationOpenToday(openingHours?: IOpeningHours) {
    if (!isDefined(openingHours)) {
        return true;
    }
    const { workingHoursOfToday, workingHoursOfTodayArray, todayFrom, todayTo } =
        getWorkingHoursParams(openingHours);
    if (workingHoursOfToday.includes('closed')) {
        return false;
    }
    if (!workingHoursOfToday.trim()) {
        return true;
    }
    if (!workingHoursOfTodayArray[0] || !workingHoursOfTodayArray[1]) {
        return false;
    }
    // Location is closed today, would be 'closed-closed' but handle any case where it's not
    if (todayFrom.toLowerCase() === 'closed' || todayTo.toLowerCase() === 'closed') {
        return false;
    }
    return true;
}

export function useLocationHelpers(): ILocationHelper {
    const location = useSelector((state: ApplicationState) => state.locations.currentLocation);
    const settings = useSelector((state: ApplicationState) => state.settings.settings);
    const { timeSlot } = useSelector((state: ApplicationState) => state.basket);
    const checkServiceAvailability = React.useCallback(
        (scenario: OrderScenario | OrderScenario[]) => {
            if (location && location.services) {
                const serviceChecker = (orderScenario: OrderScenario) => {
                    switch (orderScenario) {
                        case OrderScenario.ORDER_TO_TABLE:
                            return !!location.services.order_to_table;
                        case OrderScenario.PREORDER:
                            return !!location.services.pre_order;
                        case OrderScenario.TABLE:
                            return !!location.services.pay_at_table;
                        default:
                            return false;
                    }
                };

                if (Array.isArray(scenario)) {
                    return scenario.some(sc => serviceChecker(sc) === true);
                }

                return serviceChecker(scenario);
            }

            return true;
        },
        [location]
    );
    const _isLocationOpen = React.useMemo(
        () => isLocationOpen(location?.openingHours),
        [location?.openingHours]
    );
    const isWorkingHour = React.useMemo(() => _isLocationOpen || !!timeSlot, [_isLocationOpen, timeSlot]);
    const isLocationOrderEnabled = React.useMemo(
        () => (settings?.orderingEnabled !== undefined ? settings && settings.orderingEnabled : true),
        [settings]
    );

    return {
        isWorkingHour,
        isLocationOpen: _isLocationOpen,
        isLocationOrderEnabled,
        currentLocation: location,
        checkServiceAvailability
    };
}

// TODO: remove if we no longer need it
export function usePepperLocation(): [Location | undefined, boolean] {
    const { locationId } = useParams<LocationRouteParams>();
    const dispatch = useDispatch();
    const { currentLocation, isCurrentLocationLoading } = useSelector(
        (state: ApplicationState) => state.locations
    );
    React.useEffect(() => {
        if (isString(locationId) && (!currentLocation || currentLocation?._id !== locationId)) {
            getCurrentLocation(locationId)(dispatch);
        }
    }, [currentLocation, currentLocation?._id, dispatch, locationId]);
    return [currentLocation, isCurrentLocationLoading];
}
