import { AxiosResponse } from 'axios';
import { getLocalStaticId } from 'src/utils/local-info';
import { IActionData } from 'components/activity/models';
import { IGuestCardPaymentFormValues } from 'components/basket/GuestCardPaymentForm';
import { HttpClient } from 'components/http/HttpClient';
import { getLocalSettings } from 'components/settings/localStore';
import { IStripeSetupResponse } from '../../integrations/PaymentProvider/Stripe/api/Stripe';
import {
    CredentialProvider,
    ICreateCardData,
    ICreateUserCredentialRequest,
    ICreateUserCredentialResponse,
    isUserData,
    IUpdateUserRequest,
    SignUpFormData,
    User,
    UserCard,
    UserData,
    UserDataSSO
} from './model/User';

export interface ISSOLoginRequestData extends Partial<IUpdateUserRequest> {
    idToken: string;
    hasAgreedToShareData?: boolean;
}

class UserApi extends HttpClient {
    constructor() {
        super({
            baseURL: process.env.API_SERVER_URL,
            url: '/users'
        });
    }

    public async loginUser(credentials: string): Promise<UserData> {
        const response = await this.postRequest<undefined, UserData>({
            url: '/users/login',
            headers: {
                Authorization: `Basic ${credentials}`,
                'x-pepper-static-id': getLocalStaticId()
            },
            headerConfigs: {
                shouldIncludeAuthToken: false
            }
        });

        if (!isUserData(response)) {
            throw new Error('Unable to login user');
        }
        return response;
    }
    public async loginGoogleSSOUser(idToken: string): Promise<UserDataSSO> {
        const response = await this.postRequest<{ idToken: string }, UserDataSSO>({
            url: '/users/google/sso',
            data: {
                idToken
            },
            headers: {
                'x-pepper-static-id': getLocalStaticId()
            },
            headerConfigs: {
                shouldIncludeAuthToken: false
            },
            configs: {
                transformResponse: [
                    (data, _headers, status) => {
                        const parsedData = JSON.parse(data);
                        parsedData.status = status;
                        return parsedData;
                    }
                ]
            }
        });

        if (!isUserData(response)) {
            throw new Error('Unable to login user');
        }
        return response;
    }

    public async loginAppleSSOUser(data: ISSOLoginRequestData): Promise<UserDataSSO> {
        const response = await this.postRequest<{ idToken: string }, UserDataSSO>({
            url: '/users/apple/sso',
            data,
            headers: {
                'x-pepper-static-id': getLocalStaticId()
            },
            headerConfigs: {
                shouldIncludeAuthToken: false
            },
            configs: {
                transformResponse: [
                    (data, _headers, status) => {
                        const parsedData = JSON.parse(data);
                        parsedData.status = status;
                        return parsedData;
                    }
                ]
            }
        });

        if (!isUserData(response)) {
            throw new Error('Unable to login user');
        }
        return response;
    }

    public async loginWithToken(credentials: string, token: string): Promise<UserData> {
        const response = await this.postRequest<{ token: string }, UserData>({
            url: '/users/login',
            headers: {
                Authorization: `Basic ${credentials}`,
                'x-pepper-static-id': getLocalStaticId()
            },
            headerConfigs: {
                shouldIncludeAuthToken: false
            },
            data: {
                token
            }
        });

        if (!isUserData(response)) {
            throw new Error('Unable to login user');
        }
        return response;
    }

    public async requestFor2FA(id: string) {
        return this.postRequest({
            url: '/users/login',
            headers: {
                'x-pepper-static-id': getLocalStaticId()
            },
            data: {
                id
            }
        });
    }

    public logoutUser(id: string) {
        return this.getRequest({ url: `/${id}/logout` });
    }

    public async updateUser(userId: string, updatedFields: Partial<IUpdateUserRequest>) {
        return this.putRequest<Partial<IUpdateUserRequest>, User>({
            url: `/users/${userId}`,
            data: updatedFields,
            headers: {
                'x-api-version': 5
            }
        });
    }

    public async getUserDetails(userId: string, noCache?: boolean) {
        const headers: Record<string, string> = {};
        let url = `/${userId}`;
        if (noCache) {
            headers['cache-control'] = 'no-cache, no-store, must-revalidate';
            headers['Vary'] = '*';
            url = `/${userId}?time=${new Date().getTime()}`;
        }
        return this.getRequest<User>({
            url,
            headers
        });
    }

    public createUser(user: SignUpFormData): Promise<User> {
        // eslint-disable-next-line
        const rEmail = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i);
        const provider = rEmail.test(user.identity) ? CredentialProvider.EMAIL : CredentialProvider.MOBILE;
        const data = {
            firstName: user.firstName,
            lastName: user.lastName,
            roles: ['USER'],
            hasAgreedToReceiveMarketing: user.hasAgreedToReceiveMarketing,
            hasAgreedToShareData: user.hasAgreedToShareData,
            credentials: [
                {
                    provider,
                    id: user.identity,
                    token: user.password
                }
            ],
            birthdate: user.birthdate
        };
        return this.postRequest<typeof data, User>({
            url: '/users',
            data,
            headers: {
                'x-api-version': 5,
                'x-pepper-static-id': getLocalStaticId()
            },
            headerConfigs: { shouldIncludeAuthToken: true }
        });
    }

    public activateUser(id: string, token?: string) {
        const localSettings = getLocalSettings();
        if (!localSettings) {
            throw new Error('merchantId and x-application-id are missed');
        }
        const data = {
            id,
            token
        };

        return this.postRequest<{ id: string; token?: string }, UserData>({
            url: '/users/activate',
            data,
            headers: { 'x-api-version': 5, 'x-pepper-static-id': getLocalStaticId() },
            headerConfigs: { shouldIncludeAuthToken: false }
        });
    }

    public async updateGuestCredentials(
        userId: string,
        { firstName, lastName, identity }: IGuestCardPaymentFormValues
    ): Promise<[boolean, string, User?]> {
        try {
            // eslint-disable-next-line
            const rEmail = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i);
            const provider = rEmail.test(identity) ? CredentialProvider.EMAIL : CredentialProvider.MOBILE;
            const response = await this.putRequest<any, User>({
                url: `users/${userId}`,
                data: {
                    firstName,
                    lastName,
                    credentials: [{ provider, id: identity }]
                },
                headers: {
                    'x-api-version': 4
                }
            });

            return [true, '', response];
        } catch (error: any) {
            if (error.status === 400 && error.data.code === 'E-PLA-1021') {
                return [false, error.data.message];
            }
        }
        throw new Error('Could not update guest credentials');
    }

    public async getCards(userId: string): Promise<{ cards: UserCard[] }> {
        return this.getRequest<{ cards: UserCard[] }>({
            // Timestamp is just to prevent browser cache
            url: `/${userId}/cards?timestamp=${new Date().getTime()}`,
            headers: {
                'x-api-version': 5
            }
        });
    }

    public async removeCard(cardId: string) {
        return this.deleteRequest({
            url: `/cards/${cardId}`,
            headerConfigs: {
                shouldIncludeAuthToken: true
            }
        });
    }

    public getCardSetupData(): Promise<IStripeSetupResponse> {
        return this.postRequest({
            url: '/cards/setup'
        });
    }

    public createCard(data: ICreateCardData): Promise<AxiosResponse> {
        return this.postRequest({
            url: '/cards',
            data,
            headers: {
                'x-api-version': 5
            }
        });
    }

    public resetPassword(id: string) {
        // We should create new instance as we don't want to have Authorization header here
        return this.postRequest({
            url: '/users/reset',
            data: {
                id
            },
            headers: {
                'x-pepper-static-id': getLocalStaticId()
            },
            headerConfigs: {
                shouldIncludeAuthToken: false
            }
        });
    }

    public createNewPassword(id: string, resettoken: string, password: string) {
        const data = {
            resettoken,
            password,
            id
        };
        return this.postRequest<any, UserData>({
            url: '/users/reset',
            data,
            headers: {
                'x-pepper-static-id': getLocalStaticId()
            },
            headerConfigs: {
                shouldIncludeAuthToken: false
            }
        });
    }

    public async getGuestSession(): Promise<UserData> {
        const localSettings = getLocalSettings();
        if (!localSettings) {
            throw new Error('x-application-id is missing');
        }
        try {
            const data = await this.postRequest<null, UserData>({
                url: '/guests',
                headers: { 'x-api-version': 1 },
                headerConfigs: { shouldIncludeAuthToken: false }
            });

            if (!isUserData(data)) {
                throw new Error('Failed to create Guest session');
            }

            return data;
        } catch (err) {
            throw new Error('Failed to create Guest session');
        }
    }

    public getActivityHistory(userId: string, pagination: Record<string, string> = {}): Promise<IActionData> {
        return this.getRequest<IActionData>({
            url: `/${userId}/actions`,
            headers: {
                'x-api-version': 9
            },
            configs: {
                params: {
                    limit: 60,
                    'type.in':
                        // eslint-disable-next-line max-len
                        'USER_CREATED,USER_ACTIVATED,REFERAL_CLAIMED_REFEREE,REFERAL_CLAIMED_REFERER,ORDER_COMPLETED,ORDER_USER_BILLED,REWARD_EARNED,REDEEM_PERK,ORDER_PAYMENT_REFUNDED,CARD_CREATED,CARD_DELETED,VOUCHER_REDEEMED',
                    ...pagination
                }
            }
        });
    }

    public createCredential(
        id: string,
        provider: CredentialProvider,
        skipVerification = false
    ): Promise<ICreateUserCredentialResponse> {
        return this.postRequest<ICreateUserCredentialRequest, ICreateUserCredentialResponse>({
            url: '/credentials',
            headers: {
                'x-api-version': 8
            },
            data: {
                id,
                provider,
                skipVerification
            }
        });
    }

    public getAppleWalletPass(userId: string) {
        return this.postRequest<undefined, any>({
            url: `users/${userId}/wallets/apple`,
            headers: {
                'x-api-version': '9',
                'Cache-Control': 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0'
            },
            configs: {
                responseType: 'blob'
            }
        });
    }

    public getGoogleWalletPass(userId: string) {
        return this.postRequest<undefined, { token: string }>({
            url: `users/${userId}/wallets/google`,
            headers: {
                'x-api-version': '9',
                'Cache-Control': 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0'
            }
        });
    }
}

export const userApi = new UserApi();
