import React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { isNumber } from './typeInference';

export interface LoadingImageProps {
    height?: number | string;
    width?: number | string;
    src: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        image: (props: LoadingImageProps) => {
            const width = isNumber(props.width) ? theme.spacing(props.width) : props.width ?? '100%';
            const height = isNumber(props.height) ? theme.spacing(props.height) : props.height ?? '100%';
            return {
                width,
                height
            };
        },
        imageHidden: {
            visibility: 'hidden',
            width: 0,
            height: 0,
            padding: 0,
            margin: 0,
            position: 'absolute'
        },
        croppedImage: (props: LoadingImageProps) => ({
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url(${props.src})`
        })
    })
);

export const LoadingImage: React.FC<LoadingImageProps> = props => {
    const classes = useStyles(props);
    const [imageLoaded, setImageLoaded] = React.useState(false);
    const handleImageLoaded = React.useCallback(() => {
        setImageLoaded(true);
    }, []);
    return (
        <>
            <Box
                className={clsx({
                    [classes.image]: imageLoaded,
                    [classes.imageHidden]: !imageLoaded
                })}
                display="flex"
                justifyContent="center"
            >
                <img
                    className={clsx(classes.image, classes.imageHidden)}
                    onLoad={handleImageLoaded}
                    src={props.src || ''}
                />
                <div className={clsx(classes.image, classes.croppedImage)} />
            </Box>
            {!imageLoaded && <Skeleton className={classes.image} variant="rect" />}
        </>
    );
};
