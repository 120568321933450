import { Location, LocationsSummary } from 'components/location/model/Location';
import { LocationAction } from './locationActions';
import { LocationActionTypes } from './locationActionTypes';

export interface LocationsState {
    locations: Location[];
    summary: LocationsSummary;
    error: string;
    isLoading: boolean;
    isNextPageLoading?: boolean;
    lat?: number;
    lng?: number;
    currentLocation?: Location;
    isCurrentLocationLoading: boolean;
}

export const initialState: LocationsState = {
    locations: [],
    error: '',
    summary: null,
    isLoading: true,
    isNextPageLoading: false,
    currentLocation: undefined,
    isCurrentLocationLoading: true
};

export default function (state: LocationsState = initialState, action: LocationAction): LocationsState {
    switch (action.type) {
        case LocationActionTypes.END_REQUEST:
            return { ...state, isLoading: false };
        case LocationActionTypes.START_REQUEST:
            return { ...state, isLoading: true };
        case LocationActionTypes.GET_COORDINATES_SUCCESS:
            return { ...state, lat: action.lat, lng: action.lng };
        case LocationActionTypes.START_NEXT_PAGE_LOADING:
            return { ...state, isNextPageLoading: true };
        case LocationActionTypes.LOAD_LOCATIONS_SUCCESS:
            return {
                ...state,
                isNextPageLoading: false,
                summary: action.summary,
                error: '',
                locations: [...state.locations, ...action.locations]
            };
        case LocationActionTypes.GET_LOCATIONS_ERROR:
            return { ...state, locations: [], summary: null, error: action.error };
        case LocationActionTypes.GET_LOCATIONS_SUCCESS:
            return { ...state, error: '', locations: action.locations, summary: action.summary };
        case LocationActionTypes.GET_CURRENT_LOCATION_ERROR:
            return {
                ...state,
                isCurrentLocationLoading: false,
                currentLocation: undefined,
                error: action.error
            };
        case LocationActionTypes.GET_CURRENT_LOCATION_SUCCESS:
            return {
                ...state,
                isCurrentLocationLoading: false,
                currentLocation: action.currentLocation,
                error: ''
            };
        case LocationActionTypes.RESET_CURRENT_LOCATION:
            return {
                ...state,
                isCurrentLocationLoading: true,
                currentLocation: undefined,
                error: ''
            };
        default:
            return state;
    }
}
