import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { makeRouteScenario } from 'src/utils/route';
import { ViewBillHeader } from 'components/bill/ui/ViewBill/ViewBillHeader';
import { isOrderAvailableToGuest } from 'components/order/localGuestOrders';
import { IOrderReadResourceV12, OrderScenario } from 'components/order/model/Order';
import { orderApi } from 'components/order/orderApi';
import { OrderSessionExpired } from 'components/order/OrderSessionExpired';
import { OrderSummary } from 'components/order/OrderSummary';
import { getLocalMerchantId } from 'components/settings/localStore';
import { useTimeSlotHelpers } from 'components/timeslots/useTimeSlotHelpers';
import { EmptyState } from 'lib/EmptyState';
import { Throbber } from 'lib/Throbber';
import { isDefined } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { ApplicationState } from 'store/store';
import { ROUTES } from './routes';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageContainer: {
            display: 'flex',
            height: '100%',
            paddingBottom: theme.spacing(7),
            overflowY: 'hidden',
            flexDirection: 'column'
        },
        pageCenter: {
            alignItems: 'center'
        },
        basketError: {
            fontSize: theme.spacing(12),
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(-6)
        }
    })
);

enum ErrorType {
    GENERIC,
    NOT_FOUND,
    NONE
}

export const OrderSummaryPage: React.FC = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(ErrorType.NONE);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [order, setOrder] = React.useState<null | IOrderReadResourceV12>(null);
    const [isOrderAvailable, setIsOrderAvailable] = React.useState(true);
    const { asapEnabled } = useTimeSlotHelpers();
    const { locationId, orderId } = useParams<{ locationId: string; orderId: string }>();
    const { push } = useLocalHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { currentLocation } = useSelector((state: ApplicationState) => state.locations);
    const [scenario] = useRouteScenario();
    const { isGuest } = useAuth();

    React.useEffect(() => {
        async function getOrderStatus() {
            try {
                const orderResult = await orderApi.getOrder(orderId);
                setOrder(orderResult);
                setLoading(false);
            } catch (orderStatusError) {
                const err: AxiosResponse<{ message: string }> = orderStatusError;
                if (isDefined(err.data && err.status === 404)) {
                    setError(ErrorType.NOT_FOUND);
                    setErrorMessage('Order not found');
                } else {
                    setError(ErrorType.GENERIC);
                    setErrorMessage(err.data.message);
                }
                setLoading(false);
            }
        }
        function showExpiryDialog() {
            setIsOrderAvailable(false);
        }
        if (isGuest && !isOrderAvailableToGuest(orderId)) {
            showExpiryDialog();
        } else {
            getOrderStatus();
        }
    }, [dispatch, orderId, locationId, t, asapEnabled, isGuest]);

    React.useEffect(() => {
        if (!!order && order.scenario !== (scenario as OrderScenario)) {
            push(ROUTES.ORDER.SUMMARY, { scenario: makeRouteScenario(order.scenario), orderId });
        }
    }, [push, scenario, order, orderId]);

    const handleOnBack = React.useCallback(() => {
        if (order || scenario) {
            const merchantId = getLocalMerchantId();
            if (merchantId) {
                if (order?.scenario === OrderScenario.TABLE || scenario === OrderScenario.TABLE) {
                    push(ROUTES.JOURNEY.LANDING, { locationId, merchantId });
                } else {
                    push(ROUTES.MENU, { locationId, merchantId });
                }
            }
        }
    }, [locationId, order, push, scenario]);
    if (!isOrderAvailable) {
        return (
            <Box display="flex" flexDirection="column" height="100%" width="100%" className="hidden-scroll">
                <ViewBillHeader title={t('ORDER_IN_FLIGHT_TITLE')} onBack={handleOnBack} />
                <OrderSessionExpired />
            </Box>
        );
    }

    return (
        <Box display="flex" flexDirection="column" height="100%" width="100%" className="hidden-scroll">
            <ViewBillHeader title={t('ORDER_IN_FLIGHT_TITLE')} onBack={handleOnBack} />
            {loading && (
                <Box className={classes.pageContainer}>
                    <Throbber text={t('GENERAL_LOADING')} />
                </Box>
            )}
            {!loading && error === ErrorType.GENERIC && (
                <Box className={clsx(classes.pageContainer, classes.pageCenter)}>
                    <EmptyState
                        headerText={t('GENERAL_GENERIC_ERROR')}
                        paragraphText={t('ORDER_STATUS_ERROR_LOADING', { errorMessage })}
                    >
                        <ErrorOutline className={classes.basketError} />
                    </EmptyState>
                </Box>
            )}
            {!loading && (error === ErrorType.NOT_FOUND || !order) && (
                <Box className={clsx(classes.pageContainer, classes.pageCenter)}>
                    <EmptyState
                        headerText={t('GENERAL_GENERIC_ERROR')}
                        paragraphText={t('ORDER_STATUS_NOT_FOUND')}
                    >
                        <ErrorOutline className={classes.basketError} />
                    </EmptyState>
                </Box>
            )}
            {!loading && order && currentLocation && (
                <Box flex={1} height={0}>
                    <OrderSummary location={currentLocation} order={order} />
                </Box>
            )}
        </Box>
    );
};
