import logger from 'lib/logger';
import { isArrayOf, isString } from 'lib/typeInference';

const GUEST_ORDERS_KEY = 'guestOrders';

function getGuestOrders(): string[] {
    const storedOrdersString = localStorage.getItem(GUEST_ORDERS_KEY);
    if (isString(storedOrdersString)) {
        try {
            const parsedStoredOrders = JSON.parse(storedOrdersString);
            if (isArrayOf(isString, parsedStoredOrders)) {
                return parsedStoredOrders;
            }
        } catch (e) {
            logger.error(e);
        }
    }
    return [];
}

export function isOrderAvailableToGuest(orderId: string): boolean {
    const orders = getGuestOrders();
    return orders.includes(orderId);
}

export function clearGuestOrders() {
    localStorage.removeItem(GUEST_ORDERS_KEY);
}

export function addGuestOrder(orderId: string) {
    const storedOrdersString = localStorage.getItem(GUEST_ORDERS_KEY);
    let localOrders: string[] = [];
    if (isString(storedOrdersString)) {
        try {
            const parsedStoredOrders = JSON.parse(storedOrdersString);
            if (isArrayOf(isString, parsedStoredOrders)) {
                localOrders = parsedStoredOrders;
                if (!localOrders.includes(orderId)) {
                    localOrders.push(orderId);
                }
            }
        } catch (e) {
            logger.error(e);
        }
    }
    if (!localOrders.length) {
        localOrders = [orderId];
    }
    localStorage.setItem(GUEST_ORDERS_KEY, JSON.stringify(localOrders));
}
