import * as React from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
    createStyles({
        '@font-face': {
            fontFamily: 'SimpleLineIcon',
            src: `url(${process.env.PUBLIC_URL}/fonts/Simple-Line-Icons-Pro.ttf)`
        }
    })
);

export const LineIconProvider: React.FC = ({ children }) => {
    useStyles();

    return <Box flex={1}>{children}</Box>;
};
