import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BaseRouteParams, ROUTES } from 'pages/routes';
import { ApplicationState } from 'store/store';
import { IRouteProps, Route } from './Route';
import { Throbber } from './Throbber';
import { useAuth } from './useAuth';
import { useLocalHistory } from './useLocalHistory';
import { useLocation, useParams } from 'react-router-dom';
import { setOnCloseRedirectUrl } from 'components/user/localAuth';
import { isString } from './typeInference';
import { setReturnUrl } from 'store/auth/authActions';
import { useQuery } from 'src/hooks/useQuery';

export const UserRoute: React.FC<IRouteProps> = ({ children, ...rest }) => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector((state: ApplicationState) => state.auth);
    const { t } = useTranslation();
    const { isGuest, isLoggedIn } = useAuth();
    const { merchantId } = useParams<BaseRouteParams>();
    const { pathname } = useLocation();
    const authJourney = useQuery('j');
    const isLoginRedirect = authJourney === 'login';
    const { push } = useLocalHistory();
    const render = React.useCallback(() => {
        if (!isGuest && isLoggedIn) {
            return children;
        }
        if (isLoading) {
            return <Throbber text={t('GENERAL_LOADING')} />;
        }
        if (isString(merchantId)) {
            setOnCloseRedirectUrl(pathname, merchantId);
            setReturnUrl(pathname)(dispatch);
        }
        if (isLoginRedirect) {
            push(ROUTES.USER.LOGIN);
        } else {
            push(ROUTES.USER.REGISTER);
        }
        return null;
    }, [isGuest, isLoggedIn, isLoading, merchantId, isLoginRedirect, children, t, pathname, dispatch, push]);
    return <Route {...rest} render={render} />;
};
