import React from 'react';
import { Box, createStyles, Icon, makeStyles, Theme } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import Search from '@material-ui/icons/Search';
import clsx from 'clsx';
import { addOpacity } from 'lib/helpers';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            display: 'flex',
            height: theme.spacing(5),
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.background.default,
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            fontSize: theme.spacing(2),
            border: `1px solid ${theme.palette.divider}`
        },
        icon: {
            color: theme.palette.text.primary
        },
        input: {
            height: '100%',
            flex: 1,
            display: 'flex',
            border: 'none',
            backgroundColor: 'inherit',
            color: theme.palette.text.primary,
            '&::-webkit-input-placeholder': {
                color: addOpacity(theme.palette.text.primary, 0.8)
            }
        }
    })
);

interface ISearchInputProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClear: () => void;
    placeholder: string;
    value: string;
    autoFocus?: boolean;
    inputRef?: { current: HTMLInputElement | null };
    componentClasses?: { input?: string; icon?: string; wrapper?: string; box?: string };
}

export const SearchInput: React.FC<ISearchInputProps> = props => {
    const { placeholder, onChange, onClear, value, autoFocus, inputRef } = props;
    const classes = useStyles();

    return (
        <Box width="100%" className={clsx(props.componentClasses?.box || '')}>
            <Box className={clsx(classes.wrapper, props.componentClasses?.wrapper || '')}>
                <Icon className={clsx(classes.icon, props.componentClasses?.icon || '')}>
                    <Search />
                </Icon>
                <input
                    ref={inputRef}
                    autoFocus={autoFocus}
                    className={clsx(classes.input, props.componentClasses?.input || '')}
                    onChange={onChange}
                    value={value}
                    placeholder={placeholder}
                />
                {value.length > 0 && (
                    <Icon className={clsx(classes.icon, props.componentClasses?.icon || '')}>
                        <CancelIcon onClick={onClear} />
                    </Icon>
                )}
            </Box>
        </Box>
    );
};
