import React from 'react';
import { useTranslation } from 'react-i18next';
import { PayGiftCardApply } from './PayGiftCardApply';

interface PayGiftCardLoadingProps {
    giftCardNumber?: string;
    error?: string;
    onClose: () => void;
    onRetry: () => void;
}

export const PayGiftCardError: React.FC<PayGiftCardLoadingProps> = ({
    giftCardNumber,
    error,
    onClose,
    onRetry
}) => {
    const { t } = useTranslation();
    const localisationStrings = React.useMemo(
        () => ({
            title: t('PAT_QUICKPAY_GIFTCARD_ERROR_TITLE'),
            subtitle: t('PAT_QUICKPAY_GIFTCARD_ERROR_BODY'),
            hint: t('PAT_QUICKPAY_GIFTCARD_ERROR_HINT'),
            primary: t('PAT_QUICKPAY_GIFTCARD_ERROR_PRIMARY'),
            secondary: t('PAT_QUICKPAY_GIFTCARD_ERROR_SECONDARY')
        }),
        [t]
    );
    if (!error) {
        return null;
    }
    return (
        <PayGiftCardApply
            giftCardNumber={giftCardNumber}
            error={error}
            onPrimaryClick={onRetry}
            onSecondaryClick={onClose}
            localisation={localisationStrings}
        />
    );
};
