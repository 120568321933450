import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { setOnCloseRedirectUrl } from 'components/user/localAuth';
import { LoadingTypography } from 'lib/LoadingTypography';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { useStringReplace } from 'lib/useStringReplace';
import { LocationRouteParams, ROUTES } from 'pages/routes';
import { PayLinkButton } from '../ui/PayLinkButton';
import { PayRewardItem } from './PayRewardItem';
import { IAdjustmentLocalResource } from 'components/order/model/Order';
import { isOrderAvailableAdjastment } from 'components/order/helpers';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        marginBottom: theme.spacing(1),
        fontWeight: 700,
        fontSize: '18px'
    },
    linkText: {
        fontWeight: 700,
        color: theme.palette.primary.main
    }
}));

interface PayRewardsProps {
    loading?: boolean;
    rewards: IAdjustmentLocalResource[];
    selected?: IAdjustmentLocalResource;
    onSelect: (id: number) => void;
    onClear: () => void;
    disabled?: boolean;
}

export const PayRewards: React.FC<PayRewardsProps> = ({
    loading,
    rewards,
    selected,
    onSelect,
    onClear,
    disabled
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const replace = useStringReplace();
    const { isGuest } = useAuth();
    const history = useLocalHistory();
    const { merchantId } = useParams<LocationRouteParams>();
    const location = useLocation();
    const filteredRewards = React.useMemo<IAdjustmentLocalResource[]>(() => {
        if (!Array.isArray(rewards)) {
            return [];
        }
        return rewards.filter(item => isOrderAvailableAdjastment(item));
    }, [rewards]);
    const handleClick = React.useCallback(
        (id: number) => {
            onSelect(id);
        },
        [onSelect]
    );
    const handleUnselect = React.useCallback(() => {
        onClear();
    }, [onClear]);
    const handleSignUpClick = React.useCallback(() => {
        if (merchantId) {
            setOnCloseRedirectUrl(`${location.pathname}${location.search}`, merchantId);
        }
        history.push(ROUTES.USER.REGISTER);
    }, [history, location.pathname, location.search, merchantId]);
    const handleAwardsClick = React.useCallback(() => {
        if (merchantId) {
            setOnCloseRedirectUrl(`${location.pathname}${location.search}`, merchantId);
        }
        history.push(ROUTES.USER.REWARDS);
    }, [history, location.pathname, location.search, merchantId]);
    const renderReward = React.useCallback(
        (reward: IAdjustmentLocalResource) => (
            <PayRewardItem item={reward} key={reward.id} onClick={handleClick} />
        ),
        [handleClick]
    );
    if (loading) {
        return (
            <Box px={2} pb={1} pt={1}>
                <LoadingTypography loading width={100} className={classes.title} />
                <Box display="flex" justifyContent="space-between">
                    <LoadingTypography loading width={120} />
                    <Skeleton variant="circle" />
                </Box>
            </Box>
        );
    }
    if (disabled) {
        return null;
    }
    if (isGuest) {
        return (
            <Box px={2} pb={1} pt={1}>
                <LoadingTypography loading={loading} width={240}>
                    {replace('PAT_QUICKPAY_REWARDS_UNREGISTERED', 'link', index => (
                        <Typography
                            onClick={handleSignUpClick}
                            className={classes.linkText}
                            component="span"
                            key={`email-sent-${index}`}
                        >
                            {t('PAT_QUICKPAY_REWARDS_UNREGISTERED_LINK')}
                        </Typography>
                    ))}
                </LoadingTypography>
            </Box>
        );
    }
    if (!selected && (!filteredRewards || !filteredRewards.length)) {
        return (
            <Box px={2} pb={1} pt={1}>
                <LoadingTypography loading={loading} width={240}>
                    {replace('PAT_QUICKPAY_REWARDS_EMPTY', 'link', index => (
                        <Typography
                            onClick={handleAwardsClick}
                            className={classes.linkText}
                            component="span"
                            key={`email-sent-${index}`}
                        >
                            {t('PAT_QUICKPAY_REWARDS_EMPTY_LINK')}
                        </Typography>
                    ))}
                </LoadingTypography>
            </Box>
        );
    }
    return (
        <Box px={2} pb={1} pt={1}>
            <Typography className={classes.title}>{t('BILL_CHECKOUT_AVAILABLE_REWARDS')}</Typography>
            <Box>
                {!!selected && <PayRewardItem item={selected} selected onClick={handleUnselect} />}
                {!selected && filteredRewards.map(renderReward)}
            </Box>
            {!!selected && (
                <PayLinkButton onClick={handleUnselect}>{t('PAT_QUICKPAY_REWARDS_CHANGE')}</PayLinkButton>
            )}
            <Box pt={1}>
                <Divider />
            </Box>
        </Box>
    );
};
