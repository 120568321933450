import React from 'react';
import { isDefined, isNumber } from 'lib/typeInference';
import { PayGiftCardError } from '../giftcard/PayGiftCardError';
import { PayGiftCardForm } from '../giftcard/PayGiftCardForm';
import { PayGiftCardLoading } from '../giftcard/PayGiftCardLoading';
import { PayGiftCardSuccess } from '../giftcard/PayGiftCardSuccess';
import { PayBaseModal } from './PayBaseModal';

interface PayGiftCardModalProps {
    open: boolean;
    onClose: () => void;
    onGiftcardApply: (giftCard?: string) => void;
    loading?: boolean;
    error?: string;
    giftCardPayment?: number;
    resetErrors: () => void;
    remainingBalance?: number;
}

enum GiftcardState {
    FORM = 'FORM',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    LOADING = 'LOADING'
}

export const PayGiftCardModal: React.FC<PayGiftCardModalProps> = ({
    open,
    onClose,
    loading,
    error,
    onGiftcardApply,
    remainingBalance,
    giftCardPayment,
    resetErrors
}) => {
    const [giftCardNumber, setGiftCardNumber] = React.useState<string>();
    const handleSubmit = React.useCallback(
        (value: string) => {
            setGiftCardNumber(value);
            onGiftcardApply(value.replace(/\s/g, ''));
        },
        [onGiftcardApply]
    );
    const stage = React.useMemo(() => {
        if (loading) {
            return GiftcardState.LOADING;
        }
        if (error) {
            return GiftcardState.ERROR;
        }
        if (isNumber(giftCardPayment)) {
            return GiftcardState.SUCCESS;
        }
        return GiftcardState.FORM;
    }, [error, giftCardPayment, loading]);
    const handleClose = React.useCallback(() => {
        if (loading) {
            return;
        }
        setGiftCardNumber(undefined);
        resetErrors();
        onClose();
    }, [loading, onClose, resetErrors]);
    const handleChangeGiftcard = React.useCallback(() => {
        onGiftcardApply(undefined);
        handleClose();
    }, [handleClose, onGiftcardApply]);
    const handleRetry = React.useCallback(() => {
        resetErrors();
        setGiftCardNumber(undefined);
    }, [resetErrors]);
    return (
        <PayBaseModal open={!!open} onClose={handleClose}>
            {stage === GiftcardState.ERROR && (
                <PayGiftCardError
                    giftCardNumber={giftCardNumber}
                    onClose={handleClose}
                    error={error}
                    onRetry={handleRetry}
                />
            )}
            {stage === GiftcardState.LOADING && (
                <PayGiftCardLoading empty={!isDefined(giftCardNumber)} giftCardNumber={giftCardNumber} />
            )}
            {stage === GiftcardState.SUCCESS && (
                <PayGiftCardSuccess
                    giftCardNumber={giftCardNumber}
                    giftCardPayment={giftCardPayment}
                    giftCardRemainingBalance={remainingBalance}
                    onClose={handleClose}
                    onChange={handleChangeGiftcard}
                />
            )}
            {stage === GiftcardState.FORM && <PayGiftCardForm onSubmit={handleSubmit} />}
        </PayBaseModal>
    );
};
