/* eslint-disable react/no-multi-comp */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Collapse,
    createStyles,
    FormControl,
    FormHelperText,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import { Field, FieldAttributes, FieldProps } from 'formik';
import { QuantityField } from '../QuantityField';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';
import { isString } from 'lib/typeInference';

export interface QuantityLabel {
    title: string;
    description?: string;
    image?: string;
    disabled?: boolean;
    calories?: string;
}

interface QuantityProps {
    label: React.ReactNode;
    labels: Record<string, QuantityLabel>;
    max?: number;
    min?: number;
    description?: string;
    collapsed?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontWeight: 'bold',
            color: theme.palette.titleTextColour
        },
        label: {
            whiteSpace: 'pre-wrap'
        },
        counter: {
            flex: 'none',
            opacity: 0.7
        },
        panel: {
            cursor: 'pointer'
        },
        icon: {
            transition: 'transform .25s',
            marginLeft: theme.spacing(1),
            color: theme.palette.text.hint
        },
        expandedIcon: {
            transform: 'rotate(180deg)'
        }
    })
);

const QuantityFormFieldComponent: React.FC<FieldProps & QuantityProps> = ({
    field,
    meta,
    labels,
    label,
    description,
    max = 999,
    min = 0,
    collapsed: collapsedByDefault
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [collapsed, setCollapsed] = React.useState(!!collapsedByDefault);
    const handleCollapse = React.useCallback(() => {
        setCollapsed(isCollapsed => !isCollapsed);
    }, []);
    const isError = React.useMemo(() => meta.touched && !!meta.error, [meta]);
    const quantity = React.useMemo(
        () => (Array.isArray(field.value) ? field.value.length : 0),
        [field.value]
    );
    const disabledMax = React.useMemo(() => quantity === max, [quantity, max]);
    const value = React.useMemo(() => {
        const base = Object.keys(labels).reduce<Record<string, number>>((acc, item) => {
            acc[item] = 0;
            return acc;
        }, {});
        if (quantity > 0) {
            field.value.forEach((item: string) => {
                base[item] = base[item] + 1;
            });
        }
        return base;
    }, [field.value, quantity, labels]);
    const handleChange = React.useCallback(
        (itemName: string) => (count: number) => {
            const newValue = { ...value, [itemName]: count };
            const resultValue = Object.entries(newValue).reduce<string[]>((acc, [id, itemQuantity]) => {
                if (itemQuantity) {
                    for (let i = 0; i < itemQuantity; i++) {
                        acc.push(id);
                    }
                }
                return acc;
            }, []);
            field.onChange({ target: { value: resultValue, name: field.name } });
        },
        [field, value]
    );
    const mapLabels = React.useCallback(
        ([id, { title, description: itemDescription, image, disabled }]) => (
            <QuantityField
                key={`quantity-field-${id}`}
                image={image}
                label={title}
                description={itemDescription}
                value={value[id]}
                onChange={handleChange(id)}
                disabledMax={disabledMax}
                disabled={disabled}
            />
        ),
        [value, disabledMax, handleChange]
    );
    const selectedOptions = React.useMemo(() => {
        const resultOptions: string[] = [];
        Object.entries(value).forEach(([id, quantity]) => {
            const label = labels[id]?.title;
            if (isString(label) && quantity > 0) {
                if (quantity > 1) {
                    resultOptions.push(`${quantity}x ${label}`);
                } else {
                    resultOptions.push(label);
                }
            }
        });
        return resultOptions.join(', ');
    }, [labels, value]);
    return (
        <FormControl error={isError} fullWidth>
            <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                paddingBottom={1}
                marginBottom={1}
                onClick={handleCollapse}
                className={classes.panel}
            >
                <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
                    <Typography className={classes.title}>
                        {label}
                        {min > 0 ? ' *' : ''}
                    </Typography>
                    {min > 0 && (
                        <Typography className={classes.counter} variant="body2">
                            {t('PRODUCT_DETAILS_QUANTITY_MODIFIER_LABEL', {
                                quantity,
                                requiredQuantity: max
                            })}
                        </Typography>
                    )}
                </Box>
                <KeyboardArrowDownIcon
                    color="primary"
                    className={clsx(classes.icon, { [classes.expandedIcon]: !collapsed })}
                />
            </Box>
            {!!collapsed && !!selectedOptions && (
                <Typography className={classes.label}>{selectedOptions}</Typography>
            )}
            <Collapse in={!collapsed}>
                <React.Fragment>
                    {Object.entries(labels).map(mapLabels)}

                    {(isError || description) && (
                        <FormHelperText error={isError}>{isError ? meta.error : description}</FormHelperText>
                    )}
                </React.Fragment>
            </Collapse>
        </FormControl>
    );
};

export const QuantityFormField: React.FC<FieldAttributes<QuantityProps>> = ({
    labels,
    max,
    label,
    description,
    collapsed,
    min,
    ...props
}) => (
    <Field {...props}>
        {(childProps: FieldProps) => (
            <QuantityFormFieldComponent
                label={label}
                labels={labels}
                max={max}
                min={min}
                collapsed={collapsed}
                description={description}
                {...childProps}
            />
        )}
    </Field>
);
