import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    Box,
    Button,
    createStyles,
    Dialog,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import clsx from 'clsx';
import { Form, Formik, FormikHelpers } from 'formik';
import smartlookClient from 'smartlook-client';
import * as Yup from 'yup';
import { getLocalMerchantId } from 'components/settings/localStore';
import { getIdentityLabel } from 'components/settings/model/Settings';
import { setOnCloseRedirectUrl } from 'components/user/localAuth';
import { getIdentityValidationScheme } from 'components/user/model/User';
import { userApi } from 'components/user/userApi';
import { BottomDialog } from 'lib/BottomDialog';
import { TextFormField } from 'lib/form/TextFormField';
import { Link } from 'lib/Link';
import logger from 'lib/logger';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { ROUTES } from 'pages/routes';
import { setReturnUrl, updateUser } from 'store/auth/authActions';
import { setGuestContactInformation } from 'store/guest/guestActions';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/LoadingButton';
import { ViewBillHeader } from '../ViewBillHeader';
import { useSnackbar } from 'notistack';
import { EGAEventName, useGAHelpers } from 'lib/useGAHelpers';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        contentSpacing: {
            padding: theme.spacing(0, 2, 2, 2)
        },
        content: {
            overflowY: 'scroll'
        },
        orCondition: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.grey[600],
            fontSize: theme.spacing(2),
            width: theme.spacing(7),
            height: theme.spacing(7),
            borderRadius: '50%',
            border: `1px solid ${theme.palette.divider}`
        },
        flexGrow: {
            flex: 1
        },
        guestCheckoutTitle: {
            margin: theme.spacing(0, 1)
        },
        fullHeight: {
            height: '100%'
        },
        description: {
            whiteSpace: 'pre-wrap'
        }
    })
);

export interface IGuestCardPaymentFormValues {
    firstName: string;
    lastName: string;
    identity: string;
}

const initialValues: IGuestCardPaymentFormValues = {
    firstName: '',
    lastName: '',
    identity: ''
};

interface IProps {
    onClickBack: () => void;
    onAuthActionClick?: () => Promise<any>;
}

export const GuestCheckout: React.FC<IProps> = ({ onClickBack, onAuthActionClick }) => {
    const classes = useStyles();
    const { user, isGuest, isVerifiedGuest } = useAuth();
    const [isSmartlookSet, setIsSmartlookSet] = React.useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { push } = useLocalHistory();
    const { pathname, search } = useLocation();
    const { enqueueSnackbar } = useSnackbar();

    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { identity } = useSelector((state: ApplicationState) => state.guest);
    const { logUserEvent } = useGAHelpers();

    const [alreadyHaveAccountOpen, setAlreadyHaveAccountOpen] = React.useState(false);
    const [isGuestCheckoutOpen, setIsGuestCheckoutOpen] = React.useState<boolean>(
        isGuest && !isVerifiedGuest
    );

    const userId = React.useMemo(() => (user ? user._id : ''), [user]);
    const identityLabel = React.useMemo(
        () => getIdentityLabel(settings?.emailAuthEnabled, settings?.phoneAuthEnabled),
        [settings?.emailAuthEnabled, settings?.phoneAuthEnabled]
    );
    const validationSchema = React.useMemo(
        () =>
            Yup.object().shape({
                firstName: Yup.string().required(t('FORMS_VALIDATION_REQUIRED')),
                lastName: Yup.string().required(t('FORMS_VALIDATION_REQUIRED')),
                identity: getIdentityValidationScheme(identityLabel, settings?.region.phoneNumberCode, t)
            }),
        [identityLabel, settings?.region?.phoneNumberCode, t]
    );
    const handleSubmit = React.useCallback(
        async (
            { firstName, lastName, identity: userIdentity }: IGuestCardPaymentFormValues,
            formikHelpers: FormikHelpers<IGuestCardPaymentFormValues>
        ) => {
            try {
                const [success, , guestDetails] = await userApi.updateGuestCredentials(userId, {
                    firstName,
                    lastName,
                    identity: userIdentity
                });
                setGuestContactInformation(firstName, lastName, userIdentity)(dispatch);
                if (!success) {
                    setAlreadyHaveAccountOpen(true);
                } else {
                    setIsGuestCheckoutOpen(false);
                    if (guestDetails) {
                        dispatch(updateUser(guestDetails));
                    }
                }
            } catch (error) {
                logger.error(error);
                enqueueSnackbar(t('GENERAL_ERROR_PROCESSING_REQUEST'), { variant: 'error' });
            } finally {
                formikHelpers.setSubmitting(false);
            }
        },
        [dispatch, enqueueSnackbar, t, userId]
    );
    const handleClearEmail = React.useCallback(
        (setFieldValue: FormikHelpers<any>['setFieldValue']) => () => {
            setFieldValue('identity', initialValues.identity);
        },
        []
    );
    const handleAlreadyHaveAnAccountClose = React.useCallback(() => {
        setAlreadyHaveAccountOpen(false);
        logUserEvent(EGAEventName.GuestAccountExistCancel);
    }, [logUserEvent]);

    const handleSignInClicked = React.useCallback(
        (event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>) => {
            event.preventDefault();
            logUserEvent(EGAEventName.GuestAccountExistLogin);
            setReturnUrl(pathname + search)(dispatch);
            const merchantId = getLocalMerchantId();
            if (merchantId) {
                setOnCloseRedirectUrl(pathname + search, merchantId);
            }
            if (onAuthActionClick) {
                onAuthActionClick();
            }
            if (identity) {
                push(ROUTES.USER.LOGIN, {}, `identity=${encodeURIComponent(identity)}`);
            } else {
                push(ROUTES.USER.LOGIN);
            }
        },
        [logUserEvent, pathname, search, dispatch, onAuthActionClick, identity, push]
    );
    const handleSignUpClicked = React.useCallback(() => {
        setReturnUrl(pathname + search)(dispatch);

        const merchantId = getLocalMerchantId();
        if (merchantId) {
            setOnCloseRedirectUrl(pathname + search, merchantId);
        }
        if (onAuthActionClick) {
            onAuthActionClick();
        }
        push(ROUTES.USER.REGISTER);
    }, [pathname, search, dispatch, onAuthActionClick, push]);
    const handleBackClick = React.useCallback(() => {
        onClickBack();
        setIsGuestCheckoutOpen(false);
    }, [onClickBack]);

    React.useEffect(() => {
        if (!isSmartlookSet && settings?.smartlookEnabled && isGuestCheckoutOpen) {
            try {
                const merchantId = getLocalMerchantId();
                smartlookClient.track('guest_checkout', { merchantId: merchantId ?? '' });
                setIsSmartlookSet(true);
            } catch (err) {
                logger.error(err);
            }
        }
    }, [isGuestCheckoutOpen, isSmartlookSet, settings?.smartlookEnabled]);

    return (
        <Dialog open={isGuestCheckoutOpen} fullScreen>
            <ViewBillHeader onBack={handleBackClick} title={t('GUEST_CHECKOUT_TITLE')} />
            <Box
                className={clsx(classes.contentSpacing, classes.content)}
                flex={1}
                marginTop={2}
                display="flex"
                flexDirection="column"
            >
                <Typography align="center" variant="body2" className={classes.description}>
                    {t('CHECKOUT_SIGN_IN_BENEFITS')}
                </Typography>
                <Box marginY={1}>
                    <Button color="primary" onClick={handleSignUpClicked} variant="contained" fullWidth>
                        {t('GUEST_CHECKOUT_SIGNUP')}
                    </Button>
                </Box>
                <Typography align="center" variant="body2">
                    {t('AUTH_ALREADY_HAVE_ACCOUNT') + ' '}
                    <Link onClick={handleSignInClicked} to="">
                        {t('ONBOARDING_SIGN_IN')}
                    </Link>
                </Typography>
                <Box marginTop={2} marginBottom={3} display="flex" justifyContent="center">
                    <div className={classes.orCondition}>{'OR'}</div>
                </Box>
                <Box display="flex" alignItems="center">
                    <Divider className={classes.flexGrow} />
                    <Typography className={classes.guestCheckoutTitle}>{t('GUEST_CHECKOUT')}</Typography>
                    <Divider className={classes.flexGrow} />
                </Box>
                <Box textAlign="center">
                    <Typography variant="caption">{t('GUEST_CHECKOUT_SUBTITLE')}</Typography>
                </Box>
                <Box flex={1}>
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                    >
                        {formik => {
                            const {
                                submitForm,
                                setFieldValue,
                                isSubmitting,
                                isValid,
                                values: { firstName, lastName, identity: userIdentity }
                            } = formik;

                            return (
                                <Form className={classes.fullHeight}>
                                    <Box paddingTop={2} display="flex" flexDirection="column" height="100%">
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <TextFormField
                                                    name="firstName"
                                                    label={t('ONBOARDING_SIGNUP_FIRSTNAME')}
                                                    variant="standard"
                                                    trimWhiteSpaces="sides"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextFormField
                                                    name="lastName"
                                                    label={t('ONBOARDING_SIGNUP_LASTNAME')}
                                                    variant="standard"
                                                    trimWhiteSpaces="sides"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Box marginY={2}>
                                            <TextFormField
                                                name="identity"
                                                type={identityLabel}
                                                label={t(`SIGN_UP_LABEL_${identityLabel.toUpperCase()}`)}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton
                                                            onClick={handleClearEmail(setFieldValue)}
                                                            size="small"
                                                            tabIndex={-1}
                                                        >
                                                            <Close />
                                                        </IconButton>
                                                    )
                                                }}
                                                variant="standard"
                                                trimWhiteSpaces="sides"
                                                description={t(
                                                    `CHECKOUT_GUEST_${identityLabel.toUpperCase()}_DESCRIPTION`
                                                )}
                                            />
                                        </Box>
                                        <Box marginTop="auto">
                                            <LoadingButton
                                                loading={isSubmitting}
                                                disabled={
                                                    isSubmitting ||
                                                    !isValid ||
                                                    !firstName ||
                                                    !lastName ||
                                                    !userIdentity
                                                }
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={submitForm}
                                            >
                                                {t('GUEST_CHECKOUT_SUBMIT')}
                                            </LoadingButton>
                                        </Box>
                                    </Box>
                                </Form>
                            );
                        }}
                    </Formik>
                </Box>
            </Box>
            <BottomDialog open={alreadyHaveAccountOpen}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography>{t('CHECKOUT_ACCOUNT_EXISTS')}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleAlreadyHaveAnAccountClose}
                            fullWidth
                        >
                            {t('GENERAL_CANCEL')}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" color="primary" onClick={handleSignInClicked} fullWidth>
                            {t('ONBOARDING_SIGN_IN')}
                        </Button>
                    </Grid>
                </Grid>
            </BottomDialog>
        </Dialog>
    );
};
