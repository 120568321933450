import { ActionCreator } from 'redux';
import { getLocalMerchantId, resetLocalSettings, updateLocalSettings } from 'components/settings/localStore';
import { ISettings } from 'components/settings/model/Settings';
import { settingsApi } from 'components/settings/settingsApi';
import { Timeslot } from 'components/timeslots/model/Timeslot';
import { timeslotsApi } from 'components/timeslots/timeslotsApi';
import { getLoadingImagesFolder } from 'lib/imageHelpers';
import logger from 'lib/logger';
import { BasketActionTypes } from 'store/basket/basketActionTypes';
import { SettingsActionTypes } from './settingsActionTypes';
import { enqueueSnackbar } from 'notistack';

interface SettingsLoadingAction {
    type: SettingsActionTypes.END_REQUEST | SettingsActionTypes.START_REQUEST;
}

interface SettingsGetSuccessAction {
    type: SettingsActionTypes.GET_SETTINGS_SUCCESS;
    settings: ISettings & { title: string; applicationId: string; locationId: string };
    merchantId: string;
}

interface SettingsErrorAction {
    type: SettingsActionTypes.GET_SETTINGS_ERROR | SettingsActionTypes.GET_TIMESLOTS_ERROR;
    error: string;
}

interface SettingsTimeslotsAction {
    type: SettingsActionTypes.GET_TIMESLOTS_SUCCESS;
    timeslots: Timeslot[];
}

interface SettingsLoadingImageAction {
    type: SettingsActionTypes.GET_LOADING_IMAGE;
    loadingImage?: string;
}

export type SettingsAction =
    | SettingsLoadingAction
    | SettingsGetSuccessAction
    | SettingsErrorAction
    | SettingsLoadingImageAction
    | SettingsTimeslotsAction;

export const loadSettings =
    (merchantId: string, locationId?: string) => async (dispatch: ActionCreator<SettingsAction>) => {
        dispatch({
            type: SettingsActionTypes.START_REQUEST
        });
        try {
            const tenantData = await settingsApi.getTenant(merchantId);
            const data = await settingsApi.getSettings(merchantId, locationId);
            updateLocalSettings(merchantId, tenantData.platform.applicationId);
            dispatch({
                merchantId,
                settings: {
                    ...data,
                    locationId,
                    googlePayEnabled: !!data.googlePayWebEnabled,
                    applePayEnabled: !!data.applePayWebEnabled,
                    title: tenantData.title,
                    applicationId: tenantData.platform.applicationId
                },
                type: SettingsActionTypes.GET_SETTINGS_SUCCESS
            });
        } catch (e) {
            logger.error(e);
            resetLocalSettings();
            dispatch({
                type: SettingsActionTypes.GET_SETTINGS_ERROR,
                error: e.data.message
            });
        }
        return dispatch({
            type: SettingsActionTypes.END_REQUEST
        });
    };

export const loadTimeslots =
    (locationId: string, timeslot?: Timeslot, timeslotDoesntExistMessage = '') =>
    async (dispatch: ActionCreator<SettingsAction>) => {
        try {
            const result = await timeslotsApi.getTimeslots(locationId);
            const timeslots = result.items.filter(item => item.available);
            if (
                !!timeslot &&
                !timeslots.some(item => item.start === timeslot.start && item.end === timeslot.end)
            ) {
                dispatch({
                    type: BasketActionTypes.SET_TIMESLOT
                });
                enqueueSnackbar(timeslotDoesntExistMessage, { variant: 'warning' });
            }
            dispatch({
                type: SettingsActionTypes.GET_TIMESLOTS_SUCCESS,
                timeslots
            });
        } catch (e) {
            logger.error(e);
            dispatch({
                type: SettingsActionTypes.GET_TIMESLOTS_ERROR,
                error: e.data.message
            });
        }
    };

export const checkLoadingImage = () => (dispatch: ActionCreator<SettingsAction>) => {
    const merchantId = getLocalMerchantId();
    if (!merchantId) {
        return dispatch({
            type: SettingsActionTypes.GET_LOADING_IMAGE
        });
    }
    let image = `${getLoadingImagesFolder(merchantId)}/spinner.gif`;
    fetch(image)
        .then(result => {
            if (!result.ok) {
                throw new Error("Can't load spinner");
            }
            dispatch({
                type: SettingsActionTypes.GET_LOADING_IMAGE,
                loadingImage: image
            });
        })
        .catch(() => {
            image = `${getLoadingImagesFolder(merchantId)}/spinner.svg`;
            fetch(image)
                .then(result => {
                    if (!result.ok) {
                        throw new Error("Can't load spinner");
                    }
                    dispatch({
                        type: SettingsActionTypes.GET_LOADING_IMAGE,
                        loadingImage: image
                    });
                })
                .catch(() => {
                    dispatch({
                        type: SettingsActionTypes.GET_LOADING_IMAGE
                    });
                });
        });
};
