import React from 'react';
import { Box, Checkbox, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useCalories } from 'src/hooks/useCalories';
import { IItemReadResourceV10 } from 'components/basket/model/Basket';
import { getModifierProductDetails, getProductOptionsDetails } from 'components/menu/model/Menu';
import { getCurrencyString, showCalories } from 'lib/helpers';

const useStyles = makeStyles((theme: Theme) => ({
    opaqueText: {
        opacity: 0.7
    },
    containerBox: {
        borderBottom: `1px solid ${theme.palette.divider}`
    }
}));

interface IRecentOrderItemBoxProps {
    selected: boolean;
    onChange: (id: number) => void;
    item: IItemReadResourceV10;
    modifierItems: IItemReadResourceV10[];
    currencyCode: string;
    isLast: boolean;
}

export const RecentOrderItemBox: React.FC<IRecentOrderItemBoxProps> = ({
    selected,
    onChange,
    item,
    item: { quantity, cost, productName, modifiers, id },
    modifierItems,
    currencyCode,
    isLast
}) => {
    const { getCaloriesString, getOrderItemsCalories } = useCalories();
    const classes = useStyles();
    const [modifierProductsString, calculatedItemPrice] = React.useMemo(() => {
        const [modifierString, modifiersPrice] = getModifierProductDetails(modifierItems, quantity);
        const totalCalculatedPrice = cost + modifiersPrice;
        return [modifierString, totalCalculatedPrice];
    }, [cost, modifierItems, quantity]);

    const [modifierOptionsString, calculatedOptionsPrice] = React.useMemo(
        () => getProductOptionsDetails(modifiers, false),
        [modifiers]
    );

    const totalPrice = React.useMemo(
        () => Math.round((calculatedItemPrice + calculatedOptionsPrice) * quantity * 100) / 100,
        [calculatedItemPrice, calculatedOptionsPrice, quantity]
    );

    const handleChange = React.useCallback(() => {
        onChange(id);
    }, [id, onChange]);
    const totalItemCalories = React.useMemo(
        () => getOrderItemsCalories([item]),
        [getOrderItemsCalories, item]
    );

    const priceString = React.useMemo(() => {
        if (showCalories(totalItemCalories)) {
            return `${getCurrencyString(totalPrice, currencyCode)} | ${getCaloriesString(
                totalItemCalories.value
            )}`;
        }

        return getCurrencyString(totalPrice, currencyCode);
    }, [currencyCode, getCaloriesString, totalPrice, totalItemCalories]);

    return (
        <Box onClick={handleChange} className={clsx({ [classes.containerBox]: !isLast })}>
            <Grid container alignItems="center">
                <Grid item xs={10}>
                    <Typography variant="h6">
                        {quantity}
                        {'x'} {productName}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Box display="flex" justifyContent="flex-end">
                        <Checkbox checked={selected} />
                    </Box>
                </Grid>
            </Grid>
            {(!!modifierProductsString || !!modifierOptionsString) && (
                <Box marginBottom={1}>
                    <Typography color="textPrimary" align="justify" className={classes.opaqueText}>
                        {modifierProductsString}
                        {modifierOptionsString}
                    </Typography>
                </Box>
            )}
            <Box marginTop={0.5}>
                <Typography>{priceString}</Typography>
            </Box>
        </Box>
    );
};
