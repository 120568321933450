import * as React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import random from 'lodash/random';
import { addOpacity } from 'lib/helpers';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            borderBottom: `1px solid ${addOpacity(theme.palette.text.primary, 0.1)}`
        },
        title: {
            fontSize: theme.spacing(2)
        },
        subtitle: {
            fontSize: theme.spacing(1.75),
            color: theme.palette.grey[600]
        }
    })
);

export const ActionListSkeleton: React.FC = () => {
    const classes = useStyles();
    const renderSkeleton = React.useCallback(
        (_, index: number) => (
            <Box
                display="flex"
                key={index}
                alignItems="center"
                paddingY={1.5}
                paddingX={2}
                className={classes.wrapper}
            >
                <Skeleton variant="rect" width={35} height={35} />
                <Box marginLeft={1.5}>
                    <Skeleton height={24} width={random(40, 200)} />
                    <Skeleton height={21} width={random(40, 200)} />
                </Box>
            </Box>
        ),
        [classes.wrapper]
    );

    return <div>{Array(15).fill('').map(renderSkeleton)}</div>;
};
