import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ButtonBase, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { EActionType } from 'components/activity/enums';
import { notificationsApi } from 'components/notifications/notificationsApi';
import { userApi } from 'components/user/userApi';
import logger from 'lib/logger';
import { useAuth } from 'lib/useAuth';
import { PayEmailReceiptModal } from '../modals/PayEmailReceiptModal';

const useStyles = makeStyles((theme: Theme) => ({
    buttonBase: {
        width: '100%',
        color: theme.palette.primary.main,
        padding: theme.spacing(1, 0)
    },
    buttonBaseRegistered: {
        width: '100%',
        padding: theme.spacing(1, 0),
        display: 'block'
    },
    text: {
        paddingRight: theme.spacing(0.5),
        fontSize: '16px',
        fontWeight: 700,
        color: theme.palette.primary.main
    },
    title: {
        color: theme.palette.text.primary
    }
}));

interface PayEmailReceiptProps {
    orderId: number;
}

export const PayEmailReceipt: React.FC<PayEmailReceiptProps> = ({ orderId }) => {
    const { user, isGuest } = useAuth();
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [receiptSent, setReceiptSent] = React.useState(false);
    const handleOpen = React.useCallback(() => {
        setOpen(true);
    }, []);
    const handleClose = React.useCallback(() => {
        setOpen(false);
    }, []);
    const handleSubmit = React.useCallback(
        async ({ email }: { email: string }) => {
            setLoading(true);
            try {
                if (user?.id) {
                    const list = await userApi.getActivityHistory(user?.id);
                    const orderRelatedActionId = list.items.find(
                        action =>
                            (action.type === EActionType.ORDER_USER_BILLED ||
                                action.type === EActionType.ORDER_COMPLETED) &&
                            action.metadata.order &&
                            action.metadata.order.id === orderId
                    )?._id;

                    if (orderRelatedActionId) {
                        await notificationsApi.resendReceipt({
                            userEmail: email,
                            actionId: orderRelatedActionId
                        });
                        setReceiptSent(true);
                        setTimeout(() => {
                            setOpen(false);
                            setReceiptSent(false);
                        }, 3000);
                    }
                } else {
                    throw new Error('Invalid user id');
                }
            } catch (e) {
                logger.error(e);
            } finally {
                setLoading(false);
            }
        },
        [orderId, user?.id]
    );
    return (
        <React.Fragment>
            <Box flex={1}>
                {isGuest && (
                    <ButtonBase onClick={handleOpen} className={classes.buttonBase}>
                        <Box display="flex" flex={1} alignItems="center" justifyContent="center" paddingY={1}>
                            <Typography className={classes.text} color="inherit">
                                {t('PAT_QUICKPAY_EMAIL_RECEIPT')}
                            </Typography>
                            <ArrowForward color="inherit" />
                        </Box>
                    </ButtonBase>
                )}
                {!isGuest && (
                    <ButtonBase onClick={handleOpen} className={classes.buttonBaseRegistered}>
                        <Typography className={classes.title} color="inherit" align="center">
                            {t('PAT_QUICKPAY_RESEND_RECEIPT_TITLE')}
                        </Typography>
                        <Typography className={classes.text} color="inherit" align="center">
                            {t('PAT_QUICKPAY_RESEND_RECEIPT_SUBTITLE')}
                        </Typography>
                    </ButtonBase>
                )}
                <Box px={2}>
                    <Divider />
                </Box>
            </Box>
            <PayEmailReceiptModal
                onSubmit={handleSubmit}
                defaultEmail={user?.email}
                open={open}
                loading={loading}
                onClose={handleClose}
                sent={receiptSent}
            />
        </React.Fragment>
    );
};
