import React from 'react';
import InView from 'react-intersection-observer';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    createStyles,
    Divider,
    ListItem,
    ListItemText,
    makeStyles,
    Theme
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import debounce from 'lodash/debounce';
import {
    IEnrichedCategory,
    IEnrichedMenuWithModifierMaps,
    IEnrichedProduct,
    isProductAvailable,
    isProductGroup,
    ProductGroup
} from './model/Menu';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store/store';
import { MenuProduct } from './MenuProduct';
import { useResponsive } from 'src/hooks/useResponsive';
import { getCompoundKey } from 'lib/helpers';

interface MenuCategoryProps {
    item: IEnrichedCategory;
    isCollapsible?: boolean;
    isExpandedInitially?: boolean;
    menu?: IEnrichedMenuWithModifierMaps;
    onProductSelect: (item: IEnrichedProduct | ProductGroup, categoryId: string) => void;
    setActiveCategory?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            flexDirection: 'column'
        },
        categoryTitle: {
            fontWeight: 'bold',
            color: theme.palette.titleTextColour
        },
        panel: {
            width: '100%',
            '&::before': {
                height: 0
            },
            marginTop: '0 !important'
        },
        imageWrapper: {
            width: '100%',
            position: 'relative',
            // this is for obtaining image ratio of 3x1 (~33.33%)
            paddingTop: `${100 / 3}%`,
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden'
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0
        },
        details: {
            padding: 0
        },
        products: {
            width: '100%',
            paddingTop: 0
        },
        expansionPanelSummary: {
            alignItems: 'flex-start',
            padding: theme.spacing(0, 3, 0, 1.5)
        },
        expansionPanelIcon: {
            color: theme.palette.titleTextColour,
            margin: theme.spacing(0, -3, 0, 0),
            '& svg': {
                fontSize: '32px'
            }
        },
        expansionPanelContent: {
            marginTop: theme.spacing(1),
            marginBottom: 0
        }
    })
);

export const MenuCategory: React.FC<MenuCategoryProps> = ({
    item,
    setActiveCategory,
    isExpandedInitially,
    isCollapsible = true,
    onProductSelect,
    menu
}) => {
    const classes = useStyles();
    const { items } = useSelector((state: ApplicationState) => state.basket);
    const { isDesktop } = useResponsive();
    const [expanded, setExpanded] = React.useState(!item.collapsed || isExpandedInitially);
    const [imageLoaded, setImageLoaded] = React.useState(false);
    const togglePanel = React.useCallback(() => setExpanded(isExpanded => !isExpanded), []);
    const handleImageLoad = React.useCallback(() => {
        setImageLoaded(true);
    }, [setImageLoaded]);

    const handleActiveCategory = React.useMemo(
        () =>
            debounce((inView: boolean) => {
                if (inView && setActiveCategory) {
                    setActiveCategory(getCompoundKey(item.groupCategoryId, item.id));
                }
            }, 200),
        [item.groupCategoryId, item.id, setActiveCategory]
    );
    const categoryImage = React.useMemo(
        () =>
            !!item.imageUrl && (
                <Box paddingX={1.5} width="100%">
                    <Box lineHeight={1} onClick={togglePanel} className={classes.imageWrapper}>
                        <img
                            src={item.imageUrl}
                            onLoad={handleImageLoad}
                            alt={item.title}
                            className={classes.image}
                        />
                    </Box>
                    {!imageLoaded && <Skeleton className={classes.imageWrapper} variant="rect" />}
                </Box>
            ),
        [
            classes.image,
            classes.imageWrapper,
            handleImageLoad,
            imageLoaded,
            item.imageUrl,
            item.title,
            togglePanel
        ]
    );

    const handleProductSelect = React.useCallback(
        (product: IEnrichedProduct | ProductGroup) => {
            onProductSelect(product, item.id);
        },
        [item.id, onProductSelect]
    );

    const renderProduct = React.useCallback(
        (product: IEnrichedProduct) => {
            const itemQuantity = items.reduce((acc, basketItem) => {
                if (
                    item.id === basketItem.categoryId &&
                    (product.id === basketItem.productId ||
                        (isProductGroup(product) &&
                            product.products.some(childProduct => childProduct.id === basketItem.productId)))
                ) {
                    return acc + basketItem.quantity;
                }
                return acc;
            }, 0);
            return (
                <MenuProduct
                    key={product.id}
                    item={product}
                    menu={menu}
                    quantity={itemQuantity}
                    onClick={handleProductSelect}
                    isDesktop={isDesktop}
                />
            );
        },
        [items, menu, handleProductSelect, isDesktop, item.id]
    );

    const filteredProducts = React.useMemo(() => {
        if (item.hideUnavailableProducts) {
            return item.products.filter(product => isProductAvailable(product));
        }
        return item.products;
    }, [item.hideUnavailableProducts, item.products]);

    return (
        <ListItem disableGutters className={classes.listItem}>
            {isCollapsible ? (
                <>
                    {categoryImage}
                    <Accordion
                        expanded={expanded}
                        onChange={togglePanel}
                        className={classes.panel}
                        TransitionProps={{ unmountOnExit: true }}
                        elevation={0}
                    >
                        <AccordionSummary
                            aria-controls={`${item.id}-content`}
                            id={`${item.id}-header`}
                            expandIcon={<ExpandMoreIcon />}
                            className={classes.expansionPanelSummary}
                            classes={{
                                content: classes.expansionPanelContent,
                                expandIcon: classes.expansionPanelIcon
                            }}
                        >
                            <Box width="100%">
                                <ListItemText
                                    primaryTypographyProps={{
                                        className: classes.categoryTitle,
                                        variant: 'h5'
                                    }}
                                    primary={item.title}
                                />
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails className={classes.details}>
                            <div className={classes.products}>{filteredProducts.map(renderProduct)}</div>
                        </AccordionDetails>
                    </Accordion>
                </>
            ) : (
                <InView
                    rootMargin={'-110px 0px -80% 0px'}
                    onChange={handleActiveCategory}
                    style={{ width: '100%' }}
                    key={item.id}
                >
                    {categoryImage}
                    <Box width="100%" marginTop={2} marginBottom={1} paddingX={1.5}>
                        <ListItemText
                            primaryTypographyProps={{ className: classes.categoryTitle, variant: 'h5' }}
                            primary={item.title}
                        />
                    </Box>
                    <div className={classes.products}>{filteredProducts.map(renderProduct)}</div>
                </InView>
            )}
            <Box paddingX={1.5} width="100%">
                <Divider />
            </Box>
        </ListItem>
    );
};
