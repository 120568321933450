import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, createStyles, Divider, makeStyles, Theme } from '@material-ui/core';
import { isApplePayReady } from 'src/integrations/PaymentProvider/ApplePayButton';
import { browser } from 'src/utils/browser';
import { EOperatingSystem, getOperatingSystem } from 'src/utils/device';
import { getCardTypeByIssuer } from 'src/utils/payment';
import { IPaymentOptionValue } from 'components/bill/ui/ViewBill/Checkout/PaymentMethod/PaymentMethodDialog';
import { OrderPaymentType } from 'components/order/model/Order';
import { EPaymentProvider } from 'components/settings/model/Settings';
import { UserCard } from 'components/user/model/User';
import { userApi } from 'components/user/userApi';
import { addOpacity } from 'lib/helpers';
import { LoadingTypography } from 'lib/LoadingTypography';
import logger from 'lib/logger';
import { useAuth } from 'lib/useAuth';
import { getUserCard } from 'store/auth/authActions';
import { ApplicationState } from 'store/store';
import { PayLinkButton } from '../ui/PayLinkButton';
import { PayAddCardDialog } from './PayAddCardDialog';
import { PaymentMethodGroup } from './PaymentMethodGroup';
import { PaymentMethodItem } from './PaymentMethodItem';
import { isGooglePayAvailable } from 'src/integrations/GooglePay/isGooglePayReady';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: '18px',
            fontWeight: 700
        },
        action: {
            color: theme.palette.text.secondary,
            fontSize: theme.spacing(1.5),
            textDecoration: 'underline'
        },
        link: {
            color: addOpacity(theme.palette.text.primary, 0.7)
        }
    })
);

interface IProps {
    onPaymentMethodChange: (value: IPaymentOptionValue) => void;
    selectedPaymentMethod: IPaymentOptionValue;
    onAddNewCardClick: () => void;
    cards: UserCard[];
    loading?: boolean;
    addNewCardOpen: boolean;
    handleCardAdded: (paymentType: OrderPaymentType, selectedCard: UserCard | null) => () => void;
    handleAddNewCardClose: () => void;
}

export const PaymentMethods: React.FC<IProps> = ({
    onPaymentMethodChange,
    cards,
    onAddNewCardClick,
    selectedPaymentMethod,
    addNewCardOpen,
    loading: externalLoading,
    handleCardAdded,
    handleAddNewCardClose
}) => {
    const [addCardLoading, setAddCardLoading] = React.useState(false);
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { user, card } = useAuth();
    const [selectedPaymentOption, setSelectedPaymentOption] = React.useState<IPaymentOptionValue>();
    const [selectedPaymentType, setSelectedPaymentType] = React.useState<string>(() => {
        if (selectedPaymentMethod && selectedPaymentMethod.card) {
            return selectedPaymentMethod.card._id;
        }
        return selectedPaymentMethod?.type.toString() || '';
    });
    const [isGpAvailable, setIsGpAvailable] = React.useState(false);
    const handlePaymentTypeSelect = React.useCallback(
        (paymentType: string) => setSelectedPaymentType(paymentType),
        []
    );
    React.useEffect(() => {
        isGooglePayAvailable(settings)
            .then(result => {
                setIsGpAvailable(result);
            })
            .catch(e => {
                logger.warn(e.message);
                setIsGpAvailable(false);
            });
    }, [settings]);
    React.useEffect(() => {
        if (selectedPaymentMethod) {
            setSelectedPaymentType(() => {
                if (selectedPaymentMethod && selectedPaymentMethod.card) {
                    return selectedPaymentMethod.card._id;
                }
                return selectedPaymentMethod.type.toString() || '';
            });
        }
    }, [selectedPaymentMethod]);
    const paymentMethods = React.useMemo(() => {
        const methods: {
            value: string;
            label: React.ReactNode | string;
            onClickValue: IPaymentOptionValue;
        }[] = [];
        const os = getOperatingSystem();
        const notIPhone = os !== EOperatingSystem.IOS;
        const applePayEnabledProviders = [
            EPaymentProvider.SQUARE,
            EPaymentProvider.JUDOPAY,
            EPaymentProvider.WORLDPAY,
            EPaymentProvider.STRIPE
        ];

        if (
            settings &&
            applePayEnabledProviders.includes(settings.paymentProvider as EPaymentProvider) &&
            settings?.applePayEnabled &&
            isApplePayReady()
        ) {
            methods.push({
                value: OrderPaymentType.APPLEPAY.toString(),
                label: <PaymentMethodItem type="APPLEPAY" />,
                onClickValue: { type: OrderPaymentType.APPLEPAY }
            });
        }
        if (settings?.googlePayEnabled && browser().isChrome && notIPhone && isGpAvailable) {
            methods.push({
                value: OrderPaymentType.GOOGLEPAY.toString(),
                label: <PaymentMethodItem type="GOOGLEPAY" />,
                onClickValue: { type: OrderPaymentType.GOOGLEPAY }
            });
        }

        if (cards.length > 0) {
            const cardOptions = cards.map(cardItem => {
                const type = getCardTypeByIssuer(cardItem.type) || '';

                return {
                    label: <PaymentMethodItem type={type} card={cardItem} />,
                    value: cardItem._id,
                    onClickValue: { type: OrderPaymentType.CARD_ON_FILE, card: cardItem }
                };
            });
            methods.push(...cardOptions);
        }

        return methods;
    }, [cards, isGpAvailable, settings]);
    const handlePaymentOptionClick = React.useCallback(setSelectedPaymentOption, [setSelectedPaymentOption]);
    React.useEffect(() => {
        if (selectedPaymentOption) {
            onPaymentMethodChange(selectedPaymentOption);
        }
    }, [onPaymentMethodChange, selectedPaymentOption]);
    React.useEffect(() => {
        if (cards.length > 0) {
            setSelectedPaymentType(cards[0]._id);
        }
    }, [cards]);
    const handleChangeCard = React.useCallback(async () => {
        if (card) {
            setAddCardLoading(true);
            try {
                await userApi.removeCard(card._id);
                if (user && user.id) {
                    await getUserCard(user.id)(dispatch);
                }
                onAddNewCardClick();
            } catch (e) {
                logger.error(e);
            } finally {
                setAddCardLoading(false);
            }
        }
    }, [card, dispatch, onAddNewCardClick, user]);
    const loading = externalLoading || addCardLoading;
    return (
        <>
            <Box px={2} pb={1} pt={1}>
                <LoadingTypography loading={loading} className={classes.title} width={140}>
                    {t('BILL_CHECKOUT_PAYMENT_METHOD_TITLE')}
                </LoadingTypography>
                {(loading || (!!paymentMethods && !!paymentMethods.length)) && (
                    <PaymentMethodGroup
                        options={paymentMethods}
                        loading={loading}
                        value={selectedPaymentType?.toString()}
                        onChange={handlePaymentTypeSelect}
                        onClick={handlePaymentOptionClick}
                    />
                )}
                {(!cards || !cards.length) && !loading && (
                    <PayLinkButton onClick={onAddNewCardClick}>
                        {'+ '}
                        {t('CHECKOUT_ADD_CARD')}
                    </PayLinkButton>
                )}
                {!!cards && !!cards.length && !loading && !addCardLoading && (
                    <PayLinkButton onClick={handleChangeCard}>
                        {t('PAT_QUICKPAY_CHANGE_PAYMENT_METHOD')}
                    </PayLinkButton>
                )}
                <Divider />
            </Box>
            <PayAddCardDialog
                open={addNewCardOpen}
                handleCreateOrder={handleCardAdded}
                onClose={handleAddNewCardClose}
            />
        </>
    );
};
