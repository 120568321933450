import { ActionCreator } from 'redux';
import { locationApi } from 'components/location/locationApi';
import { Location, LocationService, LocationsSummary } from 'components/location/model/Location';
import logger from 'lib/logger';
import { LocationActionTypes } from './locationActionTypes';

const LOCATIONS_PER_PAGE = '25';

interface LocationLoadingAction {
    type:
        | LocationActionTypes.END_REQUEST
        | LocationActionTypes.START_REQUEST
        | LocationActionTypes.START_NEXT_PAGE_LOADING
        | LocationActionTypes.RESET_CURRENT_LOCATION;
}

interface GetLocationActionSuccess {
    type: LocationActionTypes.GET_LOCATIONS_SUCCESS | LocationActionTypes.LOAD_LOCATIONS_SUCCESS;
    summary: { total: number; count: number; pages: number; page: number };
    locations: Location[];
}

interface GetCoordinatesActionSuccess {
    type: LocationActionTypes.GET_COORDINATES_SUCCESS;
    lat: number;
    lng: number;
}

interface GetLocationActionError {
    type: LocationActionTypes.GET_LOCATIONS_ERROR | LocationActionTypes.GET_CURRENT_LOCATION_ERROR;
    error: string;
}

interface GetCurrentLocationSuccess {
    type: LocationActionTypes.GET_CURRENT_LOCATION_SUCCESS;
    currentLocation: Location;
}

export type LocationAction =
    | LocationLoadingAction
    | GetLocationActionSuccess
    | GetLocationActionError
    | GetCoordinatesActionSuccess
    | GetCurrentLocationSuccess;

const startRequest = {
    type: LocationActionTypes.START_REQUEST
};

const endRequest = {
    type: LocationActionTypes.END_REQUEST
};

export interface GetLocationsOptions {
    coords?: { lat: number; long: number };
    searchTerm?: string;
    services?: LocationService | LocationService[];
}

export const getLocations =
    (options?: GetLocationsOptions) => async (dispatch: ActionCreator<LocationAction>) => {
        dispatch(startRequest);
        const queryParameters: Record<string, string> = {
            page: '1',
            per_page: LOCATIONS_PER_PAGE,
            state: 'ACTIVE',
            services: 'pre_order'
        };
        if (options && options.coords) {
            queryParameters.lat = String(options.coords.lat);
            queryParameters.long = String(options.coords.long);
        }
        if (options && options.searchTerm) {
            queryParameters.search = options.searchTerm;
        }
        if (options && options.services) {
            queryParameters.services = Array.isArray(options.services)
                ? options.services.reduce((acc, service) => (acc += `${service},`), '').replace(/,\s*$/, '')
                : options.services;
        }
        try {
            const response = await locationApi.getList(queryParameters);
            const { locations, summary } = response;
            dispatch({
                locations,
                summary,
                type: LocationActionTypes.GET_LOCATIONS_SUCCESS
            });
        } catch (e) {
            logger.error(e);
            dispatch({
                type: LocationActionTypes.GET_LOCATIONS_ERROR,
                error: e.message
            });
        }
        dispatch(endRequest);
    };

export const loadLocations =
    (locationStateSummary: LocationsSummary, options?: GetLocationsOptions) =>
    async (dispatch: ActionCreator<LocationAction>) => {
        dispatch({
            type: LocationActionTypes.START_NEXT_PAGE_LOADING
        });
        if (!!locationStateSummary && locationStateSummary.page === locationStateSummary.pages) {
            return dispatch(endRequest);
        }
        const nextPage = (locationStateSummary ? locationStateSummary.page : 1) + 1;
        const queryParameters: Record<string, string> = {
            page: String(nextPage),
            per_page: LOCATIONS_PER_PAGE,
            state: 'ACTIVE',
            services: 'pre_order'
        };
        if (options && options.coords) {
            queryParameters.lat = String(options.coords.lat);
            queryParameters.long = String(options.coords.long);
        }
        try {
            const response = await locationApi.getList(queryParameters);
            const { locations, summary } = response;
            dispatch({
                locations,
                summary,
                type: LocationActionTypes.LOAD_LOCATIONS_SUCCESS
            });
        } catch (e) {
            logger.error(e);
            dispatch({
                type: LocationActionTypes.GET_LOCATIONS_ERROR,
                error: e.message
            });
        }
        dispatch(endRequest);
    };

export const getCoordinatesSuccess =
    (lat: number, lng: number) => (dispatch: ActionCreator<GetLocationActionSuccess>) => {
        dispatch({
            lat,
            lng,
            type: LocationActionTypes.GET_COORDINATES_SUCCESS
        });
    };

export const getCurrentLocation = (locationId: string) => async (dispatch: ActionCreator<LocationAction>) => {
    dispatch({
        type: LocationActionTypes.RESET_CURRENT_LOCATION
    });
    try {
        const currentLocation = await locationApi.getLocation(locationId);
        dispatch({
            currentLocation,
            type: LocationActionTypes.GET_CURRENT_LOCATION_SUCCESS
        });
    } catch (e) {
        logger.error(e);
        dispatch({
            type: LocationActionTypes.GET_CURRENT_LOCATION_ERROR,
            error: e.message
        });
    }
};

export const resetCurrentLocation = (dispatch: ActionCreator<LocationLoadingAction>) => {
    dispatch({
        type: LocationActionTypes.RESET_CURRENT_LOCATION
    });
};
