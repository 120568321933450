import React from 'react';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useResponsive } from 'src/hooks/useResponsive';
import { ApplicationState } from 'store/store';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        text: {
            marginTop: theme.spacing(2)
        },
        loadingSvg: {
            width: '80%',
            maxWidth: theme.spacing(50)
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            zIndex: 3,
            transition: theme.transitions.easing.easeIn,
            opacity: 1
        },
        isHidden: {
            opacity: 0,
            pointerEvents: 'none'
        },
        hasBackground: {
            backgroundColor: theme.palette.common.white
        },
        fullScreen: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.5)',
            zIndex: 3,
            transition: theme.transitions.easing.easeIn,
            opacity: 1
        }
    })
);
interface IThrobberProps {
    text: string;
    isLoading?: boolean;
    isOverlay?: boolean;
    isFullScreen?: boolean;
}

export const Throbber: React.FC<IThrobberProps> = props => {
    const { text, isLoading, isOverlay, isFullScreen } = props;
    const { isDesktop } = useResponsive();
    const classes = useStyles();
    const { loadingImage } = useSelector((state: ApplicationState) => state.settings);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flex={1}
            height="100%"
            className={clsx({
                [classes.overlay]: isOverlay,
                [classes.isHidden]: isOverlay && !isLoading,
                [classes.hasBackground]: isDesktop,
                [classes.fullScreen]: isFullScreen
            })}
        >
            {loadingImage ? (
                <img src={loadingImage} className={classes.loadingSvg} alt="loading" />
            ) : (
                <CircularProgress variant="indeterminate" />
            )}
            <Typography variant="subtitle1" className={classes.text}>
                {text}
            </Typography>
        </Box>
    );
};
