import { uuid } from 'uuidv4';

export const checkStaticId = () => {
    const staticIdFromLocalStorage = localStorage.getItem('pepper_static_id');

    if (!staticIdFromLocalStorage) {
        localStorage.setItem('pepper_static_id', uuid());
    }
};

export const getLocalStaticId = () => localStorage.getItem('pepper_static_id');
