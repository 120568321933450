import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Button, createStyles, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import { isApplePayReady } from 'src/integrations/PaymentProvider/ApplePayButton';
import { browser } from 'src/utils/browser';
import { EOperatingSystem, getOperatingSystem } from 'src/utils/device';
import { getCardTypeByIssuer } from 'src/utils/payment';
import { OrderPaymentType } from 'components/order/model/Order';
import { getLocalMerchantId } from 'components/settings/localStore';
import { EPaymentProvider } from 'components/settings/model/Settings';
import { setOnCloseRedirectUrl } from 'components/user/localAuth';
import { UserCard } from 'components/user/model/User';
import { BottomDialog } from 'lib/BottomDialog';
import { addOpacity } from 'lib/helpers';
import { MuiRadioGroup, Option } from 'lib/MuiRadioGroup';
import { PaymentMethodIcon } from 'lib/PaymentMethodIcon';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { ROUTES } from 'pages/routes';
import { ApplicationState } from 'store/store';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    cards: UserCard[];
    onSelectPaymentMethod: (paymentMethod: IPaymentOptionValue) => void;
    userSelectedPaymentOption: IPaymentOptionValue;
}

export interface IPaymentOptionValue {
    type: OrderPaymentType;
    card?: UserCard;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        action: {
            color: theme.palette.text.secondary,
            fontSize: theme.spacing(1.5),
            textDecoration: 'underline'
        },
        link: {
            color: addOpacity(theme.palette.text.primary, 0.7)
        }
    })
);

export const ViewBillPaymentMethodsDialog: React.FC<IProps> = ({
    isOpen,
    onClose,
    cards,
    onSelectPaymentMethod,
    userSelectedPaymentOption
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const { push } = useLocalHistory();
    const { isLoggedIn } = useAuth();
    const { pathname } = useLocation();

    const [selectedPaymentType, setSelectedPaymentType] = React.useState<string>(() => {
        if (userSelectedPaymentOption && userSelectedPaymentOption.card) {
            return userSelectedPaymentOption.card._id;
        }
        return userSelectedPaymentOption?.type.toString() || '';
    });
    React.useEffect(() => {
        if (userSelectedPaymentOption) {
            setSelectedPaymentType(() => {
                if (userSelectedPaymentOption && userSelectedPaymentOption.card) {
                    return userSelectedPaymentOption.card._id;
                }
                return userSelectedPaymentOption.type.toString() || '';
            });
        }
    }, [userSelectedPaymentOption]);

    const [selectedPaymentOption, setSelectedPaymentOption] = React.useState<IPaymentOptionValue>();

    const handleRemoveCard = React.useCallback(() => {
        const merchantId = getLocalMerchantId();
        if (merchantId && isLoggedIn) {
            setOnCloseRedirectUrl(`${pathname}?isCheckingOut=true`, merchantId);
            push(ROUTES.USER.PAYMENT_METHODS, { merchantId });
        }
    }, [isLoggedIn, pathname, push]);
    const paymentMethods = React.useMemo(() => {
        const methods: {
            value: string;
            label: React.ReactNode | string;
            onClickValue: IPaymentOptionValue;
        }[] = [];
        const os = getOperatingSystem();
        const notIPhone = os !== EOperatingSystem.IOS;
        const applePayEnabledProviders = [
            EPaymentProvider.SQUARE,
            EPaymentProvider.JUDOPAY,
            EPaymentProvider.WORLDPAY,
            EPaymentProvider.STRIPE
        ];

        if (
            settings &&
            applePayEnabledProviders.includes(settings.paymentProvider as EPaymentProvider) &&
            settings?.applePayEnabled &&
            isApplePayReady()
        ) {
            methods.push({
                value: OrderPaymentType.APPLEPAY.toString(),
                label: (
                    <Box display="flex" alignItems="center">
                        <Box marginRight={1} display="flex" alignItems="center">
                            <PaymentMethodIcon width="30px" type="APPLEPAY" />
                        </Box>
                        {t('PAYMENT_TYPE_APPLE_PAY')}
                    </Box>
                ),
                onClickValue: { type: OrderPaymentType.APPLEPAY }
            });
        }
        if (settings?.googlePayEnabled && browser().isChrome && notIPhone) {
            methods.push({
                value: OrderPaymentType.GOOGLEPAY.toString(),
                label: (
                    <Box display="flex" alignItems="center">
                        <Box marginRight={1} display="flex" alignItems="center">
                            <PaymentMethodIcon width="30px" type="GOOGLEPAY" />
                        </Box>
                        {t('PAYMENT_TYPE_GOOGLE_PAY')}
                    </Box>
                ),
                onClickValue: { type: OrderPaymentType.GOOGLEPAY }
            });
        }

        if (cards.length > 0) {
            const cardOptions = cards.map(card => {
                const type = getCardTypeByIssuer(card.type) || '';

                return {
                    label: (
                        <Box display="flex" alignItems="center">
                            <Box marginRight={1} display="flex" alignItems="center">
                                <PaymentMethodIcon width="30px" type={type} />
                            </Box>
                            {`**** **** **** ${card.last4}`}
                        </Box>
                    ),
                    value: card._id,
                    onClickValue: { type: OrderPaymentType.CARD_ON_FILE, card }
                };
            });
            methods.push(...cardOptions);
        } else {
            methods.push({
                label: t('CHECKOUT_ADD_CARD'),
                value: OrderPaymentType.CARD_ON_FILE.toString(),
                onClickValue: { type: OrderPaymentType.CARD_ON_FILE }
            });
        }

        return methods;
    }, [cards, settings, t]);

    const handlePaymentTypeSelect = React.useCallback(
        (paymentType: string) => setSelectedPaymentType(paymentType),
        []
    );
    const handlePaymentOptionClick = React.useCallback(setSelectedPaymentOption, [setSelectedPaymentOption]);
    const handlePaymentOptionSelected = React.useCallback(() => {
        if (selectedPaymentOption) {
            onSelectPaymentMethod(selectedPaymentOption);
        }
        onClose();
    }, [onClose, onSelectPaymentMethod, selectedPaymentOption]);
    React.useEffect(() => {
        if (cards.length > 0) {
            setSelectedPaymentType(cards[0]._id);
        }
    }, [cards]);
    const renderActionButton = React.useCallback(
        (option: Option) =>
            isLoggedIn &&
            option.value !== OrderPaymentType.APPLEPAY.toString() &&
            option.onClickValue?.card !== undefined &&
            option.value !== OrderPaymentType.GOOGLEPAY.toString() ? (
                <Typography className={classes.action}>
                    <Link className={classes.link} href="" onClick={handleRemoveCard} color="inherit">
                        {t('DIALOG_REMOVE_CARD_OK')}
                    </Link>
                </Typography>
            ) : null,
        [classes.action, classes.link, handleRemoveCard, isLoggedIn, t]
    );
    return (
        <BottomDialog open={isOpen} onClose={onClose}>
            <Box paddingY={2}>
                <Typography align="center" variant="h6">
                    {t('DIALOG_PAYMENT_METHOD_PICKER_TITLE')}
                </Typography>
            </Box>
            <MuiRadioGroup
                options={paymentMethods}
                value={selectedPaymentType?.toString()}
                onChange={handlePaymentTypeSelect}
                onClick={handlePaymentOptionClick}
                renderActionButton={renderActionButton}
            />
            <Box marginTop={1}>
                <Button color="primary" variant="contained" fullWidth onClick={handlePaymentOptionSelected}>
                    {t('BILL_CHECKOUT_PAYMENT_SELECT_BTN')}
                </Button>
            </Box>
        </BottomDialog>
    );
};
