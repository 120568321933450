import { ActionCreator } from 'redux';
import { RequestActionTypes } from './requestActionTypes';

interface SetLastRequestAction {
    type: RequestActionTypes.SET_LAST_REQUEST;
    request: () => void;
}

interface ClearLastReqest {
    type: RequestActionTypes.CLEAR_LAST_REQUESTS;
}

export type RequestAction = SetLastRequestAction | ClearLastReqest;

export const setLastRequest = (request: () => void) => (dispatch: ActionCreator<RequestAction>) => {
    dispatch({
        request,
        type: RequestActionTypes.SET_LAST_REQUEST
    });
};

export const resetGuestState = (dispatch: ActionCreator<RequestAction>) => {
    dispatch({
        type: RequestActionTypes.CLEAR_LAST_REQUESTS
    });
};
