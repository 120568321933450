import { useLocation } from 'react-router-dom';

// List of entries:
//  - j: 'login' - Awards page and other user routes

//  - orderId: string - Activity page
//  - orderId: string - PAT basket

//  - error: string - ViewBasketPayButton component
//  - error: string - BasketTimeslotPicker component

//  - search: string - OTTLocationPicker component
//  - search: string - MenuSearchDialog component
//  - search: string - TimeslotPicker component

//  - isCheckingOut: boolean - pat
//  - isSplitByItemOpen: boolean - pat
//  - splitPart: number - pat (2 places)
//  - isSplitBillItem: boolean - pat (2 places)

//  - dateCheckIdB64: string - pat v2
//  - dateCheckId: string - pat v2
//  - checkId: string - pat v2 (2 places)

//  - tableNumber: string - pat v2 (2 places)
//  - tableNumber: string - order entry page

//  - scenario: string - order entry page

//  - identity: string - login page
//  - identity: string - forgot password page
//  - identity: string - signup page

//  - firstName: string - signup page

//  - lastName: string - signup page

//  - locationId: string - base page
//  - locationId: string - order entry page

export function useQuery(selector: string) {
    const location = useLocation();
    return decodeURIComponent(new URLSearchParams(location.search).get(selector) || '');
}
