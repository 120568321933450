import * as React from 'react';
import { Link as BaseLink, LinkProps } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            color: theme.palette.text.hint
        }
    })
);

export const Link: React.FC<React.PropsWithChildren<LinkProps>> = ({ children, ...props }) => {
    const classes = useStyles();

    return (
        <BaseLink className={classes.link} {...props}>
            {children}
        </BaseLink>
    );
};
