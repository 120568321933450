import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    Box,
    createStyles,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    Theme,
    Typography
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { AxiosResponse } from 'axios';
import { Form, Formik, FormikHelpers } from 'formik';
import { useQuery } from 'src/hooks/useQuery';
import * as Yup from 'yup';
import { MerchantTitle } from 'app/MerchantTitle';
import { getIdentityLabel } from 'components/settings/model/Settings';
import { MuiAppBar } from 'lib/appbar/MuiAppBar';
import { PasswordFormField } from 'lib/form/PasswordFormField';
import { TextFormField } from 'lib/form/TextFormField';
import { Link } from 'lib/Link';
import { Throbber } from 'lib/Throbber';
import { useLocalHistory } from 'lib/useLocalHistory';
import { BaseRouteParams, ROUTES } from 'pages/routes';
import { signIn } from 'store/auth/authActions';
import { resetAwards } from 'store/basket/basketActions';
import { ApplicationState } from 'store/store';
import { LoadingButton } from 'ui/LoadingButton';
import { UserData, getIdentityValidationScheme } from './model/User';
import { getOnCloseRedirectUrl } from './localAuth';
import { GoogleSSO } from './GoogleSSO';
import { AppleSSO } from './AppleSSO';
import { TermsAndConditions } from './TermsAndCondition';
import { EGAEventName, useGAHelpers } from 'lib/useGAHelpers';

Yup.addMethod(Yup.string, 'or', function (schemas: Yup.Schema<any>[], msg: string) {
    // TODO: find better way to do this
    return (this as unknown as any).test({
        name: 'or',
        message: msg || 'Please enter valid url or email.',
        test: (value: string) => {
            if (Array.isArray(schemas) && schemas.length > 1) {
                const resee = schemas.map(schema => schema.isValidSync(value));
                return resee.some(res => res);
            }
            throw new TypeError('Schemas is not correct array schema');
        },
        exclusive: false
    });
});

interface LogInProps {
    message?: string;
}

interface LoginFormData {
    identity: string;
    password: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2)
        },
        button: {
            height: theme.spacing(6),
            fontSize: '1.2em'
        },
        forgot: {
            margin: theme.spacing(0, 1.75),
            marginTop: theme.spacing(1),
            display: 'block'
        },
        forgotWith2FA: {
            margin: theme.spacing(0, 1.75),
            marginTop: theme.spacing(-1),
            display: 'block'
        },
        orContainer: {
            position: 'relative',
            padding: theme.spacing(0, 5),
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2)
        },
        or: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(1, 3),
            color: theme.palette.divider
        }
    })
);

export const LogIn: React.FC<LogInProps> = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { merchantId } = useParams<BaseRouteParams>();
    const { logUserEvent } = useGAHelpers();
    const userIdentity = useQuery('identity');
    const { settings, isLoading } = useSelector((state: ApplicationState) => state.settings);
    const [errorMessage, setErrorMessage] = React.useState('');
    const { isLoading: isAuthLoading } = useSelector((state: ApplicationState) => state.auth);
    const identity = React.useMemo(
        () => getIdentityLabel(settings?.emailAuthEnabled, settings?.phoneAuthEnabled),
        [settings?.emailAuthEnabled, settings?.phoneAuthEnabled]
    );
    const { push, getParsedPath } = useLocalHistory();

    const initialFields: LoginFormData = React.useMemo(
        () => ({
            identity: userIdentity ?? '',
            password: ''
        }),
        [userIdentity]
    );

    const handleMouseDownIdentity = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    }, []);

    const handleClearIdentity = React.useCallback(
        (setFieldValue: FormikHelpers<any>['setFieldValue']) => () => {
            setFieldValue('identity', '');
        },
        []
    );

    const has2FA = React.useMemo(
        () => !!settings && !!settings.iam && settings.iam.appUserAuth === 'VERIFY_PASSWORD',
        [settings]
    );

    const signinValidation = React.useMemo(
        () =>
            Yup.object().shape({
                identity: getIdentityValidationScheme(identity, settings?.region.phoneNumberCode, t),
                ...(!has2FA && {
                    password: Yup.string().required(t('FORMS_VALIDATION_REQUIRED'))
                })
            }),
        [t, identity, settings?.region.phoneNumberCode, has2FA]
    );

    const handleSignInSuccess = React.useCallback(
        (res: UserData) => {
            resetAwards(dispatch);
            const returnUrl = getOnCloseRedirectUrl(merchantId ?? '');
            if (returnUrl) {
                const [path, search] = returnUrl.split('?');
                push(path, {}, search);
            } else {
                push(ROUTES.BASE);
            }

            return res;
        },
        [dispatch, merchantId, push]
    );

    const handleSignInError = React.useCallback(
        (error: AxiosResponse<{ message: string; code: string }>) => {
            if (error.data?.code === 'E-WAF-0001') {
                return setErrorMessage(error.data.message);
            }
            switch (error.status) {
                case 401:
                    setErrorMessage("Sorry! We couldn't find you with that email and password combo.");
                    break;
                default:
                    setErrorMessage(t('GENERAL_ERROR_PROCESSING_REQUEST'));
                    break;
            }
        },
        [t]
    );

    const handleSubmit = React.useCallback(
        (data: LoginFormData) => {
            logUserEvent(EGAEventName.LoginSubmit);
            if (has2FA) {
                push(getParsedPath(ROUTES.USER.TWO_FACTOR_AUTHENTICATION), undefined, undefined, {
                    id: data.identity
                });
            } else {
                const credentials = btoa(`${data.identity}:${data.password}`);

                return signIn(credentials)(dispatch).then(handleSignInSuccess).catch(handleSignInError);
            }
        },
        [dispatch, getParsedPath, handleSignInError, handleSignInSuccess, has2FA, logUserEvent, push]
    );

    const handleKeyPress = React.useCallback(
        (submit: () => Promise<void>) => (e: React.KeyboardEvent) => {
            if (e.keyCode === 13) {
                submit();
            }
        },
        []
    );

    const handleForgotClick = React.useCallback(() => {
        logUserEvent(EGAEventName.ForgotPasswordClick);
    }, [logUserEvent]);

    if (isLoading) {
        return <Throbber text={t('GENERAL_LOADING')} />;
    }

    return (
        <>
            <MerchantTitle title={t('ONBOARDING_SIGN_IN')} />
            <MuiAppBar title={t('ONBOARDING_SIGN_IN')} icon={Close} disabledMenu />
            <Formik initialValues={initialFields} validationSchema={signinValidation} onSubmit={handleSubmit}>
                {({
                    submitForm,
                    setFieldValue,
                    isSubmitting,
                    isValid,
                    values: { identity: identityField, password }
                }) => (
                    <Form className={classes.root}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography align="center">
                                    {t('AUTH_NO_ACCOUNT') + ' '}
                                    <Link to={getParsedPath(ROUTES.USER.REGISTER)}>
                                        {t('ONBOARDING_SIGN_UP')}
                                    </Link>
                                </Typography>
                            </Grid>
                            <GoogleSSO
                                onSignInError={handleSignInError}
                                onSignInSuccess={handleSignInSuccess}
                            />
                            <AppleSSO
                                onSignInError={handleSignInError}
                                onSignInSuccess={handleSignInSuccess}
                            />
                            {(settings?.appleSSOEnabled || settings?.googleSSOEnabled) && (
                                <Grid item xs={12}>
                                    <Box className={classes.orContainer}>
                                        <Divider />
                                        <Typography className={classes.or}>OR</Typography>
                                    </Box>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <TextFormField
                                    name="identity"
                                    label={t(`ONBOARDING_SIGNUP_LABEL_${identity.toUpperCase()}`)}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onClick={handleClearIdentity(setFieldValue)}
                                                onMouseDown={handleMouseDownIdentity}
                                                size="small"
                                                tabIndex={-1}
                                            >
                                                <Close />
                                            </IconButton>
                                        ),
                                        autoComplete: 'email'
                                    }}
                                />
                            </Grid>
                            {!has2FA && (
                                <Grid item xs={12}>
                                    <PasswordFormField
                                        name="password"
                                        label={t('ONBOARDING_SIGNIN_PASSWORD')}
                                        onKeyDown={handleKeyPress(submitForm)}
                                    />
                                    <Typography
                                        align="right"
                                        variant="caption"
                                        className={classes.forgot}
                                        onClick={handleForgotClick}
                                    >
                                        <Link to={getParsedPath(ROUTES.USER.FORGOT_PASSWORD)}>
                                            {t('BUTTON_FORGOTTEN')}
                                        </Link>
                                    </Typography>
                                </Grid>
                            )}
                            {has2FA && (
                                <Grid item xs={12}>
                                    <Typography
                                        align="right"
                                        variant="caption"
                                        className={classes.forgotWith2FA}
                                        onClick={handleForgotClick}
                                    >
                                        <Link to={getParsedPath(ROUTES.USER.FORGOT_PASSWORD)}>
                                            {t('BUTTON_FORGOTTEN')}
                                        </Link>
                                    </Typography>
                                </Grid>
                            )}
                            {!!errorMessage && (
                                <Grid item xs={12}>
                                    <Typography color="error">{errorMessage}</Typography>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                        !isValid || isAuthLoading || !identityField || (!has2FA && !password)
                                    }
                                    fullWidth
                                    onClick={submitForm}
                                    className={classes.button}
                                    loading={isSubmitting || isAuthLoading}
                                >
                                    {t('BUTTON_SUBMIT')}
                                </LoadingButton>
                            </Grid>
                            <Grid item xs={12}>
                                <TermsAndConditions />
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};
