import * as React from 'react';
import { useSelector } from 'react-redux';
import { Box, createStyles, DialogProps, makeStyles, Theme, Typography } from '@material-ui/core';
import { useResponsive } from 'src/hooks/useResponsive';
import { ApplicationState } from 'store/store';
import { BottomDialog } from './BottomDialog';
import { isEmptyString } from './typeInference';

interface IProps extends DialogProps {
    icon: JSX.Element | null;
    title: string;
    subtitle: string;
    open: boolean;
    onClose: () => void;
    hideImage?: boolean;
    hideTitle?: boolean;
    isLoading?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        locationTitle: {
            fontFamily: `TenantFont, ${theme.typography.fontFamily}`,
            textAlign: 'center',
            color: theme.palette.titleTextColour
        },
        locationImg: {
            width: '100%',
            objectFit: 'cover',
            height: theme.spacing(200 / 8)
        },
        bagIconWrapper: (props: { hasImg: boolean }) => ({
            width: theme.spacing(100 / 8),
            height: theme.spacing(100 / 8),
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: props.hasImg ? theme.spacing(-50 / 8) : 0,
            position: 'relative',
            zIndex: 1
        }),
        icon: {
            fontSize: theme.spacing(7),
            color: theme.palette.background.default,
            lineHeight: 0
        },
        boldText: {
            fontWeight: theme.typography.fontWeightBold
        },
        selectBox: {
            maxHeight: theme.spacing(200 / 8),
            borderRadius: theme.shape.borderRadius
        },
        selectBoxList: {
            padding: theme.spacing(0)
        }
    })
);

export const MerchantBrandedDialog: React.FC<React.PropsWithChildren<IProps>> = ({
    title,
    subtitle,
    icon,
    open,
    onClose,
    children,
    hideImage,
    hideTitle,
    ...rest
}) => {
    const { currentLocation } = useSelector((state: ApplicationState) => state.locations);
    const classes = useStyles({ hasImg: !!currentLocation?.mainImageUrl });
    const { isDesktop } = useResponsive();

    const renderLocalisationStrings = React.useCallback(
        string => (
            <Typography key={string} align="center" variant="body1" paragraph={isEmptyString(string)}>
                {string}
            </Typography>
        ),
        []
    );

    return (
        <BottomDialog {...rest} open={open} onClose={onClose}>
            <Box paddingX={1} minWidth={isDesktop && !currentLocation?.mainImageUrl ? 445 : 0}>
                {!hideImage && (
                    <>
                        <Box paddingY={1}>
                            <Typography className={classes.locationTitle} variant="h6">
                                {currentLocation?.title}
                            </Typography>
                        </Box>
                        <Box marginX={-2}>
                            {currentLocation?.mainImageUrl && (
                                <img
                                    className={classes.locationImg}
                                    src={currentLocation?.mainImageUrl}
                                    alt={currentLocation?.title}
                                />
                            )}
                            {!!icon && (
                                <div className={classes.bagIconWrapper}>
                                    <span className={classes.icon}>{icon}</span>
                                </div>
                            )}
                        </Box>
                    </>
                )}

                <Box marginTop={1}>
                    {!hideTitle && (
                        <Typography align="center" variant="body1">
                            <b>{title}</b>
                        </Typography>
                    )}

                    <Typography align="center" variant="body1">
                        {subtitle.split('\n').map(renderLocalisationStrings)}
                    </Typography>
                </Box>
                {children}
            </Box>
        </BottomDialog>
    );
};
