import { useParams } from 'react-router-dom';
import { ERouteScenario } from 'src/enums';
import { getRouteScenario, makeRouteScenario } from 'src/utils/route';
import { isOrderScenario, OrderScenario } from 'components/order/model/Order';

export const useRouteScenario: () => [OrderScenario | undefined, ERouteScenario | string] = () => {
    const { scenario } = useParams<{ scenario: ERouteScenario }>();

    const validScenario = getRouteScenario(scenario);

    return [
        isOrderScenario(validScenario) ? validScenario : undefined,
        scenario ?? makeRouteScenario(OrderScenario.PREORDER) ?? ''
    ];
};
