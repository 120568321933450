import * as React from 'react';
import { useSelector } from 'react-redux';
import { PaymentRequest } from '@stripe/stripe-js';
import { StripeProvider } from 'src/integrations/PaymentProvider/Stripe/StripeProvider';
import { OrderPaymentType } from 'components/order/model/Order';
import { PaymentProviderType } from 'components/user/model/User';
import logger from 'lib/logger';
import { isDefined } from 'lib/typeInference';
import { ApplicationState } from 'store/store';
import { IAddCardComponentClasses, IPaymentProvider, paymentProviderFactory } from '../PaymentProvider';
import { ApplePay, GooglePay } from '../Square/models';
import { SquareDigital, SquareProvider } from '../Square/SquareProvider';

type IProps =
    | {
          type: 'cardOnFile';
          order?: undefined;
      }
    | {
          type: 'digitalWallet';
          order?: {
              total: string;
              label: string;
              currencyCode: string;
              countryCode: string;
          };
      };

interface AddCardProps {
    onCardAdded?: () => void;
    classes?: IAddCardComponentClasses;
}

export interface IWalletAvailability {
    applePay: boolean;
    googlePay: boolean;
}

export function usePaymentProvider(
    { order, type }: IProps,
    { onCardAdded, classes }: AddCardProps = {},
    onNonceRecieved?: (nonce: string, walletType: OrderPaymentType) => void
) {
    const { settings } = useSelector((state: ApplicationState) => state.settings);
    const paymentProvider = settings?.paymentProvider;
    const [walletsAvailability, setWalletsAvailability] = React.useState<IWalletAvailability>({
        applePay: false,
        googlePay: false
    });
    const [isWalletsLoading, setIsWalletsLoading] = React.useState<boolean>(true);
    const [provider, setProvider] = React.useState<IPaymentProvider | undefined>(undefined);
    const [isProviderLoading, setProviderLoading] = React.useState(true);
    const [stripePaymentRequest, setStripePaymentRequest] = React.useState<PaymentRequest | null>(null);
    const [squareDigital, setSquareDigital] = React.useState<null | SquareDigital>(null);

    React.useEffect(() => {
        async function createProvider() {
            setProviderLoading(true);
            if (settings) {
                setProvider(await paymentProviderFactory.create(settings));
            }
            setProviderLoading(false);
        }
        createProvider();
    }, [order, settings, type]);

    const addCardComponent = React.useMemo(() => {
        if (provider && onCardAdded) {
            return provider.createAddCardComponent(onCardAdded, classes);
        }
        return null;
    }, [classes, onCardAdded, provider]);

    React.useEffect(() => {
        switch (paymentProvider) {
            case PaymentProviderType.SQUARE: {
                const squareProvider = provider as SquareProvider;
                const initSquareWallets = () => {
                    if (!order) {
                        return;
                    }
                    const paymentsRequest = SquareProvider.instance.paymentRequest({
                        countryCode: order.countryCode,
                        currencyCode: order.currencyCode,
                        total: {
                            amount: order.total,
                            label: order.label
                        }
                    });
                    let counter = 2;
                    const newSquareDigital: SquareDigital = {};
                    const newWalletsAvailability: IWalletAvailability = {
                        googlePay: false,
                        applePay: false
                    };
                    SquareProvider.instance
                        .applePay(paymentsRequest)
                        .then((result: ApplePay) => {
                            newSquareDigital.applePay = result;
                            newWalletsAvailability.applePay = true;
                        })
                        .catch((e: Error) => {
                            logger.error(e.message, e);
                            newSquareDigital.applePay = undefined;
                            newWalletsAvailability.applePay = false;
                        })
                        .finally(() => {
                            counter--;
                            if (counter === 0) {
                                setWalletsAvailability(newWalletsAvailability);
                                setSquareDigital(newSquareDigital);
                                setIsWalletsLoading(false);
                            }
                        });
                    SquareProvider.instance
                        .googlePay(paymentsRequest)
                        .then((result: GooglePay) => {
                            newSquareDigital.googlePay = result;
                            newWalletsAvailability.googlePay = true;
                        })
                        .catch((e: Error) => {
                            logger.error(e.message, e);
                            newSquareDigital.googlePay = undefined;
                            newWalletsAvailability.googlePay = false;
                        })
                        .finally(() => {
                            counter--;
                            if (counter === 0) {
                                setWalletsAvailability(newWalletsAvailability);
                                setSquareDigital(newSquareDigital);
                                setIsWalletsLoading(false);
                            }
                        });
                };
                if (type === 'digitalWallet' && !!squareProvider && !!order) {
                    let counter = 5;
                    const tryToLoadWallets: () => void = () => {
                        if (isDefined(SquareProvider.instance)) {
                            initSquareWallets();
                        } else {
                            setTimeout(() => {
                                if (counter > 0) {
                                    counter--;
                                    tryToLoadWallets();
                                }
                            }, 1000);
                        }
                    };
                    tryToLoadWallets();
                }
                break;
            }
            case PaymentProviderType.STRIPE: {
                const stripeProvider = provider as StripeProvider;
                if (type === 'digitalWallet' && !!stripeProvider && !!order) {
                    const stripeGateway = stripeProvider.getGateway();
                    const paymentRequest = stripeGateway.paymentRequest({
                        country: order.countryCode,
                        currency: order.currencyCode.toLowerCase(),
                        total: {
                            amount: Math.trunc(Number(order.total) * 100),
                            label: order.label
                        }
                    });

                    paymentRequest.on('paymentmethod', ({ complete, walletName, paymentMethod }) => {
                        if (onNonceRecieved) {
                            let digitalWalletType: OrderPaymentType;

                            try {
                                switch (walletName) {
                                    case 'googlePay':
                                        digitalWalletType = OrderPaymentType.GOOGLEPAY;
                                        break;
                                    case 'applePay':
                                        digitalWalletType = OrderPaymentType.APPLEPAY;
                                        break;
                                    default:
                                        throw new Error('Unknown wallet type');
                                }

                                onNonceRecieved(paymentMethod.id, digitalWalletType);
                                complete('success');
                            } catch {
                                complete('fail');
                            }
                        }
                    });

                    paymentRequest
                        .canMakePayment()
                        .then(walletsAvailabilityResult => {
                            if (!!walletsAvailabilityResult) {
                                const { applePay, googlePay } = walletsAvailabilityResult;
                                setWalletsAvailability({ applePay, googlePay });
                                setStripePaymentRequest(paymentRequest);
                            }
                        })
                        .finally(() => {
                            setIsWalletsLoading(false);
                        });
                }
                break;
            }
            default:
                setIsWalletsLoading(false);
                break;
        }
    }, [isWalletsLoading, onNonceRecieved, paymentProvider, type, provider, setWalletsAvailability, order]);
    return {
        addCardComponent,
        isWalletsLoading,
        walletsAvailability,
        isProviderLoading,
        stripePaymentRequest,
        squareDigital
    };
}
